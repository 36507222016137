import { IonSpinner } from "@ionic/react";
import classnames from "classnames";
import React from "react";

export type Props = {
  color?: IonicThemeShade;
  size?: number;
  className?: string;
};

const DEFAULT_SIZE = 24;

export default class UISpinner extends React.Component<Props> {
  render = (): React.ReactNode => {
    const { size = DEFAULT_SIZE, className } = this.props;

    return (
      <IonSpinner
        className={classnames(className)}
        color={this.props.color}
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
        name="crescent"
      />
    );
  };
}
