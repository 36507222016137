import { RouteComponentProps } from "react-router-dom";

import { getQueryString } from "common/utils/url";
import { MAP_ROUTE, MapAction, QUERY_PARAMS } from "constants/routes";

import { serializeActionData } from "./mapParamHelpers";

const getPathNameForMap = (): string => {
  return `/${MAP_ROUTE}`;
};

const getSearchParamsForMap = (game: string): string => {
  const queryParamUpdates = {
    [QUERY_PARAMS.map.action.key]: MapAction.SELECT_REALITY_CHANNEL,
    [QUERY_PARAMS.map.actionData.key]: serializeActionData({
      game,
    }),
  };

  const search = getQueryString(queryParamUpdates);

  return search;
};

export const getRouteForMapPageSelectedRealityChannel = (game: string): string => {
  return `${getPathNameForMap()}?${getSearchParamsForMap(game)}`;
};

const routeToMapPageAndSelectRealityChannel = (
  history: RouteComponentProps["history"],
  game: string,
): void => {
  history.push({
    pathname: getPathNameForMap(),
    search: getSearchParamsForMap(game),
  });
};

export default routeToMapPageAndSelectRealityChannel;
