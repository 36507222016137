const DEFAULT_DIALOG_AND_PANE_PRIORITY = 100;
const USER_PROFILE_PANE_PRIORITY = 200;
const REPORT_PANE_PRIORITY = 300;

// Helpshift's iframe has a zIndex of 9999999 and its attached to the document.body.
// There is nothing we can do to beat that so this is likely always going to be the top most thing
// if its active
const HELPSHIFT_PANE_PRIORITY = 9999999;

// Higher values take priority over lower values.
// Key name is "|" seperated to communicate nesting of components that may exist simultaneously.
const HARDWARE_BACK_EVENT_PRIORITY = {
  // Default dialogs/panes appear at priority 100. Pretty high since they usually are on top.
  dialogs: DEFAULT_DIALOG_AND_PANE_PRIORITY,
  helpshiftDialog: HELPSHIFT_PANE_PRIORITY,
  appRoot: {
    // Since user profile shows anywhere, it's a bit more significant.
    userProfile: USER_PROFILE_PANE_PRIORITY,
    "userProfile|nav": USER_PROFILE_PANE_PRIORITY + 1,

    // Report Dialog is also global, in case we ever report a user, put it higher than the user profile.
    reportDialog: REPORT_PANE_PRIORITY,
    "reportDialog|nav": REPORT_PANE_PRIORITY + 1,

    // Within pages, each page can structure priority within itself since
    // the CampfirePageProvider should be taken into account to only affect the visible page.
    // NOTE: When you have UINav's inside of a UIPane, you typically need to define the
    // priority on the UIPane to be lower than the UINav, so that the back button first
    // effects the nav, until the nav is at its internal root, then the back button should interact
    // with the UIPane. Hence, why we use a pipe naming convention to communicate hierarchy.
    pages: {
      // User page is a bit special, since it uses a UINav at the root and there are no
      // navs within panes.
      user: {
        root: 10,
        "root|nav": 11,
      },
      notifications: {
        root: 10,
      },
      clubs: {
        root: 10,
        // Create Club Pane Stack
        createClubPane: DEFAULT_DIALOG_AND_PANE_PRIORITY + 1,
        inviteToClubPaneAfterCreate: DEFAULT_DIALOG_AND_PANE_PRIORITY + 1,
        "createClubPane|nav": DEFAULT_DIALOG_AND_PANE_PRIORITY + 2,
        "createClubPane|nav|location-picker-nav": DEFAULT_DIALOG_AND_PANE_PRIORITY + 3,

        // Header Pane Stack
        "headerPane|nav": DEFAULT_DIALOG_AND_PANE_PRIORITY + 1,
        "headerPane|nav|settings-nav": DEFAULT_DIALOG_AND_PANE_PRIORITY + 2,
        "headerPane|nav|settings-nav|location-picker-nav": DEFAULT_DIALOG_AND_PANE_PRIORITY + 3,
        "headerPane|nav|club-member-action-dialog": DEFAULT_DIALOG_AND_PANE_PRIORITY + 2,
        "headerPane|nav|text-channel-notif-nav": DEFAULT_DIALOG_AND_PANE_PRIORITY + 2,

        // Edit Event Stack
        "editEvent|nav": DEFAULT_DIALOG_AND_PANE_PRIORITY + 1,
        "editEvent|nav|location-picker-nav": DEFAULT_DIALOG_AND_PANE_PRIORITY + 2,
      },
      messages: {
        channelList: {
          root: 10,
        },
        channel: {
          "root|settings": DEFAULT_DIALOG_AND_PANE_PRIORITY + 1,
          // These are the UIPanes in the GroupDMSettings
          "root|panes": DEFAULT_DIALOG_AND_PANE_PRIORITY + 1,
        },
      },
      activityCenter: {
        root: 10,
      },
    },
  },
};

export default HARDWARE_BACK_EVENT_PRIORITY;
