/**
 * @generated SignedSource<<da97123ce59f4b99437740b38325dfcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppUpdateInput = {
  appVersion: string;
  jsVersion: string;
  platform: string;
};
export type checkForUpdates_Query$variables = {
  input?: AppUpdateInput | null;
};
export type checkForUpdates_Query$data = {
  readonly appUpdate: {
    readonly bundle: {
      readonly url: string;
      readonly version: string;
    } | null;
    readonly isUpToDate: boolean;
  };
};
export type checkForUpdates_Query = {
  response: checkForUpdates_Query$data;
  variables: checkForUpdates_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AppUpdateResponse",
    "kind": "LinkedField",
    "name": "appUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isUpToDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BundleInfo",
        "kind": "LinkedField",
        "name": "bundle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkForUpdates_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkForUpdates_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "58ae5483672b095c95082aa2e5c65afa",
    "id": null,
    "metadata": {},
    "name": "checkForUpdates_Query",
    "operationKind": "query",
    "text": "query checkForUpdates_Query(\n  $input: AppUpdateInput\n) {\n  appUpdate(input: $input) {\n    isUpToDate\n    bundle {\n      version\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e093f09e954b9384aff82c2e4fc909fd";

export default node;
