import logException from "common/analytics/exceptions";
import {
  PermissionStatus,
  CampfirePushNotifications,
} from "common/capacitor/plugins/campfire-push-notifications";

export const validator = (permissionStatus: PermissionStatus): boolean => {
  const permissionResult = permissionStatus.receive;

  try {
    return !!permissionResult;
  } catch (error) {
    logException(
      "validating PushNotifications.requestPermissions interop response",
      "validator",
      "requestPermission",
      error,
    );
    return false;
  }
};

export default CampfirePushNotifications.requestPermissions;
