import environment from "common/relay/relay-env";
import { commitLocalUpdate } from "react-relay";
import { StoreUpdater } from "relay-runtime";
import { RecordSourceProxy } from "relay-runtime/lib/store/RelayStoreTypes";

const createPgoBeacon = (
  dropId: string,
  beaconId: string,
  beaconData: CampfirePushEvent.PgoBeaconDataFromPushEvent,
): StoreUpdater => {
  return (store: RecordSourceProxy) => {
    try {
      // Get the mapObject by drop id
      const mapObjectInStore = store.get(dropId);

      if (mapObjectInStore) {
        // Get the corresponding pgoGym field
        const pgoGymInStore = mapObjectInStore.getLinkedRecord("pgoGym");

        if (pgoGymInStore) {
          const beacons = pgoGymInStore.getLinkedRecords("beacons") || [];

          // Create a new PGOBeacon entity
          const createdBeacon = store.create(beaconId, "PGOBeacon");

          createdBeacon.setValue(beaconId, "id");
          createdBeacon.setValue(dropId, "dropId");
          createdBeacon.setValue(beaconData.createdAt, "createdAt");
          createdBeacon.setValue(beaconData.expiresAt, "expiresAt");

          // Link a User record to the created beacon
          const createdBeaconUser = createdBeacon.getOrCreateLinkedRecord("user", "User");

          createdBeaconUser.setValue(beaconData.userId, "id");
          createdBeaconUser.setValue(beaconData.beaconCreator.isMyFriend, "isMyFriend");
          createdBeaconUser.setValue(beaconData.beaconCreator.avatarUrl, "avatarUrl");

          // Update the pgoGym.beacons
          const newBeacons = [...beacons, createdBeacon];

          pgoGymInStore.setLinkedRecords(newBeacons, "beacons");
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export default (
  dropId: string,
  beaconId: string,
  beaconData: CampfirePushEvent.PgoBeaconDataFromPushEvent,
): void => {
  commitLocalUpdate(environment, createPgoBeacon(dropId, beaconId, beaconData));
};
