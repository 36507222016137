/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

export type PermissionType = "geolocation" | "notifications" | "camera";

type Key = PermissionType;

export const PERMISSION_TYPE: Record<PermissionType, PermissionType> = {
  geolocation: "geolocation",
  notifications: "notifications",
  camera: "camera",
};

const DEFAULTS: Record<Key, boolean> = {
  geolocation: false,
  notifications: false,
  camera: false,
};

class PermissionStore extends BaseStore {
  constructor() {
    super("PermissionStore", DEFAULTS, false);
  }

  get(key: Key): any {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, value: any): GenericObject {
    return super.set(key, value);
  }
}

export default new PermissionStore();
