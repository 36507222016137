export const CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_NAME = "data-attr-main-fabs-exit";
export const CORE_NAVIGATION_RC_EXIT_DATA_ATTRIBUTE_NAME = "data-attr-reality-channel-fab-exit";
export const CORE_NAVIGATION_PRIORITY_DATA_ATTRIBUTE_NAME = "data-attr-priority";

export const CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_VALUE = "main-fabs-exit";
export const CORE_NAVIGATION_RC_EXIT_DATA_ATTRIBUTE_VALUE = "reality-channel-fab-exit";

export enum FabExitPriority {
  // Any page that is not active should get this.
  INACTIVE = 5,

  // The default root rendering should be a bit higher than an inactive page.
  INACTIVE_ROOT = 6,

  // When a page is active, it should be the one getting the fabs.
  ACTIVE = 10,
}
