import { invokeRemoteAsync } from "common/utils/webInterop/plugins";

import checkPermissions, {
  validator as checkPermissionsResponseValidator,
} from "./checkPermissions";
import getCurrentPosition, {
  validator as getCurrentPositionResponseValidator,
} from "./getCurrentPosition";

export default {
  checkPermissions: invokeRemoteAsync<typeof checkPermissions, "Geolocation">(
    checkPermissions,
    checkPermissionsResponseValidator,
    "Geolocation",
    "checkPermissions",
  ),
  getCurrentPosition: invokeRemoteAsync<typeof getCurrentPosition, "Geolocation">(
    getCurrentPosition,
    getCurrentPositionResponseValidator,
    "Geolocation",
    "getCurrentPosition",
  ),
};
