import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { TransferClubOwnershipMutation$data } from "__generated__/TransferClubOwnershipMutation.graphql";

type ReturnType = TransferClubOwnershipMutation$data["transferClubOwnership"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  clubId: string;
  userId: string;
};

const mutation = graphql`
  mutation TransferClubOwnershipMutation($input: TransferClubOwnershipInput!) {
    transferClubOwnership(input: $input) {
      club {
        id
        creator {
          id
        }
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "transferClubOwnership.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "transferClubOwnership.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "transferClubOwnership"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          clubId: input.clubId,
          userId: input.userId,
        },
        clubId: input.clubId,
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
