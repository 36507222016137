import { RouteComponentProps } from "react-router-dom";

import { getUrlForDmChannel } from "common/utils/routing/routeToDMChannel";
import { QUERY_PARAMS } from "constants/routes";

// DEPRECATED - Please use routeToDMMessageByMessageId
export const getUrlForDmMessageDeprecated = (
  channelId: string,
  pubnubTimetoken: string,
): ReactRouterv5HistoryPushParam => {
  const querySearchStr = `${QUERY_PARAMS.messages.channel.messageTimetoken.key}=${pubnubTimetoken}`;

  return {
    pathname: getUrlForDmChannel(channelId),
    search: `?${querySearchStr}`,
  };
};

export default (
  routeComponentProps: RouteComponentProps,
  channelId: string,
  pubnubTimetoken: string,
): void => {
  routeComponentProps.history.push(getUrlForDmMessageDeprecated(channelId, pubnubTimetoken));
};
