import classnames from "classnames";
import React, { RefObject } from "react";

import styles from "./styles.scss";

type Props = {
  className?: string;
  children: (anchorEl: HTMLDivElement) => React.ReactNode;
};

type State = {};

export default class Anchor extends React.PureComponent<Props, State> {
  anchorEl: RefObject<HTMLDivElement> = React.createRef();

  componentDidMount = (): void => {
    this.forceUpdate();
  };

  render = (): React.ReactNode => {
    const { className, children } = this.props;

    return (
      <div ref={this.anchorEl} className={classnames(styles.root, className)}>
        {this.anchorEl.current && children(this.anchorEl.current)}
      </div>
    );
  };
}
