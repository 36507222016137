import _ from "lodash";
import React from "react";

export type ExposedProps = {
  joinersInQueueByRadius: number | null;
  updateJoinersInQueueByRadius: (joinersInQueueByRadius: number) => void;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  joinersInQueueByRadius: number | null;
};

const INITIAL_CONTEXT: ExposedProps = {
  joinersInQueueByRadius: null,
  updateJoinersInQueueByRadius: _.noop,
};

export const TeamUpContext = React.createContext(INITIAL_CONTEXT);

export class TeamUpProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      joinersInQueueByRadius: null,
    };
  }

  updateJoinersInQueueByRadius = (joinersInQueueByRadius: number): void => {
    this.setState({ joinersInQueueByRadius });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <TeamUpContext.Provider
        value={{
          joinersInQueueByRadius: this.state.joinersInQueueByRadius,
          updateJoinersInQueueByRadius: this.updateJoinersInQueueByRadius,
        }}
      >
        {children}
      </TeamUpContext.Provider>
    );
  };
}

export const TeamUpConsumer = TeamUpContext.Consumer;
