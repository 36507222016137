import React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import UserPermanentBanMessageHandler from "common/components/UserPermanentBanMessageHandler";
import UserPgoBeaconUpdateMessageHandler from "common/components/UserPgoBeaconUpdateMessageHandler";
import UserSuspensionMessageHandler from "common/components/UserSuspensionMessageHandler";
import UserWarningMessageHandler from "common/components/UserWarningMessageHandler";

import { GlobalMessageHandlers_me$data as GlobalMessageHandlersMe } from "__generated__/GlobalMessageHandlers_me.graphql";

export type Props = {
  me: GlobalMessageHandlersMe;
};

type State = {};

class GlobalMessageHandlers extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { id, pubnubUserMessageChannelName } = this.props.me;

    return (
      <>
        <UserSuspensionMessageHandler channel={pubnubUserMessageChannelName} userId={id} />
        <UserWarningMessageHandler channel={pubnubUserMessageChannelName} userId={id} />
        <UserPermanentBanMessageHandler channel={pubnubUserMessageChannelName} userId={id} />
        <UserPgoBeaconUpdateMessageHandler channel={pubnubUserMessageChannelName} />
      </>
    );
  };
}

const FragmentContainer = createFragmentContainer(GlobalMessageHandlers, {
  me: graphql`
    fragment GlobalMessageHandlers_me on User {
      id
      pubnubUserMessageChannelName
    }
  `,
});

export default FragmentContainer;
