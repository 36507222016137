/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

type Key =
  | "initialLaunchBirthday"
  | "acceptedTOS"
  | "showAddFriendConfirmation"
  | "seenPrivacyPolicy"
  | "timeAppFirstLoaded"
  | "seenOfficialGroupWelcome"
  | "seenLocationShareDisclaimer"
  | "hidePrivacyOnMapDisclaimer"
  | "showResetAccountDataSuccess"
  | "accountResetData";

const DEFAULTS: Record<Key, string | number | boolean | Record<string, unknown> | void | null> = {
  initialLaunchBirthday: null,
  acceptedTOS: false,
  showAddFriendConfirmation: true,
  seenPrivacyPolicy: false,
  timeAppFirstLoaded: Date.now(),
  seenOfficialGroupWelcome: false,
  seenLocationShareDisclaimer: false,
  hidePrivacyOnMapDisclaimer: false,
  showResetAccountDataSuccess: false,
  accountResetData: {
    isAbusive: false,
  },
};

class OnboardingStore extends BaseStore {
  constructor() {
    super("OnboardingStore", DEFAULTS, false);
  }

  get(key: Key): any {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, value: any): GenericObject {
    return super.set(key, value);
  }
}

export default new OnboardingStore();
