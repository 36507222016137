/**
 * @generated SignedSource<<a01ca6869b6d39eafcc90dd8925eb641>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MapAndActivities_realityChannel$data = {
  readonly iconURL: string;
  readonly id: string;
  readonly metadata: {
    readonly game: string;
    readonly googleMapsPlatformMapId: string | null;
    readonly isFirstParty: boolean;
    readonly splashImageUrl: string | null;
  } | null;
  readonly name: string;
  readonly sources: ReadonlyArray<{
    readonly dropTypes: ReadonlyArray<string>;
    readonly name: string;
  }>;
  readonly welcomeBanner: {
    readonly bodyMd: string;
    readonly imgURL: string;
    readonly location: {
      readonly latitude: number | null;
      readonly longitude: number | null;
    } | null;
    readonly title: string;
  } | null;
  readonly " $fragmentType": "MapAndActivities_realityChannel";
};
export type MapAndActivities_realityChannel$key = {
  readonly " $data"?: MapAndActivities_realityChannel$data;
  readonly " $fragmentSpreads": FragmentRefs<"MapAndActivities_realityChannel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MapAndActivities_realityChannel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealityChannelSource",
      "kind": "LinkedField",
      "name": "sources",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dropTypes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealityChannelMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFirstParty",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "game",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleMapsPlatformMapId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "splashImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealityChannelBanner",
      "kind": "LinkedField",
      "name": "welcomeBanner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bodyMd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imgURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RealityChannel",
  "abstractKey": null
};
})();

(node as any).hash = "243e96cfe1d8e29ec84b6eb48bed8bf6";

export default node;
