import React from "react";

export type ExposedProps = {
  hasFeatureFlag: (flag: string) => boolean;
};

type Props = {
  featureFlags: Record<string, boolean>;
  children: React.ReactNode;
};

type State = {};

// By default, feature flags should be off (false)
const INITIAL_CONTEXT: ExposedProps = {
  hasFeatureFlag: () => false,
};

export const FeatureFlagContext = React.createContext(INITIAL_CONTEXT);

export class FeatureFlagProvider extends React.Component<Props, State> {
  hasFeatureFlag = (flag: string): boolean => {
    return !!this.props.featureFlags[flag];
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <FeatureFlagContext.Provider
        value={{
          hasFeatureFlag: this.hasFeatureFlag,
        }}
      >
        {children}
      </FeatureFlagContext.Provider>
    );
  };
}

export const FeatureFlagConsumer = FeatureFlagContext.Consumer;
