/**
 * @generated SignedSource<<49347d7db4b45034c26e62e51c01b57b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Wayfarer_page_Query$variables = {};
export type Wayfarer_page_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"WayfarerPageMainContent_me">;
  };
};
export type Wayfarer_page_Query = {
  response: Wayfarer_page_Query$data;
  variables: Wayfarer_page_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Wayfarer_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WayfarerPageMainContent_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Wayfarer_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4486821a812e0c7e7a8b54fe89f6e18b",
    "id": null,
    "metadata": {},
    "name": "Wayfarer_page_Query",
    "operationKind": "query",
    "text": "query Wayfarer_page_Query {\n  me {\n    ...WayfarerPageMainContent_me\n    id\n  }\n}\n\nfragment WayfarerPageMainContent_me on User {\n  id\n}\n"
  }
};

(node as any).hash = "17e6f3e2964d8accfce56039910fbb5c";

export default node;
