import logException from "common/analytics/exceptions";
import PermissionStore, { PERMISSION_TYPE } from "common/stores/PermissionStore";
import StubbedPlugins from "common/utils/webInterop/plugins";

const { Camera } = StubbedPlugins;

type PermissionResultState = "granted" | "denied";

const promptForCameraAccess = async (): Promise<void> => {
  PermissionStore.set(PERMISSION_TYPE.camera, true);

  try {
    await Camera.requestPermissions({
      permissions: ["camera"],
    });
  } catch (error) {
    logException("Camera.requestPermissions", "promptForCameraAccess", "camera", error);
  }
};

export const checkCameraPermissions = async (): Promise<PermissionResultState> => {
  try {
    const permissionStatus = await Camera.checkPermissions();

    if (permissionStatus.camera === "granted") {
      return "granted";
    }

    return "denied";
  } catch (error) {
    return "denied";
  }
};

export const requestCameraPermission = async (): Promise<PermissionResultState> => {
  const hasPromptedBefore = PermissionStore.get(PERMISSION_TYPE.camera);

  // Check if we have enabled the camera permission already.
  const permissionResult = await checkCameraPermissions();

  // If so, no need to prompt
  if (permissionResult === "granted") {
    return "granted";
  }

  if (!hasPromptedBefore) {
    await promptForCameraAccess();

    const permissionResultUpdated = await checkCameraPermissions();

    if (permissionResultUpdated === "granted") {
      return "granted";
    }

    return "denied";
  }

  return "denied";
};
