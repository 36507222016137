import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { TrackClientActionMutation$data } from "__generated__/TrackClientActionMutation.graphql";

export type ActionType =
  | "OpenTextChannel"
  | "OpenDmOrGroupDm"
  | "OpenFactionRegionChat"
  | "Referred"
  | "CreatePgoBeacon"
  | "MapObjectClick"
  | "ShareMapObject"
  | "OpenMapObjectSharePane"
  | "ExternalShareMapObject"
  | "GetMapObjectDirections"
  | "PgoMapInteraction"
  | "IngMapInteraction"
  | "Logout"
  | "SharedLoginLogout"
  | "RegisterForNianticHomeChannel"
  | "ChangeRealityChannel"
  | "ViewIngressPostDetails"
  | "IngressExperienceRestart"
  | "IngressPostLinkClick"
  | "OpenNearbyDrawer"
  | "ToggleNearbyDrawerTab"
  | "NearbyDrawerMapObjectClick"
  | "CreatePost"
  | "OpenPost"
  | "LikePost"
  | "LoadMapViewport"
  | "LoadRealityChannelMapViewport"
  | "JoinFlareChat"
  | "LeaveFlareChat"
  | "SendFlareChatMessage"
  | "OpenFlareChatPreviewCard"
  | "ClickNearbyActivityFromClubs"
  | "ClickMeetupFeed"
  | "PgoGymDeepLinkClick"
  | "OnboardingEvent"
  | "OpenPgoRaidMatchmakingDrawer"
  | "SelectPgoRaidToHost"
  | "LoadPgoRaidQueue"
  | "LoadRealityChannelMapViewport"
  | "LeavePgoRaidQueue"
  | "PassPgoRaidMatch"
  | "LeavePgoRaidMatch"
  | "JoinPgoRaidMatch"
  | "JoinPgoRaidChat"
  | "AddRaidGroupFriend"
  | "PgoRaidGoToGameClick"
  | "NbaAllWorldDeepLinkClick"
  | "ClickPeridotHatchDeepLink"
  | "OnboardingCheckIncludeMeInFriendRecommendations"
  | "OnboardingAddFriend"
  | "TeamUpV2StartFunnel"
  | "TeamUpV2OpenDrawer"
  | "TeamUpV2SelectPgoRaidToHost"
  | "TeamUpV2HostLoadDistanceValidation"
  | "TeamUpV2HostLoadReadyToHost"
  | "TeamUpV2HostDistanceValidationClickTravelCloser"
  | "TeamUpV2HostDistanceValidationClickUseRemotePass"
  | "TeamUpV2HostClickStartHosting"
  | "TeamUpV2HostLoadQueue"
  | "TeamUpV2HostLoadAcceptAllRequestScreen"
  | "TeamUpV2HostClickAcceptAllRequest"
  | "TeamUpV2HostCopyTrainerNames"
  | "TeamUpV2HostTutorial"
  | "TeamUpV2JoinerLoadFindRaidGroupQueue"
  | "TeamUpV2JoinerLoadRaidFound"
  | "TeamUpV2JoinerSendRequestToJoin"
  | "TeamUpV2JoinerPassMatch"
  | "TeamUpV2JoinerQueueWaitingForOtherJoiners"
  | "TeamUpV2JoinerQueueWaitingForHostAccept"
  | "TeamUpV2JoinRaidChat"
  | "TeamUpV2DeepLink"
  | "TeamUpV2LeaveRaidChat"
  | "TeamUpV2LeaveFunnel"
  | "TeamUpV2NotEligibleClick"
  | "TeamUpV2JoinerHelpClick"
  | "DeleteEvent"
  | "EditEvent"
  | "JoinEvent"
  | "ActivityCenterView";

type ReturnType = TrackClientActionMutation$data["trackClientAction"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  actionType: ActionType;
  jsonPayload: GenericObject;
};

const mutation = graphql`
  mutation TrackClientActionMutation($input: TrackClientActionInput!) {
    trackClientAction(input: $input) {
      success
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "trackClientAction.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "trackClientAction.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "trackClientAction"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      cacheConfig: {
        metadata: {
          disableMutationInvalidation: true,
        },
      },
      variables: {
        input: {
          actionType: input.actionType,
          jsonPayload: JSON.stringify(input.jsonPayload),
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
