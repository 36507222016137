// Core Views
export const USER_INFO_ROUTE = "user";
export const MESSAGES_ROUTE = "messages";
export const MESSAGES_CHANNEL_ROUTE = "channel";
export const MESSAGES_REQUESTS_ROUTE = "requests";
export const MAP_ROUTE = "map";
export const ACTIVITY_CENTER_ROUTE = "activity-center";
export const ACTIVITY_CENTER_MAP_ROUTE = "map";
export const CLUBS_ROUTE = "clubs";
export const CLUBS_TEXT_CHANNEL_ROUTE = "textChannel";
export const CLUBS_FEED_ROUTE = "feed";
export const NOTIFICATIONS_ROUTE = "notifications";
export const UNKNOWN_ROUTE = "404";
export const INTERNAL_ROUTE = "internal";
export const EXPERIENCE_ROUTE = "experience";
export const MEDIA_SHARE_EXPERIENCE_ROUTE = "media-share";
export const MAGELLAN_EXPERIENCE_ROUTE = "mhn";
export const MAGELLAN_EXPERIENCE_PARTY_ROUTE = "party";
export const EXTERNAL_ROUTE = "external";
export const INTEGRATION_ROUTE = "integrations";
export const DISCORD_INTEGRATION_ROUTE = "discord";
export const WAYFARER_ROUTE = "wayfarer";

// Path Params
// Activity Center Path Params
export const REALITY_CHANNEL_ID_PATH_PARAM = "realityChannelId";

// Query Params supported by route

// App wide query param to show create club flow
const INTEROP_TYPE_QUERY_PARAM = "interopType";
const EMBEDDED_CLIENT_INFO = "embeddedClientInfo";
const SHOW_CREATE_CLUB_QUERY_PARAM = "showCreateClub";
const SESSION_TOKEN_QUERY_PARAM = "token";
const STATE_QUERY_PARAM = "state";
const CLUB_ID_QUERY_PARAM = "clubId";
const CLUB_INVITE_QUERY_PARAM = "hasInvite";
const CLUB_EVENT_ID_QUERY_PARAM = "eventId";
const CLUB_EVENT_SHOW_CHECK_IN_PARAM = "showCheckIn";
const CLUB_EVENT_SHOW_MEETUP_DETAILS_PARAM = "showMeetupDetails";
const MAP_OBJECT_QUERY_PARAM = "mapObjId";
const MAP_ACTION_QUERY_PARAM = "action";
const MAP_ACTION_DATA_QUERY_PARAM = "actionData";
const MAP_ZOOM_LEVEL_QUERY_PARAM = "z";
const MAP_LATITUDE_QUERY_PARAM = "lat";
const MAP_LONGITUDE_QUERY_PARAM = "lng";
const PUBNUB_MESSAGE_TIMETOKEN = "messageTimetoken";
const CHATV2_MESSAGE_ID = "messageId";
const IS_SUSPENDED_QUERY_PARAM = "isSuspended";
const IS_PERMANENT_BANNED_QUERY_PARAM = "isPermanentBan";
const SHOW_CLOSED_BETA_QUERY_PARAM = "showClosedBetaMessage";
const SHARE_FILE_PATH_QUERY_PARAM = "shareFilePath";
const EMBEDDED_PGO_INTENT = "pgoIntent";
const THEME_QUERY_PARAM = "theme";
const DISCORD_AUTH_CODE_QUERY_PARAM = "code";

type QueryParamDefinition = {
  key: string;
  type?: number | string | boolean;
};

type QueryParamConfig = { [key: string]: QueryParamDefinition | QueryParamConfig };

export enum MapAction {
  SHOW_CREATE_POST = "showCreatePost",
  SELECT_MAP_OBJECT = "selectMapObject",
  SHOW_ACTIVITY_FEED = "showActivityFeed",
  SHOW_POI_CHAT = "showPoiChat",
  SELECT_REALITY_CHANNEL = "selectRealityChannel",
  SHOW_TEAM_UP = "showTeamUp",
  VIEW_USER = "viewUser",
}

// This QUERY_PARAM object helps us manage what query params are used across the app.
// We also want to know what the type of that query param is, so this makes it very easy
// to remind us as the app gets larger.
// ===== HOW TO ADD A NEW QUERY PARAM =====
// 1. Add a query param string above.
// 2. Add a query param config definition for the route you want to support to the type below.
// 3. Add the query param to the actual exported config object.
// 4. Access the query param like QUERY_PARAMS.root.showCreateClub.key.
type SupportedQueryParamsByRoute = { [key: string]: QueryParamConfig } & {
  root: {
    interopType: {
      key: "interopType";
      type?: string;
    };
    embeddedClientInfo: {
      key: "embeddedClientInfo";
      type?: string;
    };
    // Session token is sent as "token" when google oauth redirects back.
    sessionToken: {
      key: "token";
      type?: string;
    };
    // CSRF token is encoded inside of "state".
    state: {
      key: "state";
      type?: string;
    };
    showCreateClub: {
      key: "showCreateClub";
      type?: boolean;
    };
    isSuspended: {
      key: "isSuspended";
      type?: string;
    };
    isPermanentBan: {
      key: "isPermanentBan";
      type?: string;
    };
    showClosedBeta: {
      key: "showClosedBetaMessage";
      type?: boolean;
    };
    embeddedPgoIntent: {
      key: "pgoIntent";
      type?: string;
    };
    theme: {
      key: "theme";
      type?: string;
    };
  };
  clubs: {
    clubId: {
      key: "clubId";
      type?: string;
      feed: {
        eventId: {
          key: "eventId";
          type?: string;
        };
        showCheckIn: {
          key: "showCheckIn";
          type?: boolean;
        };
        showMeetupDetails: {
          key: "showMeetupDetails";
          type?: boolean;
        };
      };
    };
    hasInvite: {
      key: "hasInvite";
      type?: boolean;
    };
    textChannel: {
      messageTimetoken: {
        key: "messageTimetoken";
        type?: string;
      };
      messageId: {
        key: "messageId";
        type?: string;
      };
    };
  };
  map: {
    mapObjId: {
      key: "mapObjId";
      type?: string;
    };
    action: {
      key: "action";
      type?: MapAction;
    };
    actionData: {
      key: "actionData";
      type?: string;
    };
    zoomLevel: {
      key: "z";
      type?: string;
    };
    lat: {
      key: "lat";
      type?: string;
    };
    lng: {
      key: "lng";
      type?: string;
    };
  };
  messages: {
    channel: {
      messageTimetoken: {
        key: "messageTimetoken";
        type?: string;
      };
      messageId: {
        key: "messageId";
        type?: string;
      };
    };
  };
  experience: {
    ["media-share"]: {
      shareFilePath: {
        key: "shareFilePath";
        type?: string;
      };
    };
  };
  external: {
    ["integrations"]: {
      ["discord"]: {
        codePath: {
          key: "code";
          type?: string;
        };
      };
    };
  };
};

export const QUERY_PARAMS: SupportedQueryParamsByRoute = {
  root: {
    interopType: {
      key: INTEROP_TYPE_QUERY_PARAM,
    },
    embeddedClientInfo: {
      key: EMBEDDED_CLIENT_INFO,
    },
    sessionToken: {
      key: SESSION_TOKEN_QUERY_PARAM,
    },
    state: {
      key: STATE_QUERY_PARAM,
    },
    // TODO: Deprecate
    showCreateClub: {
      key: SHOW_CREATE_CLUB_QUERY_PARAM,
    },
    isSuspended: {
      key: IS_SUSPENDED_QUERY_PARAM,
    },
    isPermanentBan: {
      key: IS_PERMANENT_BANNED_QUERY_PARAM,
    },
    showClosedBeta: {
      key: SHOW_CLOSED_BETA_QUERY_PARAM,
    },
    embeddedPgoIntent: {
      key: EMBEDDED_PGO_INTENT,
    },
    theme: {
      key: THEME_QUERY_PARAM,
    },
  },
  [USER_INFO_ROUTE]: {},
  [MESSAGES_ROUTE]: {
    [MESSAGES_CHANNEL_ROUTE]: {
      messageTimetoken: {
        key: PUBNUB_MESSAGE_TIMETOKEN,
      },
      messageId: {
        key: CHATV2_MESSAGE_ID,
      },
    },
  },
  [MAP_ROUTE]: {
    mapObjId: {
      key: MAP_OBJECT_QUERY_PARAM,
    },
    zoomLevel: {
      key: MAP_ZOOM_LEVEL_QUERY_PARAM,
    },
    lat: {
      key: MAP_LATITUDE_QUERY_PARAM,
    },
    lng: {
      key: MAP_LONGITUDE_QUERY_PARAM,
    },
    action: {
      key: MAP_ACTION_QUERY_PARAM,
    },
    actionData: {
      key: MAP_ACTION_DATA_QUERY_PARAM,
    },
  },
  [CLUBS_ROUTE]: {
    clubId: {
      key: CLUB_ID_QUERY_PARAM,
      [CLUBS_FEED_ROUTE]: {
        eventId: {
          key: CLUB_EVENT_ID_QUERY_PARAM,
        },
        showCheckIn: {
          key: CLUB_EVENT_SHOW_CHECK_IN_PARAM,
        },
        showMeetupDetails: {
          key: CLUB_EVENT_SHOW_MEETUP_DETAILS_PARAM,
        },
      },
    },
    hasInvite: {
      key: CLUB_INVITE_QUERY_PARAM,
    },
    [CLUBS_TEXT_CHANNEL_ROUTE]: {
      messageTimetoken: {
        key: PUBNUB_MESSAGE_TIMETOKEN,
      },
      messageId: {
        key: CHATV2_MESSAGE_ID,
      },
    },
  },
  [NOTIFICATIONS_ROUTE]: {},
  [EXPERIENCE_ROUTE]: {
    [MEDIA_SHARE_EXPERIENCE_ROUTE]: {
      shareFilePath: {
        key: SHARE_FILE_PATH_QUERY_PARAM,
      },
    },
  },
  [EXTERNAL_ROUTE]: {
    [INTEGRATION_ROUTE]: {
      [DISCORD_INTEGRATION_ROUTE]: {
        codePath: {
          key: DISCORD_AUTH_CODE_QUERY_PARAM,
        },
      },
    },
  },
};

// Tab Bar related
export type TabName = "user" | "map" | "clubs" | "notifications" | "messages";

// Floating Tab Bar related, app router v2
export type TabNameRouterV2 = "activity-center" | "user" | "clubs" | "notifications" | "messages";

export const CORE_ROUTES: TabName[] = [
  MAP_ROUTE,
  MESSAGES_ROUTE,
  CLUBS_ROUTE,
  NOTIFICATIONS_ROUTE,
  USER_INFO_ROUTE,
];
