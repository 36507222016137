import logException from "common/analytics/exceptions";
import { RequestCurrentRegionResponse } from "types/embedPluginInterface/regionalChat";

export const validator = (currentRegionResponse: RequestCurrentRegionResponse): boolean => {
  try {
    const hasResponseBody = !!currentRegionResponse.regionInfo;

    if (hasResponseBody) {
      const hasCrossFactionRegionHash = !!currentRegionResponse.regionInfo.crossFactionRegionHash;
      const hasFactionRegionHash = !!currentRegionResponse.regionInfo.factionRegionHash;
      const hasRegionName = !!currentRegionResponse.regionInfo.regionName;

      return (hasCrossFactionRegionHash || hasFactionRegionHash) && hasRegionName;
    }

    return false;
  } catch (error) {
    logException("validating requestCurrentRegion", "validator", "requestCurrentRegion", error);
    return false;
  }
};

// const debugResponse = {
//   regionalChatResponse: {
//     regionInfo: {
//       crossFactionRegionHash: "test",
//       factionRegionHash: "adsfa",
//       regionName: "Test",
//     },
//     statusInfo: {
//       status: "valid" as RequestCurrentRegionResponseStatus,
//       reason: "",
//     },
//   },
// };

const errorMessage = "Custom cap plugin RegionalChat.requestCurrentRegion not implemented!";
// eslint-disable-next-line max-len
const defaultRequestCurrentRegion = (): Promise<RequestCurrentRegionResponse> =>
  Promise.reject(errorMessage);

export default defaultRequestCurrentRegion;
