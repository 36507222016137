import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import { replaceRouteToActivityCenter } from "common/utils/routing/routeToActivityCenter";
import { replaceRouteToActivityCenterMap } from "common/utils/routing/routeToActivityCenterMap";
import { ACTIVITY_CENTER_MAP_ROUTE, ACTIVITY_CENTER_ROUTE } from "constants/routes";

type PathParams = {
  // Defined in AppRouterV2.
  realityChannelId?: string;
};

export type Props = {
  // When not active, the url changes will not be taken into account.
  // Please disable this when the view is not interactive, to prevent other pages from getting
  // unexpected side effects.
  isActive: boolean;
  selectedRealityChannelId: string;
  changeGlobalRealityChannel: (realityChannelId: string) => void;
};

/**
 * This component watches the url for changes to the path param realityChannelId and
 * updates the global RC state accordingly.
 *
 *
 */
const MapAndActivitiesRouteStateSynchronizer: FunctionComponent<Props> = (
  props: Props,
): React.ReactElement | null => {
  const { isActive } = props;
  const pathParams = useParams<PathParams>();
  const history = useHistory();
  const location = useLocation();
  // Support for bi-directional state + url synchronization.
  // AKA, you change the url, the state changes. You change the state, the url changes.
  // CRITICAL - Remove this and watch an infinite loop happen.
  // REPRO:  start at /rc_id_a, change the url to /rc_id_b, and press enter.
  // As you can imagine, its super easy to cause an infinite loop, which is why we handle this by:
  // 1. Only run the sync logic on changes after first mount (since useEffect runs on mount once no matter what)
  // 2. Ensure we only trigger changes when differences are detected between url and state rc id.
  const [listenForChanges, setListenForChanges] = useState(false);

  const syncSelectedRealityChannelWithUrl = (realityChannelId: string): void => {
    props.changeGlobalRealityChannel(realityChannelId);
  };

  /**
   * Syncs the selected reality channel id with the url when it changes
   */
  const syncStateWithUrlChange = () => {
    if (!isActive) {
      return;
    }

    if (pathParams.realityChannelId) {
      if (props.selectedRealityChannelId !== pathParams.realityChannelId) {
        syncSelectedRealityChannelWithUrl(pathParams.realityChannelId);
      }
    }
  };

  /**
   * Syncs the url with a change in the selected reality channel
   */
  const syncUrlWithStateChange = () => {
    if (!isActive) {
      return;
    }

    const isOnActivityCenterMap = history.location.pathname.endsWith(
      `/${ACTIVITY_CENTER_MAP_ROUTE}`,
    );
    const isOnActivityCenter =
      !isOnActivityCenterMap && history.location.pathname.startsWith(`/${ACTIVITY_CENTER_ROUTE}`);

    // If we have a selected RC id, and we also have in the url a path param that probably needs
    // to be updated, try to update it.
    if (props.selectedRealityChannelId && pathParams.realityChannelId) {
      // Check if the new selected RC is different from the one in the url.
      if (props.selectedRealityChannelId !== pathParams.realityChannelId) {
        // Clearly the url and state need to be synced
        const nextRcId = props.selectedRealityChannelId;

        // Check if we are on the activity center or the map.
        // The path needs to be updated based on the possible routes on this page.
        if (isOnActivityCenter) {
          replaceRouteToActivityCenter(history, nextRcId);
        } else if (isOnActivityCenterMap) {
          replaceRouteToActivityCenterMap(history, location, nextRcId, true);
        }
      }
    }
  };

  // On mount, check change the current RC to the one in the url.
  const componentDidMount = (): void => {
    setListenForChanges(true);

    if (isActive && pathParams.realityChannelId) {
      setTimeout(syncStateWithUrlChange);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(componentDidMount, []);

  // Active only after mount, and starts watching for url changes so that state can be updated.
  useEffect(() => {
    if (listenForChanges) {
      syncStateWithUrlChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathParams.realityChannelId]);

  // Active only after mount, and starts watching for state changes so that url can be updated.
  useEffect(() => {
    if (listenForChanges) {
      syncUrlWithStateChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRealityChannelId]);

  return null;
};

export default MapAndActivitiesRouteStateSynchronizer;
