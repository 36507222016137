/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { StandaloneSettingsConsumer, ExposedProps } from "providers/StandaloneSettingsProvider";

export type WithStandaloneSettingsProps = {
  standaloneSettings: {
    exposedFeatureFlags: ExposedProps["exposedFeatureFlags"];
    minFrontendVersion: ExposedProps["minFrontendVersion"];
  };
};

// eslint-disable-next-line max-len
export default <P extends WithStandaloneSettingsProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithStandaloneSettingsProps>): React.ReactElement => (
    <StandaloneSettingsConsumer>
      {({ exposedFeatureFlags, minFrontendVersion }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          standaloneSettings={{
            exposedFeatureFlags,
            minFrontendVersion,
          }}
          {...props}
        />
      )}
    </StandaloneSettingsConsumer>
  );
};
