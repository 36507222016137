import { BundleInfo } from "@capgo/capacitor-updater";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withToast, { WithToastProps } from "providers/ToastProvider/withToast";
import withVersionInfo, {
  WithVersionInfoProps,
} from "providers/VersionInfoProvider/withVersionInfo";

import logException from "common/analytics/exceptions";
import { isAndroid } from "common/capacitor/helpers";
import { applyUpdateAndCleanupState } from "common/utils/appUpdate/applyBundle";
import { deleteAllUnusedBundlesExcept } from "common/utils/appUpdate/bundleCleanup";
import {
  checkBundleAlreadyExistsByVersion,
  getExistingBundleByVersion,
} from "common/utils/appUpdate/bundleStatus";
import {
  AppUpdateData,
  checkForUpdates as checkForJSUpdates,
} from "common/utils/appUpdate/checkForUpdates";
import { downloadJSBundle } from "common/utils/appUpdate/downloadBundle";
import {
  getNextBundleIdToApplyOnColdStart,
  setNextBundleToApplyOnColdStart,
} from "common/utils/appUpdate/shouldApplyBundle";
import { CAMPFIRE_JS_VERSION } from "common/utils/campfireApp";
import { delayResolve } from "common/utils/delay";

import ConfirmationDialogView from "common/components/ConfirmationDialogView";
import UIDialog from "common/components/UIDialog";

export type ExposedProps = {
  showAppUpdateDialog: () => void;
  hasValidBundleForVersion: (jsVersion: string) => Promise<boolean>;
  checkForUpdatesAndDownloadSilent: () => Promise<BundleInfo | void>;
  setNextBundleToApplyOnColdStart: (bundleInfo: BundleInfo) => void;
  applyBundleImmediately: (bundleId: string) => void;
  cleanupUnusedBundles: () => Promise<void>;
};

type Props = WithVersionInfoProps &
  WithToastProps &
  WithTranslation & {
    children: React.ReactNode;
  };

type UpdateErrorType = "checking-for-updates" | null;

type State = {
  appUpdateDialogOpen: boolean;
  applyUpdateDialogOpen: boolean;
  isCheckingForUpdates: boolean;
  updateErrorType: UpdateErrorType;
  isUpToDate: boolean;
  isDownloadingManualUpdate: boolean;
};

type UpdateMessagingConfig = {
  title: string;
  description?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onConfirm?: () => Promise<void>;
  onCancel?: () => void;
};

const INITIAL_CONTEXT: ExposedProps = {
  showAppUpdateDialog: () => undefined,
  hasValidBundleForVersion: () => Promise.resolve(false),
  checkForUpdatesAndDownloadSilent: () =>
    Promise.reject(
      new Error(
        "Default implementation of checkForUpdatesAndDownloadSilent called! Your invocation probably isn't within an AppUpdateProvider",
      ),
    ),
  setNextBundleToApplyOnColdStart: () => undefined,
  applyBundleImmediately: () => undefined,
  cleanupUnusedBundles: () => Promise.resolve(undefined),
};

export const AppUpdateContext = React.createContext(INITIAL_CONTEXT);

const ARBITRARY_CHECKING_FOR_UPDATE_DELAY_MS = 500;
const ARBITRARY_UPDATE_READY_DELAY_MS = 500;

class AppUpdateProvider extends React.Component<Props, State> {
  // There are kinda 2 values tracked related to bundle ids that could be installed.
  // 1. AppUpdateStore "nextScheduledBundleIdToApply"
  // 2. this.lastDownloadedBundleInfo
  // You may wonder why:
  // this.lastDownloadedBundleInfo stores the last downloaded bundle info from the interaction
  // from the UI in this Provider. This update downloaded manually is only applied by
  // an explicit action by a user.
  // This differs from the AppUpdateStore's nextScheduledBundleIdToApply since that deals with a
  // bundle that will be applied later.
  lastDownloadedBundleInfo: BundleInfo | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      appUpdateDialogOpen: false,
      applyUpdateDialogOpen: false,
      isCheckingForUpdates: false,
      isUpToDate: false,
      isDownloadingManualUpdate: false,
      updateErrorType: null,
    };
  }

  setDownloadingManualUpdate = (): void => {
    this.setState({ isDownloadingManualUpdate: true });
  };

  clearDownloadingManualUpdate = (): void => {
    this.setState({ isDownloadingManualUpdate: false });
  };

  showAppUpdateDialog = (): void => {
    this.setState({ appUpdateDialogOpen: true });
    this.checkForUpdates();
  };

  hideAppUpdateDialog = (): void => {
    this.setState({ appUpdateDialogOpen: false, isCheckingForUpdates: false });
  };

  showApplyUpdateDialog = (): void => {
    this.setState({ applyUpdateDialogOpen: true });
  };

  hideApplyUpdateDialog = (): void => {
    // Clear the last downloaded bundle info, since the user didn't want to install the update.
    this.lastDownloadedBundleInfo = null;
    this.setState({ applyUpdateDialogOpen: false });
  };

  jsIsUpToDate = (appUpdateData: AppUpdateData): boolean => {
    const { isUpToDate, bundle } = appUpdateData;
    const bundleWeShouldInstall = bundle;

    if (bundleWeShouldInstall) {
      const versionToUpdateToIsSameAsCurrentJSVersion =
        bundleWeShouldInstall.version === CAMPFIRE_JS_VERSION;

      return isUpToDate || versionToUpdateToIsSameAsCurrentJSVersion;
    }

    return isUpToDate;
  };

  /**
   * Sets the next bundle that will get applied on the subsequent cold boot
   */
  setNextBundleToApplyOnColdStart = (bundleInfo: BundleInfo): void => {
    setNextBundleToApplyOnColdStart(bundleInfo);
  };

  /**
   * Gets a bundle that has already been downloaded and is ready to be applied.
   * Bundles that have failed to download or are errored are not detected as downloaded and
   * will return as false from this method.
   */
  getExistingDownloadedBundleForVersion = async (jsVersion: string): Promise<BundleInfo | void> => {
    return getExistingBundleByVersion(jsVersion);
  };

  /**
   * Checks if a bundle has already been downloaded and is ready to be applied.
   * Bundles that have failed to download or are errored are not detected as downloaded and
   * will return as false from this method.
   */
  hasValidBundleForVersion = async (jsVersion: string): Promise<boolean> => {
    return checkBundleAlreadyExistsByVersion(jsVersion);
  };

  /**
   * For use outside of this provider. This should be the exposed method.
   */
  checkForUpdatesSilent = async (): Promise<AppUpdateData> => {
    const installedAppVersion = this.props.versionInfo.appVersion;
    const currentJSVersion = CAMPFIRE_JS_VERSION as string;

    return checkForJSUpdates(installedAppVersion, currentJSVersion);
  };

  /**
   * Checks for updates and downloads a bundle if necessary.
   *
   * Will return a BundleInfo if there is a bundle that needs to be applied.
   * If a bundle is already scheduled for update, or if there is no update needed
   * this method will NOT return a BundleInfo.
   *
   * This helps us discern when we need to schedule a bundle to be applied, since it may
   * already have been scheduled.
   */
  checkForUpdatesAndDownloadSilent = async (): Promise<BundleInfo | void> => {
    try {
      // Check for updates.
      const appUpdateData = await this.checkForUpdatesSilent();
      const { bundle } = appUpdateData;
      const bundleWeShouldInstall = bundle;

      // If the BE tells us the version to update to is the same as the current js bundle version,
      // do not actually perform the update. This is sorta a redundancy in case the BE returns
      // incorrect information.
      if (this.jsIsUpToDate(appUpdateData)) {
        return;
      }

      // If no bundle was returned to install, exit early.
      if (!bundleWeShouldInstall) {
        return;
      }

      // Check in the CapacitorUpdater storage if we already have downloaded this version.
      // Don't trigger ANOTHER download of a bundle we already have.
      // We only have versions to match against, so that will have to be how we check if we
      // have already downloaded a bundle.
      const bundleAlreadyDownloaded = await this.getExistingDownloadedBundleForVersion(
        bundleWeShouldInstall.version,
      );

      // Exit early if the bundle is already downloaded.
      // But only return the bundle if it is not scheduled for update already.
      if (bundleAlreadyDownloaded) {
        const nextBundleIdScheduledToBeApplied = getNextBundleIdToApplyOnColdStart();

        // The bundle that was already downloaded is scheduled to be applied already.
        // Don't return it.
        if (bundleAlreadyDownloaded.id === nextBundleIdScheduledToBeApplied) {
          return;
        }

        // TODO: Man, this ESLint rule really shouldn't be here since it doesnt know about types.
        // eslint-disable-next-line consistent-return
        return bundleAlreadyDownloaded;
      }

      // Otherwise, we probably need to download it.
      // If we have updates, download them.
      if (!appUpdateData.isUpToDate) {
        const bundleInfo = await downloadJSBundle(
          bundleWeShouldInstall.version,
          bundleWeShouldInstall.url,
        );

        // TODO: Man, this ESLint rule really shouldn't be here since it doesnt know about types.
        // eslint-disable-next-line consistent-return
        return bundleInfo;
      }
    } catch (error) {
      logException(
        "checkForUpdatesAndDownloadSilent",
        "checkForUpdatesAndDownloadSilent",
        "AppUpdateProvider",
        error,
      );
    }
  };

  /**
   * Checks for updates for the current detected app version.
   * NOTE: This method currently is not supposed to be used utility method and is only for use during the update
   *       dialog flow.
   */
  checkForUpdates = async (): Promise<void> => {
    const installedAppVersion = this.props.versionInfo.appVersion;
    const currentJSVersion = CAMPFIRE_JS_VERSION as string;

    if (this.state.isCheckingForUpdates) {
      return;
    }

    this.setState({ isCheckingForUpdates: true, updateErrorType: null });

    try {
      const appUpdateData = await checkForJSUpdates(installedAppVersion, currentJSVersion);

      this.setState({
        isCheckingForUpdates: false,
        isUpToDate: appUpdateData.isUpToDate,
        updateErrorType: null,
      });
    } catch (error) {
      this.setState({
        isCheckingForUpdates: false,
        isUpToDate: false,
        updateErrorType: "checking-for-updates",
      });
    }
  };

  /**
   * Given a target app version, this method will ask the server what version it should
   * be on, and then download that update if needed.
   *
   * @param targetAppVersion - The target app version to check for JS updates against.
   */
  downloadAppUpdateForTargetAppVersion = async (targetAppVersion: string): Promise<void> => {
    const currentJSVersion = CAMPFIRE_JS_VERSION as string;

    try {
      // If we could not find an installed app version, bail out.
      // If we are currently downloading a manual update, bail out as well.
      if (!targetAppVersion || this.state.isDownloadingManualUpdate) {
        return;
      }

      // First, lets determine what we should download and install.
      const appUpdateData = await checkForJSUpdates(targetAppVersion, currentJSVersion);

      // If we are already up to date, tell the user that.
      if (appUpdateData && appUpdateData.isUpToDate) {
        this.setState({ isUpToDate: true });
        // Put a small delay to the UI so the feels nice.
        await delayResolve(ARBITRARY_CHECKING_FOR_UPDATE_DELAY_MS);
      } else if (appUpdateData && appUpdateData.bundle) {
        // Mark that we are currently download a manual update
        this.setDownloadingManualUpdate();

        // Use the info returned from the server to download the bundle to device.
        // Download async, but put a small delay to the UI so the feels nice.
        downloadJSBundle(appUpdateData.bundle.version, appUpdateData.bundle.url)
          .then((bundleInfo) => {
            if (bundleInfo) {
              // Save the bundle info we just installed, and then tell the user that the download
              // was complete.
              this.lastDownloadedBundleInfo = bundleInfo;

              // Hide the app update dialog, we will show the apply update dialog next.
              this.hideAppUpdateDialog();

              // Delay the showing of the apply update dialog for UX.
              // If the app update dialog is open, close it.
              setTimeout(() => {
                this.showApplyUpdateDialog();
              }, ARBITRARY_UPDATE_READY_DELAY_MS);
            } else {
              this.lastDownloadedBundleInfo = null;
            }
          })
          .finally(() => {
            // After error or complete, mark we are no longer downloading a manual update.
            this.clearDownloadingManualUpdate();
          });

        // Put a small delay to the UI so the feels nice.
        await delayResolve(ARBITRARY_CHECKING_FOR_UPDATE_DELAY_MS);
        // Display a toast the the user that we are downloading an update.
        this.props.toastProvider.showFeedbackToast(this.props.t("AN_UPDATE_IS_BEING_DOWNLOADED"));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.lastDownloadedBundleInfo = null;
      logException(
        "Download and apply app updates",
        "downloadAppUpdateForTargetAppVersion",
        "UserSettingsPage",
        error,
      );
    } finally {
      // Now close the dialog, animate it outta the view.
      this.setState({ appUpdateDialogOpen: false });
    }
  };

  /**
   * Downloads js update bundle for a given app version
   */
  downloadAppUpdate = async (appVersion: string): Promise<void> => {
    return this.downloadAppUpdateForTargetAppVersion(appVersion);
  };

  /**
   * Downloads js update bundle for the current app version, used for manual updates
   */
  downloadAppUpdateManual = async (): Promise<void> => {
    const installedAppVersion = this.props.versionInfo.appVersion;

    if (this.lastDownloadedBundleInfo) {
      this.showApplyUpdateDialog();
    }

    return this.downloadAppUpdate(installedAppVersion);
  };

  /**
   * Reloads the page and sets the last bundle downloaded during this run of the application
   * as the next js bundle to use.
   */
  applyBundleImmediately = (bundleId: string): void => {
    // TODO: Since we know the bundle we wanna apply, let's get rid of some bundles.
    applyUpdateAndCleanupState(bundleId);
  };

  /**
   * Applies the last downloaded bundle as seen by THIS PROVIDER immediately.
   *
   * NOTE: This is not the same bundle that may be auto downloaded by the AutoUpdater!
   */
  applyLastDownloadedBundleImmediately = async (): Promise<void> => {
    await this.cleanupUnusedBundles();

    if (this.lastDownloadedBundleInfo) {
      this.applyBundleImmediately(this.lastDownloadedBundleInfo.id);
    }
  };

  cleanupUnusedBundles = async (): Promise<void> => {
    // In case we have one downloaded via manual download, keep this as well.
    const bundleIdsToKeep = this.lastDownloadedBundleInfo ? [this.lastDownloadedBundleInfo.id] : [];

    await deleteAllUnusedBundlesExcept(bundleIdsToKeep);
  };

  getDialogMessagingConfig = (
    isCheckingForUpdates: boolean,
    isUpToDate: boolean,
    isDownloadingManualUpdate: boolean,
    updateErrorType: UpdateErrorType,
  ): UpdateMessagingConfig => {
    const upToDateDescription = isAndroid
      ? this.props.t("VISIT_THE_PLAY_STORE_IF_YOU_WANT_NEWEST_FEATURES")
      : this.props.t("VISIT_THE_APP_STORE_IF_YOU_WANT_NEWEST_FEATURES");

    if (updateErrorType === "checking-for-updates") {
      return {
        title: `${this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}`,
        cancelBtnText: this.props.t("CLOSE"),
        onCancel: this.hideAppUpdateDialog,
      };
    }

    if (isDownloadingManualUpdate) {
      return {
        title: `${this.props.t("DOWNLOADING_UPDATE")}...`,
        description: this.props.t("AN_UPDATE_IS_BEING_DOWNLOADED_PLEASE_WAIT"),
        cancelBtnText: this.props.t("CLOSE"),
        onCancel: this.hideAppUpdateDialog,
      };
    }

    // Return a messaging config representing that we are checking for updates.
    if (isCheckingForUpdates) {
      return {
        title: `${this.props.t("CHECKING_FOR_UPDATES")}...`,
        cancelBtnText: this.props.t("CANCEL"),
        onCancel: this.hideAppUpdateDialog,
      };
    }

    // Return a messaging config representing the app is already up-to-date.
    if (isUpToDate) {
      return {
        title: this.props.t("YOUR_APP_IS_UP_TO_DATE"),
        description: upToDateDescription,
        confirmBtnText: this.props.t("OK"),
        onCancel: this.hideAppUpdateDialog,
      };
    }

    return {
      title: this.props.t("AN_UPDATE_IS_AVAILABLE"),
      description: this.props.t("CLICK_TO_DOWNLOAD"),
      confirmBtnText: this.props.t("OK"),
      cancelBtnText: this.props.t("CANCEL"),
      onConfirm: this.downloadAppUpdateManual,
      onCancel: this.hideAppUpdateDialog,
    };
  };

  render = (): React.ReactNode => {
    const { children } = this.props;
    const { isCheckingForUpdates, isUpToDate, isDownloadingManualUpdate, updateErrorType } =
      this.state;

    const messagingConfig = this.getDialogMessagingConfig(
      isCheckingForUpdates,
      isUpToDate,
      isDownloadingManualUpdate,
      updateErrorType,
    );

    return (
      <AppUpdateContext.Provider
        value={{
          showAppUpdateDialog: this.showAppUpdateDialog,
          hasValidBundleForVersion: this.hasValidBundleForVersion,
          checkForUpdatesAndDownloadSilent: this.checkForUpdatesAndDownloadSilent,
          setNextBundleToApplyOnColdStart: this.setNextBundleToApplyOnColdStart,
          applyBundleImmediately: this.applyBundleImmediately,
          cleanupUnusedBundles: this.cleanupUnusedBundles,
        }}
      >
        <UIDialog
          type="floating-center"
          isOpen={this.state.appUpdateDialogOpen}
          close={this.hideAppUpdateDialog}
        >
          <ConfirmationDialogView
            title={messagingConfig.title}
            description={messagingConfig.description}
            confirmBtnText={messagingConfig.confirmBtnText}
            cancelBtnText={messagingConfig.cancelBtnText}
            onConfirm={messagingConfig.onConfirm}
            onCancel={messagingConfig.onCancel}
          />
        </UIDialog>

        <UIDialog
          type="floating-center"
          isOpen={this.state.applyUpdateDialogOpen}
          close={this.hideApplyUpdateDialog}
        >
          <ConfirmationDialogView
            title={this.props.t("AN_UPDATE_IS_READY")}
            description={this.props.t("APPLYING_WILL_RESTART_THE_APP")}
            confirmBtnText={this.props.t("OK")}
            cancelBtnText={this.props.t("CANCEL")}
            onConfirm={this.applyLastDownloadedBundleImmediately}
            onCancel={this.hideApplyUpdateDialog}
          />
        </UIDialog>
        {children}
      </AppUpdateContext.Provider>
    );
  };
}

const ToastConnected = withToast(AppUpdateProvider);
const VersionInfoConnected = withVersionInfo(ToastConnected);
const TranslationConnected = withTranslation()(VersionInfoConnected);

export { TranslationConnected as AppUpdateProvider };
export const AppUpdateConsumer = AppUpdateContext.Consumer;
