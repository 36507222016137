/**
 * @generated SignedSource<<b9e616c8f9b90faf1c51e7645d198dfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResetAccountDataQR_Query$variables = {};
export type ResetAccountDataQR_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ResetAccountData_me">;
  };
};
export type ResetAccountDataQR_Query = {
  response: ResetAccountDataQR_Query$data;
  variables: ResetAccountDataQR_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetAccountDataQR_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ResetAccountData_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ResetAccountDataQR_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserResetData",
            "kind": "LinkedField",
            "name": "resetData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResetProperties",
                "kind": "LinkedField",
                "name": "nianticId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAbuse",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97102c3149e1cba9e341d80b952ce586",
    "id": null,
    "metadata": {},
    "name": "ResetAccountDataQR_Query",
    "operationKind": "query",
    "text": "query ResetAccountDataQR_Query {\n  me {\n    ...ResetAccountData_me\n    id\n  }\n}\n\nfragment ResetAccountData_me on User {\n  username\n  resetData {\n    nianticId {\n      isAbuse\n    }\n  }\n}\n"
  }
};

(node as any).hash = "930b5ba29bcea0942edd06c17175e131";

export default node;
