import classnames from "classnames";
import React from "react";

import ErrorMessage from "common/components/ResetAccountData/components/ResetAccountDataInputErrorMessage";
import {
  InputErrorMap,
  InputErrorType,
} from "common/components/ResetAccountData/resetAccountDataErrors";

import styles from "./styles.scss";

type Props = {
  type: InputErrorType;
  errors: number[] | null;
  className?: string;
};

const DataInputError: React.FC<Props> = (props: Props) => {
  // Filter errors based on the type
  const errorCodes = Array.from(new Set(props.errors)).filter(
    (error) => InputErrorMap.get(error) === props.type,
  );

  return (
    <div className={classnames(styles.root, props.className)} aria-live="assertive">
      {errorCodes.map((errorCode) => (
        <div className={styles.error} key={errorCode}>
          <ErrorMessage error={errorCode} />
        </div>
      ))}
    </div>
  );
};

export default DataInputError;
