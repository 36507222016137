import { autoAssignPriorityLevels, AutoDefinedFlow, Flow } from "@niantic/react-coachmark";

import { COACHMARKS } from "./coachmarks";

// Steps to add a new flow:
// =======================
// 1. Add the coachmark names that represent the steps in the flow.
//    - Add these as InteractionName's in providers/UserInteractionHistoryProvider/interactions.ts
//    - Then, add them to coachmarks.ts so we can easily see which interactions are actually
//      used as coachmarks.
//    - FYI, coachmarks are really just interactions, but a subset of all the interactions
//      specifically reserved for use in the coachmark system.
// 2. Add a new FlowName to define your flow.
// 3. Create a Flow Object to represent your flow.
// 4. Insert the Flow Object into the ORDERED_FLOWS array.
//    - The position you insert it dictates the priority. Lower idx = HIGHER priority.
// 5. Confirm that the CoachmarkProvider is not throwing errors validating the flows.
export type FlowName =
  | "ONBOARDING_OFFICIAL_CLUB_FLOW"
  | "ONBOARDING_OFFICIAL_PERIDOT_CLUB_FLOW"
  | "ONBOARDING_OFFICIAL_PGO_CLUB_FLOW"
  | "ONBOARDING_OFFICIAL_NBAALLWORLD_CLUB_FLOW"
  | "ONBOARDING_PGO_EXPERIMENT_ONE_FLOW"
  | "ONBOARDING_ACTIVITY_CENTER_FLOW";

// A few tidbits about flows:
// 1. Multiple flows can choose to reuse the same coachmark if desired.
// 2. Flows can require other flows before showing.
// 3. A HIGHER priorityLevel value indicates HIGHER priority. So, 1000 will show BEFORE 10, but after 1010.

// ===== START Official Club Flows =====
// There are multiple official club flows since we want this to appear per game.
// I experimented with a way to reuse a single coachmark name, but it involves adjusting the
// coachmarks passed to the CoachmarkProvider based on the game which I felt was more prone to cause
// confusion as all other coachmarks declare and mark themselves as the same string name.
// The experimented way would have made it use the default name, but when we mark,
// we mark for the game specific name. So I value the less error prone way.
// ONBOARDING_OFFICIAL_CLUB_FLOW: Encourage joining official club flow. (Default)
export const ONBOARDING_OFFICIAL_CLUB_FLOW: AutoDefinedFlow = {
  name: "ONBOARDING_OFFICIAL_CLUB_FLOW",
  steps: [COACHMARKS.ONBOARDING_OFFICIAL_CLUB],
  interruptable: true,
};

// ONBOARDING_OFFICIAL_PERIDOT_CLUB_FLOW: Encourage joining official club flow. (Peridot)
export const ONBOARDING_OFFICIAL_PERIDOT_CLUB_FLOW: AutoDefinedFlow = {
  name: "ONBOARDING_OFFICIAL_PERIDOT_CLUB_FLOW",
  steps: [COACHMARKS.ONBOARDING_OFFICIAL_CLUB_PERIDOT],
  interruptable: true,
};

// ONBOARDING_OFFICIAL_PGO_CLUB_FLOW: Encourage joining official club flow. (PGO)
export const ONBOARDING_OFFICIAL_PGO_CLUB_FLOW: AutoDefinedFlow = {
  name: "ONBOARDING_OFFICIAL_PGO_CLUB_FLOW",
  steps: [COACHMARKS.ONBOARDING_OFFICIAL_CLUB_PGO],
  interruptable: true,
};

// ONBOARDING_OFFICIAL_NBAALLWORLD_CLUB_FLOW: Encourage joining official club flow. (NBAALLWORLD)
export const ONBOARDING_OFFICIAL_NBAALLWORLD_CLUB_FLOW: AutoDefinedFlow = {
  name: "ONBOARDING_OFFICIAL_NBAALLWORLD_CLUB_FLOW",
  steps: [COACHMARKS.ONBOARDING_OFFICIAL_CLUB_NBAALLWORLD],
  interruptable: true,
};
// ===== END Official Club Flows =====

// ONBOARDING_PGO_EXPERIMENT_ONE_FLOW: Tell users about the geo-based matchmaking experiment
// that will run for a short time in select cities
export const ONBOARDING_PGO_EXPERIMENT_ONE_FLOW: AutoDefinedFlow = {
  name: "ONBOARDING_PGO_EXPERIMENT_ONE_FLOW",
  steps: [COACHMARKS.ONBOARDING_PGO_EXPERIMENT_ONE],
  interruptable: true,
};

// ONBOARDING_ACTIVITY_CENTER_FLOW: Tell users about the new activity center
export const ONBOARDING_ACTIVITY_CENTER_FLOW: AutoDefinedFlow = {
  name: "ONBOARDING_ACTIVITY_CENTER_FLOW",
  steps: [COACHMARKS.ONBOARDING_ACTIVITY_CENTER],
  interruptable: false,
};

// Below we define all the flows that are possible.
// For convenience, we automatically assign priority levels for the flows we use in the app and instead
// use an array to convey priority. Lower index = Higher priority level
// Flows are "visually" grouped below to convey a sense of relative priorities for a set of flows.
const ORDERED_FLOWS: AutoDefinedFlow[] = [
  ONBOARDING_ACTIVITY_CENTER_FLOW,
  ONBOARDING_OFFICIAL_CLUB_FLOW,
  ONBOARDING_OFFICIAL_PERIDOT_CLUB_FLOW,
  ONBOARDING_OFFICIAL_PGO_CLUB_FLOW,
  ONBOARDING_OFFICIAL_NBAALLWORLD_CLUB_FLOW,
  ONBOARDING_PGO_EXPERIMENT_ONE_FLOW,
];

export const APP_COACHMARK_FLOWS: Record<FlowName, Flow> = autoAssignPriorityLevels(ORDERED_FLOWS);
