import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { ShareReceiver, OnImageShareReceivedEvent } from "common/capacitor/plugins/share-receiver";
import { setShareData } from "common/utils/shareData";
import { EXPERIENCE_ROUTE, MEDIA_SHARE_EXPERIENCE_ROUTE } from "constants/routes";

type Props = RouteComponentProps;

class ShareReceivedHandler extends React.Component<Props> {
  componentDidMount = async (): Promise<void> => {
    ShareReceiver.addListener("onImageShareReceived", (event: OnImageShareReceivedEvent) => {
      // Store the share data, then send the user over to the media share page
      setShareData(event.dataUri);
      this.props.history.push({
        pathname: `${EXPERIENCE_ROUTE}/${MEDIA_SHARE_EXPERIENCE_ROUTE}`,
      });
    });
  };

  render = (): React.ReactNode => {
    return null;
  };
}

const RouterConnected = withRouter(ShareReceivedHandler);

export default RouterConnected;
