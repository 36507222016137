import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isTomorrow from "dayjs/plugin/isTomorrow";
// import timezone from "dayjs/plugin/timezone";
// import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

// The following imports are used to localize date & time
// If Campfire wants to support more languages, please update the imports below
// dayjs list of supported locale: https://github.com/iamkun/dayjs/tree/dev/src/locale
import "dayjs/locale/cs";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/ja";
import "dayjs/locale/ko";
import "dayjs/locale/nl";
import "dayjs/locale/nb";
import "dayjs/locale/pl";
import "dayjs/locale/pt";
import "dayjs/locale/ru";
import "dayjs/locale/sv";
import "dayjs/locale/th";
import "dayjs/locale/tr";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";

// TODO: If we REALLY need to limit bundle size, we could import different versions
//       of dayjs from utils that calls .extend() on dayjs on demand.
// ====== DAY.JS PLUGIN CONFIGURATION ======
// Any plugin we add here is added to the global dayjs instance!
// Add advanced formatting options.
// https://day.js.org/docs/en/plugin/advanced-format
dayjs.extend(advancedFormat);

// Add the duration plugin to dayjs
// https://day.js.org/docs/en/plugin/duration
dayjs.extend(duration);

// Add the plugin to support isSameOrBefore
// https://day.js.org/docs/en/plugin/is-same-or-after
dayjs.extend(isSameOrAfter);

// Add the plugin to support isSameOrBefore
// https://day.js.org/docs/en/plugin/is-same-or-before
dayjs.extend(isSameOrBefore);

// Add the plugin to support isTomorrow
// https://day.js.org/docs/en/plugin/is-tomorrow
dayjs.extend(isTomorrow);

// (ON HOLD) Add the plugin to support timezone
// https://day.js.org/docs/en/plugin/timezone
// mchen 05/21/2022 - dayjs uses the ECMA-402 Intl API's to determine timezone, which requires
// some level of polyfilling. I got concerned with just how much the bundle size increased
// just to polyfill the required API's so the timezone support is being removed for the time
// being.
// dayjs.extend(utc);
// dayjs.extend(timezone);

// Add the plugin to support localized date and time
// https://day.js.org/docs/en/plugin/localized-format
// https://day.js.org/docs/en/display/format#list-of-localized-formats
dayjs.extend(localizedFormat);

export default dayjs;
