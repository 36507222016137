import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { UnregisterFcmTokenMutation$data } from "__generated__/UnregisterFcmTokenMutation.graphql";

type ReturnType = UnregisterFcmTokenMutation$data["unregisterFcmToken"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;

export type OSType = "ios" | "android";

type InputType = {
  fcmToken: string;
  os: OSType;
};

const mutation = graphql`
  mutation UnregisterFcmTokenMutation($input: UnregisterFcmTokenInput!) {
    unregisterFcmToken(input: $input) {
      user {
        id
        username
        fcmTokens
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "unregisterFcmToken.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "unregisterFcmToken.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "unregisterFcmToken"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          fcmToken: input.fcmToken,
          game: "campfire",
          os: input.os,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
