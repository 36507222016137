import { Filesystem, StatResult } from "@capacitor/filesystem";
import _ from "lodash";

import logException from "common/analytics/exceptions";

// https://capacitorjs.com/docs/apis/filesystem#stat
export const validator = (data: StatResult): boolean => {
  try {
    const hasSize = !_.isNil(data.size);
    const hasType = !_.isNil(data.type);
    const hasUri = !_.isNil(data.uri);
    const isValid = hasSize && hasType && hasUri;

    if (!isValid) {
      const invalidFields = [
        {
          name: "size",
          value: data.size,
        },
        {
          name: "type",
          value: data.type,
        },
        {
          name: "uri",
          value: data.uri,
        },
      ].filter((fieldObj) => _.isNil(fieldObj.value));

      const invalidFieldStr = invalidFields.map((fieldObj) => fieldObj.name).join(",");
      const errorStr = `Received invalid values for fields: ${invalidFieldStr}`;

      throw new Error(errorStr);
    }

    return isValid;
  } catch (error) {
    logException("validating Filesystem.stat data from embed", "validator", "stat", error);
    return false;
  }
};

export default Filesystem.stat;
