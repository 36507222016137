const APPSFLYER_QUERY_PARAMS = {
  // predefined query param from apps flyer
  BASE: "deep_link_sub1",
  // param for appsflyer route
  ROUTE: "r",
  // param for appsflyer club id
  CLUB_ID: "c",
  // param for appsflyer channel id
  CHANNEL_ID: "ch",
  // param for appsflyer event id
  EVENT_ID: "eid",
  // param for appsflyer event
  EVENT: "e",
  // param for appsflyer invite to club
  INVITE: "i",
  // param for originating game
  GAME: "g",
  // param for map lat bounds
  LAT: "lat",
  // param for map lng bounds
  LNG: "lng",
  // param for map object id
  MAP_OBJECT_ID: "m",
  // param for moderated photo id
  PHOTO_ID: "p",
  // param for un-moderated photo id
  UN_MODERATED_PHOTO_ID: "up",
  // param for create post
  CREATE_POST: "cp",
  // param for sharing newly created post
  SHARE_PROMPT: "sp",
  // param for deep linking to a message in a channel
  TIME_TOKEN: "tt",
};

export default APPSFLYER_QUERY_PARAMS;
