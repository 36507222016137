import _ from "lodash";

import { embeddedClientInfo } from "common/utils/webInterop";
import GAMES_BY_SHORT_CODE, {
  GameInfo,
  GameShortCode,
  GameShortCodeWithBackwardsCompat,
  GAME_SHORT_CODES,
} from "constants/nianticGame";

/**
 * Looks at the current game from any embeddedClientInfo to determine the active game short code.
 */
export const getCurrentGameShortCode = (): GameShortCode => {
  return _.get(embeddedClientInfo, "Game") as GameShortCode;
};

export const getCurrentGameInfo = (): GameInfo | undefined => {
  const gameShortCode = getCurrentGameShortCode();
  const gameInfo: GameInfo | undefined = GAMES_BY_SHORT_CODE[gameShortCode];

  return gameInfo;
};

export const getGameShortNameFromCode = (code: string): string => {
  const game = GAMES_BY_SHORT_CODE[code.toUpperCase() as GameShortCodeWithBackwardsCompat];

  if (!game) {
    return "";
  }

  return game.gameName;
};

/**
 * Returns all short codes corresponding to actual games
 */
export const getAllGameShortCodes = (): GameShortCode[] => {
  const allShortCodes = Object.keys(GAMES_BY_SHORT_CODE) as GameShortCode[];
  const shortCodesToRemove = [
    GAME_SHORT_CODES.SAMPLE,
    GAME_SHORT_CODES.ICH,
    GAME_SHORT_CODES.NIASOCIAL,
    GAME_SHORT_CODES.CAMPFIRE,
    GAME_SHORT_CODES.SUPERBLUE,
    GAME_SHORT_CODES.MHNOW, // Disable it for now
  ];

  return allShortCodes.filter((code) => !shortCodesToRemove.includes(code));
};

/**
 * Returns true if the provided gameShortCode corresponds to an actual game
 */
export const gameShortCodeIsActualGame = (gameShortCode: string): boolean => {
  return getAllGameShortCodes().includes(gameShortCode as GameShortCode);
};
