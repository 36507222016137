import { useRef, useState } from "react";

import { DynamicSlide, DynamicSlideType } from "./slideTypes";

export type HookReturnType = {
  dynamicSlides: DynamicSlide[];
  addDynamicSlide: (slideId: string) => void;
  removeDynamicSlide: (slideId: string) => void;
};

/**
 * Holds the state of the dynamic slides that may be added to the Activity Center's UINav.
 */
function useDynamicSlideState(): HookReturnType {
  const [dynamicSlides, setDynamicSlides] = useState<DynamicSlide[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timerRef = useRef<any>(null);
  const slidesToRemoveRef = useRef<Set<string>>(new Set());

  /**
   * Adds a dynamic slide to the set of slides to render.
   */
  const addDynamicSlide = (slideId: string): void => {
    const existingSlide = dynamicSlides.find((slide) => slide.slideId === slideId);

    // Only add slide if needed
    if (!existingSlide) {
      const newSlide: DynamicSlideType = {
        slideId,
      };
      const nextSlides = [...dynamicSlides, newSlide];

      setDynamicSlides(nextSlides);
    }
  };

  const removeScheduledToDeleteSlides = (): void => {
    const slidesNotRemoved = dynamicSlides.filter((s) => !slidesToRemoveRef.current.has(s.slideId));

    setDynamicSlides(slidesNotRemoved);
    slidesToRemoveRef.current.clear();
  };

  /**
   * Schedules a slide to be removed in the future. Since multiple slides can close simultaneously,
   * and state updates get batched, we want to commit the changes to state all at once.
   */
  const scheduleSlideToBeRemoved = (slideId: string) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    slidesToRemoveRef.current.add(slideId);
    timerRef.current = setTimeout(removeScheduledToDeleteSlides, 10);
  };

  return {
    dynamicSlides,
    addDynamicSlide,
    removeDynamicSlide: scheduleSlideToBeRemoved,
  };
}

export default useDynamicSlideState;
