import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { NIANTIC_PRIVACY_URL, NIANTIC_CAMPFIRE_SUPPORT_URL } from "constants/urls";

import UILink from "common/components/UILink";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {};

type State = {};

class PrivacyFooter extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <div className={styles.left}>
          <UILink href={NIANTIC_PRIVACY_URL} openInNewTab>
            <UIText color="medium" variant="body2">
              {this.props.t("PRIVACY").toUpperCase()}
            </UIText>
          </UILink>
        </div>

        <div className={styles.right}>
          <UILink href={NIANTIC_CAMPFIRE_SUPPORT_URL} openInNewTab>
            <UIText color="medium" variant="body2">
              {this.props.t("HELP")}
            </UIText>
          </UILink>
        </div>
      </div>
    );
  };
}

export default withTranslation()(PrivacyFooter);
