/* eslint-disable react/self-closing-comp */
import _ from "lodash";
import { MessageEvent } from "pubnub";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withAuth, { WithAuthProps } from "providers/AuthProvider/withAuth";
import withPubnub, { WithPubnubProps } from "providers/PubnubProvider/withPubnub";

import PermanentBanDialog from "common/components/PermanentBanDialog";

export type Props = WithTranslation &
  WithAuthProps &
  WithPubnubProps & {
    userId: string;
    channel: string;
  };

type State = {
  isDialogOpen: boolean;
};

/**
 * Registers actions to be performed when the user's channel group receives a signal from
 * pubnub. Usually when a different user sends a chat message, the BE will publish
 * a pubnub signal to an associated signal channel that exists alongside a message channel. This
 * we use to denote activity within a message channel, all for the awesome price of $0.00.
 * (Since signals are free).
 */
class UserPermanentBanMessageHandler extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDialogOpen: false,
    };
  }

  componentDidMount = (): void => {
    this.props.pubnubProvider.pubnubClient.registerMessageHandler(
      this.props.channel,
      this.handleNewChannelActivity,
    );
  };

  componentWillUnmount = (): void => {
    this.props.pubnubProvider.pubnubClient.unregisterListener(
      this.props.channel,
      this.handleNewChannelActivity,
      false,
    );
  };

  openConfirmationDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  closeConfirmationDialogAndLogout = () => {
    this.setState({ isDialogOpen: false });
    this.props.authProvider.logout();
  };

  handleNewChannelActivity = (pubnubMessageEvent: MessageEvent) => {
    const pubnubMessage: CampfirePushEvent.PermanentBanPushEvent = pubnubMessageEvent.message;
    const actionType: CampfirePushEvent.ActionType = _.get(pubnubMessage, "action") || "";
    const isBanAction = actionType === "permanent-ban";

    if (isBanAction) {
      this.openConfirmationDialog();
    }
  };

  render = (): React.ReactNode => {
    return (
      <PermanentBanDialog
        isOpen={this.state.isDialogOpen}
        close={this.closeConfirmationDialogAndLogout}
      />
    );
  };
}

const PubnubConnected = withPubnub(UserPermanentBanMessageHandler);
const AuthConnected = withAuth(PubnubConnected);

export default withTranslation()(AuthConnected);
