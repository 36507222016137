import React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import UserChannelGroupSignalHandler from "boot-loader/components/UserChannelGroupSignalHandler";

import { GlobalSignalHandlers_me$data as GlobalSignalHandlersMe } from "__generated__/GlobalSignalHandlers_me.graphql";

export type Props = {
  me: GlobalSignalHandlersMe;
};

type State = {};

class GlobalSignalHandlers extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <>
        <UserChannelGroupSignalHandler userId={this.props.me.id} />
      </>
    );
  };
}

const FragmentContainer = createFragmentContainer(GlobalSignalHandlers, {
  me: graphql`
    fragment GlobalSignalHandlers_me on User {
      id
    }
  `,
});

export default FragmentContainer;
