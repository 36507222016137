// PLEASE KEEP THIS LIST IN ALPHABETICAL ORDER!
// eslint-disable-next-line import/prefer-default-export
export const FEATURE_FLAGS = {
  // [RM - Christina] Enables the nearby activity and posts drawer
  ACTIVITY_FEED: "activity_feed",
  // In club and channel notification settings, allows the user to select All Messages.
  ALL_MESSAGES_NOTIFICATIONS: "all_messages_notifications",
  // Enables the AutoUpdater which enables the app to automatically download js updates
  // and apply them on next boot or next foreground based on some criteria being met.
  APP_AUTO_UPDATE: "app_auto_update",
  // Enables Manual In App Updates
  APP_MANUAL_UPDATE: "app_manual_update",
  // Allows users to see their own and friends avatars on pois on the map when they light a flare
  BEACON_AVATARS: "beacon_avatars",
  // Enable campfire's v2 UI which removes the tab bar
  CAMPFIRE_V2: "campfire_v2",
  // Enables chatv2 as the read data source for chat messages from history.
  CHATV2_READ: "chatv2_read",
  // [RM - Mike] Enables all users to create communities
  COMMUNITY_CREATION: "community_creation",
  // [RM - Emery] Enables deeplinking from standalone campfire to pgo gym
  DEEP_LINK_PGO_GYM: "deep_link_pgo_gym",
  // Enables ability for users to create location shares inside of dm channels.
  DM_LOCATION_SHARE: "dm_location_share",
  // [DEPRECATE - Emery] Enables pending friends to be listed along side actual friends when creating a new DM
  DM_PENDING_FRIENDS: "dm_pending_friends",
  // Email notifications coachmark and settings menu
  EMAIL_NOTIFICATIONS: "email_notifications",
  // Enable reset flow for users to reset account data
  ENABLE_RESET_FLOW: "enable_reset_flow",
  // [RM - CJ] Enables poi-based chat channels
  EPHEMERAL_POI_CHAT: "ephemeral_poi_chat",
  // Enables poi-based chat channels
  EPHEMERAL_POI_CHAT_LOCATION_SHARE: "ephemeral_poi_chat_location_share",
  // [DEPRECATE - CJ] present "copy link" type behaviors for users to share to one another.
  EXTERNAL_INVITE: "external_invite",
  // [RM - Christina] Enables the group nearby activity and posts drawer
  GROUP_ACTIVITY_FEED: "group_activity_feed",
  // [RM - Christina] Enables querying to indicate "Active Now" on the Nearby Group Activities button
  GROUP_ACTIVITY_FEED_ACTIVE_NOW: "group_activity_feed_active_now",
  // Hides the ability to edit meetup time and invitees due to T&S concerns
  HIDE_MEETUP_EDIT_DETAILS: "hide_meetup_edit_details",
  // Enables the Monster Hunter Now (Magellan) Experience route
  MHN_EXPERIENCE: "mhn_experience",
  // Enables Party Chat within the Monster Hunter Now (Magellan) Experience
  MHN_EXPERIENCE_PARTY_CHAT: "mhn_experience_party_chat",
  // [DEPRECATE - CJ] Enable delete account in user settings
  MANAGE_ACCOUNT: "manage_account",
  // [RM - Emery] Cache the last results of mapObjectsInLatLngBounds & homeMapObjects
  MAP_OBJECT_CACHE: "map_object_cache",
  // Controls whether or not users see the Events/Offers/Updates section of push notification settings.
  MARKETING_PUSH_NOTIFICATIONS: "marketing_push_notifications",
  // Master App Kill Switch. Enable this and the app is basically nothing.
  MASTER_KILL_SWITCH_ACTIVE: "master_kill_switch_active",
  // Allows group admins to mention @everyone in the group.
  MENTION_EVERYONE: "mention_everyone",
  // [RM - CJ] Enables geocoding to be done natively/on-device (only available for standalone)
  NATIVE_GEOCODING: "native_geocoding",
  // Enabled NBA All World Clustering
  NBA_ALL_WORLD_CLUSTER: "nba_all_world_cluster",
  // Enables NBA All World drops on the map
  NBA_ALL_WORLD_DROPS: "nba_all_world_drops",
  // Enables NBA All World standalone channel as a map option
  NBA_ALL_WORLD_STANDALONE: "nba_all_world_standalone",
  // [RM? - Christina] Enables PGO gym clustering
  PGO_CLUSTER: "pgo_cluster",
  // When enabled, will redirect pgo embed to the activity center
  PGO_EMBED_TEMPORARY_AC_REDIRECT: "pgo_embed_temporary_ac_redirect",
  // Enables our first pgo experiment, now referred to as TeamUp
  PGO_EXPERIMENT_ONE: "pgo_experiment_one",
  // Enables our first pgo experiment (now referred to as TeamUp) selection from the normal pgo map for easier testing
  PGO_EXPERIMENT_ONE_STANDALONE_BUTTON: "pgo_experiment_one_standalone_button",
  // Enables Pikmin standalone channel as a map option
  PIKMIN_STANDALONE: "pikmin_standalone",
  // [RM - Shawn] Enables location/POI search in event form and club settings form.
  PLACES_SEARCH: "places_search",
  // [RM - Zain] Enables player activity to be rendered as heatmap
  PLAYER_ACTIVITY: "player_activity",
  // Allows users to use the updated IP violation report flow
  REPORT_IP_VIOLATIONS: "report_ip_violations",
  // Allows users to use the new updated IP Viiolation report flow to report post content
  REPORT_POST_IP_VIOLATIONS: "report_post_ip_violations",
  // [RM - Shawn] Enables localized flare notifications
  SOMEONE_LIT_A_FLARE_NEAR_ME_NOTIFICATIONS: "someone_lit_a_flare_near_me_notifications",
  // [RM - CJ] When enabled, allows users to specify an associated game for clubs. Events will inherit the clubs' game.
  SPECIFY_GAME_FOR_CLUBS: "specify_game_for_clubs",
  // Enables usage of the new upload file flow in the embed. Enable when all clients move off of old upload file flow
  USE_UPDATED_UPLOAD_FLOW: "use_updated_upload_flow",
  // Enable video to gif feature in chat
  VIDEO_TO_GIF: "video_to_gif",
  // Enables ar drops on the maps home layer
  WEB_AR_DROPS: "web_ar_drops",
};
