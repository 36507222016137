import React, { useRef } from "react";

import ClubsPageSlideRenderer from "pages/Clubs/providers/ClubsPageViewProvider/components/ClubsPageSlideRenderer";
import { ChannelSlideConfig } from "pages/Clubs/providers/ClubsPageViewProvider/slides";

import HARDWARE_BACK_EVENT_PRIORITY from "constants/hardwareBackHierarchy";
import { SLIDE_ANIMATION_FOR_CHAT } from "constants/springAnimations";

import useDynamicSlidePresenter, { ViewControlReturnType } from "./useDynamicSlidePresenter";
import useDynamicSlideState, { DynamicSlideType, DynamicSlide } from "./useDynamicSlideState";
import UINav, {
  ExposedProps as UINavExposedProps,
  UINavOutlet,
  UINavSlide,
  UINavSlides,
} from "common/components/UINav";

export type ExposedProps = {
  // Displays a view.
  showDynamicView: (slideConfig: ChannelSlideConfig) => ViewControlReturnType;

  // Hides a view that is currently being displayed.
  // If the slide is a dynamic slide, it will also be removed from the set of dynamic slides when
  // the slide has been dismissed.
  hideView: (slideId: string) => void;

  hideAllViews: () => void;

  hideAllViewsExceptActive: () => void;

  // The currently active view.
  activeView: string | null;

  // Detects if a view is open in the UINav stack.
  isViewOpen: (slideId: string) => boolean;
};

type Props = {
  children: (exposedProps: ExposedProps) => React.ReactNode;
  isAnimated: boolean;
  updateUrlBasedOnActiveSlide: (slideId: string | null) => void;
};

const ClubsPageSlideLayout = (props: Props): React.ReactElement => {
  const { dynamicSlides, addDynamicSlide, removeDynamicSlide } = useDynamicSlideState();
  const { showDynamicViewCore, hideViewCore } = useDynamicSlidePresenter({ addDynamicSlide });
  const lastActiveSlideSeenRef = useRef<string | null>(null);

  const getExposedProps = (uiNavProps: UINavExposedProps) => {
    const showDynamicView = (slideConfig: ChannelSlideConfig): ViewControlReturnType => {
      return showDynamicViewCore(slideConfig, uiNavProps);
    };

    const hideView = (slideId: string): void => {
      return hideViewCore(slideId, uiNavProps);
    };

    const hideAllViews = (): void => {
      const allSlideIds = dynamicSlides.map((item) => item.slideId);

      uiNavProps.hideSlides(allSlideIds);
    };

    const hideAllViewsExceptActive = (): void => {
      const allButActiveSlideIds = dynamicSlides
        .map((item) => item.slideId)
        .filter((slideId) => slideId !== uiNavProps.activeSlide);

      uiNavProps.hideSlides(allButActiveSlideIds);
    };

    const exposedProps: ExposedProps = {
      showDynamicView,
      hideView,
      hideAllViews,
      hideAllViewsExceptActive,
      activeView: uiNavProps.activeSlide,
      isViewOpen: uiNavProps.isSlideInStack,
    };

    return exposedProps;
  };

  const renderDynamicSlide = (dynamicSlide: DynamicSlideType): React.ReactNode => {
    // When the dynamic slide hides, remove it from the list.
    // Changes to the dynamic slides array should be ok to do when idle
    const onHidden = () => {
      removeDynamicSlide(dynamicSlide.slideId);
      props.updateUrlBasedOnActiveSlide(lastActiveSlideSeenRef.current);
    };

    return (
      <UINavSlide key={dynamicSlide.slideId} slideId={dynamicSlide.slideId} onHidden={onHidden}>
        {({ activeSlide }) => {
          return (
            <ClubsPageSlideRenderer
              isActiveView={activeSlide === dynamicSlide.slideId}
              slideConfig={dynamicSlide}
            />
          );
        }}
      </UINavSlide>
    );
  };

  const renderDynamicViews = (): React.ReactNode[] => {
    return dynamicSlides.map((dynamicSlide: DynamicSlide) => {
      return renderDynamicSlide(dynamicSlide);
    });
  };

  const onSlideChange = (slideId: string | null) => {
    lastActiveSlideSeenRef.current = slideId;
  };

  return (
    <UINav
      animationSpringConfig={SLIDE_ANIMATION_FOR_CHAT}
      disableAnimations={!props.isAnimated}
      enableSwipebackOnSlides
      enableHardwareBack
      hardwareBackPriority={HARDWARE_BACK_EVENT_PRIORITY.appRoot.pages.clubs.root}
      onSlideChange={onSlideChange}
    >
      <UINavSlides>
        {/* ====== DYNAMIC Views ====== */}
        {renderDynamicViews()}
      </UINavSlides>

      <UINavOutlet>
        {(uiNavProps: UINavExposedProps) => {
          const exposedProps: ExposedProps = getExposedProps(uiNavProps);

          return props.children(exposedProps);
        }}
      </UINavOutlet>
    </UINav>
  );
};

export default ClubsPageSlideLayout;
