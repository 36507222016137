import { delay } from "common/utils/delay";
import config from "constants/config";

type ImageDimensionResult = {
  naturalWidth: number;
  naturalHeight: number;
};

// How long to wait before giving up loading an image.
const MAX_TIME_LOAD_IMAGE_MS = 15000;
// How long to wait before giving up loading an image for dimensions.
const MAX_TIME_LOAD_IMAGE_DIMENSIONS_MS = 5000;
const MAX_PIXEL_RATIO = 4;

const SUPPORTED_IMAGE_FILE_TYPE: Record<string, boolean> = {
  "image/png": true,
  "image/x-png": true,
  "image/jpeg": true,
  "image/pjpeg": true,
  "image/gif": true,
  "image/bmp": true,
  "image/webp": true,
};

export const SUPPORTED_IMAGE_FILE_TYPES = Object.keys(SUPPORTED_IMAGE_FILE_TYPE);

export const isSupportedImageFile = (fileType: File["type"]): boolean => {
  return SUPPORTED_IMAGE_FILE_TYPE[fileType];
};

export const checkIfUrlIsImage = async (url: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const timeout = MAX_TIME_LOAD_IMAGE_MS;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timer: any;
    const img = new Image();

    const onError = () => {
      clearTimeout(timer);
      reject(new Error("Not an image url!"));
    };

    const onLoad = () => {
      clearTimeout(timer);
      resolve(true);
    };

    img.onerror = onError;
    img.onabort = onError;
    img.onload = onLoad;
    img.src = url;

    timer = setTimeout(() => {
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = "//!!!!/test.jpg";
      reject(new Error("Image Load Timeout!"));
    }, timeout);
  });
};

export const isImageCached = (url: string): boolean => {
  const img = new Image();

  img.src = url;

  return img.complete;
};

/**
 * Gets natural width and height of an image from a url.
 * Waits at most MAX_TIME_LOAD_IMAGE_DIMENSIONS_MS before giving up.
 */
export const getImageDimensions = async (url: string): Promise<ImageDimensionResult | void> => {
  const img = new Image();

  img.src = url;

  const promise = new Promise((resolve) => {
    img.onload = () => {
      resolve({
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
      });
    };
  });

  try {
    return Promise.race([
      delay(MAX_TIME_LOAD_IMAGE_DIMENSIONS_MS, "Timeout to get image dimension reached!"),
      promise,
    ]) as Promise<ImageDimensionResult | void>;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Unable to get image dimensions");

    return undefined;
  }
};

/**
 * Tries to convert an external image URL to a data URL.
 * Will throw an error and reject the Promise if the fetch response was not ok.
 * Tries to only accept our supported image file types.
 *
 * @param url - the external URL
 */
export const extImageUrlToDataUrl = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: new Headers({
        Accept: SUPPORTED_IMAGE_FILE_TYPES.join(", "),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response not ok");
        }

        return response.blob();
      })
      .then((blob) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      })
      .catch(reject);
  });
};

/**
 * Adds a size parameter to an image URL, where the provided size is the "css size"
 * of the image. The CSS size is multiplied with the device's pixel ratio, which can be
 * greater than one on retina displays.
 */
export const getImageUrlWithSize = (url: string, cssSize?: number): string => {
  const backendUrl = config.get("CAMPFIRE_APP_WWW_URL");

  // Only apply resizing to backend proxied images.
  if (!url.startsWith(`${backendUrl}/images/`)) {
    return url;
  }

  if (!cssSize) {
    return url;
  }

  return `${url}?size=${cssSize * Math.min(MAX_PIXEL_RATIO, window.devicePixelRatio)}`;
};

export const getImageUrlByPhotoId = (imageId: string, cssSize?: number): string => {
  const backendUrl = config.get("CAMPFIRE_APP_WWW_URL");

  return getImageUrlWithSize(`${backendUrl}/images/${imageId}`, cssSize);
};
