import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { ANIMATION_TIMING_MS, AuthenticatedViewMode, SetupStepsNeeded } from "boot-loader";
import { isInClosedBeta } from "common/utils/webInterop";

import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";

import AuthenticatedAppModeRenderer from "boot-loader/components/AuthenticatedAppModeRenderer";
import ClosedBetaSplash from "boot-loader/components/ClosedBetaSplash";
import LandingPageEmbedded from "boot-loader/components/LandingPageEmbedded";

import styles from "./styles.scss";

type Props = RouteComponentProps & {
  isLoggedIn: boolean;
  authenticatedViewMode: AuthenticatedViewMode | undefined;
  setupStepsNeeded: SetupStepsNeeded;
  closeAndReset: () => void;
  finishSetupFlow: () => Promise<void>;
  proceedToNextSequenceInApp: () => Promise<void>;
};

type State = {
  isOnAllowList: boolean;
};

class EmbeddedBootLoader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOnAllowList: !isInClosedBeta,
    };
  }

  render = (): React.ReactNode => {
    const { isLoggedIn, authenticatedViewMode } = this.props;
    const { isOnAllowList } = this.state;

    // Check if we are not on the allowlist, and if so show a gated UX.
    if (!isOnAllowList) {
      return (
        <AnimatedFadeInOut
          type="fade-in"
          className={styles.fullScreen}
          show
          durationMs={ANIMATION_TIMING_MS.landingPageFadeInDurationMS}
        >
          <ClosedBetaSplash />
        </AnimatedFadeInOut>
      );
    }

    return (
      <>
        <AnimatedFadeInOut
          type="fade-in"
          className={styles.fullScreen}
          show={!isLoggedIn}
          durationMs={
            isLoggedIn
              ? ANIMATION_TIMING_MS.landingPageFadeOutDurationMS
              : ANIMATION_TIMING_MS.landingPageFadeInDurationMS
          }
        >
          <LandingPageEmbedded />
        </AnimatedFadeInOut>

        {/* Use the same view for the app for embed as well. If we ever need to be more */}
        {/* tailored to embed, we can adjust the view here. But for now, it's the same. */}
        <AuthenticatedAppModeRenderer
          isLoggedIn={isLoggedIn}
          authenticatedViewMode={authenticatedViewMode}
          setupStepsNeeded={this.props.setupStepsNeeded}
          closeAndReset={this.props.closeAndReset}
          finishSetupFlow={this.props.finishSetupFlow}
          proceedToNextSequenceInApp={this.props.proceedToNextSequenceInApp}
          disableBack
          respectSafeArea={false}
        />
      </>
    );
  };
}

export default withRouter(EmbeddedBootLoader);
