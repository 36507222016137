/**
 * @generated SignedSource<<40c3f86fdd412c233b83f3f64125d370>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateUserNotificationSettingsInput = {
  beaconFriendLitScope?: string | null;
  beaconNearMeScope?: string | null;
  beaconSameLitScope?: string | null;
  eventCreationEnabled?: boolean | null;
  eventInviteScope?: string | null;
  eventReminderEnabled?: boolean | null;
  friendRequestEnabled?: boolean | null;
  groupInvitationEnabled?: boolean | null;
  groupMemberLocationShareEnabled?: boolean | null;
  groupNewMemberEnabled?: boolean | null;
  marketingScope?: string | null;
  notificationDestinationSettings?: string | null;
  outgoingFlareScope?: string | null;
  postCreatedNearMeScope?: string | null;
  postFriendCreatedScope?: string | null;
  postLikedScope?: string | null;
};
export type UpdateUserPgoNotificationSettingsMutation$variables = {
  input: UpdateUserNotificationSettingsInput;
};
export type UpdateUserPgoNotificationSettingsMutation$data = {
  readonly updateUserNotificationSettings: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"PGORealityChannelSettings_me">;
    };
  };
};
export type UpdateUserPgoNotificationSettingsMutation = {
  response: UpdateUserPgoNotificationSettingsMutation$data;
  variables: UpdateUserPgoNotificationSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserPgoNotificationSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserNotificationSettingsResponse",
        "kind": "LinkedField",
        "name": "updateUserNotificationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PGORealityChannelSettings_me"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserPgoNotificationSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserNotificationSettingsResponse",
        "kind": "LinkedField",
        "name": "updateUserNotificationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNotificationSettings",
                "kind": "LinkedField",
                "name": "notificationSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "beaconSameLitScope",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "beaconNearMeScope",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "outgoingFlareScope",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "36cee95d7115f3b5e0a4159e61aaf485",
    "id": null,
    "metadata": {},
    "name": "UpdateUserPgoNotificationSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserPgoNotificationSettingsMutation(\n  $input: UpdateUserNotificationSettingsInput!\n) {\n  updateUserNotificationSettings(input: $input) {\n    user {\n      ...PGORealityChannelSettings_me\n      id\n    }\n  }\n}\n\nfragment PGORealityChannelSettings_me on User {\n  notificationSettings {\n    beaconSameLitScope\n    beaconNearMeScope\n    outgoingFlareScope\n  }\n}\n"
  }
};
})();

(node as any).hash = "6da9dbc8d96778558ef8a3cf09e7280a";

export default node;
