import React from "react";

import OnboardElementTitle from "common/components/Onboarding/components/OnboardElementTitle";
import UISpacer from "common/components/UISpacer";

import styles from "./styles.scss";

export type Props = {
  title: string;
  subtitle?: string;
  renderSubmit?: () => React.ReactNode;
  children: React.ReactNode;
};

type State = {};

export default class OnboardElementLayout extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <div className={styles.innerContainer}>
          <UISpacer h={92} />
          <OnboardElementTitle title={this.props.title} subtitle={this.props.subtitle} />
          {this.props.children}
          <UISpacer h={108} />
          {this.props.renderSubmit && (
            <div className={styles.submitContainer}>{this.props.renderSubmit()}</div>
          )}
        </div>
      </div>
    );
  };
}
