import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

import dayjs from "common/utils/dayjs";
import { NIANTIC_GUIDELINES_URL, NIANTIC_HELPSHIFT_BAN_APPEAL_URL } from "constants/urls";

import ConfirmationDialogView from "common/components/ConfirmationDialogView";
import UIDialog from "common/components/UIDialog";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  isOpen: boolean;
  close: () => void;
  suspensionExpirationInSeconds: number;
};

type State = {};

class SuspensionDialog extends React.PureComponent<Props, State> {
  render = (): React.ReactNode => {
    const { suspensionExpirationInSeconds } = this.props;
    const format = "MMM Do YYYY";
    const suspendedUntil = dayjs(suspensionExpirationInSeconds).format(format);

    return (
      <UIDialog
        type="floating-center"
        isOpen={this.props.isOpen}
        close={this.props.close}
        onDidDismiss={this.props.close}
      >
        <ConfirmationDialogView
          title={this.props.t("YOUVE_BEEN_TEMPORARILY_SUSPENDED")}
          onCancel={this.props.close}
          cancelBtnText={this.props.t("CLOSE").toLocaleUpperCase()}
        >
          <UISpacer h={20} />

          <UIText color="dark" variant="h4" weight="medium">
            <Trans
              i18nKey="PLEASE_CHECK_BACK_SUSPENSION_ON"
              values={{ suspendedUntil }}
              components={{
                LINK: <UILink href={NIANTIC_GUIDELINES_URL} className={styles.link} />,
              }}
            />
          </UIText>

          <UISpacer h={20} />

          <UIText color="dark" variant="h4" weight="medium">
            <Trans
              i18nKey="SUBMIT_AN_APPEAL"
              components={{
                LINK: <UILink href={NIANTIC_HELPSHIFT_BAN_APPEAL_URL} className={styles.link} />,
              }}
            />
          </UIText>
        </ConfirmationDialogView>
      </UIDialog>
    );
  };
}

export default withTranslation()(SuspensionDialog);
