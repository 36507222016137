/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { AppUpdateConsumer, ExposedProps } from "providers/AppUpdateProvider";

export type WithAppUpdateProps = {
  appUpdateProvider: ExposedProps;
};

// eslint-disable-next-line max-len
export default <P extends WithAppUpdateProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithAppUpdateProps>): React.ReactElement => (
    <AppUpdateConsumer>
      {(exposedProps: ExposedProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component appUpdateProvider={exposedProps} {...props} />
      )}
    </AppUpdateConsumer>
  );
};
