import React from "react";

export type Props = {
  children: React.ReactNode;
};

export default class UINavSlides extends React.Component<Props> {
  render = (): React.ReactNode => {
    return this.props.children;
  };
}
