import _ from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import ConfirmationDialogView from "common/components/ConfirmationDialogView";
import UIDialog from "common/components/UIDialog";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type ExposedProps = {
  onClickUrl: (url: string) => void;
};

type Props = WithTranslation & {
  children: React.ReactNode;
};

type State = {
  isConfirmOpenLinkOpen: boolean;
  url: string;
};

const INITIAL_CONTEXT: ExposedProps = {
  onClickUrl: () => undefined,
};

const ExternalLinkContext = React.createContext(INITIAL_CONTEXT);

const PROTOCOL_REGEX = /^[a-zA-Z]+:\/\//;

export const processUrl = (url: string): string => {
  let processedUrl = url;

  // Add a default protocol if there is no protocol detected.
  if (!processedUrl.match(PROTOCOL_REGEX)) {
    processedUrl = `http://${url}`;
  }

  return processedUrl;
};

class ExternalLinkProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isConfirmOpenLinkOpen: false,
      url: "",
    };
  }

  closeOpenLinkDialog = (): void => {
    this.setState({ isConfirmOpenLinkOpen: false });
  };

  renderOpenLinkButton = (): React.ReactNode => {
    return (
      <a
        className={styles.continueButtonLink}
        href={this.state.url}
        rel="noopener noreferrer"
        target="_blank"
        onClick={this.closeOpenLinkDialog}
      >
        <UIText variant="body1" color="white" weight="bold">
          {this.props.t("CONTINUE")}
        </UIText>
      </a>
    );
  };

  renderOpenLinkDialog = (): React.ReactNode => {
    return (
      <UIDialog
        type="floating-center"
        isOpen={this.state.isConfirmOpenLinkOpen}
        close={this.closeOpenLinkDialog}
      >
        <ConfirmationDialogView
          title={this.props.t("EXTERN_LINK_CONFIRM_TITLE")}
          description={this.props.t("EXTERN_LINK_CONFIRM_DESC")}
          cancelBtnText={this.props.t("BACK").toUpperCase()}
          onCancel={this.closeOpenLinkDialog}
          renderConfirmBtn={this.renderOpenLinkButton}
          onConfirm={_.noop}
        >
          <UISpacer h={8} />
          <UIText variant="h4" color="primary" weight="medium">
            {this.state.url}
          </UIText>
          <UISpacer h={8} />
        </ConfirmationDialogView>
      </UIDialog>
    );
  };

  onClickUrl = (url: string): void => {
    const processedUrl = processUrl(url);

    this.setState({ url: processedUrl, isConfirmOpenLinkOpen: true });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <ExternalLinkContext.Provider
        value={{
          onClickUrl: this.onClickUrl,
        }}
      >
        {this.renderOpenLinkDialog()}
        {children}
      </ExternalLinkContext.Provider>
    );
  };
}

const ExternalLinkProviderWithTranslation = withTranslation()(ExternalLinkProvider);

export { ExternalLinkProviderWithTranslation as ExternalLinkProvider };
export const ExternalLinkConsumer = ExternalLinkContext.Consumer;
