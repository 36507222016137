import React from "react";

export type ExposedProps = {
  runMethod: () => void;
};

type Props = {
  children: React.ReactNode;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  runMethod: () => undefined,
};

export const WayfarerDataContext = React.createContext(INITIAL_CONTEXT);

export class WayfarerDataProvider extends React.Component<Props, State> {
  runMethod = (): void => {
    return undefined;
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <WayfarerDataContext.Provider
        value={{
          runMethod: this.runMethod,
        }}
      >
        {children}
      </WayfarerDataContext.Provider>
    );
  };
}

export const WayfarerDataConsumer = WayfarerDataContext.Consumer;
