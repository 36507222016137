import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  title: string;
  subtitle: React.ReactElement;
  abuse: string;
};

const ResetAccountDataTitle: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.root}>
      <UIText color="dark" variant="h2" weight="bold">
        {props.title}
      </UIText>

      <UISpacer h={20} />

      {props.subtitle}

      {props.abuse.length > 0 ? (
        <>
          <UISpacer h={8} />
          <UIText className={styles.subtitleText} color="dark" variant="h4" weight="medium">
            {props.abuse}
          </UIText>
        </>
      ) : null}
    </div>
  );
};

export default withTranslation()(ResetAccountDataTitle);
