/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

// Store a small copy of the actual data to represent the reality channel.
// If we need full data, we can find by id.
export type RealityChannelMetadata = {
  id: string;
  name: string;
  iconURL: string;
};

type Key = "selectedRealityChannelMetadata";
type StoreType = {
  selectedRealityChannelMetadata: RealityChannelMetadata | null;
};

const DEFAULTS: StoreType = {
  selectedRealityChannelMetadata: null,
};

class SelectedRealityChannelStore extends BaseStore {
  constructor() {
    super("SelectedRealityChannelStore", DEFAULTS, false);
  }

  get(key: Key): any {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, value: any): GenericObject {
    return super.set(key, value);
  }

  getCachedSelectedRealityChannelMetadata = (): StoreType["selectedRealityChannelMetadata"] => {
    return this.get("selectedRealityChannelMetadata");
  };
}

export default new SelectedRealityChannelStore();
