import BaseStore from "common/stores/BaseStore";

const DEFAULTS = {};

/**
 * This store serves as the backing for ReportedEntityProvider, and should
 * completely mirror its `reportedEntities` state.
 */
class ReportedEntityStore extends BaseStore {
  constructor() {
    super("ReportedEntityStore", DEFAULTS, false);
  }
}

export default new ReportedEntityStore();
