/**
 * @generated SignedSource<<ab5b29b348fb68e58a14689cda333598>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClubUserProfileFetcher_Query$variables = {
  clubId: string;
  userId: string;
};
export type ClubUserProfileFetcher_Query$data = {
  readonly club: {
    readonly " $fragmentSpreads": FragmentRefs<"ClubUserProfileModerationActionMenu_club">;
  };
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ClubUserProfileModerationActionMenu_me" | "UserProfileFragment_me">;
  };
  readonly userById: {
    readonly " $fragmentSpreads": FragmentRefs<"ClubUserProfileModerationActionMenu_user" | "UserProfileFragment_user">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileFragment_query">;
};
export type ClubUserProfileFetcher_Query = {
  response: ClubUserProfileFetcher_Query$data;
  variables: ClubUserProfileFetcher_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "clubId",
    "variableName": "clubId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "clubId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ClubConnection",
  "kind": "LinkedField",
  "name": "memberOf",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClubEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Club",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClubUserProfileFetcher_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserProfileFragment_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserProfileFragment_me"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClubUserProfileModerationActionMenu_me"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserProfileFragment_user"
          },
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "ClubUserProfileModerationActionMenu_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClubUserProfileModerationActionMenu_club"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClubUserProfileFetcher_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PendingClubInvite",
        "kind": "LinkedField",
        "name": "outgoingPendingClubInvites",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "senderId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recipientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clubId",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userById",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isBlocked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMyFriend",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPendingFriendInviteFromMe",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGameProfile",
            "kind": "LinkedField",
            "name": "gameProfiles",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "game",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "codename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "faction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "factionColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "level",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastPlayedTimestampMs",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "kind": "ScalarField",
            "name": "isMember",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amIAdmin",
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "roles",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aeccb5d7df1c68849c06883100173aeb",
    "id": null,
    "metadata": {},
    "name": "ClubUserProfileFetcher_Query",
    "operationKind": "query",
    "text": "query ClubUserProfileFetcher_Query(\n  $userId: ID!\n  $clubId: ID!\n) {\n  ...UserProfileFragment_query\n  me {\n    ...UserProfileFragment_me\n    ...ClubUserProfileModerationActionMenu_me\n    id\n  }\n  userById(id: $userId) {\n    ...UserProfileFragment_user\n    ...ClubUserProfileModerationActionMenu_user_4tF2CU\n    id\n  }\n  club(id: $clubId) {\n    ...ClubUserProfileModerationActionMenu_club\n    id\n  }\n}\n\nfragment ClubModerationControl_club on Club {\n  id\n  name\n  roles {\n    id\n    name\n  }\n}\n\nfragment ClubModerationControl_me on User {\n  id\n}\n\nfragment ClubUserProfileModerationActionMenu_club on Club {\n  id\n  amIAdmin\n  name\n  creator {\n    id\n  }\n  ...ClubModerationControl_club\n}\n\nfragment ClubUserProfileModerationActionMenu_me on User {\n  id\n  isSuperAdmin\n  ...ClubModerationControl_me\n}\n\nfragment ClubUserProfileModerationActionMenu_user_4tF2CU on User {\n  id\n  displayName\n  isAdmin(clubId: $clubId)\n  isMember(clubId: $clubId)\n}\n\nfragment UserProfileFragment_me on User {\n  id\n  memberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n\nfragment UserProfileFragment_query on Query {\n  outgoingPendingClubInvites {\n    inviteId\n    senderId\n    recipientId\n    clubId\n  }\n}\n\nfragment UserProfileFragment_user on User {\n  id\n  username\n  displayName\n  avatarUrl\n  isBlocked\n  isMyFriend\n  hasPendingFriendInviteFromMe\n  gameProfiles {\n    id\n    game\n    codename\n    faction\n    factionColor\n    visibility\n    level\n    lastPlayedTimestampMs\n  }\n  memberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c48aaf8c0a815f1302b3d7206eae93d3";

export default node;
