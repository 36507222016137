import React from "react";

import ExternalNotificationActionHandler from "boot-loader/components/ExternalNotificationActionHandler";
import KeyboardEventActionHandler from "boot-loader/components/KeyboardEventActionHandler";
import NavigationActionHandler from "boot-loader/components/NavigationActionHandler";

type Props = {};

type State = {};

export default class EmbedRemoteActionHandler extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <>
        <ExternalNotificationActionHandler />
        <NavigationActionHandler />
        <KeyboardEventActionHandler />
      </>
    );
  };
}
