import { AxiosRequestConfig } from "axios";

import authAxios from "common/api/axios-instance";
import { CLIENT_INFO_HEADER } from "common/api/request-headers";
import { GameShortCodeWithBackwardsCompat } from "constants/nianticGame";

export type ClientInfo = {
  Game?: GameShortCodeWithBackwardsCompat;
  Platform?: string;
  AppId?: string;
  Language?: string;
  CampfireVersion?: string;
};

// Tracking the last interceptor added
let interceptorId: number | null = null;

/**
 * Configures an axios request interceptor to attach client info on requests.
 * TODO: Should clientInfo be dynamically changeable to account for async things?
 */
export const attachClientInfoToEachRequest = (clientInfo: ClientInfo): void => {
  // If we invoke this multiple times, clean up the previous interceptor so we
  // don't get a ton of interceptors added.
  if (interceptorId !== null) {
    authAxios.interceptors.request.eject(interceptorId);
  }

  interceptorId = authAxios.interceptors.request.use((config: AxiosRequestConfig) => {
    const embeddedClientInfoString = JSON.stringify(clientInfo);

    // Guess the new axios has updated the types here.
    if (!config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {};
    }

    // eslint-disable-next-line no-param-reassign
    config.headers[CLIENT_INFO_HEADER] = embeddedClientInfoString;

    return config;
  });
};
