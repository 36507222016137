// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CoachmarkStore from "common/stores/CoachmarkStore";

/**
 * As of 3/15/2023, we discovered that we're double JSON.stringifying the value before saving it into localStorage
 * via the BaseStore implementation (we're doing JSON.stringify and the underlying store2 library used by BaseStore
 * is doing it again).
 *
 * The BaseStore has been updated to correct this behavior and also account for legacy values that are still
 * double stringified. However, we're moving the CoachmarkProvider to a library and it pulls directly from
 * localStorage, so we want to make sure that the legacy formatting is cleaned up for the CoachmarkStore
 * prior to the CoachmarkProvider hooking in.
 */
const cleanupStores = (): void => {
  // Perform a read-only operation on the store which will update itself to clean the legacy format up
  CoachmarkStore.getStore();
};

export default cleanupStores;
