import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { createFragmentContainer, graphql } from "react-relay";
import { withRouter, RouteComponentProps } from "react-router-dom";

import ClubsPageStore from "common/stores/ClubsPageStore";
import { getQueryString, parseQuery } from "common/utils/url";
import { QUERY_PARAMS, CLUBS_ROUTE } from "constants/routes";

import { ClubsPageStateProvider_me$data as ClubsPageStateProviderMe } from "__generated__/ClubsPageStateProvider_me.graphql";

type ClubEdge = ExtractRelayEdge<ClubsPageStateProviderMe["memberOf"]>;
type Club = ExtractRelayEdgeNode<ClubsPageStateProviderMe["memberOf"]>;

export type ExposedProps = {
  currentClubId: string | null;
  changeCurrentClub: (clubId: string, updateUrl?: boolean) => void;
  clearCurrentClub: () => void;
  getInitialClub: () => string;
  getArbitraryClub: () => string;
};

type Props = RouteComponentProps<{ clubId?: string }> & {
  children: React.ReactNode;
  me: ClubsPageStateProviderMe;
};

type State = {
  currentClubId: string | null;
};

const INITIAL_CONTEXT: ExposedProps = {
  currentClubId: null,
  changeCurrentClub: _.noop,
  clearCurrentClub: _.noop,
  getInitialClub: () => "",
  getArbitraryClub: () => "",
};

export const ClubsPageStateContext = React.createContext(INITIAL_CONTEXT);

class ClubsPageStateProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentClubId: null,
    };
  }

  componentDidMount = (): void => {
    this.changeCurrentClub(this.getInitialClub(), false);
  };

  componentDidUpdate = (): void => {
    // Update the current club if we changed the route with a query param set
    // or from the path param. Compare with the clubId current set on state.
    const clubIdFromPathParam = this.props.match?.params.clubId;
    const queryParams = parseQuery(this.props.location.search);
    const clubIdFromQuery = queryParams[QUERY_PARAMS.clubs.clubId.key];

    // Prefer the path param over query param
    const nextClubId = clubIdFromPathParam || clubIdFromQuery;

    const clubIdIsDifferent = nextClubId !== this.state.currentClubId;

    if (nextClubId && clubIdIsDifferent) {
      this.changeCurrentClub(nextClubId, false);
    }
  };

  getInitialClub = (): string => {
    const queryParams = parseQuery(this.props.location.search);
    // For the /clubs route, we get the clubId via a query param, which is
    // a relic of a workaround when we relied on Ionic's router to perform animations.
    const clubIdFromQuery = queryParams[QUERY_PARAMS.clubs.clubId.key];
    // For more nested views, like /clubs/clubId/channelId, we can look for the clubId
    // in path params. This key "clubId" is defined at the router level.
    const clubIdFromPathParam = this.props.match?.params.clubId;

    const initialClubId =
      clubIdFromPathParam || clubIdFromQuery || ClubsPageStore.getCurrentClubId(this.props.me.id);

    if (!initialClubId) {
      return this.getArbitraryClub() || "";
    }

    return initialClubId;
  };

  getArbitraryClub = (): string => {
    const { memberOf } = this.props.me;

    const clubEdges = _.get(memberOf, "edges", []) as ClubEdge[];
    const clubs = clubEdges.map((edge: ClubEdge) => edge.node) as Club[];

    if (clubs.length) {
      return clubs[0].id;
    }

    return "";
  };

  changeCurrentClub = (clubId: string, updateUrl = false): void => {
    if (!clubId) {
      // if the clubId is empty, let's clear it
      // handles the case when there are no more clubs to change to
      this.clearCurrentClub(updateUrl);
      return;
    }

    if (clubId && clubId !== this.state.currentClubId) {
      ClubsPageStore.setCurrentClubId(this.props.me.id, clubId);

      // [SO-435]: Similar issue as SO-434, since this method is used in other handlers
      //           we want React to batch the state update and url update together if possible.
      //           Otherwise, if its processed sync, we will change the current club, trigger
      //           the componentDidUpdate, see the query param is different, and reset back to the
      //           previous club on the URL. The issue that is manifested is a dialog with only
      //           a backdrop is visible after leaving a club or really triggering a changeCurrentClub
      //           call. Since rapidly (and I mean REALLY fast) toggling the isOpen prop on an IonModal
      //           causes some weird behavior where the backdrop is not cleaned up among other things.
      try {
        ReactDOM.unstable_batchedUpdates(() => {
          const clubIdQueryString = QUERY_PARAMS.clubs.clubId.key;
          const queryParamUpdates = { [clubIdQueryString]: clubId };

          this.setState({ currentClubId: clubId });

          if (updateUrl) {
            this.props.history.replace({
              pathname: `/${CLUBS_ROUTE}`,
              search: getQueryString(queryParamUpdates, this.props.location.search),
            });
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error batching updates with React", error);
      }
    }
  };

  clearCurrentClub = (updateUrl = true): void => {
    // [SO-434]: Generally React will batched state updates together and perform them
    //           in a single cycle. With React 18, this is the default behavior, but in React 17,
    //           state updates are batched only in react event handlers. When this method is
    //           used onClick of a button for example, this would result in a batched update.
    //           However, when used in the issue tracked, it's invoked as a side effect of a button
    //           click event. As a result, react ends up flushing the state update synchronously
    //           (and in turn invoking componentDidUpdate) before moving to the line after
    //           the call to setState. Since the url may still have the clubId, we incorrectly
    //           think we need to change the current club. Since we generally assume state
    //           update are async, we wrap this in an unstable_batchedUpdates to ensure the
    //           route change and state update happen before the componentDidUpdate event is called.
    try {
      ReactDOM.unstable_batchedUpdates(() => {
        this.setState({ currentClubId: null });
        ClubsPageStore.setCurrentClubId(this.props.me.id, null);

        // Remove query param on url if needed
        const clubIdQueryString = QUERY_PARAMS.clubs.clubId.key;
        const queryParamUpdates = { [clubIdQueryString]: undefined };

        if (updateUrl) {
          this.props.history.replace({
            pathname: `/${CLUBS_ROUTE}`,
            search: getQueryString(queryParamUpdates, this.props.location.search),
          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error batching updates with React", error);
    }
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <ClubsPageStateContext.Provider
        value={{
          currentClubId: this.state.currentClubId,
          changeCurrentClub: this.changeCurrentClub,
          clearCurrentClub: this.clearCurrentClub,
          getInitialClub: this.getInitialClub,
          getArbitraryClub: this.getArbitraryClub,
        }}
      >
        {children}
      </ClubsPageStateContext.Provider>
    );
  };
}

const RouterConnectedProivder = withRouter(ClubsPageStateProvider);
const FragmentContainer = createFragmentContainer(RouterConnectedProivder, {
  me: graphql`
    fragment ClubsPageStateProvider_me on User {
      id
      isSuperAdmin
      memberOf {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});

export { FragmentContainer as ClubsPageStateProvider };
export const ClubsPageStateConsumer = ClubsPageStateContext.Consumer;
