import { IonLabel } from "@ionic/react";
import React from "react";

import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";
import UITextInput from "common/components/UITextInput";

import styles from "./styles.scss";

type Props = {
  label: string;
  value: string;
  onChange: (value: string) => void;
};

export default class OnboardElementLabelInput extends React.Component<Props> {
  render = (): React.ReactNode => {
    return (
      <>
        <IonLabel position="stacked">
          <UIText className={styles.idLabel} color="medium" weight="bold">
            {this.props.label}
          </UIText>
        </IonLabel>
        <UISpacer h={4} />
        <UITextInput
          className={styles.idInput}
          value={this.props.value}
          onIonChange={(event) => this.props.onChange(event.detail.value ?? "")}
        />
      </>
    );
  };
}
