import classnames from "classnames";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import {
  CampfirePageProvider,
  CAMPFIRE_PAGE_ID_ATTRIBUTE_NAME,
} from "providers/CampfirePageProvider";
import withKeyboard, { WithKeyboardProps } from "providers/KeyboardProvider/withKeyboard";

import hideKeyboardAndBlurActiveElement from "common/utils/hideKeyboardAndBlurActiveElement";

import styles from "./styles.scss";

export type Props = WithKeyboardProps & {
  pageClassname?: string;
  contentClassname?: string;
  isActivePage: boolean;
  children: React.ReactNode;
};

type State = {};

class CampfirePage extends React.Component<Props, State> {
  pageId = `campfire-page-id-${uuidv4()}`;

  componentDidUpdate = (prevProps: Readonly<Props>): void => {
    const isNoLongerActive = !this.props.isActivePage && prevProps.isActivePage;

    if (isNoLongerActive) {
      this.handleViewNoLongerActive();
    }
  };

  handleViewNoLongerActive = (): void => {
    // When a tab is no longer the active tab, if the keyboard is open (like from
    // a chat and a deeplink routes the app), close it.
    if (this.props.keyboard.isKeyboardOpen) {
      hideKeyboardAndBlurActiveElement();
    }
  };

  render = (): React.ReactNode => {
    const { isActivePage, pageClassname, contentClassname = "" } = this.props;

    const pageIdAttribute = {
      [CAMPFIRE_PAGE_ID_ATTRIBUTE_NAME]: this.pageId,
    };

    return (
      <CampfirePageProvider isActivePage={isActivePage} pageId={this.pageId}>
        <div
          className={classnames(styles.root, pageClassname, {
            [styles.hidden]: !isActivePage,
          })}
          style={{ zIndex: isActivePage ? 1 : 0 }}
          {...pageIdAttribute}
        >
          <div className={classnames(styles.content, contentClassname)}>{this.props.children}</div>
        </div>
      </CampfirePageProvider>
    );
  };
}

const KeyboardConnected = withKeyboard(CampfirePage);

export default KeyboardConnected;
