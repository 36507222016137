/**
 * @generated SignedSource<<a39eecdaf1b24720ef73054e2299f78d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Internal_page_Query$variables = {};
export type Internal_page_Query$data = {
  readonly me: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"InternalRouter_me">;
  };
};
export type Internal_page_Query = {
  response: Internal_page_Query$data;
  variables: Internal_page_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Internal_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InternalRouter_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Internal_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminToolsRole",
            "kind": "LinkedField",
            "name": "adminToolsRole",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userActivityExport",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gdprExport",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0eef6371d2c6bfc1276b970ef12b171",
    "id": null,
    "metadata": {},
    "name": "Internal_page_Query",
    "operationKind": "query",
    "text": "query Internal_page_Query {\n  me {\n    id\n    ...InternalRouter_me\n  }\n}\n\nfragment InternalRouter_me on User {\n  email\n  isSuperAdmin\n  adminToolsRole {\n    userActivityExport\n    gdprExport\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb51e04cb535f55d0b63839e961aac86";

export default node;
