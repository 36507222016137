import { ToggleChangeEventDetail, JSX } from "@ionic/core";
import { IonLabel, IonToggle } from "@ionic/react";
import classnames from "classnames";
import React, { HTMLAttributes } from "react";

import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = JSX.IonToggle &
  HTMLAttributes<HTMLIonToggleElement> & {
    customRenderUIText?: (label: string) => React.ReactNode;
    label?: string;
    onIonChange?: (event: CustomEvent<ToggleChangeEventDetail>) => void;
    toggleClassName?: string;
    dataTestIdInput?: string;
  };

type State = {};

export default class UIToggle extends React.Component<Props, State> {
  renderUIText = (label: string): React.ReactNode => {
    if (this.props.customRenderUIText) {
      return this.props.customRenderUIText(label);
    }

    return (
      <UIText color="dark" variant="h4" weight="medium">
        {label}
      </UIText>
    );
  };

  render = (): React.ReactNode => {
    const {
      label,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      customRenderUIText,
      className,
      toggleClassName,
      dataTestIdInput,
      ...remainingProps
    } = this.props;

    return (
      <div className={classnames(styles.root, className)} data-test-id={dataTestIdInput}>
        {label && <IonLabel className={styles.label}>{this.renderUIText(label)}</IonLabel>}
        <IonToggle className={classnames(styles.toggle, toggleClassName)} {...remainingProps} />
      </div>
    );
  };
}
