/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ReactGA from "react-ga";

/**
 * Dimension must be added via the Google Analytics Dashboard before use.
 * https://support.google.com/analytics/answer/2709829
 */
export enum DimensionType {
  clientInfo = "dimension1",
  isEmbedded = "dimension2",
  appVersion = "dimension3",
}

export default (dimensionType: DimensionType, value: any): void => {
  ReactGA.set({ [dimensionType]: JSON.stringify(value) });

  /*
    Temporarily logging to both Universal Analytics and Google Analytics 4 for comparison
  */
  gtag("set", "user_properties", {
    [dimensionType]: value,
  });
};
