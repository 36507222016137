/**
 * @generated SignedSource<<69a3719a1d7340a33d0d659adcb8d2c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type App_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppGlobalFlows_query" | "AppRouterV2_query" | "AuthenticatedServiceProviders_query">;
  readonly " $fragmentType": "App_query";
};
export type App_query$key = {
  readonly " $data"?: App_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"App_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "App_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppRouterV2_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppGlobalFlows_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthenticatedServiceProviders_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2a2f60d94f4d66888ed4a7fade8aeb63";

export default node;
