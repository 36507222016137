/**
 * @generated SignedSource<<ea18b92468650330f4d14126c98a440d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RealityChannelsInLatLngBoundsInput = {
  bounds?: LatLngBounds | null;
  zoomLevel?: number | null;
};
export type LatLngBounds = {
  ne: LatLng;
  sw: LatLng;
};
export type LatLng = {
  lat: number;
  lng: number;
};
export type preloader_RealityChannelSelection_Query$variables = {
  input: RealityChannelsInLatLngBoundsInput;
};
export type preloader_RealityChannelSelection_Query$data = {
  readonly realityChannelsInLatLngBounds: ReadonlyArray<{
    readonly iconURL: string;
    readonly id: string;
    readonly metadata: {
      readonly displayRank: number | null;
    } | null;
    readonly name: string;
  }>;
};
export type preloader_RealityChannelSelection_Query = {
  response: preloader_RealityChannelSelection_Query$data;
  variables: preloader_RealityChannelSelection_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RealityChannel",
    "kind": "LinkedField",
    "name": "realityChannelsInLatLngBounds",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "iconURL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RealityChannelMetadata",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayRank",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "preloader_RealityChannelSelection_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "preloader_RealityChannelSelection_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c74d72b5f448ea2f794ae7cd8a09e691",
    "id": null,
    "metadata": {},
    "name": "preloader_RealityChannelSelection_Query",
    "operationKind": "query",
    "text": "query preloader_RealityChannelSelection_Query(\n  $input: RealityChannelsInLatLngBoundsInput!\n) {\n  realityChannelsInLatLngBounds(input: $input) {\n    id\n    name\n    iconURL\n    metadata {\n      displayRank\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d261fca0750c3dca37ea1fe879892575";

export default node;
