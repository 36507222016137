import { Capacitor } from "@capacitor/core";

import { isEmbedded } from "common/utils/webInterop";

// TODO: Deprecate the isEmbedded ternary after we release new embed with properly namespaced
// window.webkit.messageHandlers.bridge lol.
export const isIos = isEmbedded ? false : Capacitor.getPlatform() === "ios";
export const isAndroid = isEmbedded ? false : Capacitor.getPlatform() === "android";
export const isWeb = isEmbedded ? true : Capacitor.getPlatform() === "web";

// To differentiate between web development in chrome vs embedded unity web.
export const isPurelyWeb = isWeb && !isEmbedded;

export const isStandalone = isIos || isAndroid;
export const { isNative } = Capacitor;
