import { InputChangeEventDetail } from "@ionic/core";
import { documentsOutline } from "ionicons/icons";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";
import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import { FEATURE_FLAGS } from "constants/featureFlags";
import HARDWARE_BACK_EVENT_PRIORITY from "constants/hardwareBackHierarchy";
import { ALL_REPORT_REASONS, ReportReason, ReportReasonValue } from "constants/reportReasons";
import { NIANTIC_IP_POLICY } from "constants/urls";

import MenuGroup, { MenuItem } from "common/components/MenuGroup";
import ReportConfirmationDialog from "common/components/Reporting/components/ReportConfirmationDialog";
import UIAsyncButton from "common/components/UIAsyncButton";
import UIButton from "common/components/UIButton";
import UICopy from "common/components/UICopy";
import UIIcon from "common/components/UIIcon";
import UILink from "common/components/UILink";
import UINav, {
  UINavSlides,
  UINavSlide,
  UINavSlideView,
  UINavOutlet,
} from "common/components/UINav";
import UIPane from "common/components/UIPane";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";
import UITextArea from "common/components/UITextArea";

import styles from "./styles.scss";

export type ReportDialogConfig = {
  // Custom content props
  title: string;
  description?: string;
  renderDescription?: () => React.ReactNode;
  renderReportedContent?: () => React.ReactNode;
  reportReasonPrompt: string;
  reportReasons?: ReportReason[];
  imageUrl?: string;
  // Custom event props
  report?: (reason: number, customReportReason: string) => Promise<boolean>;
  onReportPaneWillDismiss?: () => void;
  onReportPaneDidDismiss?: () => void;
  onConfirmationDidDismiss?: () => void;
  // Props for blocking
  confirmationShowsBlock: boolean;
  userId?: string;
  displayName?: string;
};

export type Props = WithTranslation &
  WithFeatureFlagProps &
  WithToastProps & {
    isReportPaneOpen: boolean;
    closeReportPane: () => void;
    config: ReportDialogConfig;
  };

type State = {
  customReportReason: string;
  isConfirmDialogOpen: boolean;
  isReportSuccess: boolean;
};

type MenuItemConfig = {
  id: string;
  name: string;
  description?: string;
  onClick: () => void;
};

class ReportDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      customReportReason: "",
      isConfirmDialogOpen: false,
      isReportSuccess: false,
    };
  }

  reportWithReason = async (reason: number): Promise<void> => {
    if (!this.props.config.report) {
      return;
    }

    const { customReportReason } = this.state;
    const response = await this.props.config.report(reason, customReportReason);

    this.setState({ isConfirmDialogOpen: true, isReportSuccess: response, customReportReason: "" });
    this.props.closeReportPane();
  };

  onCloseConfirmDialog = (): void => {
    this.setState({ isConfirmDialogOpen: false });
  };

  onCustomReportReasonChange = (event: CustomEvent<InputChangeEventDetail>): void => {
    this.setState({
      customReportReason: event.detail.value || "",
    });
  };

  onWillDismiss = (): void => {
    this.props.config.onReportPaneWillDismiss?.();
  };

  onDidDismiss = (): void => {
    this.setState({ customReportReason: "" });
    this.props.config.onReportPaneDidDismiss?.();
  };

  renderIPViolationSlide = (reason: ReportReason) => {
    return (
      <UINavSlide slideId={reason.value.toString()} key={reason.value.toString()}>
        {({ hideLastSlide }) => {
          return (
            <UINavSlideView headerFlat onClickBack={hideLastSlide}>
              <div className={styles.slide}>
                <div className={styles.flexNone}>
                  <UISpacer h={12} />
                  <UIText variant="h2" color="dark" weight="bold">
                    {this.props.t(reason.name)}
                  </UIText>
                  <UISpacer h={8} />
                  {this.props.config.imageUrl && (
                    <UIText variant="h3" color="dark">
                      <Trans
                        i18nKey={reason.longDescription}
                        components={{
                          LINK: (
                            <UILink
                              href={NIANTIC_IP_POLICY}
                              openInNewTab
                              className={styles.ipLink}
                            />
                          ),
                        }}
                      />
                    </UIText>
                  )}
                  <UISpacer h={12} />
                  <div className={styles.ipViolationImageContainer}>
                    <div className={styles.violationImageOverlay}>
                      <div
                        style={{ backgroundImage: `url(${this.props.config.imageUrl})` }}
                        className={styles.ipViolationImage}
                      />
                    </div>

                    <UICopy textToCopy={this.props.config.imageUrl || ""}>
                      {() => (
                        <UIButton
                          className={styles.centeredCopyButton}
                          onClick={() => {
                            this.props.toastProvider.showFeedbackToast(
                              this.props.t("COPIED_IMAGE_URL"),
                              "none",
                              true,
                            );
                          }}
                        >
                          <UIIcon icon={documentsOutline} />

                          <UIText
                            weight="bold"
                            variant="h5"
                            color="white"
                            className={styles.copyButtonText}
                          >
                            {this.props.t("COPY_IMAGE_URL")}
                          </UIText>
                        </UIButton>
                      )}
                    </UICopy>
                  </div>
                </div>
              </div>
            </UINavSlideView>
          );
        }}
      </UINavSlide>
    );
  };

  renderSlides = (reasonMenuItems: ReportReason[]): React.ReactNode => {
    const isUpdatedIPViolationReportFlowEnabled = this.props.hasFeatureFlag(
      FEATURE_FLAGS.REPORT_IP_VIOLATIONS,
    );
    const isReportingPost = this.props.config.title === this.props.t("REPORT_POST");
    const isPostIPViolationReportingEnabled = this.props.hasFeatureFlag(
      FEATURE_FLAGS.REPORT_POST_IP_VIOLATIONS,
    );

    return reasonMenuItems.map((reason) => {
      const isOther = reason.value === ReportReasonValue.REPORT_REASON_NAME_OTHER;

      if (
        reason.value === ReportReasonValue.REPORT_REASON_NAME_IP_VIOLATION &&
        isUpdatedIPViolationReportFlowEnabled &&
        (!isReportingPost || isPostIPViolationReportingEnabled)
      ) {
        return this.renderIPViolationSlide(reason);
      }

      return (
        <UINavSlide slideId={reason.value.toString()} key={reason.value.toString()}>
          {({ hideLastSlide }) => {
            return (
              <UINavSlideView
                title={isOther ? this.props.t("TELL_US_MORE") : this.props.t("SUBMIT_REPORT")}
                headerFlat
                onClickBack={hideLastSlide}
              >
                <div className={styles.slide}>
                  <div className={styles.flexNone}>
                    <div className={styles.center}>
                      <UIText variant="h4" color="dark">
                        {this.props.config.reportReasonPrompt}
                      </UIText>
                    </div>

                    <UISpacer h={24} />
                    {this.props.config.renderReportedContent &&
                      this.props.config.renderReportedContent()}
                    <UISpacer h={24} />
                  </div>
                  <div>
                    <UIText variant="h3" weight="bold" color="dark">
                      {this.props.t(reason.name)}
                    </UIText>

                    {reason.description && (
                      <>
                        <UISpacer h={8} />
                        <UIText color="dark">{this.props.t(reason.description)}</UIText>
                      </>
                    )}

                    {reason.longDescription && (
                      <>
                        <UISpacer h={12} />
                        <UIText
                          variant="body2"
                          color="medium"
                          className={styles.reportReasonLongDescription}
                        >
                          {/* Supports unordered lists in the reason's long description */}
                          <Trans
                            i18nKey={reason.longDescription}
                            components={{ UNORDEREDLIST: <ul />, LISTITEM: <li /> }}
                          />
                        </UIText>
                      </>
                    )}
                  </div>

                  {isOther && (
                    /* OTHER */ <div className={styles.stretch}>
                      <UISpacer h={20} />
                      {this.renderReportReasonLabel()}
                      <UITextArea
                        placeholder={this.props.t(reason.name)}
                        onIonChange={this.onCustomReportReasonChange}
                        value={this.state.customReportReason}
                        rows={5}
                        maxlength={1000}
                      />
                    </div>
                  )}

                  <div className={styles.flexGrow} />

                  <div>
                    <UIText className={styles.submitLabel} variant="body2" color="medium">
                      {this.props.t("REPORT_SUBMIT_DISCLAIMER")}
                    </UIText>
                    <UISpacer h={8} />
                    <UIAsyncButton
                      className={styles.submitBtn}
                      expand="block"
                      size="large"
                      onClick={() => this.reportWithReason(reason.value)}
                    >
                      <UIText variant="h4" weight="bold">
                        {this.props.t("SUBMIT_REPORT").toUpperCase()}
                      </UIText>
                    </UIAsyncButton>
                  </div>
                </div>
              </UINavSlideView>
            );
          }}
        </UINavSlide>
      );
    });
  };

  renderDescription = (): React.ReactNode => {
    if (this.props.config.renderDescription) {
      return this.props.config.renderDescription();
    }

    return <UIText color="dark">{this.props.config.description}</UIText>;
  };

  renderReportReasonLabel = (): React.ReactNode => {
    return (
      <div>
        <UIText className={styles.reportReasonLabel} variant="h4" weight="bold" color="medium">
          {this.props.t("REASON_FOR_REPORT")}
        </UIText>
        <UISpacer h={4} />
      </div>
    );
  };

  getRenderReasonMenuItems = (
    reportReasons: ReportReason[],
    showSlide: (id: string) => void,
  ): React.ReactElement[] => {
    return reportReasons.map((reason) => {
      const menuGroupId = reason.value.toString();

      const reasonMenuItem: MenuItemConfig = {
        id: menuGroupId,
        // Reason names are i18n keys.
        name: this.props.t(reason.name),
        description: reason.description ? this.props.t(reason.description) : undefined,
        onClick: () => {
          showSlide(menuGroupId);
        },
      };

      return (
        <MenuItem
          key={reasonMenuItem.id}
          name={reasonMenuItem.name}
          description={reasonMenuItem.description}
          onClick={reasonMenuItem.onClick}
        />
      );
    });
  };

  render = (): React.ReactNode => {
    const reportReasons = this.props.config.reportReasons ?? ALL_REPORT_REASONS;

    return (
      <>
        <ReportConfirmationDialog
          isConfirmDialogOpen={this.state.isConfirmDialogOpen}
          isReportSuccess={this.state.isReportSuccess}
          onCloseReportDialog={this.onCloseConfirmDialog}
          showBlockOption={this.props.config.confirmationShowsBlock}
          userId={this.props.config.userId}
          displayName={this.props.config.displayName}
          onDidDismiss={this.props.config.onConfirmationDidDismiss}
        />
        <UIPane
          className={styles.root}
          isOpen={this.props.isReportPaneOpen}
          onWillDismiss={this.onWillDismiss}
          onDidDismiss={this.onDidDismiss}
          close={this.props.closeReportPane}
          disableDefaultHeader
          hardwareBackPriority={HARDWARE_BACK_EVENT_PRIORITY.appRoot.reportDialog}
        >
          <UINav
            enableSwipebackOnSlides
            enableHardwareBack
            hardwareBackPriority={HARDWARE_BACK_EVENT_PRIORITY.appRoot["reportDialog|nav"]}
          >
            <UINavSlides>{this.renderSlides(reportReasons)}</UINavSlides>
            <UINavOutlet>
              {({ showSlide }) => {
                let filteredReasons = reportReasons;

                if (!this.props.config.imageUrl) {
                  filteredReasons = filteredReasons.filter(
                    (reason) => reason.value !== ReportReasonValue.REPORT_REASON_NAME_IP_VIOLATION,
                  );
                }

                const menuItems = this.getRenderReasonMenuItems(filteredReasons, showSlide);

                return (
                  <div className={styles.scroller}>
                    <div role="presentation" onClick={this.props.closeReportPane}>
                      <UISpacer h={80} />
                    </div>
                    <div className={styles.drawer}>
                      <div className={styles.drawerContent}>
                        <div className={styles.center}>
                          <UIText variant="h2" weight="bold" color="dark">
                            {this.props.config.title}
                          </UIText>
                          <UISpacer h={8} />
                          {this.renderDescription()}
                        </div>
                        {this.props.config.renderReportedContent && (
                          <>
                            <UISpacer h={24} />
                            {this.props.config.renderReportedContent()}
                          </>
                        )}
                        <UISpacer h={20} />

                        <div className={styles.stretch}>
                          {this.renderReportReasonLabel()}

                          <MenuGroup variant="disconnected">{menuItems}</MenuGroup>
                          <UIButton
                            expand="full"
                            size="large"
                            color="light"
                            fill="clear"
                            onClick={this.props.closeReportPane}
                          >
                            <UIText variant="body1" weight="bold">
                              {this.props.t("CANCEL").toUpperCase()}
                            </UIText>
                          </UIButton>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </UINavOutlet>
          </UINav>
        </UIPane>
      </>
    );
  };
}

const ToastConnected = withToast(ReportDialog);
const FeatureFlagConnected = withFeatureFlag(ToastConnected);
const TranslatedComponent = withTranslation()(FeatureFlagConnected);

export default TranslatedComponent;
