import React from "react";

import styles from "./styles.scss";

export type Props = {
  onClick: () => void;
};

type State = {};

export default class PGOExitGameButton extends React.PureComponent<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div role="presentation" className={styles.root} onClick={this.props.onClick}>
        <div className={styles.icon} />
      </div>
    );
  };
}
