import React from "react";

import UISpacer from "common/components/UISpacer";
import UISpinner from "common/components/UISpinner";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = {};

type State = {};

export default class BootloaderLoadingSpinner extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UISpinner color="dark" size={48} />
        <UISpacer h={16} />
        <UIText color="dark" variant="body1">
          It&apos;s taking a little longer, sit tight!
        </UIText>
      </div>
    );
  };
}
