import { RouteComponentProps } from "react-router-dom";

import { ACTIVITY_CENTER_ROUTE } from "constants/routes";

export const buildUrlToActivityCenter = (realityChannelId: string): string => {
  if (!realityChannelId) {
    return `/${ACTIVITY_CENTER_ROUTE}`;
  }

  return `/${ACTIVITY_CENTER_ROUTE}/${realityChannelId}`;
};

export const replaceRouteToActivityCenter = (
  history: RouteComponentProps["history"],
  realityChannelId?: string,
): void => {
  if (realityChannelId) {
    history.replace({
      pathname: buildUrlToActivityCenter(realityChannelId),
    });
  } else {
    history.replace({
      pathname: `/${ACTIVITY_CENTER_ROUTE}`,
    });
  }
};

export default (history: RouteComponentProps["history"], realityChannelId?: string): void => {
  if (realityChannelId) {
    history.push({
      pathname: buildUrlToActivityCenter(realityChannelId),
    });
  } else {
    history.push({
      pathname: `/${ACTIVITY_CENTER_ROUTE}`,
    });
  }
};
