import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import campfireLogo from "assets/images/campfire/campfire-logo.png";

import Image from "common/components/Image";
import UIButton from "common/components/UIButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  next: () => void;
};

type State = {};

/**
 * Only is inserted if there are no slides to show. Acts as an escpae hatch for the user.
 */
class AccountSetupEscapeSlide extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UISpacer h={100} />

        <UIText variant="h2" color="dark" weight="bold">
          {this.props.t("WELCOME_TO_CAMPFIRE_ONBOARDING")}
        </UIText>

        <UISpacer h={24} />

        <div>
          <UIText color="dark" variant="h4" weight="medium">
            {this.props.t("CAMPFIRE_IS_THE_PLACE_TO_DISCOVER")}
          </UIText>
        </div>

        <UISpacer h={36} />

        <div className={styles.campfireLogo}>
          <Image alt="campfire" src={campfireLogo} />
        </div>

        <UIButton className={styles.okButton} expand="block" onClick={this.props.next}>
          <UIText weight="bold">{this.props.t("OK").toLocaleUpperCase()}</UIText>
        </UIButton>
      </div>
    );
  };
}

export default withTranslation()(AccountSetupEscapeSlide);
