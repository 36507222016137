import { alertCircleOutline } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import UIButton from "common/components/UIButton";
import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {};

type State = {};

class FourOhFour extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIIcon icon={alertCircleOutline} color="dark" size={40} />

        <UISpacer h={16} />

        <UIText variant="h3" color="dark" weight="semiBold">
          {this.props.t("OOPS_THIS_PAGE_ISNT_AVAILABLE")}
        </UIText>

        <UISpacer h={16} />

        <Link to="/">
          <UIButton>
            <UIText variant="body1" weight="semiBold">
              {this.props.t("BACK_TO_HOME")}
            </UIText>
          </UIButton>
        </Link>
      </div>
    );
  };
}

export default withTranslation()(FourOhFour);
