/**
 * @generated SignedSource<<53b91b702f2af8bf45cd6cd4acb44225>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClubUserProfileModerationActionMenu_user$data = {
  readonly displayName: string;
  readonly id: string;
  readonly isAdmin: boolean;
  readonly isMember: boolean;
  readonly " $fragmentType": "ClubUserProfileModerationActionMenu_user";
};
export type ClubUserProfileModerationActionMenu_user$key = {
  readonly " $data"?: ClubUserProfileModerationActionMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClubUserProfileModerationActionMenu_user">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "clubId",
    "variableName": "clubId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clubId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClubUserProfileModerationActionMenu_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "isAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "isMember",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "b77f576431d9f5f9a3b493ce35a027ce";

export default node;
