import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import withAuth, { WithAuthProps } from "providers/AuthProvider/withAuth";

import ajaxPath from "common/api/ajaxPath";
import { get } from "common/api/axios";
import { OAuth, SignInResponse } from "common/capacitor/plugins/oauth";
import OnboardingStore from "common/stores/OnboardingStore";

type Props = RouteComponentProps &
  WithAuthProps & {
    children: React.ReactNode;
  };

export type ExposedProps = {
  authenticateWithOAuth: (oauthProvider: SupportedOAuthProvider) => Promise<unknown>;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  authenticateWithOAuth: () => Promise.resolve(),
};

const OAuthContext = React.createContext(INITIAL_CONTEXT);

class OAuthProvider extends React.Component<Props, State> {
  appListenerHandler: GenericObject | null;

  browserListenerHandler: GenericObject | null;

  constructor(props: Props) {
    super(props);

    this.appListenerHandler = null;
    this.browserListenerHandler = null;

    this.state = {};
  }

  componentWillUnmount = (): void => {
    this.cleanupListeners();
  };

  cleanupListeners = (): void => {
    if (this.appListenerHandler) {
      this.appListenerHandler.remove();
    }

    if (this.browserListenerHandler) {
      this.browserListenerHandler.remove();
    }
  };

  setupOAuthListeners = (): Promise<unknown> => {
    // Setup promise for listening for the browser window to either:
    // 1. Hear an app url, which denotes a completed oauth flow
    // 2. Window closes and we reject the promise.
    return new Promise((resolve, reject) => {
      // Prepare to open a browser and listen for the event which tells us the flow is complete.
      this.appListenerHandler = App.addListener("appUrlOpen", (data) => {
        // For iOS and android, we will hear the url via this event.
        // For web, we just parse it from the url in AuthProvider on mount.
        if (data.url) {
          this.props.authProvider.extractTokensFromLaunchUrl(data.url);
        }

        // VERY IMPORTANT TO CLEAN UP BEFORE WE CALL CLOSE SO THE EVENT DOES NOT TRIGGER!
        this.cleanupListeners();
        Browser.close();
        resolve(data);
      });

      this.browserListenerHandler = Browser.addListener("browserFinished", () => {
        this.cleanupListeners();
        reject();
      });
    });
  };

  authenticateWithOAuth = async (oauthProvider: SupportedOAuthProvider): Promise<void> => {
    const response = await OAuth.signIn({ provider: oauthProvider });

    if (!response.authCode) {
      return;
    }

    await this.invokeOauthCallback(oauthProvider, response);
  };

  invokeOauthCallback = async (
    oauthProvider: SupportedOAuthProvider,
    pluginResponse: SignInResponse,
  ): Promise<void> => {
    // By the time we get here, birthday has been set in OnboardingStore and it should have an actual value
    const birthday = OnboardingStore.get("initialLaunchBirthday");
    const state = {
      birthday,
      showRedirectSplash: false,
    };
    const path = this.getOauthCallbackPath(oauthProvider);
    const encodedState = btoa(JSON.stringify(state));

    const response = await get(
      `${path}?code=${pluginResponse.authCode}&state=${encodedState}&isStandalone=true`,
    );

    this.props.authProvider.saveTokens("", response.token, oauthProvider);
  };

  getOauthCallbackPath = (oauthProvider: SupportedOAuthProvider): string => {
    if (oauthProvider === "google") {
      return ajaxPath.get("googleAuthCallback");
    }

    if (oauthProvider === "facebook") {
      return ajaxPath.get("facebookAuthStandaloneCallback");
    }

    if (oauthProvider === "apple") {
      return ajaxPath.get("appleAuthCallback");
    }

    return "";
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <OAuthContext.Provider
        value={{
          authenticateWithOAuth: this.authenticateWithOAuth,
        }}
      >
        {children}
      </OAuthContext.Provider>
    );
  };
}

const AuthConnected = withAuth(OAuthProvider);
const OAuthProviderRouterConnected = withRouter(AuthConnected);

export { OAuthProviderRouterConnected as OAuthProvider };
export const OAuthConsumer = OAuthContext.Consumer;
