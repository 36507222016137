import environment from "common/relay/relay-env";
import React from "react";

import { KnownAnalyticsPayload } from "providers/AnalyticsProvider/fields";

import TeamUpStore from "common/stores/TeamUpStore";
import entryPointHistory from "common/utils/telemetry/entryPointHistory";
import TrackClientActionMutation, { ActionType } from "mutations/TrackClientActionMutation";

export type AnalyticsPayload =
  | KnownAnalyticsPayload
  | Record<
      string,
      | string
      | number
      | boolean
      | string[]
      | number[]
      | { [key: string]: number | boolean | string | string[] | string[] }[]
      | CampfireMap.LatLngBoundsForAnalytics
    >;

export type ExposedProps = {
  // In the preferably rare cases where we have data at one level, but not another, yet we
  // still want to track it. So you can grab args from the nearest analytics provider and pass
  // to another AnalyticsProvider. Ideally don't do this, but this exists if you really need to.
  contextArgs: Partial<AnalyticsPayload>;
  trackClientAction: (actionName: ActionType, additionalFields?: AnalyticsPayload) => Promise<void>;
  trackClientTeamUpAction: (
    actionName: ActionType,
    additionalFields?: AnalyticsPayload,
  ) => Promise<void>;
};

type Props = {
  // Contextual/Default arguments to pass to the payload.
  // I considered inheriting from multiple providers but it sounds way too messy, so the way
  // this is inteded to work is, if you need to change the default args passed, you can define
  // a new AnalyticsProvider scoped to a different branch in the React Component Tree. No
  // inheriting args from higher up providers.
  contextArgs: Partial<AnalyticsPayload>;
  children: React.ReactNode;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  contextArgs: Object.freeze({}),
  trackClientAction: () => Promise.reject(new Error("Analytics Provider not initialized!")),
  trackClientTeamUpAction: () => Promise.reject(new Error("Analytics Provider not initialized!")),
};

export const AnalyticsContext = React.createContext(INITIAL_CONTEXT);

export class AnalyticsProvider extends React.Component<Props, State> {
  trackClientAction = async (
    actionName: ActionType,
    additionalFields: AnalyticsPayload = {},
  ): Promise<void> => {
    const payload = {
      actionType: actionName,
      jsonPayload: {
        ...this.props.contextArgs,
        ...additionalFields,
      },
    };

    // Suppress the error
    try {
      await TrackClientActionMutation.commit(environment, payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // Adds extra fields wanted by all Team Up telemetry
  trackClientTeamUpAction = async (
    actionName: ActionType,
    additionalFields: AnalyticsPayload = {},
  ): Promise<void> => {
    const fields = {
      entryPoint: entryPointHistory.getLatestTeamUpEntryPoint(),
      funnelId: TeamUpStore.getFunnelId(),
      ...additionalFields,
    };

    return this.trackClientAction(actionName, fields);
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <AnalyticsContext.Provider
        value={{
          contextArgs: this.props.contextArgs,
          trackClientAction: this.trackClientAction,
          trackClientTeamUpAction: this.trackClientTeamUpAction,
        }}
      >
        {children}
      </AnalyticsContext.Provider>
    );
  };
}

export const AnalyticsConsumer = AnalyticsContext.Consumer;
