/**
 * @generated SignedSource<<9f7b1f0c23b15cb2d926abf67a350c43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SelectedRealityChannelProvider_rcById_Query$variables = {
  realityChannelId: string;
};
export type SelectedRealityChannelProvider_rcById_Query$data = {
  readonly realityChannelById: {
    readonly iconURL: string;
    readonly id: string;
    readonly name: string;
  };
};
export type SelectedRealityChannelProvider_rcById_Query = {
  response: SelectedRealityChannelProvider_rcById_Query$data;
  variables: SelectedRealityChannelProvider_rcById_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "realityChannelId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "realityChannelId"
      }
    ],
    "concreteType": "RealityChannel",
    "kind": "LinkedField",
    "name": "realityChannelById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "iconURL",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectedRealityChannelProvider_rcById_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectedRealityChannelProvider_rcById_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d7bfc938196a8c113acc52ead54b94c1",
    "id": null,
    "metadata": {},
    "name": "SelectedRealityChannelProvider_rcById_Query",
    "operationKind": "query",
    "text": "query SelectedRealityChannelProvider_rcById_Query(\n  $realityChannelId: ID!\n) {\n  realityChannelById(id: $realityChannelId) {\n    id\n    name\n    iconURL\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d8eaa5d874b0ba19217286eb9fb0ac7";

export default node;
