import { CallbackError, Location } from "@capacitor-community/background-geolocation/definitions";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withCurrentLocation, {
  WithCurrentLocationProps,
} from "providers/CurrentLocationProvider/withCurrentLocation";
import withLocationShare, {
  WithLocationShareProps,
} from "providers/LocationShareProvider/withLocationShare";

import { BackgroundGeolocation } from "common/capacitor/communityPlugins";

import PollingRefetcher from "common/components/PollingRefetcher";

type Props = WithLocationShareProps & WithTranslation & WithCurrentLocationProps & {};

type State = {};

type LocationCoords = {
  latitude: number | null;
  longitude: number | null;
};

const STANDALONE_GEOLOCATION_TIMEOUT_MS = 5000;
const MAX_ATTEMPTS_BEFORE_RESENDING_LOCATION = 3;

/**
 * Constantly updates the backend of the users current location.
 *
 * When background location sharing is available, we use a watcher on the background location
 * service to get location updates. When it is not available, we poll the official geolocation plugin
 * for the user's current location.
 *
 * The foreground location sharing poller essentially operates like Stimpack from Starcraft. When we want to share location,
 * we just accelerate the rate at which we send updates. If we stim again before stim expires,
 * it keeps the same elevated rate, but extends the timer.
 * Eventually, when stim expires, we reset back to our base rate.
 */
class LocationPinger extends React.Component<Props, State> {
  sameLocationAttemptCounter = 0;

  lastCoords: LocationCoords = { latitude: null, longitude: null };

  fetchingCurrentPosition = false;

  backgroundGeolocationWatcherId = "";

  componentDidUpdate = () => {
    const { updateRateMs, useBackgroundLocationSharing } = this.props.locationShare;

    if (updateRateMs > 0 && useBackgroundLocationSharing) {
      this.startBackgroundGeolocationWatcher();
    } else if (updateRateMs === 0 || !useBackgroundLocationSharing) {
      this.stopBackgroundGeolocationWatcher();
    }
  };

  resetToDefaults = (): void => {
    this.props.locationShare.resetLocationUpdateRate();
  };

  needsToResetRate = (): boolean => {
    const now = Date.now();
    const timeMsWhenRateShouldReset = this.props.locationShare.expiresAt;

    if (timeMsWhenRateShouldReset) {
      return now > timeMsWhenRateShouldReset;
    }

    return false;
  };

  positionHasChanged = (nextCoords: LocationCoords, currentCoords: LocationCoords): boolean => {
    return (
      nextCoords.latitude !== currentCoords.latitude ||
      nextCoords.longitude !== currentCoords.longitude
    );
  };

  // Add a background location watcher, if no current watcher exists.
  startBackgroundGeolocationWatcher = async (): Promise<void> => {
    if (!this.backgroundGeolocationWatcherId) {
      const id = await BackgroundGeolocation.addWatcher(
        {
          backgroundTitle: this.props.t("SHARING_BACKGROUND_LOCATION"),
          backgroundMessage: this.props.t("SHARING_BACKGROUND_LOCATION_DESCRIPTION"),
          requestPermissions: true,
        },
        this.handleBackgroundLocation,
      );

      this.backgroundGeolocationWatcherId = id;
    }
  };

  // Remove the current background location watcher, if it exists
  stopBackgroundGeolocationWatcher = () => {
    if (this.backgroundGeolocationWatcherId) {
      BackgroundGeolocation.removeWatcher({ id: this.backgroundGeolocationWatcherId });
      this.backgroundGeolocationWatcherId = "";
    }
  };

  handleBackgroundLocation = async (location?: Location, error?: CallbackError) => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`error when trying to handling background geolocation: ${error}`);
      return;
    }

    if (!location) {
      return;
    }

    const payload = {
      latitude: location.latitude,
      longitude: location.longitude,
    };

    await this.updateLocation(payload);
  };

  updateLocation = async (payload: { latitude: number; longitude: number }) => {
    // Check if the position is any different, exit early if the location
    // we got looks the same as the previous, no need to update then.
    const positionHasChanged = this.positionHasChanged(payload, this.lastCoords);

    // If the position isn't any different, and the counter hasn't been reached,
    // increment the attempt counter and exit early.
    if (
      !positionHasChanged &&
      this.sameLocationAttemptCounter <= MAX_ATTEMPTS_BEFORE_RESENDING_LOCATION
    ) {
      this.sameLocationAttemptCounter += 1;
      return;
    }

    // Reset the counter to 0.
    this.sameLocationAttemptCounter = 0;

    // Update last saved coords
    this.lastCoords = payload;

    const response = await this.props.currentLocationProvider.pingLocation(payload);

    // Check if we are still sharing to some club.
    const isStillSharing = !!response.me.location;

    this.onLocationUpdateSuccess(isStillSharing);
  };

  pollUserLocation = async (): Promise<void> => {
    // Don't invoke again if we are currently fetching the current position.
    // Now that the rate can change dynamically, we need to enforce we don't overload the
    // client device with requests to current position.
    if (this.fetchingCurrentPosition) {
      return;
    }

    // Denote we are fetching current location
    this.fetchingCurrentPosition = true;

    try {
      const geoPosition = await this.props.currentLocationProvider.getCurrentPosition(
        {
          maximumAge: 0,
          timeout: STANDALONE_GEOLOCATION_TIMEOUT_MS,
          enableHighAccuracy: true,
        },
        false,
      );

      if (geoPosition && geoPosition.coords) {
        // Build Payload
        const payload = {
          latitude: geoPosition.coords.latitude,
          longitude: geoPosition.coords.longitude,
        };

        await this.updateLocation(payload);
      }
    } catch (e) {
      this.onLocationUpdateFailed();
    } finally {
      this.fetchingCurrentPosition = false;
    }
  };

  onLocationUpdateSuccess = (backendDenotesUserStillSharingInSomeClubs: boolean): void => {
    const shouldResetRateToDefault =
      !backendDenotesUserStillSharingInSomeClubs || this.needsToResetRate();

    // Check if we need to reset our update rate. Based on either the backend response,
    // or a client-based expiry safegaurd.
    if (shouldResetRateToDefault) {
      this.resetToDefaults();
    }
  };

  onLocationUpdateFailed = (): void => {
    // If the location update failed, then we only use the client side check
    // This way, if there is a persistent error on the server, we will eventually reset the rate.
    if (this.needsToResetRate()) {
      this.resetToDefaults();
    }
  };

  render = (): React.ReactNode => {
    const { updateRateMs, useBackgroundLocationSharing } = this.props.locationShare;

    if (!useBackgroundLocationSharing) {
      // Restart the PollingRefetcher when the rate changes.
      return (
        <PollingRefetcher
          key={updateRateMs}
          pollingFn={this.pollUserLocation}
          intervalTimeMs={updateRateMs}
        />
      );
    }

    return null;
  };
}

const withLocationShareComponent = withLocationShare(LocationPinger);
const CurrentLocationConnected = withCurrentLocation(withLocationShareComponent);

export default withTranslation()(CurrentLocationConnected);
