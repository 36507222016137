export enum OnboardingEvent {
  WELCOME = "welcome",
  MAP_DISCOVERY = "map-discovery",
  FRIEND_LOCATION = "friend-location",
  NIANTIC_ID = "niantic-id",
  LOCATION = "location",
  OFFICIAL_CLUB = "official-club",
  PUSH_NOTIFICATIONS = "push-notifications",
  USER_REFERRAL = "user-referral",
  EMAIL_NOTIFICATIONS = "email-notifications",
  NIANTIC_GAMES = "niantic-games",
  NIANTIC_FRIENDS = "niantic-friends",
}

const onboardingEventOrder = [
  OnboardingEvent.WELCOME,
  OnboardingEvent.MAP_DISCOVERY,
  OnboardingEvent.FRIEND_LOCATION,
  OnboardingEvent.NIANTIC_ID,
  OnboardingEvent.LOCATION,
  OnboardingEvent.OFFICIAL_CLUB,
  OnboardingEvent.PUSH_NOTIFICATIONS,
  OnboardingEvent.USER_REFERRAL,
  OnboardingEvent.EMAIL_NOTIFICATIONS,
];

export const getOnboardingEventPayload = (
  event: OnboardingEvent,
  accepted = true,
): Record<string, number | string | boolean> => {
  const index = onboardingEventOrder.indexOf(event);

  return {
    eventId: event.toString(),
    sequentialOrder: index,
    isLastSequentialEvent: index === onboardingEventOrder.length - 1,
    accepted,
  };
};
