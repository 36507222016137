import dayjs from "common/utils/dayjs";
import { getLanguage } from "common/utils/i18n";

/**
 * Locale codes between Campfire translation and dayjs are not always the same.
 * Use the following map for mapping.
 * If the target language code is not in the map keys(), use English as an alternative.
 * Update the following record Campifre supports more languages.
 * dayjs list of supported locale: https://github.com/iamkun/dayjs/tree/dev/src/locale
 * Note:
 *   - Campfire Simplified Chinese Code: zh-CN
 *   - Campfire Tranditional Chinese Code: zh, zh-HK, zh-TW
 *   - dayjs Simplified Chinese Code: zh, zh-ch
 *   - dayjs Traditional Chinese Code: zh-tw, zh-hk
 */
const I18N_DAYJS_CODE_MAPPING: Record<string, string> = {
  UNKNOWN: "en",
  cs: "cs",
  de: "de",
  en: "en",
  es: "es",
  fr: "fr",
  it: "it",
  ja: "ja",
  ko: "ko",
  nl: "nl",
  no: "nb",
  pl: "pl",
  pt: "pt",
  ru: "ru",
  sv: "sv",
  th: "th",
  tr: "tr",
  zh: "zh-tw",
  "zh-CN": "zh-cn",
  "zh-TW": "zh-tw",
};

// Globally set dayjs locale
const setDayjsLocaleCode = (deviceLocale: string): void => {
  if (I18N_DAYJS_CODE_MAPPING[deviceLocale]) {
    const code = I18N_DAYJS_CODE_MAPPING[deviceLocale];

    dayjs.locale(code);
  } else {
    dayjs.locale("en");
  }
};

const getLanguageAndSetDayJsLocale = async (): Promise<void> => {
  const language = await getLanguage();

  setDayjsLocaleCode(language);
};

export default getLanguageAndSetDayJsLocale;
