/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { PushNotificationConsumer, ExposedProps } from "providers/PushNotificationProvider";

export type WithPushNotificationProps = {
  registerPushNotifications: ExposedProps["registerPushNotifications"];
  unregisterPushNotifications: ExposedProps["unregisterPushNotifications"];
};

// eslint-disable-next-line max-len
export default <P extends WithPushNotificationProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithPushNotificationProps>): React.ReactElement => (
    <PushNotificationConsumer>
      {({ registerPushNotifications, unregisterPushNotifications }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          registerPushNotifications={registerPushNotifications}
          unregisterPushNotifications={unregisterPushNotifications}
          {...props}
        />
      )}
    </PushNotificationConsumer>
  );
};
