import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import UIButton from "common/components/UIButton";
import UIDialog from "common/components/UIDialog";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  isOpen: boolean;
  isAbusive: boolean;
  closeDialog: () => void;
};

type State = {};

class ReactAccountDataSuccessDialog extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <UIDialog
        type="floating-center"
        cssClass={styles.root}
        isOpen={this.props.isOpen}
        close={this.props.closeDialog}
      >
        <div className={styles.messageContainer}>
          <UIText color="dark" variant="h2" weight="bold">
            {this.props.t("RESET_UPDATE_NIANTIC_ID_SUCCESS")}
          </UIText>

          {this.props.isAbusive ? (
            <>
              <UISpacer h={8} />
              <UIText className={styles.subtitleText} color="dark" variant="h4" weight="medium">
                {this.props.t("RESET_REPEATED_OFFENSES")}
              </UIText>
            </>
          ) : null}

          <UIButton
            className={styles.closeBtn}
            onClick={this.props.closeDialog}
            color="light"
            expand="block"
            fill="solid"
          >
            <UIText weight="bold">{this.props.t("CLOSE").toUpperCase()}</UIText>
          </UIButton>
        </div>
      </UIDialog>
    );
  };
}

export default withTranslation()(ReactAccountDataSuccessDialog);
