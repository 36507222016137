/**
 * @generated SignedSource<<468132da003a868dcd6c71fa1a39b91f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type App_hydrate_Query$variables = {};
export type App_hydrate_Query$data = {
  readonly me: {
    readonly displayName: string;
    readonly email: string;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"App_me">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"App_query">;
};
export type App_hydrate_Query = {
  response: App_hydrate_Query$data;
  variables: App_hydrate_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "App_hydrate_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "App_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "App_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "App_hydrate_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unreadMentionsCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unreadDirectMessagesCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "officialClub",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSetting",
        "kind": "LinkedField",
        "name": "clientSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slowLocationPushIntervalMs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fastLocationPushIntervalMs",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signalsChannelGroupName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messagesChannelGroupName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pubnubUserMessageChannelName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "blockedUsers",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "blockedByUsers",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pubnubAuthKey",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureFlags",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "interactionHistory",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0b30683c63feb432f07cf0b21df980e",
    "id": null,
    "metadata": {},
    "name": "App_hydrate_Query",
    "operationKind": "query",
    "text": "query App_hydrate_Query {\n  ...App_query\n  me {\n    email\n    username\n    displayName\n    ...App_me\n    id\n  }\n}\n\nfragment AppGlobalFlows_me on User {\n  id\n  username\n}\n\nfragment AppGlobalFlows_query on Query {\n  officialClub {\n    id\n    name\n    avatarUrl\n  }\n}\n\nfragment AppRouterV2_me on User {\n  id\n  isSuperAdmin\n  ...CoreNavigationFloatingTabBar_me\n}\n\nfragment AppRouterV2_query on Query {\n  ...CoreNavigationFloatingTabBar_query\n}\n\nfragment App_me on User {\n  id\n  ...AppRouterV2_me\n  ...AppGlobalFlows_me\n  ...AuthenticatedServiceProviders_me\n}\n\nfragment App_query on Query {\n  ...AppRouterV2_query\n  ...AppGlobalFlows_query\n  ...AuthenticatedServiceProviders_query\n}\n\nfragment AuthenticatedServiceProviders_me on User {\n  id\n  signalsChannelGroupName\n  messagesChannelGroupName\n  pubnubUserMessageChannelName\n  blockedUsers {\n    id\n    displayName\n    avatarUrl\n    username\n  }\n  blockedByUsers {\n    id\n  }\n  pubnubAuthKey\n  featureFlags\n  ...GlobalSignalHandlers_me\n  ...GlobalMessageHandlers_me\n  ...UserInteractionHistoryProvider_me\n}\n\nfragment AuthenticatedServiceProviders_query on Query {\n  clientSetting {\n    slowLocationPushIntervalMs\n    fastLocationPushIntervalMs\n  }\n}\n\nfragment CoreNavigationFloatingTabBar_me on User {\n  id\n  avatarUrl\n  displayName\n}\n\nfragment CoreNavigationFloatingTabBar_query on Query {\n  unreadMentionsCount\n  unreadDirectMessagesCount\n}\n\nfragment GlobalMessageHandlers_me on User {\n  id\n  pubnubUserMessageChannelName\n}\n\nfragment GlobalSignalHandlers_me on User {\n  id\n}\n\nfragment UserInteractionHistoryProvider_me on User {\n  id\n  interactionHistory\n}\n"
  }
};
})();

(node as any).hash = "e8824e35d9447902ff962c65da057bc2";

export default node;
