/**
 * @generated SignedSource<<a75900cba3d2bba8bc93300dffa3bb5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsPage_Query$variables = {};
export type NotificationsPage_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationsPageMainContent_me">;
  };
};
export type NotificationsPage_Query = {
  response: NotificationsPage_Query$data;
  variables: NotificationsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationsPageMainContent_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGameProfile",
            "kind": "LinkedField",
            "name": "gameProfiles",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "game",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "codename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "faction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "factionColor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotificationSettings",
            "kind": "LinkedField",
            "name": "notificationSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groupInvitationEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groupNewMemberEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groupMemberLocationShareEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventReminderEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "friendRequestEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notificationDestinationSettings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "beaconSameLitScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "beaconNearMeScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postLikedScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postFriendCreatedScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postCreatedNearMeScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "eventInviteScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "outgoingFlareScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "marketingScope",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c23a36cbe0f04051b67d6f6d75a91eca",
    "id": null,
    "metadata": {},
    "name": "NotificationsPage_Query",
    "operationKind": "query",
    "text": "query NotificationsPage_Query {\n  me {\n    ...NotificationsPageMainContent_me\n    id\n  }\n}\n\nfragment NotificationsPageMainContent_me on User {\n  id\n  ...UserNotificationSettings_me\n}\n\nfragment UserNotificationSettings_me on User {\n  gameProfiles {\n    id\n    game\n    codename\n    faction\n    factionColor\n  }\n  notificationSettings {\n    groupInvitationEnabled\n    groupNewMemberEnabled\n    groupMemberLocationShareEnabled\n    eventReminderEnabled\n    friendRequestEnabled\n    notificationDestinationSettings\n    beaconSameLitScope\n    beaconNearMeScope\n    postLikedScope\n    postFriendCreatedScope\n    postCreatedNearMeScope\n    eventInviteScope\n    outgoingFlareScope\n    marketingScope\n  }\n}\n"
  }
};
})();

(node as any).hash = "eaefde7cf0fe012e5ebe54c489e98b92";

export default node;
