import axios from "axios";
import React from "react";

import config from "constants/config";

export type ExposedProps = {
  exposedFeatureFlags: Record<string, boolean>;
  minFrontendVersion: string;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  // Enumerate the standalone settings we support here
  exposedFeatureFlags: Record<string, boolean>;
  minFrontendVersion: string;
};

const GLOBAL_CONFIG_BASE_URL = config.get("CAMPFIRE_APP_GLOBAL_CONFIG_BASE_URL");
const STANDALONE_SETTINGS_URL = `${GLOBAL_CONFIG_BASE_URL}/standalone_settings.json`;
const TIMEOUT = 2000;

const INITIAL_CONTEXT: ExposedProps = {
  exposedFeatureFlags: {},
  minFrontendVersion: "",
};

export const StandaloneSettingsContext = React.createContext(INITIAL_CONTEXT);

export class StandaloneSettingsProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      exposedFeatureFlags: {},
      minFrontendVersion: "",
    };
  }

  componentDidMount = async (): Promise<void> => {
    const standaloneSettings = await this.getStandaloneSettings();

    this.setState(standaloneSettings);
  };

  getStandaloneSettings = async (): Promise<State> => {
    try {
      // Use axios directly to bypass CORS issue due to probable conflict with baseURL config in our common instance
      const standaloneSettingsPromise = axios.get(STANDALONE_SETTINGS_URL, {
        timeout: TIMEOUT, // Request timeout
      });

      const response = await standaloneSettingsPromise;

      return {
        exposedFeatureFlags: response.data.exposedFeatureFlags || {},
        minFrontendVersion: response.data.minFrontendVersion || "0.0.0",
      };
    } catch (error) {
      return {
        exposedFeatureFlags: {},
        minFrontendVersion: "0.0.0",
      };
    }
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    const { exposedFeatureFlags, minFrontendVersion } = this.state;

    // The FeatureFlagProvider here is at a higher level than the one in AuthenticatedServiceProviders
    // and so that one takes precedence over this one once the user is authenticated
    // and the full set of available feature flags are returned.
    return (
      <StandaloneSettingsContext.Provider
        value={{
          exposedFeatureFlags,
          minFrontendVersion,
        }}
      >
        {children}
      </StandaloneSettingsContext.Provider>
    );
  };
}

export const StandaloneSettingsConsumer = StandaloneSettingsContext.Consumer;
