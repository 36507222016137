import { createGesture } from "@ionic/core";
import { Gesture, GestureDetail } from "@ionic/core/dist/types/utils/gesture";
import { useCallback, useRef } from "react";
import ReactDOM from "react-dom";

type Props = {
  translateTo: (positionPx: number) => void;
  onSwipeBackStart: () => void;
  onSwipeBackEnd: (shouldHide: boolean) => void;
};

type RefCallback = (el: HTMLDivElement) => void;
type HookReturnType = [RefCallback];

function useSlideGesture(props: Props): HookReturnType {
  const gestureRef = useRef<Gesture | undefined>(undefined);

  const onPanStart = () => {
    props.onSwipeBackStart();
  };

  const onPan = (gestureDetail: GestureDetail) => {
    const distanceToRight = Math.max(0, gestureDetail.deltaX);

    props.translateTo(distanceToRight);
  };

  const onPanEnd = (gestureDetail: GestureDetail) => {
    const distanceToRight = Math.max(0, gestureDetail.deltaX);
    const shouldHide = distanceToRight > 100;

    // Since React 17 does not batch updates by default unless inside a react event,
    // when we invoke in a gesture, we also want to batch updates since it's a tad faster.
    ReactDOM.unstable_batchedUpdates(() => {
      props.onSwipeBackEnd(shouldHide);
    });
  };

  const initializeGesture = (el: HTMLDivElement): Gesture => {
    const gesture = createGesture({
      el,
      gestureName: "ui-nav-slide-gesture",
      threshold: 1,
      direction: "x",
      disableScroll: true,
      onStart: onPanStart,
      onMove: onPan,
      onEnd: onPanEnd,
    });

    gesture.enable();

    return gesture;
  };

  // When the drawer element comes into the DOM, initialize the gesture for that element.
  // When the element is removed, destroy the gesture.
  const slideContentRef = useCallback((el: HTMLDivElement) => {
    if (el !== null) {
      gestureRef.current = initializeGesture(el);
    } else if (gestureRef.current) {
      gestureRef.current.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [slideContentRef];
}

export default useSlideGesture;
