import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { CLUBS_ROUTE } from "constants/routes";

import UIButton from "common/components/UIButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & RouteComponentProps;

type State = {};

class ChannelLoadError extends React.Component<Props, State> {
  backToGroups = (): void => {
    this.props.history.push(`/${CLUBS_ROUTE}`);
  };

  render = (): React.ReactNode => {
    const channelTypeStr = "channel";

    return (
      <div className={styles.root}>
        <UIText variant="h3" color="dark" weight="semiBold">
          {this.props.t("SORRY_WERE_HAVING_TROUBLE_LOADING_THIS_VALUE", { value: channelTypeStr })}
        </UIText>

        <UISpacer h={24} />

        <UIButton color="secondary" onClick={this.backToGroups}>
          <UIText variant="body1" weight="medium">
            {this.props.t("BACK_TO_GROUPS")}
          </UIText>
        </UIButton>
      </div>
    );
  };
}

const RouterConnected = withRouter(ChannelLoadError);

export default withTranslation()(RouterConnected);
