import _ from "lodash";
import React from "react";

import ReportDialog, {
  ReportDialogConfig,
} from "common/components/Reporting/components/ReportDialog";

export type ExposedProps = {
  showReportDialog: (config: ReportDialogConfig) => void;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  isReportPaneOpen: boolean;
  reportDialogConfig: ReportDialogConfig;
};

const INITIAL_CONTEXT: ExposedProps = {
  showReportDialog: _.noop,
};

export const ReportDialogContext = React.createContext(INITIAL_CONTEXT);

export class ReportDialogProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const defaultConfig = {
      title: "",
      reportReasonPrompt: "",
      confirmationShowsBlock: false,
    };

    this.state = {
      reportDialogConfig: defaultConfig,
      isReportPaneOpen: false,
    };
  }

  showReportDialog = (config: ReportDialogConfig): void => {
    this.setState({ reportDialogConfig: config, isReportPaneOpen: true });
  };

  hideReportDialog = (): void => {
    this.setState({ isReportPaneOpen: false });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;
    const config = this.state.reportDialogConfig;

    return (
      <ReportDialogContext.Provider
        value={{
          showReportDialog: this.showReportDialog,
        }}
      >
        {config && (
          <ReportDialog
            isReportPaneOpen={this.state.isReportPaneOpen}
            closeReportPane={this.hideReportDialog}
            config={this.state.reportDialogConfig}
          />
        )}
        {children}
      </ReportDialogContext.Provider>
    );
  };
}

export const ReportDialogConsumer = ReportDialogContext.Consumer;
