import { PermissionStatus, Camera } from "@capacitor/camera";

import logException from "common/analytics/exceptions";

export const validator = (permissionStatus: PermissionStatus): boolean => {
  const permissionResult = permissionStatus.camera;

  try {
    return !!permissionResult;
  } catch (error) {
    logException(
      "validating Camera.requestPermissions interop response",
      "validator",
      "requestPermissions",
      error,
    );
    return false;
  }
};

export default Camera.requestPermissions;
