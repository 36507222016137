import classnames from "classnames";
import { chevronDownOutline } from "ionicons/icons";
import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import withKeyboard, { WithKeyboardProps } from "providers/KeyboardProvider/withKeyboard";
import withRouteHistory, {
  WithRouteHistoryProps,
} from "providers/RouteHistoryProvider/withRouteHistory";
import withSelectedRealityChannel, {
  WithSelectedRealityChannelProps,
} from "providers/SelectedRealityChannelProvider/withSelectedRealityChannel";
import { getDynamicInteractionKey } from "providers/UserInteractionHistoryProvider/interactions";
import withUserInteractionHistory, {
  WithUserInteractionHistoryProps,
} from "providers/UserInteractionHistoryProvider/withUserInteractionHistory";

import entryPointHistory, { EntryPoint } from "common/utils/telemetry/entryPointHistory";
import { isEmbedded } from "common/utils/webInterop";
import { existingRealityChannels } from "constants/nianticGame";
import {
  ACTIVITY_CENTER_ROUTE,
  CLUBS_ROUTE,
  MAP_ROUTE,
  MESSAGES_ROUTE,
  NOTIFICATIONS_ROUTE,
  TabNameRouterV2,
  USER_INFO_ROUTE,
} from "constants/routes";
import TEST_IDS from "constants/testIds";

import {
  POPPER_MODIFIER_SHIFT_MENU_10PX_UP,
  POPPER_MODIFIER_MATCH_WIDTH_OF_TAB_BAR,
} from "./animation";
import getUrlForMapTabBasedOnState from "./mapTabUrlHelper";
import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";
import CoreNavigationFloatingTabBarEntrance from "common/components/AppRouterV2CoreNavigationFabs/components/CoreNavigationFloatingTabBarEntrance";
import CoreNavigationFloatingTabBarExit from "common/components/AppRouterV2CoreNavigationFabs/components/CoreNavigationFloatingTabBarExit";
import {
  TAB_CONFIG,
  RenderedRouteInfo,
} from "common/components/AppRouterV2CoreNavigationFabs/tabConfig";
import UIAvatar from "common/components/Avatar/UIAvatar";
import Image from "common/components/Image";
import IonAppPortal from "common/components/IonAppPortal";
import {
  CF_SPOTLIGHT_ATTRIBUTE,
  StepName,
} from "common/components/Onboarding/components/OnboardCoachmarkFlow";
import RealityChannelSelection, {
  PreloadedQueryReference,
} from "common/components/RealityChannelSelection";
import UIIcon from "common/components/UIIcon";
import UIPopper from "common/components/UIPopper";
import UIPopperDelayedUnmount from "common/components/UIPopper/delayedUnmount";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import { CoreNavigationFloatingTabBar_me$data as CoreNavigationFloatingTabBarMe } from "__generated__/CoreNavigationFloatingTabBar_me.graphql";
import { CoreNavigationFloatingTabBar_query$data as CoreNavigationFloatingTabBarQuery } from "__generated__/CoreNavigationFloatingTabBar_query.graphql";
import { preloader_RealityChannelSelection_Query$data as RealityChannelSelectionPreloaderQueryResponse } from "__generated__/preloader_RealityChannelSelection_Query.graphql";

import styles from "./styles.scss";

type Props = RouteComponentProps &
  WithTranslation &
  WithSelectedRealityChannelProps &
  WithKeyboardProps &
  WithRouteHistoryProps &
  WithUserInteractionHistoryProps & {
    query: CoreNavigationFloatingTabBarQuery;
    me: CoreNavigationFloatingTabBarMe;
  };

type State = {
  isRealityChannelSelectorOpen: boolean;
  shouldShowNewRCIndicator: boolean;
};

class CoreNavigationFloatingTabBar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isRealityChannelSelectorOpen: false,
      shouldShowNewRCIndicator: false,
    };
  }

  showRCSelector = (): void => {
    this.setState({ isRealityChannelSelectorOpen: true });
  };

  hideRCSelector = (): void => {
    this.setState({ isRealityChannelSelectorOpen: false });
  };

  toggleRCSelector = (): void => {
    if (this.state.isRealityChannelSelectorOpen) {
      this.hideRCSelector();
    } else {
      this.showRCSelector();
    }
  };

  determineIfHasUnreadForTab = (tabName: TabNameRouterV2): boolean => {
    switch (tabName) {
      case CLUBS_ROUTE: {
        const hasUnread = this.props.query.unreadMentionsCount > 0;

        return hasUnread;
      }

      case MESSAGES_ROUTE: {
        return this.props.query.unreadDirectMessagesCount > 0;
      }

      default:
        return false;
    }
  };

  getRouteInfoForTab = (tabName: TabNameRouterV2): RenderedRouteInfo => {
    const { pathname } = this.props.location;

    const baseRoute = `/${tabName}`;
    const fabConfig = TAB_CONFIG[tabName];
    const isSelected = pathname.startsWith(baseRoute);
    const IconComponent = isSelected ? fabConfig.selectedIcon : fabConfig.icon;
    const hasUnread = this.determineIfHasUnreadForTab(tabName);
    const routeHistoryEntriesStartingWithBase =
      this.props.routeHistoryProvider.getRouteHistoryStartingWith(baseRoute);
    const lastRoute = routeHistoryEntriesStartingWithBase[0]
      ? `${routeHistoryEntriesStartingWithBase[0].pathname}?${routeHistoryEntriesStartingWithBase[0].search}`
      : baseRoute;
    const href = isSelected ? baseRoute : lastRoute;

    return {
      isSelected,
      hasUnread,
      IconComponent,
      href,
      dataTestId: fabConfig.dataTestId,
    };
  };

  getUrlForMapTab = (): string => {
    const { pathname: currentPathname } = this.props.location;
    const selectedRCId = this.props.selectedRealityChannelProvider.selectedRealityChannelId;

    // The Map and Activity Tab (better seen as the selected reality channel icon)
    // operates a bit differently than other tabs regarding the last route, as swapping
    // reality channels is a global concept in the app. As a result, the previous route for that
    // view is not correct, and instead we should always link to the activity center/map but for
    // the selected reality channel. But we still want the last route honored, such as was it
    // the map or the activity center. So we pull the route info from the route history provider
    // and then determine the url based on that.
    const baseRoute = `/${ACTIVITY_CENTER_ROUTE}`;
    const routeHistoryEntriesStartingActivityCenter =
      this.props.routeHistoryProvider.getRouteHistoryStartingWith(baseRoute);
    const lastRouteSeen = routeHistoryEntriesStartingActivityCenter[0];
    const href = getUrlForMapTabBasedOnState(
      currentPathname,
      selectedRCId,
      lastRouteSeen ? lastRouteSeen.pathname : "",
    );

    return href;
  };

  renderMapAndActivityTab = (): React.ReactNode => {
    const selectedRCIcon =
      this.props.selectedRealityChannelProvider.selectedRealityChannelMetadata?.iconURL;
    const href = this.getUrlForMapTab();

    return (
      <div className={styles.realityChannelIconRedDotContainer}>
        <div
          className={styles.realityChannelIcon}
          data-test-id={TEST_IDS.FloatingTabBarRealityChannelIcon}
        >
          <Link to={href} className={styles.link}>
            <Image
              className={styles.realityChannelImage}
              loading="lazy"
              alt=""
              src={selectedRCIcon}
            />
          </Link>
        </div>
        {this.state.shouldShowNewRCIndicator && <div className={styles.hasUnreadDotRC} />}
      </div>
    );
  };

  renderLinkForTab = (tabName: TabNameRouterV2): React.ReactNode => {
    const routeInfoForTab = this.getRouteInfoForTab(tabName);

    const { IconComponent, hasUnread } = routeInfoForTab;

    return (
      <Link
        className={styles.link}
        key={tabName}
        data-test-id={routeInfoForTab.dataTestId}
        to={routeInfoForTab.href}
        onClick={() => entryPointHistory.addEntryPoint(EntryPoint.TAB_BAR)}
      >
        {hasUnread && <div className={classnames(styles.hasUnreadDot)} />}

        <div className={styles.iconCtn}>
          <IconComponent
            className={classnames(styles.icon, {
              [styles.selected]: routeInfoForTab.isSelected,
              [styles.clubs]: tabName === CLUBS_ROUTE,
              [styles.notifications]: tabName === NOTIFICATIONS_ROUTE,
              [styles.user]: tabName === USER_INFO_ROUTE,
              [styles.messages]: tabName === MESSAGES_ROUTE,
            })}
          />
        </div>
      </Link>
    );
  };

  renderLinkForUserRoute = (): React.ReactNode => {
    const routeInfoForUserRoute = this.getRouteInfoForTab(USER_INFO_ROUTE);

    return (
      <Link
        className={styles.link}
        key={USER_INFO_ROUTE}
        data-test-id={routeInfoForUserRoute.dataTestId}
        to={routeInfoForUserRoute.href}
        onClick={() => entryPointHistory.addEntryPoint(EntryPoint.FAB_BAR)}
      >
        <div
          className={classnames(styles.avatarCtn, {
            [styles.selected]: routeInfoForUserRoute.isSelected,
          })}
        >
          <UIAvatar
            size={24}
            allowRandomBgColor
            avatarUrl={this.props.me.avatarUrl}
            name={this.props.me.displayName}
          />
        </div>
      </Link>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  noop = (): void => {};

  renderRealityChannelNameAndSelector = (): React.ReactNode => {
    const selectedRCName =
      this.props.selectedRealityChannelProvider.selectedRealityChannelMetadata?.name;
    const isActivityCenterSelected =
      this.props.location.pathname.startsWith(`/${ACTIVITY_CENTER_ROUTE}`) ||
      this.props.location.pathname.startsWith(`/${MAP_ROUTE}`);
    const canChangeRC = isActivityCenterSelected && !isEmbedded;
    // [SO-3187] Embed cannot change RC's
    const onClickRCName = canChangeRC ? this.toggleRCSelector : this.noop;

    // Tested on iPhone 6/7/8, with the strings:
    // - "Pokémon GO" (10)
    // - "Pikmin Bloom" (12)
    // - "NBA All World" (13)
    // Capitalizing characters increases width a good amount.
    const rcNameIsLong = (selectedRCName || "").length > 12;

    // The RC name + arrow are clickable.
    // When clicked, we open a dropdown and a click mask behind it to intercept clicks
    // on other things in the view.
    // Alternatively, if the user is not on the activity center tab, this opens the activity center.

    const content = (
      <>
        <UIText
          className={classnames({
            [styles.responsiveLongName]: rcNameIsLong,
          })}
          textOverflow="ellipsis"
          color="white"
          weight="semiBold"
        >
          {selectedRCName}
        </UIText>
        <UISpacer w={2} />
        {canChangeRC && (
          <UIIcon
            color="white"
            icon={chevronDownOutline}
            size={18}
            className={classnames(styles.arrow, {
              [styles.open]: this.state.isRealityChannelSelectorOpen,
            })}
          />
        )}
      </>
    );

    return isActivityCenterSelected ? (
      <div
        role="presentation"
        className={styles.realityChannelName}
        onClick={onClickRCName}
        data-test-id={TEST_IDS.RealityChannelNameSelector}
      >
        {content}
      </div>
    ) : (
      <Link className={styles.linkOnRcName} to={this.getUrlForMapTab()}>
        <div
          role="presentation"
          className={styles.realityChannelName}
          data-test-id={TEST_IDS.RealityChannelNameSelector}
        >
          {content}
        </div>
      </Link>
    );
  };

  /**
   * Renders the dropdown menu with a click mask to intercept clicks off the dropdown.
   */
  renderDropdownMenu = (
    rcSelectionQueryReference: PreloadedQueryReference | null | void,
  ): React.ReactNode => {
    return (
      <AnimatedFadeInOut
        show={this.state.isRealityChannelSelectorOpen}
        className={classnames(styles.dropdownMenu, {
          [styles.open]: this.state.isRealityChannelSelectorOpen,
        })}
      >
        <IonAppPortal>
          <div role="presentation" className={styles.mask} onClick={this.hideRCSelector} />
        </IonAppPortal>
        <Suspense fallback={<RealityChannelSelection.Loading />}>
          {rcSelectionQueryReference ? (
            <RealityChannelSelection.View
              close={this.hideRCSelector}
              queryReference={rcSelectionQueryReference}
            />
          ) : null}
        </Suspense>
      </AnimatedFadeInOut>
    );
  };

  onPreloadedDataLoaded = (data: RealityChannelSelectionPreloaderQueryResponse): void => {
    const newRealityChannels = data.realityChannelsInLatLngBounds.filter((rc) => {
      const interactionName = getDynamicInteractionKey("realityChannelVisited", rc.id);
      const hasSeenRc = this.props.userInteraction.hasSeen(interactionName);
      const isExistingGame = existingRealityChannels.includes(rc.name);

      return !hasSeenRc && !isExistingGame;
    });

    if (newRealityChannels.length > 0) {
      this.setState({ shouldShowNewRCIndicator: true });
    } else {
      this.setState({ shouldShowNewRCIndicator: false });
    }
  };

  render = (): React.ReactNode => {
    // UIPopper is defined around the entire tab bar we want to match width of.
    const attrs = {
      [CF_SPOTLIGHT_ATTRIBUTE]: StepName.SPOTLIGHT_TAB_BAR,
    };

    return (
      <RealityChannelSelection.Preloader onLoaded={this.onPreloadedDataLoaded}>
        {({ queryReference: rcSelectionQueryReference }) => (
          <div className={styles.root} {...attrs}>
            <UIPopper
              className={styles.popperContentRoot}
              popperRootClassName={styles.popper}
              isOpen={this.state.isRealityChannelSelectorOpen}
              placement="top"
              // Chrome has some issues animating opacity and backdrop-filter where the backdrop isn't
              // drawn until the fade in animation completes. For whatever reason, shifting the opacity
              // to be changed on the element with the backdrop directly, seems to work.
              // Hence, why this custom-delay type was created, so that the UIPopper can delay unmounting
              // of the popper content without actually animating anything, so that we can fade it out
              // ourselves from this component.
              customTransitionPopperComponent={UIPopperDelayedUnmount}
              popperModifiers={[
                POPPER_MODIFIER_SHIFT_MENU_10PX_UP,
                POPPER_MODIFIER_MATCH_WIDTH_OF_TAB_BAR,
              ]}
              renderPopperContent={() => this.renderDropdownMenu(rcSelectionQueryReference)}
            >
              <div className={styles.left}>
                {this.renderMapAndActivityTab()}
                <UISpacer w={8} />
                {this.renderRealityChannelNameAndSelector()}
              </div>
              <div className={styles.right}>
                {this.renderLinkForTab(NOTIFICATIONS_ROUTE)}
                <UISpacer w={11} />
                {this.renderLinkForTab(MESSAGES_ROUTE)}
                <UISpacer w={11} />
                {this.renderLinkForTab(CLUBS_ROUTE)}
                <UISpacer w={11} />
                {this.renderLinkForUserRoute()}
              </div>
            </UIPopper>
          </div>
        )}
      </RealityChannelSelection.Preloader>
    );
  };
}

const FragmentContainer = createFragmentContainer(CoreNavigationFloatingTabBar, {
  query: graphql`
    fragment CoreNavigationFloatingTabBar_query on Query {
      unreadMentionsCount
      unreadDirectMessagesCount
    }
  `,
  me: graphql`
    fragment CoreNavigationFloatingTabBar_me on User {
      id
      avatarUrl
      displayName
    }
  `,
});

const SelectedRealityChannelConnected = withSelectedRealityChannel(FragmentContainer);
const KeyboardConnected = withKeyboard(SelectedRealityChannelConnected);
const RouteHistoryConnected = withRouteHistory(KeyboardConnected);
const RouterConnected = withRouter(RouteHistoryConnected);
const UserInteractionHistoryConnected = withUserInteractionHistory(RouterConnected);
const TranslatedComponent = withTranslation()(UserInteractionHistoryConnected);

export default {
  Entrance: CoreNavigationFloatingTabBarEntrance,
  Exit: CoreNavigationFloatingTabBarExit,
  Content: TranslatedComponent,
};
