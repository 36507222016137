import { invokeRemoteAsync } from "common/utils/webInterop/plugins";

import defaultGetUri, { validator as getUriValidator } from "./getUri";
import defaultReadFile, { validator as readFileValidator } from "./readFile";
import defaultStat, { validator as statValidator } from "./stat";

export default {
  getUri: invokeRemoteAsync<typeof defaultGetUri, "Filesystem">(
    defaultGetUri,
    getUriValidator,
    "Filesystem",
    "getUri",
  ),
  readFile: invokeRemoteAsync<typeof defaultReadFile, "Filesystem">(
    defaultReadFile,
    readFileValidator,
    "Filesystem",
    "readFile",
  ),
  stat: invokeRemoteAsync<typeof defaultStat, "Filesystem">(
    defaultStat,
    statValidator,
    "Filesystem",
    "stat",
  ),
};
