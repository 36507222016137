import React, { FunctionComponent } from "react";

import ReportContext from "constants/reportContext";

import useReportUser from "common/components/Reporting/UserReport/useReportUser";

type ExposedProps = {
  showReportUser: () => void;
};

type Props = {
  children: (exposedProps: ExposedProps) => React.ReactNode;
  avatarUrl: string;
  displayName: string;
  username: string;
  userId: string;
  reportContext?: ReportContext;
};

const UserReportComponent: FunctionComponent<Props> = (props: Props): React.ReactElement => {
  const report = useReportUser({
    avatarUrl: props.avatarUrl,
    displayName: props.displayName,
    username: props.username,
    userId: props.userId,
    reportContext: props.reportContext,
  });

  return props.children({ showReportUser: report }) as React.ReactElement;
};

export default UserReportComponent;
