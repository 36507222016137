import { v4 as uuidv4 } from "uuid";

import BaseStore from "common/stores/BaseStore";

type Key = "teamUpFunnelId";

const DEFAULTS: Record<Key, string | number | boolean | void | null> = {
  teamUpFunnelId: "",
};

class TeamUpStore extends BaseStore {
  constructor() {
    super("TeamUpStore", DEFAULTS, false);
  }

  getFunnelId(): string {
    return super.get("teamUpFunnelId");
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  refreshFunnelId(): GenericObject {
    const funnelId = uuidv4();

    return super.set("teamUpFunnelId", funnelId);
  }
}

export default new TeamUpStore();
