import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { graphql, QueryRenderer } from "react-relay";

import {
  ClubsPageStateProvider,
  ClubsPageStateConsumer,
} from "pages/Clubs/providers/ClubsPageStateProvider";
import { ClubsPageViewProvider } from "pages/Clubs/providers/ClubsPageViewProvider";

import ClubUserProfileProviderMask from "common/components/ClubUserProfileProviderMask";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import ClubsPageLoadError from "pages/Clubs/components/ClubsPageLoadError";

import { Clubs_page_Query$data as ClubsPageQueryResponse } from "__generated__/Clubs_page_Query.graphql";

// Lazy Load Main View
const ClubsPageMainContent = React.lazy(
  () => import("pages/Clubs/components/ClubsPageMainContent"),
);

type Props = {};

type State = {
  initializedAt: number;
};

type QRProps = {
  me: ClubsPageQueryResponse["me"];
};

const CLUBS_PAGE = graphql`
  query Clubs_page_Query {
    me {
      id
      ...ClubsPageMainContent_me
      ...ClubsPageStateProvider_me
    }
  }
`;

class ClubsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedAt: Date.now(),
    };
  }

  reInit = (): void => {
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  render = (): React.ReactNode => {
    return (
      <UIErrorBoundary onRetry={this.onRetry}>
        <Suspense fallback={<LoadingPageDefault />}>
          <QueryRenderer
            key={this.state.initializedAt}
            environment={environment}
            query={CLUBS_PAGE}
            variables={{}}
            fetchPolicy="store-and-network"
            render={({ props, error }) => {
              const qrProps = props as QRProps;

              if (error) {
                return <ClubsPageLoadError reload={this.reInit} />;
              }

              if (!qrProps) {
                return <LoadingPageDefault />;
              }

              return (
                <ClubsPageStateProvider me={qrProps.me}>
                  <ClubsPageStateConsumer>
                    {({ currentClubId }) => (
                      <ClubUserProfileProviderMask clubId={currentClubId}>
                        <ClubsPageViewProvider>
                          <ClubsPageMainContent me={qrProps.me} />
                        </ClubsPageViewProvider>
                      </ClubUserProfileProviderMask>
                    )}
                  </ClubsPageStateConsumer>
                </ClubsPageStateProvider>
              );
            }}
          />
        </Suspense>
      </UIErrorBoundary>
    );
  };
}

export default ClubsPage;
