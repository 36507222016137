import environment from "common/relay/relay-env";
import React, { FunctionComponent } from "react";
import { fetchQuery, graphql } from "relay-runtime";

import PollingRefetcher from "common/components/PollingRefetcher";

export type Props = {};

const ONE_MINUTE_MS = 1_000 * 60;
const REFRESH_INTERVAL_MS = ONE_MINUTE_MS * 5;

const APP_HYDRATION_REFRESH_QUERY = graphql`
  query AppHydrationRefresher_Query {
    me {
      blockedByUsers {
        id
      }
    }
  }
`;

const AppHydrationRefresher: FunctionComponent<Props> = (): React.ReactElement => {
  const refreshStuff = (): void => {
    fetchQuery(
      environment,
      APP_HYDRATION_REFRESH_QUERY,
      {},
      {
        networkCacheConfig: {
          force: true,
        },
      },
    ).toPromise();
  };

  return <PollingRefetcher pollingFn={refreshStuff} intervalTimeMs={REFRESH_INTERVAL_MS} />;
};

export default AppHydrationRefresher;
