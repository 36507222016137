import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { graphql, QueryRenderer } from "react-relay";

import LoadingPageDefault from "common/components/LoadingPageDefault";
import UIErrorBoundary from "common/components/UIErrorBoundary";
import UISpinner from "common/components/UISpinner";

import AccountSetupFlow, {
  Props as AccountSetupFlowProps,
} from "boot-loader/components/AccountSetupFlow";

import { AccountSetupFlowQR_Query$data as AccountSetupFlowQRQueryResponse } from "__generated__/AccountSetupFlowQR_Query.graphql";

import styles from "./styles.scss";

type Props = Omit<Omit<AccountSetupFlowProps, "me">, "query">;

type State = {};

type QRProps = AccountSetupFlowQRQueryResponse & {
  me: AccountSetupFlowQRQueryResponse["me"];
  query: AccountSetupFlowQRQueryResponse;
};

const ACCOUNT_SETUP_FLOW_QUERY = graphql`
  query AccountSetupFlowQR_Query {
    ...AccountSetupFlow_query
    me {
      ...AccountSetupFlow_me
    }
  }
`;

class AccountSetupFlowQR extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <UIErrorBoundary>
        <Suspense fallback={<LoadingPageDefault />}>
          <QueryRenderer
            environment={environment}
            query={ACCOUNT_SETUP_FLOW_QUERY}
            variables={{}}
            fetchPolicy="store-and-network"
            render={({ props, error }) => {
              const qrProps = props as QRProps;

              const isError = !!error;
              const isLoading = !isError && !props;

              // When this query fails, pass null values to the account setup flow which
              // SHOULD skip any slides that required data from this query.
              if (isError) {
                return <AccountSetupFlow {...this.props} me={null} query={null} setupWithoutData />;
              }

              if (isLoading) {
                return (
                  <div className={styles.root}>
                    <UISpinner color="dark" size={48} />
                  </div>
                );
              }

              return <AccountSetupFlow {...this.props} me={qrProps.me} query={qrProps} />;
            }}
          />
        </Suspense>
      </UIErrorBoundary>
    );
  };
}

export default AccountSetupFlowQR;
