import { BundleInfo } from "@capgo/capacitor-updater";

import AppUpdateStore from "common/stores/AppUpdateStore";
import { MS_TO_ELAPSE_BEFORE_AUTO_UPDATE } from "constants/appUpdate";

const getLastBackgroundTimestamp = (): number => {
  return AppUpdateStore.get("lastBackgroundedAtMs");
};

export const markLastBackgroundTimestamp = (): void => {
  AppUpdateStore.set("lastBackgroundedAtMs", Date.now());
};

export const clearLastBackgroundTimestamp = (): void => {
  AppUpdateStore.set("lastBackgroundedAtMs", null);
};

export const setNextBundleToApplyOnColdStart = (bundleInfo: BundleInfo): void => {
  // Save the last bundle that was downloaded to storage for later use.
  AppUpdateStore.set("nextScheduledBundleToApply", bundleInfo);
  AppUpdateStore.set("nextScheduledBundleIdToApply", bundleInfo.id);
};

export const getNextBundleIdToApplyOnColdStart = (): string | null => {
  const nextBundleIdToApply = AppUpdateStore.get("nextScheduledBundleIdToApply");

  return nextBundleIdToApply;
};

export const clearNextBundleToApplyOnColdStart = (): void => {
  AppUpdateStore.set("nextScheduledBundleToApply", null);
  AppUpdateStore.set("nextScheduledBundleIdToApply", null);
};

export const hasAppVersionChanged = (
  currentAppVersion: string,
  currentJSVersion: string,
  lastAppVersion: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lastJSVersion: string,
): boolean => {
  if (lastAppVersion) {
    // Detect a change only on installed app version differences.
    return currentAppVersion !== lastAppVersion;
  }

  return false;
};

export const shouldPerformRollback = (appVersionHasChanged: boolean): boolean => {
  return appVersionHasChanged;
};

/**
 * Detects whether or not we should perform an auto update on cold start
 */
export const shouldPerformAutoUpdateOnColdStart = (nextBundleId: string | null): boolean => {
  return Boolean(nextBundleId);
};

/**
 * Detects if the auto updater should apply a previously scheduled bundle.
 *
 * Used to facilitate applying a bundle without requiring a force close of the application.
 *
 * The idea here is that user's generally like to finish a line of thought before moving onto a new task.
 * By tracking when a user backgrounds the app, we can estimate their current interaction level
 * with the Campfire app. Users who are swapping between the app to communicate and other things
 * likely will have shorter times between background and foreground. However, those who
 * only are opening campfire occasionally, we can just pretend the app is cold starting.
 */
export const shouldApplyNextScheduledBundle = (): boolean => {
  // First, check if we even have a bundle to apply. If we don't, there is clearly not a need
  // to apply a bundle.
  const nextBundleIdToApply = getNextBundleIdToApplyOnColdStart();
  const autoUpdateShouldOccurOnNextColdStart =
    shouldPerformAutoUpdateOnColdStart(nextBundleIdToApply);

  // If an auto update on next boot is not scheduled, we don't have a bundle
  // scheduled to be applied, so return early.
  if (!autoUpdateShouldOccurOnNextColdStart) {
    return false;
  }

  // Compare the current time to the last time the user had backgrounded the app.
  const lastTimestampWhenAppWasBackgroundedMs = getLastBackgroundTimestamp();

  // If the user has never backgrounded the app, then return false.
  // If the user has backgrounded the app, and if 10 minutes has passed since backgrounding
  // then return true.
  if (
    lastTimestampWhenAppWasBackgroundedMs &&
    typeof lastTimestampWhenAppWasBackgroundedMs === "number"
  ) {
    const msBetweenNowAndLast = Date.now() - lastTimestampWhenAppWasBackgroundedMs;
    const minutesBetweenNowAndLast = msBetweenNowAndLast;
    const hasAtLeastTenMinutesElapsed = minutesBetweenNowAndLast >= MS_TO_ELAPSE_BEFORE_AUTO_UPDATE;

    return hasAtLeastTenMinutesElapsed;
  }

  return false;
};
