import _ from "lodash";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { getQueryString } from "common/utils/url";
import { QUERY_PARAMS } from "constants/routes";

export type ExposedProps = {
  getRouteHistory: () => RouteHistoryEntry[];
  getRouteHistoryStartingWith: (str: string) => RouteHistoryEntry[];
};

type RouteHistoryEntry = {
  pathname: string;
  search: string; // This does NOT include the "?" at the front!
};

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

type State = {
  lastNRoutes: RouteHistoryEntry[];
};

const INITIAL_CONTEXT: ExposedProps = {
  getRouteHistory: () => [],
  getRouteHistoryStartingWith: () => [],
};

export const RouteHistoryContext = React.createContext(INITIAL_CONTEXT);

// Hold onto 50 of the last history entries.
const MAX_HISTORY_ENTRIES = 50;

export class RouteHistoryProviderBase extends React.Component<Props, State> {
  /**
   * Removes certain query params from a search string.
   * NOTE: Returns a search string that does NOT contain a "?" preceding the string.
   */
  static sanitizeSearchString = (search: string): string => {
    const fieldsToRemove = {
      [QUERY_PARAMS.map.action.key]: undefined,
      [QUERY_PARAMS.map.actionData.key]: undefined,
    };

    return getQueryString(fieldsToRemove, search);
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      lastNRoutes: [],
    };
  }

  componentDidUpdate = (prevProps: Readonly<Props>): void => {
    // Anytime the pathname changes, this is considered a significant route event.
    // Store the PREVIOUS route info for us to infer where the user was likely last on that
    // core tab.
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.addRouteHistory(prevProps.location.pathname, prevProps.location.search);
    }
  };

  /**
   * Adds an significant route entry to the providers tracking state.
   * @param pathname
   * @param search - Can include "?" preceeding the search string, but this will NOT
   *                 be saved in the route entry data.
   */
  addRouteHistory = (pathname: string, search: string): void => {
    const { lastNRoutes } = this.state;

    let updatedLastNRoutes = _.cloneDeep(lastNRoutes);

    updatedLastNRoutes.unshift({
      pathname,
      search: RouteHistoryProviderBase.sanitizeSearchString(search),
    });
    updatedLastNRoutes = updatedLastNRoutes.slice(0, MAX_HISTORY_ENTRIES);

    this.setState({ lastNRoutes: updatedLastNRoutes });
  };

  getRouteHistory = (): RouteHistoryEntry[] => {
    return this.state.lastNRoutes;
  };

  getRouteHistoryStartingWith = (str: string): RouteHistoryEntry[] => {
    return this.getRouteHistory().filter((routeHistoryEntry) => {
      return routeHistoryEntry.pathname.startsWith(str);
    });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <RouteHistoryContext.Provider
        value={{
          getRouteHistory: this.getRouteHistory,
          getRouteHistoryStartingWith: this.getRouteHistoryStartingWith,
        }}
      >
        {children}
      </RouteHistoryContext.Provider>
    );
  };
}

export const RouteHistoryConsumer = RouteHistoryContext.Consumer;

const RouterConnected = withRouter(RouteHistoryProviderBase);

export { RouterConnected as RouteHistoryProvider };
