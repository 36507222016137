/* eslint-disable no-underscore-dangle */

type AppInteropSupportedMethodName = keyof CampfireInterop.App;

// eslint-disable-next-line @typescript-eslint/ban-types
function invokeRemoteAppMethod<T extends Function>(methodName: AppInteropSupportedMethodName): T {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args) => {
    // Check to see if something has implemented a supported method for the App Interop.
    // If so, invoke it and return the value. It should return a boolean.
    if (!!window.__CAMPFIRE_APP_INTEROP__ && window.__CAMPFIRE_APP_INTEROP__[methodName]) {
      const methodToInvoke = window.__CAMPFIRE_APP_INTEROP__[methodName];

      if (methodToInvoke && typeof methodToInvoke === "function") {
        return methodToInvoke(...args);
      }
    }

    // If we did not find a method implemented, then we assume we want to perform the
    // default behavior.
    return true;
  };
}

export default {
  logout: invokeRemoteAppMethod("logout"),
  reload: invokeRemoteAppMethod("reload"),
};
