/* eslint-disable import/first, import/order */
import adjustDocumentBodyForDevice from "app-setup/adjustDocumentBodyForDevice";
import cleanupStores from "app-setup/cleanupStores";
import reportWebVitals from "app-setup/reportWebVitals";
import isDev from "constants/isDev";
import enableDevDebugActions from "app-setup/enableDevDebugActions";
import getLanguageAndSetDayJsLocale from "app-setup/getLanguageAndSetDayJsLocale";

// Import Capacitor plugins after the interop because we initialize Capacitor there
// and importing such plugins first may initialize Capacitor without us forcing embed
// as web, as done by treatEmbedAsWebPlatformCapacitor
import setClientInfoOnRequests from "common/utils/clientInfo";

/**
 * Prepares the webapp for rendering.
 *
 * Will do things like:
 * - Set the client info on requests we make
 * - Configure ionic/react to be in ios mode only
 * - Setup some debug tools for us.
 */
const prepareApp = (): void => {
  // Cleanup orphaned store values to only be stringified once
  cleanupStores();

  // Setup an interceptor to attach client info on each request
  setClientInfoOnRequests();

  // Adjust the document body for weird devices that seem to have issues on embed.
  adjustDocumentBodyForDevice();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

  // Enable some development debug actions to make our life easier.
  if (isDev) {
    enableDevDebugActions();
  }

  // Globally setup dayjs locale
  getLanguageAndSetDayJsLocale();
};

export default prepareApp;
