// Singleton store for the share receiver to write to, and
// the share dialog to read from.
let shareData = "";

export const setShareData = (data: string): void => {
  shareData = data;
};

export const getShareData = (): string => {
  return shareData;
};
