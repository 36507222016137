/**
 * Views that "slide" in on the Clubs tab are slides (really UINavSlide) of a UINav.
 * Each UINav identifies a slide via a slideId. Part of the problem when popping slides
 * off the UINav stack is that although the UI changes, the URL does not. Now, this is less of
 * a bug and more to do with the fact that the UINav is a view level component, independent
 * of the router.
 *
 * However, some of our views are deeplinkable, and it would do us good to synchronize the url with
 * the view changes. The UINav presents us with the active slideId anytime it changes.
 * Armed with this, we can use the slideId to encode some info about the view represented by
 * the slide.
 *
 * For the example of a ClubChannel, we encode clubId and channelId. Not only
 * does this uniquely identify the slide, but it becomes useable for us when synchronizing the url
 * when the active slide changes.
 */
type ClubSlideConfig = {
  isMatch: (slideId: string) => boolean;
  encodeSlideId: (...args: string[]) => string;
  decodeSlideId: (slideId: string) => ClubSlideInfo | ClubChannelSlideInfo;
};

export type ClubChannelSlideInfo = {
  type: "channel";
  clubId: string;
  channelId: string;
  channelSubRoute: string | void;
};

export type ClubSlideInfo = {
  type: "club";
  clubId: string;
  clubSubRoute: string;
};

export type ChannelSlideConfig = {
  pageType: "channel-chat" | "club-feed";
  slideId: string;
  clubId: string;
  channelId?: string;
};

export const CLUB_TYPE_SLIDE = "club";
export const CHANNEL_TYPE_SLIDE = "channel";

/**
 * This file defines some configuration around managing slides on the Club page.
 * Slide id's are actually encoded strings so that we can identify which slide is hiding
 * based on the id alone.
 */
export const CLUB_SLIDE: ClubSlideConfig = {
  isMatch: (slideId: string): boolean => {
    const slideIdComponents = slideId.split(":");
    const type = slideIdComponents[0];

    return type === CLUB_TYPE_SLIDE;
  },
  encodeSlideId: (clubId: string, clubSubRoute: string): string => {
    const slideType = CLUB_TYPE_SLIDE;

    return `${slideType}:${clubId}:${clubSubRoute}`;
  },
  decodeSlideId: (slideId: string): ClubSlideInfo => {
    const slideIdComponents = slideId.split(":");
    const clubId = slideIdComponents[1];
    const clubSubRoute = slideIdComponents[2];

    return {
      type: CLUB_TYPE_SLIDE,
      clubId,
      clubSubRoute,
    };
  },
};

export const CLUB_CHANNEL_SLIDE: ClubSlideConfig = {
  isMatch: (slideId: string): boolean => {
    const slideIdComponents = slideId.split(":");
    const type = slideIdComponents[0];

    return type === CHANNEL_TYPE_SLIDE;
  },
  encodeSlideId: (clubId: string, channelId: string, channelSubRoute?: string): string => {
    const slideType = CHANNEL_TYPE_SLIDE;

    if (channelSubRoute) {
      return `${slideType}:${clubId}:${channelId}:${channelSubRoute}`;
    }

    return `${slideType}:${clubId}:${channelId}`;
  },
  decodeSlideId: (slideId: string): ClubChannelSlideInfo => {
    const slideIdComponents = slideId.split(":");
    const clubId = slideIdComponents[1];
    const channelId = slideIdComponents[2];
    const channelSubRoute = slideIdComponents[3] ? slideIdComponents[3] : undefined;

    return {
      type: CHANNEL_TYPE_SLIDE,
      clubId,
      channelId,
      channelSubRoute,
    };
  },
};
