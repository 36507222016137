import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import withBlockedUsers, {
  WithBlockedUsersProps,
} from "providers/BlockedUsersProvider/withBlockedUsers";

import BlockUserConfirmationDialog from "common/components/BlockUserConfirmationDialog";
import UIButton from "common/components/UIButton";
import UIDialog from "common/components/UIDialog";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithTranslation &
  WithBlockedUsersProps & {
    isConfirmDialogOpen: boolean;
    isReportSuccess: boolean;
    showBlockOption: boolean;
    userId?: string;
    displayName?: string;
    onCloseReportDialog: () => void;
    onDidDismiss?: () => void;
  };

type State = {
  isBlockDialogOpen: boolean;
};

class ReportConfirmationDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isBlockDialogOpen: false,
    };
  }

  hasActions = (): boolean => {
    // Add further actions here if adding more
    return this.shouldShowBlockOption();
  };

  getHeaderText = (): string => {
    return this.props.isReportSuccess
      ? this.props.t("THANK_YOU_FOR_YOUR_REPORT")
      : this.props.t("SOMETHING_WENT_WRONG");
  };

  getDescText = (): string => {
    if (!this.props.isReportSuccess) {
      return this.props.t("WE_WERE_UNABLE_TO_SUBMIT");
    }

    if (this.hasActions()) {
      return `${this.props.t("NIANTIC_WILL_REVIEW")} ${this.props.t("NEXT_ACTION_PROMPT")}`;
    }

    return this.props.t("NIANTIC_WILL_REVIEW");
  };

  renderText = (): React.ReactNode => {
    const headerText = this.getHeaderText();
    const descText = this.getDescText();

    return (
      <>
        <UIText variant="h2" weight="bold" color="dark">
          {headerText}
        </UIText>
        <UISpacer h={8} />
        <UIText color="dark">{descText}</UIText>
      </>
    );
  };

  shouldShowBlockOption = (): boolean => {
    const { userId } = this.props;

    if (!userId) {
      return false;
    }

    // If the user is already blocked, don't show this the block option.
    if (this.props.blockedUsersProvider.isUserBlocked(userId)) {
      return false;
    }

    return this.props.showBlockOption;
  };

  onClickBlockAction = (): void => {
    this.props.onCloseReportDialog();
    this.setState({ isBlockDialogOpen: true });
  };

  closeBlockDialog = (): void => {
    this.setState({ isBlockDialogOpen: false });
  };

  onDidDismiss = (): void => {
    if (this.props.onDidDismiss) {
      this.props.onDidDismiss();
    }
  };

  renderBlockDialog = (): React.ReactNode => {
    if (!this.props.displayName || !this.props.userId) {
      return null;
    }

    return (
      <BlockUserConfirmationDialog
        isOpen={this.state.isBlockDialogOpen}
        onClose={this.closeBlockDialog}
        onBlock={this.closeBlockDialog}
        displayName={this.props.displayName}
        userId={this.props.userId}
      />
    );
  };

  renderBlockButton = (): React.ReactNode => {
    return (
      <UIButton expand="block" color="danger" fill="outline" onClick={this.onClickBlockAction}>
        <UIText variant="body1" weight="bold" textOverflow="ellipsis">
          {this.props.t("BLOCK_USERNAME", { username: this.props.displayName })}
        </UIText>
      </UIButton>
    );
  };

  renderCloseButton = (): React.ReactNode => {
    return (
      <UIButton expand="block" color="dark" fill="outline" onClick={this.props.onCloseReportDialog}>
        <UIText variant="body1" weight="bold">
          {this.props.t("CLOSE").toUpperCase()}
        </UIText>
      </UIButton>
    );
  };

  renderActions = (): React.ReactNode => {
    if (!this.props.isReportSuccess || !this.hasActions()) {
      return this.renderCloseButton();
    }

    return (
      <div>
        {this.shouldShowBlockOption() && this.renderBlockButton()}
        <UISpacer h={8} />
        {this.renderCloseButton()}
      </div>
    );
  };

  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        {this.renderBlockDialog()}
        <UIDialog
          type="floating-center"
          isOpen={this.props.isConfirmDialogOpen}
          close={this.props.onCloseReportDialog}
          onWillDismiss={this.props.onCloseReportDialog}
          onDidDismiss={this.onDidDismiss}
        >
          <div className={styles.modal}>
            {this.renderText()}
            <UISpacer h={24} />
            {this.renderActions()}
          </div>
        </UIDialog>
      </div>
    );
  };
}

const WithBlockedUsers = withBlockedUsers(ReportConfirmationDialog);

export default withTranslation()(WithBlockedUsers);
