import isDev from "constants/isDev";

type ConfigOption =
  | "CAMPFIRE_APP_WWW_URL"
  | "CAMPFIRE_APP_CUSTOM_URL_SCHEME"
  | "CAMPFIRE_APP_GOOGLE_OAUTH_LOGIN"
  | "CAMPFIRE_APP_FACEBOOK_OAUTH_LOGIN"
  | "CAMPFIRE_APP_PUBNUB_SUBSCRIBE_KEY"
  | "CAMPFIRE_APP_PUBNUB_ENCRYPTION_KEY"
  | "CAMPFIRE_APP_GOOGLE_CLIENT_ID"
  | "CAMPFIRE_APP_GOOGLE_ANALYTICS_KEY"
  | "CAMPFIRE_APP_SENTRY_DSN_KEY"
  | "CAMPFIRE_APP_APPSFLYER_DEEPLINK_URL"
  | "CAMPFIRE_APP_APPSFLYER_DEV_KEY_ANDROID"
  | "CAMPFIRE_APP_APPSFLYER_APP_ID_ANDROID"
  | "CAMPFIRE_APP_APPSFLYER_DEV_KEY_IOS"
  | "CAMPFIRE_APP_APPSFLYER_APP_ID_IOS"
  | "CAMPFIRE_APP_ANDROID_PLAY_STORE_URL"
  | "CAMPFIRE_APP_IOS_APP_STORE_URL"
  | "CAMPFIRE_APP_MOCK_DEVICE_ID"
  | "CAMPFIRE_APP_STATIC_ASSETS_BASE_URL"
  | "CAMPFIRE_APP_GLOBAL_CONFIG_BASE_URL"
  | "CAMPFIRE_APP_ID_PORTAL_URL";

// Define each config individually.
// They need to be accessed directly and not via process.env[key] because
// `process.env` gets transpiled into that entire object with all the NVARs
// available since it doesn't know ahead of time what's needed.
const actualConfig: Record<ConfigOption, string | undefined> = {
  CAMPFIRE_APP_WWW_URL: process.env.CAMPFIRE_APP_WWW_URL,
  CAMPFIRE_APP_CUSTOM_URL_SCHEME: process.env.CAMPFIRE_APP_CUSTOM_URL_SCHEME,
  CAMPFIRE_APP_GOOGLE_OAUTH_LOGIN: process.env.CAMPFIRE_APP_GOOGLE_OAUTH_LOGIN,
  CAMPFIRE_APP_FACEBOOK_OAUTH_LOGIN: process.env.CAMPFIRE_APP_FACEBOOK_OAUTH_LOGIN,
  CAMPFIRE_APP_PUBNUB_SUBSCRIBE_KEY: process.env.CAMPFIRE_APP_PUBNUB_SUBSCRIBE_KEY,
  CAMPFIRE_APP_PUBNUB_ENCRYPTION_KEY: process.env.CAMPFIRE_APP_PUBNUB_ENCRYPTION_KEY,
  CAMPFIRE_APP_GOOGLE_CLIENT_ID: process.env.CAMPFIRE_APP_GOOGLE_CLIENT_ID,
  CAMPFIRE_APP_GOOGLE_ANALYTICS_KEY: process.env.CAMPFIRE_APP_GOOGLE_ANALYTICS_KEY,
  CAMPFIRE_APP_SENTRY_DSN_KEY: process.env.CAMPFIRE_APP_SENTRY_DSN_KEY,
  CAMPFIRE_APP_APPSFLYER_DEEPLINK_URL: process.env.CAMPFIRE_APP_APPSFLYER_DEEPLINK_URL,
  CAMPFIRE_APP_APPSFLYER_DEV_KEY_ANDROID: process.env.CAMPFIRE_APP_APPSFLYER_DEV_KEY_ANDROID,
  CAMPFIRE_APP_APPSFLYER_APP_ID_ANDROID: process.env.CAMPFIRE_APP_APPSFLYER_APP_ID_ANDROID,
  CAMPFIRE_APP_APPSFLYER_DEV_KEY_IOS: process.env.CAMPFIRE_APP_APPSFLYER_DEV_KEY_IOS,
  CAMPFIRE_APP_APPSFLYER_APP_ID_IOS: process.env.CAMPFIRE_APP_APPSFLYER_APP_ID_IOS,
  CAMPFIRE_APP_ANDROID_PLAY_STORE_URL: process.env.CAMPFIRE_APP_ANDROID_PLAY_STORE_URL,
  CAMPFIRE_APP_IOS_APP_STORE_URL: process.env.CAMPFIRE_APP_IOS_APP_STORE_URL,
  CAMPFIRE_APP_MOCK_DEVICE_ID: process.env.CAMPFIRE_APP_MOCK_DEVICE_ID,
  CAMPFIRE_APP_STATIC_ASSETS_BASE_URL: process.env.CAMPFIRE_APP_STATIC_ASSETS_BASE_URL,
  CAMPFIRE_APP_GLOBAL_CONFIG_BASE_URL: process.env.CAMPFIRE_APP_GLOBAL_CONFIG_BASE_URL,
  CAMPFIRE_APP_ID_PORTAL_URL: process.env.CAMPFIRE_APP_ID_PORTAL_URL,
};

const defaultConfig: Record<ConfigOption, string> = {
  CAMPFIRE_APP_WWW_URL: "http://campfire-dev-api.eng.nianticlabs.com",
  CAMPFIRE_APP_CUSTOM_URL_SCHEME: "campfire://",
  CAMPFIRE_APP_GOOGLE_OAUTH_LOGIN: "",
  CAMPFIRE_APP_FACEBOOK_OAUTH_LOGIN: "",
  CAMPFIRE_APP_PUBNUB_SUBSCRIBE_KEY: "",
  CAMPFIRE_APP_PUBNUB_ENCRYPTION_KEY: "",
  CAMPFIRE_APP_GOOGLE_CLIENT_ID: "",
  CAMPFIRE_APP_GOOGLE_ANALYTICS_KEY: "",
  CAMPFIRE_APP_SENTRY_DSN_KEY: "",
  CAMPFIRE_APP_APPSFLYER_DEEPLINK_URL: "https://campfire-dev.onelink.me/mjG8",
  CAMPFIRE_APP_APPSFLYER_DEV_KEY_ANDROID: "",
  CAMPFIRE_APP_APPSFLYER_APP_ID_ANDROID: "",
  CAMPFIRE_APP_APPSFLYER_DEV_KEY_IOS: "",
  CAMPFIRE_APP_APPSFLYER_APP_ID_IOS: "",
  CAMPFIRE_APP_ANDROID_PLAY_STORE_URL: "",
  CAMPFIRE_APP_IOS_APP_STORE_URL: "",
  CAMPFIRE_APP_MOCK_DEVICE_ID: "",
  CAMPFIRE_APP_STATIC_ASSETS_BASE_URL: "",
  // Read global app config from `rel` as default since that should be the safest
  CAMPFIRE_APP_GLOBAL_CONFIG_BASE_URL: "https://social.nianticlabs.com/config",
  CAMPFIRE_APP_ID_PORTAL_URL: "https://id-portal-dev.eng.nianticlabs.com",
};

const config = {
  get(key: ConfigOption): string | undefined {
    const hasVariableSetInEnvironment = !!actualConfig[key];

    // If in development, check the Environment Variable for the value first.
    if (isDev && hasVariableSetInEnvironment) {
      return actualConfig[key];
    }

    // Otherwise, use the default config value.
    return hasVariableSetInEnvironment ? actualConfig[key] : defaultConfig[key];
  },
};

export default config;
