import _ from "lodash";

import { CORE_NAVIGATION_PRIORITY_DATA_ATTRIBUTE_NAME } from "constants/appRouterV2";

// eslint-disable-next-line import/prefer-default-export
export const findHighestPriorityParentElement = (
  elements: HTMLElement[],
): HTMLElement | undefined => {
  return _.maxBy(elements, (el) => {
    let rank = 1;

    try {
      rank = parseInt(el.getAttribute(CORE_NAVIGATION_PRIORITY_DATA_ATTRIBUTE_NAME) as string, 10);
    } catch (e) {
      rank = 1;
    }

    return rank;
  });
};
