import { buildUrlToActivityCenter } from "common/utils/routing/routeToActivityCenter";
import { buildUrlToActivityCenterMap } from "common/utils/routing/routeToActivityCenterMap";
import { ACTIVITY_CENTER_MAP_ROUTE, ACTIVITY_CENTER_ROUTE } from "constants/routes";

const getUrlForMapTabBasedOnState = (
  currentPathname: string,
  selectedRCId: string,
  lastPathnameSeenForMap: string,
): string => {
  const baseRoute = `/${ACTIVITY_CENTER_ROUTE}`;
  const isMapViewSelected = currentPathname.startsWith(baseRoute);
  // If the map is currently selected, logic will be based on the current pathname.
  const pathnameToBaseDecisionOn = isMapViewSelected ? currentPathname : lastPathnameSeenForMap;

  // If we have a rc selected, url will always contain it somewhere.
  if (selectedRCId) {
    const isRouteOnMap = pathnameToBaseDecisionOn.endsWith(`/${ACTIVITY_CENTER_MAP_ROUTE}`);

    // If the route was the map, then we wanna bring the user back to there
    if (isRouteOnMap) {
      return buildUrlToActivityCenterMap(selectedRCId);
    }

    return buildUrlToActivityCenter(selectedRCId);
  }

  return baseRoute;
};

export default getUrlForMapTabBasedOnState;
