import environment from "common/relay/relay-env";
import React from "react";

import logException from "common/analytics/exceptions";
import BlockUserMutation from "mutations/BlockUserMutation";
import UnblockUserMutation from "mutations/UnblockUserMutation";

import { AuthenticatedServiceProviders_me$data as AuthenticatedServiceProvidersMe } from "__generated__/AuthenticatedServiceProviders_me.graphql";

export type ExposedProps = {
  isUserBlocked: (userId: string) => boolean;
  hasUserBlockedMe: (userId: string) => boolean;
  blockUser: (userId: string) => Promise<void>;
  unblockUser: (userId: string) => Promise<void>;
  blockedUsers: AuthenticatedServiceProvidersMe["blockedUsers"];
};

type Props = {
  blockedUsers: AuthenticatedServiceProvidersMe["blockedUsers"];
  blockedByUserMap: Record<string, boolean>;
  children: React.ReactNode;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  isUserBlocked: () => false,
  hasUserBlockedMe: () => false,
  blockUser: () => Promise.reject(new Error("BlockedUserProvider not ready!")),
  unblockUser: () => Promise.reject(new Error("BlockedUserProvider not ready!")),
  blockedUsers: [],
};

const BlockedUsersContext = React.createContext(INITIAL_CONTEXT);

export class BlockedUsersProvider extends React.Component<Props, State> {
  isUserBlocked = (userId: string): boolean => {
    return !!this.props.blockedUsers.find((user) => user.id === userId);
  };

  hasUserBlockedMe = (userId: string): boolean => {
    return Boolean(this.props.blockedByUserMap[userId]);
  };

  blockUser = async (userId: string): Promise<void> => {
    const payload = { userId };

    try {
      await BlockUserMutation.commit(environment, payload);
    } catch (error) {
      logException("BlockUserMutation.commit", "blockUser", "EllipsisButton", error);
      throw new Error(error as string);
    }
  };

  unblockUser = async (userId: string): Promise<void> => {
    const payload = { userId };

    try {
      await UnblockUserMutation.commit(environment, payload);
    } catch (error) {
      logException("UnblockUserMutation.commit", "unblockUser", "EllipsisButton", error);
      throw new Error(error as string);
    }
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <BlockedUsersContext.Provider
        value={{
          isUserBlocked: this.isUserBlocked,
          hasUserBlockedMe: this.hasUserBlockedMe,
          blockUser: this.blockUser,
          unblockUser: this.unblockUser,
          blockedUsers: this.props.blockedUsers,
        }}
      >
        {children}
      </BlockedUsersContext.Provider>
    );
  };
}

export const BlockedUsersConsumer = BlockedUsersContext.Consumer;
