import React, { ForwardedRef } from "react";
import { animated, useSpring } from "react-spring";

import { UIPopperTransitionProps } from "./transitionTypes";

/**
 * Delays the mount and unmount of the popper content to allow developer to control the
 * animation manually. The style from react-spring is NOT applied to the element, but the spring
 * value still animates, which allows gives us this delay like effect.
 */
const UIPopperDelayedUnmount = React.forwardRef(function Fade(
  props: UIPopperTransitionProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  // Forces react-spring to animate the value, keeping the content mounted until the
  // spring comes to rest, but we dont apply the spring value to only get the delay effect.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} {...other}>
      {children}
    </animated.div>
  );
});

export default UIPopperDelayedUnmount;
