import environment from "common/relay/relay-env";
import { graphql } from "react-relay";
import { fetchQuery } from "relay-runtime";

import { DEFAULT_BOUNDS } from "constants/map";

import { fetchGloballyAvailableRealityChannels_Query$data as FetchGloballyAvailableRealityChannelsQueryResponse } from "__generated__/fetchGloballyAvailableRealityChannels_Query.graphql";

const FETCH_GLOBAL_RCS = graphql`
  query fetchGloballyAvailableRealityChannels_Query($input: RealityChannelsInLatLngBoundsInput!) {
    realityChannelsInLatLngBounds(input: $input) {
      id
      name
      iconURL
    }
  }
`;

export default async (): Promise<FetchGloballyAvailableRealityChannelsQueryResponse> => {
  const response = await fetchQuery(environment, FETCH_GLOBAL_RCS, {
    input: {
      bounds: DEFAULT_BOUNDS,
    },
  }).toPromise();

  return response as FetchGloballyAvailableRealityChannelsQueryResponse;
};
