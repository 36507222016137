import _ from "lodash";
import React from "react";

import HiddenMessageStore from "common/stores/HiddenMessageStore";
import ReportedEntityStore from "common/stores/ReportedEntityStore";
import dayjs from "common/utils/dayjs";

export enum ReportedEntityType {
  POST = "post",
  MESSAGE = "message",
  EVENT = "event",
}

export type ExposedProps = {
  addReportedEntity: (type: ReportedEntityType, id: string) => void;
  removeReportedEntity: (type: ReportedEntityType, id: string) => void;
  isEntityReported: (type: ReportedEntityType, id: string) => boolean;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  // key/value pairs of entityId and timestamp
  reportedEntities: Record<string, number>;
};

// Expire entities that have persisted for over 30 days since we don't want to build up
// an infinite list. By 30 days, the reported entities should have been moderated or
// they are no longer relevant.
const TTL: number = dayjs.duration(30, "days").asMilliseconds();

const INITIAL_CONTEXT: ExposedProps = {
  addReportedEntity: () => undefined,
  removeReportedEntity: () => undefined,
  isEntityReported: () => false,
};

export const ReportedEntityContext = React.createContext(INITIAL_CONTEXT);

export class ReportedEntityProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Note: We are migrating `HiddenMessageStore` to `ReportedEntityStore`.
    // A month of so after the release of this code we can come back to this
    // and completely remove HiddenMessageStore.
    // We will also need remove the special message handling from this.formatEntityKey().
    const reportedEntities =
      {
        ...HiddenMessageStore.getStore(),
        ...ReportedEntityStore.getStore(),
      } || {};

    ReportedEntityStore.updateStore(reportedEntities);
    HiddenMessageStore.clear();

    this.state = {
      reportedEntities,
    };
  }

  syncWithStore = (): void => {
    const { reportedEntities } = this.state;

    ReportedEntityStore.updateStore(reportedEntities);
  };

  removeExpiredFromReportedEntities = (
    reportedEntities: Record<string, number>,
  ): Record<string, number> => {
    const clonedEntities = _.cloneDeep(reportedEntities);
    const now = Date.now();
    const keysToDelete: string[] = [];

    Object.keys(clonedEntities).forEach((key: string) => {
      const diff = now - clonedEntities[key];

      if (diff > TTL) {
        keysToDelete.push(key);
      }
    });

    keysToDelete.forEach((key) => {
      delete clonedEntities[key];
    });

    return clonedEntities;
  };

  formatEntityKey = (type: ReportedEntityType, id: string): string => {
    // We're currently migrating from the old hidden messages store,
    // so we need to respect the legacy id format.
    if (type === ReportedEntityType.MESSAGE) return id;
    return `${type}:${id}`;
  };

  addReportedEntity = (type: ReportedEntityType, id: string): void => {
    const key = this.formatEntityKey(type, id);
    const entity = { [key]: Date.now() };

    this.setState(
      (prevState) => ({
        reportedEntities: {
          ...this.removeExpiredFromReportedEntities(prevState.reportedEntities),
          ...entity,
        },
      }),
      this.syncWithStore,
    );
  };

  removeReportedEntity = (type: ReportedEntityType, id: string): void => {
    const key = this.formatEntityKey(type, id);
    const clonedEntities = _.cloneDeep(this.state.reportedEntities);

    delete clonedEntities[key];

    this.setState(
      {
        reportedEntities: {
          ...this.removeExpiredFromReportedEntities(clonedEntities),
        },
      },
      this.syncWithStore,
    );
  };

  isEntityReported = (type: ReportedEntityType, id: string): boolean => {
    const key = this.formatEntityKey(type, id);

    return key in this.state.reportedEntities;
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <ReportedEntityContext.Provider
        value={{
          addReportedEntity: this.addReportedEntity,
          removeReportedEntity: this.removeReportedEntity,
          isEntityReported: this.isEntityReported,
        }}
      >
        {children}
      </ReportedEntityContext.Provider>
    );
  };
}

export const ReportedEntityConsumer = ReportedEntityContext.Consumer;
