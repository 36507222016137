import { locationSharp } from "ionicons/icons";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import UIAsyncButton from "common/components/UIAsyncButton";
import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  isActive: boolean;
  checkingIfLocationServicesEnabled: boolean;
  enableLocationServices: () => void;
  next: () => void;
};

type State = {};

class AccountSetupLocationServicesPermissionSlideView extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UISpacer h={100} />

        <UIText variant="h2" color="dark" weight="bold">
          {this.props.t("ENABLE_LOCATION")}
        </UIText>

        <UISpacer h={24} />

        <div>
          <UIText color="dark" variant="h3" weight="medium">
            {this.props.t("DISCOVER_NEARBY_COMMUNITIES_ACTIVITIES")}
          </UIText>
        </div>

        {/* <UISpacer h={24} /> */}

        {/* <div className={styles.serviceToggle}> */}
        {/*  {locationServicesEnabled && ( */}
        {/*    <IonIcon */}
        {/*      className={styles.success} */}
        {/*      icon={checkmarkCircle} */}
        {/*    /> */}
        {/*  )} */}
        {/*  {!locationServicesEnabled && <IonIcon icon={checkmarkCircleOutline} />} */}
        {/* </div> */}

        <UISpacer h={24} />

        <div>
          <UIIcon icon={locationSharp} size={80} color="primary" />
        </div>

        <UIAsyncButton
          className={styles.enableBtn}
          expand="block"
          onClick={this.props.enableLocationServices}
          disabled={this.props.checkingIfLocationServicesEnabled}
        >
          <UIText weight="bold">{this.props.t("ENABLE_LOCATION").toLocaleUpperCase()}</UIText>
        </UIAsyncButton>
      </div>
    );
  };
}

export default withTranslation()(AccountSetupLocationServicesPermissionSlideView);
