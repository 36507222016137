import { CheckboxChangeEventDetail } from "@ionic/core";
import { IonCheckbox } from "@ionic/react";
import React from "react";

import styles from "./styles.scss";

export type Props = {
  checked: boolean;
  color?: IonicThemeShade;
  disabled?: boolean;
  isControlled?: boolean;
  onChange?: (checked: boolean) => void;
};

type State = {};

export default class UICheckbox extends React.Component<Props, State> {
  componentDidUpdate = (prevProps: Readonly<Props>): void => {
    // When we manually swap this back to match props, it will call the change handler
    // on the ion-checkbox. So in the controlled scenario, we will only announce onChange
    // if the props change.
    if (this.props.isControlled && prevProps.checked !== this.props.checked) {
      if (this.props.onChange) {
        this.props.onChange(this.props.checked);
      }
    }
  };

  handleIonChange = (event: CustomEvent<CheckboxChangeEventDetail>): void => {
    // IonCheckbox works with DOM events, which suck for React.
    // So if we wanna do things the more React way, we can flag this component as being controlled.
    // When doing this, we will force the checked state of the ion-checkbox to be whatever
    // the value is on props.
    // https://github.com/ionic-team/ionic-framework/issues/10309
    if (this.props.isControlled) {
      // NOTE: This update triggers the ionChange event to fire twice.
      // 1. Once when we initially clicked.
      // 2. Another time for changing
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      event.target.checked = this.props.checked;
    } else if (this.props.onChange) {
      this.props.onChange(event.detail.checked);
    }
  };

  render = (): React.ReactNode => {
    const { color = "success" } = this.props;

    return (
      <IonCheckbox
        className={styles.root}
        checked={this.props.checked}
        color={color}
        disabled={this.props.disabled}
        onIonChange={this.handleIonChange}
      />
    );
  };
}
