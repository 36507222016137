import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { ReactComponent as LineSeparator } from "assets/images/niantic/games/mhnow/line-separator.svg";

import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  errorTitle?: string;
  errorDescription?: string;
  errorButtonText?: string;
  onButtonClick?: () => void;
};

type State = {};

/**
 * Common component to encapsulate the styling of an error view that's specific to Magellan.
 *
 * All current Magellan-related errors are rendered by themselves so this component will expand
 * to take up the full size of the parent.
 */
class MagellanErrorView extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const errorTitle = this.props.errorTitle || this.props.t("ERROR");
    const errorDescription =
      this.props.errorDescription || this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN");
    const errorButtonText = this.props.errorButtonText || this.props.t("RETRY");

    return (
      <div className={styles.root}>
        <div className={styles.errorView}>
          <UIText variant="h2" weight="bold" className={styles.magellanText}>
            {errorTitle}
          </UIText>

          <LineSeparator className={styles.lineSeparator} />

          <UISpacer h={24} />

          <div className={styles.errorViewBody}>
            <UIText variant="body1" weight="medium" className={styles.magellanText}>
              {errorDescription}
            </UIText>

            {this.props.onButtonClick && (
              <>
                <UISpacer h={32} />

                <button
                  type="button"
                  className={styles.errorButton}
                  onClick={this.props.onButtonClick}
                >
                  <UIText variant="body1" weight="bold" className={styles.magellanText}>
                    {errorButtonText}
                  </UIText>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(MagellanErrorView);
