export const NIANTIC_FLARES_URL = "https://niantic.helpshift.com/hc/en/34-campfire/faq/3492-flares";
export const NIANTIC_TERMS_URL = "https://nianticlabs.com/terms";
export const NIANTIC_TERMS_EMBEDDED_URL = "https://nianticlabs.com/terms-embedded";
export const NIANTIC_SUPPORT_URL = "https://nianticlabs.com/en/support";
export const NIANTIC_CAMPFIRE_SUPPORT_URL = "https://niantic.helpshift.com/hc/en/34-campfire";
export const NIANTIC_PRIVACY_URL = "https://nianticlabs.com/privacy";
export const NIANTIC_PRIVACY_EMBEDDED_URL = "https://nianticlabs.com/privacy-embedded";
export const NIANTIC_HELPSHIFT_URL =
  "https://niantic.helpshift.com/a/niantic-chat/?p=web&contact=1";
export const NIANTIC_HELPSHIFT_SUPPORT_URL = "https://ingress.com/support";
export const NIANTIC_GUIDELINES_URL = "https://nianticlabs.com/guidelines";
export const NIANTIC_HELPSHIFT_ACCOUNT_LINKING_URL =
  "https://niantic.helpshift.com/hc/en/34-campfire/faq/3448-other-ways-to-log-in-to-campfire/";
export const NIANTIC_HELPSHIFT_BAN_APPEAL_URL =
  "https://niantic.helpshift.com/hc/faq/3655-ban-appeals/";
export const NIANTIC_HELPSHIFT_ACCOUNT_VISIBILITY_URL =
  "https://niantic.helpshift.com/hc/en/34-campfire/faq/3487-what-can-others-see-about-me-in-campfire/?p=web";
export const NIANTIC_IP_POLICY = "https://www.nianticlabs.com/ip-policy";
