/**
 * @generated SignedSource<<f295e82cc27021fb90ce05b146930431>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignRolesToUserInput = {
  clubId: string;
  roleIds: ReadonlyArray<string>;
  userId: string;
};
export type AssignRolesToUserMutation$variables = {
  clubId: string;
  input: AssignRolesToUserInput;
};
export type AssignRolesToUserMutation$data = {
  readonly assignRolesToUser: {
    readonly success: boolean;
    readonly user: {
      readonly isAdmin: boolean;
    };
  };
};
export type AssignRolesToUserMutation = {
  response: AssignRolesToUserMutation$data;
  variables: AssignRolesToUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "clubId",
      "variableName": "clubId"
    }
  ],
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignRolesToUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AssignRolesToUserResponse",
        "kind": "LinkedField",
        "name": "assignRolesToUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssignRolesToUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AssignRolesToUserResponse",
        "kind": "LinkedField",
        "name": "assignRolesToUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b64bef7defcf5e550a09e983ad2207e",
    "id": null,
    "metadata": {},
    "name": "AssignRolesToUserMutation",
    "operationKind": "mutation",
    "text": "mutation AssignRolesToUserMutation(\n  $input: AssignRolesToUserInput!\n  $clubId: ID!\n) {\n  assignRolesToUser(input: $input) {\n    user {\n      isAdmin(clubId: $clubId)\n      id\n    }\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "dede69808b56eb331a8e4a10542d9f96";

export default node;
