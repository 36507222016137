import { merge } from "lodash";

// Store completed coachmarks to user's localStorage

const DEFAULT_STORE_NAME = "nia-coachmark.store";

export const getCoachmarksInStore = (storeName: string = DEFAULT_STORE_NAME) => {
  const storeAsString = localStorage.getItem(storeName);

  if (storeAsString) {
    try {
      const parsedValue = JSON.parse(storeAsString);

      return parsedValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("[Coachmark] Error parsing JSON from string", error);
    }
  }

  return {};
};

export const setCoachmarkInStore = (
  coarchmarkName: string,
  completedAt: number,
  storeName: string = DEFAULT_STORE_NAME,
): void => {
  const updatedStore = merge(getCoachmarksInStore(storeName), { [coarchmarkName]: completedAt });
  const updatedStoreAsString = JSON.stringify(updatedStore);

  localStorage.setItem(storeName, updatedStoreAsString);
};
