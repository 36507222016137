import THEMES, { ThemeName, ThemeConfig } from "common/css/themes";
import ThemeStore from "common/stores/ThemeStore";
import { parseQuery } from "common/utils/url";
import { GAME_SHORT_CODES, GameShortCode } from "constants/nianticGame";
import { QUERY_PARAMS } from "constants/routes";

const isSupportedTheme = (theme: string): boolean => {
  return Boolean(THEMES[theme as ThemeName]);
};

/**
 * Restores the default theme by removing all override classnames possible from the document.body.
 */
export const restoreDefaultTheme = (): void => {
  const allThemeNames: ThemeName[] = Object.keys(THEMES) as ThemeName[];
  const allThemeConfigs: ThemeConfig[] = allThemeNames.map(
    (themeName: ThemeName) => THEMES[themeName],
  );
  const classnamesToRemove: string[] = allThemeConfigs
    .map((themeConfig: ThemeConfig) => themeConfig.bodyClassname)
    .filter(Boolean) as string[];

  // Remove all theme override classnames, which should net the default theme.
  classnamesToRemove.forEach((classNameToRemove: string) => {
    document.body.classList.remove(classNameToRemove);
  });
};

/**
 * Changes the core ionic color themes used by our components.
 * Applies a classname to the document.body.
 */
export const changeApplicationColorTheme = (theme: string): void => {
  if (isSupportedTheme(theme)) {
    const { bodyClassname, isDefault = false } = THEMES[theme as ThemeName];

    if (isDefault) {
      restoreDefaultTheme();
    } else if (bodyClassname) {
      document.body.classList.add(bodyClassname);
    }
  }
};

/**
 * Initializes the theme of the application.
 */
export const initializeAppTheme = (): void => {
  // 1. Look at the url for a theme query param. Use that if provided.
  // 2. If not found in url, look at localStorage for previously saved value.
  // 3. Otherwise, do nothing.
  const query = parseQuery(window.location.search);
  const themeQueryParam = QUERY_PARAMS.root.theme.key;
  const themeFromUrl = query[themeQueryParam];
  const lastSavedTheme = ThemeStore.get("lastSavedTheme");

  let activeTheme: ThemeName | null = null;

  if (themeFromUrl) {
    if (isSupportedTheme(themeFromUrl)) {
      changeApplicationColorTheme(themeFromUrl);
      activeTheme = themeFromUrl;
    }
  } else if (lastSavedTheme && isSupportedTheme(lastSavedTheme)) {
    changeApplicationColorTheme(lastSavedTheme);
    activeTheme = lastSavedTheme;
  }

  // If we set a theme, save it for next time use.
  if (activeTheme) {
    ThemeStore.set("lastSavedTheme", activeTheme);
  }
};

/**
 * Runs theme overrides for certain games. Does NOT update the last saved theme.
 */
export const overrideThemeForGame = (game: GameShortCode): void => {
  // Ingress always wants us to be in dark mode
  if (game === GAME_SHORT_CODES.ING) {
    // Disabled for now.
    // changeApplicationColorTheme("dark");
  }
};
