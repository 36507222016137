import _ from "lodash";

type ColorCodes =
  | "#16BCF0"
  | "#FF731E"
  | "#03CE76"
  | "#FF453F"
  | "#F9BA2E"
  | "#3571C5"
  | "#ACB6C5"
  | "#FF80A2";

const backgroundColors: ColorCodes[] = [
  "#16BCF0",
  "#FF731E",
  "#03CE76",
  "#FF453F",
  "#F9BA2E",
  "#3571C5",
  "#ACB6C5",
  "#FF80A2",
];

const generateRandomColor = _.memoize((str = "") => {
  const lowerCaseString = str.toLowerCase();
  let sum = 0;

  for (let index = 0; index < lowerCaseString.length; index += 1) {
    sum += lowerCaseString.charCodeAt(index);
  }

  const bgColor = backgroundColors[sum % backgroundColors.length];

  return bgColor;
});

export default generateRandomColor;
