import React from "react";

import { UINavConsumer, ExposedProps } from "common/components/UINav";
import { HookReturnType as UINavSlideStackState } from "common/components/UINav/useUINavSlideStack";

export type Props = {
  contentShouldBeRenderedWhileClosed?: boolean;
  slideId: string;
  gestureEnabled?: boolean;
  onHidden?: (uiNavSlideStackState: UINavSlideStackState) => void;
  onOpen?: (uiNavSlideState: UINavSlideStackState) => void;
  children: (exposedProps: ExposedProps) => React.ReactNode;
};

/**
 * Connects to the UINav and exposes its interface. Identifies the slide content
 * with an id when performing show/hide.
 */
export default class UINavSlide extends React.Component<Props> {
  render = (): React.ReactNode => {
    return (
      <UINavConsumer>
        {(exposedProps: ExposedProps) => this.props.children(exposedProps)}
      </UINavConsumer>
    );
  };
}
