import { invokeRemoteAsync } from "common/utils/webInterop/plugins";

import checkPermissions, { validator as checkPermissionsValidator } from "./checkPermissions";
import requestPermission, { validator as requestPermissionValidator } from "./requestPermissions";

export default {
  checkPermissions: invokeRemoteAsync<typeof checkPermissions, "Camera">(
    checkPermissions,
    checkPermissionsValidator,
    "Camera",
    "checkPermissions",
  ),
  requestPermissions: invokeRemoteAsync<typeof requestPermission, "Camera">(
    requestPermission,
    requestPermissionValidator,
    "Camera",
    "requestPermissions",
  ),
};
