const isDev = process.env.NODE_ENV === "development";
const buildTargetDevOrQA =
  process.env.CAMPFIRE_APP_TARGET_ENV === "dev" || process.env.CAMPFIRE_APP_TARGET_ENV === "qa";

export const logInDevelopmentEnvs = (...args: unknown[]): void => {
  if (isDev || buildTargetDevOrQA) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export default isDev;
