import { BackButtonEvent } from "@ionic/core";

type Handler = (processNextHandler: () => void) => Promise<unknown> | void | null;

interface HandlerRegister {
  priority: number;
  handler: Handler;
  id: number;
}

let busy = false;

/**
 * Handling the hardware back button in android.
 *
 * To understand how we handle the android hardware back, it's important to understand how
 * capacitor and ionic handle it first.
 *
 * How Capacitor handles the hardware back
 * =======================================
 *
 * When the capacitor app loads in Android, it sets up a hardware back handler. You can see this
 * here: https://github.com/ionic-team/capacitor-plugins/blob/main/app/android/src/main/java/com/capacitorjs/plugins/app/AppPlugin.java#L54
 *
 * This is how capacitor translates the hardware back into a JS Event called "backbutton". However,
 * notice that there is no info about whether or not the webView can go back, aka, the history stack
 * is empty. This is really important to know so that we can minimize the app when you can't go back anymore.
 *
 * If you look at the line right above, (see here: https://github.com/ionic-team/capacitor-plugins/blob/656f9169ccd8d7fa880143b13ca5f62bb546edb0/app/android/src/main/java/com/capacitorjs/plugins/app/AppPlugin.java#L53)
 * you will notice there is another listener that gets notified of this back event, and along with that
 * is the boolean "canGoBack". This is only accessible within the App plugin from Capacitor. I'll come back to this later. (Reference_A)
 *
 * Coming back to the JS Event "backbutton", this event gets handled by Ionic if the developer desires it by
 * configuring Ionic to have "hardwareBackEnabled: true". This is done here: https://github.com/ionic-team/ionic-framework/blob/a9893640b726fe86a6d5341289d4b5757b11cd11/core/src/components/app/app.tsx#L32
 * and you can see the listener attached here: https://github.com/ionic-team/ionic-framework/blob/0156be61cbf73b25cb3c2cba1bd20adebbb3db4f/core/src/utils/hardware-back-button.ts#L29
 * This then flows into how Ionic Handles the "backbutton" event.
 *
 * How Ionic handles the hardware back
 * ===================================
 * If Ionic is setup to handle the hardware back, it converts that event to the "ionBackButton" event.
 * This event is handled internally by Ionic using a priority queue, invoking the highest priority
 * callback registered on the dispatching of the event. See here: https://github.com/ionic-team/ionic-framework/blob/0156be61cbf73b25cb3c2cba1bd20adebbb3db4f/core/src/utils/hardware-back-button.ts#L29
 * You can think of this like when this event is heard, Ionic asks anything who is listening for the
 * event, "Hey, how important are you?". Since JS events are synchronous, eventually all those
 * event handlers are called, and Ionic will have a set of "handlers" (https://github.com/ionic-team/ionic-framework/blob/0156be61cbf73b25cb3c2cba1bd20adebbb3db4f/core/src/utils/hardware-back-button.ts#L35)
 * from which to make the priority decision.
 *
 * The Problem
 * ===========
 * Simply put, in this system, there is no way to discern if the user can go back anymore.
 * This is why (Reference_A) is important. That other notifier called in the App Plugin DOES contain info
 * about whether we can go back. You can sorta see how we really need both systems in place
 * to handle the hardware back button nicely. The priority queue helps us choose which action to take
 * such as dismissing the correct modal if multiple are stacked on top of each other, but the event in the plugin also
 * allow us to handle the case where the user has nothing on their history stack, but has a modal open.
 * In this last case, the correct action is to dismiss the modal, not minimize the application.
 *
 * A Solution
 * ============
 * A solution for this is to manually invoke the "ionBackButton" event, but do so when we have the
 * info if we can go back. We can modify the behavior ionic does when translating the "backbutton"
 * event to the "ionBackButton" event slightly to return information about whether there was any
 * handler registered. We assume that if there was some handler registered, something
 * wanted to take some action and so we should respect that. This allows us to only minimize on back when there is
 * truly nothing to do. This means that if we attach a ionBackButton handler that does nothing
 * but is always present, this would prevent the user from minimizing the app.
 *
 * This is why in prepareApp.ts, we prevent ionic from reacting to the back button by setting it to
 * false in setupConfig().
 *
 * In essence, we handle the back button event specifically in the App plugin event. Or on embed
 * we react to the event via the action emitter itself.
 *
 * Taken from: https://github.com/ionic-team/ionic-framework/blob/7315e0157b917d2e3586c64f8082026827812943/core/src/utils/hardware-back-button.ts
 * and modified.
 *
 * Returns a boolean if there are more handlers that were registered but not called.
 */
// eslint-disable-next-line import/prefer-default-export
export const simulateIonBackEvent = (): boolean => {
  if (busy) {
    return true;
  }

  let index = 0;
  let handlers: HandlerRegister[] = [];
  const ionBackButtonEvent: BackButtonEvent = new CustomEvent("ionBackButton", {
    bubbles: false,
    detail: {
      register(priority: number, handler: Handler) {
        index += 1;
        handlers.push({ priority, handler, id: index });
      },
    },
  });

  const executeAction = async (handlerRegister: HandlerRegister | undefined) => {
    try {
      if (handlerRegister && handlerRegister.handler) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const result = handlerRegister.handler(processHandlers);

        if (result != null) {
          await result;
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const processHandlers = () => {
    if (handlers.length > 0) {
      let selectedHandler: HandlerRegister = {
        priority: Number.MIN_SAFE_INTEGER,
        handler: () => undefined,
        id: -1,
      };

      handlers.forEach((handler) => {
        if (handler.priority >= selectedHandler.priority) {
          selectedHandler = handler;
        }
      });

      busy = true;
      // NOTE: handler is removed
      handlers = handlers.filter((handler) => handler.id !== selectedHandler.id);
      // eslint-disable-next-line no-return-assign
      executeAction(selectedHandler).then(() => (busy = false));
    }
  };

  document.dispatchEvent(ionBackButtonEvent);

  processHandlers();

  // handlers is NOT the same array potentially after invoking processHandlers().
  // processHandlers can potentially trigger more than 1 handler if desired, and each time
  // the handler that was processed gets removed (see NOTE: handler is removed). This is all done
  // sync, so at the end of the process, handlers will be a set of remaining handlers that were
  // not invoked.
  return Boolean(handlers.length);
};
