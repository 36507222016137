import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { IonApp } from "@ionic/react";
import classnames from "classnames";
import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { BrowserRouter, withRouter, RouteComponentProps } from "react-router-dom";
import compareVersion from "semver/functions/compare";

import withAuth, { WithAuthProps } from "providers/AuthProvider/withAuth";
import withStandaloneSettings, {
  WithStandaloneSettingsProps,
} from "providers/StandaloneSettingsProvider/withStandaloneSettings";
import withVersionInfo, {
  WithVersionInfoProps,
} from "providers/VersionInfoProvider/withVersionInfo";

import { isWeb, isPurelyWeb, isStandalone, isIos } from "common/capacitor/helpers";
import checkSession from "common/requests/checkSession";
import RouteOnLoadStore from "common/stores/RouteOnLoadStore";
import { initializeBootAutoUpdater } from "common/utils/appUpdate";
import hasWebAccessBypass from "common/utils/testers/hasWebAccessBypass";
import { isEmbedded, isMHNowEmbed, isPGOEmbed } from "common/utils/webInterop";
import { FEATURE_FLAGS } from "constants/featureFlags";
import isDev from "constants/isDev";
import { ONE_HOUR } from "constants/time";

import AuthenticatedAppModeRenderer from "./components/AuthenticatedAppModeRenderer";
import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import PollingRefetcher from "common/components/PollingRefetcher";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import AppError from "boot-loader/components/AppError";
import BootLoaderLayout from "boot-loader/components/BootLoaderLayout";
import BootloaderLoadingSpinner from "boot-loader/components/BootloaderLoadingSpinner";
import DeepLinkHandler, { ParsedDeepLink } from "boot-loader/components/DeepLinkHandler";
import EmbedRemoteActionHandler from "boot-loader/components/EmbedRemoteActionHandler";
import EmbeddedBootLoader from "boot-loader/components/EmbeddedBootLoader";
import HardwareBackHandler from "boot-loader/components/HardwareBackHandler";
import LandingPage from "boot-loader/components/LandingPage";
import ServiceProviders from "boot-loader/components/ServiceProviders";
import UIProviders from "boot-loader/components/UIProviders";

import { checkSession_Query$data as CheckSessionQueryResponse } from "__generated__/checkSession_Query.graphql";

import styles from "./styles.scss";

type Props = WithAuthProps &
  RouteComponentProps &
  WithVersionInfoProps &
  WithStandaloneSettingsProps;

export type SetupStepsNeeded = {
  nianticId: boolean;
  friendRecommendations: boolean;
  gameVisibility: boolean;
  services: boolean;
};

export type OnboardingType = "fast" | "skip-all";

export type AuthenticatedViewMode = "app" | "setup" | "banned" | "reset";

type State = {
  onboardingType: OnboardingType | undefined;
  isMasterKillSwitchActive: boolean;
  isInitializing: boolean;
  isTakingAWhile: boolean;
  isLoggingIn: boolean;
  authenticatedViewMode: AuthenticatedViewMode | undefined;
  setupStepsNeeded: SetupStepsNeeded;
  setupInitialValues: {
    displayName: string;
    username: string;
    birthday: string;
  };
};

export const ANIMATION_TIMING_MS = {
  // How long the fade in of the logged out landing page takes. (Logging out, loading the app).
  landingPageFadeInDurationMS: 200,

  // How long the fade out of the logged out landing page takes. (Like after you log in).
  landingPageFadeOutDurationMS: 10,

  // How long the fade in of the app view takes. (Showing reminder on launch, post onboarding)
  appFadeInDurationMS: 100,

  // How long to delay the fading in of the app. (For easing transitions between views)
  appFadeInDelayMS: 500,
};

const TAKING_A_WHILE_DELAY_MS = 2000;
const MASTER_KILL_SWITCH_LONG_POLL_MS = ONE_HOUR;
const MS_BEFORE_HIDING_SPLASHSCREEN = 5000;

/**
 * The main container for the app. Used for initial boot strapping of the client to provide
 * faster time to first paint.
 *
 * NOTES:
 * - We want to keep the IonApp the full size of the viewport so that modal backdrops and other
 * things can appear as full screened.
 *    - The Signup page, and other unauthenticated flows are the full size of the screen. This
 *    means that they can bleed into the status bar. This is by design for cases where we want
 *    backgrounds to appear as part of the status bar.
 *    - The authenticated views, we want the content to appear immediately BELOW the status bar.
 *    HOWEVER, we want certain items like modals, to still be able to operate as full screen. As
 *    a result, it is up to the developer to figure out if they need to apply an additional
 *    bounding container.
 */
class BootLoader extends React.Component<Props, State> {
  // Timer until we give up since the initial hydration query is taking forever, to lift the splash screen.
  standaloneRevealTimer: ReturnType<typeof setTimeout> | null = null;

  static getInitialStateValues = (): State => {
    return {
      onboardingType: undefined,
      isMasterKillSwitchActive: false,
      isInitializing: true,
      isTakingAWhile: false,
      isLoggingIn: false,
      authenticatedViewMode: undefined,
      setupStepsNeeded: {
        nianticId: false,
        gameVisibility: false,
        friendRecommendations: false,
        services: false,
      },
      // Might use this in the future.
      // eslint-disable-next-line react/no-unused-state
      setupInitialValues: {
        displayName: "",
        username: "",
        birthday: "",
      },
    };
  };

  constructor(props: Props) {
    super(props);

    const shouldSkipAllOnboarding = isPGOEmbed || isMHNowEmbed;
    const initialOnboardingType = shouldSkipAllOnboarding ? "skip-all" : undefined;
    const initialState = BootLoader.getInitialStateValues();

    this.state = {
      ...initialState,
      onboardingType: initialOnboardingType,
    };
  }

  /**
   * This is the main hydration of the user in the app. Here, we check the session, and pull
   * any initial data we need to hydrate the user.
   */
  componentDidMount = async (): Promise<void> => {
    const timer = setTimeout(() => {
      this.setState({ isTakingAWhile: true });
    }, TAKING_A_WHILE_DELAY_MS);

    try {
      if (isStandalone) {
        // Setup a timer to give us 5000ms before auto hiding the splash screen.
        // We give ourselves 5_000ms to perform some work while the splash screen is up
        // before we start to display the app.
        this.setupStandaloneRevealTimer();

        if (isIos) {
          this.configureForIOS();
        }

        // For standalone, attempt to refresh the session.
        await this.props.authProvider.refreshSession();

        // Fetch who this user is, so we can discern some feature flags
        const checkSessionResponse = await checkSession();

        // Check if we need to initialize the auto updater
        // NOTE: The boot auto updater is currently only a feature for authenticated users.
        await this.initializeBootAutoUpdater(checkSessionResponse);

        // All work we needed to do is now complete, proceed to the app.
        this.finishAuthAndProceedToApp(checkSessionResponse);
      } else {
        if (!isWeb) {
          SplashScreen.hide();
        }

        // Fetch who this user is, so we can discern some feature flags
        const checkSessionResponse = await checkSession();

        this.finishAuthAndProceedToApp(checkSessionResponse);
      }
    } catch (error) {
      // If something went wrong, basically reset our state.
      this.logoutAndReset();
    } finally {
      clearTimeout(timer);
      this.revealStandaloneUI();
    }
  };

  componentDidUpdate = (prevProps: Readonly<Props>): void => {
    const gracefulLogoutDetected =
      prevProps.authProvider.isLoggedIn && !this.props.authProvider.isLoggedIn;

    // Upon detecting a graceful logout, we should reset the state of this component
    // to be as if the user hasn't touched anything.
    if (gracefulLogoutDetected) {
      const initialState = BootLoader.getInitialStateValues();

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        ...initialState,
        // Really important to set this as false and not the initial value since, well
        // we've already initialized! Also if you don't do this, enjoy a nice white screen.
        isInitializing: false,
      });
    }
  };

  logoutAndReset = () => {
    this.props.authProvider.setIsLoggedIn(false);
    this.setState({
      isInitializing: false,
      isTakingAWhile: false,
      isLoggingIn: false,
    });
  };

  hasFeature = (featureFlags: ReadonlyArray<string>, flag: string): boolean => {
    const featureFlagMap = featureFlags.reduce(
      (map: { [key: string]: boolean }, featureFlagName) => {
        // eslint-disable-next-line no-param-reassign
        map[featureFlagName] = true;
        return map;
      },
      {},
    );

    return !!featureFlagMap[flag];
  };

  handleUpdateOnboardingType = (type: OnboardingType): void => {
    this.setState({ onboardingType: type });
  };

  initializeBootAutoUpdater = async (
    checkSessionResponse: CheckSessionQueryResponse,
  ): Promise<void> => {
    if (isStandalone) {
      // Check if we need to initialize the auto updater
      // NOTE: The boot auto updater is currently only a feature for authenticated users.
      if (this.hasFeature(checkSessionResponse.me.featureFlags, FEATURE_FLAGS.APP_AUTO_UPDATE)) {
        await initializeBootAutoUpdater();
      }
    }
  };

  configureForIOS = (): void => {
    // For iOS standalone only, set the status bar style so text is dark
    // since our app only has a light background right now.
    // Android's status bar seems to be black by default so don't affect it.
    StatusBar.setStyle({ style: Style.Light });
  };

  revealStandaloneUI = (): void => {
    if (this.standaloneRevealTimer) {
      clearTimeout(this.standaloneRevealTimer);
      this.standaloneRevealTimer = null;
    }

    SplashScreen.hide();
    CapacitorUpdater.notifyAppReady();
  };

  setupStandaloneRevealTimer = (): void => {
    this.standaloneRevealTimer = setTimeout(() => {
      this.revealStandaloneUI();
    }, MS_BEFORE_HIDING_SPLASHSCREEN);
  };

  /**
   * Determines the view mode of the authenticated app. Users may have been perma banned
   * or ineligible and this state can only be evaluated post authentication. This method
   * returns a mode that represents the most generalized state of the application.
   */
  determineAuthenticatedViewMode = (
    needsSetup: boolean,
    needsReset: boolean,
    isCampfireBanned: boolean,
  ): AuthenticatedViewMode => {
    // Banned users from campfire need to be blocked
    if (isCampfireBanned) {
      return "banned";
    }

    // Users who's account data has been to reset.
    if (needsReset) {
      return "reset";
    }

    // Users who need to perform the account setup need to go through it.
    if (needsSetup) {
      return "setup";
    }

    // If none of these cases above are met, assume we are good to load the app!
    return "app";
  };

  /**
   * Determines the setup steps we need to show. Typically, this is based on values
   * from the BE, but for some experiences such as deep linking from some games, we want
   * to lower the friction to interactivity, so we apply these overrides from here.
   */
  determineAccountSetupSteps = (
    hasSetNianticId: boolean,
    shouldShowNux: boolean,
    featureFlags: ReadonlyArray<string>,
    overrideType?: string,
  ): { needsSetup: boolean; setupStepsNeeded: SetupStepsNeeded } => {
    const needsToSetupNianticId = !hasSetNianticId;
    // Only show services if we needed to show these other parts of the flow.
    // If we aren't showing these, then assume the user is already setup.
    const showServiceSetup = needsToSetupNianticId;

    // This is the default steps.
    // Embed never gets services.
    const setupStepsNeeded: SetupStepsNeeded = {
      nianticId: needsToSetupNianticId,
      gameVisibility: shouldShowNux,
      friendRecommendations: shouldShowNux,
      services: isEmbedded ? false : showServiceSetup,
    };

    // If we want a fast onboarding, only show nianticId slide regardless
    // of what the BE says we should show.
    if (overrideType === "fast") {
      setupStepsNeeded.gameVisibility = false;
      setupStepsNeeded.friendRecommendations = false;
      setupStepsNeeded.services = false;
    } else if (overrideType === "skip-all") {
      setupStepsNeeded.nianticId = false;
      setupStepsNeeded.gameVisibility = false;
      setupStepsNeeded.friendRecommendations = false;
      setupStepsNeeded.services = false;
    }

    // Usually, if we detect user is from PGO embed, the overrideType is set to skip-all,
    // but because of the experiment, a Niantic ID should be set since the experiment
    // includes chat features.
    if (isPGOEmbed && featureFlags.includes(FEATURE_FLAGS.PGO_EXPERIMENT_ONE)) {
      setupStepsNeeded.nianticId = needsToSetupNianticId;
    }

    // If any key here is truthy, show the account setup flow.
    const needsSetup = Object.values(setupStepsNeeded).some((shouldShowSetupStepValue) => {
      return Boolean(shouldShowSetupStepValue);
    });

    return {
      needsSetup,
      setupStepsNeeded,
    };
  };

  finishAuthAndProceedToAppAfterLogin = async (): Promise<void> => {
    const checkSessionResponse = await checkSession();

    // Check if we need to initialize the auto updater after we login
    // NOTE: The boot auto updater is currently only a feature for authenticated users.
    await this.initializeBootAutoUpdater(checkSessionResponse);

    this.finishAuthAndProceedToApp(checkSessionResponse);
  };

  proceedToNextSequenceInApp = async (): Promise<void> => {
    const checkSessionResponse = await checkSession();

    this.finishAuthAndProceedToApp(checkSessionResponse);
  };

  /**
   * Called when the user is authenticated, and we want to bring them into the application.
   *
   * Invoked when web loads and user has a valid session or on standalone when user finishes
   * authentication. On web, we reload the page after auth so it does not follow the exact
   * same flow as standalone's authentication. But the process is the same.
   */
  finishAuthAndProceedToApp = (checkSessionResponse: CheckSessionQueryResponse): void => {
    const response = checkSessionResponse;

    const { hasSetNianticId, isSuperAdmin, featureFlags, hasAcknowledgedReset, shouldShowNux } =
      response.me;
    const { setupStepsNeeded, needsSetup } = this.determineAccountSetupSteps(
      hasSetNianticId,
      shouldShowNux,
      featureFlags,
      this.state.onboardingType,
    );
    // TODO: Integrate perma banning here. Hardcoded to false for now.
    const isBannedFromCampfire = false;
    const isResetFlowEnabled = this.hasFeature(featureFlags, FEATURE_FLAGS.ENABLE_RESET_FLOW);
    const needsReset = !hasAcknowledgedReset && isResetFlowEnabled;

    const authenticatedViewMode = this.determineAuthenticatedViewMode(
      needsSetup,
      needsReset,
      isBannedFromCampfire,
    );

    const isMasterKillSwitchFeatureEnabled = this.hasFeature(
      featureFlags,
      FEATURE_FLAGS.MASTER_KILL_SWITCH_ACTIVE,
    );

    const canAccessWebVersion = isSuperAdmin || isDev || hasWebAccessBypass();

    // When we are here and coming from the browser, only super admins or those on local dev env can pass.
    // For embed, all can pass.
    this.props.authProvider.setIsLoggedIn(isPurelyWeb ? canAccessWebVersion : true);
    this.setState({
      isMasterKillSwitchActive: isMasterKillSwitchFeatureEnabled,
      isInitializing: false,
      isTakingAWhile: false,
      isLoggingIn: false,
      authenticatedViewMode,
      setupStepsNeeded,
    });

    // Consume and route to the RouteOnLoadStore route, if it exists.
    const previousRoute = RouteOnLoadStore.consume();

    if (previousRoute !== "") {
      this.props.history.push(previousRoute);
    }
  };

  closeSetupFlow = () => {
    this.setState({ authenticatedViewMode: "app" });
  };

  closeAndReset = () => {
    this.props.authProvider.setIsLoggedIn(false);
    this.setState({
      isInitializing: false,
      isTakingAWhile: false,
      isLoggingIn: false,
      authenticatedViewMode: undefined,
    });
  };

  finishSetupFlow = async (): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.closeSetupFlow();
        resolve();
      }, 500);
    });
  };

  onDeepLink = (deepLink: ParsedDeepLink): void => {
    this.props.authProvider.handleDeepLinkFromGame(deepLink.GAME);
  };

  // Returns versioning information used by the boot loader renderer to determine
  // if it should show the force upgrade dialog on the landing page.
  getVersionInfo = (): { outOfDate: boolean; hasRequiredVersionInfo: boolean } => {
    if (isWeb) {
      return {
        outOfDate: false,
        hasRequiredVersionInfo: true,
      };
    }

    const { appVersion } = this.props.versionInfo;
    const minVersion = this.props.standaloneSettings.minFrontendVersion;

    if (appVersion === "" || minVersion === "") {
      return {
        outOfDate: false,
        hasRequiredVersionInfo: false,
      };
    }

    return {
      outOfDate: compareVersion(appVersion, minVersion) < 0,
      hasRequiredVersionInfo: true,
    };
  };

  renderMasterKillSwitchApp = (): React.ReactNode => {
    return (
      <div className={styles.fullScreen}>
        <AppError />
      </div>
    );
  };

  longPollChangesInMasterKillSwitch = async (): Promise<void> => {
    try {
      const response = await checkSession();

      const { featureFlags } = response.me;

      const isMasterKillSwitchFeatureEnabled = this.hasFeature(
        featureFlags,
        FEATURE_FLAGS.MASTER_KILL_SWITCH_ACTIVE,
      );

      // Check if the master kill switch is still on.
      // If it is, check if the app is currently blocked by the master kill switch.
      // If the app is not currently blocked, we should block it. This handles users who have the app
      // running and haven't relaunched the app.
      if (isMasterKillSwitchFeatureEnabled) {
        if (!this.state.isMasterKillSwitchActive) {
          this.setState({ isMasterKillSwitchActive: true });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-useless-return
      return;
    }
  };

  renderForEmbedded = (): React.ReactNode => {
    const { authenticatedViewMode, setupStepsNeeded } = this.state;
    const { isLoggedIn } = this.props.authProvider;

    return (
      <EmbeddedBootLoader
        isLoggedIn={isLoggedIn}
        authenticatedViewMode={authenticatedViewMode}
        setupStepsNeeded={setupStepsNeeded}
        closeAndReset={this.closeAndReset}
        finishSetupFlow={this.finishSetupFlow}
        proceedToNextSequenceInApp={this.proceedToNextSequenceInApp}
      />
    );
  };

  renderForMobileAndWeb = (): React.ReactNode => {
    const { authenticatedViewMode } = this.state;
    const { isLoggedIn } = this.props.authProvider;

    const { outOfDate, hasRequiredVersionInfo } = this.getVersionInfo();

    // Show the landing page once we load version info, and one of the following is true:
    // 1. The app is out of date.
    // 2. The user is not logged in.
    const showLandingPage = hasRequiredVersionInfo && (outOfDate || !isLoggedIn);

    return (
      <>
        <AnimatedFadeInOut
          type="fade-in-out"
          className={styles.fullScreen}
          show={showLandingPage}
          durationMs={
            isLoggedIn
              ? ANIMATION_TIMING_MS.landingPageFadeOutDurationMS
              : ANIMATION_TIMING_MS.landingPageFadeInDurationMS
          }
        >
          <LandingPage
            appOutOfDate={outOfDate}
            finishAuthAndProceedToApp={this.finishAuthAndProceedToAppAfterLogin}
          />
        </AnimatedFadeInOut>

        <AuthenticatedAppModeRenderer
          isLoggedIn={!showLandingPage}
          authenticatedViewMode={authenticatedViewMode}
          setupStepsNeeded={this.state.setupStepsNeeded}
          closeAndReset={this.closeAndReset}
          finishSetupFlow={this.finishSetupFlow}
          proceedToNextSequenceInApp={this.proceedToNextSequenceInApp}
          respectSafeArea
        />
      </>
    );
  };

  render = (): React.ReactNode => {
    const { isInitializing, isTakingAWhile, isLoggingIn, isMasterKillSwitchActive } = this.state;

    // If the app is taking a while to initialize, show a spinner after some time.
    if (isInitializing && !isTakingAWhile) {
      return null;
    }

    const renderEmbeded = isEmbedded;

    // MASTER KILL SWITCH: If we ever enable this flag, the app will appear in an error state.
    if (isMasterKillSwitchActive) {
      return this.renderMasterKillSwitchApp();
    }

    return (
      <UIErrorBoundary>
        <DeepLinkHandler
          setOnboardingType={this.handleUpdateOnboardingType}
          deepLinkListener={this.onDeepLink}
        />
        <PollingRefetcher
          pollingFn={this.longPollChangesInMasterKillSwitch}
          intervalTimeMs={MASTER_KILL_SWITCH_LONG_POLL_MS}
        />

        <AnimatedFadeInOut
          type="fade-out"
          className={classnames(styles.fullScreen, styles.appSpinner)}
          show={isTakingAWhile || isLoggingIn}
        >
          <BootloaderLoadingSpinner />
        </AnimatedFadeInOut>

        {!isInitializing && (
          <>
            {!renderEmbeded && this.renderForMobileAndWeb()}
            {renderEmbeded && this.renderForEmbedded()}
          </>
        )}
      </UIErrorBoundary>
    );
  };
}

const BootLoaderRouterConnected = withRouter(BootLoader);
const BootLoaderAuthConnected = withAuth(BootLoaderRouterConnected);
const BootLoaderStandaloneSettingsConnected = withStandaloneSettings(BootLoaderAuthConnected);
const BootLoaderVersionInfoConnected = withVersionInfo(BootLoaderStandaloneSettingsConnected);
const BootLoaderWrapper = (): JSX.Element => (
  <IonApp>
    <BrowserRouter>
      <RelayEnvironmentProvider environment={environment}>
        <Suspense fallback={<LoadingPageDefault delayMs={0} />}>
          <UIProviders>
            <ServiceProviders>
              <EmbedRemoteActionHandler />
              {/* Handler for the android hardware back. Normally existed in the EmbedRemoteActionHandler */}
              {/* but since it's weird to have 2 place where we handle this action, moved to its */}
              {/* own component */}
              <HardwareBackHandler />
              <BootLoaderLayout>
                <BootLoaderVersionInfoConnected />
              </BootLoaderLayout>
            </ServiceProviders>
          </UIProviders>
        </Suspense>
      </RelayEnvironmentProvider>
    </BrowserRouter>
  </IonApp>
);

export default BootLoaderWrapper;
