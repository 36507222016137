import _ from "lodash";
import { useState } from "react";

import { SlideStatus } from "common/components/UINav";

type Props = {
  onSlideStackChanged?: (activeSlide: string | null) => void;
};

type SlideChanges = {
  showing: string[];
  hiding: string[];
  // This is a subset of the hiding set.
  hidingSilently: string[];
  activeSlideSwapped: boolean;
};

export type HookReturnType = {
  _slideStack: SlideStatus[];
  activeSlide: string | null;
  slideBehind: string | null;
  lastSlideChanges: SlideChanges;
  showSlide: (slideId: string) => void;
  hideSlide: (slideId: string) => void;
  hideSlides: (slideIds: string[]) => void;
  hideLastSlide: () => void;
  keepOnlyValidSlides: (validSlideIds: string[]) => void;
  clearSlideChanges: () => void;
  isSlideInStack: (slideId: string) => boolean;
  getIndexOfSlideInStack: (slideId: string) => number;
};

function useUINavSlideStack(props: Props): HookReturnType {
  const [activeSlide, setActiveSlide] = useState<string | null>(null);
  const [slideBehind, setSlideBehind] = useState<string | null>(null);
  const [slideStack, setSlideStack] = useState<SlideStatus[]>([]);
  const [lastSlideChanges, setLastSlideChanges] = useState<SlideChanges>({
    showing: [],
    hiding: [],
    hidingSilently: [],
    activeSlideSwapped: false,
  });

  const changeSlideStack = (nextSlideStack: SlideStatus[]): void => {
    const lastSlide = nextSlideStack[nextSlideStack.length - 1];
    const slideBehindLast = nextSlideStack[nextSlideStack.length - 2];
    const nextActiveSlideId = lastSlide ? lastSlide.slideId : null;
    const currentlyActiveSlide = slideStack[slideStack.length - 1];
    const currentlyActiveSlideId = currentlyActiveSlide ? currentlyActiveSlide.slideId : null;

    // Detect which slides are going to change in visibility state.
    // Difference between set A and B tells us which slides are going away.
    // Difference between set B and A tells us which slides are coming in.
    // The intersection of sets A and B are slides that are still showing. In this case,
    // there is no change so we don't care about it.
    const slidesThatExistInCurrent = slideStack.reduce((result, slideStatus) => {
      return { [slideStatus.slideId]: true, ...result };
    }, {} as Record<string, boolean>);
    const slidesThatExistInNext = nextSlideStack.reduce((result, slideStatus) => {
      return { [slideStatus.slideId]: true, ...result };
    }, {} as Record<string, boolean>);
    const differenceA = slideStack.filter((slide) => !slidesThatExistInNext[slide.slideId]);
    const differenceB = nextSlideStack.filter((slide) => !slidesThatExistInCurrent[slide.slideId]);
    const hiding = differenceA.map((slide) => slide.slideId);
    const showing = differenceB.map((slide) => slide.slideId);
    const activeSlideSwapped =
      !hiding.length && !showing.length && activeSlide !== nextActiveSlideId;

    const slideChanges = {
      showing,
      hiding,
      hidingSilently: hiding.filter((slideId) => slideId !== currentlyActiveSlideId),
      activeSlideSwapped,
    };

    setLastSlideChanges(slideChanges);
    setSlideStack(nextSlideStack);
    setActiveSlide(nextActiveSlideId);
    setSlideBehind(slideBehindLast ? slideBehindLast.slideId : null);

    if (props.onSlideStackChanged) {
      props.onSlideStackChanged(nextActiveSlideId);
    }
  };

  const clearSlideChanges = () => {
    setLastSlideChanges({
      showing: [],
      hiding: [],
      hidingSilently: [],
      activeSlideSwapped: false,
    });
  };

  /**
   * Filters current slide state and sets it to a valid state based on a set
   * of valid slide ids.
   */
  const keepOnlyValidSlides = (validSlideIds: string[]): void => {
    const validSlides = slideStack.filter((slideStatus) => {
      return validSlideIds.includes(slideStatus.slideId);
    });

    // Assume if no change in length, no changes were applied
    const hasChanged = slideStack.length !== validSlides.length;

    if (hasChanged) {
      changeSlideStack(validSlides);
    }
  };

  const showSlide = (slideId: string): void => {
    const slideStatusToAdd = { slideId };
    const slideStackWithoutSlideToAdd = slideStack.filter((slideStatus) => {
      return slideStatus.slideId !== slideId;
    });
    const nextSlideStack = _.uniqBy(
      [...slideStackWithoutSlideToAdd, slideStatusToAdd],
      (slideStatus: SlideStatus) => {
        return slideStatus.slideId;
      },
    );

    changeSlideStack(nextSlideStack);
  };

  const hideSlides = (slideIds: string[]): void => {
    const nextSlideStack = slideStack.filter((slideStatus) => {
      return !slideIds.includes(slideStatus.slideId);
    });

    changeSlideStack(nextSlideStack);
  };

  const hideSlide = (slideId: string): void => {
    const slideStatusToMutate = slideStack.find((slideStatus) => {
      return slideStatus.slideId === slideId;
    });

    if (slideStatusToMutate) {
      hideSlides([slideId]);
    }
  };

  const hideLastSlide = (): void => {
    // If we don't have anything in the stack, return.
    if (!slideStack.length) {
      return;
    }

    hideSlide(slideStack[slideStack.length - 1].slideId);
  };

  const isSlideInStack = (slideId: string): boolean => {
    return !!slideStack.find((slideStatus) => {
      return slideStatus.slideId === slideId;
    });
  };

  const getIndexOfSlideInStack = (slideId: string): number => {
    return slideStack.findIndex((slideStatus) => {
      return slideStatus.slideId === slideId;
    });
  };

  return {
    _slideStack: slideStack,
    activeSlide,
    slideBehind,
    lastSlideChanges,
    showSlide,
    hideSlide,
    hideSlides,
    hideLastSlide,
    keepOnlyValidSlides,
    clearSlideChanges,
    isSlideInStack,
    getIndexOfSlideInStack,
  };
}

export default useUINavSlideStack;
