import classnames from "classnames";
import React, { HTMLAttributes } from "react";

import HARDWARE_BACK_EVENT_PRIORITY from "constants/hardwareBackHierarchy";

import IonBackHandler from "common/components/IonBackHandler";
import UIModal, {
  PRECONFIGURED_MODAL_POSITIONS,
  PositionPercentages,
} from "common/components/UIModal";

import styles from "./styles.scss";

export type Props = HTMLAttributes<HTMLIonModalElement> & {
  isOpen: boolean;
  // TODO: Rename as dialogRootClassName
  cssClass?: string;
  // Applied to the UIModal's animating div. The root of the entire modal layer.
  className?: string;
  close: () => void;
  onWillPresent?: () => void;
  onDidPresent?: () => void;
  onDidDismiss?: () => void;
  onWillDismiss?: () => void;
  type?: "floating" | "custom" | "floating-center" | "drawer";
  animated?: boolean;
  backdropDismiss?: boolean;
  // TODO: implement
  swipeToClose?: boolean;
  showBackdrop?: boolean;
  enableHardwareBack?: boolean;
  hardwareBackPriority?: number;
};

type State = {};

const DEFAULT_PRIORITY_HARDWARE_BACK = HARDWARE_BACK_EVENT_PRIORITY.dialogs;

const MODAL_CONTENT_CLASSNAMES: Record<string, string> = {
  floating: styles.floating,
  "floating-center": styles.floatingCenter,
  drawer: styles.drawer,
  // TODO: Implement custom type
  custom: styles.custom,
};

export default class UIDialog extends React.PureComponent<Props, State> {
  getPositionValues = (): PositionPercentages => {
    switch (this.props.type) {
      case "floating":
        return PRECONFIGURED_MODAL_POSITIONS.BOTTOM_TO_OFFSET_10_PERCENT;
      case "floating-center":
        return PRECONFIGURED_MODAL_POSITIONS.BOTTOM_TO_CENTER;
      case "drawer":
        return PRECONFIGURED_MODAL_POSITIONS.BOTTOM_TO_TOP;
      default:
        return PRECONFIGURED_MODAL_POSITIONS.BOTTOM_TO_OFFSET_10_PERCENT;
    }
  };

  render = (): React.ReactNode => {
    const {
      isOpen,
      children,
      className,
      cssClass,
      close,
      onWillPresent,
      onDidPresent,
      onDidDismiss,
      onWillDismiss,
      type = "floating",
      animated = true,
      backdropDismiss = true,
      showBackdrop = true,
      enableHardwareBack = true,
      hardwareBackPriority = DEFAULT_PRIORITY_HARDWARE_BACK,
      ...remainingProps
    } = this.props;

    const isFloatingCenter = type === "floating-center";
    const enableHardwareBackHandling = enableHardwareBack && Boolean(close);
    const positionPercentages = this.getPositionValues();
    const modalContentClassName = MODAL_CONTENT_CLASSNAMES[type];
    // For floating center, we want to shift the container to the middle, and then 50% of the content size.
    // Since we can "stack" transform rules, we can actually do this all on this one element.
    const interpFn = !isFloatingCenter
      ? undefined
      : (value: number) => `translateY(${value}px) translateY(50%)`;

    return (
      <>
        {/* TODO: Consider, evaluating the stack of modals in the view and auto assign priorities */}
        {/*       so that we don't need to define it? */}
        {enableHardwareBackHandling && close && (
          <IonBackHandler
            isActive={this.props.isOpen}
            priority={hardwareBackPriority}
            onHardwareBack={close}
          />
        )}

        <UIModal
          {...remainingProps}
          isAnimated={animated}
          isOpen={isOpen}
          onWillPresent={onWillPresent}
          onDidPresent={onDidPresent}
          onDidDismiss={onDidDismiss}
          onWillDismiss={onWillDismiss}
          backdropDismiss={backdropDismiss}
          showBackdrop={showBackdrop}
          close={close}
          positionPercentages={positionPercentages}
          positionInterpolation={interpFn}
          modalContentClassName={classnames(modalContentClassName, className)}
        >
          <div className={classnames(styles.root, cssClass)}>{children}</div>
        </UIModal>
      </>
    );
  };
}
