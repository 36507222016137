import { SpringRef } from "react-spring";

import { ReactSpringAnimationResult } from "./types";

/**
 * Animates slides to a computed position desired.
 *
 * A hook to perform some work before the animation is also provided if you need to reposition
 * a slide immediately before the animation to the final state. Used when we need to make
 * a slide that is already open appear like its coming in from the right again.
 */
const animateSlidesToPositions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactSpringImperativeApi: SpringRef<any>,
  immediate = false,
  computePosition: (index: number) => number,
  computeOpacity: (index: number) => number,
  events: {
    onBeforeAnimation?: (index: number) => void;
  } = {},
): Array<Promise<ReactSpringAnimationResult>> => {
  const animationPromises = reactSpringImperativeApi.start((index) => {
    // Perform some work before the animation if desired. Pass the index
    // of the slide to the callback.
    if (events.onBeforeAnimation) {
      events.onBeforeAnimation(index);
    }

    const translateX = computePosition(index);
    const opacity = computeOpacity(index);

    return {
      immediate,
      translateX,
      opacity,
    };
  });

  return animationPromises;
};

export default animateSlidesToPositions;
