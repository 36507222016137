/**
 * @generated SignedSource<<3d7c9342791e0e4e1c7a87008d218634>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClubModerationControl_me$data = {
  readonly id: string;
  readonly " $fragmentType": "ClubModerationControl_me";
};
export type ClubModerationControl_me$key = {
  readonly " $data"?: ClubModerationControl_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClubModerationControl_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClubModerationControl_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e632f5aa3e95842febeab63da5ba16bb";

export default node;
