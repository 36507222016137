import React from "react";

import UIAsyncButton from "common/components/UIAsyncButton";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = {
  text: string;
  disabled?: boolean;
  onClick: () => Promise<void>;
};

export default class OnboardElementSubmitButton extends React.Component<Props> {
  render = (): React.ReactNode => {
    return (
      <UIAsyncButton
        color="primary"
        expand="block"
        fill="solid"
        disabled={this.props.disabled}
        className={styles.submitButton}
        onClick={this.props.onClick}
      >
        <UIText weight="bold">{this.props.text}</UIText>
      </UIAsyncButton>
    );
  };
}
