/**
 * @generated SignedSource<<e53e8437b772b5670dd0900ee2a57442>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppGlobalFlows_me$data = {
  readonly id: string;
  readonly username: string;
  readonly " $fragmentType": "AppGlobalFlows_me";
};
export type AppGlobalFlows_me$key = {
  readonly " $data"?: AppGlobalFlows_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppGlobalFlows_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppGlobalFlows_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0a0483e5fe849b46dba0150f293508f4";

export default node;
