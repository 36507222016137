import React, { useEffect } from "react";

import useAppRouterV2CoreNavigation from "providers/AppRouterV2CoreNavigationProvider/useAppRouterV2CoreNavigation";

import {
  CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_NAME,
  CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_VALUE,
  CORE_NAVIGATION_PRIORITY_DATA_ATTRIBUTE_NAME,
  FabExitPriority,
} from "constants/appRouterV2";

type Props = {
  isActive?: boolean;
  className?: string;
  rankWhenActive?: FabExitPriority;
};

const CoreNavigationFloatingTabBarExit = (props: Props): React.ReactElement => {
  const appRouterV2CoreNavProvider = useAppRouterV2CoreNavigation();

  const rankWhenActive = props.rankWhenActive || FabExitPriority.ACTIVE;
  const ranking = props.isActive ? rankWhenActive : FabExitPriority.INACTIVE;

  const attributes = {
    [CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_NAME]:
      CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_VALUE,
    [CORE_NAVIGATION_PRIORITY_DATA_ATTRIBUTE_NAME]: ranking,
  };

  useEffect(() => {
    appRouterV2CoreNavProvider.invalidateCurrentExit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isActive]);

  return <div className={props.className} {...attributes} />;
};

export default CoreNavigationFloatingTabBarExit;
