/* eslint-disable max-len */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";
import { Redirect, Route, Switch, withRouter, RouteComponentProps } from "react-router-dom";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

import { FabExitPriority } from "constants/appRouterV2";
import {
  CLUBS_PAGE_CLASSNAME,
  MAP_AND_ACTIVITY_CENTER_PAGE_CLASSNAME,
  MESSAGES_PAGE_CLASSNAME,
  NOTIFICATIONS_PAGE_CLASSNAME,
  USER_PAGE_CLASSNAME,
} from "constants/pages";
import {
  UNKNOWN_ROUTE,
  USER_INFO_ROUTE,
  MAP_ROUTE,
  CLUBS_ROUTE,
  NOTIFICATIONS_ROUTE,
  INTERNAL_ROUTE,
  MESSAGES_ROUTE,
  MESSAGES_CHANNEL_ROUTE,
  MESSAGES_REQUESTS_ROUTE,
  CLUBS_FEED_ROUTE,
  ACTIVITY_CENTER_ROUTE,
  REALITY_CHANNEL_ID_PATH_PARAM,
} from "constants/routes";

import CoreNavigation from "common/components/AppRouterV2CoreNavigationFabs/components/CoreNavigation";
import CoreNavigationFloatingTabBar from "common/components/AppRouterV2CoreNavigationFabs/components/CoreNavigationFloatingTabBar";
import CoreNavigationFullSurfaceLayout from "common/components/AppRouterV2CoreNavigationFabs/components/CoreNavigationFullSurfaceLayout";
import CampfireTabRoute from "common/components/CampfireTabRoute";

/* Main views */
import ClubsPage from "pages/Clubs";
import FourOhFour from "pages/FourOhFour";
import MapAndActivitiesPage from "pages/MapAndActivities";
import MessagesPage from "pages/Messages";
import NotificationsPage from "pages/Notifications";
import UserPage from "pages/User";

import { AppRouterV2_me$data as AppRouterV2Me } from "__generated__/AppRouterV2_me.graphql";
import { AppRouterV2_query$data as AppRouterV2Query } from "__generated__/AppRouterV2_query.graphql";

const shouldBundleInternal = process.env.CAMPFIRE_APP_BUILD_INTERNAL === "true";

// Change the InternalPage we use based on the Internal flag.
// This happens at build time.
const InternalPage = shouldBundleInternal
  ? // eslint-disable-next-line @typescript-eslint/no-var-requires
    require("../../../pages/Internal").default
  : FourOhFour;

type Props = RouteComponentProps &
  WithTranslation &
  WithFeatureFlagProps & {
    query: AppRouterV2Query;
    me: AppRouterV2Me;
  };

type State = {};

const DEFAULT_TAB = ACTIVITY_CENTER_ROUTE;

/**
 * This is the main router for the authenticated application, Campfire v2.
 */
class AppRouterV2 extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { isSuperAdmin } = this.props.me;

    const isInternalRoute = this.props.history.location.pathname.startsWith(`/${INTERNAL_ROUTE}`);

    return (
      <>
        {/* Here, we define the (currently) 2 parts of the app navigation represented by FAB's */}
        {/* The content will "re-parent" itself based on the underlying view as needed */}
        {/* For example, the map may want to anchor the controls into the map itself */}
        {/* However, other views may want to anchor it so it lives always on top */}
        {/* How this works conceptually is like a drain, where we PULL this content where we need it */}
        {/* If nothing is done, it will be rendered on top of everything */}
        <CoreNavigation>
          {!isInternalRoute ? (
            <CoreNavigationFloatingTabBar.Content query={this.props.query} me={this.props.me} />
          ) : null}
        </CoreNavigation>

        <CoreNavigationFullSurfaceLayout>
          <CoreNavigationFloatingTabBar.Exit
            isActive
            rankWhenActive={FabExitPriority.INACTIVE_ROOT}
          />
        </CoreNavigationFullSurfaceLayout>

        <Switch>
          {/* Redirect from the base route to the default tab. */}
          <Redirect exact from="/" to={`/${DEFAULT_TAB}`} />

          {/* Render Internal Page only for super admins. */}
          {isSuperAdmin && <Route path={`/${INTERNAL_ROUTE}`} component={InternalPage} />}

          {/* Any routes we support should match here. */}
          <Route
            exact
            path={[
              `/${CLUBS_ROUTE}`,
              `/${CLUBS_ROUTE}/:clubId/:channelId`,
              `/${CLUBS_ROUTE}/:clubId/${CLUBS_FEED_ROUTE}`,
              `/:baseRoute(${ACTIVITY_CENTER_ROUTE})`,
              `/:baseRoute(${MAP_ROUTE})`,
              `/:baseRoute(${ACTIVITY_CENTER_ROUTE})/:${REALITY_CHANNEL_ID_PATH_PARAM}`,
              `/:baseRoute(${ACTIVITY_CENTER_ROUTE})/:${REALITY_CHANNEL_ID_PATH_PARAM}/${MAP_ROUTE}`,
              `/${MESSAGES_ROUTE}`,
              `/${MESSAGES_ROUTE}/:messagesSubRoute(${MESSAGES_REQUESTS_ROUTE})`,
              `/${MESSAGES_ROUTE}/:messagesSubRoute(${MESSAGES_CHANNEL_ROUTE})/:channelId`,
              `/${NOTIFICATIONS_ROUTE}`,
              `/${USER_INFO_ROUTE}`,
              `/${UNKNOWN_ROUTE}`,
            ]}
          >
            <CampfireTabRoute
              exact
              path={[
                `/${CLUBS_ROUTE}`,
                `/${CLUBS_ROUTE}/:clubId/${CLUBS_FEED_ROUTE}`,
                `/${CLUBS_ROUTE}/:clubId/:channelId`,
              ]}
              component={ClubsPage}
              pageClassname={CLUBS_PAGE_CLASSNAME}
            />
            <CampfireTabRoute
              exact
              path={[
                `/:baseRoute(${ACTIVITY_CENTER_ROUTE})`,
                `/:baseRoute(${MAP_ROUTE})`,
                `/:baseRoute(${ACTIVITY_CENTER_ROUTE})/:${REALITY_CHANNEL_ID_PATH_PARAM}`,
                `/:baseRoute(${ACTIVITY_CENTER_ROUTE})/:${REALITY_CHANNEL_ID_PATH_PARAM}/${MAP_ROUTE}`,
              ]}
              component={MapAndActivitiesPage}
              pageClassname={MAP_AND_ACTIVITY_CENTER_PAGE_CLASSNAME}
            />
            <CampfireTabRoute
              exact
              path={[
                `/${MESSAGES_ROUTE}`,
                `/${MESSAGES_ROUTE}/:messagesSubRoute(${MESSAGES_REQUESTS_ROUTE})`,
                `/${MESSAGES_ROUTE}/:messagesSubRoute(${MESSAGES_CHANNEL_ROUTE})/:channelId`,
              ]}
              component={MessagesPage}
              pageClassname={MESSAGES_PAGE_CLASSNAME}
            />
            <CampfireTabRoute
              exact
              path={`/${NOTIFICATIONS_ROUTE}`}
              component={NotificationsPage}
              pageClassname={NOTIFICATIONS_PAGE_CLASSNAME}
            />
            <CampfireTabRoute
              exact
              path={`/${USER_INFO_ROUTE}`}
              component={UserPage}
              pageClassname={USER_PAGE_CLASSNAME}
            />
            <CampfireTabRoute exact path={`/${UNKNOWN_ROUTE}`} component={FourOhFour} />
          </Route>

          {/* The following Redirects allow unsupported routes on our core routes to be more gracefully handled */}
          {/* If we are on some route that doesn't match a supported route on /map, move the user back to /map */}
          <Redirect from={`/${MAP_ROUTE}`} to={`/${MAP_ROUTE}`} />
          <Redirect from={`/${ACTIVITY_CENTER_ROUTE}`} to={`/${ACTIVITY_CENTER_ROUTE}`} />
          {/* If we are on some route that doesn't match a supported route on /messages,  move the user back to /messages */}
          <Redirect from={`/${MESSAGES_ROUTE}`} to={`/${MESSAGES_ROUTE}`} />
          {/* If we are on some route that doesn't match a supported route on /clubs,  move the user back to /clubs */}
          <Redirect from={`/${CLUBS_ROUTE}`} to={`/${CLUBS_ROUTE}`} />
          {/* If we are on some route that doesn't match a supported route on /notifications,  move the user back to /notifications */}
          <Redirect from={`/${NOTIFICATIONS_ROUTE}`} to={`/${NOTIFICATIONS_ROUTE}`} />
          {/* If we are on some route that doesn't match a supported route on /user,  move the user back to /user */}
          <Redirect from={`/${USER_INFO_ROUTE}`} to={`/${USER_INFO_ROUTE}`} />

          {/* If no matching routes were found, redirect to 404. */}
          <Redirect to={`/${UNKNOWN_ROUTE}`} />
        </Switch>
      </>
    );
  };
}

const RouterConnected = withRouter(AppRouterV2);
const FragmentContainer = createFragmentContainer(RouterConnected, {
  query: graphql`
    fragment AppRouterV2_query on Query {
      ...CoreNavigationFloatingTabBar_query
    }
  `,
  me: graphql`
    fragment AppRouterV2_me on User {
      id
      isSuperAdmin
      ...CoreNavigationFloatingTabBar_me
    }
  `,
});

const FeatureFlagConnected = withFeatureFlag(FragmentContainer);
const TranslatedComponent = withTranslation()(FeatureFlagConnected);

export default TranslatedComponent;
