import { RouteComponentProps } from "react-router-dom";

import { MESSAGES_ROUTE } from "constants/routes";

export const getUrlForMessagesTab = (): string => {
  return `/${MESSAGES_ROUTE}`;
};

export default (routeComponentProps: RouteComponentProps, replaceRoute?: boolean): void => {
  if (replaceRoute) {
    routeComponentProps.history.replace({
      pathname: getUrlForMessagesTab(),
    });
  } else {
    routeComponentProps.history.push({
      pathname: getUrlForMessagesTab(),
    });
  }
};
