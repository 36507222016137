import { AppsFlyer, AFInit, AFConstants, OnDeepLink } from "appsflyer-capacitor-plugin";
import { invert } from "lodash";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { OnboardingType } from "boot-loader";
import logException from "common/analytics/exceptions";
import { isStandalone, isAndroid } from "common/capacitor/helpers";
import entryPointHistory, { EntryPoint } from "common/utils/telemetry/entryPointHistory";
import APPSFLYER_QUERY_PARAMS from "constants/appsFlyerQueryParams";
import config from "constants/config";
import isDev from "constants/isDev";

import buildRedirectUrlFromParsedDeepLinkArgs from "boot-loader/components/DeepLinkHandler/buildRedirectUrlFromParsedDeepLinkArgs";

// Cast as string since it should never be undefined. We initialize it with a default value in config.
const APPSFLYER_DEV_KEY_ANDROID = config.get("CAMPFIRE_APP_APPSFLYER_DEV_KEY_ANDROID") as string;
const APPSFLYER_APP_ID_ANDROID = config.get("CAMPFIRE_APP_APPSFLYER_APP_ID_ANDROID") as string;
const APPSFLYER_DEV_KEY_IOS = config.get("CAMPFIRE_APP_APPSFLYER_DEV_KEY_IOS") as string;
const APPSFLYER_APP_ID_IOS = config.get("CAMPFIRE_APP_APPSFLYER_APP_ID_IOS") as string;

const getDevKey = (): string => {
  if (isAndroid) return APPSFLYER_DEV_KEY_ANDROID;
  return APPSFLYER_DEV_KEY_IOS;
};

const getAppId = (): string => {
  if (isAndroid) return APPSFLYER_APP_ID_ANDROID;
  return APPSFLYER_APP_ID_IOS;
};

export type ParsedDeepLink = typeof APPSFLYER_QUERY_PARAMS;

type Props = {
  setOnboardingType: (type: OnboardingType) => void;
  deepLinkListener: (deepLink: ParsedDeepLink) => void;
};

const DeepLinkHandler: FunctionComponent<Props> = ({
  setOnboardingType,
  deepLinkListener,
}): null => {
  const history = useHistory();
  // Appsflyer init options:
  // https://github.com/AppsFlyerSDK/appsflyer-capacitor-plugin/blob/main/docs/BasicIntegration.md

  // eslint-disable-next-line camelcase
  const parseString = useCallback((deepLink: { deep_link_sub1: string }) => {
    const searchParams = new URLSearchParams(atob(deepLink.deep_link_sub1));

    const map = invert(APPSFLYER_QUERY_PARAMS);

    return Array.from(searchParams.entries()).reduce(
      (acc, [k, v]) => ({ ...acc, [map[k] || k]: v.trim() }),
      {} as ParsedDeepLink,
    );
  }, []);

  const buildRedirectUrl = useCallback(buildRedirectUrlFromParsedDeepLinkArgs, []);

  const handleDeepLink = useCallback(
    (res: OnDeepLink): void => {
      // Found deep link get url and redirect
      if (res.status === "FOUND") {
        const parsedDeepLink = parseString(res.deepLink);
        const redirectUrl = buildRedirectUrl(parsedDeepLink);

        deepLinkListener(parsedDeepLink);

        if (redirectUrl) {
          setOnboardingType("fast");
          entryPointHistory.addEntryPoint(EntryPoint.DEEPLINK);
          history.push(redirectUrl);
        }
      } else if (res.status === "ERROR") {
        logException("AppsFlyerAddListener", "handleLink", "DeepLinkHandler", res.error);
      }
    },
    [buildRedirectUrl, parseString, history, setOnboardingType, deepLinkListener],
  );

  useEffect(() => {
    // We only support AppsFlyer/deep-linking on standalone
    if (isStandalone) {
      const appID = getAppId();
      const devKey = getDevKey();

      const afConfig: AFInit = {
        appID,
        devKey,
        isDebug: isDev,
        waitForATTUserAuthorization: 10, // for iOS 14 and higher
        minTimeBetweenSessions: 6, // default 5 sec
        registerOnDeepLink: true,
        registerConversionListener: true,
        registerOnAppOpenAttribution: false,
        deepLinkTimeout: 4000, // default 3000 ms
        useReceiptValidationSandbox: true, // iOS only
        useUninstallSandbox: true, // iOS only
      };

      AppsFlyer.addListener(AFConstants.UDL_CALLBACK, handleDeepLink);

      AppsFlyer.initSDK(afConfig);
    }
  }, [handleDeepLink]);

  return null;
};

export default DeepLinkHandler;
