import classnames from "classnames";
import environment from "common/relay/relay-env";
import { chatbubbleEllipses, personAdd, personRemove } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import logException from "common/analytics/exceptions";
import OnboardingStore from "common/stores/OnboardingStore";
import routeToDMChannel from "common/utils/routing/routeToDMChannel";
import entryPointHistory, { EntryPoint } from "common/utils/telemetry/entryPointHistory";
import {
  MAX_DM_USERS_ERR_CODE,
  MESSAGE_REQUEST_DISABLED_ERR_CODE,
  PENDING_FRIEND_INVITE_EXISTS_ERR_CODE,
  YOU_HAVE_MAX_FRIENDS_ERR_CODE,
  THEY_HAVE_MAX_FRIENDS_ERR_CODE,
} from "constants/backendErrors";
import ReportContext from "constants/reportContext";
import TEST_IDS from "constants/testIds";
import CancelFriendInviteMutation from "mutations/CancelFriendInviteMutation";
import CreateDMChannelMutation from "mutations/CreateDMChannelMutation";
import CreateFriendInviteMutation from "mutations/CreateFriendInviteMutation";
import CreateMessageRequestMutation from "mutations/CreateMessageRequestMutation";

import AddFriendDialog from "common/components/AddFriendDialog";
import UIAsyncButton from "common/components/UIAsyncButton";
import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";
import UserAppList, { UserAppItem } from "common/components/UserAppList";
import UserProfileAvatarAndName from "common/components/UserProfileAvatarAndName";
import UserProfileEllipsisButton from "common/components/UserProfileEllipsisButton";

import styles from "./styles.scss";

type Props = WithTranslation &
  RouteComponentProps &
  WithToastProps & {
    myUserId: string;
    avatarUrl: string;
    displayName: string;
    username: string;
    userId: string;
    isBlocked: boolean;
    userGameProfiles?: UserAppItem[];
    isMyFriend: boolean;
    hasPendingFriendInviteFromMe: boolean;
    isSelf: boolean;
    reportContext: ReportContext;
    renderActions?: () => React.ReactNode;
    close: () => void;
    children?: React.ReactNode;
    options?: UserProfileOptions;
    disableDms?: boolean;
  };

type State = {
  isAddFriendOpen: boolean;
  showAddFriendConfirmation: boolean;
};

export type UserProfileOptions = {
  onClickMessage?: () => void;
  renderAvatarAdornment?: () => React.ReactNode;
};

class UserProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const hasShowAgain = OnboardingStore.get("showAddFriendConfirmation");

    this.state = {
      isAddFriendOpen: false,
      showAddFriendConfirmation: hasShowAgain,
    };
  }

  cancelFriendInvite = async (): Promise<void> => {
    try {
      const payload = {
        recipientId: this.props.userId,
      };

      await CancelFriendInviteMutation.commit(environment, payload);
    } catch (error) {
      logException("CancelFriendInviteMutation.commit", "cancelFriendInvite", "UserProfile", error);
    }
  };

  addFriend = async (): Promise<void> => {
    try {
      const payload = {
        userId: this.props.userId,
      };

      await CreateFriendInviteMutation.commit(environment, payload);
    } catch (error) {
      const cfError = error as CampfireErrors.Error;

      if (
        cfError.extensions?.code === YOU_HAVE_MAX_FRIENDS_ERR_CODE ||
        cfError.extensions?.code === THEY_HAVE_MAX_FRIENDS_ERR_CODE
      ) {
        this.props.toastProvider.showErrorToast(cfError.message);
      } else {
        this.props.toastProvider.showErrorToast(this.props.t("ADD_FRIEND_GENERIC_ERR"));
      }

      // Don't log an exception if user is trying to request a pending friend since invite already exists is a known error
      if (cfError.extensions?.code !== PENDING_FRIEND_INVITE_EXISTS_ERR_CODE) {
        logException(
          "CreateFriendInviteMutation.commit",
          "createFriendInvite",
          "UserProfile",
          error,
        );
      }
    }
  };

  confirmAddFriend = async (showAgain: boolean): Promise<void> => {
    if (!showAgain) {
      OnboardingStore.set("showAddFriendConfirmation", false);
    }

    // Wait until the addFriend method resolves before closing the dialog
    await this.addFriend();

    // Close dialog
    this.setState({ isAddFriendOpen: false });
    // Set component state to match store
    this.setState({ showAddFriendConfirmation: showAgain });
  };

  renderUserStatus = (): React.ReactNode => {
    if (this.props.isBlocked) {
      return (
        <div className={classnames(styles.blocked, styles.status)}>
          <UIText color="white" variant="h5" weight="bold">
            {this.props.t("BLOCKED")}
          </UIText>
        </div>
      );
    }

    if (this.props.isMyFriend) {
      return (
        <div className={classnames(styles.isFriend, styles.status)}>
          <UIText color="white" variant="h5" weight="bold">
            {this.props.t("FRIEND").toLocaleUpperCase()}
          </UIText>
        </div>
      );
    }

    if (this.props.hasPendingFriendInviteFromMe) {
      return (
        <div className={classnames(styles.pending, styles.status)}>
          <UIText color="white" variant="h5" weight="bold">
            {this.props.t("PENDING").toLocaleUpperCase()}
          </UIText>
        </div>
      );
    }

    return null;
  };

  renderCancelInviteButton = (): React.ReactNode => {
    return (
      <div className={styles.userActionItem}>
        <UIAsyncButton
          btnContentClassName={styles.buttonContent}
          color="medium"
          className={styles.userActionButton}
          onClick={this.cancelFriendInvite}
          dataTestId={TEST_IDS.UserProfileCancelBtnId}
        >
          <UIIcon size={20} icon={personRemove} />
        </UIAsyncButton>
        <UISpacer h={2} />
        <UIText variant="h5" color="medium" weight="medium">
          {this.props.t("CANCEL")}
        </UIText>
      </div>
    );
  };

  renderAddUserButton = (): React.ReactNode => {
    return (
      <div className={styles.userActionItem}>
        <UIAsyncButton
          btnContentClassName={styles.buttonContent}
          className={classnames(styles.userActionButton, styles.addFriendButton)}
          color="primary"
          onClick={
            this.state.showAddFriendConfirmation
              ? () => this.setState({ isAddFriendOpen: true })
              : this.addFriend
          }
          dataTestId={TEST_IDS.UserProfileAddBtnId}
        >
          <UIIcon size={20} icon={personAdd} />
        </UIAsyncButton>
        <UISpacer h={2} />
        <UIText variant="h5" color="medium" weight="medium">
          {this.props.t("ADD")}
        </UIText>
      </div>
    );
  };

  openMessage = async (): Promise<void> => {
    if (this.props.isMyFriend) {
      return this.openDirectMessage();
    }

    return this.openMessageRequest();
  };

  openDirectMessage = async (): Promise<void> => {
    try {
      const payload = {
        currentUserId: this.props.myUserId,
        userIds: [this.props.userId],
      };

      const response = await CreateDMChannelMutation.commit(environment, payload);

      this.props.close();

      const onClickMessage = this.props.options?.onClickMessage;

      if (onClickMessage) {
        onClickMessage();
      }

      entryPointHistory.addEntryPoint(EntryPoint.USER_PROFILE);
      routeToDMChannel(this.props, response.channel.id);
    } catch (error) {
      if ((error as CampfireErrors.Error).extensions?.code === MAX_DM_USERS_ERR_CODE) {
        this.props.toastProvider.showErrorToast(this.props.t("MAX_DM_USERS_ERR"));
      }

      logException("CreateDMChannelMutation", "openDirectMessage", "UserProfile", error);
    }
  };

  openMessageRequest = async (): Promise<void> => {
    try {
      const payload = {
        recipientId: this.props.userId,
      };

      const response = await CreateMessageRequestMutation.commit(environment, payload);

      this.props.close();

      const onClickMessage = this.props.options?.onClickMessage;

      if (onClickMessage) {
        onClickMessage();
      }

      entryPointHistory.addEntryPoint(EntryPoint.USER_PROFILE);
      routeToDMChannel(this.props, response.messageRequest.channel.id);
    } catch (error) {
      if ((error as CampfireErrors.Error).extensions?.code === MESSAGE_REQUEST_DISABLED_ERR_CODE) {
        this.props.toastProvider.showErrorToast(
          this.props.t("NOT_ACCEPTING_MESSAGE_REQUESTS", {
            displayName: this.props.displayName,
          }),
        );
      } else {
        logException("CreateMessageRequestMutation", "openMessageRequest", "UserProfile", error);
      }
    }
  };

  renderMessageButton = (): React.ReactNode => {
    return (
      <div className={styles.userActionItem}>
        <UIAsyncButton
          btnContentClassName={styles.buttonContent}
          className={classnames(styles.userActionButton, styles.messageButton)}
          color="success"
          onClick={this.openMessage}
          dataTestId={TEST_IDS.UserProfileMessageBtnId}
        >
          <UIIcon size={20} icon={chatbubbleEllipses} />
        </UIAsyncButton>
        <UISpacer h={2} />
        <UIText variant="h5" color="medium" weight="medium">
          {this.props.t("MESSAGE")}
        </UIText>
      </div>
    );
  };

  renderUserActions = (): React.ReactNode => {
    if (this.props.isBlocked) {
      return null;
    }

    const showCancelButton = this.props.hasPendingFriendInviteFromMe;
    const showAddButton = !this.props.hasPendingFriendInviteFromMe && !this.props.isMyFriend;
    const showDMButton = !this.props.disableDms;

    return (
      <div className={styles.userActionParent}>
        {showCancelButton && this.renderCancelInviteButton()}
        {showAddButton && this.renderAddUserButton()}
        {showDMButton && this.renderMessageButton()}
      </div>
    );
  };

  renderSelfProfile = (): React.ReactNode => {
    const showAppList = this.props.userGameProfiles && Boolean(this.props.userGameProfiles.length);
    const appList = this.props.userGameProfiles || [];

    return (
      <div className={styles.root}>
        <div className={styles.profile}>
          <UserProfileAvatarAndName
            avatarUrl={this.props.avatarUrl}
            displayName={this.props.displayName}
            username={this.props.username}
            renderAvatarAdornment={this.props.options?.renderAvatarAdornment}
          />
          {showAppList && !this.props.isBlocked && (
            <>
              <UISpacer h={12} />
              <UserAppList userAppItems={appList} />
            </>
          )}
        </div>
      </div>
    );
  };

  render = (): React.ReactNode => {
    const showAppList = this.props.userGameProfiles && Boolean(this.props.userGameProfiles.length);
    const appList = this.props.userGameProfiles || [];

    if (this.props.isSelf) {
      return this.renderSelfProfile();
    }

    return (
      <div className={styles.root}>
        <AddFriendDialog
          handleConfirmAddFriend={this.confirmAddFriend}
          showAddFriendConfirmation={this.state.showAddFriendConfirmation}
          username={this.props.username}
          isOpen={this.state.isAddFriendOpen}
          onClose={() => this.setState({ isAddFriendOpen: false })}
        />
        <div className={styles.overflowMenuWrapper}>
          <div className={styles.overflowMenuCtn}>
            <UserProfileEllipsisButton
              avatarUrl={this.props.avatarUrl}
              displayName={this.props.displayName}
              username={this.props.username}
              userId={this.props.userId}
              isBlocked={this.props.isBlocked}
              isMyFriend={this.props.isMyFriend}
              reportContext={this.props.reportContext}
            />
          </div>
        </div>

        <div className={styles.profile}>
          <UserProfileAvatarAndName
            avatarUrl={this.props.avatarUrl}
            displayName={this.props.displayName}
            username={this.props.username}
          />
          {this.renderUserStatus()}
          {this.renderUserActions()}
          {showAppList && !this.props.isBlocked && (
            <>
              <UISpacer h={16} />
              <UserAppList userAppItems={appList} />
            </>
          )}
          <div className={styles.actions}>
            <UISpacer h={20} />
            {this.props.renderActions && this.props.renderActions()}
            <UISpacer h={16} />
            {this.props.children}
          </div>
        </div>
      </div>
    );
  };
}

const ToastConnected = withToast(UserProfile);
const RouterConnected = withRouter(ToastConnected);

export default withTranslation()(RouterConnected);
