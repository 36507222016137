// The context in which a user creates a report.
// Since our reporting flow can be accessed in a variety of different app states,
// we want to inform moderation which state the user initiated the report flow from.
enum ReportContext {
  UNKNOWN = "",
  CLUB_CHAT = "club-chat",
  CLUB_MEMBERS = "club-members",
  DM_CHAT = "dm-chat",
  DM_SETTINGS = "dm-settings",
  FIND_FRIENDS = "find-friends",
  GROUP_DM_SETTINGS = "group-dm-settings",
  MANAGE_FRIENDS = "manage-friends",
  POI_CHAT = "poi-chat",
  RAID_LOBBY_CHAT = "raid-lobby-chat",
  EVENT_ATTENDEE_LIST = "event_attendee_list",
}

export default ReportContext;
