import classNames from "classnames";
import React, { ImgHTMLAttributes, useEffect, useState } from "react";

import ImageFallback from "assets/images/defaults/post_image_fallback.png";

export type Props = ImgHTMLAttributes<HTMLImageElement> & {
  fallbackSrc?: string;
  errorClassName?: string;
};

const Image = (props: Props): React.ReactElement => {
  const [hasLoadingError, setHasLoadingError] = useState(false);

  const { src, fallbackSrc, className, errorClassName = "", ...restProps } = props;

  // if src changes, reset the loading error state
  useEffect(() => {
    setHasLoadingError(false);
  }, [src]);

  const fallback = fallbackSrc || ImageFallback;
  const imageSrc = hasLoadingError ? fallback : src;

  return (
    <img
      src={imageSrc}
      onError={() => setHasLoadingError(true)}
      alt=""
      draggable="false"
      className={classNames(className, {
        [errorClassName]: hasLoadingError,
      })}
      {...restProps}
    />
  );
};

export default Image;
