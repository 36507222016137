import { useRef, useState } from "react";

import { ChannelSlideConfig } from "pages/Clubs/providers/ClubsPageViewProvider/slides";

export type DynamicSlideType = ChannelSlideConfig;
export type DynamicSlide = ChannelSlideConfig;

export type HookReturnType = {
  dynamicSlides: ChannelSlideConfig[];
  addDynamicSlide: (slideConfig: ChannelSlideConfig) => void;
  removeDynamicSlide: (slideId: string) => void;
};

/**
 * Holds the state of the dynamic slides that may be added to the Activity Center's UINav.
 */
function useDynamicSlideState(): HookReturnType {
  const [dynamicSlides, setDynamicSlides] = useState<ChannelSlideConfig[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timerRef = useRef<any>(null);
  const slidesToRemoveRef = useRef<Set<string>>(new Set());

  const addDynamicSlide = (slideConfig: ChannelSlideConfig): void => {
    const existingSlide = dynamicSlides.find((slide) => slide.slideId === slideConfig.slideId);

    // Only add slide if needed
    if (!existingSlide) {
      const nextSlides = [...dynamicSlides, slideConfig];

      setDynamicSlides(nextSlides);
    }
  };

  const removeScheduledToDeleteSlides = (): void => {
    const slidesNotRemoved = dynamicSlides.filter((s) => !slidesToRemoveRef.current.has(s.slideId));

    setDynamicSlides(slidesNotRemoved);
    slidesToRemoveRef.current.clear();
  };

  /**
   * Schedules a slide to be removed in the future. Since multiple slides can close simultaneously,
   * and state updates get batched, we want to commit the changes to state all at once.
   */
  const scheduleSlideToBeRemoved = (slideId: string) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    slidesToRemoveRef.current.add(slideId);
    timerRef.current = setTimeout(removeScheduledToDeleteSlides, 10);
  };

  return {
    dynamicSlides,
    addDynamicSlide,
    removeDynamicSlide: scheduleSlideToBeRemoved,
  };
}

export default useDynamicSlideState;
