import { BigNumber } from "bignumber.js";
import { FetchMessagesParameters } from "pubnub";

// Useful docs for reference:
// https://support.pubnub.com/support/solutions/articles/14000043663-how-do-i-subscribe-to-a-wildcard-channel-
// https://www.pubnub.com/docs/web-javascript/stream-controller#wildcard-subscribe

const MAX_CHANNEL_NAME_LENGTH = 92;
const CHAT_CHANNEL_NAME_PREFIX = "chat.";

// https://support.pubnub.com/support/solutions/articles/14000043769-what-are-valid-channel-names-
export const getValidPubNubChatChannelName = (desiredName: string): string => {
  const withPrefix = `${CHAT_CHANNEL_NAME_PREFIX}${desiredName}`;

  return withPrefix
    .replace(/[,:*/\\]/g, "_")
    .trim()
    .slice(0, MAX_CHANNEL_NAME_LENGTH);
};

export const getTimestampAsTimetoken = (timestamp: number = Date.now()): string => {
  // Multiply by 10,000 since we need to convert a millisecond timestamp to a pubnub timetoken
  // which is 10^-7 seconds. Convert to seconds (multiply by 10^-3), then multiply by 10^7 to get
  // pubnub timetoken. That equates to multiplying by 10^4, which is 10,000.
  return (timestamp * 10_000).toString();
};

export const getTimetokenAsTimestamp = (timetoken: string): number => {
  // Do the opposite here and divide by 10000 to get the timetoken as a millisecond timestamp.
  const timeTokenAsBigNumber = new BigNumber(timetoken);
  const timeTokenInMS = timeTokenAsBigNumber.div(10_000);
  const timeTokenAsBase10String = timeTokenInMS.toFixed(0);

  return Number(timeTokenAsBase10String);
};

export const getPubnubFetchConfigToLoadOlderMessagesFromTarget = (
  targetTimetoken: string,
): Partial<FetchMessagesParameters> => {
  return {
    start: targetTimetoken,
  };
};

export const getPubnubFetchConfigToLoadNewerMessagesFromTarget = (
  targetTimetoken: string,
): Partial<FetchMessagesParameters> => {
  return {
    start: targetTimetoken,
    end: getTimestampAsTimetoken(),
  };
};
