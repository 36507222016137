import React, { FunctionComponent } from "react";

import useSlideGesture from "common/components/UINav/useSlideGesture";

import styles from "./styles.scss";

export type Props = {
  translateTo: (positionPx: number) => void;
  onSwipeBackStart: () => void;
  onSwipeBackEnd: (shouldHide: boolean) => void;
  children: React.ReactNode;
};

const UINavGestureEnabledSlideContent: FunctionComponent<Props> = (
  props: Props,
): React.ReactElement => {
  const [slideRef] = useSlideGesture({
    translateTo: props.translateTo,
    onSwipeBackStart: props.onSwipeBackStart,
    onSwipeBackEnd: props.onSwipeBackEnd,
  });

  return (
    <div className={styles.root} ref={slideRef}>
      {props.children}
    </div>
  );
};

export default UINavGestureEnabledSlideContent;
