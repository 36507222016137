import logException from "common/analytics/exceptions";
import { UploadFileResponse, UploadFileOptions } from "types/embedPluginInterface/embedUpload";

export const validator = (uploadFileResponse: UploadFileResponse): boolean => {
  try {
    const hasFileType = !!uploadFileResponse.fileType;
    const hasFileName = !!uploadFileResponse.fileName;
    const hasPhotoId = !!uploadFileResponse.photoId;
    const hasSize = !!uploadFileResponse.size;
    const hasCampfireUrl = !!uploadFileResponse.campfireFileUrl;

    return hasFileType && hasFileName && hasPhotoId && hasSize && hasCampfireUrl;
  } catch (error) {
    logException("validating uploadFile data from embed", "validator", "uploadFile", error);
    return false;
  }
};

// eslint-disable-next-line max-len
const errorMessage = "Custom cap plugin uploadFile not implemented!";
const defaultUploadFile = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options: UploadFileOptions,
): Promise<UploadFileResponse> => {
  return Promise.reject(errorMessage);
};

export default defaultUploadFile;
