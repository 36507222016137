/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { BlockedUsersConsumer, ExposedProps } from "providers/BlockedUsersProvider";

export type WithBlockedUsersProps = {
  blockedUsersProvider: {
    isUserBlocked: ExposedProps["isUserBlocked"];
    blockUser: ExposedProps["blockUser"];
    unblockUser: ExposedProps["unblockUser"];
    blockedUsers: ExposedProps["blockedUsers"];
    hasUserBlockedMe: ExposedProps["hasUserBlockedMe"];
  };
};

export default <P extends WithBlockedUsersProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithBlockedUsersProps>): React.ReactElement => (
    <BlockedUsersConsumer>
      {({ isUserBlocked, blockUser, unblockUser, blockedUsers, hasUserBlockedMe }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          blockedUsersProvider={{
            isUserBlocked,
            hasUserBlockedMe,
            blockUser,
            unblockUser,
            blockedUsers,
          }}
          {...props}
        />
      )}
    </BlockedUsersConsumer>
  );
};
