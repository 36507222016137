import { App } from "@capacitor/app";

import logException from "common/analytics/exceptions";
import { applyUpdateAndCleanupState } from "common/utils/appUpdate/applyBundle";
import {
  getLastVersionsSeen,
  rollbackToBuiltInVersion,
  updateLastAppVersionSeen,
  updateLastJSVersionSeen,
} from "common/utils/appUpdate/rollbackUpdates";
import {
  getNextBundleIdToApplyOnColdStart,
  hasAppVersionChanged,
  shouldPerformAutoUpdateOnColdStart,
  shouldPerformRollback,
} from "common/utils/appUpdate/shouldApplyBundle";
import { CAMPFIRE_JS_VERSION } from "common/utils/campfireApp";

// TODO: REMOVE ME when feature flag is no longer required.
// eslint-disable-next-line no-underscore-dangle
export const _bootAutoUpdaterStatus: { isSuccessfullyInitialized: boolean } = {
  // Stores when the auto updater has initialized to avoid blocking the UI for the feature flag.
  isSuccessfullyInitialized: false,
};

/**
 * Initializes the boot auto updater and applies downloaded updates if needed.
 *
 * NOTE: This is seperate from the runtime auto updater component <AutoUpdater />
 *       This function is only for use during boot.
 * Notes on CapacitorUpdater:
 * - DOES NOT WORK: Capacitor updater should automatically apply a downloaded update if it was configured.
 *   https://github.com/Cap-go/capacitor-updater#next
 *   However, it turns out it applies this update on next foreground, and not on next cold boot.
 *   Ideally, on a cold boot, we should have no problem applying an update.
 *
 * - Another thing, is capacitor updater will point to old bundles on new updates of the app.
 *   Meaning, if we update to a new JS version, we need to invalidate those downloaded versions
 *   otherwise the new app version will be running with some downloaded version, instead of the version
 *   that came with the app.
 *   This does NOT apply to uninstalls and reinstalls, as that clears storage from the device.
 *
 * Returns a boolean, True if we need to reboot the webview, False otherwise.
 */
// eslint-disable-next-line import/prefer-default-export
export const initializeBootAutoUpdater = async (): Promise<boolean> => {
  let currentAppVersion = "";
  const currentJSVersion = CAMPFIRE_JS_VERSION || "";
  let needsToReboot = false;

  try {
    const appInfoResponse = await App.getInfo();

    const { lastAppVersion, lastJSVersion } = getLastVersionsSeen();

    currentAppVersion = appInfoResponse.version;

    const appVersionHasChanged = hasAppVersionChanged(
      currentAppVersion,
      // Note: This is the JS version, we just have historically had it named as CAMPFIRE_JS_VERSION.
      currentJSVersion,
      lastAppVersion,
      lastJSVersion,
    );
    // Get the next bundle to apply, we will apply it if we need to.
    const nextBundleIdToApply = getNextBundleIdToApplyOnColdStart();

    // If we detect the app version has changed
    if (shouldPerformRollback(appVersionHasChanged)) {
      // The user updated their app in this case. This means the bundles we have downloaded
      // may not be suitable for this app version. Let's just delete them all, and then
      // set the app version as the built-in version.
      await rollbackToBuiltInVersion(currentAppVersion, currentJSVersion);

      // NOTE: I don't believe we will ever get this far if a rollback is necessary.
      needsToReboot = true;

      // TODO: In the future, we can consider how we can persist the previous version, but I think it
      //       requires knowledge of the config to do so. Keep in mind, changes are not always
      //       additive, in that new functionality can be deprecated for example. So features
      //       in a previous version, may cease to exist in later versions. Though typically, features
      //       work in an additive way.
    } else if (shouldPerformAutoUpdateOnColdStart(nextBundleIdToApply)) {
      if (nextBundleIdToApply) {
        await applyUpdateAndCleanupState(nextBundleIdToApply);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    logException("initializeAutoUpdater", "initializeAutoUpdater", "appUpdate/index.ts", error);

    // If anything goes wrong in this process, set the built-in packaged version as the one to use.
    await rollbackToBuiltInVersion(currentAppVersion, currentJSVersion);

    needsToReboot = true;
  }

  // Mark some stuff for the next boot and the next app update.
  updateLastAppVersionSeen(currentAppVersion);
  updateLastJSVersionSeen(CAMPFIRE_JS_VERSION || "");

  // We really don't care too much if an error above happened, we just need to know if we completed
  // all tasks.
  _bootAutoUpdaterStatus.isSuccessfullyInitialized = true;

  return needsToReboot;
};
