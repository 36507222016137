import { RouteComponentProps } from "react-router-dom";

import { getQueryString } from "common/utils/url";
import { CLUBS_ROUTE, QUERY_PARAMS } from "constants/routes";

export const getUrlForClubWithInvite = (clubId: string, hasInvite: boolean): string => {
  const hasInviteKey = QUERY_PARAMS.clubs.hasInvite.key;
  const hasInviteValue = hasInvite ? "true" : "false";
  const clubIdKey = QUERY_PARAMS.clubs.clubId.key;

  const queryParams: Record<string, string> = {
    [hasInviteKey]: hasInviteValue,
    [clubIdKey]: clubId,
  };

  const queryString: string = Object.keys(queryParams)
    .filter((key) => !!queryParams[key])
    .map((key) => `${key}=${queryParams[key] || ""}`)
    .join("&");

  return `/${CLUBS_ROUTE}?${queryString}`;
};

// A convenience method so that we don't have to keep doing so much work to route
// to a club. An empty clubId routes to club home.
export default (
  routeComponentProps: Pick<RouteComponentProps, "history" | "location">,
  clubId: string,
  preserveSearch?: boolean,
): void => {
  if (clubId) {
    const clubIdQueryString = QUERY_PARAMS.clubs.clubId.key;
    const queryParamUpdates = { [clubIdQueryString]: clubId };
    const searchStr = preserveSearch ? routeComponentProps.location.search : "";

    routeComponentProps.history.push({
      pathname: `/${CLUBS_ROUTE}`,
      search: getQueryString(queryParamUpdates, searchStr),
    });
  } else {
    routeComponentProps.history.push({
      pathname: `/${CLUBS_ROUTE}`,
    });
  }
};
