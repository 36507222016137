/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import {
  AppRouterV2CoreNavigationConsumer,
  ExposedProps,
} from "providers/AppRouterV2CoreNavigationProvider";

export type WithAppRouterV2CoreNavigationProps = {
  appRouterV2CoreNavigationProvider: ExposedProps;
};

// eslint-disable-next-line max-len
export default <P extends WithAppRouterV2CoreNavigationProps>(
  Component: React.ComponentType<P>,
) => {
  return (props: Omit<P, keyof WithAppRouterV2CoreNavigationProps>): React.ReactElement => (
    <AppRouterV2CoreNavigationConsumer>
      {(exposedProps: ExposedProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component appRouterV2CoreNavigationProvider={exposedProps} {...props} />
      )}
    </AppRouterV2CoreNavigationConsumer>
  );
};
