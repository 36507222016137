import React from "react";

import NotificationSetting from "common/components/NotificationGroup/components/NotificationSetting";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = {
  children: React.ReactNode;
  name: string;
};

type State = {};

export default class NotificationGroup extends React.Component<Props, State> {
  getToggleGroups = (): Array<React.Component<NotificationSetting>> => {
    return React.Children.toArray(this.props.children).filter((child) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return child.type === NotificationSetting;
    }) as Array<React.Component<NotificationSetting>>;
  };

  render = (): React.ReactNode => {
    const toggleGroups = this.getToggleGroups();

    return (
      <div className={styles.root}>
        <div className={styles.notificationGroupHeader}>
          <UIText className={styles.headerText} color="medium" variant="h4" weight="bold">
            {this.props.name}
          </UIText>
        </div>

        {toggleGroups}
      </div>
    );
  };
}
