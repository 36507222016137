/**
 * @generated SignedSource<<e040e9d326588df2c04be3e4864ad087>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type checkSession_Query$variables = {};
export type checkSession_Query$data = {
  readonly me: {
    readonly ageGroup: string;
    readonly displayName: string;
    readonly email: string;
    readonly featureFlags: ReadonlyArray<string>;
    readonly hasAcknowledgedReset: boolean;
    readonly hasSetNianticId: boolean;
    readonly id: string;
    readonly isSuperAdmin: boolean;
    readonly shouldShowNux: boolean;
    readonly username: string;
  };
};
export type checkSession_Query = {
  response: checkSession_Query$data;
  variables: checkSession_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasSetNianticId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shouldShowNux",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSuperAdmin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ageGroup",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "featureFlags",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAcknowledgedReset",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "checkSession_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "checkSession_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6824c19ee169877f1380a4300636a58f",
    "id": null,
    "metadata": {},
    "name": "checkSession_Query",
    "operationKind": "query",
    "text": "query checkSession_Query {\n  me {\n    id\n    email\n    username\n    displayName\n    hasSetNianticId\n    shouldShowNux\n    isSuperAdmin\n    ageGroup\n    featureFlags\n    hasAcknowledgedReset\n  }\n}\n"
  }
};
})();

(node as any).hash = "b85eebf2b3393135d9e1706bf7e2bd80";

export default node;
