import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR } from "constants/errors";
import { MutationError } from "types/modules/relay/mutations";

import { CreateMessageRequestMutation$data } from "__generated__/CreateMessageRequestMutation.graphql";

type ReturnType = CreateMessageRequestMutation$data["createMessageRequest"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  recipientId: string;
};

const mutation = graphql`
  mutation CreateMessageRequestMutation($input: CreateMessageRequestInput!) {
    createMessageRequest(input: $input) {
      messageRequest {
        id
        channel {
          id
        }
        status
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "createMessageRequest.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "createMessageRequest.errors[0]", errors));

        return;
      }

      resolve(_.get(response, "createMessageRequest"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0]") || _.get(error, "data.errors[0]") || APOLOGETIC_ERROR;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          recipientId: input.recipientId,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
