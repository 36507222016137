/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { CampfirePageConsumer, ExposedProps } from "providers/CampfirePageProvider";

export type WithCampfirePageProps = {
  campfirePage: ExposedProps;
};

export default <P extends WithCampfirePageProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithCampfirePageProps>): React.ReactElement => (
    <CampfirePageConsumer>
      {(exposedProps: ExposedProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component campfirePage={exposedProps} {...props} />
      )}
    </CampfirePageConsumer>
  );
};
