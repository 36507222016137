import { close } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import LoadingPageDefault from "common/components/LoadingPageDefault";
import UIButton from "common/components/UIButton";
import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";
import userProfileStyles from "common/components/UserProfileFragment/styles.scss";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  isError: boolean;
  close: () => void;
};

type State = {};

class UserProfileLoadingView extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={userProfileStyles.root}>
        <div className={userProfileStyles.scroller}>
          <div className={userProfileStyles.buttonGradient}>
            <UIButton
              color="light"
              className={userProfileStyles.closeBtn}
              onClick={this.props.close}
            >
              <UIIcon color="medium" icon={close} size={30} />
            </UIButton>
          </div>

          <div role="presentation" onClick={this.props.close}>
            <UISpacer h={70} />
          </div>

          <div className={userProfileStyles.profileWrapper}>
            {this.props.isError && (
              <div className={styles.error}>
                <UIText variant="h3" color="dark" weight="semiBold">
                  {this.props.t("SORRY_COULD_NOT_LOAD_USER_PROFILE")}
                </UIText>
              </div>
            )}
            {!this.props.isError && <LoadingPageDefault delayMs={0} />}
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(UserProfileLoadingView);
