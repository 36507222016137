import { InputChangeEventDetail } from "@ionic/core";
import { IonInput, IonLabel } from "@ionic/react";
import classnames from "classnames";
import React, { HTMLAttributes } from "react";

import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = HTMLAttributes<HTMLIonInputElement> & {
  value?: string;
  label?: string;
  labelColor?: IonicThemeShade;
  className?: string;
  onIonChange?: (event: CustomEvent<InputChangeEventDetail>) => void;
  readonly?: boolean;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  disabled?: boolean;
  maxlength?: number;
  dataTestId?: string;
  type?: HTMLIonInputElement["type"];
};

type State = {};

const DEFAULT_MAX_LENGTH = 120;

export default class UITextInput extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const {
      className,
      label,
      labelColor = "medium",
      endAdornment = null,
      startAdornment = null,
      maxlength = DEFAULT_MAX_LENGTH,
      dataTestId = null,
      ...remainingProps
    } = this.props;

    return (
      <>
        {label && (
          <IonLabel position="stacked" className={styles.label}>
            <UIText color={labelColor} weight="bold">
              {label}
            </UIText>
          </IonLabel>
        )}
        <div className={classnames(styles.root, className)}>
          {startAdornment && <div className={styles.startAdornment}>{startAdornment}</div>}
          <IonInput
            className={styles.input}
            color="dark"
            maxlength={maxlength}
            type={this.props.type}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dataTestId={dataTestId}
            {...remainingProps}
          />
          {endAdornment && <div className={styles.endAdornment}>{endAdornment}</div>}
        </div>
      </>
    );
  };
}
