import { App, AppState } from "@capacitor/app";
import { Dialog } from "@capacitor/dialog";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withAnalytics, { WithAnalyticsProps } from "providers/AnalyticsProvider/withAnalytics";
import withAppPermission, {
  WithAppPermissionProps,
} from "providers/AppPermissionProvider/withAppPermission";

import logException from "common/analytics/exceptions";
import { isWeb } from "common/capacitor/helpers";
import { PERMISSION_TYPE } from "common/stores/PermissionStore";
import { getOnboardingEventPayload, OnboardingEvent } from "common/utils/telemetry/onboarding";

import AccountSetupNotificationsPermissionSlideView from "boot-loader/components/AccountSetupNotificationsPermissionSlideView";

type Props = WithAppPermissionProps &
  WithTranslation &
  WithAnalyticsProps & {
    isActive: boolean;
    next: () => Promise<void>;
  };

type State = {
  hasNotificationsEnabled: boolean;
};

class AccountSetupNotificationsPermissionSlide extends React.Component<Props, State> {
  appListenerHandler: GenericObject | null;

  constructor(props: Props) {
    super(props);

    this.appListenerHandler = null;

    this.state = {
      hasNotificationsEnabled: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    const hasNotificationsEnabled = await this.props.hasPermission(PERMISSION_TYPE.notifications);

    this.setState({ hasNotificationsEnabled });

    // Listen for changes in the app state, and when the app comes back,
    // check and update the notification setting value.
    this.appListenerHandler = App.addListener("appStateChange", async (state: AppState) => {
      const isActiveSlide = this.props.isActive;
      const appIsActiveAgain = state.isActive;

      // The auto advance on app coming back into focus (when the user updates the setting manually)
      // only should apply when this is the active slide.
      if (isActiveSlide && appIsActiveAgain) {
        // eslint-disable-next-line max-len
        const hasPushNotificationsEnabled = await this.props.hasPermission(
          PERMISSION_TYPE.notifications,
        );

        this.setState({ hasNotificationsEnabled });

        if (hasPushNotificationsEnabled) {
          this.slideNext(true);
        }
      }
    });
  };

  componentWillUnmount = (): void => {
    if (this.appListenerHandler) {
      this.appListenerHandler.remove();
    }
  };

  onEnableClick = async (): Promise<void> => {
    // eslint-disable-next-line max-len
    const currentNotificationPermission = await this.props.getPermissionState(
      PERMISSION_TYPE.notifications,
    );

    if (currentNotificationPermission === "granted") {
      return this.slideNext(true);
    }

    if (currentNotificationPermission === "denied") {
      return this.handleDeniedPermissionState();
    }

    // Bring up prompt if needed for notification settings.
    if (currentNotificationPermission === "prompt") {
      try {
        // eslint-disable-next-line max-len
        const permissionStateResult = await this.props.requestPermission(
          PERMISSION_TYPE.notifications,
        );

        // On successful grant of permission, move on. Otherwise, show denied state.
        // On an error, just move on as well.
        if (permissionStateResult === "granted") {
          return this.slideNext(true);
        }

        this.handleDeniedPermissionState();
      } catch (error) {
        logException(
          "requestPermission",
          "onEnableClick",
          "AccountSetupNotificationsPermissionSlide",
          error,
        );
        // Intentionally "true" here because the user accepted the request
        return this.slideNext(true);
      }
    }

    return undefined;
  };

  handleDeniedPermissionState = async (): Promise<void> => {
    if (!isWeb) {
      // When permissions are denied, show an alert to encourage the user to enable it.
      await Dialog.alert({
        title: this.props.t("NOTIFICATIONS_ARE_OFF"),
        message: this.props.t("HIGHLY_ENCOURAGE_NOTIFICATIONS"),
      });
    }

    this.slideNext(false);
  };

  slideNext = async (accepted: boolean): Promise<void> => {
    this.props.analyticsProvider.trackClientAction(
      "OnboardingEvent",
      getOnboardingEventPayload(OnboardingEvent.PUSH_NOTIFICATIONS, accepted),
    );
    return this.props.next();
  };

  render = (): React.ReactNode => {
    return (
      <AccountSetupNotificationsPermissionSlideView
        notificationsEnabled={this.state.hasNotificationsEnabled}
        onEnableClick={this.onEnableClick}
      />
    );
  };
}

const AnalyticsConnected = withAnalytics(AccountSetupNotificationsPermissionSlide);
const AppPermissionConnected = withAppPermission(AnalyticsConnected);

export default withTranslation()(AppPermissionConnected);
