import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import HARDWARE_BACK_EVENT_PRIORITY from "constants/hardwareBackHierarchy";

import { DynamicSlide, DynamicSlideType, ViewControlReturnType } from "./slideTypes";
import useDynamicSlidePresenter from "./useDynamicSlidePresenter";
import useDynamicSlideState from "./useDynamicSlideState";
import UINav, {
  ExposedProps as UINavExposedProps,
  UINavOutlet,
  UINavSlide,
  UINavSlides,
  UINavSlideView,
} from "common/components/UINav";

import PGORealityChannelSettings from "pages/MapAndActivities/components/PGORealityChannelSettings";

import { ActivityCenterPageLayout_me$key as ActivityCenterPageLayoutMe } from "__generated__/ActivityCenterPageLayout_me.graphql";

import styles from "./styles.scss";

export type ExposedProps = {
  // Displays a specific "UINav" view in the Activity Center Page.
  // These views slide in and on top of the root view.
  // Any view that is standalone or only should have a single instance are candidates for
  // being preconfigured.
  showPreconfiguredView: (slideId: PreconfiguredSlideId) => ViewControlReturnType;

  // Displays a DOM node that content is dynamically rendered into.
  // This enables the ability to render arbitrary react nodes into a sliding surface of the UINav.
  showDynamicView: (slideId: string) => ViewControlReturnType;

  // Hides a view that is currently being displayed.
  // If the slide is a dynamic slide, it will also be removed from the set of dynamic slides when
  // the slide has been dismissed.
  hideView: (slideId: string) => void;

  // The currently active view.
  activeView: string | null;

  // Detects if a view is open in the UINav stack.
  isViewOpen: (slideId: string) => boolean;
};

// Known slides should be defined here up front.
export enum PreconfiguredSlideId {
  PGO_REALITY_CHANNEL_SETTINGS = "pgo_reality_channel_settings",
}

export const DATA_ATTR_DYNAMIC_SLIDE_ID = "data-attr-activity-center-slide-id";

type Props = WithTranslation & {
  children: (exposedProps: ExposedProps) => React.ReactNode;
  me: ActivityCenterPageLayoutMe;
};

/**
 * The core layout for the Map and Activity Center page.
 *
 * Exposes some controls to enable other components downstream to control the UINav slide stack.
 * Keeping the layout separate from the methods in an attempt to keep the layout here simple to read.
 */
const ActivityCenterPageLayout = (props: Props): React.ReactElement => {
  const { dynamicSlides, addDynamicSlide, removeDynamicSlide } = useDynamicSlideState();
  const { showPreconfiguredViewCore, showDynamicViewCore, hideViewCore } = useDynamicSlidePresenter(
    { addDynamicSlide },
  );

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const me = useFragment(ME_FRAGMENT, props.me);

  /**
   * Props that are exposed by the activity center related to the UINav.
   */
  const getExposedProps = (uiNavProps: UINavExposedProps) => {
    const showPreconfiguredView = (slideId: string): ViewControlReturnType => {
      return showPreconfiguredViewCore(slideId, uiNavProps);
    };

    const showDynamicView = (slideId: string): ViewControlReturnType => {
      return showDynamicViewCore(slideId, uiNavProps);
    };

    const hideView = (slideId: string): void => {
      return hideViewCore(slideId, uiNavProps);
    };

    const exposedProps: ExposedProps = {
      showPreconfiguredView,
      showDynamicView,
      hideView,
      activeView: uiNavProps.activeSlide,
      isViewOpen: uiNavProps.isSlideInStack,
    };

    return exposedProps;
  };

  const renderDynamicSlide = (dynamicSlide: DynamicSlideType): React.ReactNode => {
    // When the dynamic slide hides, remove it from the list.
    // Changes to the dynamic slides array should be ok to do when idle
    const onHidden = () => {
      removeDynamicSlide(dynamicSlide.slideId);
    };

    return (
      <UINavSlide key={dynamicSlide.slideId} slideId={dynamicSlide.slideId} onHidden={onHidden}>
        {() => {
          // Create the portal destination that the dynamic slide can render content into.
          // The developer is in full control of rendering a header and whatever else
          // is needed.
          const attrs = {
            [DATA_ATTR_DYNAMIC_SLIDE_ID]: dynamicSlide.slideId,
          };

          return <div {...attrs} className={styles.dynamicSlidePortalRoot} />;
        }}
      </UINavSlide>
    );
  };

  const renderDynamicViews = (): React.ReactNode[] => {
    return dynamicSlides.map((dynamicSlide: DynamicSlide) => {
      return renderDynamicSlide(dynamicSlide);
    });
  };

  return (
    <UINav
      enableSwipebackOnSlides
      enableHardwareBack
      hardwareBackPriority={HARDWARE_BACK_EVENT_PRIORITY.appRoot.pages.activityCenter.root}
    >
      <UINavSlides>
        {/* ====== PRECONFIGURED VIEWS ====== */}
        {/* PGO REALITY CHANNEL SETTINGS */}
        <UINavSlide slideId={PreconfiguredSlideId.PGO_REALITY_CHANNEL_SETTINGS}>
          {({ hideLastSlide }) => (
            <UINavSlideView
              title={props.t("POKEMON_GO_NOTIFICATION_SETTINGS")}
              onClickBack={hideLastSlide}
            >
              <PGORealityChannelSettings me={me} />
            </UINavSlideView>
          )}
        </UINavSlide>

        {/* ====== DYNAMIC Views ====== */}
        {renderDynamicViews()}
      </UINavSlides>

      <UINavOutlet>
        {(uiNavProps: UINavExposedProps) => {
          const exposedProps: ExposedProps = getExposedProps(uiNavProps);

          return props.children(exposedProps);
        }}
      </UINavOutlet>
    </UINav>
  );
};

const ME_FRAGMENT = graphql`
  fragment ActivityCenterPageLayout_me on User {
    id
    ...PGORealityChannelSettings_me
  }
`;

export default withTranslation()(ActivityCenterPageLayout);
