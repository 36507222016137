import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Slide, toast, ToastContainer, ToastContent, ToastOptions } from "react-toastify";

import { ReactComponent as CheckMark } from "assets/icons/checkmark.svg";
import { ReactComponent as CampfireFeedbackIcon } from "assets/icons/cute-fire.svg";
import { ReactComponent as NoFire } from "assets/icons/no-cute-fire.svg";
import { ReactComponent as Walking } from "assets/icons/walking.svg";

import Image from "common/components/Image";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";
import "react-toastify/dist/ReactToastify.minimal.css";

type FeedbackIcon = "default" | "noFire" | "walking" | "checkMark" | "none";

export type ExposedProps = {
  showMessageToast: (toastInfo: MessageToastInfo, throttle?: boolean) => void;
  showErrorToast: (toastMessage: string, throttle?: boolean) => void;
  showSuccessToast: (toastMessage: string, throttle?: boolean) => void;
  showFeedbackToast: (
    toastMessage: string,
    icon?: FeedbackIcon,
    center?: boolean,
    throttle?: boolean,
  ) => void;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  // Assumes only one toast at a time
  currentToastClicked: boolean;
};

export type MessageToastInfo = {
  headerText?: string;
  messageText?: string;
  srcImage?: string;
  srcText?: string;
  duration?: number;
  onClick?: () => void;
  onDismiss?: () => void;
  renderCustomContent?: () => React.ReactNode;
};

const INITIAL_CONTEXT: ExposedProps = {
  showMessageToast: _.noop,
  showSuccessToast: _.noop,
  showErrorToast: _.noop,
  showFeedbackToast: _.noop,
};

export const ToastContext = React.createContext(INITIAL_CONTEXT);

const DEFAULT_MESSAGE_TIMEOUT_MS = 5000;
const DEFAULT_ERROR_TIMEOUT_MS = 3000;
const DEFAULT_SUCCESS_TIMEOUT_MS = 2000;
const DEFAULT_FEEDBACK_TIMEOUT_MS = 2000;

const DEFAULT_TOAST_THROTTLE_MS = 3000;

const FEEDBACK_ICON_MAP = {
  default: CampfireFeedbackIcon,
  noFire: NoFire,
  walking: Walking,
  checkMark: CheckMark,
  none: null,
};

export class ToastProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentToastClicked: false,
    };
  }

  wrappedOnClick = (onClick?: () => void): void => {
    this.setState({ currentToastClicked: true });

    if (!onClick) {
      return;
    }

    onClick();
  };

  wrappedOnDismiss = (onDismiss?: () => void): void => {
    if (!this.state.currentToastClicked && onDismiss) {
      onDismiss();
    }

    this.setState({ currentToastClicked: false });
  };

  // eslint-disable-next-line react/sort-comp
  toastThrottled = _.throttle(toast, DEFAULT_TOAST_THROTTLE_MS, { trailing: false });

  wrappedToast = (content: ToastContent, options?: ToastOptions, throttled = false): void => {
    if (throttled) {
      this.toastThrottled(content, options);
      return;
    }

    toast(content, options);
  };

  showMessageToast = (toastInfo: MessageToastInfo, throttle = false): void => {
    const messageToast = toastInfo.renderCustomContent ? (
      toastInfo.renderCustomContent()
    ) : (
      <div>
        {toastInfo.srcImage && toastInfo.srcText && (
          <>
            <div className={styles.srcRoot}>
              <Image alt="Logo" className={styles.srcImage} src={toastInfo.srcImage} />

              <UISpacer w={8} />
              <UIText color="medium" weight="medium" variant="h5">
                {toastInfo.srcText}
              </UIText>
            </div>
            <UISpacer h={12} />
          </>
        )}
        <div>
          <UIText color="dark" weight="bold" variant="h4">
            {toastInfo.headerText}
          </UIText>
          <UISpacer h={4} />
          <UIText color="dark" weight="medium" variant="h5">
            {toastInfo.messageText}
          </UIText>
        </div>
      </div>
    );

    const options = {
      autoClose: toastInfo.duration ?? DEFAULT_MESSAGE_TIMEOUT_MS,
      type: toast.TYPE.DEFAULT,
      onClick: () => this.wrappedOnClick(toastInfo.onClick),
      onClose: () => this.wrappedOnDismiss(toastInfo.onDismiss),
    };

    this.wrappedToast(messageToast, options, throttle);
  };

  showErrorToast = (toastMessage: string, throttle = false): void => {
    const messageToast = (
      <div>
        <UIText color="white" weight="bold" variant="h5">
          {toastMessage}
        </UIText>
      </div>
    );

    const options = {
      autoClose: DEFAULT_ERROR_TIMEOUT_MS,
      type: toast.TYPE.ERROR,
    };

    this.wrappedToast(messageToast, options, throttle);
  };

  showSuccessToast = (toastMessage: string, throttle = false): void => {
    const messageToast = (
      <div>
        <UIText color="white" weight="bold" variant="h5">
          {toastMessage}
        </UIText>
      </div>
    );

    const options = {
      autoClose: DEFAULT_SUCCESS_TIMEOUT_MS,
      type: toast.TYPE.SUCCESS,
    };

    this.wrappedToast(messageToast, options, throttle);
  };

  showFeedbackToast = (
    toastMessage: string,
    icon: FeedbackIcon = "default",
    centered = false,
    throttle = false,
  ): void => {
    const IconComponent = FEEDBACK_ICON_MAP[icon] || FEEDBACK_ICON_MAP.default;
    const feedbackToast = (
      <div className={styles.feedbackToast}>
        {icon !== "none" && (
          <>
            <div className={styles.icon}>
              <IconComponent />
            </div>

            <UISpacer w={12} />
          </>
        )}

        <div
          className={classNames(styles.feedbackToastContent, {
            [styles.justifyContentCenter]: centered,
          })}
        >
          <UIText color="white" weight="bold" variant="h5">
            {toastMessage}
          </UIText>
        </div>
      </div>
    );

    const options = {
      autoClose: DEFAULT_FEEDBACK_TIMEOUT_MS,
      type: toast.TYPE.DEFAULT,
      theme: "dark" as const,
    };

    this.wrappedToast(feedbackToast, options, throttle);
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <ToastContext.Provider
        value={{
          showMessageToast: this.showMessageToast,
          showErrorToast: this.showErrorToast,
          showSuccessToast: this.showSuccessToast,
          showFeedbackToast: this.showFeedbackToast,
        }}
      >
        {children}
        <ToastContainer
          position="top-center"
          transition={Slide}
          draggableDirection="y"
          draggable
          draggablePercent={50}
          closeOnClick
          closeButton={false}
          limit={1}
          pauseOnHover={false}
          icon={false}
        />
      </ToastContext.Provider>
    );
  };
}

export const ToastConsumer = ToastContext.Consumer;
