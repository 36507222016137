import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import withAppPermission, {
  WithAppPermissionProps,
} from "providers/AppPermissionProvider/withAppPermission";
import withAppState, { WithAppStateProps } from "providers/AppStateProvider/withAppState";
import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";
import withPushNotification, {
  WithPushNotificationProps,
} from "providers/PushNotificationProvider/withPushNotification";

import { isWeb } from "common/capacitor/helpers";
import OnboardingStore from "common/stores/OnboardingStore";
import { PERMISSION_TYPE } from "common/stores/PermissionStore";
import routeToActivityCenter from "common/utils/routing/routeToActivityCenter";
import { initializeAppTheme, overrideThemeForGame, restoreDefaultTheme } from "common/utils/theme";
import { parseQuery } from "common/utils/url";
import { embeddedClientInfo, isPGOEmbed } from "common/utils/webInterop";
import { FEATURE_FLAGS } from "constants/featureFlags";
import { GameShortCode } from "constants/nianticGame";
import { MapAction, MAP_ROUTE, QUERY_PARAMS } from "constants/routes";

type Props = RouteComponentProps &
  WithAppStateProps &
  WithAppPermissionProps &
  WithFeatureFlagProps &
  WithPushNotificationProps & {};

type State = {};

/**
 * Runs stuff after we know we are logged in and in the full App view.
 *
 * Currently:
 * - Will prompt user to enable push notifications if not already granted.
 * - Will listen for when the app becomes active again.
 */
class AppRunAfterInit extends React.Component<Props, State> {
  componentDidMount = (): void => {
    // Prompt to enable push notifications if needed.
    // Only should happen for iOS and Android, so NOT web should be sufficient.
    if (!isWeb) {
      this.promptForPushNotifications();
    }

    // Mark that the app loaded for the first time if not set
    if (!OnboardingStore.get("timeAppFirstLoaded")) {
      OnboardingStore.set("timeAppFirstLoaded", Date.now());
    }

    // Theme Initialization
    // ====================
    initializeAppTheme();

    // Theme Game Override
    // ===================
    if (embeddedClientInfo && embeddedClientInfo.Game) {
      overrideThemeForGame(embeddedClientInfo.Game as GameShortCode);
    }

    // TODO: REMOVE ME
    // [SO-3383 Temporary PGO Embed Based Starting Route Override
    // =============================================================
    const useCampfireV2 = this.props.hasFeatureFlag(FEATURE_FLAGS.CAMPFIRE_V2);
    const applyTempRedirect = this.props.hasFeatureFlag(
      FEATURE_FLAGS.PGO_EMBED_TEMPORARY_AC_REDIRECT,
    );

    // Must be PGO Embed and Campfire V2 enabled
    if (isPGOEmbed && useCampfireV2 && applyTempRedirect) {
      this.redirectToActivityCenterIfPGOEmbedAndCampfireV2();
    }

    // Check for certain user intentions which should take precedence over
    // potentially conflicting UI invoked by Coachmarks.
    this.checkIfCoachmarksShouldBeDeferred();
  };

  componentWillUnmount = (): void => {
    // Once we unmount, put the app back into the proper theme. Hopefully, the url has not
    // changed and we lost the theme query param.
    restoreDefaultTheme();
  };

  promptForPushNotifications = async (): Promise<void> => {
    const hasNotificationPermission = await this.props.hasPermission(PERMISSION_TYPE.notifications);

    // If we have not granted notification permissions, attempt to prompt for them
    if (!hasNotificationPermission) {
      const permissionState = await this.props.requestPermission(PERMISSION_TYPE.notifications);

      // If granted, register for push to get fcm token.
      if (permissionState === "granted") {
        await this.props.registerPushNotifications();
      }
    }
  };

  /**
   * When we launch the app (either in Standalone or Embed), there are certain intentions
   * that should take precedence over other flows controlled by the CoachmarkProvider.
   *
   * If you do nothing else, this check will defer the Coachmarks until the next boot of
   * the app if such an intention is detected. If you believe the user should not wait that
   * long, you may update the state in AppStateProvider when desired.
   *
   * Currently, the intentions are:
   * - Creating a Post: Route to /map with action=showCreatePost param
   */
  checkIfCoachmarksShouldBeDeferred = (): void => {
    let hasCoachmarkBlockingIntent = false;

    const query = parseQuery(this.props.location.search);
    const hasIntentionToCreatePost =
      this.props.location.pathname === `/${MAP_ROUTE}` &&
      query[QUERY_PARAMS.map.action.key] === MapAction.SHOW_CREATE_POST;

    hasCoachmarkBlockingIntent = hasIntentionToCreatePost;

    this.props.appState.setHasCoachmarkBlockingIntent(hasCoachmarkBlockingIntent);
  };

  redirectToActivityCenterIfPGOEmbedAndCampfireV2 = (): void => {
    // Only force redirect when the pathname starts with /map.
    if (this.props.location.pathname === `/${MAP_ROUTE}`) {
      routeToActivityCenter(this.props.history);
    }
  };

  render = (): React.ReactNode => {
    return null;
  };
}

const FeatureFlagConnected = withFeatureFlag(AppRunAfterInit);
const AppStateConnected = withAppState(FeatureFlagConnected);
const PushNotificationConnected = withPushNotification(AppStateConnected);
const RouterConnected = withRouter(PushNotificationConnected);

export default withAppPermission(RouterConnected);
