import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar-icon.svg";
import GamesIcon from "assets/images/onboarding/game-icon-group.png";
import MapIcon from "assets/images/onboarding/map-flare-center-icon.png";

import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";
import Image from "common/components/Image";
import IonAppPortal from "common/components/IonAppPortal";
import OnboardCoachmarkInfoCard from "common/components/Onboarding/components/OnboardCoachmarkInfoCard";
import OnboardCoachmarkShowMeAround from "common/components/Onboarding/components/OnboardCoachmarkShowMeAround";
import OnboardCoachmarkSpotlight from "common/components/Onboarding/components/OnboardCoachmarkSpotlight";
import UIWizard from "common/components/UIWizard";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  onComplete: () => void;
  isOpen: boolean;
};

type Step = {
  id: StepName;
  wizardIdx?: number;
  spotlightSelector?: string;
  borderRadius?: number;
};

export enum StepName {
  WELCOME_DIALOG = "welcome-dialog",
  SPOTLIGHT_TEAMUP = "spotlight-teamup",
  SPOTLIGHT_MAP_TOGGLE = "spotlight-map-toggle",
  SPOTLIGHT_TAB_BAR = "spotlight-tab-bar",
}

type State = {
  currentStep: Step;
};

export const CF_SPOTLIGHT_ATTRIBUTE = "data-cf-spotlight-id";

const STEPS: Record<StepName, Step> = {
  [StepName.WELCOME_DIALOG]: {
    id: StepName.WELCOME_DIALOG,
  },
  [StepName.SPOTLIGHT_TEAMUP]: {
    id: StepName.SPOTLIGHT_TEAMUP,
    spotlightSelector: `[${CF_SPOTLIGHT_ATTRIBUTE}='${StepName.SPOTLIGHT_TEAMUP}']`,
    wizardIdx: 0,
    borderRadius: 18,
  },
  [StepName.SPOTLIGHT_MAP_TOGGLE]: {
    id: StepName.SPOTLIGHT_MAP_TOGGLE,
    spotlightSelector: `[${CF_SPOTLIGHT_ATTRIBUTE}='${StepName.SPOTLIGHT_MAP_TOGGLE}']`,
    wizardIdx: 1,
    borderRadius: 22,
  },
  [StepName.SPOTLIGHT_TAB_BAR]: {
    id: StepName.SPOTLIGHT_TAB_BAR,
    spotlightSelector: `[${CF_SPOTLIGHT_ATTRIBUTE}='${StepName.SPOTLIGHT_TAB_BAR}']`,
    wizardIdx: 2,
    borderRadius: 15,
  },
};

const STEPS_ARR = [
  STEPS[StepName.WELCOME_DIALOG],
  STEPS[StepName.SPOTLIGHT_TEAMUP],
  STEPS[StepName.SPOTLIGHT_MAP_TOGGLE],
  STEPS[StepName.SPOTLIGHT_TAB_BAR],
];

class OnboardCoachmarkFlow extends React.Component<Props, State> {
  uiWizardRef = React.createRef<UIWizard>();

  constructor(props: Props) {
    super(props);

    this.state = {
      currentStep: STEPS[StepName.WELCOME_DIALOG],
    };
  }

  closeDialog = (): void => {
    this.advanceToNextStep();
  };

  finishFlow = (): void => {
    this.props.onComplete();
  };

  advanceToNextStep = (): void => {
    const existingStepIdx = STEPS_ARR.findIndex(
      (step: Step) => step.id === this.state.currentStep.id,
    );

    if (existingStepIdx !== -1) {
      const nextStep: Step = STEPS_ARR[existingStepIdx + 1];

      if (nextStep) {
        this.setState({ currentStep: nextStep });

        if (nextStep.wizardIdx && this.uiWizardRef.current) {
          this.uiWizardRef.current.slideTo(nextStep.wizardIdx);
        }
      }
    }
  };

  renderSpotlight = (): React.ReactNode => {
    const { spotlightSelector, borderRadius } = this.state.currentStep;

    const spotlightData = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: borderRadius || 0,
    };

    if (spotlightSelector) {
      const spotlightEl = document.querySelector(spotlightSelector);

      if (spotlightEl) {
        const boundingRect = spotlightEl?.getBoundingClientRect();

        spotlightData.top = boundingRect.top;
        spotlightData.right = boundingRect.right;
        spotlightData.bottom = boundingRect.bottom;
        spotlightData.left = boundingRect.left;
      }
    }

    return <OnboardCoachmarkSpotlight {...spotlightData} zIndex={10} highlight />;
  };

  render = (): React.ReactNode => {
    const showDialog = this.state.currentStep.id === StepName.WELCOME_DIALOG;
    const showSpotlightPart = this.state.currentStep.id !== StepName.WELCOME_DIALOG;

    return (
      <>
        <OnboardCoachmarkShowMeAround isOpen={showDialog} onSubmit={this.closeDialog} />

        <IonAppPortal>
          <AnimatedFadeInOut show={this.props.isOpen} className={styles.root}>
            {!showSpotlightPart && <div className={styles.mask} />}
            {showSpotlightPart && (
              <>
                {this.renderSpotlight()}
                <div className={styles.wizardCardCtn}>
                  <UIWizard ref={this.uiWizardRef} showBackButton={() => false}>
                    {() => [
                      <OnboardCoachmarkInfoCard
                        title={this.props.t("PLAY_WITH_OTHERS").toLocaleUpperCase()}
                        description={this.props.t("TEAM_UP_WITH_OTHERS")}
                        imageNode={<CalendarIcon />}
                        onClickOk={this.advanceToNextStep}
                      />,
                      <OnboardCoachmarkInfoCard
                        title={this.props.t("VIEW_THE_MAP").toLocaleUpperCase()}
                        description={this.props.t("SEE_WHAT_ACTIVITIES_ARE_AROUND")}
                        imageNode={<Image src={MapIcon} />}
                        onClickOk={this.advanceToNextStep}
                      />,
                      <OnboardCoachmarkInfoCard
                        title={this.props.t("SWAP_CHANNELS").toLocaleUpperCase()}
                        description={this.props.t("CHANGE_WHATS_ON_YOUR_HOME_SCREEN")}
                        imageNode={<Image src={GamesIcon} />}
                        onClickOk={this.finishFlow}
                      />,
                    ]}
                  </UIWizard>
                </div>
              </>
            )}
          </AnimatedFadeInOut>
        </IonAppPortal>
      </>
    );
  };
}

export default withTranslation()(OnboardCoachmarkFlow);
