import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import { NIANTIC_GUIDELINES_URL } from "constants/urls";

import OnboardElementLabelInput from "common/components/Onboarding/components/OnboardElementLabelInput";
import OnboardElementSubmitButton from "common/components/Onboarding/components/OnboardElementSubmitButton";
import OnboardElementTitle from "common/components/Onboarding/components/OnboardElementTitle";
import OnboardNianticIdPreview from "common/components/Onboarding/components/OnboardNianticIdPreview";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText, { Props as UITextProps } from "common/components/UIText";

import styles from "./styles.scss";

export enum NianticIDError {
  ID_EXISTS = "id_exists",
  INVALID = "invalid",
}

// Configure an "inner link" inside a string (which may be translated).
// [start index of linked text, end index of linked text, href/url target of linked text]
type NameRequirementsLinkConfig = [number, number, string];

export type Props = WithTranslation & {
  nianticId: string;
  onNianticIdChange: (id: string) => void;
  onSubmit: () => Promise<void>;
  isSubmitButtonDisabled: boolean;
  error?: NianticIDError | null;
};

class OnboardNianticID extends React.Component<Props> {
  renderNamingRequirement = (
    prefix: string,
    requirement: string,
    linkConfig?: NameRequirementsLinkConfig,
  ): React.ReactNode => {
    const textProps: UITextProps = { color: "danger", variant: "subtitle1" };

    if (!linkConfig)
      return (
        <div>
          <UIText {...textProps}>
            {prefix}
            {requirement}
          </UIText>
        </div>
      );

    // If we need to render a link inside the text block we have more to do.
    // The caller gives us the start/end index of the text that will be
    // placed inside the UILink component. The surrouding text should go
    // in regular UIText components.

    // We'll split the string into three parts based on link position,
    // where link is the middle string, and render each part separately.

    const [startIdx, endIdx, href] = linkConfig;

    const beg = requirement.substring(0, startIdx);
    const mid = requirement.substring(startIdx, startIdx + endIdx);
    const end = requirement.substring(startIdx + endIdx, requirement.length);

    return (
      <div>
        {beg.length > 0 && (
          <UIText {...textProps}>
            {prefix}
            {beg}
          </UIText>
        )}
        <UILink href={href}>
          <UIText {...textProps} className={styles.namingRequirementLink}>
            {mid}
          </UIText>
        </UILink>
        {end.length > 0 && <UIText {...textProps}>{end}</UIText>}
      </div>
    );
  };

  renderErrorInvalid = (): React.ReactNode => {
    const prefix = "\u00A0\u00A0•\u00A0\u00A0";
    const guidelinesText = this.props.t("NIANTIC_ID_PLAYER_GUIDELINES");
    const guidelinesRequirement = this.props.t("NIANTIC_ID_ABIDE_BY", {
      documentName: guidelinesText,
    });

    // Dynamically searching for the bounds of the text that we need to link.
    // We can't just hardcode the string idices, because it needs to be translated.
    // We do know for sure that the guidelinesText will appear in the final string,
    // because we template it into the translation in the line above.

    const guidelinesLinkConfig: NameRequirementsLinkConfig = [
      guidelinesRequirement.indexOf(guidelinesText),
      guidelinesText.length,
      NIANTIC_GUIDELINES_URL,
    ];

    return (
      <div>
        <UIText color="danger" weight="bold" variant="subtitle1">
          {this.props.t("NIANTIC_ID_NAMING_REQUIREMENTS")}
        </UIText>
        <UISpacer h={4} />
        {this.renderNamingRequirement(prefix, this.props.t("NIANTIC_ID_NAMING_LENGTH"))}
        {this.renderNamingRequirement(prefix, this.props.t("NIANTIC_ID_NO_SPECIAL_CHARACTERS"))}
        {this.renderNamingRequirement(prefix, this.props.t("NIANTIC_ID_NO_SPACES"))}
        {this.renderNamingRequirement(prefix, guidelinesRequirement, guidelinesLinkConfig)}
      </div>
    );
  };

  renderErrorIdExists = (): React.ReactNode => {
    return (
      <UIText color="danger" weight="bold" variant="subtitle1">
        {this.props.t("NIANTIC_ID_ALREADY_EXISTS")}
      </UIText>
    );
  };

  renderErrorIfPresent = (): React.ReactNode => {
    if (!this.props.error ?? false) return null;
    return (
      <>
        <UISpacer h={10} />
        {this.props.error === NianticIDError.ID_EXISTS && this.renderErrorIdExists()}
        {this.props.error === NianticIDError.INVALID && this.renderErrorInvalid()}
      </>
    );
  };

  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <div className={styles.innerContainer}>
          <OnboardElementTitle
            title={this.props.t("NIANTIC_ID_CHOOSE_FIRST")}
            subtitle={this.props.t("NIANTIC_ID_CAN_BE_CHANGED")}
          />
          <UISpacer h={24} />
          <OnboardNianticIdPreview />
          <UISpacer h={32} />
          <div className={styles.formContainer}>
            <OnboardElementLabelInput
              label={this.props.t("NIANTIC_USERNAME")}
              value={this.props.nianticId}
              onChange={this.props.onNianticIdChange}
            />
            <UISpacer h={22} />
            <OnboardElementSubmitButton
              text={this.props.t("OK")}
              disabled={this.props.isSubmitButtonDisabled}
              onClick={this.props.onSubmit}
            />
            {this.renderErrorIfPresent()}
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(OnboardNianticID);
