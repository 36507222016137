import React from "react";

import UIAvatar from "common/components/Avatar/UIAvatar";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = {
  avatarUrl: string;
  displayName: string;
  username: string;
  renderAvatarAdornment?: () => React.ReactNode;
};

type State = {};

export default class UserProfileAvatarAndName extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIAvatar
          allowRandomBgColor
          size={120}
          avatarUrl={this.props.avatarUrl}
          name={this.props.displayName}
        />
        {this.props.renderAvatarAdornment ? this.props.renderAvatarAdornment() : null}

        <UISpacer h={10} />

        <UIText variant="h2" weight="bold" color="dark">
          {this.props.displayName}
        </UIText>

        <UISpacer h={5} />

        <UIText variant="h4" weight="medium" color="dark">
          {`@${this.props.username}`}
        </UIText>
      </div>
    );
  };
}
