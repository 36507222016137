import { BundleInfo, CapacitorUpdater } from "@capgo/capacitor-updater";
import _ from "lodash";

import { BUILT_IN_BUNDLE_ID } from "constants/appUpdate";

/**
 * Deletes all bundles passed.
 */
const deleteBundles = async (bundles: BundleInfo[]): Promise<void> => {
  try {
    // For each bundle, delete it.
    const deleteBundlePromises: Promise<void>[] = bundles.map((bundle: BundleInfo) => {
      return CapacitorUpdater.delete({ id: bundle.id });
    });

    await Promise.allSettled(deleteBundlePromises);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

/**
 * Deletes all bundles from CapacitorUpdater except for the bundle ids passed.
 */
// eslint-disable-next-line import/prefer-default-export
export const deleteAllUnusedBundlesExcept = async (bundleIdsToKeep: string[]): Promise<void> => {
  try {
    // Get all bundles.
    const listResponse = await CapacitorUpdater.list();

    // Prevent deletion of the built-in bundle and the current bundle.
    const currentBundleAsSeenByCapUpdaterResponse = await CapacitorUpdater.current();
    const allBundles = listResponse.bundles;
    const bundleIdsToKeepIncludingActive = _.uniq([
      ...bundleIdsToKeep,
      BUILT_IN_BUNDLE_ID,
      currentBundleAsSeenByCapUpdaterResponse?.bundle?.id,
    ]);
    const bundlesToRemove = allBundles.filter(
      (bundle) => !bundleIdsToKeepIncludingActive.includes(bundle.id),
    );

    await deleteBundles(bundlesToRemove);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
