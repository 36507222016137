import { Device } from "@capacitor/device";
import environment from "common/relay/relay-env";
import { graphql } from "react-relay";
import { fetchQuery } from "relay-runtime";

import { logInDevelopmentEnvs } from "constants/isDev";

import { checkForUpdates_Query$data as CheckForUpdatesQueryResponse } from "__generated__/checkForUpdates_Query.graphql";

export type AppUpdateData = DeepWritable<CheckForUpdatesQueryResponse["appUpdate"]>;

type AppUpdateInput = {
  appVersion: string;
  jsVersion: string;
  platform: string;
};

const CHECK_FOR_APP_UPDATES_QUERY = graphql`
  query checkForUpdates_Query($input: AppUpdateInput) {
    appUpdate(input: $input) {
      isUpToDate
      bundle {
        version
        url
      }
    }
  }
`;

const checkForAppUpdatesFromServer = async (
  appUpdateInput: AppUpdateInput,
): Promise<CheckForUpdatesQueryResponse> => {
  const response = await fetchQuery(
    environment,
    CHECK_FOR_APP_UPDATES_QUERY,
    {
      input: appUpdateInput,
    },
    {
      networkCacheConfig: {
        force: true,
      },
    },
  ).toPromise();

  return response as CheckForUpdatesQueryResponse;
};

/**
 * Currently a mock endpoint to simulate an async process to get next bundle info.
 */
// eslint-disable-next-line import/prefer-default-export
export const checkForUpdates = async (
  currentAppVersion: string,
  currentJSVersion: string,
): Promise<AppUpdateData> => {
  // eslint-disable-next-line no-console
  logInDevelopmentEnvs(
    `Current Installed App Version: ${currentAppVersion}, and JS Version: ${currentJSVersion}. Checking for js updates...`,
  );

  const deviceInfoResponse = await Device.getInfo();
  const appUpdateData = await checkForAppUpdatesFromServer({
    appVersion: currentAppVersion,
    jsVersion: currentJSVersion,
    platform: deviceInfoResponse.platform,
  });

  logInDevelopmentEnvs("App update data received: ", appUpdateData);

  return appUpdateData.appUpdate;
};
