import { BackButtonEvent } from "@ionic/core";
import React from "react";

export type Props = {
  isActive: boolean;
  priority: number;
  onHardwareBack: (processNextEvent: () => void) => void;
};

type State = {};

class IonBackHandler extends React.Component<Props, State> {
  componentDidMount = (): void => {
    if (this.props.isActive) {
      this.activateBackHandling();
    }
  };

  componentWillUnmount = (): void => {
    this.deactivateBackHandling();
  };

  activateBackHandling = (): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener("ionBackButton", this.handleHardwareBack);
  };

  deactivateBackHandling = (): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.removeEventListener("ionBackButton", this.handleHardwareBack);
  };

  componentDidUpdate = (prevProps: Readonly<Props>): void => {
    if (this.props.isActive && !prevProps.isActive) {
      this.activateBackHandling();
    } else if (!this.props.isActive && prevProps.isActive) {
      this.deactivateBackHandling();
    }
  };

  handleHardwareBack = (event: BackButtonEvent): void => {
    event.detail.register(this.props.priority, (processNextHandler: () => void) => {
      this.props.onHardwareBack(processNextHandler);
    });
  };

  render = (): React.ReactNode => {
    return null;
  };
}

export default IonBackHandler;
