import { SpringRef } from "react-spring";

import getSlideInfoByIndex from "./getSlideInfoByIndex";
import { SlideStackState, SlideComponentDef } from "./types";

/**
 * The layering logic here is a tad complicated, so let me try to shed some light.
 *
 * Let's divide out set of slides into 3 groups:
 * 1. Slides that are coming in
 * 2. Slides that are going away
 * 3. Slides that are going away, but should do so silently.
 *
 * We want slides that are coming in to ALWAYS be on top. So any slide that is showing
 * should exist in the slideStack desired and will receive a z-index based on its position
 * in the stack.
 *
 * Slides that are leaving, also need to be HIGHER than the next active slide. Otherwise,
 * the slide behind, or the slide that is now the currently active one (this is when you
 * close a slide) will appear on top of the slide that is leaving. We want to give the
 * impression that the slide that is leaving was like turning a page in a book backwards.
 * The slide leaving is the page that is turning, and that should be on top.
 *
 * Slides however, can also leave when they are kinda underneath all the other slides.
 * In this case, we definitely do not want to promote those slides higher than the current
 * active slide since it would be as if we are revealing the already active slide on the top.
 * For these slides, these are kept at a lower z-index so that they can shift behind
 * all the other slides.
 */
const updateSlideLayering = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactSpringImperativeApi: SpringRef<any>,
  numberOfSlides: number,
  slides: SlideComponentDef[],
  currentSlideState: SlideStackState,
): void => {
  // Schedule an update for the springs to adjust zIndex immediately
  reactSpringImperativeApi.start((index) => {
    // We need some info about the slide, so grab that info from the current slide state.
    const { slideId, indexInStack } = getSlideInfoByIndex(index, slides, currentSlideState);

    const isSlideSupposedToBeSlidingBehindSilently =
      currentSlideState.lastSlideChanges.hidingSilently.includes(slideId);
    const isSlideHiding = currentSlideState.lastSlideChanges.hiding.includes(slideId);

    const zIndexWhenHiding = isSlideHiding ? numberOfSlides : 0;
    const zIndexBasedOnStackPosition = indexInStack !== -1 ? indexInStack + 1 : zIndexWhenHiding;
    const zIndexAccountingForSlidesBelowHiding = isSlideSupposedToBeSlidingBehindSilently
      ? 0
      : zIndexBasedOnStackPosition;

    return {
      immediate: true,
      zIndex: zIndexAccountingForSlidesBelowHiding,
    };
  });
};

export default updateSlideLayering;
