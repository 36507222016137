import { Filesystem, GetUriResult } from "@capacitor/filesystem";

import logException from "common/analytics/exceptions";

// https://capacitorjs.com/docs/apis/filesystem#geturi
export const validator = (data: GetUriResult): boolean => {
  try {
    return !!data.uri;
  } catch (error) {
    logException("validating Filesystem.getUri data from embed", "validator", "getUri", error);
    return false;
  }
};

export default Filesystem.getUri;
