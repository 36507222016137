import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { graphql, QueryRenderer } from "react-relay";

import LoadingPageDefault from "common/components/LoadingPageDefault";
import ResetAccountData from "common/components/ResetAccountData";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import { ResetAccountDataQR_Query$data as ResetAccountDataQueryResponse } from "__generated__/ResetAccountDataQR_Query.graphql";

type Props = {
  proceedToNextSequenceInApp: () => Promise<void>;
};

type State = {
  error: boolean;
};

type QRProps = ResetAccountDataQueryResponse & {
  me: ResetAccountDataQueryResponse["me"];
};

const RESET_ACCOUNT_DATA_QUERY = graphql`
  query ResetAccountDataQR_Query {
    me {
      ...ResetAccountData_me
    }
  }
`;

class ResetAccountDataQR extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  finishFlow = (): void => {
    if (!this.state.error) {
      this.props.proceedToNextSequenceInApp();
      this.setState({ error: true });
    }
  };

  render = (): React.ReactNode => {
    return (
      <UIErrorBoundary>
        <Suspense fallback={<LoadingPageDefault />}>
          <QueryRenderer
            environment={environment}
            query={RESET_ACCOUNT_DATA_QUERY}
            variables={{}}
            fetchPolicy="store-and-network"
            render={({ props, error }) => {
              const qrProps = props as QRProps;

              const isLoading = !props;
              const isError = !!error;

              if (isLoading) {
                return <LoadingPageDefault />;
              }

              if (isError) {
                this.finishFlow();
                return null;
              }

              return <ResetAccountData {...qrProps} {...this.props} />;
            }}
          />
        </Suspense>
      </UIErrorBoundary>
    );
  };
}

export default ResetAccountDataQR;
