// eslint-disable-next-line import/prefer-default-export
export enum Notification {
  CLUB_INVITE_RECEIVED = "club_invite_received",
  EVENT_CREATED = "event_created",
  EVENT_SOON = "event_soon",
  FRIEND_INVITE_RECEIVED = "friend_invite_received",
  FRIEND_INVITE_ACCEPTED = "friend_invite_accepted",
  MENTION = "mention",
  DIRECT_MESSAGE_MENTION = "direct_message_mention",
  DIRECT_MESSAGE = "direct_message",
  MESSAGE_REQUEST = "message_request",
  CAMPFIRE_INVITE = "campfire_invite",
  BEACON_SAME_LIT = "beacon_same_lit",
  BEACON_FRIEND_LIT = "beacon_friend_lit",
  BEACON_NEAR_ME_LIT = "beacon_near_me_lit",
  POST_CREATED = "post_created",
  ACTIVITY_JOINED = "activity_joined",
  ACTIVITY_MEMBERSHIP_TIMEOUT = "activity_membership_timeout",
  ADDED_TO_ACTIVITY_REMINDER = "added_to_activity_reminder",
  CAMPFIRE_INVITE_ACCEPTED = "campfire_invite_accepted",
  CLUB_JOINED = "club_joined",
  MESSAGE_SENT_ACTIVITY_CHANNEL = "message_sent_activity_channel",
  ACCOUNT_SUSPENSION = "account_suspension",
  WARNING = "warning",
}
