import React from "react";

import withAppRouterV2CoreNavigation, {
  WithAppRouterV2CoreNavigationProps,
} from "providers/AppRouterV2CoreNavigationProvider/withAppRouterV2CoreNavigation";

import CoreNavigationFloatingTabBar from "common/components/AppRouterV2CoreNavigationFabs/components/CoreNavigationFloatingTabBar";

type Props = WithAppRouterV2CoreNavigationProps & {
  children: React.ReactNode;
};

class CoreNavigation extends React.Component<Props> {
  render = (): React.ReactNode => {
    return (
      <CoreNavigationFloatingTabBar.Entrance>
        {this.props.children}
      </CoreNavigationFloatingTabBar.Entrance>
    );
  };
}

export default withAppRouterV2CoreNavigation(CoreNavigation);
