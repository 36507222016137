/**
 * @generated SignedSource<<ffae55eaa6aa337afc0a2e72c9ecf76d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MessagesFromHistoryInput = {
  channels: ReadonlyArray<string>;
  count?: number | null;
  end?: string | null;
  includeMessageActions?: boolean | null;
  includeMeta?: boolean | null;
  includeSenderColorForGame?: string | null;
  start?: string | null;
};
export type clientMessagesFromHistory_Query$variables = {
  input: MessagesFromHistoryInput;
};
export type clientMessagesFromHistory_Query$data = {
  readonly messagesFromHistory: {
    readonly channels: ReadonlyArray<{
      readonly actions: any | null;
      readonly channel: string;
      readonly message: {
        readonly actions: any | null;
        readonly attachments: any | null;
        readonly category: string;
        readonly content: string;
        readonly id: string;
        readonly isHiddenByServer: boolean;
        readonly messageType: string;
        readonly metadata: any | null;
        readonly reactions: ReadonlyArray<{
          readonly count: number;
          readonly emoji: {
            readonly id: string;
            readonly name: string;
          };
          readonly me: boolean;
        } | null>;
        readonly sender: {
          readonly avatarUrl: string;
          readonly displayName: string;
          readonly id: string;
        };
        readonly senderV2: {
          readonly avatar: string;
          readonly color: string | null;
          readonly id: string;
          readonly name: string;
          readonly senderId: string;
        };
        readonly sentAt: string;
        readonly version: string;
      };
      readonly meta: any | null;
      readonly timetoken: string;
    }>;
  };
};
export type clientMessagesFromHistory_Query = {
  response: clientMessagesFromHistory_Query$data;
  variables: clientMessagesFromHistory_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MessagesFromHistoryResponse",
    "kind": "LinkedField",
    "name": "messagesFromHistory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PubnubChannel",
        "kind": "LinkedField",
        "name": "channels",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "channel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PubnubMessage",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadata",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attachments",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "messageType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sentAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isHiddenByServer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "sender",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatarUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageSender",
                "kind": "LinkedField",
                "name": "senderV2",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "senderId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "color",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReactionAggregate",
                "kind": "LinkedField",
                "name": "reactions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "me",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Emoji",
                    "kind": "LinkedField",
                    "name": "emoji",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timetoken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meta",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clientMessagesFromHistory_Query",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clientMessagesFromHistory_Query",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "6b42c921337bb4a7b9d3ac6ef61ad105",
    "id": null,
    "metadata": {},
    "name": "clientMessagesFromHistory_Query",
    "operationKind": "query",
    "text": "query clientMessagesFromHistory_Query(\n  $input: MessagesFromHistoryInput!\n) {\n  messagesFromHistory(input: $input) {\n    channels {\n      channel\n      message {\n        actions\n        metadata\n        attachments\n        category\n        content\n        id\n        messageType\n        sentAt\n        version\n        isHiddenByServer\n        sender {\n          id\n          avatarUrl\n          displayName\n        }\n        senderV2 {\n          id\n          senderId\n          avatar\n          name\n          color\n        }\n        reactions {\n          me\n          count\n          emoji {\n            name\n            id\n          }\n        }\n      }\n      timetoken\n      meta\n      actions\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1bdccbb49053dce9c50d1b2be43adcce";

export default node;
