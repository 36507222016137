import { IonAvatar } from "@ionic/react";
import classnames from "classnames";
import React, { HTMLAttributes } from "react";

import defaultAvatarNoBg from "assets/images/defaults/avatar_no_background.svg";
import generateRandomColor from "common/utils/generateRandomColor";
import { getImageUrlWithSize } from "common/utils/image";

import Image from "common/components/Image";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type RenderMode = "character" | "image" | "custom";

type RenderConfig = {
  renderMode: RenderMode;
  avatarToUse: string;
};

export type Props = HTMLAttributes<HTMLIonAvatarElement> & {
  allowRandomBgColor?: boolean;
  avatarUrl: string;
  avatarStyle?: "circle" | "square";
  dataTestId?: string;
  name?: string;
  size?: number;
  className?: string;
  textColor?: IonicThemeShade;
  avatarContent?: React.ReactNode;
  useOriginalImageSize?: boolean;
};

type State = {
  imageErrored: boolean;
};

export const DEFAULT_AVATAR_SIZE_PX = 24;

/*
  Our Base UIAvatar component
  - Other avatars that extend this functionality include:
    - UIAvatarConnected
      - Used when wanting ability to click on avatar and view things like a profile
    - UIAvatarWithPresence
      - Used within UIAvatarConnected to add online status indicator
    - UIAvatarWithIndicator
      - Used to display a notification indicator. Can also use the wrapper if needing custom child content
    - UISplitAvatar
      - Used when wanting to render multiple images per avatar such as for group dms
*/
export default class UIAvatar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      imageErrored: false,
    };
  }

  onImageError = (): void => {
    this.setState({ imageErrored: true });
  };

  getRenderConfig = (): RenderConfig => {
    const { avatarUrl, name, useOriginalImageSize, size = DEFAULT_AVATAR_SIZE_PX } = this.props;
    const { imageErrored } = this.state;

    const fallbackAvatar = defaultAvatarNoBg;
    const firstLetter = name && name.length ? name[0].toUpperCase() : "";
    const hasAvatar = !!avatarUrl;
    const hasLetter = !!firstLetter;

    const avatarToUse = "";

    // If we have some custom avatarContent defined, use that
    if (this.props.avatarContent) {
      return {
        renderMode: "custom",
        avatarToUse,
      };
    }

    // Next, check if the image has errored. If so, default to the name if possible.
    // If not, then we render a fallback.
    if (imageErrored) {
      if (firstLetter) {
        return {
          renderMode: "character",
          avatarToUse,
        };
      }

      return {
        renderMode: "image",
        avatarToUse: fallbackAvatar,
      };
    }

    // Next, if no image has errored yet, check if we have an avatarUrl set.
    if (hasAvatar) {
      const url = useOriginalImageSize ? avatarUrl : getImageUrlWithSize(avatarUrl, size);

      return {
        renderMode: "image",
        avatarToUse: url,
      };
    }

    // Next, check if there is at least a letter.
    if (hasLetter) {
      return {
        renderMode: "character",
        avatarToUse,
      };
    }

    // If nothing could be matched, we default to a fallback.
    return {
      renderMode: "image",
      avatarToUse: fallbackAvatar,
    };
  };

  renderImage = (src: string): React.ReactNode => {
    const { size = DEFAULT_AVATAR_SIZE_PX } = this.props;

    return (
      <Image
        loading="lazy"
        style={{
          width: `${size}px`,
          height: `${size}px`,
          fontSize: `${size}px`,
        }}
        alt=""
        src={src}
        onError={this.onImageError}
      />
    );
  };

  render = (): React.ReactNode => {
    const {
      size = DEFAULT_AVATAR_SIZE_PX,
      className,
      name,
      textColor = "white",
      avatarStyle = "circle",
      dataTestId,
      ...remainingProps
    } = this.props;

    const useSquareStyle = avatarStyle === "square";
    const firstLetter = name && name.length ? name[0].toUpperCase() : "";

    const renderConfig = this.getRenderConfig();
    const { renderMode, avatarToUse } = renderConfig;
    const renderAsLetter = renderMode === "character";
    const renderWithImage = renderMode === "image";
    const renderAsCustom = renderMode === "custom";
    const bgColor = this.props.allowRandomBgColor ? generateRandomColor(name) : "";

    return (
      <IonAvatar
        {...remainingProps}
        className={classnames(styles.root, className, {
          [styles.renderText]: renderAsLetter,
          [styles.usingDefaultAvatar]: avatarToUse === defaultAvatarNoBg,
          [styles.square]: useSquareStyle,
        })}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dataTestId={dataTestId}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          fontSize: `${size}px`,
        }}
      >
        {renderAsCustom && this.props.avatarContent && (
          <div
            className={classnames(styles.customAvatarContent, {
              [styles.square]: useSquareStyle,
            })}
          >
            {this.props.avatarContent}
          </div>
        )}

        {renderWithImage && this.renderImage(avatarToUse)}

        {renderAsLetter && (
          <div
            className={classnames(styles.noAvatar, {
              [styles.square]: useSquareStyle,
            })}
            style={{
              width: `${size}px`,
              height: `${size}px`,
              fontSize: `${size}px`,
              backgroundColor: bgColor,
            }}
          >
            <UIText color={textColor} weight="bold" className={styles.letter}>
              {firstLetter}
            </UIText>
          </div>
        )}
      </IonAvatar>
    );
  };
}
