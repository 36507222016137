/**
 * @generated SignedSource<<d7f203076fbb985ba21167b12a3cf238>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportUserInput = {
  context?: string | null;
  customReportReason: string;
  reason: number;
  userId: string;
};
export type ReportUserMutation$variables = {
  input: ReportUserInput;
};
export type ReportUserMutation$data = {
  readonly reportUser: {
    readonly success: boolean;
  };
};
export type ReportUserMutation = {
  response: ReportUserMutation$data;
  variables: ReportUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ReportUserResponse",
    "kind": "LinkedField",
    "name": "reportUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a759d856185b20e6c55de52542966a0",
    "id": null,
    "metadata": {},
    "name": "ReportUserMutation",
    "operationKind": "mutation",
    "text": "mutation ReportUserMutation(\n  $input: ReportUserInput!\n) {\n  reportUser(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fef5433321418cddbcf5c983c961ea7";

export default node;
