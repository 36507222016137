import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ClubsPageSlideLayout, {
  ExposedProps as LayoutExposedProps,
} from "pages/Clubs/providers/ClubsPageViewProvider/components/ClubsPageSlideLayout/layout";
import {
  CHANNEL_TYPE_SLIDE,
  CLUB_CHANNEL_SLIDE,
  CLUB_SLIDE,
  CLUB_TYPE_SLIDE,
  ClubChannelSlideInfo,
  ClubSlideInfo,
} from "pages/Clubs/providers/ClubsPageViewProvider/slides";

import routeToClubChannel from "common/utils/routing/routeToClubChannel";
import routeToClubFeed from "common/utils/routing/routeToClubFeed";
import { CLUBS_FEED_ROUTE, CLUBS_ROUTE } from "constants/routes";

export type ExposedProps = {
  showClubChannel: (clubId: string, channelId: string) => void;
  showMeetupsForClub: (clubId: string) => void;
  activeView: string | null;
  hideAllViews: () => void;
  hideAllViewsExceptActive: () => void;
  enableAnimations: () => void;
  disableAnimations: () => void;
};

type Props = {
  children: React.ReactNode;
  viewLayoutControls: LayoutExposedProps;
  setViewAnimations: (isAnimated: boolean) => void;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  showClubChannel: () => undefined,
  showMeetupsForClub: () => undefined,
  activeView: null,
  hideAllViews: () => undefined,
  hideAllViewsExceptActive: () => undefined,
  enableAnimations: () => undefined,
  disableAnimations: () => undefined,
};

export const ClubsPageViewContext = React.createContext(INITIAL_CONTEXT);

const decodeClubAndChannelFromSlideId = (
  slideId: string,
): ClubSlideInfo | ClubChannelSlideInfo | null => {
  if (CLUB_SLIDE.isMatch(slideId)) {
    return CLUB_SLIDE.decodeSlideId(slideId);
  }

  if (CLUB_CHANNEL_SLIDE.isMatch(slideId)) {
    return CLUB_CHANNEL_SLIDE.decodeSlideId(slideId);
  }

  return null;
};

class ClubsPageViewProvider extends React.Component<Props, State> {
  showClubChannel = (clubId: string, channelId: string): void => {
    const slideId = CLUB_CHANNEL_SLIDE.encodeSlideId(clubId, channelId);

    const viewToAdd = {
      pageType: "channel-chat" as const,
      slideId,
      channelId,
      clubId,
    };

    this.props.viewLayoutControls.showDynamicView(viewToAdd);
  };

  showMeetupsForClub = (clubId: string): void => {
    const slideId = CLUB_SLIDE.encodeSlideId(clubId, CLUBS_FEED_ROUTE);

    const viewToAdd = {
      pageType: "club-feed" as const,
      slideId,
      clubId,
    };

    this.props.viewLayoutControls.showDynamicView(viewToAdd);
  };

  enableAnimations = (): void => {
    this.props.setViewAnimations(true);
  };

  disableAnimations = (): void => {
    this.props.setViewAnimations(false);
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <ClubsPageViewContext.Provider
        value={{
          showClubChannel: this.showClubChannel,
          showMeetupsForClub: this.showMeetupsForClub,
          activeView: this.props.viewLayoutControls.activeView,
          hideAllViews: this.props.viewLayoutControls.hideAllViews,
          hideAllViewsExceptActive: this.props.viewLayoutControls.hideAllViewsExceptActive,
          enableAnimations: this.enableAnimations,
          disableAnimations: this.disableAnimations,
        }}
      >
        {children}
      </ClubsPageViewContext.Provider>
    );
  };
}

const WithSlideLayout = (
  props: Omit<Props, "viewLayoutControls" | "setViewAnimations">,
): React.ReactElement => {
  const [isAnimated, setIsAnimated] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const routeProps = {
    history,
    location,
  };

  const updateUrlBasedOnActiveSlide = (activeSlideId: string | null) => {
    if (!activeSlideId) {
      history.replace(`/${CLUBS_ROUTE}`);
    } else {
      const decodedSlideInfo = decodeClubAndChannelFromSlideId(activeSlideId);

      if (decodedSlideInfo && decodedSlideInfo.type === CLUB_TYPE_SLIDE) {
        const clubDecodedInfo = decodedSlideInfo as ClubSlideInfo;
        const { clubId, clubSubRoute } = clubDecodedInfo;

        if (clubSubRoute === CLUBS_FEED_ROUTE) {
          routeToClubFeed(routeProps, clubId, true, true);
        }
      } else if (decodedSlideInfo && decodedSlideInfo.type === CHANNEL_TYPE_SLIDE) {
        const clubChannelDecodedInfo = decodedSlideInfo as ClubChannelSlideInfo;
        const { clubId, channelId } = clubChannelDecodedInfo;

        if (location.search) {
          routeToClubChannel(routeProps, clubId, channelId, true, true);
        } else {
          routeToClubChannel(routeProps, clubId, channelId, true);
        }
      }
    }
  };

  return (
    <ClubsPageSlideLayout
      isAnimated={isAnimated}
      updateUrlBasedOnActiveSlide={updateUrlBasedOnActiveSlide}
    >
      {(viewLayoutControls: LayoutExposedProps) => (
        <ClubsPageViewProvider
          {...props}
          setViewAnimations={setIsAnimated}
          viewLayoutControls={viewLayoutControls}
        >
          {props.children}
        </ClubsPageViewProvider>
      )}
    </ClubsPageSlideLayout>
  );
};

export { WithSlideLayout as ClubsPageViewProvider };
export const ClubsPageViewConsumer = ClubsPageViewContext.Consumer;
