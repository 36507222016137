import React from "react";

import AppInterop from "common/utils/webInterop/app";

import styles from "./styles.scss";

export type Props = {
  children: React.ReactNode;
};

type State = {};

export default class PGOCampfireLayout extends React.PureComponent<Props, State> {
  handleClickExitEmbed = (): void => {
    AppInterop.logout();
  };

  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <div
          role="presentation"
          className={styles.exitEmbedClickableDiv}
          onClick={this.handleClickExitEmbed}
        />
        <div className={styles.fakeMask} />
        <div className={styles.fakeDrawer}>
          <div className={styles.content}>{this.props.children}</div>
        </div>
      </div>
    );
  };
}
