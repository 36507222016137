export const USER_NIANTIC_ID_MIN_LENGTH = 3;
export const USER_NIANTIC_ID_MAX_LENGTH = 15;
export const UNCLAIMED_USERNAME = "||unclaimed";

export enum GameProfileVisibility {
  EVERYONE = "EVERYONE",
  FRIENDS = "FRIENDS",
  PRIVATE = "PRIVATE",
}

export const GAME_PROFILE_VISIBILITY_TO_TRANSLATION_KEY = {
  [GameProfileVisibility.EVERYONE]: "EVERYONE",
  [GameProfileVisibility.FRIENDS]: "FRIENDS",
  [GameProfileVisibility.PRIVATE]: "ONLY_ME",
};

export enum UserAgeGroup {
  ADULT = "adult",
  TEEN = "teen",
  UNKNOWN = "unknown",
}
