/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { AnalyticsConsumer, ExposedProps } from "providers/AnalyticsProvider";

export type WithAnalyticsProps = {
  analyticsProvider: ExposedProps;
};

// eslint-disable-next-line max-len
export default <P extends WithAnalyticsProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithAnalyticsProps>): React.ReactElement => (
    <AnalyticsConsumer>
      {(exposedProps: ExposedProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component analyticsProvider={exposedProps} {...props} />
      )}
    </AnalyticsConsumer>
  );
};
