import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import CampfireLogo from "assets/images/campfire/campfire-logo.png";

import Image from "common/components/Image";
import OnboardElementSubmitButton from "common/components/Onboarding/components/OnboardElementSubmitButton";
import UIDialog from "common/components/UIDialog";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  isOpen: boolean;
  onSubmit: () => void;
};

type State = {};

class OnboardCoachmarkShowMeAround extends React.Component<Props, State> {
  onSubmit = async (): Promise<void> => {
    this.props.onSubmit();
  };

  render = (): React.ReactNode => {
    return (
      <UIDialog
        type="floating-center"
        isOpen={this.props.isOpen}
        close={this.onSubmit}
        showBackdrop={false}
      >
        <div className={styles.root}>
          <div className={styles.innerContainer}>
            <div className={styles.contentContainer}>
              <Image src={CampfireLogo} className={styles.logo} />
              <UISpacer h={24} />
              <UIText variant="h2" weight="bold" color="dark">
                {this.props.t("WELCOME_TO_CAMPFIRE_ONBOARDING")}
              </UIText>
              <UISpacer h={12} />
              <UIText variant="h4" weight="medium" color="dark">
                {this.props.t("CAMPFIRE_IS_THE_PLACE_TO_DISCOVER")}
              </UIText>
              <UISpacer h={24} />
            </div>
            <OnboardElementSubmitButton
              text={this.props.t("SHOW_ME_AROUND").toLocaleUpperCase()}
              onClick={this.onSubmit}
            />
          </div>
        </div>
      </UIDialog>
    );
  };
}

export default withTranslation()(OnboardCoachmarkShowMeAround);
