import environment from "common/relay/relay-env";
import { commitLocalUpdate } from "react-relay";
import { StoreUpdater } from "relay-runtime";
import { RecordSourceProxy } from "relay-runtime/lib/store/RelayStoreTypes";

const deletePgoBeacon = (dropId: string, beaconId: string): StoreUpdater => {
  return (store: RecordSourceProxy) => {
    try {
      // Get the mapObject by drop id
      const mapObjectInStore = store.get(dropId);

      if (mapObjectInStore) {
        // Get the corresponding pgoGym field
        const pgoGymInStore = mapObjectInStore.getLinkedRecord("pgoGym");

        if (pgoGymInStore) {
          const beacons = pgoGymInStore.getLinkedRecords("beacons") || [];

          const filteredBeacons = beacons.filter((beacon) => beacon.getDataID() !== beaconId);

          // Update the pgoGym.beacons
          pgoGymInStore.setLinkedRecords(filteredBeacons, "beacons");

          // Remove the PGOBeacon entity
          store.delete(beaconId);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export default (dropId: string, beaconId: string): void => {
  commitLocalUpdate(environment, deletePgoBeacon(dropId, beaconId));
};
