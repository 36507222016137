import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { graphql, QueryRenderer } from "react-relay";

import LoadingPageDefault from "common/components/LoadingPageDefault";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import { Internal_page_Query$data as InternalPageQueryResponse } from "__generated__/Internal_page_Query.graphql";

import styles from "./styles.scss";

// Lazy Load Main View
const InternalRouter = React.lazy(() => import("pages/Internal/components/InternalRouter"));

type Props = {};

type State = {
  initializedAt: number;
};

type QRProps = {
  me: InternalPageQueryResponse["me"];
};

const INTERNAL_PAGE = graphql`
  query Internal_page_Query {
    me {
      id
      ...InternalRouter_me
    }
  }
`;

class InternalPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedAt: Date.now(),
    };
  }

  reInit = (): void => {
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  render = (): React.ReactNode => (
    <div className={styles.root}>
      <UIErrorBoundary onRetry={this.onRetry}>
        <Suspense fallback={<LoadingPageDefault />}>
          <QueryRenderer
            key={this.state.initializedAt}
            environment={environment}
            query={INTERNAL_PAGE}
            variables={{}}
            render={({ props }) => {
              const qrProps = props as QRProps;

              if (!qrProps) {
                return <LoadingPageDefault />;
              }

              return <InternalRouter me={qrProps.me} />;
            }}
          />
        </Suspense>
      </UIErrorBoundary>
    </div>
  );
}

export default InternalPage;
