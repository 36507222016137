/**
 * @generated SignedSource<<bc37a70df94d5713bf56a8c755614c44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResetAccountData_me$data = {
  readonly resetData: {
    readonly nianticId: {
      readonly isAbuse: boolean;
    } | null;
  } | null;
  readonly username: string;
  readonly " $fragmentType": "ResetAccountData_me";
};
export type ResetAccountData_me$key = {
  readonly " $data"?: ResetAccountData_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResetAccountData_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResetAccountData_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserResetData",
      "kind": "LinkedField",
      "name": "resetData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResetProperties",
          "kind": "LinkedField",
          "name": "nianticId",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isAbuse",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1cf4aab95bea9401d539f167924ff368";

export default node;
