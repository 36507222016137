import { IonHeader, IonToolbar, IonButtons, IonTitle } from "@ionic/react";
import classnames from "classnames";
import React from "react";

import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = {
  title?: string;
  className?: string;
  flat?: boolean;
  respectSafeArea?: boolean;
  renderTitle?: () => React.ReactNode;
  renderStart?: () => React.ReactNode;
  renderEnd?: () => React.ReactNode;
};

type State = {};

export default class UIHeader extends React.Component<Props, State> {
  renderTitle = (): React.ReactNode => {
    if (this.props.renderTitle) {
      return this.props.renderTitle();
    }

    if (this.props.title) {
      return (
        <UIText variant="h3" color="dark" weight="bold">
          {this.props.title}
        </UIText>
      );
    }

    return null;
  };

  renderStart = (): React.ReactNode => {
    if (this.props.renderStart) {
      return this.props.renderStart();
    }

    return null;
  };

  render = (): React.ReactNode => {
    const { respectSafeArea = true, flat, className } = this.props;

    const titleContent = this.renderTitle();

    return (
      <IonHeader
        className={classnames(styles.root, className, {
          [styles.flat]: flat,
        })}
      >
        <IonToolbar
          className={classnames(styles.toolbar, {
            [styles.overrideDefaultSafeArea]: !respectSafeArea,
          })}
        >
          <IonButtons slot="start">{this.renderStart()}</IonButtons>

          <IonTitle>{titleContent}</IonTitle>

          {this.props.renderEnd && <IonButtons slot="end">{this.props.renderEnd()}</IonButtons>}
        </IonToolbar>
      </IonHeader>
    );
  };
}
