import React, { useEffect, useState } from "react";

import ResetAccountDataInputError from "common/components/ResetAccountData/components/ResetAccountDataInputError/index";
import { InputErrorType } from "common/components/ResetAccountData/resetAccountDataErrors";

type Returns = [() => React.ReactElement, (errors: number[]) => void];

const useResetAccountDataInputError = (errorsRef: number[], type: InputErrorType): Returns => {
  const [errors, setErrors] = useState(errorsRef);

  useEffect(() => {
    setErrors(errorsRef);
  }, [errorsRef]);

  return [
    () => <ResetAccountDataInputError errors={errors} type={type} />,
    (errs: number[]) => {
      setErrors(errs);
    },
  ];
};

export default useResetAccountDataInputError;
