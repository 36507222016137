/* eslint-disable */
import { AxiosRequestConfig } from "axios";
import * as Sentry from "@sentry/react";

import authAxios from "common/api/axios-instance";
import { CSRF_TOKEN_HEADER, AUTHORIZATION_HEADER } from "common/api/request-headers";

// Add a request interceptor to append the CSRF-TOKEN and AUTHORIZATION Header with each request
authAxios.interceptors.request.use(function (config: AxiosRequestConfig) {
  const csrfToken = localStorage.getItem("csrfToken");
  const sessionToken = localStorage.getItem("sessionToken");

  // Guess the new axios has updated the types here.
  if (!config.headers) {
    config.headers = {};
  }

  // Append the csrfToken if we have one
  if (csrfToken) {
    config.headers[CSRF_TOKEN_HEADER] = csrfToken;
  }

  // Append the sessionToken if we have one, it's a bearer token
  if (sessionToken) {
    config.headers[AUTHORIZATION_HEADER] = `Bearer ${sessionToken}`;
  }

  return config;
});

// Intercept 401 responses to clear user session and reset gracefully.
authAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Clear tokens on unauthorized responses
    if (error.response && error.response.status && error.response.status === 401) {
      localStorage.removeItem("csrfToken");
      localStorage.removeItem("sessionToken");

      // When the user is no longer authenticated, also clear sentry configs
      Sentry.configureScope((scope) => scope.clear());
    }

    return Promise.reject(error);
  },
);
