/**
 * @generated SignedSource<<6c9789f7720ec2ad70687b81f0563f6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateMessageRequestInput = {
  recipientId: string;
};
export type CreateMessageRequestMutation$variables = {
  input: CreateMessageRequestInput;
};
export type CreateMessageRequestMutation$data = {
  readonly createMessageRequest: {
    readonly messageRequest: {
      readonly channel: {
        readonly id: string;
      };
      readonly id: string;
      readonly status: string;
    };
  };
};
export type CreateMessageRequestMutation = {
  response: CreateMessageRequestMutation$data;
  variables: CreateMessageRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMessageRequestResponse",
    "kind": "LinkedField",
    "name": "createMessageRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageRequest",
        "kind": "LinkedField",
        "name": "messageRequest",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Channel",
            "kind": "LinkedField",
            "name": "channel",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMessageRequestMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMessageRequestMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1a907a764a78921f53a5e57d3dd50cce",
    "id": null,
    "metadata": {},
    "name": "CreateMessageRequestMutation",
    "operationKind": "mutation",
    "text": "mutation CreateMessageRequestMutation(\n  $input: CreateMessageRequestInput!\n) {\n  createMessageRequest(input: $input) {\n    messageRequest {\n      id\n      channel {\n        id\n      }\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85a65ce82820b5539e5fb796064f0dda";

export default node;
