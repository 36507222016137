export const capitalizeFirstLetter = (str: string): string => {
  return str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

/**
 * Don't use this! Instead, use CSS text-transform: uppercase, which will handle non-English languages better.
 * TODO: deprecate all other uses of capitalizeEachWord and replace with text-transform.
 */
export const capitalizeEachWord = (str: string): string => {
  let words = str.split(" ");

  words = words.map((w) => capitalizeFirstLetter(w));
  return words.join(" ");
};
