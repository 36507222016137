import { ChannelSlideConfig } from "pages/Clubs/providers/ClubsPageViewProvider/slides";

import { ExposedProps as UINavExposedProps } from "common/components/UINav";

export type ViewControlReturnType = {
  hideView: () => void;
};

type Props = {
  addDynamicSlide: (slideConfig: ChannelSlideConfig) => void;
};

export type HookReturnType = {
  showDynamicViewCore: (
    slideConfig: ChannelSlideConfig,
    uiNavProps: UINavExposedProps,
  ) => ViewControlReturnType;
  hideViewCore: (slideId: string, uiNavProps: UINavExposedProps) => void;
};

function useDynamicSlidePresenter(props: Props): HookReturnType {
  const showDynamicViewCore = (
    slideConfig: ChannelSlideConfig,
    uiNavProps: UINavExposedProps,
  ): ViewControlReturnType => {
    // Add this slide to the dynamic slides.
    props.addDynamicSlide(slideConfig);

    // Since hooks suck, this is the lazy way of opening this view.
    setTimeout(() => {
      // Show the slide or bring it to the front.
      uiNavProps.showSlide(slideConfig.slideId);
    });

    return {
      hideView: () => {
        uiNavProps.hideSlide(slideConfig.slideId);
      },
    };
  };

  const hideViewCore = (slideId: string, uiNavProps: UINavExposedProps): void => {
    return uiNavProps.hideSlide(slideId);
  };

  return {
    showDynamicViewCore,
    hideViewCore,
  };
}

export default useDynamicSlidePresenter;
