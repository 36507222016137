export type ReportReason = {
  name: string;
  value: number;
  description: string;
  longDescription: string;
};

// Correspond to the enum defined here:
// https://gitlab.nianticlabs.com/crm/crm/-/blob/main/moderation-shared/src/main/proto/moderation_common.proto#L18-35

export enum ReportReasonValue {
  REPORT_REASON_NAME_THREAT = 100,
  REPORT_REASON_NAME_SELF_HARM = 101,
  REPORT_REASON_NAME_NUDITY = 102,
  REPORT_REASON_NAME_VIOLENCE = 103,
  REPORT_REASON_NAME_DRUGS = 104,
  REPORT_REASON_NAME_CHILD_SAFETY = 105,
  REPORT_REASON_NAME_HATE_SPEECH = 201,
  REPORT_REASON_NAME_REVEALING_PII = 202,
  REPORT_REASON_NAME_IP_VIOLATION = 204,
  REPORT_REASON_NAME_HACKING = 205,
  REPORT_REASON_NAME_OTHER = 302,
}

const GENERIC_CONTENT_DESCRIPTION = "REPORT_REASON_LONG_CONTENT";
const ENDANGERMENT_DESCRIPTION = "REPORT_REASON_LONG_ENDANGERMENT";

const THREAT = {
  name: "REPORT_REASON_NAME_THREAT",
  value: ReportReasonValue.REPORT_REASON_NAME_THREAT,
  description: "REPORT_REASON_DESCRIPTION_THREAT",
  longDescription: "REPORT_REASON_LONG_THREAT",
};

const SELF_HARM = {
  name: "REPORT_REASON_NAME_SELF_HARM",
  value: ReportReasonValue.REPORT_REASON_NAME_SELF_HARM,
  description: "REPORT_REASON_DESCRIPTION_SELF_HARM",
  longDescription: "REPORT_REASON_LONG_SELF_HARM",
};

const NUDITY = {
  name: "REPORT_REASON_NAME_NUDITY",
  value: ReportReasonValue.REPORT_REASON_NAME_NUDITY,
  description: "REPORT_REASON_DESCRIPTION_NUDITY",
  longDescription: GENERIC_CONTENT_DESCRIPTION,
};

const VIOLENCE = {
  name: "REPORT_REASON_NAME_VIOLENCE",
  value: ReportReasonValue.REPORT_REASON_NAME_VIOLENCE,
  description: "REPORT_REASON_DESCRIPTION_VIOLENCE",
  longDescription: GENERIC_CONTENT_DESCRIPTION,
};

const DRUGS = {
  name: "REPORT_REASON_NAME_DRUGS",
  value: ReportReasonValue.REPORT_REASON_NAME_DRUGS,
  description: "REPORT_REASON_DESCRIPTION_DRUGS",
  longDescription: ENDANGERMENT_DESCRIPTION,
};

const CHILD_SAFETY = {
  name: "REPORT_REASON_NAME_CHILD_SAFETY",
  value: ReportReasonValue.REPORT_REASON_NAME_CHILD_SAFETY,
  description: "REPORT_REASON_DESCRIPTION_CHILD_SAFETY",
  longDescription: "REPORT_REASON_LONG_CHILD_SAFETY",
};

const HATE_SPEECH = {
  name: "REPORT_REASON_NAME_HATE_SPEECH",
  value: ReportReasonValue.REPORT_REASON_NAME_HATE_SPEECH,
  description: "REPORT_REASON_DESCRIPTION_HATE_SPEECH",
  longDescription: GENERIC_CONTENT_DESCRIPTION,
};

const REVEALING_PERSONAL_INFORMATION = {
  name: "REPORT_REASON_NAME_REVEALING_PII",
  value: ReportReasonValue.REPORT_REASON_NAME_REVEALING_PII,
  description: "REPORT_REASON_DESCRIPTION_REVEALING_PII",
  longDescription: ENDANGERMENT_DESCRIPTION,
};

const IP_VIOLATION = {
  name: "REPORT_REASON_NAME_IP_VIOLATION",
  value: ReportReasonValue.REPORT_REASON_NAME_IP_VIOLATION,
  description: "REPORT_REASON_DESCRIPTION_IP_VIOLATION",
  longDescription: "REPORT_REASON_LONG_IP_VIOLATION",
};

const HACKING = {
  name: "REPORT_REASON_NAME_HACKING",
  value: ReportReasonValue.REPORT_REASON_NAME_HACKING,
  description: "REPORT_REASON_DESCRIPTION_HACKING",
  longDescription: "REPORT_REASON_LONG_HACKING",
};

const OTHER = {
  name: "REPORT_REASON_NAME_OTHER",
  value: ReportReasonValue.REPORT_REASON_NAME_OTHER,
  description: "",
  longDescription: ENDANGERMENT_DESCRIPTION,
};

const OTHER_EVENT = {
  name: "REPORT_REASON_NAME_OTHER",
  value: ReportReasonValue.REPORT_REASON_NAME_OTHER,
  description: "",
  longDescription: "REPORT_REASON_LONG_OTHER_EVENT",
};

export const ALL_REPORT_REASONS: ReportReason[] = [
  THREAT,
  HATE_SPEECH,
  NUDITY,
  VIOLENCE,
  SELF_HARM,
  CHILD_SAFETY,
  REVEALING_PERSONAL_INFORMATION,
  HACKING,
  DRUGS,
  IP_VIOLATION,
  OTHER,
];

export const TEXT_REPORT_REASONS: ReportReason[] = [
  THREAT,
  HATE_SPEECH,
  NUDITY,
  VIOLENCE,
  SELF_HARM,
  CHILD_SAFETY,
  REVEALING_PERSONAL_INFORMATION,
  HACKING,
  DRUGS,
  IP_VIOLATION,
  OTHER,
];

export const IMAGE_REPORT_REASONS: ReportReason[] = ALL_REPORT_REASONS;

// Almost exactly the same as ALL, but we want custom longDescription for OTHER
export const EVENT_REPORT_REASONS: ReportReason[] = [
  THREAT,
  HATE_SPEECH,
  NUDITY,
  VIOLENCE,
  SELF_HARM,
  CHILD_SAFETY,
  REVEALING_PERSONAL_INFORMATION,
  HACKING,
  DRUGS,
  IP_VIOLATION,
  OTHER_EVENT,
];
