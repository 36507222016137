import environment from "common/relay/relay-env";
import React from "react";
import { graphql, QueryRenderer } from "react-relay";

import ReportContext from "constants/reportContext";

import ClubUserProfileModerationActionMenu from "common/components/ClubUserProfileModerationActionMenu";
import UIErrorBoundary from "common/components/UIErrorBoundary";
import UserProfileFragment from "common/components/UserProfileFragment";
import UserProfileLoadingView from "common/components/UserProfileLoadingView";

import { ClubUserProfileFetcher_Query$data as ClubUserProfileFetcherQueryResponse } from "__generated__/ClubUserProfileFetcher_Query.graphql";

type Props = {
  userId: string;
  clubId: string;
  close: () => void;
};

type State = {};

type QRProps = {
  query: ClubUserProfileFetcherQueryResponse;
  me: ClubUserProfileFetcherQueryResponse["me"];
  userById: ClubUserProfileFetcherQueryResponse["userById"];
  club: ClubUserProfileFetcherQueryResponse["club"];
};

const USER_PROFILE_QUERY = graphql`
  query ClubUserProfileFetcher_Query($userId: ID!, $clubId: ID!) {
    ...UserProfileFragment_query
    me {
      ...UserProfileFragment_me
      ...ClubUserProfileModerationActionMenu_me
    }
    userById(id: $userId) {
      ...UserProfileFragment_user
      ...ClubUserProfileModerationActionMenu_user @arguments(clubId: $clubId)
    }
    club(id: $clubId) {
      ...ClubUserProfileModerationActionMenu_club
    }
  }
`;

export default class ClubUserProfileFetcher extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <UIErrorBoundary>
        <QueryRenderer
          environment={environment}
          query={USER_PROFILE_QUERY}
          variables={{
            userId: this.props.userId,
            clubId: this.props.clubId,
          }}
          render={({ props, error }) => {
            const qrProps = props as QRProps;
            const isError = !!error;
            const isLoading = !isError && !qrProps;

            if (isLoading || isError) {
              return <UserProfileLoadingView isError={isError} close={this.props.close} />;
            }

            return (
              <UserProfileFragment
                query={qrProps as unknown as ClubUserProfileFetcherQueryResponse}
                me={qrProps.me}
                user={qrProps.userById}
                close={this.props.close}
                reportContext={ReportContext.CLUB_MEMBERS}
              >
                <ClubUserProfileModerationActionMenu
                  me={qrProps.me}
                  user={qrProps.userById}
                  club={qrProps.club}
                />
              </UserProfileFragment>
            );
          }}
        />
      </UIErrorBoundary>
    );
  };
}
