import { invokeRemoteAsync } from "common/utils/webInterop/plugins";

import defaultWrite, { validator as writeValidator } from "./write";

export default {
  write: invokeRemoteAsync<typeof defaultWrite, "Clipboard">(
    defaultWrite,
    writeValidator,
    "Clipboard",
    "write",
  ),
};
