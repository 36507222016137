/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

type Key = string;

const DEFAULTS = {
  disableServerCoachmarks: false,
  disableTeamUpEligibilityChecks: false,
};

class QATestingStore extends BaseStore {
  constructor() {
    super("QATestingStore", DEFAULTS, false);
  }

  get(key: Key): any {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, value: any): GenericObject {
    return super.set(key, value);
  }
}

export default new QATestingStore();
