/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { AuthConsumer, ExposedProps } from "providers/AuthProvider";

export type WithAuthProps = {
  authProvider: ExposedProps;
};

// eslint-disable-next-line max-len
export default <P extends WithAuthProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithAuthProps>): React.ReactElement => (
    <AuthConsumer>
      {(exposedProps: ExposedProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component authProvider={exposedProps} {...props} />
      )}
    </AuthConsumer>
  );
};
