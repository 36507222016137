import { Device } from "@capacitor/device";

import ajaxPath from "common/api/ajaxPath";
import { get } from "common/api/axios";
import { isIos } from "common/capacitor/helpers";
import { SharedLogin, SharedLoginToken } from "common/capacitor/plugins/shared-login";
import { getGameShortNameFromCode } from "common/utils/nianticGame";
import config from "constants/config";
import { GameShortCode } from "constants/nianticGame";

const SUPPORTED_SHARED_LOGIN_PROVIDERS: SupportedOAuthProvider[] = ["google", "facebook", "apple"];

export type SharedLoginValidationResponse = {
  validToken: boolean;
  garAccountId: string;
  hasCampfireAccount: boolean;
};

export type SharedLoginLoginResponse = {
  token: string;
};

const getDeviceId = async (): Promise<string> => {
  // For testing only! Due to how Apple determines "vendors" for apps,
  // We are mocking the device ID for all non-prod environments. This is also baked into
  // the shared login Unity library used by PGO.
  // See: https://developer.apple.com/documentation/uikit/uidevice/1620059-identifierforvendor
  const mockDeviceId = config.get("CAMPFIRE_APP_MOCK_DEVICE_ID");

  if (isIos && mockDeviceId) {
    return mockDeviceId;
  }

  return (await Device.getId()).uuid;
};

export const retrieveSharedLoginToken = async (): Promise<SharedLoginToken | null> => {
  const sharedLoginPluginResponse = await SharedLogin.getLoginToken({
    providerIds: [
      "com.nianticlabs.holoholo",
      "com.nianticlabs.holoholo.dev",
      "com.nianticlabs.pokemongo",
      "com.nianticlabs.pokemongo.qa",
      "com.nianticproject.holoholo.nightly",
      "com.nianticproject.holoholo.dogfood",
    ],
  });

  if (!sharedLoginPluginResponse.token) {
    return null;
  }

  const sharedLoginToken = JSON.parse(sharedLoginPluginResponse.token) as SharedLoginToken;

  return sharedLoginToken;
};

export const validateSharedLoginToken = async (
  token: SharedLoginToken,
): Promise<SharedLoginValidationResponse> => {
  const deviceId = await getDeviceId();
  // Validate supported provider
  const provider = token.AuthProviderId as SupportedOAuthProvider;

  if (!SUPPORTED_SHARED_LOGIN_PROVIDERS.includes(provider)) {
    return {
      validToken: false,
      garAccountId: "",
      hasCampfireAccount: false,
    };
  }

  const decodedToken = atob(token.RawLoginTokenBase64);

  // Only set the shared login token if the token is valid.
  const path = ajaxPath.get("sharedLoginValidate");
  const validationResponse = await get(`${path}?token=${decodedToken}&deviceId=${deviceId}`);

  return validationResponse as SharedLoginValidationResponse;
};

export const authenticateWithSharedLoginToken = async (
  token: SharedLoginToken,
): Promise<SharedLoginLoginResponse> => {
  const path = ajaxPath.get("sharedLoginLogin");
  const deviceId = await getDeviceId();

  const decodedToken = atob(token.RawLoginTokenBase64);

  const response = await get(
    `${path}?token=${decodedToken}&deviceId=${deviceId}&email=${token.AccountName}`,
  );

  return response;
};

export const getGameNameFromToken = (sharedLoginToken: SharedLoginToken): string => {
  const gameShortCode = sharedLoginToken.CreatedByAppKey.toUpperCase() as GameShortCode;

  return getGameShortNameFromCode(gameShortCode);
};
