import environment from "common/relay/relay-env";
import _ from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withAnalytics, { WithAnalyticsProps } from "providers/AnalyticsProvider/withAnalytics";
import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import logException from "common/analytics/exceptions";
import { getOnboardingEventPayload, OnboardingEvent } from "common/utils/telemetry/onboarding";
import {
  INVALID_USERNAME_ERR_CODE,
  NIANTIC_EDIT_DISPLAY_NAME_ABUSIVE_ERR_CODE,
  NIANTIC_EDIT_ID_UNAVAILABLE_ERR_CODE,
} from "constants/backendErrors";
import { USER_NIANTIC_ID_MAX_LENGTH, USER_NIANTIC_ID_MIN_LENGTH } from "constants/user";
import SetNianticIdMutation from "mutations/SetNianticIdMutation";

import OnboardNianticId, {
  NianticIDError,
} from "common/components/Onboarding/components/OnboardNianticId";

type Props = WithTranslation &
  WithToastProps &
  WithAnalyticsProps & {
    next: () => void;
  };

type State = {
  nianticId: string;
  error: NianticIDError | null;
};

class AccountSetupNianticIdSlide extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      nianticId: "",
      error: null,
    };
  }

  updateNianticId = (nianticId: string): void => {
    this.setState({ nianticId });
  };

  canProceedToNextStep = (): boolean => {
    const newNianticId = this.state.nianticId;

    const isNianticIdSet = !!newNianticId.length;
    const isNianticIdTooShort = newNianticId.length < USER_NIANTIC_ID_MIN_LENGTH;
    const isNianticIdTooLong = newNianticId.length > USER_NIANTIC_ID_MAX_LENGTH;

    return isNianticIdSet && !isNianticIdTooShort && !isNianticIdTooLong;
  };

  setUserNianticId = async (nianticId: string): Promise<boolean> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const payload = {
      nianticId,
    };

    // Set the user's niantic id!
    try {
      await SetNianticIdMutation.commit(environment, payload);
      return true;
    } catch (error) {
      const code = _.get(error, "extensions.code");

      if (code === INVALID_USERNAME_ERR_CODE) {
        this.setState({ error: NianticIDError.INVALID });
        return false;
      }

      if (code === NIANTIC_EDIT_DISPLAY_NAME_ABUSIVE_ERR_CODE) {
        this.props.toastProvider.showErrorToast(this.props.t("ERROR_INAPPROPRIATE_CONTENT_NAME"));
        return false;
      }

      if (code === NIANTIC_EDIT_ID_UNAVAILABLE_ERR_CODE) {
        this.setState({ error: NianticIDError.ID_EXISTS });
        return false;
      }

      this.props.toastProvider.showErrorToast(
        this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN"),
      );

      // Reduce sentry logs. Only log if it's not a known error.
      logException("setUserNianticId", "setUserNianticId", "AccountSetupNianticIdSlide", error);
    }

    return false;
  };

  next = async (): Promise<void> => {
    const canProceedToNextStep = this.canProceedToNextStep();

    if (canProceedToNextStep) {
      const success = await this.setUserNianticId(this.state.nianticId);

      if (!success) return;

      this.props.analyticsProvider.trackClientAction(
        "OnboardingEvent",
        getOnboardingEventPayload(OnboardingEvent.NIANTIC_ID),
      );
      this.props.next();
    }
  };

  render = (): React.ReactNode => {
    const canProceedToNextStep = this.canProceedToNextStep();

    return (
      <OnboardNianticId
        nianticId={this.state.nianticId}
        onNianticIdChange={this.updateNianticId}
        onSubmit={this.next}
        isSubmitButtonDisabled={!canProceedToNextStep}
        error={this.state.error}
      />
    );
  };
}

const AnalyticsConnected = withAnalytics(AccountSetupNianticIdSlide);
const ToastConnected = withToast(AnalyticsConnected);

export default withTranslation()(ToastConnected);
