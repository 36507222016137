import _ from "lodash";

/**
 * Inserts a thing in between each thing in an array of things.
 */
// eslint-disable-next-line import/prefer-default-export
export const interleaveItems = (arr: unknown[], itemBetween: unknown): unknown[] => {
  return _.flatMap(arr, (value, index, array) =>
    array.length - 1 !== index ? [value, itemBetween] : value,
  );
};
