/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

type Key = "acceptedGuidelines" | "currentClubId";

const DEFAULTS = {};

const getKey = (userId: string, key: Key): string => {
  return `${userId}:${key}`;
};

class ClubsPageStore extends BaseStore {
  constructor() {
    super("ClubsPageStore", DEFAULTS, false);
  }

  getAcceptedGuidelines(userId: string): Record<string, boolean> {
    const key = getKey(userId, "acceptedGuidelines");
    const acceptedGuidelines = super.get(key);

    if (!acceptedGuidelines) {
      super.set(key, {});
      return {};
    }

    return acceptedGuidelines;
  }

  setAcceptedGuidelines(userId: string, update: any): void {
    super.set(getKey(userId, "acceptedGuidelines"), update);
  }

  getCurrentClubId(userId: string): string {
    return super.get(getKey(userId, "currentClubId"));
  }

  setCurrentClubId(userId: string, clubId: string | null): void {
    super.set(getKey(userId, "currentClubId"), clubId);
  }
}

export default new ClubsPageStore();
