import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

import {
  INVALID_USERNAME_ERR_CODE,
  NIANTIC_EDIT_ID_ABUSIVE_ERR_CODE,
  NIANTIC_EDIT_ID_EXCEED_LIMIT_ERR_CODE,
  NIANTIC_EDIT_ID_INVALID_ERR_CODE,
  NIANTIC_EDIT_ID_NOT_ALLOWED_ERR_CODE,
  NIANTIC_EDIT_ID_UNAVAILABLE_ERR_CODE,
  NIANTIC_ERR_CODE,
} from "constants/backendErrors";
import { NIANTIC_GUIDELINES_URL } from "constants/urls";

import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  error: number;
};

const ErrorMessage: (props: Props) => JSX.Element = (props: Props) => {
  switch (props.error) {
    case NIANTIC_EDIT_ID_INVALID_ERR_CODE:
    case NIANTIC_EDIT_ID_EXCEED_LIMIT_ERR_CODE:
    case NIANTIC_EDIT_ID_NOT_ALLOWED_ERR_CODE:
    case INVALID_USERNAME_ERR_CODE:
      return (
        <>
          <UIText weight="bold" variant="subtitle1">
            {props.t("NIANTIC_ID_NAMING_REQUIREMENTS")}
          </UIText>
          <UISpacer h={4} />
          <ul>
            <li>
              <UIText weight="medium" variant="subtitle1">
                {props.t("NIANTIC_ID_NAMING_LENGTH")}
              </UIText>
            </li>
            <li>
              <UIText weight="medium" variant="subtitle1">
                {props.t("NIANTIC_ID_NO_SPECIAL_CHARACTERS")}
              </UIText>
            </li>
            <li>
              <UIText weight="medium" variant="subtitle1">
                {props.t("NIANTIC_ID_NO_SPACES")}
              </UIText>
            </li>
            <li>
              <UIText weight="medium" variant="h4">
                <Trans
                  i18nKey="RESET_UPDATE_NIANTIC_ID_ABIDE_BY"
                  components={{
                    LINK: <UILink href={NIANTIC_GUIDELINES_URL} className={styles.link} />,
                  }}
                />
              </UIText>
            </li>
          </ul>
        </>
      );
    case NIANTIC_EDIT_ID_UNAVAILABLE_ERR_CODE:
      return (
        <UIText color="danger" weight="bold" variant="subtitle1">
          {props.t("ERROR_EDIT_NIANTIC_ID_UNAVAILABLE")}
        </UIText>
      );
    case NIANTIC_EDIT_ID_ABUSIVE_ERR_CODE:
      return (
        <UIText weight="bold" variant="subtitle1">
          <Trans
            i18nKey="ERROR_EDIT_INAPPROPRIATE_NAME"
            components={{
              LINK: <UILink href={NIANTIC_GUIDELINES_URL} className={styles.link} />,
            }}
          />
        </UIText>
      );
    case NIANTIC_ERR_CODE:
    default:
      return (
        <UIText color="danger" weight="bold" variant="subtitle1">
          {props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
        </UIText>
      );
  }
};

export default withTranslation()(ErrorMessage);
