import {
  USER_INFO_ROUTE,
  MAP_ROUTE,
  MESSAGES_ROUTE,
  CLUBS_ROUTE,
  NOTIFICATIONS_ROUTE,
} from "constants/routes";

const getRoutePageClassName = (routeName: string): string => {
  return `campfire-${routeName}-page`;
};

export const USER_PAGE_CLASSNAME = getRoutePageClassName(USER_INFO_ROUTE);
export const MAP_PAGE_CLASSNAME = getRoutePageClassName(MAP_ROUTE);
export const MESSAGES_PAGE_CLASSNAME = getRoutePageClassName(MESSAGES_ROUTE);
export const CLUBS_PAGE_CLASSNAME = getRoutePageClassName(CLUBS_ROUTE);
export const NOTIFICATIONS_PAGE_CLASSNAME = getRoutePageClassName(NOTIFICATIONS_ROUTE);
export const MAP_AND_ACTIVITY_CENTER_PAGE_CLASSNAME =
  getRoutePageClassName("map-and-activity-center");
