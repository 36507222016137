import _ from "lodash";
import React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { ExposedProps as LayoutExposedProps } from "pages/MapAndActivities/components/ActivityCenterPageLayout";

import { ActivityCenterViewProvider_me$data as ActivityCenterViewProviderMe } from "__generated__/ActivityCenterViewProvider_me.graphql";

export type ExposedProps = {
  showPreconfiguredView: LayoutExposedProps["showPreconfiguredView"];
  showDynamicView: LayoutExposedProps["showDynamicView"];
  hideView: LayoutExposedProps["hideView"];
  activeView: LayoutExposedProps["activeView"];
  isViewOpen: LayoutExposedProps["isViewOpen"];
};

type RequiredProps = {
  children: React.ReactNode;
  me: ActivityCenterViewProviderMe;
};

type Props = RequiredProps & {
  viewLayoutControls: LayoutExposedProps;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  showPreconfiguredView: () => ({ hideView: _.noop }),
  showDynamicView: () => ({ hideView: _.noop }),
  hideView: _.noop,
  activeView: null,
  isViewOpen: () => false,
};

export const ActivityCenterViewContext = React.createContext(INITIAL_CONTEXT);

/**
 * This is the provider that things downstream should connect to in order to adjust the
 * UINav slide stack.
 */
class ActivityCenterViewProvider extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <ActivityCenterViewContext.Provider
        value={{
          showPreconfiguredView: this.props.viewLayoutControls.showPreconfiguredView,
          showDynamicView: this.props.viewLayoutControls.showDynamicView,
          hideView: this.props.viewLayoutControls.hideView,
          activeView: this.props.viewLayoutControls.activeView,
          isViewOpen: this.props.viewLayoutControls.isViewOpen,
        }}
      >
        {children}
      </ActivityCenterViewContext.Provider>
    );
  };
}

const FragmentContainer = createFragmentContainer(ActivityCenterViewProvider, {
  me: graphql`
    fragment ActivityCenterViewProvider_me on User {
      ...ActivityCenterPageLayout_me
    }
  `,
});

export { FragmentContainer as ActivityCenterViewProvider };
export const ActivityCenterViewConsumer = ActivityCenterViewContext.Consumer;
