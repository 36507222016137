import _ from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import nianticLogo from "assets/images/niantic/niantic-logo-with-name.png";
import logException from "common/analytics/exceptions";
import { isStandalone } from "common/capacitor/helpers";
import { SharedLoginToken } from "common/capacitor/plugins/shared-login";
import OnboardingStore from "common/stores/OnboardingStore";
import { validateSharedLoginToken, retrieveSharedLoginToken } from "common/utils/sharedLogin";
import { getQueryString, parseQuery } from "common/utils/url";
import { QUERY_PARAMS } from "constants/routes";
import { NIANTIC_CAMPFIRE_SUPPORT_URL } from "constants/urls";

import Image from "common/components/Image";
import PermanentBanDialog from "common/components/PermanentBanDialog";
import SignupLegalDialog from "common/components/SignupLegalDialog";
import SignupOrLoginDialog from "common/components/SignupOrLoginDialog";
import SuspensionDialog from "common/components/SuspensionDialog";
import UIAsyncButton from "common/components/UIAsyncButton";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import AgeEligibilityDialog from "boot-loader/components/AgeEligibilityDialog";
import AuthenticationErrorDialog from "boot-loader/components/AuthenticationErrorDialog";
import ForceUpgradeDialog from "boot-loader/components/ForceUpgradeDialog";
import SharedLoginDialog from "boot-loader/components/SharedLoginDialog";

import styles from "./styles.scss";

type SharedLoginState = {
  sharedLoginToken: SharedLoginToken | null;
  validToken: boolean;
  hasCampfireAccount: boolean;
};

type Props = RouteComponentProps &
  WithTranslation & {
    appOutOfDate: boolean;
    finishAuthAndProceedToApp: () => Promise<void>;
  };

type State = {
  // These need to be distinct due to delay swapping modals and ionic modals being annoying
  // and breaking if you get out of sync with state and their dismiss event.
  authenticationMode: "signup" | "authenticate";
  showAgeEligibilityDialog: boolean;
  showAuthenticationDialog: boolean;
  showLegalDialog: boolean;
  showSharedLoginDialog: boolean;
  showSuspensionDialog: boolean;
  showPermanentBanDialog: boolean;
  showGeneralAuthErrorDialog: boolean;
  suspensionExpirationInSeconds: number;
  sharedLoginState: SharedLoginState;
  // Allows showGeneralAuthErrorDialog to support not allowed as well as generic auth error
  isAuthErrorNotAllowed: boolean;
};

/**
 * Landing page for non-authenticated users.
 *
 * This page presents a number of dialogs, depending on the user's account and app state.
 * - Suspension dialog: shown if the user's account is suspended.
 * - Legal dialog: shown if the user hasn't accepted the legal agreements (flag stored locally)
 * - Shared login dialog: shown if the user is on Standalone and has a shared login token
 *   - If the user decides to authenticate with shared login, we proceed to the app immediately
 * - Authentication dialog
 */
class LandingPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const hasSetBirthday = !!OnboardingStore.get("initialLaunchBirthday");

    this.state = {
      showAgeEligibilityDialog: !hasSetBirthday,
      authenticationMode: "authenticate",
      showAuthenticationDialog: false,
      showLegalDialog: false,
      showSharedLoginDialog: false,
      suspensionExpirationInSeconds: 0,
      showSuspensionDialog: false,
      showPermanentBanDialog: false,
      showGeneralAuthErrorDialog: false,
      sharedLoginState: {
        sharedLoginToken: null,
        validToken: false,
        hasCampfireAccount: false,
      },
      isAuthErrorNotAllowed: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    const query = parseQuery(this.props.location.search);
    const isSuspendedQueryParam = QUERY_PARAMS.root.isSuspended.key;
    const isPermanentBanQueryParam = QUERY_PARAMS.root.isPermanentBan.key;

    if (query[isPermanentBanQueryParam]) {
      this.showPermanentBanDialog();
    }

    if (query[isSuspendedQueryParam]) {
      this.showSuspensionDialog(parseInt(query.isSuspended, 10));
    }
  };

  updateSharedLoginState = async (): Promise<SharedLoginState> => {
    // Only attempt to update shared login state on Standalone.
    if (!isStandalone) {
      return this.state.sharedLoginState;
    }

    const sharedLoginToken = await retrieveSharedLoginToken();

    if (!sharedLoginToken) {
      return this.state.sharedLoginState;
    }

    // If the retrieved shared login token matches the current token,
    // skip validation and retain current state.
    if (_.isEqual(sharedLoginToken, this.state.sharedLoginState.sharedLoginToken)) {
      return this.state.sharedLoginState;
    }

    // Only set the shared login token if the token is valid.
    const validationResponse = await validateSharedLoginToken(sharedLoginToken);

    if (!validationResponse.validToken) {
      return this.state.sharedLoginState;
    }

    const sharedLoginState = {
      sharedLoginToken,
      validToken: validationResponse.validToken,
      hasCampfireAccount: validationResponse.hasCampfireAccount,
    };

    this.setState({
      sharedLoginState,
    });

    return sharedLoginState;
  };

  hideDialogs = () => {
    this.setState({
      showLegalDialog: false,
      showSharedLoginDialog: false,
      showAuthenticationDialog: false,
    });
  };

  showAgeEligibilityDialog = (): void => {
    this.setState({ showAgeEligibilityDialog: true });
  };

  hideAgeEligibilityDialog = (): void => {
    this.setState({ showAgeEligibilityDialog: false });
  };

  showLegalDialog = (): void => {
    this.setState({ showLegalDialog: true });
  };

  hideLegalDialog = (): void => {
    this.setState({ showLegalDialog: false });
  };

  showSharedLoginDialog = () => {
    this.setState({ showSharedLoginDialog: true });
  };

  hideSharedLoginDialog = () => {
    this.setState({ showSharedLoginDialog: false });
  };

  showAuthenticationDialog = (): void => {
    this.setState({ showAuthenticationDialog: true });
  };

  hideAuthenticationDialog = (): void => {
    this.setState({ showAuthenticationDialog: false });
  };

  showGeneralAuthErrorDialog = (notAllowed?: boolean): void => {
    this.setState({
      showGeneralAuthErrorDialog: true,
      isAuthErrorNotAllowed: Boolean(notAllowed),
    });
  };

  hideGeneralAuthErrorDialog = (): void => {
    this.setState({ showGeneralAuthErrorDialog: false });
  };

  showSuspensionDialog = (suspensionExpirationInSeconds: number): void => {
    this.setState({
      showSuspensionDialog: true,
      suspensionExpirationInSeconds,
    });
  };

  hideSuspensionDialog = (): void => {
    this.setState({ showSuspensionDialog: false });
  };

  hidePermanentBanDialog = (): void => {
    this.setState({ showPermanentBanDialog: false });
  };

  showPermanentBanDialog = (): void => {
    this.setState({
      showPermanentBanDialog: true,
    });
  };

  hideSuspensionDialogAndClearQueryParams = () => {
    const isSuspendedQueryParam = QUERY_PARAMS.root.isSuspended.key;
    const queryParamUpdates = { [isSuspendedQueryParam]: undefined };

    this.hideSuspensionDialog();
    this.props.history.replace({
      search: getQueryString(queryParamUpdates, this.props.location.search),
    });
  };

  hideBanDialogAndClearQueryParams = () => {
    const isBanQueryParam = QUERY_PARAMS.root.isPermanentBan.key;
    const queryParamUpdates = { [isBanQueryParam]: undefined };

    this.hidePermanentBanDialog();
    this.props.history.replace({
      search: getQueryString(queryParamUpdates, this.props.location.search),
    });
  };

  beginLoginFlow = async (nextAuthenticationMode?: State["authenticationMode"]): Promise<void> => {
    const { authenticationMode } = this.state;
    const hasSetBirthday = !!OnboardingStore.get("initialLaunchBirthday");
    const hasAcceptedTOS = !!OnboardingStore.get("acceptedTOS");
    const seenPrivacyPolicy = !!OnboardingStore.get("seenPrivacyPolicy");

    // Possibly update shared login state.
    const sharedLoginState = await this.updateSharedLoginState();

    this.setState({
      authenticationMode: nextAuthenticationMode || authenticationMode,
    });

    if (!hasSetBirthday) {
      this.showAgeEligibilityDialog();
    } else if (!hasAcceptedTOS || !seenPrivacyPolicy) {
      this.delaySwapDialog("legal");
    } else if (sharedLoginState.sharedLoginToken) {
      this.showSharedLoginDialog();
    } else {
      this.showAuthenticationDialog();
    }
  };

  delaySwapDialog = (presentingDialog: "authentication" | "legal" | "sharedLogin"): void => {
    const { showAuthenticationDialog, showLegalDialog, showSharedLoginDialog } = this.state;

    const anyDialogsOpen = showAuthenticationDialog || showLegalDialog || showSharedLoginDialog;

    let delay = 0;

    if (anyDialogsOpen) {
      this.hideDialogs();
      delay = 300;
    }

    setTimeout(() => {
      if (presentingDialog === "legal") {
        this.showLegalDialog();
      } else if (presentingDialog === "sharedLogin") {
        this.showSharedLoginDialog();
      } else {
        this.showAuthenticationDialog();
      }
    }, delay);
  };

  resumeFromSharedLogin = (): void => {
    this.delaySwapDialog("authentication");
  };

  resumeFromLegal = (): void => {
    if (this.state.sharedLoginState.sharedLoginToken) {
      this.delaySwapDialog("sharedLogin");
    } else {
      this.delaySwapDialog("authentication");
    }
  };

  onAuthenticate = async (): Promise<void> => {
    try {
      await this.props.finishAuthAndProceedToApp();
    } catch (error) {
      logException("checkSession", "onAuthenticate", "LandingPage", error);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  render = (): React.ReactNode => {
    const {
      showAgeEligibilityDialog,
      showSharedLoginDialog,
      showAuthenticationDialog,
      showLegalDialog,
      showSuspensionDialog,
      showPermanentBanDialog,
      showGeneralAuthErrorDialog,
      isAuthErrorNotAllowed,
    } = this.state;

    return (
      <div className={styles.root}>
        <ForceUpgradeDialog isOpen={this.props.appOutOfDate} />
        {!this.props.appOutOfDate && (
          // Force upgrade dialog takes precedence over all other dialogs.
          // Only show if the app is up to date.
          <>
            <AgeEligibilityDialog
              isOpen={showAgeEligibilityDialog}
              close={this.hideAgeEligibilityDialog}
            />

            <SharedLoginDialog
              isOpen={showSharedLoginDialog}
              resume={this.resumeFromSharedLogin}
              close={this.hideSharedLoginDialog}
              finishAuthAndProceedToApp={this.props.finishAuthAndProceedToApp}
              sharedLoginToken={this.state.sharedLoginState.sharedLoginToken}
              validToken={this.state.sharedLoginState.validToken ?? false}
              hasCampfireAccount={this.state.sharedLoginState.hasCampfireAccount ?? false}
              showGeneralAuthErrorDialog={this.showGeneralAuthErrorDialog}
            />

            <SignupOrLoginDialog
              mode={this.state.authenticationMode}
              isOpen={showAuthenticationDialog}
              close={this.hideAuthenticationDialog}
              onAuthenticate={this.onAuthenticate}
              showSuspensionDialog={this.showSuspensionDialog}
              showPermanentBanDialog={this.showPermanentBanDialog}
              showGeneralAuthErrorDialog={this.showGeneralAuthErrorDialog}
            />

            <AuthenticationErrorDialog
              isOpen={showGeneralAuthErrorDialog}
              isNotAllowed={isAuthErrorNotAllowed}
              close={this.hideGeneralAuthErrorDialog}
            />

            <SignupLegalDialog
              isOpen={showLegalDialog}
              resume={this.resumeFromLegal}
              close={this.hideLegalDialog}
            />

            <SuspensionDialog
              isOpen={showSuspensionDialog}
              close={this.hideSuspensionDialogAndClearQueryParams}
              suspensionExpirationInSeconds={this.state.suspensionExpirationInSeconds}
            />

            <PermanentBanDialog
              isOpen={showPermanentBanDialog}
              close={this.hideBanDialogAndClearQueryParams}
            />
          </>
        )}

        <div className={styles.bgImage} />

        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.dynamicSpacer} />
            <div className={styles.appName}>
              <UIText color="dark" variant="h1" weight="extraBold">
                {this.props.t("CAMPFIRE")}
              </UIText>
              <UISpacer h={16} />
              <UIText color="dark" variant="h3" weight="medium">
                {this.props.t("CONNECT_WITH_FRIENDS_EXPLORE_THE_WORLD")}
              </UIText>
            </div>

            <UISpacer h={60} />

            <div className={styles.actions}>
              <UIAsyncButton
                onClick={() => this.beginLoginFlow("signup")}
                className={styles.signupBtn}
                color="primary"
              >
                <UIText weight="bold">{this.props.t("SIGN_UP")}</UIText>
              </UIAsyncButton>
            </div>

            <UISpacer h={14} />

            <div className={styles.centered}>
              <UIText color="dark" variant="body1" weight="medium">
                {this.props.t("ALREADY_HAVE_AN_ACCOUNT")}
              </UIText>

              <UIAsyncButton
                onClick={() => this.beginLoginFlow("authenticate")}
                className={styles.signinBtn}
                fill="clear"
                size="small"
              >
                <UIText color="dark" variant="body1" weight="bold">
                  {this.props.t("SIGN_IN")}
                </UIText>
              </UIAsyncButton>
            </div>
          </div>
          <div className={styles.helpContainer}>
            <UIText variant="h4" weight="medium" color="white">
              {this.props.t("NEED_HELP")}
            </UIText>
            <UISpacer w={4} />
            <UILink
              className={styles.getSupportLink}
              href={NIANTIC_CAMPFIRE_SUPPORT_URL}
              openInNewTab
            >
              {_.startCase(this.props.t("GET_SUPPORT"))}
            </UILink>
          </div>
          <UISpacer h={25} />
          <div className={styles.footer}>
            <Image className={styles.logo} src={nianticLogo} alt="Logo" />
          </div>
        </div>
      </div>
    );
  };
}

const RouterConnected = withRouter(LandingPage);

export default withTranslation()(RouterConnected);
