import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";

import { ActivityPageStateProvider } from "pages/MapAndActivities/providers/ActivityPageStateProvider";
import withSelectedRealityChannel, {
  WithSelectedRealityChannelProps,
} from "providers/SelectedRealityChannelProvider/withSelectedRealityChannel";

import { RealityChannelMetadata } from "common/stores/SelectedRealityChannelStore";
import AppInterop from "common/utils/webInterop/app";
import { GAME_SHORT_CODES } from "constants/nianticGame";

import LoadingPageDefault from "common/components/LoadingPageDefault";
import PGOExitGameButton from "common/components/PGOExitGameButton";
import PageLoadError from "common/components/PageLoadError";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import ActivityCenterView from "pages/MapAndActivities/components/ActivityCenterView";

import {
  pgo_MapAndActivities_page_Query$data as MapAndActivitiesPagePGOQueryResponse,
  pgo_MapAndActivities_page_Query$variables as MapAndActivitiesPagePGOQueryVariables,
} from "__generated__/pgo_MapAndActivities_page_Query.graphql";

import styles from "./styles.scss";

// Lazy Load Main View
const MapAndActivitiesPageMainContentPGO = React.lazy(
  () => import("pages/MapAndActivities/variants/pgo/components/MapAndActivitiesPageMainContentPGO"),
);

type Props = WithTranslation & WithSelectedRealityChannelProps & {};

type State = {
  initializedAt: number;
};

type QRProps = MapAndActivitiesPagePGOQueryResponse & {
  me: MapAndActivitiesPagePGOQueryResponse["me"];
};

const MAPANDACTIVITIESPGO_PAGE = graphql`
  query pgo_MapAndActivities_page_Query($gameName: String!) {
    me {
      ...ActivityCenterView_me
      ...MapAndActivitiesPageMainContentPGO_me
    }
    ...MapAndActivitiesPageMainContentPGO_query
    realityChannelForEmbedGame(name: $gameName) {
      id
      name
      iconURL
      ...MapAndActivitiesPageMainContentPGO_realityChannel
    }
  }
`;

class MapAndActivitiesPagePgo extends React.Component<Props, State> {
  initialQueryVariables: MapAndActivitiesPagePGOQueryVariables;

  constructor(props: Props) {
    super(props);

    this.initialQueryVariables = {
      gameName: GAME_SHORT_CODES.PGO,
    };

    this.state = {
      initializedAt: Date.now(),
    };
  }

  reInit = (): void => {
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  exitGame = (): void => {
    AppInterop.logout();
  };

  render = (): React.ReactNode => {
    const { selectedRealityChannelId, changeSelectedRealityChannel } =
      this.props.selectedRealityChannelProvider;

    return (
      <UIErrorBoundary onRetry={this.onRetry}>
        <div className={styles.pgoExitGameButton}>
          <PGOExitGameButton onClick={this.exitGame} />
        </div>

        <Suspense fallback={<LoadingPageDefault />}>
          <QueryRenderer
            key={this.state.initializedAt}
            environment={environment}
            query={MAPANDACTIVITIESPGO_PAGE}
            variables={this.initialQueryVariables}
            render={({ props, error }) => {
              const qrProps = props as QRProps;
              const isLoading = !qrProps;
              const isError = !!error;

              // If the query is loading or we still haven't resolved an RC yet, show a loading
              // UI.
              if (isLoading) {
                return <LoadingPageDefault />;
              }

              if (isError) {
                return (
                  <PageLoadError
                    errorMessage={this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
                    reload={this.reInit}
                  />
                );
              }

              const pgoRCAsRCMetadata: RealityChannelMetadata = {
                id: qrProps.realityChannelForEmbedGame.id,
                name: qrProps.realityChannelForEmbedGame.name,
                iconURL: qrProps.realityChannelForEmbedGame.iconURL,
              };
              const possibleRcs = [pgoRCAsRCMetadata];

              return (
                <ActivityCenterView me={qrProps.me}>
                  <ActivityPageStateProvider
                    possibleRealityChannels={possibleRcs}
                    selectedRealityChannelId={selectedRealityChannelId}
                    changeSelectedRealityChannel={changeSelectedRealityChannel}
                  >
                    <MapAndActivitiesPageMainContentPGO
                      query={qrProps}
                      me={qrProps.me}
                      realityChannel={qrProps.realityChannelForEmbedGame}
                    />
                  </ActivityPageStateProvider>
                </ActivityCenterView>
              );
            }}
          />
        </Suspense>
      </UIErrorBoundary>
    );
  };
}

const SelectedRealityChannelConnected = withSelectedRealityChannel(MapAndActivitiesPagePgo);

export default withTranslation()(SelectedRealityChannelConnected);
