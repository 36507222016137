import _ from "lodash";

import { SlideInfo, SlideStackState, SlideComponentDef } from "./types";

const getSlideInfoByIndex = (
  index: number,
  slideComponents: SlideComponentDef[],
  currentSlideState: SlideStackState,
): SlideInfo => {
  const slideContent = slideComponents[index];

  if (!slideContent) {
    return {
      slideId: "",
      isOpen: false,
      isCurrentSlide: false,
      contentShouldBeRenderedWhileClosed: false,
      indexInStack: -1,
      onHidden: _.noop,
      onOpen: _.noop,
    };
  }

  const {
    slideId,
    onHidden,
    onOpen,
    contentShouldBeRenderedWhileClosed = false,
  } = slideContent.props;

  const isOpen = currentSlideState.isSlideInStack(slideId);
  const indexInSlideStack = currentSlideState.getIndexOfSlideInStack(slideId);
  const isCurrentSlide = slideId === currentSlideState.activeSlide;

  return {
    slideId,
    isOpen,
    contentShouldBeRenderedWhileClosed,
    isCurrentSlide,
    indexInStack: indexInSlideStack,
    onHidden,
    onOpen,
  };
};

export default getSlideInfoByIndex;
