import _ from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

// TODO: Add this image back in when implementing post removing b/c people downloaded these images
// import mapPreview from "assets/images/mapImages/pgo_map_share_post_preview.png";
import { getDeepLinkToApp } from "common/utils/getDeepLinkWithEncodedParams";

import SimpleCampfireOnboardingView from "common/components/SimpleCampfireOnboardingView";

export type Props = WithTranslation & {
  close: () => void;
};

type State = {};

class PGOCampfireFTUEShareFlow extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const deepLinkToCampfireApp = getDeepLinkToApp();

    return (
      <SimpleCampfireOnboardingView
        title={this.props.t("DISCOVER_ACTIVITY_AROUND_YOU")}
        description={this.props.t("SEE_WHAT_OTHERS_ARE_POSTING")}
        // imageUrl={mapPreview}
        confirmBtnText={this.props.t("OPEN_CAMPFIRE")}
        confirmBtnHref={deepLinkToCampfireApp}
        cancelBtnText={this.props.t("NOT_NOW")}
        onConfirm={_.noop}
        onCancel={this.props.close}
      />
    );
  };
}

export default withTranslation()(PGOCampfireFTUEShareFlow);
