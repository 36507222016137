import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withAuth, { WithAuthProps } from "providers/AuthProvider/withAuth";

import { SharedLoginToken } from "common/capacitor/plugins/shared-login";
import { authenticateWithSharedLoginToken, getGameNameFromToken } from "common/utils/sharedLogin";
import nianticGame, { GameShortCode } from "constants/nianticGame";
import { NIANTIC_PRIVACY_URL } from "constants/urls";

import AuthProviderIcon from "common/components/AuthProviderIcon";
import UIAsyncButton from "common/components/UIAsyncButton";
import UIButton from "common/components/UIButton";
import UIDialog from "common/components/UIDialog";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation &
  WithAuthProps & {
    isOpen: boolean;
    resume: () => void;
    close: () => void;
    finishAuthAndProceedToApp: () => void;
    sharedLoginToken: SharedLoginToken | null;
    validToken: boolean;
    hasCampfireAccount: boolean;
    showGeneralAuthErrorDialog: () => void;
  };

type State = {};

class SharedLoginDialog extends React.Component<Props, State> {
  onDidDismiss = (): void => {
    this.props.close();
  };

  authenticate = async (): Promise<void> => {
    const { sharedLoginToken } = this.props;

    if (!sharedLoginToken) {
      return;
    }

    try {
      const response = await authenticateWithSharedLoginToken(sharedLoginToken);

      this.props.authProvider.saveTokens("", response.token, "shared_login");
      this.props.finishAuthAndProceedToApp();
    } catch (error) {
      // If any error happened during shared login, lets just show the generic error.
      this.props.showGeneralAuthErrorDialog();
    }
  };

  renderNoGarAccount = (): React.ReactNode => {
    return (
      <>
        <UIText variant="h2" weight="bold" color="dark">
          {this.props.t("SHARED_LOGIN_TOKEN_NO_ACCOUNT")}
        </UIText>

        <UISpacer h={20} />

        <UIButton onClick={this.props.resume} color="light" expand="block" fill="solid">
          <UIText weight="bold">{this.props.t("SIGN_IN_ANOTHER_WAY")}</UIText>
        </UIButton>
      </>
    );
  };

  renderNoCampfireAccount = (sharedLoginToken: SharedLoginToken): React.ReactNode => {
    const gameShortCode = sharedLoginToken.CreatedByAppKey.toUpperCase() as GameShortCode;
    const game = nianticGame[gameShortCode];

    if (!game) {
      return null;
    }

    return (
      <>
        <UIText variant="h2" weight="bold" color="dark">
          {this.props.t("SHARED_LOGIN_NEW_TO_NIANTIC_SOCIAL")}
        </UIText>

        <UISpacer h={12} />

        <UIText variant="h4" color="dark">
          {this.props.t("SHARED_LOGIN_CREATE_NEW_ACCOUNT_WITH_CREDS")}
        </UIText>

        <UISpacer h={20} />

        <div className={styles.accountInfo}>
          <AuthProviderIcon
            provider={sharedLoginToken.AuthProviderId as SupportedOAuthProvider}
            mode="normal"
          />
          <UISpacer w={15} />
          <UIText>{sharedLoginToken.AccountName}</UIText>
        </div>

        <UISpacer h={32} />

        <UIAsyncButton onClick={this.authenticate} color="success" expand="block" fill="solid">
          <UIText weight="bold">{this.props.t("CREATE_NEW_ACCOUNT")}</UIText>
        </UIAsyncButton>

        <UISpacer h={6} />

        <UIButton onClick={this.props.resume} color="light" expand="block" fill="solid">
          <UIText weight="bold">{this.props.t("SIGN_IN_ANOTHER_WAY")}</UIText>
        </UIButton>
      </>
    );
  };

  renderHasCampfireAccount = (sharedLoginToken: SharedLoginToken): React.ReactNode => {
    return (
      <>
        <UIText variant="h2" weight="bold" color="dark">
          {this.props.t("SHARED_LOGIN_SIGN_IN_AS")}
        </UIText>

        <UISpacer h={20} />

        <div className={styles.accountInfo}>
          <AuthProviderIcon
            provider={sharedLoginToken.AuthProviderId as SupportedOAuthProvider}
            mode="normal"
            className={styles.fbAuthProviderIcon}
          />
          <UISpacer w={15} />
          <UIText>{sharedLoginToken.AccountName}</UIText>
        </div>

        <UISpacer h={24} />

        <UIText variant="h4" color="dark">
          {this.props.t("SHARED_LOGIN_PREVIOUSLY_LOGGED_INTO", {
            gameName: getGameNameFromToken(sharedLoginToken),
          })}
        </UIText>

        <UISpacer h={32} />

        <UIAsyncButton onClick={this.authenticate} color="success" expand="block" fill="solid">
          <UIText weight="bold">{this.props.t("CONTINUE")}</UIText>
        </UIAsyncButton>

        <UISpacer h={6} />

        <UIButton onClick={this.props.resume} color="light" expand="block" fill="solid">
          <UIText weight="bold">{this.props.t("SIGN_IN_ANOTHER_WAY")}</UIText>
        </UIButton>
      </>
    );
  };

  render = (): React.ReactNode => {
    const { isOpen, sharedLoginToken } = this.props;

    if (!isOpen || !sharedLoginToken) {
      return null;
    }

    // Render slightly different views if the user doesn't have a Campfire account.
    let content: React.ReactNode = null;

    if (!this.props.validToken) {
      content = this.renderNoGarAccount();
    } else if (!this.props.hasCampfireAccount) {
      content = this.renderNoCampfireAccount(sharedLoginToken);
    } else {
      content = this.renderHasCampfireAccount(sharedLoginToken);
    }

    return (
      <UIDialog isOpen={this.props.isOpen} cssClass={styles.root} close={this.props.close}>
        <div className={styles.content}>{content}</div>
        <div className={styles.privacyContainer}>
          <UILink href={NIANTIC_PRIVACY_URL} openInNewTab>
            <UIText variant="body2" weight="medium" color="medium">
              {this.props.t("PRIVACY").toLocaleUpperCase()}
            </UIText>
          </UILink>
        </div>
      </UIDialog>
    );
  };
}

const AuthConnected = withAuth(SharedLoginDialog);

export default withTranslation()(AuthConnected);
