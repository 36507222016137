import APPSFLYER_QUERY_PARAMS from "constants/appsFlyerQueryParams";
import config from "constants/config";
import { CLUBS_FEED_ROUTE, CLUBS_ROUTE, MAP_ROUTE } from "constants/routes";

// https://support.appsflyer.com/hc/en-us/articles/207032246-OneLink-1-4-Converting-users-from-owned-media#create-a-social-media-preview
export enum AppsFlyerOpenGraphTagName {
  TITLE = "af_og_title",
  DESCRIPTION = "af_og_description",
  IMAGE = "af_og_image",
}

// All must be defined for social media previews to work.
export type SocialMediaPreviewConfig = {
  title: string;
  description: string;
  image: string;
};

// Cast as string since it should never be undefined. We initialize it with a default value in config.
export const APPSFLYER_DEEPLINK_URL = config.get("CAMPFIRE_APP_APPSFLYER_DEEPLINK_URL") as string;

// Since all social params are required otherwise the preview will not work. Set it as a whitespace
// char since we filter invalid query params out.
const UNSET_SOCIAL_PARAM_VALUE = " ";

/**
 * Core method to generate a deep link to the app.
 * Optional params support configuration of the link such as social media previews.
 */
export const getEncodedLink = (
  paramString: string,
  socialMediaPreviewConfig?: SocialMediaPreviewConfig,
): string => {
  const encodedPValue = paramString ? btoa(paramString) : "";

  // Only attach params that we want.
  // This is so we can reuse this core method even for the base deep link to have previews.
  // This includes stuff for social media previews that Appsflyer one links support.
  const queryParams: Record<string, string> = {
    // Base Params, these are the core params used when deeplinking to our app.
    ...(encodedPValue && { [APPSFLYER_QUERY_PARAMS.BASE]: encodedPValue }),
    // Social Media Preview, all are required
    ...(socialMediaPreviewConfig && {
      [AppsFlyerOpenGraphTagName.TITLE]: encodeURIComponent(
        socialMediaPreviewConfig.title || UNSET_SOCIAL_PARAM_VALUE,
      ),
      [AppsFlyerOpenGraphTagName.DESCRIPTION]: encodeURIComponent(
        socialMediaPreviewConfig.description || UNSET_SOCIAL_PARAM_VALUE,
      ),
      [AppsFlyerOpenGraphTagName.IMAGE]: encodeURIComponent(
        socialMediaPreviewConfig.image || UNSET_SOCIAL_PARAM_VALUE,
      ),
    }),
  };

  // Convert all params we want to a query param string.
  const queryString: string = Object.keys(queryParams)
    .filter((key) => !!queryParams[key])
    .map((key) => `${key}=${queryParams[key] || ""}`)
    .join("&");

  const searchPart = queryString ? `?${queryString}` : "";

  return `${APPSFLYER_DEEPLINK_URL}${searchPart}`;
};

const getDeepLinkToClub = (
  clubId: string,
  socialMediaPreviewConfig?: SocialMediaPreviewConfig,
): string => {
  return getEncodedLink(
    `${APPSFLYER_QUERY_PARAMS.ROUTE}=${CLUBS_ROUTE}&${APPSFLYER_QUERY_PARAMS.CLUB_ID}=${clubId}&${
      APPSFLYER_QUERY_PARAMS.INVITE
    }=${true}`,
    socialMediaPreviewConfig,
  );
};

const getDeepLinkToEvent = (
  clubId: string,
  channelId: string,
  socialMediaPreviewConfig?: SocialMediaPreviewConfig,
): string => {
  return getEncodedLink(
    `${APPSFLYER_QUERY_PARAMS.ROUTE}=${CLUBS_ROUTE}` +
      `&${APPSFLYER_QUERY_PARAMS.CLUB_ID}=${clubId}` +
      `&${APPSFLYER_QUERY_PARAMS.CHANNEL_ID}=${channelId}` +
      `&${APPSFLYER_QUERY_PARAMS.EVENT}=${true}`,
    socialMediaPreviewConfig,
  );
};

const getDeepLinkToFeed = (
  clubId: string,
  eventId?: string,
  socialMediaPreviewConfig?: SocialMediaPreviewConfig,
): string => {
  return getEncodedLink(
    // Not preferring template since this is slightly more readable as each param is on a line
    // eslint-disable-next-line prefer-template
    `${APPSFLYER_QUERY_PARAMS.ROUTE}=${CLUBS_FEED_ROUTE}` +
      `&${APPSFLYER_QUERY_PARAMS.CLUB_ID}=${clubId}` +
      (eventId ? `&${APPSFLYER_QUERY_PARAMS.EVENT_ID}=${eventId}` : ""),
    socialMediaPreviewConfig,
  );
};

const getDeepLinkToChannel = (
  clubId: string,
  channelId: string,
  socialMediaPreviewConfig?: SocialMediaPreviewConfig,
): string => {
  return getEncodedLink(
    `${APPSFLYER_QUERY_PARAMS.ROUTE}=${CLUBS_ROUTE}` +
      `&${APPSFLYER_QUERY_PARAMS.CLUB_ID}=${clubId}` +
      `&${APPSFLYER_QUERY_PARAMS.CHANNEL_ID}=${channelId}`,
    socialMediaPreviewConfig,
  );
};

const getDeepLinkToMapObject = (
  lat: string,
  lng: string,
  mapObjectId: string,
  game: string,
  socialMediaPreviewConfig?: SocialMediaPreviewConfig,
): string => {
  return getEncodedLink(
    `${APPSFLYER_QUERY_PARAMS.ROUTE}=${MAP_ROUTE}` +
      `&${APPSFLYER_QUERY_PARAMS.LAT}=${lat}` +
      `&${APPSFLYER_QUERY_PARAMS.LNG}=${lng}` +
      `&${APPSFLYER_QUERY_PARAMS.MAP_OBJECT_ID}=${mapObjectId}` +
      `&${APPSFLYER_QUERY_PARAMS.GAME}=${game}`,
    socialMediaPreviewConfig,
  );
};

const getDeepLinkToApp = (socialMediaPreviewConfig?: SocialMediaPreviewConfig): string => {
  return getEncodedLink("", socialMediaPreviewConfig);
};

export {
  getDeepLinkToApp,
  getDeepLinkToClub,
  getDeepLinkToEvent,
  getDeepLinkToFeed,
  getDeepLinkToChannel,
  getDeepLinkToMapObject,
};
