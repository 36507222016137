import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

import ConfirmationDialogView from "common/components/ConfirmationDialogView";
import UIDialog from "common/components/UIDialog";

export type Props = WithTranslation &
  WithFeatureFlagProps & {
    isOpen: boolean;
    isNotAllowed: boolean;
    close: () => void;
  };

type State = {};

class AuthenticationErrorDialog extends React.Component<Props, State> {
  renderTitle = (): string => {
    if (this.props.isNotAllowed) {
      return this.props.t("THANKS_FOR_CHECKING_OUT_CAMPFIRE");
    }

    return this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN");
  };

  renderDescription = (): string | undefined => {
    if (this.props.isNotAllowed) {
      return this.props.t("CAMPFIRE_IS_NOT_AVAILABLE_IN_LOCATION");
    }

    return undefined;
  };

  render = (): React.ReactNode => {
    return (
      <UIDialog
        type="floating-center"
        isOpen={this.props.isOpen}
        onDidDismiss={this.props.close}
        close={this.props.close}
      >
        <ConfirmationDialogView
          title={this.renderTitle()}
          description={this.renderDescription()}
          onCancel={this.props.close}
        />
      </UIDialog>
    );
  };
}

const featureFlagConnected = withFeatureFlag(AuthenticationErrorDialog);

export default withTranslation()(featureFlagConnected);
