import React from "react";

import { AppPermissionProvider } from "providers/AppPermissionProvider";
import { AppUpdateProvider } from "providers/AppUpdateProvider";
import { AuthProvider } from "providers/AuthProvider";
import { CurrentLocationProvider } from "providers/CurrentLocationProvider";
import { FeatureFlagProvider } from "providers/FeatureFlagProvider";
import { OAuthProvider } from "providers/OAuthProvider";
import {
  StandaloneSettingsConsumer,
  StandaloneSettingsProvider,
} from "providers/StandaloneSettingsProvider";
import { VersionInfoProvider } from "providers/VersionInfoProvider";

type Props = {
  children: React.ReactNode;
};

type State = {};

export default class ServiceProviders extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <AppPermissionProvider>
        <AuthProvider>
          <OAuthProvider>
            <StandaloneSettingsProvider>
              <StandaloneSettingsConsumer>
                {({ exposedFeatureFlags }) => (
                  <FeatureFlagProvider featureFlags={exposedFeatureFlags}>
                    <VersionInfoProvider>
                      <CurrentLocationProvider>
                        {/* NOTE: AppUpdateProvider uses VersionInfoProvider! */}
                        <AppUpdateProvider>{children}</AppUpdateProvider>
                      </CurrentLocationProvider>
                    </VersionInfoProvider>
                  </FeatureFlagProvider>
                )}
              </StandaloneSettingsConsumer>
            </StandaloneSettingsProvider>
          </OAuthProvider>
        </AuthProvider>
      </AppPermissionProvider>
    );
  };
}
