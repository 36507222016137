import classnames from "classnames";
import environment from "common/relay/relay-env";
import React, { useState } from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { NIANTIC_ERR_CODE } from "constants/backendErrors";
import { NIANTIC_GUIDELINES_URL } from "constants/urls";
import UpdateAcknowledgedResetMutation from "mutations/UpdateAcknowledgedResetMutation";
import UpdateUserForAccountResetMutation from "mutations/UpdateUserForAccountResetMutation";

import ResetAccountDataNianticIdInput from "common/components/ResetAccountData/components/ResetAccountDataNianticIdInput";
import ReactAccountDataSuccessDialog from "common/components/ResetAccountData/components/ResetAccountDataSuccessDialog";
import ResetAccountDataTitle from "common/components/ResetAccountData/components/ResetAccountDataTitle";
import UIAsyncButton from "common/components/UIAsyncButton";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import { ResetAccountData_me$data as ResetAccountDataMe } from "__generated__/ResetAccountData_me.graphql";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  me: ResetAccountDataMe;
  proceedToNextSequenceInApp: () => Promise<void>;
};

const ResetAccountData: React.FC<Props> = (props: Props) => {
  const [nianticId, setNianticId] = useState("");
  const [errors, setErrors] = useState<number[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const getSubtitle = () => {
    return props.me.resetData?.nianticId?.isAbuse ? (
      <UIText color="dark" variant="h4" weight="medium">
        <Trans
          i18nKey="RESET_VIOLATING_NIANTIC_ID"
          components={{
            LINK: <UILink href={NIANTIC_GUIDELINES_URL} className={styles.link} />,
          }}
        />
      </UIText>
    ) : (
      <UIText color="dark" variant="h4" weight="medium">
        {props.t("RESET_NON_VIOLATING_NIANTIC_ID")}
      </UIText>
    );
  };

  const acknowledgeAndProcessToApp = async () => {
    const payloadAcknowledged = {
      isNianticId: true,
      isDisplayName: false,
      isPhoto: false,
    };

    try {
      // Call mutation to update user's acknowledgement
      await UpdateAcknowledgedResetMutation.commit(environment, payloadAcknowledged);
    } finally {
      // Continue with the rest of the app
      props.proceedToNextSequenceInApp();
    }
  };

  const closeSuccessDialog = () => {
    setShowSuccess(false);
    acknowledgeAndProcessToApp();
  };

  const onOkClick = async () => {
    try {
      if (nianticId.trim().length > 0) {
        const payloadAccountReset = {
          nianticId: nianticId.trim(),
          displayName: null,
        };

        // Call mutation to update the user's account data
        await UpdateUserForAccountResetMutation.commit(environment, payloadAccountReset);

        // Set flag to show success dialog
        setShowSuccess(true);
      } else {
        await acknowledgeAndProcessToApp();
      }
    } catch (error) {
      const cfError = error as CampfireErrors.Error;

      if (cfError.extensions && cfError.extensions.code) {
        setErrors([cfError.extensions.code]);
      } else {
        setErrors([NIANTIC_ERR_CODE]);
      }
    }
  };

  return (
    <>
      <div
        className={classnames(styles.root, {
          [styles.nonAbuse]: !props.me.resetData?.nianticId?.isAbuse,
        })}
      >
        <ResetAccountDataTitle
          title={props.t("RESET_UPDATE_NIANTIC_ID")}
          subtitle={getSubtitle()}
          abuse={props.me.resetData?.nianticId?.isAbuse ? props.t("RESET_REPEATED_OFFENSES") : ""}
        />

        <UISpacer h={28} />

        <ResetAccountDataNianticIdInput
          label={props.t("NIANTIC_USERNAME")}
          value={nianticId}
          placeholder={props.me.username}
          setValue={setNianticId}
          errors={errors}
        />

        <UISpacer h={20} />

        <UIAsyncButton
          color="primary"
          expand="block"
          fill="solid"
          className={styles.submitButton}
          onClick={onOkClick}
        >
          <UIText variant="h4" weight="bold">
            {props.t("OK")}
          </UIText>
        </UIAsyncButton>
      </div>

      {/* Reset flow confirmation */}
      <ReactAccountDataSuccessDialog
        isOpen={showSuccess}
        isAbusive={
          props.me.resetData?.nianticId?.isAbuse ? props.me.resetData.nianticId.isAbuse : false
        }
        closeDialog={closeSuccessDialog}
      />
    </>
  );
};

const FragmentContainer = createFragmentContainer(ResetAccountData, {
  me: graphql`
    fragment ResetAccountData_me on User {
      username
      resetData {
        nianticId {
          isAbuse
        }
      }
    }
  `,
});

export default withTranslation()(FragmentContainer);
