import { invokeRemoteAsync } from "common/utils/webInterop/plugins";

import defaultCheckPermissions, {
  validator as checkPermissionsValidator,
} from "./checkPermissions";
import defaultRequestPermission, {
  validator as requestPermissionValidator,
} from "./requestPermissions";

export default {
  checkPermissions: invokeRemoteAsync<typeof defaultCheckPermissions, "PushNotifications">(
    defaultCheckPermissions,
    checkPermissionsValidator,
    "PushNotifications",
    "checkPermissions",
  ),
  requestPermissions: invokeRemoteAsync<typeof defaultRequestPermission, "PushNotifications">(
    defaultRequestPermission,
    requestPermissionValidator,
    "PushNotifications",
    "requestPermissions",
  ),
};
