/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

type Key =
  // Tracks the last app version seen by the app
  | "lastAppVersion"
  // Tracks the last js version seen by the app
  | "lastJSVersion"
  // Tracks the last timestamp when the app was backgrounded
  | "lastBackgroundedAtMs"
  // The next bundle id for capacitor updater to apply on next cold boot.
  | "nextScheduledBundleIdToApply"
  // The next bundle info for capacitor updater to apply (For later use if needed)
  | "nextScheduledBundleToApply";

const DEFAULTS: Record<Key, string | number | null> = {
  lastAppVersion: "",
  lastJSVersion: "",
  lastBackgroundedAtMs: null,
  // Bundle id is a CapacitorUpdater BundleInfo object, NOT a semver tag like "2.5.0".
  nextScheduledBundleIdToApply: null,
  // In case we need the full data later on.
  nextScheduledBundleToApply: null,
};

class AppUpdateStore extends BaseStore {
  constructor() {
    super("AppUpdateStore", DEFAULTS, false);
  }

  get(key: Key): any {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, value: any): GenericObject {
    return super.set(key, value);
  }
}

export default new AppUpdateStore();
