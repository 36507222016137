import environment from "common/relay/relay-env";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import logException from "common/analytics/exceptions";
import RemoveFriendMutation from "mutations/RemoveFriendMutation";

import AllAppDisplay from "common/components/AllAppDisplay";
import ConfirmationDialogView from "common/components/ConfirmationDialogView";
import UIDialog from "common/components/UIDialog";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  isOpen: boolean;
  onClose: () => void;
  onRemoveFriend?: () => void;
  username: string;
  userId: string;
};

type State = {};

class RemoveFriendDialog extends React.Component<Props, State> {
  confirmRemoveFriend = async (): Promise<void> => {
    try {
      const payload = {
        userId: this.props.userId,
      };

      await RemoveFriendMutation.commit(environment, payload);

      if (this.props.onRemoveFriend) {
        this.props.onRemoveFriend();
      }

      if (this.props.onClose) {
        this.props.onClose();
      }
    } catch (error) {
      logException("RemoveFriendMutation.commit", "RemoveFriend", "RemoveFriendDialog", error);
    }
  };

  render = (): React.ReactNode => {
    const { username } = this.props;
    const removeFriendDialogTitle = this.props.t("UNFRIEND_USERNAME", { username });
    const removeFriendDialogDesc = this.props.t("UNFRIENDING_USERNAME_WILL_REMOVE", { username });

    return (
      <UIDialog
        type="floating-center"
        isOpen={this.props.isOpen}
        close={this.props.onClose}
        onWillDismiss={this.props.onClose}
      >
        <ConfirmationDialogView
          title={removeFriendDialogTitle}
          description={removeFriendDialogDesc}
          confirmBtnText={this.props.t("UNFRIEND")}
          onCancel={this.props.onClose}
          onConfirm={this.confirmRemoveFriend}
        >
          <AllAppDisplay className={styles.allAppDisplay} />
        </ConfirmationDialogView>
      </UIDialog>
    );
  };
}

export default withTranslation()(RemoveFriendDialog);
