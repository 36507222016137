import environment from "common/relay/relay-env";
import React from "react";
import { graphql } from "react-relay";
import { fetchQuery } from "relay-runtime";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

import logException from "common/analytics/exceptions";
import fetchGloballyAvailableRealityChannels from "common/requests/fetchGloballyAvailableRealityChannels";
import SelectedRealityChannelStore, {
  RealityChannelMetadata,
} from "common/stores/SelectedRealityChannelStore";
import {
  isEmbedded,
  isIngressEmbed,
  isNbaAllWorldEmbed,
  isPeridotEmbed,
  isPGOEmbed,
} from "common/utils/webInterop";
import { FEATURE_FLAGS } from "constants/featureFlags";
import { GAME_SHORT_CODES } from "constants/nianticGame";
import { FirstPartyRealityChannelName } from "types/realityChannel";

import fetchRealityChannelByGame from "pages/NewMap/requests/fetchRealityChannelByGame";

import { SelectedRealityChannelProvider_rcById_Query$data as SelectedRealityChannelProviderRcByIdQueryResponse } from "__generated__/SelectedRealityChannelProvider_rcById_Query.graphql";

export type ExposedProps = {
  isDeterminingRealityChannel: boolean;
  selectedRealityChannelId: string;
  selectedRealityChannelMetadata: RealityChannelMetadata | null;
  changeSelectedRealityChannel: (realityChannelMetadata: RealityChannelMetadata) => void;
};

type Props = WithFeatureFlagProps & {
  children: React.ReactNode;
};

type State = {
  isDeterminingRealityChannel: boolean;
  selectedRealityChannelMetadata: RealityChannelMetadata | null;
};

const INITIAL_CONTEXT: ExposedProps = {
  isDeterminingRealityChannel: false,
  selectedRealityChannelId: "",
  selectedRealityChannelMetadata: null,
  changeSelectedRealityChannel: () => undefined,
};

export const SelectedRealityChannelContext = React.createContext(INITIAL_CONTEXT);

const RC_BY_ID_QUERY = graphql`
  query SelectedRealityChannelProvider_rcById_Query($realityChannelId: ID!) {
    realityChannelById(id: $realityChannelId) {
      id
      name
      iconURL
    }
  }
`;

/**
 * Manages the selected reality channel across the entire application.
 *
 * This component is purely state focused and should never become url aware. It is up to the
 * view to determine if the current reality channel needs to change or not. This decouples the url
 * from the management of the state.
 *
 * During the initialization phase, we check if we have a previously selected RC.
 * If we do, we will update some metadata about it to be in sync with the server.
 *   - This operation is non-blocking to allow the app to load faster elsehwere.
 * If we do not, we will select a default RC to use.
 *   - This operation is blocking.
 */
class SelectedRealityChannelProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Load the state from previous RC selected. The underlying page can handle correcting this
    // the user is not allowed to access that channel anymore.
    const previouslyCachedRCMetadata =
      SelectedRealityChannelStore.getCachedSelectedRealityChannelMetadata();

    this.state = {
      isDeterminingRealityChannel: false,
      selectedRealityChannelMetadata: previouslyCachedRCMetadata,
    };
  }

  componentDidMount = async (): Promise<void> => {
    // Let's not impact the normal v1 of the app
    // If we are coming in from the PGO Embed, we know the rc, but we really don't need to do anything here.
    if (!this.props.hasFeatureFlag(FEATURE_FLAGS.CAMPFIRE_V2) || isPGOEmbed) {
      return;
    }

    // Resolve the selected RC.
    const rcToUse = await this.resolveSelectedRealityChannel();

    if (rcToUse) {
      this.changeSelectedRealityChannel(rcToUse);
    }
  };

  /**
   * Returns a game name when we are running in the embed to fetch the RC for.
   */
  getInitialRealityChannelGame = (): FirstPartyRealityChannelName | null => {
    if (isEmbedded) {
      if (isNbaAllWorldEmbed) return GAME_SHORT_CODES.NBAALLWORLD as FirstPartyRealityChannelName;

      if (isIngressEmbed) return GAME_SHORT_CODES.ING as FirstPartyRealityChannelName;

      if (isPeridotEmbed) return GAME_SHORT_CODES.PERIDOT as FirstPartyRealityChannelName;

      return null;
    }

    return null;
  };

  resolveDefaultRCFromGlobal = async (): Promise<RealityChannelMetadata | null> => {
    try {
      const response = await fetchGloballyAvailableRealityChannels();
      const globalRcs = response.realityChannelsInLatLngBounds;

      if (globalRcs && globalRcs.length) {
        const rcToUse = {
          id: globalRcs[0].id,
          name: globalRcs[0].name,
          iconURL: globalRcs[0].iconURL,
        };

        return rcToUse;
      }

      return null;
    } catch (error) {
      logException(
        "Fetch Global RCs",
        "resolveDefaultRCFromGlobal",
        "SelectedRealityChannelProvider",
        error,
      );
      return null;
    }
  };

  resolveRCById = async (realityChannelId: string): Promise<RealityChannelMetadata | null> => {
    try {
      const response = (await fetchQuery(environment, RC_BY_ID_QUERY, {
        realityChannelId,
      }).toPromise()) as SelectedRealityChannelProviderRcByIdQueryResponse;

      return {
        id: response.realityChannelById.id,
        name: response.realityChannelById.name,
        iconURL: response.realityChannelById.iconURL,
      };
    } catch (error) {
      logException("Fetch RC by Id", "resolveRCById", "SelectedRealityChannelProvider", error);
      return null;
    }
  };

  /**
   * Attempts to rehydrate the selected RC. If it fails, its likely due to the RC not existing,
   * so reset to some default.
   *
   * NOTE: This method can change state!
   */
  reHydrateSelectedRealityChannel = async (realityChannelId: string): Promise<void> => {
    const rcById = await this.resolveRCById(realityChannelId);

    if (rcById) {
      this.changeSelectedRealityChannel(rcById);
    } else {
      const defaultRc = await this.resolveDefaultRCFromGlobal();

      if (defaultRc) {
        this.changeSelectedRealityChannel(defaultRc);
      }
    }
  };

  /**
   * Resolves an RC to use as the starting RC.
   * Mainly to help first time users since they won't have anything cached.
   */
  resolveSelectedRealityChannel = async (): Promise<RealityChannelMetadata | null> => {
    // Steps:
    // 1. Check if we are coming from a game. If we are, that takes priority over cache and default.
    // 2. Check if we have a cached RC. If we do, rehydrate it in the background.
    // 3. If we don't, check if we need to hydrate a random one, or one for a game if we're coming
    //    from embed.

    // If we're on the embed, we need will try to resolve an RC via game name.
    if (isEmbedded) {
      const initialRealityChannelGame = this.getInitialRealityChannelGame();

      if (initialRealityChannelGame) {
        const response = await fetchRealityChannelByGame(initialRealityChannelGame);

        return response.realityChannelForEmbedGame;
      }
    }

    const shouldAttemptRehydration = Boolean(this.state.selectedRealityChannelMetadata?.id);

    if (shouldAttemptRehydration) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const rcId = this.state.selectedRealityChannelMetadata!.id;

      // Update cached data for the RC behind the scenes.
      // However, if the RC is no longer available, then cleanup
      this.reHydrateSelectedRealityChannel(rcId);

      return this.state.selectedRealityChannelMetadata;
    }

    // If we don't have one cached, then we need to resolve one. This may cause dependent parts
    // of the app to block.
    this.setState({ isDeterminingRealityChannel: true });

    // If we don't have one cached, then we are gonna have to load all the RC's and pick one.
    // Cache it for next time
    const defaultRc = await this.resolveDefaultRCFromGlobal();

    if (defaultRc) {
      this.changeSelectedRealityChannel(defaultRc);
    }

    this.setState({ isDeterminingRealityChannel: false });

    return null;
  };

  changeSelectedRealityChannel = (realityChannelMetadata: RealityChannelMetadata): void => {
    // TODO: @mchen - Only update the state if the RC is different.
    // Take into account metadata changes
    this.setState({ selectedRealityChannelMetadata: realityChannelMetadata });

    // Sync to localStorage
    SelectedRealityChannelStore.set("selectedRealityChannelMetadata", realityChannelMetadata);
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <SelectedRealityChannelContext.Provider
        value={{
          isDeterminingRealityChannel: this.state.isDeterminingRealityChannel,
          selectedRealityChannelId: this.state.selectedRealityChannelMetadata?.id || "",
          selectedRealityChannelMetadata: this.state.selectedRealityChannelMetadata,
          changeSelectedRealityChannel: this.changeSelectedRealityChannel,
        }}
      >
        {children}
      </SelectedRealityChannelContext.Provider>
    );
  };
}

const FeatureFlagConnected = withFeatureFlag(SelectedRealityChannelProvider);

export { FeatureFlagConnected as SelectedRealityChannelProvider };

export const SelectedRealityChannelConsumer = SelectedRealityChannelContext.Consumer;
