/**
 * @generated SignedSource<<b185b7f2e950cdfab96721763d5f5ab5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveAdminRoleInput = {
  clubId: string;
  userId: string;
};
export type RemoveAdminRoleMutation$variables = {
  clubId: string;
  input: RemoveAdminRoleInput;
};
export type RemoveAdminRoleMutation$data = {
  readonly removeAdminRole: {
    readonly user: {
      readonly isAdmin: boolean;
    };
  };
};
export type RemoveAdminRoleMutation = {
  response: RemoveAdminRoleMutation$data;
  variables: RemoveAdminRoleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "clubId",
      "variableName": "clubId"
    }
  ],
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveAdminRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "RemoveAdminRoleResponse",
        "kind": "LinkedField",
        "name": "removeAdminRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RemoveAdminRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "RemoveAdminRoleResponse",
        "kind": "LinkedField",
        "name": "removeAdminRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95ce1a20c707ace22e7b0638edffbc5a",
    "id": null,
    "metadata": {},
    "name": "RemoveAdminRoleMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveAdminRoleMutation(\n  $input: RemoveAdminRoleInput!\n  $clubId: ID!\n) {\n  removeAdminRole(input: $input) {\n    user {\n      isAdmin(clubId: $clubId)\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e929066990d34675015c088138c2869d";

export default node;
