import { SplashScreen } from "@capacitor/splash-screen";
import { CapacitorUpdater, BundleInfo } from "@capgo/capacitor-updater";

import { isBundleOkToApply } from "common/utils/appUpdate/bundleStatus";
import { BUILT_IN_BUNDLE_ID } from "constants/appUpdate";

import { clearNextBundleToApplyOnColdStart } from "./shouldApplyBundle";

type AppUpdateState = {
  isApplyingUpdate: boolean;
};

// eslint-disable-next-line no-underscore-dangle
const _appUpdateState: AppUpdateState = {
  isApplyingUpdate: false,
};

const validateNonBuiltInVersionExists = async (
  targetBundleId: string,
): Promise<BundleInfo | void> => {
  // Clearly, if we pass in the built in bundle id, return true since it has to be there.
  if (targetBundleId === BUILT_IN_BUNDLE_ID) {
    return undefined;
  }

  try {
    const listResponse = await CapacitorUpdater.list();
    const existingBundle = listResponse.bundles.find((bundle) => bundle.id === targetBundleId);

    return existingBundle;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error setting next jsbundle to use", error);
  }

  return undefined;
};

/**
 * Applies an update by showing the splash screen and then reloading the webpage essentially.
 * Then will hide the splash screen after the update is complete.
 *
 * Not designed to be exported! Please use applyUpdateAndCleanupState.
 */
const applyUpdate = async (bundleInfoId: string): Promise<boolean> => {
  // If we are already applying the update, do not apply it again.
  if (_appUpdateState.isApplyingUpdate) {
    return false;
  }

  // Check if the bundle exists!
  const bundle = await validateNonBuiltInVersionExists(bundleInfoId);

  if (!bundle || !isBundleOkToApply(bundle)) {
    return false;
  }

  // Display the splash screen when we are applying an update.
  SplashScreen.show();

  let successfulUpdate = true;

  try {
    _appUpdateState.isApplyingUpdate = true;
    await CapacitorUpdater.set({ id: bundleInfoId });
    // At this point, the new version should be active, and will need to hide the splash screen
  } catch (error) {
    successfulUpdate = false;
    // eslint-disable-next-line no-console
    console.error("Error setting next capgo bundle Id to use", error);
  } finally {
    _appUpdateState.isApplyingUpdate = false;
    SplashScreen.hide();
  }

  return successfulUpdate;
};

/**
 * This is the method we should be using to apply a bundle/update.
 */
// eslint-disable-next-line import/prefer-default-export
export const applyUpdateAndCleanupState = async (bundleInfoId: string): Promise<boolean> => {
  // Consume the saved value, since once we apply it, no need to apply it later
  clearNextBundleToApplyOnColdStart();
  return applyUpdate(bundleInfoId);
};
