// Version Bump Plan:
// ==================
// With every release, we will bump the MINOR version of the app following SEMVER.
// Meaning, if the current release is 1.3.2, the next release should start at 1.4.0.
// When we need to hotfix, we will bump the PATCH value, so 1.3.3 is the next in the example
// above.
// Releases will then be tagged with this version number as well.
// eslint-disable-next-line import/prefer-default-export
export const CAMPFIRE_JS_VERSION = process.env.NPM_PACKAGE_VERSION;

// For convenience, expose the version so we can quickly check on the web.
// DEPRECATED
// Object.defineProperty(window, "__CAMPFIRE_APP_VERSION__", {
//   value: CAMPFIRE_JS_VERSION,
// });

Object.defineProperty(window, "__CAMPFIRE_JS_VERSION__", {
  value: CAMPFIRE_JS_VERSION,
});
