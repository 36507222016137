/**
 * @generated SignedSource<<d3de2964991053ec454358f25174b03b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscordIntegration_page_Query$variables = {};
export type DiscordIntegration_page_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"DiscordIntegrationPageMainContent_me">;
  };
};
export type DiscordIntegration_page_Query = {
  response: DiscordIntegration_page_Query$data;
  variables: DiscordIntegration_page_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscordIntegration_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DiscordIntegrationPageMainContent_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DiscordIntegration_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f31a7b384a972f888f246f0abd66d78",
    "id": null,
    "metadata": {},
    "name": "DiscordIntegration_page_Query",
    "operationKind": "query",
    "text": "query DiscordIntegration_page_Query {\n  me {\n    ...DiscordIntegrationPageMainContent_me\n    id\n  }\n}\n\nfragment DiscordIntegrationPageMainContent_me on User {\n  id\n  avatarUrl\n  displayName\n  username\n}\n"
  }
};

(node as any).hash = "6955c9e18eeb8ca344d7b423b10ae27a";

export default node;
