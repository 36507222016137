import { GENERIC_ERROR_MESSAGE, APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";

export const GENERIC_ERROR_CODE = 0;
export const GENERIC_ERROR = {
  message: GENERIC_ERROR_MESSAGE,
  extensions: {
    display: true,
    code: GENERIC_ERROR_CODE,
  },
};

export const APOLOGETIC_ERROR = {
  message: APOLOGETIC_ERROR_MESSAGE,
  extensions: {
    display: true,
    code: GENERIC_ERROR_CODE, // share error code with the generic error
  },
};
