/**
 * @generated SignedSource<<04fe31b2cfd2d8aeb8aa51873ecf0e2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Messages_page_Query$variables = {};
export type Messages_page_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"MessagesPageMainContent_me">;
  };
};
export type Messages_page_Query = {
  response: Messages_page_Query$data;
  variables: Messages_page_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Messages_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MessagesPageMainContent_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Messages_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AcceptedDisclaimers",
            "kind": "LinkedField",
            "name": "acceptedDisclaimers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "DM",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageRequest",
            "kind": "LinkedField",
            "name": "pendingMessageRequests",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Channel",
                "kind": "LinkedField",
                "name": "channel",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "members",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMessageRequestAllowed",
            "storageKey": null
          },
          (v3/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasLegalHold",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f67556cf72394f23f8c30550e8fad98",
    "id": null,
    "metadata": {},
    "name": "Messages_page_Query",
    "operationKind": "query",
    "text": "query Messages_page_Query {\n  me {\n    ...MessagesPageMainContent_me\n    id\n  }\n}\n\nfragment CampfireChat_me on User {\n  id\n  displayName\n  avatarUrl\n  hasLegalHold\n}\n\nfragment ChannelChatView_me on User {\n  id\n  displayName\n  avatarUrl\n  location\n  hasLegalHold\n  pendingMessageRequests {\n    id\n    channel {\n      id\n    }\n  }\n  ...DMChatActionsLeft_me\n  ...CampfireChat_me\n}\n\nfragment DMChatActionsLeft_me on User {\n  id\n  avatarUrl\n  username\n  displayName\n  location\n}\n\nfragment MessageRequestSettings_me on User {\n  isMessageRequestAllowed\n}\n\nfragment MessageRequestsView_me on User {\n  pendingMessageRequests {\n    id\n    channel {\n      id\n      members {\n        id\n        avatarUrl\n        username\n        displayName\n      }\n    }\n    status\n  }\n  ...MessageRequestSettings_me\n}\n\nfragment MessagesPageMainContent_me on User {\n  id\n  acceptedDisclaimers {\n    DM\n  }\n  ...MessagesPageOutletContent_me\n  ...MessagesPageSlideChangeHandler_me\n  ...MessageRequestsView_me\n  ...ChannelChatView_me\n}\n\nfragment MessagesPageOutletContent_me on User {\n  id\n  pendingMessageRequests {\n    id\n  }\n  ...MessageRequestSettings_me\n}\n\nfragment MessagesPageSlideChangeHandler_me on User {\n  id\n}\n"
  }
};
})();

(node as any).hash = "c67b23155f9759e197cb1a1a8f866e23";

export default node;
