import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import logException from "common/analytics/exceptions";
import TEST_IDS from "constants/testIds";

import UIAsyncButton from "common/components/UIAsyncButton";
import UIButton from "common/components/UIButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type OwnProps = {
  title: string;
  description?: string;
  onConfirm?: () => Promise<void> | void;
  onConfirmSuccess?: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  confirmBtnText?: string;
  cancelBtnText?: string;
  renderTitle?: () => React.ReactNode;
  renderConfirmBtn?: (isConfirming: boolean) => React.ReactNode;
  renderDescription?: () => React.ReactNode;
};

export type Props = WithTranslation & OwnProps;

type State = {
  isConfirming: boolean;
  error: string;
};

class ConfirmationDialogView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isConfirming: false,
      error: "",
    };
  }

  onConfirm = async (): Promise<void> => {
    if (this.state.isConfirming) {
      return;
    }

    this.setState({ isConfirming: true, error: "" });

    try {
      if (this.props.onConfirm) {
        await this.props.onConfirm();
      }

      this.setState({ isConfirming: false, error: "" });

      if (this.props.onConfirmSuccess) {
        this.props.onConfirmSuccess();
      }
    } catch (error) {
      this.setState({ isConfirming: false, error: error as string });
      logException("onConfirm", "onConfirm", "ConfirmationDialogView", error);
    }
  };

  render = (): React.ReactNode => {
    const { confirmBtnText, cancelBtnText, description } = this.props;
    const { isConfirming } = this.state;

    return (
      <div className={styles.root}>
        {this.props.renderTitle && this.props.renderTitle()}
        {!this.props.renderTitle && (
          <UIText color="dark" variant="h3" weight="bold">
            {this.props.title}
          </UIText>
        )}
        <UISpacer h={6} />
        {this.props.renderDescription && this.props.renderDescription()}
        {!this.props.renderDescription && description && (
          <>
            <UIText color="dark" variant="h4" weight="medium">
              {this.props.description}
            </UIText>
            <UISpacer h={6} />
          </>
        )}

        {this.props.children ? this.props.children : null}

        {!!this.state.error && (
          <>
            <UIText color="danger" variant="body2">
              {this.state.error}
            </UIText>
            <UISpacer h={16} />
          </>
        )}

        <UISpacer h={16} />

        <div className={styles.btnContainer}>
          {this.props.onCancel && (
            <>
              <UIButton
                className={styles.btn}
                expand="block"
                color="light"
                onClick={this.props.onCancel}
                dataTestId={TEST_IDS.ModalCancelBtnId}
              >
                <UIText variant="body1" weight="bold">
                  {cancelBtnText || this.props.t("CANCEL").toUpperCase()}
                </UIText>
              </UIButton>
            </>
          )}

          {this.props.onCancel && this.props.onConfirm && <UISpacer w={4} />}

          {this.props.onConfirm && (
            <>
              {this.props.renderConfirmBtn && this.props.renderConfirmBtn(isConfirming)}
              {!this.props.renderConfirmBtn && (
                <UIAsyncButton
                  className={styles.btn}
                  expand="block"
                  color="danger"
                  onClick={this.onConfirm}
                  dataTestId={TEST_IDS.ModalConfirmBtnId}
                >
                  <UIText variant="body1" weight="bold">
                    {confirmBtnText || this.props.t("CONFIRM")}
                  </UIText>
                </UIAsyncButton>
              )}
            </>
          )}
        </div>
      </div>
    );
  };
}

export default withTranslation()(ConfirmationDialogView);
