import BaseStore from "common/stores/BaseStore";

const DEFAULTS: Record<string, number> = {};

/**
 * This store is no longer being used directly. See app-setup/cleanupStores for more details.
 */
class CoachmarkStore extends BaseStore {
  constructor() {
    super("CoachmarkStore", DEFAULTS, false);
  }

  getStore(): GenericObject {
    return super.getStore();
  }
}

export default new CoachmarkStore();
