import { Geolocation, PositionOptions, Position } from "@capacitor/geolocation";

import { isIos } from "common/capacitor/helpers";
import { delay } from "common/utils/delay";

const MAX_WAIT_TIME_FOR_CALLBACK_ID_MS = 2000;

// eslint-disable-next-line no-underscore-dangle
let _geolocationPromise: Promise<Position> | null = null;

// Currently, there is a bug in capacitor when getting geolocation multiple times.
// https://github.com/ionic-team/capacitor/issues/3062.
// The work around is just to go through this interface to get geolocation and if
// a call is waiting to be resolved, return the saved call.
const getCurrentPosition = (options?: PositionOptions): Promise<Position> => {
  // If we have a geolocation request currently in flight, return that saved promise.
  if (_geolocationPromise) {
    return _geolocationPromise;
  }

  _geolocationPromise = new Promise((resolve, reject) => {
    if (isIos) {
      // https://github.com/ionic-team/capacitor/issues/1893
      // If we cold-call Geolocation.getCurrentPosition on iOS with high accuracy, it's really slow.
      // This bypasses the iOS-specific location updater startup and returns high-accuracy location immediately.
      const idPromise = Geolocation.watchPosition(options || {}, (position, err) => {
        // By the time watchPosition runs this callback, we should have resolved a callback id
        // from capacitor. If not that's ok, we can just wait till that resolves. Then we
        // should reset the _geolocationPromise to denote that we are now ok to fetch for
        // location info again.
        // In the event that for whatever reason the callback id was not
        // resolved, at most wait 2000ms before freeing up the _geolocationPromise to be hit again.
        // This ensures we never get stuck in a situation where the geolocation promise isn't freed.
        const clearWatchPromise = idPromise.then((id) => {
          Geolocation.clearWatch({ id });
        });
        const promisesToWaitOn = [clearWatchPromise, delay(MAX_WAIT_TIME_FOR_CALLBACK_ID_MS, "")];

        Promise.race(promisesToWaitOn).finally(() => {
          _geolocationPromise = null;
        });

        if (err) {
          reject(err);
          return;
        }

        if (!position) {
          reject(new Error("no position found"));
          return;
        }

        resolve(position);
      });

      return;
    }

    Geolocation.getCurrentPosition(options)
      .then((geoPosition) => {
        resolve(geoPosition);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        _geolocationPromise = null;
      });
  });

  return _geolocationPromise;
};

export default {
  getCurrentPosition,
};
