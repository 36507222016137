import { RouteComponentProps } from "react-router-dom";

import { CLUBS_ROUTE } from "constants/routes";

export const getUrlForClubs = (): string => {
  return `/${CLUBS_ROUTE}`;
};

export default (routeComponentProps: RouteComponentProps): void => {
  routeComponentProps.history.push({
    pathname: getUrlForClubs(),
  });
};
