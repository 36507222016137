import { Device } from "@capacitor/device";
import i18n, { LanguageDetectorAsyncModule } from "i18next";
import Backend from "i18next-http-backend";
import { noop } from "lodash";
import { initReactI18next } from "react-i18next";

import { isStandalone } from "common/capacitor/helpers";

import { parseQuery } from "./url";

const DEFAULT_LANGUAGE = "en";

// Blacklist Russian and Turkish
const BLACKLISTED_LANGUAGES = ["ru", "tr"];

const getLanguageFromQueryParam = (): string => {
  const queryParams = parseQuery(window.location.search);
  const lang = queryParams.language;

  return lang || DEFAULT_LANGUAGE;
};

export const getLanguage = async (): Promise<string> => {
  if (isStandalone) {
    const result = await Device.getLanguageCode();

    return result.value;
  }

  return getLanguageFromQueryParam();
};

const languageDetector: LanguageDetectorAsyncModule = {
  type: "languageDetector",
  async: true,
  detect: async (callback: (lng: string) => void) => {
    const lng = await getLanguage();
    const isBlacklisted = BLACKLISTED_LANGUAGES.some((blacklistedLng) =>
      lng.startsWith(blacklistedLng),
    );

    // If the language is blacklisted, force language as the default language.
    if (isBlacklisted) {
      callback(DEFAULT_LANGUAGE);
    } else {
      callback(lng);
    }
  },
  init: noop,
  cacheUserLanguage: noop,
};

i18n
  .use(languageDetector)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: DEFAULT_LANGUAGE,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
