import { RouteComponentProps } from "react-router-dom";

import { CLUBS_ROUTE, CLUBS_FEED_ROUTE } from "constants/routes";

export const getClubFeedRouteForClub = (clubId: string): string => {
  return `/${CLUBS_ROUTE}/${clubId}/${CLUBS_FEED_ROUTE}`;
};

export default (
  routeComponentProps: Pick<RouteComponentProps, "history" | "location">,
  clubId: string,
  replace?: boolean,
  preserveSearch?: boolean,
): void => {
  if (replace) {
    routeComponentProps.history.replace({
      pathname: getClubFeedRouteForClub(clubId),
      ...(preserveSearch && { search: routeComponentProps.location.search }),
    });
  } else {
    routeComponentProps.history.push({
      pathname: getClubFeedRouteForClub(clubId),
      ...(preserveSearch && { search: routeComponentProps.location.search }),
    });
  }
};
