import environment from "common/relay/relay-env";
import _ from "lodash";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

import logException from "common/analytics/exceptions";
import { FEATURE_FLAGS } from "constants/featureFlags";
import UpdateUserPgoNotificationSettingsMutation from "mutations/UpdateUserPgoNotificationSettingsMutation";

import NotificationGroup from "common/components/NotificationGroup";
import ToggleOption from "common/components/NotificationGroup/ToggleOption";
import NotificationSetting from "common/components/NotificationGroup/components/NotificationSetting";

import { PGORealityChannelSettings_me$data as PGORealityChannelSettingsMe } from "__generated__/PGORealityChannelSettings_me.graphql";

import styles from "./styles.scss";

type Props = WithTranslation &
  WithFeatureFlagProps & {
    me: PGORealityChannelSettingsMe;
  };

type State = {
  beaconSameLitScope: NotificationScope;
  beaconNearMeScope: NotificationScope;
  outgoingFlare: NotificationScope;
};

enum NotificationScope {
  NONE = "none",
  FRIENDS = "friends",
  ANYONE = "anyone",
}

const DEBOUNCE_TIME_MS = 1000;

class PGORealityChannelSettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const settings = props.me.notificationSettings;

    this.state = {
      beaconSameLitScope: settings.beaconSameLitScope as NotificationScope,
      beaconNearMeScope: settings.beaconNearMeScope as NotificationScope,
      outgoingFlare: settings.outgoingFlareScope as NotificationScope,
    };
  }

  setNotificationSettings = async (): Promise<void> => {
    const payload = {
      beaconSameLitScope: this.state.beaconSameLitScope,
      beaconNearMeScope: this.state.beaconNearMeScope,
      outgoingFlareScope: this.state.outgoingFlare,
    };

    try {
      await UpdateUserPgoNotificationSettingsMutation.commit(environment, payload);
    } catch (error) {
      logException(
        "UpdateUserPgoNotificationSettingsMutation",
        "setNotificationSettings",
        "PGORealityChannelSettings",
        error,
      );
    }
  };

  setNotificationSettingsDebounced = _.debounce(this.setNotificationSettings, DEBOUNCE_TIME_MS, {
    leading: false,
  });

  updateBeaconNearMeScope = (notificationScope: NotificationScope | null) => {
    if (notificationScope) {
      this.setState(
        { beaconNearMeScope: notificationScope },
        this.setNotificationSettingsDebounced,
      );
    }
  };

  updateBeaconSameLitScope = (notificationScope: NotificationScope | null) => {
    if (notificationScope) {
      this.setState(
        { beaconSameLitScope: notificationScope },
        this.setNotificationSettingsDebounced,
      );
    }
  };

  updateOutgoingFlareScope = (notificationScope: NotificationScope | null) => {
    if (notificationScope) {
      this.setState({ outgoingFlare: notificationScope }, this.setNotificationSettingsDebounced);
    }
  };

  render = (): React.ReactNode => {
    const hasEnabledFlaresNearMe = this.props.hasFeatureFlag(
      FEATURE_FLAGS.SOMEONE_LIT_A_FLARE_NEAR_ME_NOTIFICATIONS,
    );

    return (
      <div className={styles.root}>
        <NotificationGroup name={this.props.t("FLARES")}>
          {hasEnabledFlaresNearMe && (
            <NotificationSetting
              toggleable
              name={this.props.t("SOMEONE_LIGHTS_A_FLARE_NEAR_ME")}
              value={this.state.beaconNearMeScope}
              disabledValue={NotificationScope.NONE}
              onOptionChanged={this.updateBeaconNearMeScope}
            >
              <ToggleOption
                value={NotificationScope.FRIENDS}
                name={this.props.t("MY_FRIENDS_ONLY")}
              />
              <ToggleOption value={NotificationScope.ANYONE} name={this.props.t("ANYONE")} />
            </NotificationSetting>
          )}
          <NotificationSetting
            toggleable
            name={this.props.t("SOMEONE_LIGHTS_A_FLARE_I_HAVE_LIT")}
            value={this.state.beaconSameLitScope}
            disabledValue={NotificationScope.NONE}
            onOptionChanged={this.updateBeaconSameLitScope}
          >
            <ToggleOption
              value={NotificationScope.FRIENDS}
              name={this.props.t("MY_FRIENDS_ONLY")}
            />
            <ToggleOption value={NotificationScope.ANYONE} name={this.props.t("ANYONE")} />
          </NotificationSetting>
          <NotificationSetting
            name={this.props.t("OUTGOING_FLARE")}
            value={this.state.outgoingFlare}
            onOptionChanged={this.updateOutgoingFlareScope}
          >
            <ToggleOption value={NotificationScope.NONE} name={this.props.t("NOBODY")} />
            <ToggleOption
              value={NotificationScope.FRIENDS}
              name={this.props.t("MY_FRIENDS_ONLY")}
            />
            <ToggleOption value={NotificationScope.ANYONE} name={this.props.t("ANYONE")} />
          </NotificationSetting>
        </NotificationGroup>
      </div>
    );
  };
}

const FragmentContainer = createFragmentContainer(PGORealityChannelSettings, {
  me: graphql`
    fragment PGORealityChannelSettings_me on User {
      notificationSettings {
        beaconSameLitScope
        beaconNearMeScope
        outgoingFlareScope
      }
    }
  `,
});

const FeatureFlagConnected = withFeatureFlag(FragmentContainer);

export default withTranslation()(FeatureFlagConnected);
