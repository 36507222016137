import classnames from "classnames";
import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { graphql, QueryRenderer } from "react-relay";
import { withRouter, RouteComponentProps } from "react-router-dom";

import withCampfirePage, {
  WithCampfirePageProps,
} from "providers/CampfirePageProvider/withCampfirePage";
import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

import { FEATURE_FLAGS } from "constants/featureFlags";
import { APPROX_TIME_FOR_SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT_MS } from "constants/springAnimations";

import ClubUserProfileProviderMask from "common/components/ClubUserProfileProviderMask";
import EmbeddedPageWithTopBarWrapper from "common/components/EmbeddedPageWithTopBarWrapper";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import ChannelLoadError from "pages/ClubChannel/components/ChannelLoadError";

import { ClubChannel_page_Query$data as ClubChannelPageQueryResponse } from "__generated__/ClubChannel_page_Query.graphql";

import styles from "./styles.scss";

// Lazy Load Main View
const ClubChannelPageMainContent = React.lazy(
  () => import("pages/ClubChannel/components/ClubChannelPageMainContent"),
);

type Props = WithFeatureFlagProps &
  WithCampfirePageProps &
  RouteComponentProps & {
    goBack: () => void;
    clubId: string;
    channelId: string;
  };

type State = {
  readyToRender: boolean;
  initializedAt: number;
};

type QRProps = {
  me: ClubChannelPageQueryResponse["me"];
  club: ClubChannelPageQueryResponse["club"];
  channel: ClubChannelPageQueryResponse["channel"];
};

const CLUBCHANNEL_PAGE = graphql`
  query ClubChannel_page_Query($clubId: ID!, $channelId: ID!) {
    me {
      ...ClubChannelPageMainContent_me @arguments(clubId: $clubId)
    }
    club(id: $clubId) {
      ...ClubChannelPageMainContent_club
    }
    channel(id: $channelId) {
      ...ClubChannelPageMainContent_channel
    }
  }
`;

class ClubChannelPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      readyToRender: false,
      initializedAt: Date.now(),
    };
  }

  componentDidMount = (): void => {
    this.prepareToProceed();
  };

  reInit = () => {
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void) => {
    this.reInit();
    clearError();
  };

  prepareToProceed = (): void => {
    const approxTimeForSlideAnimationMs =
      APPROX_TIME_FOR_SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT_MS;

    setTimeout(() => {
      this.setState({ readyToRender: true });
    }, approxTimeForSlideAnimationMs);
  };

  render = (): React.ReactNode => {
    const { clubId, channelId } = this.props;

    const useCampfireV2 = this.props.hasFeatureFlag(FEATURE_FLAGS.CAMPFIRE_V2);

    return (
      <UIErrorBoundary onRetry={this.onRetry}>
        <Suspense fallback={<LoadingPageDefault />}>
          <EmbeddedPageWithTopBarWrapper hideTopBar>
            <QueryRenderer
              key={this.state.initializedAt}
              environment={environment}
              query={CLUBCHANNEL_PAGE}
              variables={{
                clubId,
                channelId,
              }}
              fetchPolicy="store-and-network"
              render={({ props, error }) => {
                const qrProps = props as QRProps;
                const isError = !!error;
                const isLoading = !isError && !props;

                let content = null;

                if (isError) {
                  content = <ChannelLoadError />;
                } else if (isLoading || !this.state.readyToRender) {
                  content = (
                    <LoadingPageDefault
                      delayMs={APPROX_TIME_FOR_SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT_MS}
                    />
                  );
                } else {
                  content = (
                    <ClubUserProfileProviderMask clubId={clubId}>
                      <ClubChannelPageMainContent
                        goBack={this.props.goBack}
                        me={qrProps.me}
                        club={qrProps.club}
                        channel={qrProps.channel}
                      />
                    </ClubUserProfileProviderMask>
                  );
                }

                return (
                  <div
                    className={classnames(styles.root, {
                      [styles.campfireV2]: useCampfireV2,
                    })}
                  >
                    {content}
                  </div>
                );
              }}
            />
          </EmbeddedPageWithTopBarWrapper>
        </Suspense>
      </UIErrorBoundary>
    );
  };
}

const FeatureFlagConnected = withFeatureFlag(ClubChannelPage);
const CampfirePageConnected = withCampfirePage(FeatureFlagConnected);

export default withRouter(CampfirePageConnected);
