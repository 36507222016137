import classnames from "classnames";
import React from "react";

import UIIcon, { Props as UIIconProps } from "common/components/UIIcon";

import styles from "./styles.scss";

export type Props = UIIconProps & {
  className?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLIonIconElement>) => void;
};

type State = {};

export default class UIClickableIcon extends React.Component<Props, State> {
  onClick = (event: React.MouseEvent<HTMLIonIconElement>): void => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render = (): React.ReactNode => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onClick, disabled, className, ...remainingProps } = this.props;

    return (
      <UIIcon
        {...remainingProps}
        className={classnames(styles.root, className, {
          [styles.disabled]: disabled,
        })}
        onClick={this.onClick}
      />
    );
  };
}
