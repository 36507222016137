import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

import getOAuthLoginUrl from "providers/OAuthProvider/getOAuthLoginUrl";
import withOAuth, { WithAuthenticationProps } from "providers/OAuthProvider/withOAuth";

import logException from "common/analytics/exceptions";
import { isWeb, isIos } from "common/capacitor/helpers";
import OnboardingStore from "common/stores/OnboardingStore";
import { capitalizeEachWord } from "common/utils/capitalization";
import constantsConfig from "constants/config";
import { NIANTIC_HELPSHIFT_ACCOUNT_LINKING_URL, NIANTIC_TERMS_URL } from "constants/urls";

import PrivacyFooter from "common/components/PrivacyFooter";
import UIAsyncButton from "common/components/UIAsyncButton";
import UIDialog from "common/components/UIDialog";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import AuthProviderIcon from "../AuthProviderIcon";

import styles from "./styles.scss";

type Mode = "signup" | "authenticate";
type Props = WithTranslation &
  WithAuthenticationProps & {
    isOpen: boolean;
    close: () => void;
    mode?: Mode;
    onLogin?: () => Promise<void>; // TODO: Implement when we can login
    onSignup?: () => Promise<void>;
    onAuthenticate?: () => Promise<void>; // Runs after signup or login
    showSuspensionDialog: (time: number) => void;
    showPermanentBanDialog: () => void;
    showGeneralAuthErrorDialog: (notAllowed?: boolean) => void;
  };

type State = {
  authenticating: boolean;
};

const CONFIG: Record<Mode, GenericObject> = {
  signup: {
    title: "SIGN_UP",
    tos: "SIGN_UP_TOS",
    googleBtnText: "SIGN_UP_WITH_GOOGLE",
    facebookBtnText: "CONTINUE_WITH_FACEBOOK",
    appleBtnText: "SIGN_UP_WITH_APPLE",
  },
  authenticate: {
    title: "SIGN_IN",
    tos: "SIGN_IN_TOS",
    googleBtnText: "SIGN_IN_WITH_GOOGLE",
    facebookBtnText: "CONTINUE_WITH_FACEBOOK",
    appleBtnText: "SIGN_IN_WITH_APPLE",
  },
};

const PermanentBanErrorMessage = "banned";
const SuspendedErrorMessage = "suspended";
const NotAllowedErrorMessage = "access is currently limited";
const CanceledErrorMessage = "canceled";
const AppleSignInCanceledErrorMessage = "error 1001";

class SignupOrLoginDialog extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      authenticating: false,
    };
  }

  signup = async (provider: SupportedOAuthProvider) => {
    if (isWeb) {
      let url;

      if (provider === "google") {
        url = constantsConfig.get("CAMPFIRE_APP_GOOGLE_OAUTH_LOGIN");
      } else if (provider === "facebook") {
        url = constantsConfig.get("CAMPFIRE_APP_FACEBOOK_OAUTH_LOGIN");
      } else if (provider === "apple") {
        logException(
          "authenticateWithOAuth",
          "signup",
          "SignupOrLoginDialog",
          "web login with apple not supported",
        );
      }

      if (url) {
        // By the time we get here, birthday has been set in OnboardingStore and it should have an actual value
        const birthday = OnboardingStore.get("initialLaunchBirthday");

        window.location.href = getOAuthLoginUrl(url, birthday, window.location.href);
      }

      return;
    }

    if (this.state.authenticating) return;

    this.setState({
      authenticating: true,
    });

    try {
      await this.props.authenticateWithOAuth(provider);

      if (this.props.onSignup) {
        await this.props.onSignup();
      }

      if (this.props.onAuthenticate) {
        await this.props.onAuthenticate();
      }

      this.setState({ authenticating: false });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.setState({ authenticating: false });
      this.handleAuthError(error);
      logException("authenticateWithOAuth", "signup", "SignupOrLoginDialog", error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAuthError = (error: any): void => {
    if (error.response?.data) {
      // Handle an error from our backend
      const errorMsg = error.response.data.toLowerCase();

      if (errorMsg.includes(PermanentBanErrorMessage)) {
        this.props.showPermanentBanDialog();
      } else if (errorMsg.includes(SuspendedErrorMessage)) {
        const expiresAtMs = errorMsg.split(":")[1] || "";
        const expiresAt = expiresAtMs ? parseInt(expiresAtMs, 10) : 0;

        this.props.showSuspensionDialog(expiresAt);
      } else if (errorMsg.includes(NotAllowedErrorMessage)) {
        this.props.showGeneralAuthErrorDialog(true);
      } else {
        this.props.showGeneralAuthErrorDialog();
      }
    } else {
      // Handle error from client oauth plugin

      // Do nothing if user intentionally canceled
      // FB doesn't return an error so can't distinguish for this case
      if (
        error.errorMessage?.toLowerCase().includes(CanceledErrorMessage) ||
        error.errorMessage?.toLowerCase().includes(AppleSignInCanceledErrorMessage)
      ) {
        return;
      }

      this.props.showGeneralAuthErrorDialog();
    }
  };

  onDidDismiss = (): void => {
    this.setState({
      authenticating: false,
    });
    this.props.close();
  };

  render = (): React.ReactNode => {
    const { mode = "authenticate" } = this.props;

    const config = CONFIG[mode];

    return (
      <UIDialog
        isOpen={this.props.isOpen}
        cssClass={styles.root}
        close={this.props.close}
        onDidDismiss={this.onDidDismiss}
      >
        <div className={styles.content}>
          <UIText variant="h2" color="dark" weight="extraBold">
            {capitalizeEachWord(this.props.t(config.title))}
          </UIText>

          <UISpacer h={50} />

          {isIos && (
            <UIAsyncButton
              className={styles.appleBtn}
              btnContentClassName={styles.btnContent}
              onClick={async () => this.signup("apple")}
              expand="block"
            >
              <AuthProviderIcon mode="button" provider="apple" />

              <UIText className={styles.loginBtnText}>
                {capitalizeEachWord(this.props.t(config.appleBtnText))}
              </UIText>
            </UIAsyncButton>
          )}

          <UIAsyncButton
            className={styles.facebookBtn}
            btnContentClassName={styles.btnContent}
            onClick={async () => this.signup("facebook")}
            expand="block"
          >
            <AuthProviderIcon mode="button" provider="facebook" />

            <UIText className={styles.loginBtnText}>
              {capitalizeEachWord(this.props.t(config.facebookBtnText))}
            </UIText>
          </UIAsyncButton>

          <UIAsyncButton
            className={styles.googleBtn}
            btnContentClassName={styles.btnContent}
            onClick={async () => this.signup("google")}
            expand="block"
          >
            <AuthProviderIcon mode="button" provider="google" />

            <UIText className={styles.googleLoginBtnText}>
              {capitalizeEachWord(this.props.t(config.googleBtnText))}
            </UIText>
          </UIAsyncButton>

          <UISpacer h={40} />

          <div className={styles.termsOfService}>
            <UIText variant="body2" color="medium">
              <Trans i18nKey={config.tos}>
                By signing (up/in), you agree to Niantic&apos;s
                <UILink href={NIANTIC_TERMS_URL} className={styles.link}>
                  Terms of Service
                </UILink>
              </Trans>
            </UIText>
          </div>

          <UISpacer h={24} />

          <div className={styles.termsOfService}>
            <UIText variant="body2" color="medium">
              <UILink href={NIANTIC_HELPSHIFT_ACCOUNT_LINKING_URL} className={styles.link}>
                {this.props.t("NEED_OTHER_WAYS_TO_SIGN_IN")}
              </UILink>
            </UIText>
          </div>

          <UISpacer h={32} />

          <PrivacyFooter />
        </div>
      </UIDialog>
    );
  };
}

const AuthConnected = withOAuth(SignupOrLoginDialog);

export default withTranslation()(AuthConnected);
