import { RouteComponentProps } from "react-router-dom";

import { getQueryString } from "common/utils/url";
import { MAP_ROUTE, MapAction, QUERY_PARAMS } from "constants/routes";

import { serializeActionData } from "./mapParamHelpers";

export const getUrlForMapPage = (): string => {
  return `/${MAP_ROUTE}`;
};

const getQueryParamUpdatesForCreatePost = (
  photoId: string,
  unModeratedPhotoId: string,
  game: string,
  showSharePrompt: string,
  lat?: number | string,
  lng?: number | string,
): { lat?: string; lng?: string; action: string; actionData: string } => {
  const queryParamUpdates = {
    ...(lat && { [QUERY_PARAMS.map.lat.key]: lat.toString() }),
    ...(lng && { [QUERY_PARAMS.map.lng.key]: lng.toString() }),
    [QUERY_PARAMS.map.action.key]: MapAction.SHOW_CREATE_POST,
    [QUERY_PARAMS.map.actionData.key]: serializeActionData({
      game,
      photoId,
      unModeratedPhotoId,
      showSharePrompt,
    }),
  };

  return queryParamUpdates;
};

/**
 * Gets a full url to use to route to the map page and display the create post UI.
 */
export const getRouteForMapPageCreatePost = (
  photoId: string,
  unModeratedPhotoId: string,
  game: string,
  showSharePrompt: string,
  lat?: number | string,
  lng?: number | string,
): string => {
  const queryParams: Record<string, string> = getQueryParamUpdatesForCreatePost(
    photoId,
    unModeratedPhotoId,
    game,
    showSharePrompt,
    lat,
    lng,
  );

  const queryString: string = Object.keys(queryParams)
    .filter((key) => !!queryParams[key])
    .map((key) => `${key}=${queryParams[key] || ""}`)
    .join("&");

  const searchPart = queryString ? `?${queryString}` : "";

  return `/${MAP_ROUTE}${searchPart}`;
};

/**
 * Routes to the map page and displays the create post UI.
 * Preserves existing search params.
 */
export const routeToMapPageAndCreatePostCore = (
  history: RouteComponentProps["history"],
  photoId: string,
  unModeratedPhotoId: string,
  game: string,
  showSharePrompt: string,
  lat?: number | string,
  lng?: number | string,
): void => {
  const queryParamUpdates = getQueryParamUpdatesForCreatePost(
    photoId,
    unModeratedPhotoId,
    game,
    showSharePrompt,
    lat,
    lng,
  );
  const search = getQueryString(queryParamUpdates, history.location.search);

  history.push({
    pathname: getUrlForMapPage(),
    search,
  });
};

const routeToMapPageAndCreatePost = (
  history: RouteComponentProps["history"],
  photoId: string,
  unModeratedPhotoId: string,
  game: string,
  showSharePrompt: string,
  lat?: number,
  lng?: number,
): void => {
  routeToMapPageAndCreatePostCore(
    history,
    photoId,
    unModeratedPhotoId,
    game,
    showSharePrompt,
    lat,
    lng,
  );
};

export default routeToMapPageAndCreatePost;
