import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { UpdateIsIncludeAsFriendSuggestionAllowedMutation$data } from "__generated__/UpdateIsIncludeAsFriendSuggestionAllowedMutation.graphql";

type ReturnType =
  UpdateIsIncludeAsFriendSuggestionAllowedMutation$data["updateIsIncludeAsFriendSuggestionAllowed"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;

type InputType = {
  isAllowed: boolean;
};

const mutation = graphql`
  mutation UpdateIsIncludeAsFriendSuggestionAllowedMutation(
    $input: UpdateIsIncludeAsFriendSuggestionAllowedInput!
  ) {
    updateIsIncludeAsFriendSuggestionAllowed(input: $input) {
      success
      me {
        ...UserFriendSuggestionSettings_me
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors =
        _.get(response, "updateIsIncludeAsFriendSuggestionAllowed.errors.length", 0) > 0;

      if (hasErrors) {
        reject(
          _.get(response, "updateIsIncludeAsFriendSuggestionAllowed.errors[0].message", errors),
        );

        return;
      }

      resolve(_.get(response, "updateIsIncludeAsFriendSuggestionAllowed"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          isAllowed: input.isAllowed,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
