import { isEmpty, maxBy, merge } from "lodash";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { Flow } from "../../types/flows";
import objectsHaveSameKeys from "../../utils/objectsHaveSameKeys";
import validateFlows from "../../utils/validateFlows";

import { getCoachmarksInStore, setCoachmarkInStore } from "./store";

type CoachmarkCompletionMap = Record<string, number>;
type CoachmarksForFlow = Record<string, boolean>;
type ActiveCoachmarks = Record<string, CoachmarksForFlow>;

export type Props = {
  children: React.ReactNode;
  flows: Record<string, Flow>;
  // key is the coachmark name, value denotes when the coachmark was completed in ms.
  coachmarks: CoachmarkCompletionMap;
  // Overrides the default name of the local storage key that tracks coachmarks
  storeName?: string;
  markCoachmarkCompleteOnServer?: (coachmarkName: string) => Promise<void>;
};

export type ExposedProps = {
  instanceId: string;
  currentFlow: Flow | null;
  currentCoachmark: string | null;
  registerCoachmark: (flowName: string, coachmarkName: string) => void;
  unregisterCoachmark: (flowName: string, coachmarkName: string) => void;
  evaluateNextCoachmark: () => void;
  markCoachmarkAsComplete: (
    coachmarkName: string,
    asyncCallback?: () => Promise<void>,
  ) => Promise<void>;
  isCoachmarkActive: (coachmarkName: string, flowsToCheck?: string[]) => boolean;
  isFlowComplete: (flowName: string) => boolean;
  forceEnterFlow: (flowName: string) => void;
};

export type State = {
  instanceId: string;
  currentFlowName: string | null;
  currentCoachmark: string | null;
  forcedFlow: boolean;
  forcedFlowCoachmarks: Record<string, number>;
};

const INITIAL_CONTEXT: ExposedProps = {
  instanceId: uuidv4(),
  currentFlow: null,
  currentCoachmark: null,
  registerCoachmark: () => undefined,
  unregisterCoachmark: () => undefined,
  evaluateNextCoachmark: () => undefined,
  markCoachmarkAsComplete: () => Promise.resolve(),
  isCoachmarkActive: () => false,
  isFlowComplete: () => false,
  forceEnterFlow: () => undefined,
};

const CoachmarkContext = React.createContext(INITIAL_CONTEXT);
const DEFAULT_STATE = Object.freeze({
  currentFlowName: null,
  currentCoachmark: null,
  forcedFlow: false,
  forcedFlowCoachmarks: {},
});

const COACHMARK_ATTENDANCE_DELAY_MS = 1500;

/**
 * Coachmark System v1.0
 * =====================
 * The best way to think of the Coachmark system is that it's akin to giving a presentation
 * in school.
 *
 * When class begins, a teacher looks at the classroom to see which students are present.
 * Once known, the teacher will look at a list to know who should present first.
 *
 * Essentially, our components will declare themselves as present by registering with the Provider
 * when they render. They will provide which "student" they are during that process.
 * A student is a "flow" in the Coachmark system. Then after things have settled, the provider
 * will look at the priority and other prerequisites to determine which student/flow should present
 * first for those currently in the classroom.
 *
 * Advanced features:
 * ==================
 * - Interruptability
 *     The concept is simple. Can a student be interrupted during their presentation?
 *     Some students are nice and will allow another student to present if an emergency comes up.
 *     However, some are not so nice, and refuse to stop presenting until they are finished.
 *     So the Coachmark system can jump from a flow that is interruptable to another. However, if
 *     the current flow is NOT interruptable, it cannot jump to another flow until complete.
 *
 *     Used when needing to do very important flows at a given time or complex flows
 *     that span multiple pages/views.
 *
 * - Required Flows
 *     A Flow can delay its showing based on other flows needing have been completed first.
 *     NOTE: This does not chain infinitely, as a flow is simply considered complete if its
 *     coachmarks are done.
 *
 * - Forced Enter Flows
 *     We can forcibly enter a flow, or restart a flow that would normally be considered complete.
 */
export class CoachmarkProvider extends React.Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attendanceTimer: any = null;

  // A map of the active flows and coachmarks currently that exist in the view.
  activeCoachmarks: ActiveCoachmarks = {};

  constructor(props: Props) {
    super(props);

    // Extract flows from props.
    const flows: Flow[] = Object.values(props.flows);

    this.state = {
      instanceId: uuidv4(),
      ...DEFAULT_STATE,
    };

    this.initialize(flows);
  }

  componentDidUpdate = (prevProps: Props): void => {
    // If the flows have changed, we need to reset the coachmark provider
    if (!objectsHaveSameKeys(prevProps.flows, this.props.flows)) {
      this.reset();
    }
  };

  /**
   * Validates the flows are properly configured and sets up a place for each flow to denote
   * active coachmarks currently in the app.
   */
  initialize = (flows: Flow[]): void => {
    if (!validateFlows(flows)) {
      throw new Error("CoachmarkProvider initialization failure!");
    }

    // We have a valid configuration
    // Add each flow to the active flow map
    flows.forEach((flow) => {
      const flowName = flow.name;

      this.activeCoachmarks[flowName] = {};
    });
  };

  /**
   * Sets all state variables and other state related items to their default values.
   */
  reset = (): void => {
    const flows: Flow[] = Object.values(this.props.flows);

    // Reset State, create a new instance ID to communicate to <Coachmark> components to re-register
    this.setState({
      instanceId: uuidv4(),
      ...DEFAULT_STATE,
    });

    this.activeCoachmarks = {};

    // Stop the attendance timer
    if (this.attendanceTimer) {
      clearTimeout(this.attendanceTimer);
      this.attendanceTimer = false;
    }

    // Re-init and find the next coachmark
    this.initialize(flows);
    this.evaluateNextCoachmark();
  };

  getCurrentFlow = (): Flow | null => {
    return this.state.currentFlowName ? this.props.flows[this.state.currentFlowName] : null;
  };

  /**
   * Determines if the current flow can be interrupted.
   *
   * Forced flows act as un-interruptable flows.
   */
  currentFlowIsNotInterruptable = (): boolean => {
    const currentFlow = this.getCurrentFlow();
    const currentFlowIsForced = currentFlow && this.state.forcedFlow;
    const currentFlowIsNotInterruptable = currentFlow && !currentFlow.interruptable;

    return !!(currentFlowIsForced || currentFlowIsNotInterruptable);
  };

  /**
   * Returns all completed coachmark names as the keys in a map.
   */
  getCompletedCoachmarks = (): CoachmarkCompletionMap => {
    const locallySavedCoachmarks = getCoachmarksInStore(this.props.storeName);

    // Combine the set of coachmarks from props with those saved locally to determine
    // the full set of coachmarks
    const forcedFlowOverrides = this.state.forcedFlowCoachmarks || {};
    const completedCoachmarks = merge(
      {},
      locallySavedCoachmarks,
      this.props.coachmarks,
      forcedFlowOverrides,
    );

    return completedCoachmarks;
  };

  /**
   * Checks if the given flow is complete.
   *
   * NOTE:
   * - Completion ONLY requires coachmarks to be set.
   * - The required flows DO NOT NEED TO ALSO be complete for a given flow to be considered complete.
   */
  isFlowComplete = (flowName: string | null): boolean => {
    if (!flowName) {
      return false;
    }

    const flow = this.props.flows[flowName];

    // Determine which coachmarks are completed.
    const completedCoachmarks = this.getCompletedCoachmarks();

    if (flow) {
      const flowCoachmarks = flow.steps;

      // Check that all the coachmarks that compose the flow are completed
      return flowCoachmarks.every((coachmarkName) => {
        return !!completedCoachmarks[coachmarkName];
      });
    }

    return true;
  };

  /**
   * Checks if all the required flows for a given flow are complete.
   */
  hasCompletedPrerequisites = (flowName: string): boolean => {
    const flow = this.props.flows[flowName];

    const requiredFlows = flow.requires;

    if (requiredFlows) {
      return requiredFlows.every((requiredFlowName) => {
        return this.isFlowComplete(requiredFlowName);
      });
    }

    return true;
  };

  /**
   * Finds the next coachmark to show for a given flow based on what the user has completed.
   */
  getNextCoachmarkInFlow = (flowName: string | null): string | null => {
    if (!flowName) {
      return null;
    }

    const flow = this.props.flows[flowName];
    const completedCoachmarks = this.getCompletedCoachmarks();

    // Reduce the set of steps to the incomplete steps
    const incompleteSteps = flow.steps.filter(
      (coachmarkName) => !completedCoachmarks[coachmarkName],
    );

    // Return the first step that is incomplete
    return incompleteSteps.length ? incompleteSteps[0] : null;
  };

  /**
   * Returns a set of flows that have an active coachmark currently registered.
   */
  getActiveFlows = (): Flow[] => {
    const activeFlowNames = Object.keys(this.activeCoachmarks) as string[];
    const getFlowFromFlowName = (activeFlowName: string): Flow => {
      return this.props.flows[activeFlowName];
    };
    const isActive = (flow: Flow): boolean => {
      const activeFlowForName = this.activeCoachmarks[flow.name];

      return !isEmpty(activeFlowForName);
    };
    const activeFlows = activeFlowNames.map(getFlowFromFlowName).filter(isActive);

    return activeFlows;
  };

  /**
   * Gets the presentable flows that are currently active.
   *
   * Presentable flows are flows that contain a coachmark that is both active and NEXT in the flow.
   * As an example, a flow with steps [1, 2, 3] is NOT presentable if only step 2 is active,
   * but will be presentable if step 1 is complete.
   */
  getPresentableActiveFlows = (): Flow[] => {
    const activeFlows = this.getActiveFlows();

    // Filter for flows that have met pre-requisites to be showing
    // Filter for flows that have the next coachmark in the active set
    return activeFlows
      .filter((flow) => this.hasCompletedPrerequisites(flow.name))
      .filter((flow) => {
        const nextCoachmarkForFlow = this.getNextCoachmarkInFlow(flow.name);

        // If no next coachmark, this flow is completed
        if (!nextCoachmarkForFlow) {
          return false;
        }

        const activeCoachmarksForFlow = this.activeCoachmarks[flow.name] || {};

        return activeCoachmarksForFlow[nextCoachmarkForFlow];
      });
  };

  /**
   * Determines whether the given coachmark is currently active.
   * Optionally verifies against specific flows since a single coachmark
   * can be part of multiple flows.
   */
  isCoachmarkActive = (coachmarkName: string, flowsToCheck: string[] = []): boolean => {
    const isCurrentCoachmark = this.state.currentCoachmark === coachmarkName;

    if (!flowsToCheck.length) {
      return isCurrentCoachmark;
    }

    // If passed flows, verify that the coachmark and flow match
    const isCurrentFlow = this.state.currentFlowName
      ? flowsToCheck.includes(this.state.currentFlowName)
      : false;

    return isCurrentFlow && isCurrentCoachmark;
  };

  clearCurrentCoachmark = (): void => {
    // If the current flow cannot be stopped, maintain it as the current flow
    const currentFlowIsNotInterruptable = this.currentFlowIsNotInterruptable();
    const { currentFlowName } = this.state;

    this.setState({
      currentCoachmark: null,
      currentFlowName: currentFlowIsNotInterruptable ? currentFlowName : null,
    });
  };

  /**
   * Marks a coachmark as complete.
   *
   * If the current flow is being forced, and after marking is now complete, this method will also
   * clear out the forced flow.
   */
  markCoachmarkAsComplete = async (
    coachmarkName: string,
    asyncCallback?: () => Promise<void>,
  ): Promise<void> => {
    const completedAt = Date.now();

    setCoachmarkInStore(coachmarkName, completedAt, this.props.storeName);

    // If we have provided a callback to sync this coachmark to the backend, invoke it
    // as a side effect. Don't wait for it.
    if (this.props.markCoachmarkCompleteOnServer) {
      this.props.markCoachmarkCompleteOnServer(coachmarkName);
    }

    // If we are in a forced flow, mark the override as well
    if (this.state.forcedFlow) {
      const isForcedFlowComplete = this.isFlowComplete(this.state.currentFlowName);

      if (isForcedFlowComplete) {
        this.reset();

        return;
      }

      const currentForcedFlowCoachmarks = this.state.forcedFlowCoachmarks;

      this.setState({
        forcedFlowCoachmarks: {
          ...currentForcedFlowCoachmarks,
          [coachmarkName]: completedAt,
        },
      });
    }

    // If the coachmark we are clearing is the current, also unset that
    if (this.state.currentCoachmark === coachmarkName) {
      this.clearCurrentCoachmark();
    }

    // Perform some async operation. Usually to update flags on the BE
    if (asyncCallback) {
      await asyncCallback();
    }
  };

  /**
   * Registers a coachmark for a given flow with the provider.
   *
   * This is basically used when a component renders, and announces itself as important for the provider to
   * consider when deciding which coachmark should show.
   */
  registerCoachmark = (flowName: string, coachmarkName: string): void => {
    if (!this.activeCoachmarks[flowName]) {
      return;
    }

    // If we have a timer setup, reset it
    if (this.attendanceTimer) {
      clearTimeout(this.attendanceTimer);
      this.attendanceTimer = false;
    }

    const activeFlow = this.activeCoachmarks[flowName] || {};

    // Register this coachmark as a currently possible coachmark if not already
    if (!activeFlow[coachmarkName]) {
      activeFlow[coachmarkName] = true;
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `Coachmark Provider Warning: The coachmark ${flowName}:${coachmarkName} has already been registered!`,
      );
    }

    // Start the timer again
    this.attendanceTimer = setTimeout(this.evaluateNextCoachmark, COACHMARK_ATTENDANCE_DELAY_MS);
  };

  /**
   * Unregisters a coachmark for a given flow with the provider.
   *
   * This is basically used when a component unmounts, and announces itself as not important for the provider to
   * consider when deciding which coachmark should show.
   */
  unregisterCoachmark = (flowName: string, coachmarkName: string): void => {
    const activeFlow = this.activeCoachmarks[flowName] || {};

    // If we have a timer setup, reset it
    if (this.attendanceTimer) {
      clearTimeout(this.attendanceTimer);
      this.attendanceTimer = false;
    }

    // NOTE: This should be ok, even if called multiple times since the change to state is the same if this condition is met.
    // (considering React State batching).
    // If we are unregistering the currentCoachmark, set the currentCoachmark to null
    if (this.state.currentFlowName === flowName && this.state.currentCoachmark === coachmarkName) {
      this.clearCurrentCoachmark();
    }

    // Remove the available coachmark from the active set
    if (activeFlow) {
      delete activeFlow[coachmarkName];
    }

    // Start the timer again
    this.attendanceTimer = setTimeout(this.evaluateNextCoachmark, COACHMARK_ATTENDANCE_DELAY_MS);
  };

  /**
   * Finds the current flow and coachmark the app should show.
   *
   * Updates state with those values.
   *
   * NOTE: This is where most of the magic for determining which flow to show happens!
   */
  evaluateNextCoachmark = (): void => {
    // We need to find the flow and coachmark that should be presenting based on what is available

    // If the current flow cannot be stopped, maintain it as the current flow
    const currentFlowIsNotInterruptable = this.currentFlowIsNotInterruptable();

    // (2): Non-interruptable Flow
    // If we are in an un-interruptable flow, there's no need to find the current flow, we know what it is!
    // If the current flow is forced, then we treat it like a non-interruptable flow.
    if (currentFlowIsNotInterruptable) {
      const nextCoachmarkForFlow = this.getNextCoachmarkInFlow(this.state.currentFlowName);

      // If there is a next coachmark for the locked flow update to the next coachmark.
      // Otherwise, the flow must be complete. So reset and re-evaluate. (This jumps you to (1). See below)
      if (nextCoachmarkForFlow) {
        this.setState({ currentCoachmark: nextCoachmarkForFlow });
      } else {
        this.setState(DEFAULT_STATE, this.evaluateNextCoachmark);
      }
    } else {
      // (1): Interruptable or null Flow
      // Find the set of flows that are presentable given the coachmarks that are active.
      const presentableFlows = this.getPresentableActiveFlows();

      // Order the presentable flows by priorityLevel, we want the highest value
      const mostSignificantFlow: Flow | undefined = maxBy(
        presentableFlows,
        (flow) => flow.priorityLevel,
      );

      // If we have a flow to enter, enter it.
      // Otherwise, since we aren't locked and also there is no flow to show, clear it out.
      if (mostSignificantFlow) {
        const nextCoachmarkForFlow = this.getNextCoachmarkInFlow(mostSignificantFlow.name);

        this.setState({
          currentFlowName: mostSignificantFlow.name,
          currentCoachmark: nextCoachmarkForFlow,
        });
      } else {
        this.setState(DEFAULT_STATE);
      }
    }
  };

  /**
   * Forces a flow to show from the beginning, regardless of current completion status.
   *
   * Also makes that flow un-interruptable.
   */
  forceEnterFlow = (flowName: string): void => {
    const flow = this.props.flows[flowName];
    const incompleteCoachmarksForFlow = flow.steps.reduce((result, coachmarkName) => {
      return {
        ...result,
        [coachmarkName]: 0,
      };
    }, {});

    this.setState(
      {
        currentFlowName: flowName,
        forcedFlow: true,
        forcedFlowCoachmarks: incompleteCoachmarksForFlow,
      },
      this.evaluateNextCoachmark,
    );
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <CoachmarkContext.Provider
        // Intentional as getCurrentFlow() may change
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          instanceId: this.state.instanceId,
          currentFlow: this.getCurrentFlow(),
          currentCoachmark: this.state.currentCoachmark,
          registerCoachmark: this.registerCoachmark,
          unregisterCoachmark: this.unregisterCoachmark,
          evaluateNextCoachmark: this.evaluateNextCoachmark,
          markCoachmarkAsComplete: this.markCoachmarkAsComplete,
          isCoachmarkActive: this.isCoachmarkActive,
          isFlowComplete: this.isFlowComplete,
          forceEnterFlow: this.forceEnterFlow,
        }}
      >
        {children}
      </CoachmarkContext.Provider>
    );
  };
}

export const CoachmarkConsumer = CoachmarkContext.Consumer;
