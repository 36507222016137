/**
 * @generated SignedSource<<49817c2047e0b6dd6bfab713a230edf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type fetchRealityChannelByGame_Query$variables = {
  gameName: string;
};
export type fetchRealityChannelByGame_Query$data = {
  readonly realityChannelForEmbedGame: {
    readonly iconURL: string;
    readonly id: string;
    readonly metadata: {
      readonly game: string;
      readonly googleMapsPlatformMapId: string | null;
      readonly isFirstParty: boolean;
      readonly splashImageUrl: string | null;
    } | null;
    readonly name: string;
    readonly sources: ReadonlyArray<{
      readonly dropTypes: ReadonlyArray<string>;
      readonly name: string;
    }>;
    readonly welcomeBanner: {
      readonly bodyMd: string;
      readonly imgURL: string;
      readonly location: {
        readonly latitude: number | null;
        readonly longitude: number | null;
      } | null;
      readonly title: string;
    } | null;
  };
};
export type fetchRealityChannelByGame_Query = {
  response: fetchRealityChannelByGame_Query$data;
  variables: fetchRealityChannelByGame_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gameName"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "gameName"
      }
    ],
    "concreteType": "RealityChannel",
    "kind": "LinkedField",
    "name": "realityChannelForEmbedGame",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "iconURL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RealityChannelSource",
        "kind": "LinkedField",
        "name": "sources",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dropTypes",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RealityChannelMetadata",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFirstParty",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "game",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "googleMapsPlatformMapId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "splashImageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RealityChannelBanner",
        "kind": "LinkedField",
        "name": "welcomeBanner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bodyMd",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imgURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Location",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latitude",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "longitude",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchRealityChannelByGame_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchRealityChannelByGame_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "13ed8a9083da5a23dc83fede4e6c85ec",
    "id": null,
    "metadata": {},
    "name": "fetchRealityChannelByGame_Query",
    "operationKind": "query",
    "text": "query fetchRealityChannelByGame_Query(\n  $gameName: String!\n) {\n  realityChannelForEmbedGame(name: $gameName) {\n    id\n    name\n    iconURL\n    sources {\n      name\n      dropTypes\n    }\n    metadata {\n      isFirstParty\n      game\n      googleMapsPlatformMapId\n      splashImageUrl\n    }\n    welcomeBanner {\n      title\n      bodyMd\n      imgURL\n      location {\n        latitude\n        longitude\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ffa5d0c3305eea5441d7407d7aa11452";

export default node;
