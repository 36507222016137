import { cloneDeep } from "lodash";

import { AutoDefinedFlow, Flow, FlowName } from "../types/flows";

const BASE_PRIORITY_LEVEL = 1000;

/**
 * This method automatically assigns priority levels to the provided flows.
 *
 * An array is used to convey priority. Lower index = Higher priority level
 *
 * Flows are "visually" grouped below to convey a sense of relative priorities for a set of flows.
 *
 * @param flows An array of flows to be prioritized
 * @returns An object of flows to be given to the CoachmarkProvider
 */
const autoAssignPriorityLevels = (flows: AutoDefinedFlow[]): Record<FlowName, Flow> => {
  // Clone the array since we are going to reverse this in place.
  const clonedFlows: AutoDefinedFlow[] = cloneDeep(flows);

  // Reverse the order, since we need HIGHER priority level, but we want lower index to be more important.
  return clonedFlows.reverse().reduce((allFlows, flow, idx) => {
    const modifiedFlow = {
      ...flow,
      priorityLevel: BASE_PRIORITY_LEVEL + idx,
    };

    return {
      ...allFlows,
      [flow.name]: modifiedFlow,
    };
  }, {} as Record<FlowName, Flow>);
};

export default autoAssignPriorityLevels;
