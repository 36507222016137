import environment from "common/relay/relay-env";
import React, { useCallback, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ReportDialogContext } from "providers/ReportDialogProvider";

import logException from "common/analytics/exceptions";
import ReportContext from "constants/reportContext";
import { NIANTIC_GUIDELINES_URL } from "constants/urls";
import ReportUserMutation from "mutations/ReportUserMutation";

import UIAvatar from "common/components/Avatar/UIAvatar";
import { ReportDialogConfig } from "common/components/Reporting/components/ReportDialog";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = {
  avatarUrl: string;
  displayName: string;
  username: string;
  userId: string;
  reportContext?: ReportContext;
};

type HookReturnType = () => void;

function useReportUser(props: Props): HookReturnType {
  const { showReportDialog } = useContext(ReportDialogContext);
  const { t } = useTranslation();

  const renderReportDescription = (): React.ReactNode => {
    return (
      <UIText color="dark" variant="h4" weight="medium">
        <Trans i18nKey="REPORT_USER_DESCRIPTION">
          Report this user to Niantic if they&apos;re breaking the
          <UILink href={NIANTIC_GUIDELINES_URL} className={styles.link}>
            <UIText color="primary" variant="h4">
              Community Guidelines
            </UIText>
          </UILink>
        </Trans>
      </UIText>
    );
  };

  const renderReportUserContent = (): React.ReactNode => {
    return (
      <div>
        <div className={styles.userReportContent}>
          <UIAvatar
            allowRandomBgColor
            size={48}
            avatarUrl={props.avatarUrl}
            name={props.displayName}
          />
          <UISpacer w={10} />
          <div className={styles.textParent}>
            <UIText variant="h4" weight="bold" color="dark">
              {props.displayName}
            </UIText>
            <UISpacer h={2} />
            <UIText variant="h4" weight="medium" color="dark">
              {`@${props.username}`}
            </UIText>
          </div>
        </div>
      </div>
    );
  };

  const reportUser = async (
    reason: number,
    customReportReason: string,
    reportContext?: ReportContext,
  ): Promise<boolean> => {
    const payload = {
      userId: props.userId,
      reason,
      customReportReason,
      reportContext: reportContext ?? ReportContext.UNKNOWN,
    };

    try {
      const response = await ReportUserMutation.commit(environment, payload);
      const { success } = response;

      return success;
    } catch (error) {
      logException("ReportUserMutation", "reportUser", "UserReportDialog", error);
      return false;
    }
  };

  return useCallback(() => {
    const config: ReportDialogConfig = {
      title: t("REPORT_USER_TITLE"),
      renderDescription: renderReportDescription,
      renderReportedContent: renderReportUserContent,
      reportReasonPrompt: t("WHY_REPORT_USER"),
      report: (reason, customReportReason) =>
        reportUser(reason, customReportReason, props.reportContext),
      confirmationShowsBlock: true,
      userId: props.userId,
      displayName: props.displayName,
    };

    if (props.avatarUrl !== "") {
      config.imageUrl = props.avatarUrl;
    }

    showReportDialog(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useReportUser;
