/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import {
  UserInteractionHistoryConsumer,
  ExposedProps,
} from "providers/UserInteractionHistoryProvider";

export type WithUserInteractionHistoryProps = {
  userInteraction: {
    hasSeen: ExposedProps["hasSeen"];
    markInteraction: ExposedProps["markInteraction"];
  };
};

// eslint-disable-next-line max-len
export default <P extends WithUserInteractionHistoryProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithUserInteractionHistoryProps>): React.ReactElement => (
    <UserInteractionHistoryConsumer>
      {({ hasSeen, markInteraction }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          userInteraction={{
            hasSeen,
            markInteraction,
          }}
          {...props}
        />
      )}
    </UserInteractionHistoryConsumer>
  );
};
