import classnames from "classnames";
import React, { HTMLAttributes } from "react";

import HARDWARE_BACK_EVENT_PRIORITY from "constants/hardwareBackHierarchy";

import ContentWithHeader from "common/components/ContentWithHeader";
import IonBackHandler from "common/components/IonBackHandler";
import UIModal from "common/components/UIModal";

import styles from "./styles.scss";

export type Props = HTMLAttributes<HTMLIonModalElement> & {
  isOpen: boolean;
  respectSafeArea?: boolean;
  className?: string;
  animated?: boolean;
  close: () => void;
  headerText?: string;
  renderHeaderEnd?: () => React.ReactNode;
  renderHeaderStart?: () => React.ReactNode;
  onWillPresent?: () => void;
  onDidPresent?: () => void;
  onDidDismiss?: () => void;
  onWillDismiss?: () => void;
  scrollable?: boolean;
  swipeToClose?: boolean;
  disableDefaultHeader?: boolean;
  enableHardwareBack?: boolean;
  hardwareBackPriority?: number;
};

type State = {};

const DEFAULT_PRIORITY_HARDWARE_BACK = HARDWARE_BACK_EVENT_PRIORITY.dialogs;

/**
 * Panes are just full screen modals!
 */
export default class UIPane extends React.PureComponent<Props, State> {
  /**
   * Render a default header and back/close button and have the content below it.
   * NOTE: Your content will be inset in by the header height. So if you want full screen stuff
   *       you need to render it all yourself. renderWithDefaults is merely a convenience feature.
   */
  renderWithDefaults = (): React.ReactNode => {
    const {
      close,
      headerText,
      children,
      renderHeaderEnd,
      renderHeaderStart,
      respectSafeArea,
      scrollable = false,
    } = this.props;

    return (
      <ContentWithHeader
        title={headerText}
        onClickBack={close}
        headerFlat={!headerText}
        renderHeaderStart={renderHeaderStart}
        renderHeaderEnd={renderHeaderEnd}
        scrollable={scrollable}
        respectSafeArea={respectSafeArea}
      >
        {children}
      </ContentWithHeader>
    );
  };

  render = (): React.ReactNode => {
    const {
      isOpen,
      children,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      respectSafeArea,
      close,
      className,
      headerText,
      disableDefaultHeader,
      enableHardwareBack = true,
      hardwareBackPriority = DEFAULT_PRIORITY_HARDWARE_BACK,
      animated = true,
      ...remainingProps
    } = this.props;

    const minimumPropsForDefaultHeader = !!close || headerText !== undefined;
    const renderWithDefaults = disableDefaultHeader ? false : minimumPropsForDefaultHeader;
    const enableHardwareBackHandling = enableHardwareBack && Boolean(close);

    return (
      <>
        {enableHardwareBackHandling && close && (
          <IonBackHandler
            isActive={this.props.isOpen}
            priority={hardwareBackPriority}
            onHardwareBack={close}
          />
        )}

        <UIModal
          {...remainingProps}
          isAnimated={animated}
          backdropDismiss={false}
          isOpen={isOpen}
          close={close}
          modalContentClassName={classnames(styles.root, className, {
            [styles.safeAreaBound]: respectSafeArea,
          })}
        >
          {renderWithDefaults && this.renderWithDefaults()}
          {!renderWithDefaults && children}
        </UIModal>
      </>
    );
  };
}
