/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

type Key = "route";

const DEFAULTS: Record<Key, string> = {
  route: "",
};

/**
 * Local storage that indicates the route the app should load to on the next refresh.
 * Currently used by the shared login warning to store a deep link for the next reload.
 */
class RouteOnLoadStore extends BaseStore {
  constructor() {
    super("ThemeStore", DEFAULTS, false);
  }

  consume(): any {
    const route = super.get("route");

    this.setRoute("");
    return route;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setRoute(value: any): GenericObject {
    return super.set("route", value);
  }
}

export default new RouteOnLoadStore();
