import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { UpdateAcknowledgedResetMutation$data } from "__generated__/UpdateAcknowledgedResetMutation.graphql";

type ReturnType = UpdateAcknowledgedResetMutation$data["updateAcknowledgedReset"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  isNianticId: boolean;
  isDisplayName: boolean;
  isPhoto: boolean;
};

const mutation = graphql`
  mutation UpdateAcknowledgedResetMutation($input: UpdateAcknowledgedResetInput!) {
    updateAcknowledgedReset(input: $input) {
      success
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "updateAcknowledgedReset.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "updateAcknowledgedReset.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "updateAcknowledgedReset"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          isNianticId: input.isNianticId,
          isDisplayName: input.isDisplayName,
          isPhoto: input.isPhoto,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
