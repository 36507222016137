import queryString from "query-string";

export const URL_REGEX =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
export const CONTAINS_URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9(@:%_\\+.~#?&//=]*)/g;

export const parseQuery = (search: string): GenericObject => {
  // Parse boolean strings into booleans.
  return queryString.parse(search, { parseBooleans: true });
};

export const getQueryString = (queryObj: GenericObject, search?: string): string => {
  // If we are provided a search string, use the search string as the base object
  // when generating the query string.
  const currentSearch = search ? queryString.parse(search) : {};

  return queryString.stringify({ ...currentSearch, ...queryObj });
};
