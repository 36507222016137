import React from "react";

import withUserProfile, {
  WithUserProfileProps,
} from "providers/UserProfileProvider/withUserProfile";

import ReportContext from "constants/reportContext";

import UIAvatarWithPresence, {
  Props as UIAvatarWithPresenceProps,
} from "common/components/Avatar/UIAvatarWithPresence";

export type RequiredProps = UIAvatarWithPresenceProps & {
  userId?: string;
  reportContext?: ReportContext;
};

type Props = RequiredProps & WithUserProfileProps;

type State = {};

class UIAvatarConnected extends React.Component<Props, State> {
  onClick = (event: React.MouseEvent<HTMLIonAvatarElement>) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (this.props.userId) {
      this.props.viewUser(this.props.userId, this.props.reportContext);

      event.preventDefault();
      event.stopPropagation();
    }
  };

  render = (): React.ReactNode => {
    const { allowRandomBgColor = true, ...remainingProps } = this.props;

    return (
      <UIAvatarWithPresence
        allowRandomBgColor={allowRandomBgColor}
        {...remainingProps}
        onClick={this.onClick}
      />
    );
  };
}

export default withUserProfile(UIAvatarConnected);
