import { notifications } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import UIAsyncButton from "common/components/UIAsyncButton";
import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  notificationsEnabled: boolean;
  onEnableClick: () => Promise<void>;
};

type State = {};

class AccountSetupNotificationsPermissionSlideView extends React.Component<Props, State> {
  onEnableClick = async (): Promise<void> => {
    await this.props.onEnableClick();
  };

  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UISpacer h={100} />

        <UIText variant="h2" color="dark" weight="bold">
          {this.props.t("ENABLE_NOTIFICATIONS")}
        </UIText>

        <UISpacer h={24} />

        <div>
          <UIText color="dark" variant="h3" weight="medium">
            {this.props.t("SO_YOU_KNOW_WHEN_PEOPLE_ARE_CHATTING")}
          </UIText>
        </div>

        {/* <UISpacer h={24} /> */}

        {/* <div className={styles.serviceToggle}> */}
        {/*  {notificationsEnabled && ( */}
        {/*    <IonIcon */}
        {/*      className={styles.success} */}
        {/*      icon={checkmarkCircle} */}
        {/*    /> */}
        {/*  )} */}
        {/*  {!notificationsEnabled && <IonIcon icon={checkmarkCircleOutline} />} */}
        {/* </div> */}

        <UISpacer h={24} />

        <div>
          <UIIcon icon={notifications} size={80} color="primary" />
        </div>

        <UIAsyncButton className={styles.enableBtn} expand="block" onClick={this.onEnableClick}>
          <UIText weight="bold">{this.props.t("ENABLE_NOTIFICATIONS").toLocaleUpperCase()}</UIText>
        </UIAsyncButton>
      </div>
    );
  };
}

export default withTranslation()(AccountSetupNotificationsPermissionSlideView);
