import React from "react";

export type Props = {
  elementToWatch: HTMLElement;
  onResize: () => void;
};

type State = {};

export default class UIResizeObserver extends React.PureComponent<Props, State> {
  resizeObserver: ResizeObserver;

  isReady = false;

  componentDidMount = (): void => {
    this.resizeObserver = new ResizeObserver(this.onElementResized);

    this.resizeObserver.observe(this.props.elementToWatch);
  };

  componentWillUnmount = (): void => {
    this.resizeObserver.unobserve(this.props.elementToWatch);
  };

  onElementResized = (): void => {
    // Observers trigger the callback once first. Only trigger onResize after the first cycle.
    if (this.isReady) {
      this.props.onResize();
    }

    this.isReady = true;
  };

  render = (): React.ReactNode => {
    return null;
  };
}
