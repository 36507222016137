/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { LocationShareConsumer, ExposedProps } from "providers/LocationShareProvider";

export type WithLocationShareProps = {
  locationShare: {
    updateRateMs: ExposedProps["updateRateMs"];
    expiresAt: ExposedProps["expiresAt"];
    useBackgroundLocationSharing: ExposedProps["useBackgroundLocationSharing"];
    increaseLocationUpdateRate: ExposedProps["increaseLocationUpdateRate"];
    resetLocationUpdateRate: ExposedProps["resetLocationUpdateRate"];
  };
};

// eslint-disable-next-line max-len
export default <P extends WithLocationShareProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithLocationShareProps>): React.ReactElement => (
    <LocationShareConsumer>
      {(locationShareProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component locationShare={locationShareProps} {...props} />
      )}
    </LocationShareConsumer>
  );
};
