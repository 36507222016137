import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";

import EmbeddedPageWithTopBarWrapper from "common/components/EmbeddedPageWithTopBarWrapper";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import PageLoadError from "common/components/PageLoadError";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import { NotificationsPage_Query$data as NotificationsPageQueryResponse } from "__generated__/NotificationsPage_Query.graphql";

// Lazy Load Main View
const NotificationsPageMainContent = React.lazy(
  () => import("pages/Notifications/components/NotificationsPageMainContent"),
);

type QRProps = NotificationsPageQueryResponse & {
  me: NotificationsPageQueryResponse["me"];
};

type Props = WithTranslation & {};

type State = {
  initializedAt: number;
};

const NOTIFICATIONS_PAGE_QUERY = graphql`
  query NotificationsPage_Query {
    me {
      ...NotificationsPageMainContent_me
    }
  }
`;

class NotificationsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedAt: Date.now(),
    };
  }

  reInit = () => {
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void) => {
    this.reInit();
    clearError();
  };

  render = (): React.ReactNode => (
    <UIErrorBoundary onRetry={this.onRetry}>
      <Suspense fallback={<LoadingPageDefault />}>
        <EmbeddedPageWithTopBarWrapper>
          <QueryRenderer
            key={this.state.initializedAt}
            environment={environment}
            query={NOTIFICATIONS_PAGE_QUERY}
            variables={{}}
            render={({ props, error }) => {
              const qrProps = props as QRProps;
              const isLoading = !qrProps;
              const isError = !!error;

              if (isError) {
                return (
                  <PageLoadError
                    errorMessage={this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
                    reload={this.reInit}
                  />
                );
              }

              if (isLoading) {
                return <LoadingPageDefault />;
              }

              return <NotificationsPageMainContent me={qrProps.me} />;
            }}
          />
        </EmbeddedPageWithTopBarWrapper>
      </Suspense>
    </UIErrorBoundary>
  );
}

export default withTranslation()(NotificationsPage);
