import React from "react";

import { Interactions } from "providers/UserInteractionHistoryProvider/interactions";
import withUserInteractionHistory, {
  WithUserInteractionHistoryProps,
} from "providers/UserInteractionHistoryProvider/withUserInteractionHistory";

import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";

import PGOCampfireFTUEFlow from "boot-loader/components/PGOCampfireFTUEFlow";
import PGOCampfireFTUEShareFlow from "boot-loader/components/PGOCampfireFTUEShareFlow";

import styles from "./styles.scss";

export type LaunchIntent = "first-time" | "share";

export type Props = WithUserInteractionHistoryProps & {
  initialIntent?: LaunchIntent;
  children: React.ReactNode;
};

type State = {
  intent: LaunchIntent;
  showInterstitial: boolean;
};

class PGOCampfireInterstitials extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let showInterstitial = false;

    // Per product spec:
    // Share to Campfire (EVERY TIME before user downloaded Campfire) → Share to Campfire interstitial
    // https://docs.google.com/document/d/1wbCQadpvzTpb68W5cNGvpnHvm2sNEn8w0pysKsscLyM/edit?disco=AAAAXuJE6N4
    // If the embed opens us with the intent to share, this likely means the user has yet
    // to install the standalone app, so present the UI always per product.
    if (props.initialIntent === "share") {
      showInterstitial = true;
    } else {
      showInterstitial = !props.userInteraction.hasSeen(Interactions.PGO_EMBED_WELCOME);
    }

    this.state = {
      intent: props.initialIntent || "first-time",
      showInterstitial,
    };
  }

  hideInterstitial = (): void => {
    this.setState({ showInterstitial: false });
  };

  markHasSeenPGOEmbedWelcomeAndClose = (): void => {
    this.props.userInteraction.markInteraction(Interactions.PGO_EMBED_WELCOME);
    this.hideInterstitial();
  };

  renderInterstitialBasedOnIntent = (): React.ReactNode => {
    if (this.state.intent === "share") {
      return <PGOCampfireFTUEShareFlow close={this.hideInterstitial} />;
    }

    return <PGOCampfireFTUEFlow close={this.markHasSeenPGOEmbedWelcomeAndClose} />;
  };

  render = (): React.ReactNode => {
    // Use intent to choose which UI to present.
    // If an interstitial is presenting, present it on a layer above the main UI.
    return (
      <>
        <AnimatedFadeInOut className={styles.interstitial} show={this.state.showInterstitial}>
          {this.renderInterstitialBasedOnIntent()}
        </AnimatedFadeInOut>

        {this.props.children}
      </>
    );
  };
}

export default withUserInteractionHistory(PGOCampfireInterstitials);
