// Add polyfills here
import "core-js/es/map";
import "core-js/es/set";
import "core-js/es/promise";
// CRA uses corejs 3, and the Promse.allSettled was added with 3.2.
// So we need to import it manually.
// https://github.com/facebook/create-react-app/issues/8779#issuecomment-613898773
import "core-js/modules/esnext.promise.all-settled";
import "core-js/es/object";
import { ResizeObserver } from "@juggle/resize-observer";

// Polyfill ResizeObserver if needed
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}
