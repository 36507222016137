import { WriteOptions } from "@capacitor/clipboard";
import { GetUriOptions, ReadFileOptions, StatOptions } from "@capacitor/filesystem";
import { PositionOptions } from "@capacitor/geolocation";

import { serializeConsoleMessage } from "common/utils/webInterop/helpers";
import isDev from "constants/isDev";
import {
  InitiateUploadOptions,
  UploadFileOptions,
  GetFilePreviewUrlOptions,
} from "types/embedPluginInterface/embedUpload";

const CUSTOM_LAUNCH_URL = "uniwebview://";
const EMBED_BRIDGE_MESSAGE_HANDLER_NAME = "_social_webview_bridge_";

type ActionName =
  // Uncategorized actions
  | "console"
  | "locationChange"

  // App Interop
  | "logout"
  | "reload"

  // Capacitor Stubs
  | "PushNotifications.requestPermissions"
  | "PushNotifications.checkPermissions"
  | "Camera.requestPermissions"
  | "Camera.checkPermissions"
  | "Geolocation.getCurrentPosition"
  | "Geolocation.checkPermissions"
  | "Clipboard.write"
  | "Filesystem.getUri"
  | "Filesystem.readFile"
  | "Filesystem.stat"

  // Custom Capacitor Plugins
  | "RegionalChat.requestCurrentRegion"
  | "EmbedUpload.initiateUpload"
  | "EmbedUpload.uploadFile"
  | "EmbedUpload.getFilePreviewUrl";

const ACTIONS: Record<string, ActionName> = {
  CONSOLE: "console",
  LOCATION_CHANGE: "locationChange",

  LOGOUT: "logout",
  RELOAD: "reload",

  PUSH_NOTIFICATION_REQUEST_PERMISSION: "PushNotifications.requestPermissions",
  PUSH_NOTIFICATION_CHECK_PERMISSIONS: "PushNotifications.checkPermissions",
  CAMERA_REQUEST_PERMISSIONS: "Camera.requestPermissions",
  CAMERA_CHECK_PERMISSIONS: "Camera.checkPermissions",
  GEOLOCATION_GET_CURRENT_POSITION: "Geolocation.getCurrentPosition",
  GEOLOCATION_CHECK_PERMISSIONS: "Geolocation.checkPermissions",
  CLIPBOARD_WRITE: "Clipboard.write",
  FILE_SYSTEM_GET_URI: "Filesystem.getUri",
  FILE_SYSTEM_READ_FILE: "Filesystem.readFile",
  FILE_SYSTEM_STAT: "Filesystem.stat",

  REGIONAL_CHAT_REQUEST_REGION: "RegionalChat.requestCurrentRegion",
  EMBED_UPLOAD_INITIATE_UPLOAD: "EmbedUpload.initiateUpload",
  EMBED_UPLOAD_FILE: "EmbedUpload.uploadFile",
  EMBED_UPLOAD_GET_FILE_PREVIEW_URL: "EmbedUpload.getFilePreviewUrl",
};

const toUnity = (
  actionName: ActionName,
  promiseId: string | null = null,
  options: GenericObject = {},
) => {
  const queryParams: Record<string, number | string | boolean | null | undefined> = {
    ...options,
    promiseId,
    action: actionName,
  };

  // Join all query params into a string.
  const queryString: string = Object.keys(queryParams)
    .filter((key) => !!queryParams[key])
    .map((key) => `${key}=${queryParams[key] || ""}`)
    .join("&");

  const bridgeUrl = `${CUSTOM_LAUNCH_URL}${queryString ? `?${queryString}` : ""}`;

  // eslint-disable-next-line no-underscore-dangle
  if (window?.__CAMPFIRE_ANDROID_BRIDGE__) {
    // eslint-disable-next-line no-underscore-dangle
    window.__CAMPFIRE_ANDROID_BRIDGE__.notify(bridgeUrl);
  } else if (
    window?.webkit?.messageHandlers &&
    window?.webkit?.messageHandlers[EMBED_BRIDGE_MESSAGE_HANDLER_NAME]
  ) {
    // This is the current embed bridge name for iOS
    window.webkit.messageHandlers[EMBED_BRIDGE_MESSAGE_HANDLER_NAME].postMessage({
      uniwebview: bridgeUrl,
    });
  } else if (window?.webkit?.messageHandlers?.bridge) {
    // This is the OLD embed bridge name for iOS, to support new FE clients still being able to
    // communicate with old embed versions.
    window.webkit.messageHandlers.bridge.postMessage({ uniwebview: bridgeUrl });
  } else if (window.originalConsole) {
    window.originalConsole.log(`bridge: ${bridgeUrl}`);
  } else {
    // eslint-disable-next-line no-console
    console.log(`bridge: ${bridgeUrl}`);
  }
};

// ===== Uncategorized Methods =====
const notifyUnityOfLocationChange = (url: string) => {
  // Encode the url since we are sending it in a url lol.
  const options = { url: encodeURIComponent(url) };

  toUnity(ACTIONS.LOCATION_CHANGE, undefined, options);
};

const pipeConsoleToUnity = (logLevel: string, ...args: unknown[]): void => {
  const argsAsString = args.map(serializeConsoleMessage).join(" ");

  // Send to unity the log level and arguments as a string named message.
  const options = {
    level: logLevel,
    message: encodeURIComponent(argsAsString),
  };

  toUnity(ACTIONS.CONSOLE, null, options);
};

// ===== App Interop Methods =====
const logout = (): boolean => {
  toUnity(ACTIONS.LOGOUT);

  return false;
};

const reload = (): boolean => {
  toUnity(ACTIONS.RELOAD);

  return false;
};

// ===== Plugin Interop Methods =====
// === Geolocation ===
const getCurrentPosition = (promiseId: string, options?: PositionOptions): void => {
  toUnity(ACTIONS.GEOLOCATION_GET_CURRENT_POSITION, promiseId, options);
};

const checkGeolocationPermissions = (promiseId: string): void => {
  toUnity(ACTIONS.GEOLOCATION_CHECK_PERMISSIONS, promiseId);
};

// === PushNotifications ===
const requestNotificationPermissions = (promiseId: string): void => {
  toUnity(ACTIONS.PUSH_NOTIFICATION_REQUEST_PERMISSION, promiseId);
};

const checkPushNotificationPermissions = (promiseId: string): void => {
  toUnity(ACTIONS.PUSH_NOTIFICATION_CHECK_PERMISSIONS, promiseId);
};

// === Camera ===
const requestCameraPermissions = (promiseId: string): void => {
  toUnity(ACTIONS.CAMERA_REQUEST_PERMISSIONS, promiseId);
};

const checkCameraPermissions = (promiseId: string): void => {
  toUnity(ACTIONS.CAMERA_CHECK_PERMISSIONS, promiseId);
};

// === Clipboard ===
const clipboardWrite = (promiseId: string, options: WriteOptions): void => {
  toUnity(ACTIONS.CLIPBOARD_WRITE, promiseId, options);
};

// === Filesystem ===
const fileSystemGetUri = (promiseId: string, options: GetUriOptions): void => {
  toUnity(ACTIONS.FILE_SYSTEM_GET_URI, promiseId, options);
};

const fileSystemReadFile = (promiseId: string, options: ReadFileOptions): void => {
  toUnity(ACTIONS.FILE_SYSTEM_READ_FILE, promiseId, options);
};

const fileSystemStat = (promiseId: string, options: StatOptions): void => {
  toUnity(ACTIONS.FILE_SYSTEM_STAT, promiseId, options);
};

// ===== CUSTOM EMBED CAPACITOR PLUGINS ===
const regionalChatRequestRegion = (promiseId: string): void => {
  toUnity(ACTIONS.REGIONAL_CHAT_REQUEST_REGION, promiseId);
};

const embedUploadInitiateUpload = (promiseId: string, options: InitiateUploadOptions): void => {
  toUnity(ACTIONS.EMBED_UPLOAD_INITIATE_UPLOAD, promiseId, options);
};

const embedUploadFile = (promiseId: string, options: UploadFileOptions): void => {
  toUnity(ACTIONS.EMBED_UPLOAD_FILE, promiseId, options);
};

const embedUploadGetFilePreviewUrl = (
  promiseId: string,
  options: GetFilePreviewUrlOptions,
): void => {
  toUnity(ACTIONS.EMBED_UPLOAD_GET_FILE_PREVIEW_URL, promiseId, options);
};

const hijackHistoryPushAndReplaceState = () => {
  // Overwrite both pushState and replaceState, as these are the 2 primary navigation
  // methods we invoke!
  if (window.history && window.history.pushState) {
    const { pushState } = window.history;

    // eslint-disable-next-line func-names
    window.history.pushState = function (...args) {
      pushState.apply(window.history, args);

      // https://developer.mozilla.org/en-US/docs/Web/API/History/pushState
      const url = args[2];

      if (url) {
        // console.log("pushState", url);
        notifyUnityOfLocationChange(url as string);
      }
    };
  }

  if (window.history && window.history.replaceState) {
    const { replaceState } = window.history;

    // eslint-disable-next-line func-names
    window.history.replaceState = function (...args) {
      replaceState.apply(window.history, args);

      // https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState
      const url = args[2];

      if (url) {
        // console.log("replaceState", url);
        notifyUnityOfLocationChange(url as string);
      }
    };
  }
};

const overrideConsole = (): void => {
  const BRIDGED_CONSOLE_METHODS = ["debug", "error", "info", "log", "trace", "warn"];

  // Taken from Capacitor ios bridge, adapted for unity/niantic:
  // https://github.com/ionic-team/capacitor/blob/151e7a899d9646dbd5625a2539fd3f2297349bc5/ios/Capacitor/Capacitor/assets/native-bridge.js
  const originalConsole = { ...window.console };

  // Save the original console in case we need it
  window.originalConsole = originalConsole;

  // For all console methods, override the default with our own implementation.
  // eslint-disable-next-line no-restricted-syntax
  for (const logfn of BRIDGED_CONSOLE_METHODS) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.console[logfn] = (...args) => {
      const msgs = [...args];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      originalConsole[logfn](...msgs);

      try {
        pipeConsoleToUnity(logfn, ...msgs);
      } catch (e) {
        originalConsole.error(e);
      }
    };
  }
};

const initialize = (): void => {
  // Pass all console.logs to unity. ONLY FOR DEV!
  if (isDev) {
    overrideConsole();
  }

  // Override the history pushState method to communicate changes simultaneously to unity.
  hijackHistoryPushAndReplaceState();
};

// TODO: Add the new methods!
// Such as Geolocation.requestPermissions
export default {
  initialize,
  app: {
    logout,
    reload,
  },
  plugins: {
    PushNotifications: {
      checkPermissions: checkPushNotificationPermissions,
      requestPermissions: requestNotificationPermissions,
    },
    Camera: {
      checkPermissions: checkCameraPermissions,
      requestPermissions: requestCameraPermissions,
    },
    Geolocation: {
      checkPermissions: checkGeolocationPermissions,
      getCurrentPosition,
    },
    Clipboard: {
      write: clipboardWrite,
    },
    Filesystem: {
      getUri: fileSystemGetUri,
      readFile: fileSystemReadFile,
      stat: fileSystemStat,
    },
    RegionalChat: {
      requestCurrentRegion: regionalChatRequestRegion,
    },
    EmbedUpload: {
      initiateUpload: embedUploadInitiateUpload,
      uploadFile: embedUploadFile,
      getFilePreviewUrl: embedUploadGetFilePreviewUrl,
    },
  },
};
