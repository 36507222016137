/**
 * Built for our security testers, this key set in sessionStorage will allow a user to access
 * the web version of the app. Session Storage will clear on each new tab, so a tad more restrictive
 * than localStorage.
 */
const hasWebAccessBypass = (): boolean => {
  return sessionStorage.getItem("ISolemnlySwearThatIAmUpToNoGood") === "Mischief Managed";
};

export default hasWebAccessBypass;
