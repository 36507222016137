import i18n from "common/utils/i18n";
import { ONE_DAY } from "constants/time";

type RelativeTimeFormatKeyType = string | "other";

// https://gitlab.nianticlabs.com/campfire/identity-portal/-/blob/trunk/portal/src/constants/i18n.ts
export const RELATIVE_TIME_FORMAT: Record<string, Record<RelativeTimeFormatKeyType, string>> = {
  de: {
    "0": "heute",
    "-1": "gestern",
    "-2": "vorgestern",
    other: "vor {} Tagen",
  },
  en: {
    "0": "today",
    "-1": "yesterday",
    other: "{} days ago",
  },
  es: {
    "0": "hoy",
    "-1": "ayer",
    "-2": "anteayer",
    other: "hace {} días",
  },
  fr: {
    "0": "aujourd’hui",
    "-1": "hier",
    "-2": "avant-hier",
    other: "il y a {} jours",
  },
  it: {
    "0": "oggi",
    "-1": "ieri",
    "-2": "l’altro ieri",
    other: "{} giorni fa",
  },
  ja: {
    "0": "今日",
    "-1": "昨日",
    "-2": "一昨日",
    other: "{} 日前",
  },
  ko: {
    "0": "오늘",
    "-1": "어제",
    "-2": "그저께",
    other: "{}일 전",
  },
  "pt-BR": {
    "0": "hoje",
    "-1": "ontem",
    "-2": "anteontem",
    other: "há {} dias",
  },
  "zh-Hant": {
    "0": "今天",
    "-1": "昨天",
    "-2": "前天",
    other: "{} 天前",
  },
};

const getLongAutoRelativeTimeFormattedString = (value: number, locale: string) => {
  const formatter = new Intl.RelativeTimeFormat(locale, {
    style: "long",
    numeric: "auto",
  });

  return formatter.format(value, "day");
};

const getRelativeTimeFormattedStringGreaterThan2Days = (value: number, locale: string) => {
  const formatter = new Intl.RelativeTimeFormat(locale, {
    style: "long",
    numeric: "always",
  });

  return formatter
    .formatToParts(value, "day")
    .map((part) => (part.type === "integer" ? "2+" : part.value))
    .join("");
};

// WARNING: This method requires the new ECMA-402 Intl API's. This is why its in a try catch
// since polyfilling this bloats the bundle.
// Since ID Portal will probably QQ if it doesn't render EXACTLY the same, I literally
// jacked the string formatting code from their repo. Though I did modify it to be a tad more readable:
// Plus no need to cast!
// Modifications include:
// - More readable computation of num days between
// - Handle edge case of future times
// - Better typescript support
// https://gitlab.nianticlabs.com/campfire/identity-portal/-/blob/trunk/portal/src/utils/strings.ts
// eslint-disable-next-line @typescript-eslint/no-unused-vars,import/prefer-default-export
export const formatLastPlayedTime = (time: number, locale: string = i18n.language): string => {
  const now = Date.now();
  const start = new Date(time);

  start.setHours(0, 0, 0, 0);

  const startOfDayForTargetTimeInMs = start.getTime();

  //          yesterday           today
  // |-------------|--------------$-|---*------------|
  //               ^              ^    now
  //       Start_day_target   Target Time
  // Using the start of the day for the target time, calculate the number of days
  // between. Throw out the decimal since we only care about the day value.
  const diffInDays = Math.trunc((startOfDayForTargetTimeInMs - now) / ONE_DAY);
  const isGreaterThanToday = diffInDays > 0;
  const isOlderThan2Days = diffInDays <= -2;

  try {
    // Edge case for times that are greater than now
    if (isGreaterThanToday) {
      return getLongAutoRelativeTimeFormattedString(0, locale);
    }

    if (isOlderThan2Days) {
      return getRelativeTimeFormattedStringGreaterThan2Days(diffInDays, locale);
    }

    return getLongAutoRelativeTimeFormattedString(diffInDays, locale);
  } catch {
    const fallbackFormatting = RELATIVE_TIME_FORMAT.en;
    const format = RELATIVE_TIME_FORMAT[locale] || fallbackFormatting;
    const diffInDaysAsString: string = diffInDays.toString(10);

    // Check for larger dates
    if (isGreaterThanToday) {
      return format["0"];
    }

    // Use diff in days to get the string if possible.
    // Otherwise use the "other" key
    if (format[diffInDaysAsString]) {
      return format[diffInDaysAsString];
    }

    if (format.other) {
      return format.other.replace("{}", "2+");
    }

    // If the formatting does not have an other key, we prolly screwed up so use the fallback.
    return fallbackFormatting.other.replace("{}", "2+");
  }
};
