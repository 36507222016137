import React from "react";

import PresenceStatusIcon from "common/components/Avatar/PresenceStatusIcon";
import UIAvatar, {
  DEFAULT_AVATAR_SIZE_PX,
  Props as UIAvatarProps,
} from "common/components/Avatar/UIAvatar";

import styles from "./styles.scss";

export type Props = UIAvatarProps & {
  avatarStyle?: "circle" | "square";
  presenceStatus?: PresenceStatus;
  sizeOverride?: number;
  renderOnBottom?: boolean;
};

type State = {};

const INDICATOR_SIZE_AS_PERCENT_OF_AVATAR = 0.2;

export default class UIAvatarWithPresence extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const {
      avatarUrl,
      size = DEFAULT_AVATAR_SIZE_PX,
      presenceStatus,
      ...remainingProps
    } = this.props;

    const avatarSize = size;
    const avatarRadius = avatarSize / 2;
    const indicatorSize =
      this.props.sizeOverride || avatarSize * INDICATOR_SIZE_AS_PERCENT_OF_AVATAR;
    const indicatorRadius = indicatorSize / 2;

    // *** Please read up on unit circles if you want to know where this calculation is from. ***
    // Basically the 45 degree point on the avatar exists at (x,y) = (sqrt(2)/2, sqrt(2)/2)
    // on the unit circle. To reach that position, but from the right and not the center of
    // the unit circle, that's = (Radius - Radius * sqrt(2)/2). Then, since we want to align
    // the center of the indicator with the circumference of the avatar, we offset the radius
    // of the indicator.
    const fortyFiveDegree = avatarRadius - (Math.sqrt(2) / 2) * avatarRadius;
    const fortyFiveDegreeWithOffset = fortyFiveDegree - indicatorRadius;

    // Positioning we do in rems, since avatars (where this is used primarily) are rendered in rems.
    const styleOverride = {
      position: "absolute",
      top: this.props.renderOnBottom ? undefined : `${fortyFiveDegreeWithOffset}px`,
      right: `${fortyFiveDegreeWithOffset}px`,
      bottom: this.props.renderOnBottom ? `${fortyFiveDegreeWithOffset}px` : undefined,
    };

    return (
      <div className={styles.root}>
        <UIAvatar {...remainingProps} avatarUrl={avatarUrl} size={avatarSize} />
        {presenceStatus && (
          <PresenceStatusIcon
            presenceStatus={presenceStatus}
            size={indicatorSize}
            styleOverride={styleOverride}
          />
        )}
      </div>
    );
  };
}
