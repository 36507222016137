/* eslint-disable max-len */
import * as Sentry from "@sentry/react";
import environment from "common/relay/relay-env";
import React, { useState } from "react";
import { createFragmentContainer, graphql, QueryRenderer } from "react-relay";
import { withRouter, RouteComponentProps, Switch, Route } from "react-router-dom";

import { AppStateProvider, AppStateConsumer } from "providers/AppStateProvider";
import withAuth, { WithAuthProps } from "providers/AuthProvider/withAuth";

import { isAndroid } from "common/capacitor/helpers";
import { embeddedPGOIntent, isPGOEmbed } from "common/utils/webInterop";
import {
  EXPERIENCE_ROUTE,
  EXTERNAL_ROUTE,
  INTEGRATION_ROUTE,
  DISCORD_INTEGRATION_ROUTE,
  WAYFARER_ROUTE,
} from "constants/routes";

import LoadingPageDefault from "common/components/LoadingPageDefault";

// !!!IMPORTANT!!!
// To avoid circular dependencies, we need to resolve the app providers before loading any other module.
import "./initAuthenticatedProviders";

import AppError from "boot-loader/components/AppError";
import AppGlobalFlows from "boot-loader/components/AppGlobalFlows";
import AppGlobalRunningProcesses from "boot-loader/components/AppGlobalRunningProcesses";
import AppRouterPGO from "boot-loader/components/AppRouterPGO";
import AppRouterV2 from "boot-loader/components/AppRouterV2";
import AppRunAfterInit from "boot-loader/components/AppRunAfterInit";
import AuthenticatedServiceProviders from "boot-loader/components/AuthenticatedServiceProviders";
import LocationPinger from "boot-loader/components/LocationPinger";
import PGOCampfireInterstitials, {
  LaunchIntent,
} from "boot-loader/components/PGOCampfireInterstitials";
import ShareReceivedHandler from "boot-loader/components/ShareReceivedHandler";
import DiscordIntegrationPage from "pages/DiscordIntegration";
import ExperiencePage from "pages/Experience";
import FourOhFourPage from "pages/FourOhFour";

import { App_hydrate_Query$data as AppHydrateQueryResponse } from "__generated__/App_hydrate_Query.graphql";
import { App_me$data as AppMe } from "__generated__/App_me.graphql";
import { App_query$data as AppQuery } from "__generated__/App_query.graphql";

type QRProps = {
  query: AppHydrateQueryResponse;
  me: AppHydrateQueryResponse["me"];
};

type Props = WithAuthProps &
  RouteComponentProps & {
    query: AppQuery;
    me: AppMe;
  };

type State = {};

const shouldBundleWayfarer = process.env.CAMPFIRE_APP_BUILD_WAYFARER === "true";

const WayfarerPage = shouldBundleWayfarer
  ? // eslint-disable-next-line @typescript-eslint/no-var-requires
    require("../../../pages/Wayfarer").default
  : FourOhFourPage;

/**
 * This is the main authenticated app
 */
class App extends React.Component<Props, State> {
  embeddedPgoIntent: string | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.embeddedPgoIntent = embeddedPGOIntent;

    // After user is authenticated, update the user we send to sentry.
    Sentry.configureScope((scope) => {
      scope.setUser({ id: props.me.id });
    });
  }

  componentDidMount = (): void => {
    // Only start reacting to 401's when the app is actually running.
    this.props.authProvider.listenFor401Responses();
  };

  componentWillUnmount = (): void => {
    this.props.authProvider.stopListeningFor401s();
  };

  /**
   * Renders the primary campfire app experience.
   */
  renderCoreCampfireExperience = (): React.ReactNode => {
    return (
      <AppStateConsumer>
        {() => (
          <>
            <AppGlobalRunningProcesses />
            <AppRouterV2 query={this.props.query} me={this.props.me} />

            <AppRunAfterInit />
            <AppGlobalFlows query={this.props.query} me={this.props.me} disableOfficialClubFTUE />
          </>
        )}
      </AppStateConsumer>
    );
  };

  renderPGOCampfireExperience = (): React.ReactNode => {
    const initialIntent = this.embeddedPgoIntent as LaunchIntent;

    return (
      <PGOCampfireInterstitials initialIntent={initialIntent}>
        <AppRunAfterInit />
        <LocationPinger />
        <AppRouterPGO />
      </PGOCampfireInterstitials>
    );
  };

  render = (): React.ReactNode => {
    const campfireExperience = isPGOEmbed
      ? this.renderPGOCampfireExperience()
      : this.renderCoreCampfireExperience();

    // Render certain routes as more standalone pages such as the Peridot photo share
    // experience. The core experience introduces unnecessary tasks such as location updating
    // FTUE flows, which aren't entirely valid for these special experiences and take
    // the user out of context of the experience. However, so that transitioning between
    // these routes is decent, the experiences and main app share the primary hydration
    // such that we don't need to rehydrate core items after the experience is complete.
    // Let's see how long that reasoning holds.
    return (
      <AuthenticatedServiceProviders me={this.props.me} query={this.props.query}>
        {/* Plugin is only implemented for Android currently */}
        {isAndroid && <ShareReceivedHandler />}
        <AppStateProvider>
          <Switch>
            {/* Render Experiences Page for special experiences. */}
            <Route path={`/${EXPERIENCE_ROUTE}`} component={ExperiencePage} />

            <Route path={`/${WAYFARER_ROUTE}`} component={WayfarerPage} />

            {/* Render External Integrations */}
            <Route
              path={`/${EXTERNAL_ROUTE}/${INTEGRATION_ROUTE}/${DISCORD_INTEGRATION_ROUTE}`}
              component={DiscordIntegrationPage}
            />

            {/* Otherwise, render the main campfire experience */}
            <Route>{campfireExperience}</Route>
          </Switch>
        </AppStateProvider>
      </AuthenticatedServiceProviders>
    );
  };
}

const FragmentContainer = createFragmentContainer(App, {
  query: graphql`
    fragment App_query on Query {
      ...AppRouterV2_query
      ...AppGlobalFlows_query
      ...AuthenticatedServiceProviders_query
    }
  `,
  me: graphql`
    fragment App_me on User {
      id
      ...AppRouterV2_me
      ...AppGlobalFlows_me
      ...AuthenticatedServiceProviders_me
    }
  `,
});

const AuthConnected = withAuth(FragmentContainer);
const RouterConnectedApp = withRouter(AuthConnected);

export const APP_HYDRATE_QUERY = graphql`
  query App_hydrate_Query {
    ...App_query
    me {
      email
      username
      displayName
      ...App_me
    }
  }
`;

export default (): JSX.Element => {
  // When we setInitTime, we reload the entire app
  const [initializedAt, setInitTime] = useState(0);

  return (
    <QueryRenderer
      key={initializedAt}
      environment={environment}
      query={APP_HYDRATE_QUERY}
      variables={{}}
      render={({ props, error }) => {
        const qrProps = props as QRProps;

        // If we could not even complete the main request, show a special app error screen.
        if (error) {
          return <AppError retry={() => setInitTime(Date.now())} />;
        }

        if (!qrProps) {
          return <LoadingPageDefault />;
        }

        return (
          <RouterConnectedApp
            query={qrProps as unknown as AppHydrateQueryResponse}
            me={qrProps.me}
          />
        );
      }}
    />
  );
};
