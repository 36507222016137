/**
 * @generated SignedSource<<a36dcaf8e55eaf6c25733760e6a46390>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClubUserProfileModerationActionMenu_me$data = {
  readonly id: string;
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ClubModerationControl_me">;
  readonly " $fragmentType": "ClubUserProfileModerationActionMenu_me";
};
export type ClubUserProfileModerationActionMenu_me$key = {
  readonly " $data"?: ClubUserProfileModerationActionMenu_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClubUserProfileModerationActionMenu_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClubUserProfileModerationActionMenu_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClubModerationControl_me"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f478c6b8c792c27270fad14e10e0287d";

export default node;
