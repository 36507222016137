/**
 * @generated SignedSource<<cdc3aa1ee3003192752b2b7ee6d23cd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppHydrationRefresher_Query$variables = {};
export type AppHydrationRefresher_Query$data = {
  readonly me: {
    readonly blockedByUsers: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type AppHydrationRefresher_Query = {
  response: AppHydrationRefresher_Query$data;
  variables: AppHydrationRefresher_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "blockedByUsers",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppHydrationRefresher_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppHydrationRefresher_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "75c2984e4146c044c97be31af0581afb",
    "id": null,
    "metadata": {},
    "name": "AppHydrationRefresher_Query",
    "operationKind": "query",
    "text": "query AppHydrationRefresher_Query {\n  me {\n    blockedByUsers {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "534a0a080779c27adb7b35a11273d8f0";

export default node;
