import _ from "lodash";
import { graphql } from "react-relay";

import createConnectionLoader from "common/components/ConnectionLoader/createConnectionLoader";

import type { MyClubsConnectionLoaderQuery$data } from "__generated__/MyClubsConnectionLoaderQuery.graphql";
import type { MyClubsConnectionLoader_me$data as MyClubsConnectionLoaderMe } from "__generated__/MyClubsConnectionLoader_me.graphql";

export type ClubEdge = ExtractRelayEdge<MyClubsConnectionLoaderMe["memberOf"]>;
export type Club = ExtractRelayEdgeNode<MyClubsConnectionLoaderMe["memberOf"]>;

// Props that our defined pagination component needs to operate
// Usually things like clubId, or things needed for the request are added here.
// So if we wanted a list of users in a club for instance, we would probably need something
// to help our query out to know what club we are pulling from, so clubId would be a required prop.
type RequiredProps = {};

// Props that come from the relay response when paginating
type RelayProps = {
  me: MyClubsConnectionLoaderMe | null;
};

type MainQueryResponse = MyClubsConnectionLoaderQuery$data;

const MainQuery = graphql`
  query MyClubsConnectionLoaderQuery($first: Int!, $after: String) {
    me {
      ...MyClubsConnectionLoader_me
    }
  }
`;

const paginationFragmentSpec = {
  me: graphql`
    fragment MyClubsConnectionLoader_me on User {
      memberOf(first: $first, after: $after) @connection(key: "MyClubsConnectionLoader_memberOf") {
        edges {
          node {
            id
            name
            avatarUrl
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
};

const forwardPaginationQuery = graphql`
  query MyClubsConnectionLoaderForwardQuery($first: Int!, $after: String) {
    me {
      ...MyClubsConnectionLoader_me
    }
  }
`;

const getConnectionFromProps = (props: RelayProps) => {
  return props.me && props.me.memberOf;
};

const getRelayPropsForComponent = (props?: MainQueryResponse): RelayProps => {
  const meFragment = _.get(props, "me", null) as RelayProps["me"];

  return {
    me: props ? meFragment : null,
  };
};

const getNodesFromFragment = (props: RelayProps): Club[] => {
  const memberOfEdges = _.get(props, "me.memberOf.edges", []) || [];

  return memberOfEdges.map((edge: ClubEdge) => edge.node);
};

const getVariablesForInitialQuery = () => {
  return {};
};

export default createConnectionLoader<RequiredProps, RelayProps, MainQueryResponse>(
  // relayPaginationContainerOpts
  {
    fragmentSpec: paginationFragmentSpec,
    connectionConfig: {
      getConnectionFromProps,
      getVariables: getVariablesForInitialQuery,
      query: forwardPaginationQuery,
    },
  },
  {
    getNodes: getNodesFromFragment,
    getRelayPropsForComponent,
  },
  {
    query: MainQuery,
    getVariables: getVariablesForInitialQuery,
  },
);
