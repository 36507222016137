/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";

import googleAnalytics, { DimensionType } from "common/analytics/googleAnalytics";
import { initializeSentry, logSentryException } from "common/analytics/sentry";
import { CAMPFIRE_JS_VERSION } from "common/utils/campfireApp";
import config from "constants/config";

export const logGlobalError = (event: any) => {
  const description = `uncaught error: ${JSON.stringify(event.error)}`;

  ReactGA.exception({
    description,
    fatal: true,
  });

  // Send global errors to sentry.
  Sentry.captureException(event.error);

  /*
    Temporarily logging to both Universal Analytics and Google Analytics 4 for comparison
  */
  gtag("event", "exception", {
    description,
    fatal: true,
  });

  // This will pipe the error to unity since unity interop overrides console methods!
  // eslint-disable-next-line no-console
  console.error(event.error);
};

export default (
  action: string,
  method: string,
  file: string,
  error: any,
  isFatal?: boolean,
  errorName?: string,
  errorContext?: GenericObject,
): void => {
  const description = `error ${action} during ${method}() in ${file}: ${JSON.stringify(error)}`;
  const fatal = isFatal || false;

  ReactGA.exception({
    description,
    fatal,
  });

  // Log to Sentry
  logSentryException(action, method, file, error, fatal, errorName, errorContext);

  /*
    Temporarily logging to both Universal Analytics and Google Analytics 4 for comparison
  */
  gtag("event", "exception", {
    description,
    fatal,
  });

  // This will pipe the error to unity since unity interop overrides console methods!
  // eslint-disable-next-line no-console
  console.error(error);
};

export const initializeErrorTracking = (): void => {
  const key = config.get("CAMPFIRE_APP_GOOGLE_ANALYTICS_KEY");

  // Attach a global error handler.
  window.addEventListener("error", (e) => {
    logGlobalError(e);
  });

  if (key) {
    ReactGA.initialize(key, {
      debug: false, // set true for console log debugging
      titleCase: false,
    });

    // Set the appVersion dimension on init.
    googleAnalytics(DimensionType.appVersion, CAMPFIRE_JS_VERSION);
  }

  initializeSentry();
};

initializeErrorTracking();
