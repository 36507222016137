/**
 * @generated SignedSource<<ad3cf9eb4c384fce1b34f6f3526a29b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoreNavigationFloatingTabBar_me$data = {
  readonly avatarUrl: string;
  readonly displayName: string;
  readonly id: string;
  readonly " $fragmentType": "CoreNavigationFloatingTabBar_me";
};
export type CoreNavigationFloatingTabBar_me$key = {
  readonly " $data"?: CoreNavigationFloatingTabBar_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoreNavigationFloatingTabBar_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoreNavigationFloatingTabBar_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c56c7e57eeff93c7ce88a87026478d12";

export default node;
