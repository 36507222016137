import { SignalEvent } from "pubnub";
import React from "react";

import withActivity, { WithActivityProps } from "providers/ActivityProvider/withActivity";
import withPubnub, { WithPubnubProps } from "providers/PubnubProvider/withPubnub";

export type Props = WithActivityProps &
  WithPubnubProps & {
    userId: string;
  };

type State = {};

/**
 * Registers actions to be performed when the user's channel group receives a signal from
 * pubnub. Usually when a different user sends a chat message, the BE will publish
 * a pubnub signal to an associated signal channel that exists alongside a message channel. This
 * we use to denote activity within a message channel, all for the awesome price of $0.00.
 * (Since signals are free).
 */
class UserChannelGroupSignalHandler extends React.PureComponent<Props, State> {
  componentDidMount = (): void => {
    // eslint-disable-next-line max-len
    this.props.pubnubProvider.pubnubClient.registerGlobalSignalHandler(
      this.handleNewChannelActivity,
    );
  };

  componentWillUnmount = (): void => {
    // eslint-disable-next-line max-len
    this.props.pubnubProvider.pubnubClient.unregisterGlobalSignalHandler(
      this.handleNewChannelActivity,
    );
  };

  handleNewChannelActivity = (pubnubSignalEvent: SignalEvent) => {
    const desiredChannel = pubnubSignalEvent.channel;
    const isFromActiveChannel = this.props.activityProvider.channelIsVisiblyActive(desiredChannel);

    if (desiredChannel) {
      // If we are currently viewing this channel, dont increment.
      if (!isFromActiveChannel) {
        this.props.activityProvider.updateChannelNewestMessage(
          desiredChannel,
          pubnubSignalEvent.timetoken,
        );
      }
    }
  };

  render = (): React.ReactNode => {
    return null;
  };
}

const PubnubConnected = withPubnub(UserChannelGroupSignalHandler);
const ActivityConnected = withActivity(PubnubConnected);

export default ActivityConnected;
