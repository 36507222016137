import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { MutationError } from "types/modules/relay/mutations";

import { UpdateUserForAccountResetMutation$data } from "__generated__/UpdateUserForAccountResetMutation.graphql";

type ReturnType = UpdateUserForAccountResetMutation$data["updateUserForAccountReset"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  nianticId: string | null;
  displayName: string | null;
};

const mutation = graphql`
  mutation UpdateUserForAccountResetMutation($input: UpdateUserForAccountResetInput!) {
    updateUserForAccountReset(input: $input) {
      me {
        username
        displayName
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "updateUserForAccountReset.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "updateUserForAccountReset.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "updateUserForAccountReset"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0]") || _.get(error, "data.errors[0]") || error;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          nianticId: input.nianticId,
          displayName: input.displayName,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
