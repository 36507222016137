import { useContext } from "react";

import { CampfirePageContext, ExposedProps } from "providers/CampfirePageProvider";

type HookReturnType = ExposedProps;

/**
 * Hook to get CampfirePageProvider props
 */
function useCampfirePage(): HookReturnType {
  const exposedProps = useContext(CampfirePageContext);

  return exposedProps;
}

export default useCampfirePage;
