import { IonIcon } from "@ionic/react";
import classnames from "classnames";
import { bug } from "ionicons/icons";
import React, { ErrorInfo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { withRouter, RouteComponentProps } from "react-router-dom";

import logException from "common/analytics/exceptions";

import UIButton from "common/components/UIButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = RouteComponentProps &
  WithTranslation & {
    children: React.ReactNode;
    className?: string;

    // If you want to be able to bypass the error, you must need a way to trigger a retry.
    // If you specify this onRetry method, a button will appear, you can run some code,
    // and you can control when you are ready to show your view again.
    // So for example, if you wanted to click => show spinner => show view, you would need to
    // invoke clearError() first such that your UI can begin to show again and you can manage
    // the loading state yourself.
    onRetry?: (clearError: () => void) => void;

    // Similar to onRetry, but gives you full control of the error UI. You can attach the
    // clearError method to whatever in your UI you want.
    renderError?: (clearError: () => void) => React.ReactNode;
  };

type State = {
  hasError: boolean;
};

class UIErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch = (error: Error, info: ErrorInfo) => {
    this.setState({ hasError: true });

    logException("", "componentDidCatch", "UIErrorBoundary", info.componentStack);
  };

  clearError = () => {
    this.setState({ hasError: false });
  };

  onRetry = () => {
    if (this.props.onRetry) {
      this.props.onRetry(this.clearError);
    }
  };

  render() {
    if (this.state.hasError) {
      // If we want a custom error UI
      if (this.props.renderError) {
        return this.props.renderError(this.clearError);
      }

      // Default error UI
      return (
        <div className={classnames(styles.root, this.props.className)}>
          <IonIcon className={styles.bugIcon} icon={bug} color="dark" />

          <UISpacer h={24} />

          <UIText variant="body1" color="dark" weight="medium">
            {this.props.t("SORRY_SOMETHING_WENT_WRONG")}
          </UIText>

          {this.props.onRetry && (
            <>
              <UISpacer h={16} />
              <UIButton onClick={this.onRetry} color="secondary">
                <UIText variant="body1" color="white" weight="medium">
                  {this.props.t("RETRY")}
                </UIText>
              </UIButton>
            </>
          )}
        </div>
      );
    }

    // Otherwise, no errors, show the main view.
    return this.props.children;
  }
}

const RouterConnected = withRouter(UIErrorBoundary);

export default withTranslation()(RouterConnected);
