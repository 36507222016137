import config from "constants/config";

type ApiPath =
  | "graphql"
  | "googleAuthCallback"
  | "facebookAuthStandaloneCallback"
  | "appleAuthCallback"
  | "sharedLoginValidate"
  | "sharedLoginLogin";

const PATHS = {
  graphql: "/graphql",
  googleAuthCallback: "/auth/google/callback",
  facebookAuthStandaloneCallback: "/auth/facebook/standalone_callback",
  appleAuthCallback: "/auth/apple/code_callback",
  sharedLoginValidate: "/auth/shared_login/validate",
  sharedLoginLogin: "/auth/shared_login/login",
};

const ajaxPath = {
  get(key: ApiPath): string {
    return `${config.get("CAMPFIRE_APP_WWW_URL")}${PATHS[key]}`;
  },
};

export default ajaxPath;
