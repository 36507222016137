import React, { Suspense } from "react";

import LoadingPageDefault from "common/components/LoadingPageDefault";

// Lazy Load Main View
const ExperienceMediaSharePageMainContent = React.lazy(
  () => import("pages/ExperienceMediaShare/components/ExperienceMediaSharePageMainContent"),
);

type Props = {};

type State = {
  initializedAt: number;
};

class ExperienceMediaSharePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      // TODO: Add UIErrorBOundary or soemthing
      // eslint-disable-next-line react/no-unused-state
      initializedAt: Date.now(),
    };
  }

  reInit = (): void => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  render = (): React.ReactNode => (
    <Suspense fallback={<LoadingPageDefault />}>
      <ExperienceMediaSharePageMainContent />
    </Suspense>
  );
}

export default ExperienceMediaSharePage;
