import { chevronDown } from "ionicons/icons";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { GAME_PROFILE_VISIBILITY_TO_TRANSLATION_KEY, GameProfileVisibility } from "constants/user";

import UIButton from "common/components/UIButton";
import UIDialog from "common/components/UIDialog";
import UIIcon from "common/components/UIIcon";
import UIText from "common/components/UIText";
import UserAppList, {
  toAppItemsShowAll,
  GameProfile,
  UserAppItem,
} from "common/components/UserAppList";
import UserAppVisibilitySelectionDrawer from "common/components/UserAppVisibilitySelectionDrawer";

import { UserAppListVisibilitySelection_me$data as UserAppListVisibilitySelectionMe } from "__generated__/UserAppListVisibilitySelection_me.graphql";

import styles from "./styles.scss";

type AppVisibilityState = Record<string, GameProfileVisibility>;

export type Props = WithTranslation & {
  me: UserAppListVisibilitySelectionMe;
  onChange: (state: AppVisibilityState) => void;
  appItemsVisibility: AppVisibilityState;
};

type State = {
  /**
   * Value is the ID of the app item
   */
  showAppItemVisibilityDialogFor: string;
};

class UserAppListVisibilitySelection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showAppItemVisibilityDialogFor: "",
    };
  }

  /**
   * Handle when user updates the visibility of an appItem from the drawer.
   * Updates our mapping and sets `showAppItemVisibilityDialogFor` to empty
   * to close the drawer.
   */
  onAppItemVisibilityChange = (visibility: GameProfileVisibility): void => {
    const { appItemsVisibility } = this.props;
    const { showAppItemVisibilityDialogFor } = this.state;

    appItemsVisibility[showAppItemVisibilityDialogFor] = visibility;

    this.setState({
      showAppItemVisibilityDialogFor: "",
    });
  };

  /**
   * Updates state to show the drawer in which user can change the visibility
   * of a given appItem. The state stores the appItem.id so we know which one
   * is potentially being updated.
   */
  changeShowAppItemVisibilityDialogForState = (appItemId: string): void => {
    // supports empty string to close dialog
    this.setState({
      showAppItemVisibilityDialogFor: appItemId,
    });
  };

  /**
   * Render the dialog drawer in which user can change an appItem's visibility
   */
  renderAppItemVisibilitySelectionDialog = (): React.ReactNode => {
    const { appItemsVisibility } = this.props;
    const { showAppItemVisibilityDialogFor } = this.state;

    return (
      <UIDialog
        type="drawer"
        isOpen={Boolean(showAppItemVisibilityDialogFor)}
        close={() => this.changeShowAppItemVisibilityDialogForState("")}
        onDidDismiss={() => this.changeShowAppItemVisibilityDialogForState("")}
      >
        <UserAppVisibilitySelectionDrawer
          selectedVisibility={appItemsVisibility[showAppItemVisibilityDialogFor]}
          onSelectVisibility={this.onAppItemVisibilityChange}
        />
      </UIDialog>
    );
  };

  /**
   * Renders a button with label showing current appItem's GameProfileVisibility
   * from state. Clicking on button triggers drawer in which user can change the visibility.
   */
  renderAppListVisibility = (appItem: UserAppItem): React.ReactNode => {
    const { appItemsVisibility } = this.props;
    const visibility = appItemsVisibility[appItem.id];
    const labelKey = GAME_PROFILE_VISIBILITY_TO_TRANSLATION_KEY[visibility];

    return (
      <UIButton
        fill="clear"
        className={styles.itemEndButton}
        onClick={() => this.changeShowAppItemVisibilityDialogForState(appItem.id)}
      >
        <UIText variant="subtitle1" weight="semiBold" color="dark">
          {this.props.t(labelKey)}
        </UIText>
        <UIIcon color="dark" icon={chevronDown} slot="end" />
      </UIButton>
    );
  };

  render = (): React.ReactNode => {
    const appList = toAppItemsShowAll(this.props.me.gameProfiles.filter(Boolean) as GameProfile[]);

    return (
      <>
        {this.renderAppItemVisibilitySelectionDialog()}
        <UserAppList
          showVisibilityDetails
          listCustomLabel={this.props.t("GAME_PROFILES")}
          userAppItems={appList}
          renderCustomEndContent={this.renderAppListVisibility}
        />
      </>
    );
  };
}

const FragmentContainer = createFragmentContainer(UserAppListVisibilitySelection, {
  me: graphql`
    fragment UserAppListVisibilitySelection_me on User {
      gameProfiles {
        id
        game
        codename
        faction
        factionColor
        visibility
        level
        lastPlayedTimestampMs
      }
    }
  `,
});

export default withTranslation()(FragmentContainer);
