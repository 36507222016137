// These are all the interactions that we mark in the app.
// Interactions are like "seen"/"not seen" type of things.
// This is the basis on which the Coachmark's utilize.

// eslint-disable-next-line import/prefer-default-export
export enum Interactions {
  ONBOARDING_OFFICIAL_CLUB = "onboarding-official-club",
  ONBOARDING_OFFICIAL_CLUB_NBAALLWORLD = "onboarding-official-club-nbaallworld",
  ONBOARDING_OFFICIAL_CLUB_PERIDOT = "onboarding-official-club-peridot",
  ONBOARDING_OFFICIAL_CLUB_PGO = "onboarding-official-club-pgo",
  PGO_EMBED_WELCOME = "pgo-embed-welcome",
  DONT_SHOW_FLARE_NOTIFICATION_MODAL = "dont-show-flare-notifications-modal",
  HAS_SEEN_FLARE_CHAT_MODAL = "has_seen_flare_chat_modal",
  ONBOARDING_PGO_EXPERIMENT_ONE = "onboarding-pgo-experiment-one",
  ONBOARDING_ACTIVITY_CENTER = "onboarding_activity_center",
  DONT_SHOW_TEAM_UP_HOST_INSTRUCTION_DIALOG = "dont-show-team_up-host-instruction-dialog",
  DONT_SHOW_NOTIFY_EVERYONE_DIALOG = "dont-show-notify-everyone-dialog",
}

type DynamicInteractionKeyPrefix = "realityChannelVisited" | "welcomeBannerAcknowledged";

export const getDynamicInteractionKey = (
  prefix: DynamicInteractionKeyPrefix,
  id: string,
): string => {
  return `${prefix}:${id}`;
};
