/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { CurrentLocationConsumer, ExposedProps } from "providers/CurrentLocationProvider";

export type WithCurrentLocationProps = {
  currentLocationProvider: ExposedProps;
};

// eslint-disable-next-line max-len
export default <P extends WithCurrentLocationProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithCurrentLocationProps>): React.ReactElement => (
    <CurrentLocationConsumer>
      {(exposedProps: ExposedProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component currentLocationProvider={exposedProps} {...props} />
      )}
    </CurrentLocationConsumer>
  );
};
