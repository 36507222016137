/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { VersionInfoConsumer, ExposedProps } from "providers/VersionInfoProvider";

export type WithVersionInfoProps = {
  versionInfo: {
    appVersion: ExposedProps["appVersion"];
  };
};

export default <P extends WithVersionInfoProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithVersionInfoProps>): React.ReactElement => (
    <VersionInfoConsumer>
      {({ appVersion }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          versionInfo={{
            appVersion,
          }}
          {...props}
        />
      )}
    </VersionInfoConsumer>
  );
};
