import { Keyboard } from "@capacitor/keyboard";

import { isWeb } from "common/capacitor/helpers";

const hideKeyboardAndBlurActiveElement = (): void => {
  // First hide the keyboard if on standalone
  if (!isWeb) {
    Keyboard.hide();
  }

  // If the document has something focused (aka the input of chat), blur it
  // so the browser doesn't refocus into it again.
  if (document.activeElement) {
    (document.activeElement as HTMLElement).blur();
  }
};

export default hideKeyboardAndBlurActiveElement;
