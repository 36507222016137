import { InputChangeEventDetail } from "@ionic/core";
import { IonDatetime, IonLabel } from "@ionic/react";
import dayjs from "dayjs";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import OnboardingStore from "common/stores/OnboardingStore";
import TEST_IDS from "constants/testIds";

import PrivacyFooter from "common/components/PrivacyFooter";
import UIButton from "common/components/UIButton";
import UIDialog from "common/components/UIDialog";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Mode = "age_entry" | "denied";

type Props = WithTranslation & {
  isOpen: boolean;
  close: () => void;
};

type State = {
  mode: Mode;
  birthday: string;
};

const MIN_AGE_ELIGIBLE = 13;

class AgeEligibilityDialog extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: "age_entry",
      birthday: "",
    };
  }

  updateBirthday = (event: CustomEvent<InputChangeEventDetail>): void => {
    const birthday = event.detail.value || "";

    this.setState({ birthday });
  };

  isAgeEligible = (birthdayIso: string): boolean => {
    const years = dayjs().diff(dayjs(birthdayIso), "year");

    return years >= MIN_AGE_ELIGIBLE;
  };

  backToLandingPage = (): void => {
    this.props.close();
  };

  checkIfAgeIsEligible = (): void => {
    const birthdayIso = new Date(Date.parse(this.state.birthday)).toISOString();

    if (this.isAgeEligible(birthdayIso)) {
      OnboardingStore.set("initialLaunchBirthday", birthdayIso);
      this.props.close();
    } else {
      this.setState({ mode: "denied" });
    }
  };

  renderAgeEntryMode = (): React.ReactNode => {
    return (
      <>
        <UIText variant="h2" color="dark" weight="extraBold">
          {this.props.t("WHEN_IS_YOUR_BIRTHDAY")}
        </UIText>

        <UISpacer h={36} />

        <div className={styles.datePickerContainer}>
          <IonLabel className={styles.label} position="stacked">
            <UIText color="medium" weight="bold">
              {this.props.t("BIRTHDAY")}
            </UIText>
          </IonLabel>
          <IonDatetime
            className={styles.datePicker}
            color="dark"
            displayFormat="MM/DD/YYYY"
            value={this.state.birthday}
            onIonChange={this.updateBirthday}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data-test-id={TEST_IDS.AgeEligibilityDateTimePickerId}
          />
        </div>

        <UISpacer h={16} />

        <UIButton
          color="primary"
          expand="block"
          disabled={!this.state.birthday}
          onClick={this.checkIfAgeIsEligible}
        >
          <UIText variant="body1" weight="bold">
            {this.props.t("SUBMIT")}
          </UIText>
        </UIButton>

        <UISpacer h={48} />

        <PrivacyFooter />
      </>
    );
  };

  renderDeniedMode = (): React.ReactNode => {
    return (
      <>
        <UIText variant="h2" color="dark" weight="extraBold">
          {this.props.t("YOU_ARE_INELIGIBLE_TO_USE_CAMPFIRE")}
        </UIText>

        <UISpacer h={36} />

        <UIButton color="primary" expand="block" onClick={this.backToLandingPage}>
          <UIText variant="body1" weight="bold">
            {this.props.t("BACK_TO_SIGN_UP")}
          </UIText>
        </UIButton>

        <UISpacer h={36} />
      </>
    );
  };

  render = (): React.ReactNode => {
    const { mode = "age_entry" } = this.state;

    const showAgeEntryMode = mode === "age_entry";
    const showDeniedMode = mode === "denied";

    return (
      <UIDialog
        isOpen={this.props.isOpen}
        cssClass={styles.root}
        backdropDismiss={false}
        close={this.renderDeniedMode}
      >
        <div className={styles.content}>
          {showAgeEntryMode && this.renderAgeEntryMode()}
          {showDeniedMode && this.renderDeniedMode()}
        </div>
      </UIDialog>
    );
  };
}

export default withTranslation()(AgeEligibilityDialog);
