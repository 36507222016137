/**
 * @generated SignedSource<<fe798c243674b62bdf43ed0f3a3fa2c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppRouterV2_me$data = {
  readonly id: string;
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CoreNavigationFloatingTabBar_me">;
  readonly " $fragmentType": "AppRouterV2_me";
};
export type AppRouterV2_me$key = {
  readonly " $data"?: AppRouterV2_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppRouterV2_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppRouterV2_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CoreNavigationFloatingTabBar_me"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6f2955bace2a31a89556075a0acdc5e7";

export default node;
