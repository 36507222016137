import _ from "lodash";

import getSlideInfoByIndex from "./getSlideInfoByIndex";
import { ReactSpringAnimationResult, SlideComponentDef, SlideStackState } from "./types";

/**
 * Triggers handlers on slides when animations have finished.
 */
const triggerHandlersForAnimationFinished = (
  currentSlideState: SlideStackState,
  slides: SlideComponentDef[],
  animationPromises: Array<Promise<ReactSpringAnimationResult>>,
): void => {
  // Convert an array of animation promises to an array with more info about each
  // slide so we can invoke handlers as necessary.
  const slideAnimationInfos = animationPromises.map((animationPromise, idx) => {
    const {
      onHidden = _.noop,
      onOpen = _.noop,
      isOpen,
      slideId,
    } = getSlideInfoByIndex(idx, slides, currentSlideState);

    return {
      slideId,
      animationPromise,
      isOpen,
      onHidden,
      onOpen,
    };
  });

  // For each animation, once it completes, check if the slide is no longer open
  // and if the animation completed (and actually changed). If so, invoke some methods.
  slideAnimationInfos.forEach((slideAnimationInfo) => {
    slideAnimationInfo.animationPromise.then((animationResult) => {
      const animationFinished = animationResult.finished;

      // If the animation finished and it was not a noop operation, check to see if the
      // slide state has changed to a hidden state for this slide. Checking for the current
      // value of isOpen is not enough as slides may start as hidden.
      if (animationFinished && !animationResult.noop) {
        const slideIsClosed = !slideAnimationInfo.isOpen;
        const slidesThatChangedToHidden = currentSlideState.lastSlideChanges.hiding;
        const slidesThatChangedToShow = currentSlideState.lastSlideChanges.showing;
        const slideJustTransitionedToHidden = slidesThatChangedToHidden.includes(
          slideAnimationInfo.slideId,
        );
        const slideJustTransitionedToShow = slidesThatChangedToShow.includes(
          slideAnimationInfo.slideId,
        );

        if (slideIsClosed && slideJustTransitionedToHidden) {
          slideAnimationInfo.onHidden(currentSlideState);
        } else if (!slideIsClosed && slideJustTransitionedToShow) {
          slideAnimationInfo.onOpen(currentSlideState);
        }
      }
    });
  });
};

export default triggerHandlersForAnimationFinished;
