import { InputChangeEventDetail } from "@ionic/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import UIButton from "common/components/UIButton";
import UIText from "common/components/UIText";
import UITextInput from "common/components/UITextInput";

import styles from "./styles.scss";

type Props = WithTranslation & {
  label: string;
  value: string;
  placeholder: string;
  onClear: () => void;
  onChange: (event: CustomEvent<InputChangeEventDetail>) => void;
};

const ResetAccountDataInput: React.FC<Props> = (props: Props) => {
  const renderEndAdorment = (): React.ReactNode => {
    return (
      <UIButton className={styles.resetBtn} color="blue-grey" size="small" onClick={props.onClear}>
        <UIText className={styles.endAdornment} variant="h4" weight="bold">
          {props.t("RESET")}
        </UIText>
      </UIButton>
    );
  };

  return (
    <div className={styles.root}>
      <UITextInput
        className={styles.input}
        label={props.label}
        value={props.value}
        placeholder={props.placeholder}
        onIonChange={props.onChange}
        endAdornment={renderEndAdorment()}
      />
    </div>
  );
};

export default withTranslation()(ResetAccountDataInput);
