import { useContext } from "react";

import {
  UserInteractionHistoryContext,
  ExposedProps,
} from "providers/UserInteractionHistoryProvider";

type HookReturnType = ExposedProps;

const useUserInteractionHistory = (): HookReturnType => {
  const exposedProps = useContext(UserInteractionHistoryContext);

  return exposedProps;
};

export default useUserInteractionHistory;
