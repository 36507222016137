import environment from "common/relay/relay-env";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import logException from "common/analytics/exceptions";
import { SUPER_ADMIN_BAN_OWNER_ERR_CODE } from "constants/backendErrors";
import { ROLE_NAME } from "constants/roles";
import AssignRolesToUserMutation from "mutations/AssignRolesToUserMutation";
import BanUserMutation from "mutations/BanUserMutation";
import RemoveAdminRoleMutation from "mutations/RemoveAdminRoleMutation";
import SuspendUserUntilExpirationMutation, {
  getDateOneWeekFromNowAsISOString,
} from "mutations/SuspendUserUntilExpirationMutation";
import TransferClubOwnershipMutation from "mutations/TransferClubOwnershipMutation";
import UnbanUserMutation from "mutations/UnbanUserMutation";

import ConfirmationDialogView, {
  OwnProps as ConfirmationDialogProps,
} from "common/components/ConfirmationDialogView";
import UIDialog from "common/components/UIDialog";
import UIText from "common/components/UIText";

import { ClubModerationControl_club$data as ClubModerationControlClub } from "__generated__/ClubModerationControl_club.graphql";
import { ClubModerationControl_me$data as ClubModerationControlMe } from "__generated__/ClubModerationControl_me.graphql";

export type ClubMemberObj = {
  id: string;
  displayName: string;
};

export type Prompt = (targetUser: ClubMemberObj, callback?: () => void) => void;

type ExposedProps = {
  promptMakeAdmin: Prompt;
  promptRemoveAdmin: Prompt;
  promptBanUser: Prompt;
  promptUnbanUser: Prompt;
  promptTransferOwnership: Prompt;
  promptSuspendUser: Prompt;
  promptExportUserData: Prompt;
  promptDeleteUser: Prompt;
};

export type Props = WithTranslation &
  WithToastProps & {
    children: (exposedProps: ExposedProps) => React.ReactNode;
    me: ClubModerationControlMe;
    club: ClubModerationControlClub;
  };

type State = {
  isDialogOpen: boolean;
  confirmationDialogProps: ConfirmationDialogProps;
};

class ClubModerationControl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDialogOpen: false,
      confirmationDialogProps: {
        title: "",
      },
    };
  }

  openConfirmationDialog = (confirmationDialogProps: ConfirmationDialogProps) => {
    this.setState({ isDialogOpen: true, confirmationDialogProps });
  };

  closeConfirmationDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  getAdminRoleId = (): string | null => {
    const existingAdminRole = this.props.club.roles.find((role) => role.name === ROLE_NAME.ADMIN);

    if (existingAdminRole) {
      return existingAdminRole.id;
    }

    return null;
  };

  banTargetUser = async (targetUser: ClubMemberObj): Promise<void> => {
    try {
      const payload = {
        userId: targetUser.id,
        clubId: this.props.club.id,
      };

      await BanUserMutation.commit(environment, payload);
    } catch (error) {
      if ((error as CampfireErrors.Error).extensions?.code === SUPER_ADMIN_BAN_OWNER_ERR_CODE) {
        // Only super admin will see this message
        this.props.toastProvider.showErrorToast("Must transfer ownership before banning");
      }

      logException(
        "ClubModerationControl.banTargetUser",
        "banTargetUser",
        "ClubModerationControl",
        error,
      );
    }
  };

  unbanTargetUser = async (targetUser: ClubMemberObj): Promise<void> => {
    try {
      const payload = {
        userId: targetUser.id,
        clubId: this.props.club.id,
      };

      await UnbanUserMutation.commit(environment, payload);
    } catch (error) {
      logException(
        "ClubModerationControl.unbanTargetUser",
        "unbanTargetUser",
        "ClubModerationControl",
        error,
      );
    }
  };

  /**
   * Promotes the targetUser to have roles that admins should have.
   */
  makeAdmin = async (targetUser: ClubMemberObj): Promise<void> => {
    try {
      const adminRoleId = this.getAdminRoleId();
      const roleIds = [adminRoleId].filter(Boolean) as string[];

      const payload = {
        userId: targetUser.id,
        clubId: this.props.club.id,
        roleIds,
      };

      await AssignRolesToUserMutation.commit(environment, payload);
    } catch (error) {
      logException("ClubModerationControl.makeAdmin", "makeAdmin", "ClubModerationControl", error);
    }
  };

  /**
   * Removes roles that admins should have from target user.
   */
  removeAdmin = async (targetUser: ClubMemberObj): Promise<void> => {
    try {
      const payload = {
        userId: targetUser.id,
        clubId: this.props.club.id,
      };

      await RemoveAdminRoleMutation.commit(environment, payload);
    } catch (error) {
      logException(
        "ClubModerationControl.removeAdmin",
        "removeAdmin",
        "ClubModerationControl",
        error,
      );
    }
  };

  /**
   * Transfers ownership to targetUser.
   */
  transferOwnership = async (targetUser: ClubMemberObj): Promise<void> => {
    try {
      const payload = {
        userId: targetUser.id,
        clubId: this.props.club.id,
      };

      await TransferClubOwnershipMutation.commit(environment, payload);
    } catch (error) {
      logException(
        "TransferOwnershipMutation",
        "transferOwnership",
        "ClubModerationControl",
        error,
      );
    }
  };

  /**
   * Suspends user from app for 14 days.
   */
  suspendUser = async (targetUser: ClubMemberObj): Promise<void> => {
    try {
      const payload = {
        userId: targetUser.id,
        expirationTime: getDateOneWeekFromNowAsISOString(),
      };

      await SuspendUserUntilExpirationMutation.commit(environment, payload);
    } catch (error) {
      logException(
        "SuspendUserUntilExpirationMutation",
        "suspendUser",
        "ClubModerationControl",
        error,
      );
    }
  };

  /**
   * GDPR export of user data.
   */
  exportUserData = async (targetUser: ClubMemberObj): Promise<void> => {
    try {
      logException("implement me!", "", "", targetUser);
    } catch (error) {
      logException("ExportUserDataMutation", "exportUserData", "ClubModerationControl", error);
    }
  };

  /**
   * GDPR deletion of user.
   */
  deleteUser = async (targetUser: ClubMemberObj): Promise<void> => {
    try {
      logException("implement me!", "", "", targetUser);
    } catch (error) {
      logException("DeleteUserMutation", "deleteUser", "ClubModerationControl", error);
    }
  };

  /**
   * Opens prompt before making someone an admin
   */
  promptToConfirmAdmin = (targetUser: ClubMemberObj, callback?: () => void): void => {
    const { displayName } = targetUser;

    const renderTitle = () => (
      <UIText color="dark" variant="h3" weight="bold">
        <Trans i18nKey="ARE_YOU_SURE_ADMIN">
          <UIText color="medium" variant="h3" weight="bold">
            {{ displayName }}
          </UIText>
        </Trans>
      </UIText>
    );

    const onConfirm = async () => {
      // Invoke mutation, close dialog, and then invoke callback if desired.
      await this.makeAdmin(targetUser);
      this.closeConfirmationDialog();

      if (callback) {
        callback();
      }
    };

    // Open the prompt!
    this.openConfirmationDialog({
      title: this.props.t("ARE_YOU_SURE"),
      renderTitle,
      onCancel: this.closeConfirmationDialog,
      onConfirm,
    });
  };

  /**
   * Opens prompt before removing someone as an admin
   */
  promptToRemoveAdmin = (targetUser: ClubMemberObj, callback?: () => void): void => {
    const { displayName } = targetUser;

    const renderTitle = () => (
      <UIText color="dark" variant="h3" weight="bold">
        <Trans i18nKey="ARE_YOU_SURE_REMOVE_ADMIN">
          <UIText color="medium" variant="h3" weight="bold">
            {{ displayName }}
          </UIText>
        </Trans>
      </UIText>
    );

    const onConfirm = async () => {
      // Invoke mutation, close dialog, and then invoke callback if desired.
      await this.removeAdmin(targetUser);
      this.closeConfirmationDialog();

      if (callback) {
        callback();
      }
    };

    // Open the prompt!
    this.openConfirmationDialog({
      title: this.props.t("ARE_YOU_SURE"),
      renderTitle,
      onCancel: this.closeConfirmationDialog,
      onConfirm,
    });
  };

  promptToBan = (targetUser: ClubMemberObj, callback?: () => void): void => {
    const { displayName } = targetUser;

    const renderTitle = () => (
      <UIText color="dark" variant="h3" weight="bold">
        <Trans i18nKey="ARE_YOU_SURE_BAN">
          <UIText color="medium" variant="h3" weight="bold">
            {{ displayName }}
          </UIText>
        </Trans>
      </UIText>
    );

    const onConfirm = async () => {
      // Invoke mutation, close dialog, and then invoke callback if desired.
      await this.banTargetUser(targetUser);
      this.closeConfirmationDialog();

      if (callback) {
        callback();
      }
    };

    // Open the prompt!
    this.openConfirmationDialog({
      title: this.props.t("ARE_YOU_SURE"),
      renderTitle,
      onCancel: this.closeConfirmationDialog,
      onConfirm,
    });
  };

  promptToUnban = (targetUser: ClubMemberObj, callback?: () => void): void => {
    const { displayName } = targetUser;

    const renderTitle = () => (
      <UIText color="dark" variant="h3" weight="bold">
        <Trans i18nKey="ARE_YOU_SURE_UNBAN">
          <UIText color="medium" variant="h3" weight="bold">
            {{ displayName }}
          </UIText>
        </Trans>
      </UIText>
    );

    const onConfirm = async () => {
      // Invoke mutation, close dialog, and then invoke callback if desired.
      await this.unbanTargetUser(targetUser);
      this.closeConfirmationDialog();

      if (callback) {
        callback();
      }
    };

    // Open the prompt!
    this.openConfirmationDialog({
      title: this.props.t("ARE_YOU_SURE"),
      renderTitle,
      onCancel: this.closeConfirmationDialog,
      onConfirm,
    });
  };

  promptToTransferOwnership = (targetUser: ClubMemberObj, callback?: () => void): void => {
    const { displayName } = targetUser;
    const clubName = this.props.club.name;

    const renderTitle = () => (
      <UIText color="dark" variant="h3" weight="bold">
        <Trans i18nKey="ARE_YOU_SURE_TRANSFER">
          <UIText color="medium" variant="h3" weight="bold">
            {{ displayName }}
          </UIText>
          <UIText color="medium" variant="h3" weight="bold">
            {{ clubName }} to {{ displayName }}
          </UIText>
        </Trans>
      </UIText>
    );

    const onConfirm = async () => {
      // Invoke mutation, close dialog, and then invoke callback if desired.
      await this.transferOwnership(targetUser);
      this.closeConfirmationDialog();

      if (callback) {
        callback();
      }
    };

    // Open the prompt!
    this.openConfirmationDialog({
      title: this.props.t("ARE_YOU_SURE"),
      renderTitle,
      onCancel: this.closeConfirmationDialog,
      onConfirm,
    });
  };

  promptToSuspendUser = (targetUser: ClubMemberObj, callback?: () => void): void => {
    const { displayName } = targetUser;

    const renderTitle = () => (
      <UIText color="dark" variant="h3" weight="bold">
        <Trans i18nKey="ARE_YOU_SURE_SUSPEND">
          <UIText color="medium" variant="h3" weight="bold">
            {{ displayName }}
          </UIText>
        </Trans>
      </UIText>
    );

    const onConfirm = async () => {
      // Invoke mutation, close dialog, and then invoke callback if desired.
      await this.suspendUser(targetUser);
      this.closeConfirmationDialog();

      if (callback) {
        callback();
      }
    };

    // Open the prompt!
    this.openConfirmationDialog({
      title: this.props.t("ARE_YOU_SURE"),
      renderTitle,
      onCancel: this.closeConfirmationDialog,
      onConfirm,
    });
  };

  promptToExportUserData = (targetUser: ClubMemberObj, callback?: () => void): void => {
    const { displayName } = targetUser;

    const renderTitle = () => (
      <UIText color="dark" variant="h3" weight="bold">
        <Trans i18nKey="ARE_YOU_SURE_EXPORT">
          <UIText color="medium" variant="h3" weight="bold">
            {{ displayName }}
          </UIText>
        </Trans>
      </UIText>
    );

    const onConfirm = async () => {
      // Invoke mutation, close dialog, and then invoke callback if desired.
      await this.exportUserData(targetUser);
      this.closeConfirmationDialog();

      if (callback) {
        callback();
      }
    };

    // Open the prompt!
    this.openConfirmationDialog({
      title: this.props.t("ARE_YOU_SURE"),
      renderTitle,
      onCancel: this.closeConfirmationDialog,
      onConfirm,
    });
  };

  promptToDeleteUser = (targetUser: ClubMemberObj, callback?: () => void): void => {
    const { displayName } = targetUser;

    const renderTitle = () => (
      <UIText color="dark" variant="h3" weight="bold">
        <Trans i18nKey="ARE_YOU_SURE_DELETE_USER">
          <UIText color="medium" variant="h3" weight="bold">
            {{ displayName }}
          </UIText>
        </Trans>
      </UIText>
    );

    const onConfirm = async () => {
      // Invoke mutation, close dialog, and then invoke callback if desired.
      await this.deleteUser(targetUser);
      this.closeConfirmationDialog();

      if (callback) {
        callback();
      }
    };

    // Open the prompt!
    this.openConfirmationDialog({
      title: this.props.t("ARE_YOU_SURE"),
      renderTitle,
      onCancel: this.closeConfirmationDialog,
      onConfirm,
    });
  };

  render = (): React.ReactNode => {
    const exposedProps = {
      promptMakeAdmin: this.promptToConfirmAdmin,
      promptRemoveAdmin: this.promptToRemoveAdmin,
      promptBanUser: this.promptToBan,
      promptUnbanUser: this.promptToUnban,
      promptTransferOwnership: this.promptToTransferOwnership,
      promptSuspendUser: this.promptToSuspendUser,
      promptExportUserData: this.promptToExportUserData,
      promptDeleteUser: this.promptToDeleteUser,
    };

    return (
      <>
        <UIDialog
          type="floating-center"
          isOpen={this.state.isDialogOpen}
          onWillDismiss={this.closeConfirmationDialog}
          close={this.closeConfirmationDialog}
        >
          <ConfirmationDialogView {...this.state.confirmationDialogProps} />
        </UIDialog>

        {this.props.children(exposedProps)}
      </>
    );
  };
}

const FragmentContainer = createFragmentContainer(ClubModerationControl, {
  me: graphql`
    fragment ClubModerationControl_me on User {
      id
    }
  `,
  club: graphql`
    fragment ClubModerationControl_club on Club {
      id
      name
      roles {
        id
        name
      }
    }
  `,
});

const ToastConnected = withToast(FragmentContainer);

export default withTranslation()(ToastConnected);
