import _ from "lodash";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";

type CustomMutationError = Error & {
  extensions?: {
    display: boolean;
  };
};

export const getFirstErrorFromMutationResponse = (
  mutationName: string,
  response: GenericObject,
): CustomMutationError | undefined => {
  const hasErrors = _.get(response, `${mutationName}.errors.length`, 0) > 0;

  if (hasErrors) {
    return _.get(response, `${mutationName}.errors[0]`);
  }

  return undefined;
};

export const checkForErrorFromMutationResponse = (
  mutationName: string,
  response: GenericObject,
): boolean => {
  return Boolean(getFirstErrorFromMutationResponse(mutationName, response));
};

export const extractErrorMessageFromSource = (error: Error): string => {
  const relayErrors = _.get(error, "source.errors") || [];

  if (relayErrors.length) {
    const firstError = relayErrors[0];

    // Check if we should display this error
    if (firstError && firstError.extensions && firstError.extensions.display) {
      return firstError.message;
    }
  }

  return APOLOGETIC_ERROR_MESSAGE;
};

export const extractErrorMessageFromMutationResponse = (
  mutationName: string,
  response: GenericObject,
): string => {
  const firstError = getFirstErrorFromMutationResponse(mutationName, response);

  if (firstError) {
    // Check if we should display this error
    if (firstError && firstError.extensions && firstError.extensions.display) {
      return firstError.message;
    }
  }

  return APOLOGETIC_ERROR_MESSAGE;
};
