import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { UpdateInteractionHistoryMutation$data } from "__generated__/UpdateInteractionHistoryMutation.graphql";

type ReturnType = UpdateInteractionHistoryMutation$data["updateInteractionHistory"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = Record<string, number>;

const mutation = graphql`
  mutation UpdateInteractionHistoryMutation($input: UpdateInteractionHistoryInput!) {
    updateInteractionHistory(input: $input) {
      me {
        interactionHistory
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "updateInteractionHistory.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "updateInteractionHistory.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "updateInteractionHistory"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      cacheConfig: {
        metadata: {
          disableMutationInvalidation: true,
        },
      },
      variables: {
        input: {
          interactionHistoryUpdate: input,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
