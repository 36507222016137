import React from "react";

import withCampfirePage, {
  WithCampfirePageProps,
} from "providers/CampfirePageProvider/withCampfirePage";
import { ExposedProps as SelectedRealityChannelProviderExposedProps } from "providers/SelectedRealityChannelProvider";

import { RealityChannelMetadata } from "common/stores/SelectedRealityChannelStore";

import MapAndActivitiesRouteStateSynchronizer from "pages/MapAndActivities/components/MapAndActivitiesRouteStateSynchronizer";

export type ExposedProps = {
  selectedRealityChannelId: string;
};

type Props = WithCampfirePageProps & {
  children: React.ReactNode;
  possibleRealityChannels: RealityChannelMetadata[];
  selectedRealityChannelId: string;
  changeSelectedRealityChannel: SelectedRealityChannelProviderExposedProps["changeSelectedRealityChannel"];
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  selectedRealityChannelId: "",
};

export const ActivityPageStateContext = React.createContext(INITIAL_CONTEXT);

/**
 * Proxies the global selected RC state to components downstream and watches url changes on the
 * map and activity page and updates the selected RC state accordingly.
 */
class ActivityPageStateProvider extends React.Component<Props, State> {
  changeGlobalRealityChannel = (realityChannelId: string): void => {
    const accessibleRC = this.props.possibleRealityChannels.find((rc) => {
      return rc.id === realityChannelId;
    });

    if (accessibleRC) {
      this.props.changeSelectedRealityChannel(accessibleRC);
    }
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <ActivityPageStateContext.Provider
        value={{
          selectedRealityChannelId: this.props.selectedRealityChannelId,
        }}
      >
        <MapAndActivitiesRouteStateSynchronizer
          selectedRealityChannelId={this.props.selectedRealityChannelId}
          isActive={this.props.campfirePage.isInteractive}
          changeGlobalRealityChannel={this.changeGlobalRealityChannel}
        />
        {children}
      </ActivityPageStateContext.Provider>
    );
  };
}

const CampfirePageConnected = withCampfirePage(ActivityPageStateProvider);

export { CampfirePageConnected as ActivityPageStateProvider };
export const ActivityPageStateConsumer = ActivityPageStateContext.Consumer;
