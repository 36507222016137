import classnames from "classnames";
import { bonfireOutline } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import PollingRefetcher from "common/components/PollingRefetcher";
import UIClickableIcon from "common/components/UIClickableIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  retry?: () => void;
};

type State = {};

const RETRY_INTERVAL_MS = 60000;

class AppError extends React.Component<Props, State> {
  retry = (): void => {
    if (this.props.retry) {
      this.props.retry();
    }
  };

  render = (): React.ReactNode => {
    const hasRetryMethod = Boolean(this.props.retry);

    return (
      <div className={styles.root}>
        {hasRetryMethod && (
          <PollingRefetcher pollingFn={this.retry} intervalTimeMs={RETRY_INTERVAL_MS} />
        )}

        <div className={classnames(styles.bgImage)} />
        <UIText variant="h2" color="dark" weight="bold">
          {this.props.t("WERE_HAVING_TROUBLE_GETTING_THE_FIRE_STARTED")}
        </UIText>

        <UISpacer h={24} />

        <UIText variant="h4" color="dark" weight="semiBold">
          {this.props.t("HANG_TIGHT_AS_WE_REKINDLE_THE_FLAME")}
        </UIText>

        <UISpacer h={36} />

        <UIClickableIcon size={48} icon={bonfireOutline} color="dark" onClick={this.retry} />
      </div>
    );
  };
}

export default withTranslation()(AppError);
