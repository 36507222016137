import { App, BackButtonListenerEvent } from "@capacitor/app";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { isStandalone } from "common/capacitor/helpers";
import { simulateIonBackEvent } from "common/utils/ionic/ionicEvents";
import { _actionEventEmitter, isEmbedded } from "common/utils/webInterop";
import AppInterop from "common/utils/webInterop/app";

import IonBackHandler from "common/components/IonBackHandler";

type Props = RouteComponentProps & {};

type State = {};

const EMBED_ANDROID_BACK_BUTTON_EVENT_NAME = "androidBackButton";

class HardwareBackHandler extends React.Component<Props, State> {
  appListenerHandler: GenericObject | null;

  constructor(props: Props) {
    super(props);

    this.appListenerHandler = null;
  }

  componentDidMount = (): void => {
    if (isStandalone) {
      this.listenForHardwareBackOnStandaloneWhenCantGoBack();
    } else if (isEmbedded) {
      _actionEventEmitter.on(EMBED_ANDROID_BACK_BUTTON_EVENT_NAME, this.handleHardwareBack);
    }
  };

  componentWillUnmount = (): void => {
    _actionEventEmitter.off(EMBED_ANDROID_BACK_BUTTON_EVENT_NAME, this.handleHardwareBack);

    if (this.appListenerHandler) {
      this.appListenerHandler.remove();
    }
  };

  listenForHardwareBackOnStandaloneWhenCantGoBack = (): void => {
    this.appListenerHandler = App.addListener("backButton", this.handleHardwareBack);
  };

  handleHardwareBack = (backButtonEventInfo: BackButtonListenerEvent): void => {
    const aHandlerWasTriggered = simulateIonBackEvent();

    if (!aHandlerWasTriggered) {
      // When capacitor says we can't go back anymore, minimize the app.
      if (!backButtonEventInfo.canGoBack) {
        if (isStandalone) {
          App.minimizeApp();
        } else if (isEmbedded) {
          AppInterop.logout();
        }
      }
    }
  };

  render = (): React.ReactNode => {
    // Mirroring what ionic does, now that we no longer rely on ionics router:
    // https://github.com/ionic-team/ionic-framework/blob/223f36f6adacf8adce47cee4809a60c94a9e0efa/packages/react/src/routing/NavManager.tsx#L89
    // Assign a default handling to perform a router back on hardware back. Super low
    // priority so anything else like a UINav, UIPane's hardware back handling will be handled
    // instead of this. Otherwise, if nothing else desires to be handled, we can do this.
    return (
      <IonBackHandler
        isActive
        priority={0}
        onHardwareBack={(processNextEvent) => {
          this.props.history.goBack();
          // NOTE: This is important to invoke for any hardware back handler that is always
          // listening in order to correctly detect when to close the app.
          processNextEvent();
        }}
      />
    );
  };
}

export default withRouter(HardwareBackHandler);
