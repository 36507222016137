import { RouteComponentProps } from "react-router-dom";

import { MESSAGES_ROUTE, MESSAGES_CHANNEL_ROUTE } from "constants/routes";

export const getUrlForDmChannel = (channelId: string): string => {
  return `/${MESSAGES_ROUTE}/${MESSAGES_CHANNEL_ROUTE}/${channelId}`;
};

// Core method since functional hook based components don't usually get all RouteComponentProps.
// Well, its more like its tedious and useless to get all props like match and location when
// all we need is history.
export const routeToDmChannelCore = (
  history: RouteComponentProps["history"],
  location: RouteComponentProps["location"],
  channelId: string,
  replace?: boolean,
  preserveSearch?: boolean,
): void => {
  if (replace) {
    history.replace({
      pathname: getUrlForDmChannel(channelId),
      ...(preserveSearch && { search: location.search }),
    });
  } else {
    history.push({
      pathname: getUrlForDmChannel(channelId),
      ...(preserveSearch && { search: location.search }),
    });
  }
};

export default (
  routeComponentProps: RouteComponentProps,
  channelId: string,
  replace?: boolean,
  preserveSearch?: boolean,
): void => {
  routeToDmChannelCore(
    routeComponentProps.history,
    routeComponentProps.location,
    channelId,
    replace,
    preserveSearch,
  );
};
