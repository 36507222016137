import { IonItem } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { interleaveItems } from "common/utils/arrayHelpers";
import { formatLastPlayedTime } from "common/utils/humanReadableDurations/gameProfileOrientedDurations";
import GAMES_BY_SHORT_CODE, { GameInfo, GameShortCode } from "constants/nianticGame";
import { GameProfileVisibility } from "constants/user";

import Image from "common/components/Image";
import UIButton from "common/components/UIButton";
import UIClickableIcon from "common/components/UIClickableIcon";
import UIDialog from "common/components/UIDialog";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  userAppItems: UserAppItem[];
  showVisibilityDetails?: boolean;
  listCustomLabel?: string;
  renderCustomEndContent?: (appItem: UserAppItem) => React.ReactNode;
};

type State = {
  showGameProfilesInfoDialog: boolean;
};

export type UserAppItem = {
  id: string;
  shortCode: GameShortCode;
  username: string;
  visibility: GameProfileVisibility;
  lastPlayedTimestampMs?: number;
  factionName?: string;
  factionColor?: string;
  level?: number;
};

export type GameProfile = {
  // Apparently, you can have multiple game profiles for the same game o_O
  id: string;
  game: string;
  codename: string;
  faction: string;
  factionColor: string;
  visibility: GameProfileVisibility;
  level?: number;
  lastPlayedTimestampMs?: number;
};

const NOT_SPECIFIED_FACTION_NAME = "NOT_SPECIFIED";
const UNSET_FACTION_NAME = "UNSET";

const convertGameProfileToAppItem = (gameProfile: GameProfile): UserAppItem => {
  return {
    id: gameProfile.id,
    shortCode: gameProfile.game as GameShortCode,
    username: gameProfile.codename,
    factionName: gameProfile.faction,
    factionColor: gameProfile.factionColor,
    visibility: gameProfile.visibility,
    level: gameProfile.level,
    lastPlayedTimestampMs: gameProfile.lastPlayedTimestampMs,
  };
};

/**
 * Returns list of all visible gameProfiles/appItems based on their visibility settings.
 * Is visible if visibility is set to EVERYONE or FRIENDS and P1/P2 are friends
 *
 * @param gameProfiles - the gameProfiles to check
 * @param isMyFriend - whether P1/P2 are friends
 */
export const toAppItems = (gameProfiles: GameProfile[], isMyFriend: boolean): UserAppItem[] => {
  return gameProfiles
    .filter(Boolean)
    .filter(
      (gameProfile) =>
        gameProfile.visibility === GameProfileVisibility.EVERYONE ||
        (gameProfile.visibility === GameProfileVisibility.FRIENDS && isMyFriend),
    )
    .map(convertGameProfileToAppItem);
};

/**
 * Show all gameProfiles/appItems regardless of visibility settings
 *
 * @param gameProfiles - the gameProfiles
 */
export const toAppItemsShowAll = (gameProfiles: GameProfile[]): UserAppItem[] => {
  return gameProfiles.filter(Boolean).map(convertGameProfileToAppItem);
};

class UserAppList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showGameProfilesInfoDialog: false,
    };
  }

  toggleGameProfilesInfoDialog = (showDialog: boolean): void => {
    this.setState({
      showGameProfilesInfoDialog: showDialog,
    });
  };

  renderGameProfilesInfoDialog = (): React.ReactNode => {
    return (
      <UIDialog
        type="floating-center"
        isOpen={this.state.showGameProfilesInfoDialog}
        close={() => this.toggleGameProfilesInfoDialog(false)}
        onDidDismiss={() => this.toggleGameProfilesInfoDialog(false)}
      >
        <div className={styles.gameProfilesInfoDialogContent}>
          <UIText variant="h3" color="dark" weight="bold">
            {this.props.t("GAME_PROFILES")}
          </UIText>
          <UISpacer h={8} />
          <UIText variant="body1" color="dark">
            {this.props.t("GAME_PROFILES_INFO")}
          </UIText>
          <UISpacer h={20} />
          <UIButton
            fill="solid"
            color="light"
            onClick={() => this.toggleGameProfilesInfoDialog(false)}
          >
            <UIText variant="body1" color="dark" weight="bold">
              {this.props.t("CLOSE").toUpperCase()}
            </UIText>
          </UIButton>
        </div>
      </UIDialog>
    );
  };

  renderHeaderLabel = (label: string): React.ReactNode => {
    return (
      <UIText className={styles.label} color="medium" variant="h5" weight="bold">
        {label}
      </UIText>
    );
  };

  renderHeader = (): React.ReactNode => {
    const label: string = this.props.listCustomLabel ?? this.props.t("GAMES");
    const labelElement = this.renderHeaderLabel(label);

    if (this.props.showVisibilityDetails) {
      return (
        <div className={styles.header}>
          <div className={styles.headerLabelAndInfo}>
            {labelElement}
            <UIClickableIcon
              icon={informationCircle}
              color="medium"
              size={14}
              onClick={() => this.toggleGameProfilesInfoDialog(true)}
            />
          </div>
          {this.renderHeaderLabel(this.props.t("VISIBILITY"))}
        </div>
      );
    }

    return labelElement;
  };

  renderEndContent = (appItem: UserAppItem): React.ReactNode => {
    if (this.props.renderCustomEndContent) {
      return this.props.renderCustomEndContent(appItem);
    }

    if (appItem.lastPlayedTimestampMs) {
      const playedAtString = formatLastPlayedTime(appItem.lastPlayedTimestampMs);

      return (
        <UIText color="medium" variant="body2" weight="medium">
          {playedAtString}
        </UIText>
      );
    }

    return null;
  };

  renderDescriptionText = (userAppItem: UserAppItem): React.ReactNode => {
    const hasLevel = Boolean(userAppItem.level);
    const hasFactionName =
      Boolean(userAppItem.factionName) &&
      userAppItem.factionName !== UNSET_FACTION_NAME &&
      userAppItem.factionName !== NOT_SPECIFIED_FACTION_NAME;
    const hasSomethingToShow = Boolean(userAppItem.level) || Boolean(userAppItem.factionName);

    if (!hasSomethingToShow) {
      return null;
    }

    const factionNameStyles = {
      ...(userAppItem.factionColor && { color: userAppItem.factionColor }),
    };

    // Add text nodes we want to show to this array.
    // Interleave each node with a pipe element. This way, we can handle cases where
    // level is defined but factionName isn't, or the other way around and not accidentally
    // insert the pipe between. Basically, trying to mimic array.join but for arbitrary items.
    const textNodes = [];

    if (hasLevel) {
      textNodes.push(
        <UIText variant="body2" weight="bold">
          {this.props.t("LEVEL", { level: userAppItem.level })}
        </UIText>,
      );
    }

    if (hasFactionName) {
      textNodes.push(
        <UIText variant="body2" weight="bold" style={factionNameStyles}>
          {userAppItem.factionName}
        </UIText>,
      );
    }

    const seperator = (
      <UIText variant="body2" weight="bold" className={styles.pipe}>
        |
      </UIText>
    );
    const nodesToRender = interleaveItems(textNodes, seperator) as React.ReactNode[];

    return (
      <div>
        {nodesToRender.map((node: React.ReactNode, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={idx}>{node}</React.Fragment>
        ))}
      </div>
    );
  };

  renderUserAppItem = (userAppItem: UserAppItem): React.ReactNode => {
    const gameInfo: GameInfo | undefined = GAMES_BY_SHORT_CODE[userAppItem.shortCode];

    if (!gameInfo) {
      return null;
    }

    const gameLogo = gameInfo ? gameInfo.logoUrl : "";

    return (
      <IonItem className={styles.ionItem} key={userAppItem.id} detail={false} lines="none">
        <div className={styles.appItem}>
          <div className={styles.logoWrapper}>
            <Image alt="Logo" className={styles.logo} src={gameLogo} />
          </div>

          <UISpacer w={12} />
          <div className={styles.textParent}>
            <UIText color="dark" variant="subtitle1" weight="bold">
              {userAppItem.username}
            </UIText>
            {this.renderDescriptionText(userAppItem)}
          </div>
        </div>
        <div slot="end" className={styles.end}>
          {this.renderEndContent(userAppItem)}
        </div>
      </IonItem>
    );
  };

  render = (): React.ReactNode => {
    return (
      <>
        {this.renderGameProfilesInfoDialog()}
        <div className={styles.root}>
          {this.renderHeader()}
          <UISpacer h={4} />
          <div className={styles.itemParent}>
            {this.props.userAppItems.map((userAppItem) => this.renderUserAppItem(userAppItem))}
          </div>
        </div>
      </>
    );
  };
}

export default withTranslation()(UserAppList);
