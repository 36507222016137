import { RouteComponentProps } from "react-router-dom";

import { CLUBS_ROUTE, QUERY_PARAMS } from "constants/routes";

export const getUrlForClubChannel = (clubId: string, channelId: string): string => {
  return `/${CLUBS_ROUTE}/${clubId}/${channelId}`;
};

export const getUrlForClubChannelWithInvite = (
  clubId: string,
  channelId: string,
  hasInvite: boolean,
): string => {
  const hasInviteKey = QUERY_PARAMS.clubs.hasInvite.key;
  const hasInviteValue = hasInvite ? "true" : "false";

  const queryParams: Record<string, string> = {
    [hasInviteKey]: hasInviteValue,
  };

  const queryString: string = Object.keys(queryParams)
    .filter((key) => !!queryParams[key])
    .map((key) => `${key}=${queryParams[key] || ""}`)
    .join("&");

  return `${getUrlForClubChannel(clubId, channelId)}?${queryString}`;
};

// Core method since functional hook based components don't usually get all RouteComponentProps.
// Well, it's more like its tedious and useless to get all props when all we need is history
// and location.
export const routeToClubChannelCore = (
  history: RouteComponentProps["history"],
  location: RouteComponentProps["location"],
  clubId: string,
  channelId: string,
  replace?: boolean,
  preserveSearch?: boolean,
): void => {
  if (replace) {
    history.replace({
      pathname: getUrlForClubChannel(clubId, channelId),
      ...(preserveSearch && { search: location.search }),
    });
  } else {
    history.push({
      pathname: getUrlForClubChannel(clubId, channelId),
      ...(preserveSearch && { search: location.search }),
    });
  }
};

export default (
  routeComponentProps: Pick<RouteComponentProps, "history" | "location">,
  clubId: string,
  channelId: string,
  replace?: boolean,
  preserveSearch?: boolean,
): void => {
  routeToClubChannelCore(
    routeComponentProps.history,
    routeComponentProps.location,
    clubId,
    channelId,
    replace,
    preserveSearch,
  );
};
