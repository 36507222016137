/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as CryptoJS from "crypto-js";
import _ from "lodash";

import logException from "common/analytics/exceptions";
import config from "constants/config";

/**
 * Pubnub messages are encrypted on the server using AES encryption https://cryptojs.gitbook.io/docs/
 * Pubnub message payloads should always be valid JSON https://www.pubnub.com/docs/rest-api#history-fetch-history-get
 * If message payload values cannot be decrypted they are replaced with undefined
 */

type GenericObject = { [key: string]: any | null };

export const decryptText = (value: any): string => {
  const errorMsg = "error while attempting to decrypted string";

  const encryptionKey = config.get("CAMPFIRE_APP_PUBNUB_ENCRYPTION_KEY");

  try {
    const wordArray = CryptoJS.AES.decrypt(value as string, encryptionKey as string);

    return wordArray.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    logException("CryptoJS.AES.decrypt", "decryptText", "decryption", error, true);
    throw new Error(`${errorMsg} ${error}`);
  }
};

const parseJSONString = (text: string): any => {
  try {
    return JSON.parse(text);
  } catch (error) {
    logException("JSON.parse", "parseJSONString", "decryption", error, true);
    throw new Error(`error while attempting to parse decrypted JSON string ${error}`);
  }
};

export const decryptMessage = (message: GenericObject): GenericObject => {
  // Clone the message so that we don't mutate the original.
  const decryptedMessage = _.cloneDeep(message);

  Object.keys(message).forEach((key) => {
    try {
      const decryptedText = decryptText(decryptedMessage[key]);

      decryptedMessage[key] = parseJSONString(decryptedText);
    } catch (error) {
      decryptedMessage[key] = undefined;
      logException("parseJSONString", "decryptMessage", "decryption", error);
    }
  });

  return decryptedMessage;
};

export default decryptMessage;
