import { PositionOptions, Position } from "@capacitor/geolocation";
import environment from "common/relay/relay-env";
import _ from "lodash";
import React from "react";

import StubbedPlugins from "common/utils/webInterop/plugins";
import LocationHeartbeatMutation from "mutations/LocationHeartbeatMutation";

import PollingRefetcher from "common/components/PollingRefetcher";

import { LocationHeartbeatMutation$data } from "__generated__/LocationHeartbeatMutation.graphql";

const { Geolocation } = StubbedPlugins;

type AvailableCoords = Pick<Position["coords"], "latitude" | "longitude">;

export type CachedPosition = {
  timestamp: number;
  coords: AvailableCoords | null;
};

type WatchCallbackFn = (position: CachedPosition) => void;
type WatchCleanupFn = () => void;

export type ExposedProps = {
  currentPosition: CachedPosition;
  getCurrentPosition: (
    positionOptions?: PositionOptions,
    syncLocationToBackend?: boolean,
    freshnessMs?: number,
  ) => Promise<CachedPosition | undefined>;
  watchPosition: (callback: WatchCallbackFn) => WatchCleanupFn;
  pingLocation: (location: {
    latitude: number;
    longitude: number;
  }) => Promise<LocationHeartbeatMutation$data["locationHeartbeat"]>;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  currentPosition: CachedPosition;
  isWatchingPosition: boolean;
};

const getDefaultPosition = (): CachedPosition => ({
  timestamp: Date.now(),
  coords: null,
});

const THROTTLE_MS = 5000;
const POLL_WATCH_POSITION_MS = 5000;
const INITIAL_POSITION = getDefaultPosition();
const DEFAULT_WATCH_POSITION_OPTIONS = {
  maximumAge: 0,
  timeout: POLL_WATCH_POSITION_MS,
  enableHighAccuracy: true,
};
const INITIAL_CONTEXT: ExposedProps = {
  currentPosition: INITIAL_POSITION,
  getCurrentPosition: () => Promise.reject(INITIAL_POSITION),
  watchPosition: () => _.noop,
  pingLocation: () => Promise.reject(),
};

export const CurrentLocationContext = React.createContext(INITIAL_CONTEXT);

export class CurrentLocationProvider extends React.Component<Props, State> {
  // A map of callbacks that should be invoked when the current position changes.
  watchPositionCallbacksRegistered = new Map();

  constructor(props: Props) {
    super(props);

    this.state = {
      currentPosition: getDefaultPosition(),
      isWatchingPosition: false,
    };
  }

  isWithinDuration = (timestamp: number, duration: number): boolean => {
    return Date.now() - timestamp < duration;
  };

  hasFreshEnoughLocation = (freshnessMs: number): boolean => {
    const cachedPosition = this.state.currentPosition.coords;

    return (
      Boolean(cachedPosition) &&
      this.isWithinDuration(this.state.currentPosition.timestamp, freshnessMs)
    );
  };

  /**
   * Anytime we need the current position, we should go through this method.
   * This calls into the stubbed plugin as needed, so this works for web, embed, or standalone.
   */
  getCurrentPosition = async (
    positionOptions?: PositionOptions,
    syncLocationToBackend = true,
    freshnessMs = 0,
  ): Promise<CachedPosition | undefined> => {
    // If a developer wants location but is ok with a cached value, return immediately.
    // If freshnessMs is not passed, assume we want the location now.
    if (freshnessMs && this.hasFreshEnoughLocation(freshnessMs)) {
      return this.state.currentPosition;
    }

    try {
      const position = await Geolocation.getCurrentPosition(positionOptions);

      const currentPosition = {
        timestamp: position.timestamp || Date.now(),
        coords: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        },
      };

      this.setState({ currentPosition });

      if (syncLocationToBackend) {
        this.pingLocationThrottled(currentPosition.coords);
      }

      return currentPosition;
    } catch (error) {
      return undefined;
    }
  };

  pingLocation = async (location: {
    latitude: number;
    longitude: number;
  }): Promise<LocationHeartbeatMutation$data["locationHeartbeat"]> => {
    return LocationHeartbeatMutation.commit(environment, location);
  };

  // eslint-disable-next-line react/sort-comp
  pingLocationThrottled = _.throttle(this.pingLocation, THROTTLE_MS);

  pollPosition = async (): Promise<void> => {
    await this.getCurrentPosition(DEFAULT_WATCH_POSITION_OPTIONS, false);

    // Trigger an update for the current position.
    this.watchPositionCallbacksRegistered.forEach(
      (valueCallback: WatchCleanupFn, keyCallback: WatchCallbackFn) => {
        keyCallback(this.state.currentPosition);
      },
    );
  };

  startWatchingPosition = (): void => {
    this.setState({ isWatchingPosition: true });
  };

  /**
   * Stops watching the current position.
   */
  stopWatchingPosition = async (): Promise<void> => {
    this.setState({ isWatchingPosition: false });
    this.watchPositionCallbacksRegistered.clear();
  };

  /**
   * Starts a short polling interval to watch the current position. On each successful
   * location update, the provided callback will be invoked.
   *
   * This method can be called multiple times with different callbacks, and will only
   * start the polling interval once. Once all callbacks have been removed, the polling
   * interval will be stopped.
   *
   * Notes: This is not implemented using Geolocation.watchPosition for 2 reasons:
   * 1. Mainly since the location from watchLocation isn't as accurate. Idling in the same spot
   *    seems to jump around.
   * 2. We use watchPosition as part of the iOS getCurrentPosition implementation, which seems to
   *    somehow impact the other calls to watchPosition when it clears the watcher.
   *
   * Returns a function that can should be called to stop watching the position.
   */
  watchPosition = (callback: WatchCallbackFn): WatchCleanupFn => {
    if (this.watchPositionCallbacksRegistered.get(callback)) {
      // eslint-disable-next-line no-console
      console.warn("Callback already registered!");
      return this.watchPositionCallbacksRegistered.get(callback);
    }

    const cleanupCallback = (): void => {
      // Remove the callback from the set of callbacks to invoke.
      this.watchPositionCallbacksRegistered.delete(callback);

      // If there is nothing watching the position, clear the watcher.
      if (this.watchPositionCallbacksRegistered.size === 0) {
        this.stopWatchingPosition();
      }
    };

    // Add the callback to the set of callbacks to invoke when the position changes.
    this.watchPositionCallbacksRegistered.set(callback, cleanupCallback);

    if (!this.state.isWatchingPosition) {
      this.startWatchingPosition();
    }

    return cleanupCallback;
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <CurrentLocationContext.Provider
        value={{
          currentPosition: this.state.currentPosition,
          getCurrentPosition: this.getCurrentPosition,
          watchPosition: this.watchPosition,
          pingLocation: this.pingLocation,
        }}
      >
        {this.state.isWatchingPosition && (
          <PollingRefetcher pollingFn={this.pollPosition} intervalTimeMs={POLL_WATCH_POSITION_MS} />
        )}
        {children}
      </CurrentLocationContext.Provider>
    );
  };
}

export const CurrentLocationConsumer = CurrentLocationContext.Consumer;
