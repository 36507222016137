/* eslint-disable @typescript-eslint/no-explicit-any */
type HandlerFunction = (...args: any[]) => void;

export default class SimpleEventEmitter<E extends string> {
  subscriptions: Map<string, Map<HandlerFunction, HandlerFunction>>;

  constructor() {
    this.subscriptions = new Map();
  }

  on = (eventName: E, callback: HandlerFunction): void => {
    // If we have never created a Map for this key, create one
    if (!this.subscriptions.get(eventName)) {
      this.subscriptions.set(eventName, new Map());
    }

    const keySpecificHandlers = this.subscriptions.get(eventName);

    if (keySpecificHandlers) {
      keySpecificHandlers.set(callback, callback);
    }
  };

  off = (eventName: E, callback: HandlerFunction): void => {
    if (this.subscriptions.get(eventName)) {
      const keySpecificHandlers = this.subscriptions.get(eventName);

      if (keySpecificHandlers) {
        keySpecificHandlers.delete(callback);
      }
    }
  };

  dispatch = (eventName: E, ...args: any[]): void => {
    // Invoke handlers now for the key!
    const callbacksForEvent = this.subscriptions.get(eventName);

    if (callbacksForEvent) {
      const keySpecificHandlers = callbacksForEvent;

      if (keySpecificHandlers) {
        keySpecificHandlers.forEach((handler: HandlerFunction) => {
          handler(...args);
        });
      }
    }
  };

  destroy = (): void => {
    this.subscriptions = new Map();
  };
}
