import { config } from "react-spring";

/**
 * A lot of the animations across the app use react-spring and spring based animations.
 * This applies to some fade ins, UIModal, and UINav slide transitions.
 *
 * Changing the spring used can change the timing and feel of each animation. This file
 * aims to help coordinate animations we use.
 *
 * Helpful link to visualize springs: https://react-spring-visualizer.com
 */
export const DEFAULT_SPRING = config.default;

// Slide animation for chat slide in/out.
export const SLIDE_ANIMATION_FOR_CHAT = {
  ...DEFAULT_SPRING,
  tension: 200,
  friction: 26.0,
  clamp: true,
};

// Slightly increased duration estimate of animation spring for chat slide in/out.
export const APPROX_TIME_FOR_SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT_MS = 650;
export const SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT = {
  ...SLIDE_ANIMATION_FOR_CHAT,
  tension: 180,
};
