import React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { ActivityCenterViewProvider } from "pages/MapAndActivities/providers/ActivityCenterViewProvider";

import ActivityCenterPageLayout, {
  ExposedProps as LayoutExposedProps,
} from "pages/MapAndActivities/components/ActivityCenterPageLayout";

import { ActivityCenterView_me$data as ActivityCenterViewMe } from "__generated__/ActivityCenterView_me.graphql";

type Props = {
  me: ActivityCenterViewMe;
  children: React.ReactNode;
};

type State = {};

/**
 * The root UI Nav view for the Activity Center.
 *
 * NOTE: Don't confuse this with ActivityCenterViewportProvider, which is the viewport where
 * the main activity center surface/view is rendered. Maybe we should rename one of em?
 */
class ActivityCenterView extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <ActivityCenterPageLayout me={this.props.me}>
        {(viewLayoutControls: LayoutExposedProps) => (
          <ActivityCenterViewProvider me={this.props.me} viewLayoutControls={viewLayoutControls}>
            {this.props.children}
          </ActivityCenterViewProvider>
        )}
      </ActivityCenterPageLayout>
    );
  };
}

const FragmentContainer = createFragmentContainer(ActivityCenterView, {
  me: graphql`
    fragment ActivityCenterView_me on User {
      ...ActivityCenterPageLayout_me
      ...ActivityCenterViewProvider_me
    }
  `,
});

export default FragmentContainer;
