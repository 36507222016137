/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from "uuid";

import { delay } from "common/utils/delay";

const DEFAULT_TIMEOUT_MS = 30000;

type DeferredLikeObject = {
  rpcName: string;
  validator: RPCValidator;
  resolve: (data: any) => void;
  reject: (error: any) => void;
};

// Promise Map
// This is where we store async calls to the interop bridge to be resolved later.
// The interop developer needs to either resolve or reject the appropriate call within
// a timeout timeframe.
const PROMISE_MAP: { [key: string]: DeferredLikeObject } = {};

type RPCValidator = (data: any) => boolean;
type InteropRPCCall = (promiseId: string) => void;

// Always assume valid if not provided.
const defaultValidator = () => true;

export function invokeRPC(
  rpcName: string,
  interopRPCCall: InteropRPCCall,
  rpcValidator: RPCValidator = defaultValidator,
): Promise<any> {
  const promiseId = `campfire-async-interop-${uuidv4()}`;

  // Create the promise, save the resolve and reject methods (like a deferred lol),
  // and invoke the interop rpc call.
  const promise = new Promise((resolve, reject) => {
    interopRPCCall(promiseId);
    PROMISE_MAP[promiseId] = {
      rpcName,
      resolve,
      reject,
      validator: rpcValidator,
    };
  });

  // Wait for a max of timeout time before acting.
  return Promise.race([
    delay(
      DEFAULT_TIMEOUT_MS,
      `Interop failed to respond within ${DEFAULT_TIMEOUT_MS}ms. ${rpcName}`,
    ),
    promise,
  ]).finally(() => {
    delete PROMISE_MAP[promiseId];
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resolveInteropPromise = (promiseId: string, data: any): boolean => {
  // Check that we have the promise and that the data passes the validator before resolving.
  if (PROMISE_MAP[promiseId]) {
    if (PROMISE_MAP[promiseId].validator(data)) {
      PROMISE_MAP[promiseId].resolve(data);

      return true;
    }

    const { rpcName } = PROMISE_MAP[promiseId];
    const errorMessage = `Resolved data did not pass validation for ${rpcName}!
     Please ensure that you are providing sufficient data for the 
     Capacitor Plugin you are stubbing.`;

    const error = new TypeError(errorMessage);

    PROMISE_MAP[promiseId].reject(error);
  }

  return false;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rejectInteropPromise = (promiseId: string, error: any): boolean => {
  if (PROMISE_MAP[promiseId]) {
    PROMISE_MAP[promiseId].reject(error);

    return true;
  }

  return false;
};
