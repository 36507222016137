import classnames from "classnames";
import React, { FunctionComponent, SyntheticEvent, useEffect, useRef } from "react";
import { animated, useTransition, config, to } from "react-spring";

import Anchor from "common/components/Anchor";
import Backdrop from "common/components/Backdrop";
import DelayedUnmount from "common/components/DelayedUnmount";
import IonAppPortal from "common/components/IonAppPortal";
import { getTranslateYPositionPx } from "common/components/UIModal/computePositions";
import UIResizeObserver from "common/components/UIResizeObserver";

import styles from "./styles.scss";

export type PositionPercentages = {
  open: number;
  closed: number;
};

enum AnimationState {
  OPENING = "opening",
  OPEN = "open",
  CLOSING = "closing",
  CLOSED = "closed",
}

type RequiredProps = {
  // Denotes if the modal should be visible
  isOpen: boolean;

  // Will update the isOpen prop from the parent to close the modal, required and controlled by the parent
  close: () => void;

  // The parent element where the UIModal is rendered inside of. This element should represent the
  // area that the modal will anchor inside of.
  parentElement: HTMLElement;

  // Configures if the backdrop is rendered.
  showBackdrop?: boolean;

  // Configures if clicking on backdrop will dismiss modal.
  backdropDismiss?: boolean;

  // Callback invoked when the has been modal is about to present.
  onWillPresent?: () => void;

  // Callback invoked after the dialog has finished the open animation and is presenting
  onDidPresent?: () => void;

  // Callback invoked when the has been modal dismissed, meaning the animation has finished or the component has unmounted
  onDidDismiss?: () => void;

  // Callback invoked before the dialog is about to be dismissed.
  onWillDismiss?: () => void;

  // Determines if the animations will be used or not.
  isAnimated?: boolean;

  children: React.ReactNode;

  // Determines the position that the modal will animate to and from
  // If positionPercentages is not provided set defaults to be for fullscreen mode
  positionPercentages?: PositionPercentages;

  // Enables super custom transform styles to be applied instead of the default.
  // Currently only used to position floating-center dialogs.
  positionInterpolation?: (value: number) => string;

  // CSS styles that can be provided to override the default fullscreen ones
  modalContentClassName?: string;
};

export type Props = Omit<RequiredProps, "parentElement">;

const stopEventFromBubblingOut = (event: SyntheticEvent): void => {
  event.stopPropagation();
};

const DEFAULT_INTERP_FN_TRANSLATE_Y = (value: number) => `translateY(${value}px)`;

// Defines a readable set of common positions we use for the UIModal.
// Values define percentages of the container the dialog is anchored to.
// Open: When dialog is open, how far from the bottom of the container is the bottom of the
//       dialog content?
// Closed: When dialog is closed, wow far from the bottom of the container is the bottom of the
//         dialog content? Negative values go farther down.
export const PRECONFIGURED_MODAL_POSITIONS: Record<string, PositionPercentages> = {
  BOTTOM_TO_OFFSET_10_PERCENT: {
    open: 0.1,
    closed: -1.1,
  },
  BOTTOM_TO_CENTER: {
    open: 0.5,
    closed: -1.5,
  },
  BOTTOM_TO_TOP: {
    open: 0,
    closed: -1,
  },
};

/**
 * Core Modal component powering the UIDialog and UIPane.
 *
 * Conceptually, all this component does is animate an element from a starting position to
 * an ending position. The calling component controls the container that is being animated,
 * which may range from a small dialog or a fullscreen pane.
 *
 * NOTE: react-spring v9.6.1 is incompatible with this implementation for some reason.
 *       Need to investigate more why.
 */
const UIModalCore: FunctionComponent<RequiredProps> = (
  props: RequiredProps,
): React.ReactElement => {
  const {
    isOpen,
    showBackdrop = true,
    backdropDismiss,
    children,
    isAnimated = true,
    parentElement,
    positionPercentages = PRECONFIGURED_MODAL_POSITIONS.BOTTOM_TO_TOP,
    modalContentClassName,
    close,
    onWillPresent,
    onDidPresent,
    onWillDismiss,
    onDidDismiss,
  } = props;
  const shouldShowBackdrop = isOpen && !!showBackdrop;
  const { open, closed } = positionPercentages;

  // The modal will animate to the starting position, so this value ref will be updated
  // when the animation begins even if the component is initially passed isOpen = true.
  const animationStateRef = useRef<AnimationState>(AnimationState.CLOSING);

  // For Gestures later on
  // const isGesturingRef = useRef<boolean>(false);

  const handleModalWillPresent = () => {
    const shouldAnnounce = animationStateRef.current !== AnimationState.OPENING;

    if (shouldAnnounce && onWillPresent) {
      onWillPresent();
    }

    animationStateRef.current = AnimationState.OPENING;
  };

  const handleModalDidPresent = () => {
    const shouldAnnounce = animationStateRef.current !== AnimationState.OPEN;

    if (shouldAnnounce && onDidPresent) {
      onDidPresent();
    }

    animationStateRef.current = AnimationState.OPEN;
  };

  const handleModalWillDismiss = () => {
    const shouldAnnounce = animationStateRef.current !== AnimationState.CLOSING;

    if (shouldAnnounce && onWillDismiss) {
      onWillDismiss();
    }

    animationStateRef.current = AnimationState.CLOSING;
  };

  const handleModalDidDismiss = () => {
    if (onDidDismiss) {
      onDidDismiss();
    }

    animationStateRef.current = AnimationState.CLOSED;
  };

  // Negative translateY values shift up. Positive shift downwards.
  // However, the preconfigured modal positions are defined kinda inverted, where we read a percentage
  // from the bottom, where 0.1 means 10% up from the bottom, -1.1 means -110% down from the bottom.
  // Hence, we need to negate it here. So a value of 0.1 => -50px for example. The -50px
  // in translateY shifts up, which is what we want in the open state.
  const openPx = getTranslateYPositionPx(-open, parentElement);
  const closedPx = getTranslateYPositionPx(-closed, parentElement);

  // Use the api for gestures
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transition, api] = useTransition(isOpen, () => ({
    config: config.default,
    // This is where the modal starts. Usually at the bottom.
    initial: { translateY: closedPx },
    // This is where the modal ends up when open.
    enter: {
      translateY: openPx,
      onRest: () => {
        // For Gestures later on
        // if (isGesturingRef.current === true) {
        //   return;
        // }
        handleModalDidPresent();
      },
    },
    // This is where the modal should finish when closed.
    leave: {
      translateY: closedPx,
      onRest: () => {
        // if (isGesturingRef.current === true) {
        //   return;
        // }
      },
    },
  }));

  const handleBackdropClick = (): void => {
    const isBackdropDismissable = animationStateRef.current === "open";

    if (isBackdropDismissable && shouldShowBackdrop && backdropDismiss && close) {
      close();
    }
  };

  const repositionModalOnParentResize = () => {
    if (isOpen) {
      // Reposition the modal immediately if it's already open.
      const positionPx = getTranslateYPositionPx(-open, parentElement);

      api.start({ translateY: positionPx, immediate: true });
    } else {
      // If the modal is in the process of closing, but the parent element gets resized,
      // update the position so that the modal visually moves to the final position.
      const positionPx = getTranslateYPositionPx(-closed, parentElement);

      api.start({ translateY: positionPx });
    }
  };

  /**
   * Handles the immediate open and close events so we can announce them.
   * When gestures are implemented, sadly we can't use onStart since it isn't being invoked
   * unless the spring is totally at rest, so if a user gestures and taps the backdrop fast,
   * only didDismiss fires. (react-spring: v9.4.5)
   */
  useEffect(() => {
    // if (isGesturingRef.current === true) {
    //   return;
    // }

    if (isOpen) {
      handleModalWillPresent();
    } else {
      handleModalWillDismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    return () => {
      handleModalDidDismiss();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UIResizeObserver
        elementToWatch={props.parentElement}
        onResize={repositionModalOnParentResize}
      />
      <Backdrop
        isOpen={shouldShowBackdrop}
        cssClass={styles.backdropCtn}
        onClick={handleBackdropClick}
      />
      {!isAnimated ? (
        <div
          role="presentation"
          className={classnames(modalContentClassName, styles.childCtn, {
            // If we aren't provided override styles use default ones to make modal fullscreen
            [styles.childCtnFullscreen]: !modalContentClassName,
          })}
          // [CAMP-1566] The following events are not allowed to bubble out of this boundary element
          // since React and React Portals reissue events based on the React tree, and not the DOM tree.
          // This essentially scopes events within IonModal's to be contained, which
          // is nearly always what you want.
          // https://reactjs.org/docs/portals.html#event-bubbling-through-portals
          onClick={stopEventFromBubblingOut}
          onMouseDown={stopEventFromBubblingOut}
          onTouchStart={stopEventFromBubblingOut}
        >
          {children}
        </div>
      ) : (
        transition((transitionStyles, item) => {
          if (!item) {
            return null;
          }

          // Default interpolation fn is only operating with translateY
          const customInterpFn = (value: number) => props.positionInterpolation?.(value);
          const interpolationFn = props.positionInterpolation
            ? customInterpFn
            : DEFAULT_INTERP_FN_TRANSLATE_Y;

          return (
            <animated.div
              className={classnames(modalContentClassName, styles.childCtn, {
                // If we aren't provided override styles use default ones to make modal fullscreen
                [styles.childCtnFullscreen]: !modalContentClassName,
              })}
              style={{
                transform: to(transitionStyles.translateY, interpolationFn),
              }}
              // [CAMP-1566] The following events are not allowed to bubble out of this boundary element
              // since React and React Portals reissue events based on the React tree, and not the DOM tree.
              // This essentially scopes events within IonModal's to be contained, which
              // is nearly always what you want.g
              // https://reactjs.org/docs/portals.html#event-bubbling-through-portals
              onClick={stopEventFromBubblingOut}
              onMouseDown={stopEventFromBubblingOut}
              onTouchStart={stopEventFromBubblingOut}
            >
              {children}
            </animated.div>
          );
        })
      )}
    </>
  );
};

const UIModal: FunctionComponent<Props> = (props: Props): React.ReactElement => (
  <DelayedUnmount isOpen={props.isOpen}>
    <IonAppPortal>
      <Anchor className={styles.root}>
        {(anchorEl) => <UIModalCore {...props} parentElement={anchorEl} />}
      </Anchor>
    </IonAppPortal>
  </DelayedUnmount>
);

export const UIModalRelative: FunctionComponent<Props> = (props: Props): React.ReactElement => (
  <DelayedUnmount isOpen={props.isOpen}>
    <Anchor className={styles.root}>
      {(anchorEl) => <UIModalCore {...props} parentElement={anchorEl} />}
    </Anchor>
  </DelayedUnmount>
);

export default UIModal;
