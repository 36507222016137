// Define color theme adjustments in a seperate file
import darkStyles from "./dark.scss";
import pgoStyles from "./pgo.scss";

export type ThemeName = "default" | "light" | "dark" | "pgo";

export type ThemeConfig = {
  isDefault?: boolean;
  bodyClassname?: string;
};

// A set of all the preconfigured themes we support.
const THEMES: Record<ThemeName, ThemeConfig> = {
  default: {
    isDefault: true,
  },
  // This is the default
  light: {
    isDefault: true,
  },
  dark: {
    bodyClassname: darkStyles.dark,
  },
  pgo: {
    bodyClassname: pgoStyles.pgo,
  },
};

export default THEMES;
