import _ from "lodash";
import { commitMutation, DeclarativeMutationConfig, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { UnbanUserMutation$data } from "__generated__/UnbanUserMutation.graphql";

type ReturnType = UnbanUserMutation$data["unbanUser"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  clubId: string;
  userId: string;
};

const mutation = graphql`
  mutation UnbanUserMutation($input: UnbanUserInput!) {
    unbanUser(input: $input) {
      user {
        id
      }
      success
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "unbanUser.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "unbanUser.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "unbanUser"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Find a club with this id, look for the connection named as such
    // located at the path to connection. The entity to delete can be identified using
    // the field on this entity "id".
    const deleteUpdaterConfig: DeclarativeMutationConfig = {
      type: "RANGE_DELETE",
      parentID: input.clubId,
      connectionKeys: [
        {
          key: "BannedClubMembersConnectionLoader_bannedUsers",
        },
      ],
      pathToConnection: ["club", "members"],
      deletedIDFieldName: ["user"],
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          clubId: input.clubId,
          userId: input.userId,
        },
      },
      configs: [deleteUpdaterConfig],
      onCompleted,
      onError,
    });
  });
};

export default { commit };
