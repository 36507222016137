/**
 * @generated SignedSource<<188513f74b53df657d08e0c2fdff5467>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateIsIncludeAsFriendSuggestionAllowedInput = {
  isAllowed: boolean;
};
export type UpdateIsIncludeAsFriendSuggestionAllowedMutation$variables = {
  input: UpdateIsIncludeAsFriendSuggestionAllowedInput;
};
export type UpdateIsIncludeAsFriendSuggestionAllowedMutation$data = {
  readonly updateIsIncludeAsFriendSuggestionAllowed: {
    readonly me: {
      readonly " $fragmentSpreads": FragmentRefs<"UserFriendSuggestionSettings_me">;
    };
    readonly success: boolean;
  };
};
export type UpdateIsIncludeAsFriendSuggestionAllowedMutation = {
  response: UpdateIsIncludeAsFriendSuggestionAllowedMutation$data;
  variables: UpdateIsIncludeAsFriendSuggestionAllowedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateIsIncludeAsFriendSuggestionAllowedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateIsIncludeAsFriendSuggestionAllowedResponse",
        "kind": "LinkedField",
        "name": "updateIsIncludeAsFriendSuggestionAllowed",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserFriendSuggestionSettings_me"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateIsIncludeAsFriendSuggestionAllowedMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateIsIncludeAsFriendSuggestionAllowedResponse",
        "kind": "LinkedField",
        "name": "updateIsIncludeAsFriendSuggestionAllowed",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isIncludeAsFriendSuggestionAllowed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "333358e40280aa0632557d6446fcbb76",
    "id": null,
    "metadata": {},
    "name": "UpdateIsIncludeAsFriendSuggestionAllowedMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateIsIncludeAsFriendSuggestionAllowedMutation(\n  $input: UpdateIsIncludeAsFriendSuggestionAllowedInput!\n) {\n  updateIsIncludeAsFriendSuggestionAllowed(input: $input) {\n    success\n    me {\n      ...UserFriendSuggestionSettings_me\n      id\n    }\n  }\n}\n\nfragment UserFriendSuggestionSettings_me on User {\n  isIncludeAsFriendSuggestionAllowed\n}\n"
  }
};
})();

(node as any).hash = "5096fd5fd28a1a597a7a601c7d8de7be";

export default node;
