import isDev from "constants/isDev";

const DELIMITER = ":&&:";

// eslint-disable-next-line import/prefer-default-export
export const validateDynamicSlideChanges = (prevHash: string, newHash: string): void => {
  if (!isDev) {
    return;
  }

  // Check that relative position of slides is unchanged. If we see that
  // a slide has slipped out of its position since a developer popped the slide to the
  // front or something, throw an error to denote to the developer to TRY to keep
  // order consistent.
  const prevSlideIds = prevHash.split(DELIMITER).filter(Boolean);
  const newSlideIds = newHash.split(DELIMITER).filter(Boolean);

  newSlideIds.forEach((slideId: string, idx) => {
    const existsBefore = prevSlideIds.includes(slideId);
    const prevIndex = prevSlideIds.indexOf(slideId);
    const existsAndPositionChanged = existsBefore && prevIndex !== idx;
    const dneButInAUsedPosition = !existsBefore && idx < prevSlideIds.length;

    if (dneButInAUsedPosition) {
      // eslint-disable-next-line no-console
      console.error(
        `UINav Dynamic Slide Definition Order Error: Detected new slide added in a position potentially already occupied by another slide. This can lead to weirdness in the UI. Try to keep the position of new slides to come at the end when using dynamic slides.`,
        `\nPrevious Slide ID's: ${prevSlideIds}`,
        `\nNew Slide ID's: ${newSlideIds}`,
      );
    } else if (existsAndPositionChanged) {
      // eslint-disable-next-line no-console
      console.error(
        `UINav Dynamic Slide Definition Order Error: Detected the order of UINavSlide's has changed. Previous slides exist in new slide definitions, but their positions have changed. This can lead to weirdness in the UI. Try to keep the position of each slide in the same spot when using dynamic slides.`,
        `\nPrevious Slide ID's: ${prevSlideIds}`,
        `\nNew Slide ID's: ${newSlideIds}`,
      );
    }
  });
};
