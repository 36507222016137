import React from "react";

import logException from "common/analytics/exceptions";

import UIButton, {
  Props as UIButtonProps,
  IonButtonMouseEventType,
} from "common/components/UIButton";
import UISpinner from "common/components/UISpinner";

import styles from "./styles.scss";

export type Props = UIButtonProps & {
  children: React.ReactNode;
  btnContentClassName?: string;
  className?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (event: IonButtonMouseEventType) => Promise<any> | any;

  // Unfortunately, since ion-button uses slots to position stuff, to maintain
  // some semblance of backwards compatibility, we need to expose the slots to the developer.
  // TODO: Perhaps we need to revisit how we render the spinner so that slots can be honored?
  uiButtonSlots?: React.ReactNode;
};

type State = {
  isProcessing: boolean;
};

export default class UIAsyncButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isProcessing: false,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick = async (event: IonButtonMouseEventType): Promise<any> => {
    if (this.state.isProcessing) {
      return;
    }

    this.setState({ isProcessing: true });

    try {
      await this.props.onClick(event);
    } catch (error) {
      logException("onClick", "onClick", "UIAsyncButton", error);
    } finally {
      this.setState({ isProcessing: false });
    }
  };

  onClickAsync = (event: IonButtonMouseEventType): void => {
    this.onClick(event);
  };

  render = (): React.ReactNode => {
    const { children, disabled, btnContentClassName, uiButtonSlots, ...remainingProps } =
      this.props;
    const { isProcessing } = this.state;

    return (
      <UIButton
        {...remainingProps}
        onClick={this.onClickAsync}
        disabled={this.state.isProcessing || disabled}
      >
        {uiButtonSlots}
        <div
          className={btnContentClassName}
          style={{ visibility: isProcessing ? "hidden" : "visible" }}
        >
          {children}
        </div>
        {isProcessing && <UISpinner className={styles.spinner} size={24} />}
      </UIButton>
    );
  };
}
