import React from "react";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

import { isStandalone } from "common/capacitor/helpers";
import { FEATURE_FLAGS } from "constants/featureFlags";

import AppHydrationRefresher from "boot-loader/components/AppHydrationRefresher";
import AutoUpdater from "boot-loader/components/AutoUpdater";
import LocationPinger from "boot-loader/components/LocationPinger";

export type Props = WithFeatureFlagProps & {};

type State = {};

/**
 * These are ethereal components that just are running some processes during the runtime
 * of the app. They shouldn't render any in flow UI, but have access to the Component tree so that they
 * can render dialogs or other things as necessary.
 */
class AppGlobalRunningProcesses extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const autoUpdaterEnabled =
      isStandalone && this.props.hasFeatureFlag(FEATURE_FLAGS.APP_AUTO_UPDATE);

    return (
      <>
        {autoUpdaterEnabled && <AutoUpdater />}
        <AppHydrationRefresher />
        <LocationPinger />
      </>
    );
  };
}

const FeatureFlagConnected = withFeatureFlag(AppGlobalRunningProcesses);

export default FeatureFlagConnected;
