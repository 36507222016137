import { RouteComponentProps } from "react-router-dom";

import { NOTIFICATIONS_ROUTE } from "constants/routes";

export const getUrlForNotificationsTab = (): string => {
  return `/${NOTIFICATIONS_ROUTE}`;
};

export default (routeComponentProps: RouteComponentProps): void => {
  routeComponentProps.history.push({
    pathname: getUrlForNotificationsTab(),
  });
};
