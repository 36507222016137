/* eslint-disable no-underscore-dangle */
import { CameraPlugin } from "@capacitor/camera";
import { ClipboardPlugin } from "@capacitor/clipboard";
import { FilesystemPlugin } from "@capacitor/filesystem";
import { GeolocationPlugin } from "@capacitor/geolocation";

import { CampfirePushNotificationsPlugin } from "common/capacitor/plugins/campfire-push-notifications";
import { invokeRPC } from "common/utils/webInterop/resolver";
import { EmbedUploadInterface } from "types/embedPluginInterface/embedUpload";
import { RegionalChatInterface } from "types/embedPluginInterface/regionalChat";

import Camera from "./camera";
import Clipboard from "./clipboard";
import EmbedUpload from "./embedUpload";
import Filesystem from "./fileSystem";
import Geolocation from "./geolocation";
import PushNotifications from "./pushNotifications";
import RegionalChat from "./regionalChat";

type InteropSupportedPluginName = keyof CampfireInterop.Plugins;

// WEB EMBEDDABLE CAPACITOR PLUGINS
// ================================
// HOW TO ADD A NEW WEB EMBEDDED INTEROP PLUGIN.
// 1. Add the interop exposed method to CampfireInterop.Plugins in campfire.interop.d.ts.
//    - This is the interface that unity, Pgo, ingress apps will need to implement/provide
//      as the JS will invoke this method from the provided interop.
//    - Specify if the method is async or not async (most likely its async).
//    - Make sure you are stubbing a plugin and method from a REAL Capacitor Plugin.
//
// 2. Add the method to the StubbedPlugins type below. This is the interface that the JS will
//    call into to mask the original Capacitor plugin method.
//    - The type should be the same as the plugin method, so DO NOT DEFINE YOUR OWN!
//
// 3. Add the plugin and method to the specified directory in the webInterop/plugins folder.
//    - So if we were adding a plugin stub for Capacitor.Plugins.Geolocation.getCurrentPosition,
//      we would want to add a directory called "geolocation" and then a file inside of that
//      called getCurrentPosition.ts.
//    - You may need to also create the index.ts file that will export this Stubbed
//      Geolocation Plugin.
//
// 4. Wrap the original method with either invokeRemoteAsync or invokeRemote (async or sync).
//    - You need to pass to that method the PluginName and MethodName.
//
// 5. Add the action name to unity.ts, "Geolocation.getCurrentPosition"
//    - You also need to add this action to the preconfigured plugin interop methods.
//
// 6. Now in the JS code, when you want to getCurrentPosition, import the plugin instead
//    from the stubbed Plugins here.
type StubbedPlugins = {
  Geolocation: {
    checkPermissions: GeolocationPlugin["checkPermissions"];
    getCurrentPosition: GeolocationPlugin["getCurrentPosition"];
  };
  Camera: {
    checkPermissions: CameraPlugin["checkPermissions"];
    requestPermissions: CameraPlugin["requestPermissions"];
  };
  Clipboard: {
    write: ClipboardPlugin["write"];
  };
  PushNotifications: {
    checkPermissions: CampfirePushNotificationsPlugin["checkPermissions"];
    requestPermissions: CampfirePushNotificationsPlugin["requestPermissions"];
  };
  Filesystem: {
    getUri: FilesystemPlugin["getUri"];
    readFile: FilesystemPlugin["readFile"];
    stat: FilesystemPlugin["stat"];
  };

  // Custom Capacitor Plugins
  RegionalChat: {
    requestCurrentRegion: RegionalChatInterface["requestCurrentRegion"];
  };
  EmbedUpload: {
    initiateUpload: EmbedUploadInterface["initiateUpload"];
    getFilePreviewUrl: EmbedUploadInterface["getFilePreviewUrl"];
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function invokeRemote<T extends Function, K extends InteropSupportedPluginName>(
  fn: T,
  pluginName: K,
  functionName: keyof CampfireInterop.Plugins[K],
): T {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (...args): void => {
    // Check to see if something has implemented a supported method for the App Interop.
    // If so, invoke it and return the value. It should return a boolean.
    if (!!window.__CAMPFIRE_PLUGIN_INTEROP__ && window.__CAMPFIRE_PLUGIN_INTEROP__[pluginName]) {
      const plugin = window.__CAMPFIRE_PLUGIN_INTEROP__[pluginName] as CampfireInterop.Plugins[K];

      // If we cannot find a plugin or method, we need to fall through to the default behavior.
      if (plugin) {
        const method = plugin[functionName];

        if (method && typeof method === "function") {
          method(...args);

          return;
        }
      }
    }

    fn(...args);
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function invokeRemoteAsync<T extends Function, K extends InteropSupportedPluginName>(
  fn: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: (data: any) => boolean,
  pluginName: InteropSupportedPluginName,
  functionName: keyof CampfireInterop.Plugins[K],
): T {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (...args) => {
    // Check to see if something has implemented a supported method for the App Interop.
    // If so, invoke it and return the value. It should return a boolean.
    if (!!window.__CAMPFIRE_PLUGIN_INTEROP__ && window.__CAMPFIRE_PLUGIN_INTEROP__[pluginName]) {
      const plugin = window.__CAMPFIRE_PLUGIN_INTEROP__[pluginName] as CampfireInterop.Plugins[K];

      if (plugin) {
        const interopPluginMethod = plugin[functionName];

        if (interopPluginMethod && typeof interopPluginMethod === "function") {
          const rpcMethod = (promiseId: string) => {
            // Pass the interop plugin method the promiseId so the developer knows
            // what to resolve later, and then pass the full set of arguments that we would
            // have normally given our original method.
            interopPluginMethod(promiseId, ...args);
          };

          // Just to help debug.
          const rpcName = `${pluginName}.${functionName}`;

          return invokeRPC(rpcName, rpcMethod, validator);
        }
      }
    }

    return fn(...args);
  };
}

const Plugins: StubbedPlugins = {
  Geolocation,
  Camera,
  Clipboard,
  PushNotifications,
  RegionalChat,
  Filesystem,
  EmbedUpload,
};

export default Plugins;
