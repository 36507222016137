import React, { FunctionComponent } from "react";
import { config, useTransition, SpringConfig } from "react-spring";

type Props = {
  isOpen: boolean;
  // eslint-disable-next-line react/require-default-props
  springConfig?: SpringConfig;
  children: React.ReactNode;
};

/**
 * BASED OFF DEFAULT CONFIG FOR REACT SPRING!
 *
 * Delays the unmounting of the content.
 * This allows the content to perform a transition before being removed.
 */
const DelayedUnmount: FunctionComponent<Props> = (props: Props): React.ReactElement | null => {
  const { springConfig = config.default } = props;

  // We are totally just using react-spring to handle the transition of mount to unmount
  // so we are passing arbitrary style rules here. I just chose opacity since it doesn't
  // impact much. But honestly it shouldn't matter. React Spring just needs something.
  const transitions = useTransition(props.isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: springConfig,
  });

  return (
    <>
      {transitions((transitionStyles, item) => {
        if (!item) return null;

        return props.children;
      })}
    </>
  );
};

export default DelayedUnmount;
