export type ClubRoleMemberObj = {
  id: string;
  isAdmin: boolean;
  isCreator: boolean;
  isMember: boolean;
  isSuperAdmin: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const canAdministrateUser = (
  currentUser: ClubRoleMemberObj,
  targetUser: ClubRoleMemberObj,
): boolean => {
  // SuperAdmin has the ability to do everything
  if (currentUser.isSuperAdmin) {
    return true;
  }

  // The creator always has ability to interact with any user (other than himself)
  // For non creator admins, they can interact with any user other than the creator
  // and other admins.
  const currentUserIsCreator = currentUser.isCreator;
  const currentUserIsAnAdmin = currentUser.isAdmin;
  const targetIsSelf = targetUser.id === currentUser.id;
  const targetIsAnAdmin = targetUser.isAdmin;
  const targetIsCreator = targetUser.isCreator;
  const targetIsMember = targetUser.isMember;

  // You cannot act on this user if:
  // 1. The user is yourself.
  // 2. You are not an admin.
  // 3. The user is the creator.
  // 4. The user is not a member.
  if (targetIsSelf || !currentUserIsAnAdmin || targetIsCreator || !targetIsMember) {
    return false;
  }

  // At this point, we know the target is not ourself and that we have admin privileges.
  // Check if the current user is the creator. If so, we can administrate since the creator
  // is special.
  if (currentUserIsCreator) {
    return true;
  }

  // Admins cannot act on other admins.
  if (targetIsAnAdmin) {
    return false;
  }

  return true;
};
