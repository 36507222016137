import { BundleInfo, BundleStatus, CapacitorUpdater } from "@capgo/capacitor-updater";

/**
 * Verify that a bundle can be applied.
 */
export const isBundleOkToApply = (bundle: BundleInfo): boolean => {
  // FYI: "pending" means that the bundle is ready to apply, but not yet applied.
  //      "success" means that it has been applied, and likely means we can re-apply it as well.
  // https://docs.capgo.app/plugin/debugging#bundle-status
  const validStatuses: BundleStatus[] = ["pending", "success"];

  return validStatuses.includes(bundle.status);
};

/**
 * Gets a js bundle that already exists.
 */
export const getExistingBundleByVersion = async (jsVersion: string): Promise<BundleInfo | void> => {
  const allBundlesDownloaded = await CapacitorUpdater.list();
  const matchingBundle = allBundlesDownloaded.bundles.find(
    (bundle) => bundle.version === jsVersion,
  );
  const bundleIsReady = !!matchingBundle && isBundleOkToApply(matchingBundle);

  return bundleIsReady ? matchingBundle : undefined;
};

/**
 * Checks if a js bundle already exists.
 */
export const checkBundleAlreadyExistsByVersion = async (jsVersion: string): Promise<boolean> => {
  const existingBundle = await getExistingBundleByVersion(jsVersion);

  return Boolean(existingBundle);
};

/**
 * Check if a bundle for a given jsVersion is being downloaded currently.
 */
export const isBundleBeingDownloaded = async (jsVersion: string): Promise<boolean> => {
  const existingBundle = await getExistingBundleByVersion(jsVersion);

  return Boolean(existingBundle && existingBundle.status === "downloading");
};
