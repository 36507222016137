import environment from "common/relay/relay-env";
import { graphql } from "react-relay";
import { fetchQuery } from "relay-runtime";

import { checkSession_Query$data as CheckSessionQueryResponse } from "__generated__/checkSession_Query.graphql";

const CHECK_SESSION_QUERY = graphql`
  query checkSession_Query {
    me {
      id
      email
      username
      displayName
      hasSetNianticId
      shouldShowNux
      isSuperAdmin
      ageGroup
      featureFlags
      hasAcknowledgedReset
    }
  }
`;

export default async (): Promise<CheckSessionQueryResponse> => {
  const response = await fetchQuery(
    environment,
    CHECK_SESSION_QUERY,
    {},
    {
      networkCacheConfig: {
        force: true,
      },
    },
  ).toPromise();

  return response as CheckSessionQueryResponse;
};
