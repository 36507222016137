/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { PubnubConsumer, ExposedProps } from "providers/PubnubProvider";

export type WithPubnubProps = {
  pubnubProvider: {
    pubnubClient: ExposedProps["pubnubClient"];
    fetchMessagesFromHistory: ExposedProps["fetchMessagesFromHistory"];
    fetchMostRecentMessagesFromHistory: ExposedProps["fetchMostRecentMessagesFromHistory"];
    fetchMostRecentMessageActions: ExposedProps["fetchMostRecentMessageActions"];
    fetchMessageCounts: ExposedProps["fetchMessageCounts"];
  };
};

// eslint-disable-next-line max-len
export default <P extends WithPubnubProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithPubnubProps>): React.ReactElement => (
    <PubnubConsumer>
      {({
        pubnubClient,
        fetchMessagesFromHistory,
        fetchMostRecentMessagesFromHistory,
        fetchMostRecentMessageActions,
        fetchMessageCounts,
      }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          pubnubProvider={{
            pubnubClient,
            fetchMessagesFromHistory,
            fetchMostRecentMessagesFromHistory,
            fetchMostRecentMessageActions,
            fetchMessageCounts,
          }}
          {...props}
        />
      )}
    </PubnubConsumer>
  );
};
