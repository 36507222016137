import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import TEST_IDS from "constants/testIds";
import { NIANTIC_PRIVACY_URL } from "constants/urls";

import UIButton from "common/components/UIButton";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  onClickOk: () => void;
};

type State = {};

class SignupPrivacy extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIText variant="h2" color="dark" weight="extraBold">
          {this.props.t("PLEASE_REVIEW_THE_NIANTIC_PRIVACY_POLICY")}
        </UIText>

        <UISpacer h={30} />

        <UILink className={styles.underline} href={NIANTIC_PRIVACY_URL} openInNewTab>
          <UIText variant="body1" weight="bold">
            {this.props.t("VIEW_PRIVACY_POLICY")}
          </UIText>
        </UILink>

        <UISpacer h={30} />

        <UIButton
          color="primary"
          expand="block"
          onClick={this.props.onClickOk}
          dataTestId={TEST_IDS.PrivacyPolicyOkBtnId}
        >
          <UIText variant="body1" weight="bold">
            {this.props.t("OK")}
          </UIText>
        </UIButton>
      </div>
    );
  };
}

export default withTranslation()(SignupPrivacy);
