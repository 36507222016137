import Graph from "graphology";
import { allSimplePaths } from "graphology-simple-path";
import { uniqBy } from "lodash";

import { Flow } from "../types/flows";

/**
 * Checks for cycles in flows which have prerequisites on other flows.
 * This helps us detect situations in our configuration of flows where we may say,
 * "I want to show Flow A, but Flow A requires Flow B, but Flow B requires Flow A". o_O
 */
const hasCycleInFlows = (flows: Flow[]): boolean => {
  const graph = new Graph();

  // Add each flow as a node in a graph
  flows.forEach((flow) => {
    graph.addNode(flow.name);
  });

  // Add edges between nodes
  flows.forEach((flow) => {
    const requiredFlows = flow.requires || [];

    requiredFlows.forEach((requiredFlowName) => {
      graph.addEdge(flow.name, requiredFlowName);
    });
  });

  // Now check if any node has a cycle
  return flows.some((flow) => {
    // We detect a cycle if there exists a path from a node to itself.
    const cycles = allSimplePaths(graph, flow.name, flow.name);

    return cycles.length;
  });
};

/**
 * Validates that flows passed are properly configured.
 */
const validateFlows = (flows: Flow[]): boolean => {
  const prioritySeen: GenericObject = {};
  let isValid = true;

  const allFlowsHaveUniqueName = uniqBy(flows, (flow) => flow.name).length === flows.length;

  // Check that all flows have different priorities
  flows.forEach((flow) => {
    const { priorityLevel } = flow;

    if (prioritySeen[priorityLevel]) {
      isValid = false;
    }

    // Mark we have seen this priorityLevel
    prioritySeen[priorityLevel] = true;
  });

  if (!isValid) {
    // eslint-disable-next-line no-console
    console.error("Coachmark Validation Failed: All flows must have unique priority levels!");
    return false;
  }

  if (!allFlowsHaveUniqueName) {
    // eslint-disable-next-line no-console
    console.error("Coachmark Validation Failed: All flows must have a unique name!");
    return false;
  }

  // Look for circular dependencies in the required flows
  if (hasCycleInFlows(flows)) {
    // eslint-disable-next-line no-console
    console.error("Coachmark Validation Failed: Cycle found in required flows!");
    return false;
  }

  return true;
};

export default validateFlows;
