/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeName } from "common/css/themes";
import BaseStore from "common/stores/BaseStore";

type Key = "lastSavedTheme";

const DEFAULTS: Record<Key, ThemeName | null> = {
  lastSavedTheme: null,
};

class ThemeStore extends BaseStore {
  constructor() {
    super("ThemeStore", DEFAULTS, false);
  }

  get(key: Key): any {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, value: any): GenericObject {
    return super.set(key, value);
  }
}

export default new ThemeStore();
