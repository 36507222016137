/**
 * @generated SignedSource<<f7f624efc39e806f692cfb98b8db0a90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateGameProfilesInput = {
  gameProfiles: ReadonlyArray<UpdateGameProfileVisibility>;
};
export type UpdateGameProfileVisibility = {
  game: string;
  gameProfileId: string;
  visibility: string;
};
export type UpdateGameProfilesMutation$variables = {
  input: UpdateGameProfilesInput;
};
export type UpdateGameProfilesMutation$data = {
  readonly updateGameProfiles: {
    readonly gameProfiles: ReadonlyArray<{
      readonly visibility: string;
    }>;
  };
};
export type UpdateGameProfilesMutation = {
  response: UpdateGameProfilesMutation$data;
  variables: UpdateGameProfilesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateGameProfilesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGameProfilesResponse",
        "kind": "LinkedField",
        "name": "updateGameProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGameProfile",
            "kind": "LinkedField",
            "name": "gameProfiles",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateGameProfilesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGameProfilesResponse",
        "kind": "LinkedField",
        "name": "updateGameProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGameProfile",
            "kind": "LinkedField",
            "name": "gameProfiles",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8a5d8778976b1070f6e7f63f998ed4dd",
    "id": null,
    "metadata": {},
    "name": "UpdateGameProfilesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateGameProfilesMutation(\n  $input: UpdateGameProfilesInput!\n) {\n  updateGameProfiles(input: $input) {\n    gameProfiles {\n      visibility\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9040c5da317ddc815d8fbc2666fd494";

export default node;
