import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { UpdateGameProfilesMutation$data } from "__generated__/UpdateGameProfilesMutation.graphql";

type ReturnType = UpdateGameProfilesMutation$data["updateGameProfiles"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type UpdateGameProfileVisibility = {
  gameProfileId: string;
  visibility: string;
  game: string;
};
type InputType = {
  gameProfiles: UpdateGameProfileVisibility[];
};

const mutation = graphql`
  mutation UpdateGameProfilesMutation($input: UpdateGameProfilesInput!) {
    updateGameProfiles(input: $input) {
      gameProfiles {
        visibility
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "updateGameProfiles.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "updateGameProfiles.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "updateGameProfiles"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          gameProfiles: input.gameProfiles,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
