import { ViewControlReturnType } from "./slideTypes";
import { ExposedProps as UINavExposedProps } from "common/components/UINav";

type Props = {
  addDynamicSlide: (slideId: string) => void;
};

export type HookReturnType = {
  showPreconfiguredViewCore: (
    slideId: string,
    uiNavProps: UINavExposedProps,
  ) => ViewControlReturnType;
  showDynamicViewCore: (slideId: string, uiNavProps: UINavExposedProps) => ViewControlReturnType;
  hideViewCore: (slideId: string, uiNavProps: UINavExposedProps) => void;
};

/**
 * Provides methods to show different kinds of views in the Activity Center's UINav.
 */
function useDynamicSlidePresenter(props: Props): HookReturnType {
  /**
   * The core method used to show a preconfigured view.
   */
  const showPreconfiguredViewCore = (
    slideId: string,
    uiNavProps: UINavExposedProps,
  ): ViewControlReturnType => {
    uiNavProps.showSlide(slideId);

    return {
      hideView: () => {
        uiNavProps.hideSlide(slideId);
      },
    };
  };

  /**
   * The core method used to show a dynamically rendered view.
   */
  const showDynamicViewCore = (
    slideId: string,
    uiNavProps: UINavExposedProps,
  ): ViewControlReturnType => {
    // Add this slide to the dynamic slides.
    props.addDynamicSlide(slideId);

    // Since hooks suck, this is the lazy way of opening this view.
    setTimeout(() => {
      // Show the slide or bring it to the front.
      uiNavProps.showSlide(slideId);
    });

    return {
      hideView: () => {
        uiNavProps.hideSlide(slideId);
      },
    };
  };

  const hideViewCore = (slideId: string, uiNavProps: UINavExposedProps): void => {
    return uiNavProps.hideSlide(slideId);
  };

  return {
    showPreconfiguredViewCore,
    showDynamicViewCore,
    hideViewCore,
  };
}

export default useDynamicSlidePresenter;
