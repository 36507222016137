import classnames from "classnames";
import React from "react";

import { ReactComponent as NianticAirship } from "assets/icons/niantic-airship.svg";

import styles from "./styles.scss";

export type Props = {
  children: React.ReactNode;
  showRibbon?: boolean;
  small?: boolean;
};

export default class OnboardElementCard extends React.Component<Props> {
  render = (): React.ReactNode => {
    return (
      <div
        className={classnames(
          styles.root,
          this.props.small ? styles.roundedSmall : styles.roundedLarge,
        )}
      >
        {this.props.showRibbon && (
          <div className={styles.ribbon}>
            <NianticAirship className={styles.airship} width={39} height={55} />
          </div>
        )}
        {this.props.children}
      </div>
    );
  };
}
