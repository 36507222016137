import { CapacitorUpdater } from "@capgo/capacitor-updater";

import AppUpdateStore from "common/stores/AppUpdateStore";
import { clearNextBundleToApplyOnColdStart } from "common/utils/appUpdate/shouldApplyBundle";

export const updateLastAppVersionSeen = (lastAppVersion: string): void => {
  AppUpdateStore.set("lastAppVersion", lastAppVersion);
};

export const updateLastJSVersionSeen = (lastJSVersion: string): void => {
  AppUpdateStore.set("lastJSVersion", lastJSVersion);
};

/**
 * CRITICAL: We must update anything that may impact our decision whether or not to perform
 * a rollback otherwise, we can infinite loop rollback since the reset() method
 * will reload this file immediately.
 */
// eslint-disable-next-line no-underscore-dangle
const prepareForRollback = (currentAppVersion: string, currentJSVersion: string): void => {
  updateLastAppVersionSeen(currentAppVersion);
  updateLastJSVersionSeen(currentJSVersion);

  // Also, clear the next bundle to apply, since once we rollback, that should be a clean slate.
  // Otherwise, we may rollback, and then detect a need to auto apply something, which is wrong.
  clearNextBundleToApplyOnColdStart();
};

export const getLastVersionsSeen = (): { lastAppVersion: string; lastJSVersion: string } => {
  const lastAppVersion = AppUpdateStore.get("lastAppVersion");
  const lastJSVersion = AppUpdateStore.get("lastJSVersion");

  return {
    lastAppVersion,
    lastJSVersion,
  };
};

/**
 * Sets the bundle that came with the app install as the one to use.
 *
 * In order to safely rollback and prevent infinite loops during this process, a developer
 * must provide the current app version and js version. Without updating these 2 values,
 * during a reload of the JS, we would see that nothing changed and think we need to rollback again
 * and again and again...
 */
// eslint-disable-next-line import/prefer-default-export
export const rollbackToBuiltInVersion = async (
  currentAppVersion: string,
  currentJSVersion: string,
): Promise<void> => {
  try {
    // CRITICAL: We must update anything that may impact our decision whether to perform
    //           a rollback otherwise, we can infinite loop rollback since the reset() method
    //           will reload this file immediately.
    prepareForRollback(currentAppVersion, currentJSVersion);

    await CapacitorUpdater.reset();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
