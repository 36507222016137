// GQLError codes
import {
  INVALID_USERNAME_ERR_CODE,
  NIANTIC_EDIT_DISPLAY_NAME_ABUSIVE_ERR_CODE,
  NIANTIC_EDIT_ID_ABUSIVE_ERR_CODE,
  NIANTIC_EDIT_ID_EXCEED_LIMIT_ERR_CODE,
  NIANTIC_EDIT_ID_INVALID_ERR_CODE,
  NIANTIC_EDIT_ID_NOT_ALLOWED_ERR_CODE,
  NIANTIC_EDIT_ID_UNAVAILABLE_ERR_CODE,
  NIANTIC_ERR_CODE,
} from "constants/backendErrors";

export enum InputErrorType {
  NIANTIC_ID,
}

/**
 * Map GraphQL errors to input error types, so that we know a given error
 * belongs to which input field, hence know how to display this error.
 */
export const InputErrorMap = new Map<number, InputErrorType>([
  [NIANTIC_ERR_CODE, InputErrorType.NIANTIC_ID],
  [NIANTIC_EDIT_ID_INVALID_ERR_CODE, InputErrorType.NIANTIC_ID],
  [NIANTIC_EDIT_ID_UNAVAILABLE_ERR_CODE, InputErrorType.NIANTIC_ID],
  [NIANTIC_EDIT_ID_EXCEED_LIMIT_ERR_CODE, InputErrorType.NIANTIC_ID],
  [NIANTIC_EDIT_ID_NOT_ALLOWED_ERR_CODE, InputErrorType.NIANTIC_ID],
  [NIANTIC_EDIT_ID_ABUSIVE_ERR_CODE, InputErrorType.NIANTIC_ID],
  [NIANTIC_EDIT_DISPLAY_NAME_ABUSIVE_ERR_CODE, InputErrorType.NIANTIC_ID],
  [INVALID_USERNAME_ERR_CODE, InputErrorType.NIANTIC_ID],
]);
