import { RouteComponentProps } from "react-router-dom";

import { MESSAGES_ROUTE, MESSAGES_REQUESTS_ROUTE } from "constants/routes";

export const getUrlForMessageRequests = (): string => {
  return `/${MESSAGES_ROUTE}/${MESSAGES_REQUESTS_ROUTE}`;
};

export default (routeComponentProps: RouteComponentProps, replace?: boolean): void => {
  if (replace) {
    routeComponentProps.history.replace({
      pathname: getUrlForMessageRequests(),
    });
  } else {
    routeComponentProps.history.push({
      pathname: getUrlForMessageRequests(),
    });
  }
};
