/**
 * @generated SignedSource<<d610e2df3c9b51ea46122cf050785875>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LocationHeartbeatInput = {
  location: string;
};
export type LocationHeartbeatMutation$variables = {
  input: LocationHeartbeatInput;
};
export type LocationHeartbeatMutation$data = {
  readonly locationHeartbeat: {
    readonly me: {
      readonly location: string | null;
    };
    readonly success: boolean;
  };
};
export type LocationHeartbeatMutation = {
  response: LocationHeartbeatMutation$data;
  variables: LocationHeartbeatMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "location",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocationHeartbeatMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LocationHeartbeatResponse",
        "kind": "LinkedField",
        "name": "locationHeartbeat",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocationHeartbeatMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LocationHeartbeatResponse",
        "kind": "LinkedField",
        "name": "locationHeartbeat",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b8cd65098ef282565599d1480d96d60f",
    "id": null,
    "metadata": {},
    "name": "LocationHeartbeatMutation",
    "operationKind": "mutation",
    "text": "mutation LocationHeartbeatMutation(\n  $input: LocationHeartbeatInput!\n) {\n  locationHeartbeat(input: $input) {\n    success\n    me {\n      location\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9d77e8579ec45e4051df292bcaa7865";

export default node;
