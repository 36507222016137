import { RouteComponentProps } from "react-router-dom";

import { ACTIVITY_CENTER_ROUTE, ACTIVITY_CENTER_MAP_ROUTE } from "constants/routes";

export const buildUrlToActivityCenterMap = (realityChannelId: string): string => {
  if (!realityChannelId) {
    return `/${ACTIVITY_CENTER_ROUTE}`;
  }

  return `/${ACTIVITY_CENTER_ROUTE}/${realityChannelId}/${ACTIVITY_CENTER_MAP_ROUTE}`;
};

export const replaceRouteToActivityCenterMap = (
  history: RouteComponentProps["history"],
  location: RouteComponentProps["location"],
  realityChannelId: string,
  preserveSearch?: boolean,
): void => {
  history.replace({
    pathname: buildUrlToActivityCenterMap(realityChannelId),
    ...(preserveSearch && { search: location.search }),
  });
};

export default (
  history: RouteComponentProps["history"],
  location: RouteComponentProps["location"],
  realityChannelId: string,
  preserveSearch?: boolean,
): void => {
  history.push({
    pathname: buildUrlToActivityCenterMap(realityChannelId),
    ...(preserveSearch && { search: location.search }),
  });
};
