import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import UIAvatar from "common/components/Avatar/UIAvatar";
import OnboardElementCard from "common/components/Onboarding/components/OnboardElementCard";
import OnboardElementLayout from "common/components/Onboarding/components/OnboardElementLayout";
import OnboardElementSubmitButton from "common/components/Onboarding/components/OnboardElementSubmitButton";
import UIAsyncButton from "common/components/UIAsyncButton";
import UICheckbox from "common/components/UICheckbox";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Friend = {
  id: string;
  name: string;
  displayName: string;
  description: string;
  avatarUrl: string;
  isPending: boolean;
};

export type Props = WithTranslation & {
  onSubmit: () => Promise<void>;
  onAddFriend: (id: string) => Promise<void>;
  friends: Friend[];
  isIncludedInSuggestions: boolean;
  onClickIncludeMe: () => void;
};

type State = {};

class OnboardingNianticFriends extends React.Component<Props, State> {
  addFriend = async (friend: Friend): Promise<void> => {
    await this.props.onAddFriend(friend.displayName);
  };

  renderFriend = (friend: Friend): React.ReactNode => {
    return (
      <OnboardElementCard small>
        <div className={styles.friendContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.avatarContainer}>
              <UIAvatar avatarUrl={friend.avatarUrl} name={friend.displayName} size={48} />
            </div>
            <UISpacer w={14} />
            <div className={styles.idContainer}>
              <UIText color="dark" variant="h4" weight="bold">
                {friend.name}
              </UIText>
              <UISpacer h={2} />
              <UIText color="dark" variant="h5">
                {" "}
                {`@${friend.displayName}`}
              </UIText>
              <UISpacer h={2} />
              <UIText color="medium" variant="h5">
                {" "}
                {`${friend.description}`}
              </UIText>
            </div>
          </div>
          <div className={styles.addButtonContainer}>
            {friend.isPending ? (
              <UIText color="dark" className={styles.pendingText}>
                {this.props.t("PENDING")}
              </UIText>
            ) : (
              <UIAsyncButton fill="clear" size="small" onClick={() => this.addFriend(friend)}>
                <UIText weight="bold">{this.props.t("ADD_FRIEND")}</UIText>
              </UIAsyncButton>
            )}
          </div>
        </div>
      </OnboardElementCard>
    );
  };

  renderSubmit = (): React.ReactNode => {
    return (
      <>
        <div className={styles.submitContainer}>
          <div className={styles.checkboxContainer}>
            <UICheckbox
              checked={this.props.isIncludedInSuggestions}
              onChange={this.props.onClickIncludeMe}
            />
            <UISpacer w={12} />
            <UIText color="dark" weight="medium">
              {this.props.t("INCLUDE_ME_IN_FRIEND_SUGGESTIONS")}
            </UIText>
          </div>
          <UISpacer h={28} />
          <OnboardElementSubmitButton
            text={this.props.t("CONTINUE")}
            onClick={this.props.onSubmit}
          />
        </div>
      </>
    );
  };

  render = (): React.ReactNode => {
    return (
      <OnboardElementLayout
        title={this.props.t("CAMPFIRE_IS_MORE_FUN_WITH_FRIENDS")}
        subtitle={this.props.t("ADD_PLAYERS_YOU_MIGHT_KNOW")}
        renderSubmit={this.renderSubmit}
      >
        <UISpacer h={48} />
        {this.props.friends.map((friend) => (
          <React.Fragment key={friend.id}>
            {this.renderFriend(friend)}
            <UISpacer h={12} />
          </React.Fragment>
        ))}
      </OnboardElementLayout>
    );
  };
}

export default withTranslation()(OnboardingNianticFriends);
