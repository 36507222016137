import environment from "common/relay/relay-env";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import withAnalytics, { WithAnalyticsProps } from "providers/AnalyticsProvider/withAnalytics";

import logException from "common/analytics/exceptions";
import routeToClubById from "common/utils/routing/routeToClubById";
import { getOnboardingEventPayload, OnboardingEvent } from "common/utils/telemetry/onboarding";
import JoinClubMutation from "mutations/JoinClubMutation";

import UIDialog from "common/components/UIDialog";
import WelcomeToClubDialog from "common/components/WelcomeToClubDialog";

export type Props = RouteComponentProps &
  WithAnalyticsProps & {
    clubId: string;
    clubName: string;
    clubAvatarUrl: string;
    isOpen: boolean;
    close: () => void;
  };

type State = {};

class OfficialClubWelcomeDialog extends React.PureComponent<Props, State> {
  joinClubAndRoute = async (): Promise<void> => {
    try {
      const { clubId } = this.props;
      const payload = { clubId };

      await JoinClubMutation.commit(environment, payload);

      this.props.analyticsProvider.trackClientAction(
        "OnboardingEvent",
        getOnboardingEventPayload(OnboardingEvent.OFFICIAL_CLUB),
      );

      // If we successfully joined, route to the club!
      routeToClubById(this.props, clubId, true);
    } catch (error) {
      logException("joinClubOnWelcome", "joinClubAndRoute", "OfficialClubWelcomeDialog", error);
    } finally {
      this.props.close();
    }
  };

  render = (): React.ReactNode => {
    return (
      <UIDialog
        type="drawer"
        isOpen={this.props.isOpen}
        backdropDismiss={false}
        close={this.props.close}
      >
        <WelcomeToClubDialog
          avatarUrl={this.props.clubAvatarUrl}
          title={this.props.clubName}
          close={this.props.close}
          onClickVisitClub={this.joinClubAndRoute}
        />
      </UIDialog>
    );
  };
}

const AnalyticsConnected = withAnalytics(OfficialClubWelcomeDialog);

export default withRouter(AnalyticsConnected);
