import {
  LocalNotifications,
  ActionPerformed as LocalNotificationActionPerformed,
} from "@capacitor/local-notifications";
import environment from "common/relay/relay-env";
import _ from "lodash";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import withAppPermission, {
  WithAppPermissionProps,
} from "providers/AppPermissionProvider/withAppPermission";
import PushActionHandler from "providers/PushNotificationProvider/pushActionHandler";

import logException from "common/analytics/exceptions";
import { isIos, isWeb } from "common/capacitor/helpers";
import {
  ActionPerformed,
  CampfirePushNotifications,
  PushNotificationSchema,
  Token,
} from "common/capacitor/plugins/campfire-push-notifications";
import { PERMISSION_TYPE } from "common/stores/PermissionStore";
import PushTokenRegistrationStore, {
  TokenRegistration,
} from "common/stores/PushTokenRegistrationStore";
import dayjs from "common/utils/dayjs";
import RegisterFcmTokenMutation, { OSType } from "mutations/RegisterFcmTokenMutation";
import UnregisterFcmTokenMutation from "mutations/UnregisterFcmTokenMutation";

const FCM_TOKEN_REFRESH_DELAY_DAYS = 30;

type Props = RouteComponentProps &
  WithAppPermissionProps & {
    userId: string;
    children: React.ReactNode;
  };

export type ExposedProps = {
  registerPushNotifications: () => Promise<void>;
  unregisterPushNotifications: () => Promise<void>;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  registerPushNotifications: () => Promise.resolve(),
  unregisterPushNotifications: () => Promise.resolve(),
};

const PushNotificationContext = React.createContext(INITIAL_CONTEXT);

class PushNotificationProvider extends React.Component<Props, State> {
  pushActionHandler: PushActionHandler;

  historyUnregisterCallback: () => void = _.noop;

  constructor(props: Props) {
    super(props);

    this.pushActionHandler = new PushActionHandler(props);
  }

  componentDidMount = async (): Promise<void> => {
    // For web and embed applications (So that's like us developing in Chrome),
    // don't bother attaching listeners since it causes sentry errors. On embed,
    // we havent implemented addListener so theres nothing this method even would do.
    // Merely a convenience.
    if (!isWeb) {
      await this.initializePushNotifications();

      // Register a listener on the history object that updates the Push Notification plugin's
      // current route. The plugin will use this information to determine whether or not to
      // display an incoming notification. For example, if the user has a particular DM chat open,
      // the plugin will discard any notifications coming from that DM.
      this.historyUnregisterCallback = this.props.history.listen((location) => {
        CampfirePushNotifications.setCurrentRoute({
          pathname: location.pathname ?? "",
          search: location.search ?? "",
        });
      });

      const hasNotificationPermission = await this.props.hasPermission(
        PERMISSION_TYPE.notifications,
      );

      this.attachPushListeners();

      // On load, check if we have already granted permissions for push notifications.
      // If so, register now. Otherwise, something else will prompt us for enabling this permission.
      if (hasNotificationPermission) {
        this.registerPushNotifications();
      }
    }
  };

  componentWillUnmount = (): void => {
    this.historyUnregisterCallback();
  };

  initializePushNotifications = async (): Promise<void> => {
    // The push notification plugin uses the userId to determine if an incoming notification should be
    // shown to the current user.
    return CampfirePushNotifications.setCurrentUserId({ userId: this.props.userId });
  };

  registerPushNotifications = async (): Promise<void> => {
    return CampfirePushNotifications.register();
  };

  unregisterPushNotifications = async (): Promise<void> => {
    const lastTokenRegistration = PushTokenRegistrationStore.get(this.props.userId);

    // eslint-disable-next-line
    console.log(`registration: ${JSON.stringify(lastTokenRegistration)}`);

    if (!lastTokenRegistration) {
      return;
    }

    const payload = {
      fcmToken: lastTokenRegistration.token,
      os: isIos ? "ios" : ("android" as OSType),
    };

    await UnregisterFcmTokenMutation.commit(environment, payload);
    // Remove last registration from store.
    PushTokenRegistrationStore.remove(this.props.userId);
  };

  attachPushListeners = () => {
    // On success, we should be able to receive notifications
    CampfirePushNotifications.addListener("registration", (token: Token) => {
      // eslint-disable-next-line
      console.log(`Push registration success, token: ${token.value}`);

      const lastTokenRegistration = PushTokenRegistrationStore.get(this.props.userId);

      if (this.shouldRegisterToken(lastTokenRegistration, token.value)) {
        // eslint-disable-next-line
        console.log(`Registering token for ${this.props.userId}`);

        const payload = {
          fcmToken: token.value,
          os: isIos ? "ios" : ("android" as OSType),
        };

        try {
          RegisterFcmTokenMutation.commit(environment, payload);
          PushTokenRegistrationStore.set(this.props.userId, token.value);
        } catch (error) {
          logException(
            "RegisterFcmTokenMutation",
            "attachPushListeners",
            "PushNotificationProvider",
            error,
          );
        }
      }
    });

    // Some issue with our setup and push will not work
    CampfirePushNotifications.addListener(
      "registrationError",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error: any) => {
        // eslint-disable-next-line
        console.log(`Error on registration: ${JSON.stringify(error)}`);
      },
    );

    // Show us the notification payload if the app is open on our device
    CampfirePushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        // eslint-disable-next-line
        console.log(`Push received: ${JSON.stringify(notification)}`);
      },
    );

    // Method called when an action is performed on a notification
    CampfirePushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notificationAction: ActionPerformed) => {
        if (notificationAction.actionId === "dismiss") {
          return;
        }
        // eslint-disable-next-line
        console.log(`Push action performed: ${JSON.stringify(notificationAction.notification)}`);
        this.pushActionHandler.handlePushAction(notificationAction.notification);
      },
    );

    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (notificationAction: LocalNotificationActionPerformed) => {
        if (notificationAction.actionId === "dismiss") {
          return;
        }
        // eslint-disable-next-line
        console.log(
          `Local notification action performed ${JSON.stringify(notificationAction.notification)}`,
        );
        this.pushActionHandler.handleLocalAction(notificationAction.notification);
      },
    );
  };

  shouldRegisterToken = (
    lastTokenRegistration: TokenRegistration | null,
    currentToken: string,
  ): boolean => {
    // If we've never registered a token before
    if (!lastTokenRegistration) {
      return true;
    }

    // If the last registered token is different than the current token, register now.
    // Tokens can change even without reinstalling the app.
    if (lastTokenRegistration.token !== currentToken) {
      return true;
    }

    const lastRegisteredDate = dayjs(lastTokenRegistration.registeredAt);
    const now = dayjs();
    const elapsedDays = now.diff(lastRegisteredDate, "day");

    return elapsedDays >= FCM_TOKEN_REFRESH_DELAY_DAYS;
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <PushNotificationContext.Provider
        value={{
          registerPushNotifications: this.registerPushNotifications,
          unregisterPushNotifications: this.unregisterPushNotifications,
        }}
      >
        {children}
      </PushNotificationContext.Provider>
    );
  };
}

const AppPermissionConnected = withRouter(withAppPermission(PushNotificationProvider));

export { AppPermissionConnected as PushNotificationProvider };
export const PushNotificationConsumer = PushNotificationContext.Consumer;
