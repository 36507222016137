import React from "react";

export type ExposedProps = {
  isKeyboardOpen: boolean;
  dispatchKeyboardWillShow: () => void;
  dispatchKeyboardDidShow: () => void;
  dispatchKeyboardWillHide: () => void;
  dispatchKeyboardDidHide: () => void;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  isKeyboardOpen: boolean;
};

const INITIAL_CONTEXT: ExposedProps = {
  isKeyboardOpen: false,
  dispatchKeyboardWillShow: () => undefined,
  dispatchKeyboardDidShow: () => undefined,
  dispatchKeyboardWillHide: () => undefined,
  dispatchKeyboardDidHide: () => undefined,
};

const KeyboardContext = React.createContext(INITIAL_CONTEXT);

export class KeyboardProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isKeyboardOpen: false,
    };
  }

  componentDidMount = (): void => {
    window.addEventListener("keyboardWillShow", this.denoteKeyboardOpen);
    window.addEventListener("keyboardWillHide", this.denoteKeyboardClosed);
  };

  componentWillUnmount = (): void => {
    window.removeEventListener("keyboardWillShow", this.denoteKeyboardOpen);
    window.removeEventListener("keyboardWillHide", this.denoteKeyboardClosed);
  };

  denoteKeyboardOpen = (): void => {
    this.setState({ isKeyboardOpen: true });
  };

  denoteKeyboardClosed = (): void => {
    this.setState({ isKeyboardOpen: false });
  };

  dispatchKeyboardWillShow = (): void => {
    window.dispatchEvent(new Event("keyboardWillShow"));
  };

  dispatchKeyboardDidShow = (): void => {
    window.dispatchEvent(new Event("keyboardDidShow"));
  };

  dispatchKeyboardWillHide = (): void => {
    window.dispatchEvent(new Event("keyboardWillHide"));
  };

  dispatchKeyboardDidHide = (): void => {
    window.dispatchEvent(new Event("keyboardDidHide"));
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <KeyboardContext.Provider
        value={{
          isKeyboardOpen: this.state.isKeyboardOpen,
          dispatchKeyboardWillShow: this.dispatchKeyboardWillShow,
          dispatchKeyboardDidShow: this.dispatchKeyboardDidShow,
          dispatchKeyboardWillHide: this.dispatchKeyboardWillHide,
          dispatchKeyboardDidHide: this.dispatchKeyboardDidHide,
        }}
      >
        {children}
      </KeyboardContext.Provider>
    );
  };
}

export const KeyboardConsumer = KeyboardContext.Consumer;
