import React from "react";

import withKeyboard, { WithKeyboardProps } from "providers/KeyboardProvider/withKeyboard";

import { _actionEventEmitter } from "common/utils/webInterop";

type Props = WithKeyboardProps & {};

type State = {};

const KEYBOARD_WILL_SHOW_EVENT_NAME = "keyboardWillShow";
const KEYBOARD_DID_SHOW_EVENT_NAME = "keyboardDidShow";
const KEYBOARD_WILL_HIDE_EVENT_NAME = "keyboardWillHide";
const KEYBOARD_DID_HIDE_EVENT_NAME = "keyboardDidHide";

class KeyboardEventActionHandler extends React.Component<Props, State> {
  componentDidMount = (): void => {
    _actionEventEmitter.on(KEYBOARD_WILL_SHOW_EVENT_NAME, this.handleKeyboardWillShow);
    _actionEventEmitter.on(KEYBOARD_DID_SHOW_EVENT_NAME, this.handleKeyboardDidShow);
    _actionEventEmitter.on(KEYBOARD_WILL_HIDE_EVENT_NAME, this.handleKeyboardWillHide);
    _actionEventEmitter.on(KEYBOARD_DID_HIDE_EVENT_NAME, this.handleKeyboardDidHide);
  };

  componentWillUnmount = (): void => {
    _actionEventEmitter.off(KEYBOARD_WILL_SHOW_EVENT_NAME, this.handleKeyboardWillShow);
    _actionEventEmitter.off(KEYBOARD_DID_SHOW_EVENT_NAME, this.handleKeyboardDidShow);
    _actionEventEmitter.off(KEYBOARD_WILL_HIDE_EVENT_NAME, this.handleKeyboardWillHide);
    _actionEventEmitter.off(KEYBOARD_DID_HIDE_EVENT_NAME, this.handleKeyboardDidHide);
  };

  handleKeyboardWillShow = (): void => {
    this.props.keyboard.dispatchKeyboardWillShow();
  };

  handleKeyboardDidShow = (): void => {
    this.props.keyboard.dispatchKeyboardDidShow();
  };

  handleKeyboardWillHide = (): void => {
    this.props.keyboard.dispatchKeyboardWillHide();
  };

  handleKeyboardDidHide = (): void => {
    this.props.keyboard.dispatchKeyboardDidHide();
  };

  render = (): React.ReactNode => {
    return null;
  };
}

export default withKeyboard(KeyboardEventActionHandler);
