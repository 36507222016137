/**
 * @generated SignedSource<<af724d6dd657bb39e6fffe581912d48e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RealityChannelsInLatLngBoundsInput = {
  bounds?: LatLngBounds | null;
  zoomLevel?: number | null;
};
export type LatLngBounds = {
  ne: LatLng;
  sw: LatLng;
};
export type LatLng = {
  lat: number;
  lng: number;
};
export type MapAndActivities_page_Query$variables = {
  realityChannelsInLatLngBoundsInput: RealityChannelsInLatLngBoundsInput;
};
export type MapAndActivities_page_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityCenterView_me" | "MapAndActivitiesPageMainContent_me">;
  };
  readonly realityChannelsInLatLngBounds: ReadonlyArray<{
    readonly iconURL: string;
    readonly id: string;
    readonly metadata: {
      readonly game: string;
      readonly googleMapsPlatformMapId: string | null;
      readonly isFirstParty: boolean;
      readonly splashImageUrl: string | null;
    } | null;
    readonly name: string;
    readonly sources: ReadonlyArray<{
      readonly dropTypes: ReadonlyArray<string>;
      readonly name: string;
    }>;
    readonly welcomeBanner: {
      readonly bodyMd: string;
      readonly imgURL: string;
      readonly location: {
        readonly latitude: number | null;
        readonly longitude: number | null;
      } | null;
      readonly title: string;
    } | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"MapAndActivitiesPageMainContent_query">;
};
export type MapAndActivities_page_Query = {
  response: MapAndActivities_page_Query$data;
  variables: MapAndActivities_page_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "realityChannelsInLatLngBoundsInput"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "game",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "realityChannelsInLatLngBoundsInput"
    }
  ],
  "concreteType": "RealityChannel",
  "kind": "LinkedField",
  "name": "realityChannelsInLatLngBounds",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealityChannelSource",
      "kind": "LinkedField",
      "name": "sources",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dropTypes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealityChannelMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFirstParty",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "googleMapsPlatformMapId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "splashImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RealityChannelBanner",
      "kind": "LinkedField",
      "name": "welcomeBanner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bodyMd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imgURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MapAndActivities_page_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MapAndActivitiesPageMainContent_query"
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MapAndActivitiesPageMainContent_me"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActivityCenterView_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MapAndActivities_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSetting",
        "kind": "LinkedField",
        "name": "clientSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MapPageClientSettings",
            "kind": "LinkedField",
            "name": "mapPageClientSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mapDefaultZoomLvl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGameProfile",
            "kind": "LinkedField",
            "name": "gameProfiles",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotificationSettings",
            "kind": "LinkedField",
            "name": "notificationSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "beaconSameLitScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "beaconNearMeScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "outgoingFlareScope",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8e8fce230277032d6aea4160315ba52",
    "id": null,
    "metadata": {},
    "name": "MapAndActivities_page_Query",
    "operationKind": "query",
    "text": "query MapAndActivities_page_Query(\n  $realityChannelsInLatLngBoundsInput: RealityChannelsInLatLngBoundsInput!\n) {\n  ...MapAndActivitiesPageMainContent_query\n  realityChannelsInLatLngBounds(input: $realityChannelsInLatLngBoundsInput) {\n    id\n    name\n    iconURL\n    sources {\n      name\n      dropTypes\n    }\n    metadata {\n      isFirstParty\n      game\n      googleMapsPlatformMapId\n      splashImageUrl\n    }\n    welcomeBanner {\n      title\n      bodyMd\n      imgURL\n      location {\n        latitude\n        longitude\n      }\n    }\n  }\n  me {\n    ...MapAndActivitiesPageMainContent_me\n    ...ActivityCenterView_me\n    id\n  }\n}\n\nfragment ActivityCenterPageLayout_me on User {\n  id\n  ...PGORealityChannelSettings_me\n}\n\nfragment ActivityCenterViewProvider_me on User {\n  ...ActivityCenterPageLayout_me\n}\n\nfragment ActivityCenterView_me on User {\n  ...ActivityCenterPageLayout_me\n  ...ActivityCenterViewProvider_me\n}\n\nfragment DefaultActivityCenterQR_clientSetting on ClientSetting {\n  mapPageClientSettings {\n    mapDefaultZoomLvl\n  }\n}\n\nfragment MapAndActivitiesPageMainContent_me on User {\n  id\n  gameProfiles {\n    game\n    id\n  }\n}\n\nfragment MapAndActivitiesPageMainContent_query on Query {\n  clientSetting {\n    ...RealityChannelActivityCenterPage_clientSetting\n  }\n}\n\nfragment PGOActivityCenterQR_clientSetting on ClientSetting {\n  mapPageClientSettings {\n    mapDefaultZoomLvl\n  }\n}\n\nfragment PGORealityChannelSettings_me on User {\n  notificationSettings {\n    beaconSameLitScope\n    beaconNearMeScope\n    outgoingFlareScope\n  }\n}\n\nfragment RealityChannelActivityCenterPage_clientSetting on ClientSetting {\n  ...PGOActivityCenterQR_clientSetting\n  ...DefaultActivityCenterQR_clientSetting\n}\n"
  }
};
})();

(node as any).hash = "bc9ad8032f24d5397cfba1ed9a8fdaeb";

export default node;
