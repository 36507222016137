/* eslint-disable @typescript-eslint/no-explicit-any */

import { ToggleChangeEventDetail } from "@ionic/core";
import { IonRadioGroup } from "@ionic/react";
import React from "react";

import ToggleOption, {
  Props as ToggleOptionProps,
} from "common/components/NotificationGroup/ToggleOption";
import UIText from "common/components/UIText";
import UIToggle from "common/components/UIToggle";

import styles from "./styles.scss";

type Props<T> = {
  name: string;
  toggleable?: boolean;
  value: T;
  // Value of this setting when the toggle is unchecked.
  disabledValue?: T;
  // Value of this setting when the toggle is checked. If this setting has any child ToggleOption,
  // the value of the currently selected ToggleOption is used instead.
  enabledValue?: T;
  children?: React.ReactNode;
  // Called whenever the toggle changes, or any child ToggleOption is selected.
  onOptionChanged?: (value: T | null) => void;
};

type State<T> = {
  checked: boolean;
  selectedOption: T | null;
};

const callAll =
  (...fns: any[]) =>
  (...args: any[]) =>
    fns.forEach((fn) => fn && fn(...args));

export default class NotificationSetting<T = any> extends React.Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);

    const toggleOptions = this.getToggleOptionChildren();

    // Setup initial state based on the value prop
    const checked = this.props.toggleable ? this.props.value !== this.props.disabledValue : true;
    const selectedOption = checked ? this.props.value : toggleOptions[0]?.props.value;

    this.state = {
      checked,
      selectedOption,
    };
  }

  getToggleOptionChildren = (): Array<React.ReactElement<ToggleOptionProps<T>>> => {
    return React.Children.toArray(this.props.children).filter((child) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isOptionType = [ToggleOption].includes(child.type);

      return isOptionType;
    }) as Array<React.ReactElement<ToggleOptionProps<T>>>;
  };

  getToggleOptions = (): Array<React.ReactElement<ToggleOptionProps<T>>> => {
    const toggleOptions = this.getToggleOptionChildren();

    return toggleOptions.map((toggleOptionComponent: React.ReactElement<ToggleOptionProps<T>>) => {
      // When a toggle is clicked, invoke the onClick on it, as well as another one!
      const onClick = callAll(toggleOptionComponent.props.onClick, () => {
        if (this.props.onOptionChanged) {
          this.props.onOptionChanged(toggleOptionComponent.props.value);
        }
      });

      const disabled = !this.state.checked;

      return React.cloneElement(toggleOptionComponent, {
        onClick,
        disabled,
      }) as React.ReactElement<ToggleOptionProps<T>>;
    });
  };

  onIonChange = (event: CustomEvent<ToggleChangeEventDetail>): void => {
    if (this.props.onOptionChanged && event.detail.checked !== this.state.checked) {
      const { checked } = event.detail;

      if (checked) {
        this.props.onOptionChanged(this.state.selectedOption ?? this.props.enabledValue ?? null);
      } else {
        this.props.onOptionChanged(this.props.disabledValue ?? null);
      }

      this.setState({ checked });
    }
  };

  renderToggleableHeader = (): React.ReactNode => {
    return (
      <div className={styles.toggle}>
        <UIToggle
          checked={this.state.checked}
          label={this.props.name}
          color="success"
          onIonChange={this.onIonChange}
        />
      </div>
    );
  };

  renderStaticHeader = (): React.ReactNode => {
    return (
      <div className={styles.toggle}>
        <UIText color="dark" variant="h4" weight="medium">
          {this.props.name}
        </UIText>
      </div>
    );
  };

  render = (): React.ReactNode => {
    const toggleOptions = this.getToggleOptions() || [];

    return (
      <div className={styles.toggleGroupRoot}>
        {this.props.toggleable ? this.renderToggleableHeader() : this.renderStaticHeader()}
        <div className={styles.radioGroup}>
          <IonRadioGroup value={this.props.value}>{toggleOptions}</IonRadioGroup>
        </div>
      </div>
    );
  };
}
