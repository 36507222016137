import { checkmarkSharp } from "ionicons/icons";
import _ from "lodash";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";

import useSelectedRealityChannel from "providers/SelectedRealityChannelProvider/useSelectedRealityChannel";
import { getDynamicInteractionKey } from "providers/UserInteractionHistoryProvider/interactions";
import useUserInteractionHistory from "providers/UserInteractionHistoryProvider/useUserInteractionHistory";

import { existingRealityChannels } from "constants/nianticGame";

import Image from "common/components/Image";
import RealityChannelSelectionPreloader, {
  PRELOADED_QUERY,
} from "common/components/RealityChannelSelection/preloader";
import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UISpinner from "common/components/UISpinner";
import UIText from "common/components/UIText";

import {
  preloader_RealityChannelSelection_Query as RealityChannelSelectionPreloaderQuery,
  preloader_RealityChannelSelection_Query$data as RealityChannelSelectionPreloaderQueryResponse,
} from "__generated__/preloader_RealityChannelSelection_Query.graphql";

import styles from "./styles.scss";

export type PreloadedQueryReference = PreloadedQuery<RealityChannelSelectionPreloaderQuery>;

type RealityChannel = ArrayElement<
  RealityChannelSelectionPreloaderQueryResponse["realityChannelsInLatLngBounds"]
>;

export type Props = {
  close: () => void;
  queryReference: PreloadedQueryReference;
};

const RealityChannelSelectionView = (props: Props): React.ReactElement | null => {
  const { t } = useTranslation();
  const userInteraction = useUserInteractionHistory();
  const { selectedRealityChannelId, changeSelectedRealityChannel } = useSelectedRealityChannel();
  const data = usePreloadedQuery<RealityChannelSelectionPreloaderQuery>(
    PRELOADED_QUERY,
    props.queryReference,
  );

  /**
   * Orders reality channels so that Reality Channels with a higher display rank are first.
   */
  const orderRealityChannelsForDisplay = (realityChannels: RealityChannel[]): RealityChannel[] => {
    return _.orderBy(
      realityChannels,
      (rc) => {
        return rc.metadata?.displayRank;
      },
      ["desc"],
    );
  };

  const rcs = data.realityChannelsInLatLngBounds as Array<Writable<RealityChannel>>;
  const orderedByDisplayRank = orderRealityChannelsForDisplay(rcs);

  const renderRCRowItem = useCallback(
    (realityChannel: RealityChannel): React.ReactNode => {
      const isSelected = selectedRealityChannelId === realityChannel.id;
      const selectRC = (): void => {
        const rcMetadata = {
          id: realityChannel.id,
          name: realityChannel.name,
          iconURL: realityChannel.iconURL,
        };

        const interactionName = getDynamicInteractionKey(
          "realityChannelVisited",
          realityChannel.id,
        );

        userInteraction.markInteraction(interactionName);
        changeSelectedRealityChannel(rcMetadata);
        props.close();
      };

      const interactionName = getDynamicInteractionKey("realityChannelVisited", realityChannel.id);
      const hasVisited = userInteraction.hasSeen(interactionName);
      const isExistingGame = existingRealityChannels.includes(realityChannel.name);
      const shouldShowNewPill = !hasVisited && !isExistingGame && !isSelected;

      return (
        <div
          role="presentation"
          key={realityChannel.id}
          className={styles.realityChannelRowItem}
          onClick={selectRC}
          data-test-name={realityChannel.name}
        >
          <Image
            className={styles.realityChannelIcon}
            loading="lazy"
            alt=""
            src={realityChannel.iconURL}
          />
          <UISpacer w={12} />
          <UIText color="white" variant="body1" weight="bold">
            {realityChannel.name}
          </UIText>
          {isSelected && (
            <UIIcon className={styles.checkmark} icon={checkmarkSharp} color="white" size={24} />
          )}
          {shouldShowNewPill && (
            <div className={styles.newIndicator}>
              <UIText variant="subtitle2" weight="bold">
                {t("NEW").toLocaleUpperCase()}
              </UIText>
            </div>
          )}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeSelectedRealityChannel, props, selectedRealityChannelId],
  );

  return <div className={styles.root}>{orderedByDisplayRank.map(renderRCRowItem)}</div>;
};

const RealityChannelSelectionLoading = (): React.ReactElement | null => {
  return (
    <div className={styles.loading}>
      <UISpinner size={24} color="white" />
    </div>
  );
};

export default {
  Preloader: RealityChannelSelectionPreloader,
  View: RealityChannelSelectionView,
  Loading: RealityChannelSelectionLoading,
};
