import adjustFontSizeIfNeeded from "app-setup/adjustFontSizeIfNeeded";
import { clearLastBackgroundTimestamp } from "common/utils/appUpdate/shouldApplyBundle";

/**
 * During the app boot process, we need to do some native work before rendering the web app.
 */
export const prepareNativeApp = async (): Promise<void> => {
  adjustFontSizeIfNeeded();
};

/**
 * Once we are ready to render the app, finish any work that needs to be.
 */
export const finalizeNativeApp = async (): Promise<void> => {
  // For use when to auto update:
  // Clear the last time we backgrounded the app.
  // Since this should called on a cold start, we care about the NEXT background timestamp from here on out.
  clearLastBackgroundTimestamp();
};
