import classnames from "classnames";
import { chevronBackOutline } from "ionicons/icons";
import React from "react";

import TEST_IDS from "constants/testIds";

import SafeAreaBottomSpacer from "common/components/SafeAreaBottomSpacer";
import UIHeader from "common/components/UIHeader";
import UIIcon from "common/components/UIIcon";

import styles from "./styles.scss";

export type Props = {
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClickBack?: () => void;
  scrollable?: boolean;
  renderHeaderTitle?: () => React.ReactNode;
  renderHeaderStart?: () => React.ReactNode;
  renderHeaderEnd?: () => React.ReactNode;
  headerFlat?: boolean;
  respectSafeArea?: boolean;
};

type State = {};

export default class ContentWithHeader extends React.Component<Props, State> {
  renderStart = (): React.ReactNode => {
    return (
      <>
        {this.props.onClickBack && (
          <div role="presentation" onClick={this.props.onClickBack} className={styles.backBtn}>
            <UIIcon
              size={26}
              color="dark"
              icon={chevronBackOutline}
              dataTestId={TEST_IDS.HeaderBackBtnId}
            />
          </div>
        )}
        {this.props.renderHeaderStart && this.props.renderHeaderStart()}
      </>
    );
  };

  render = (): React.ReactNode => {
    const {
      respectSafeArea = true,
      scrollable = true,
      title,
      onClickBack,
      headerFlat,
      renderHeaderStart,
      renderHeaderTitle,
      renderHeaderEnd,
    } = this.props;

    // Render a header automatically when:
    // 1. Title is defined
    // 2. We want a click back button
    // 3. A custom rendering function was provided for the header start.
    // 4. A custom rendering function was provided for the header title.
    // 5. A custom rendering function was provided for the header end.
    const showDefaultHeader =
      title !== undefined ||
      !!onClickBack ||
      renderHeaderStart ||
      renderHeaderTitle ||
      renderHeaderEnd;

    return (
      <div className={classnames(styles.root, this.props.className)}>
        {showDefaultHeader && (
          <UIHeader
            title={this.props.title}
            renderTitle={this.props.renderHeaderTitle}
            renderStart={this.renderStart}
            renderEnd={this.props.renderHeaderEnd}
            flat={headerFlat}
            respectSafeArea={respectSafeArea}
          />
        )}

        <div
          className={classnames(styles.content, {
            [styles.scrollable]: scrollable,
          })}
        >
          {this.props.children}
          {/* Add default space on bottom when the content should be scrollable. Non scrollable content is controlled by the developer */}
          {scrollable && <SafeAreaBottomSpacer />}
        </div>
      </div>
    );
  };
}
