/**
 * @generated SignedSource<<7449cad52baaf899626b253e1f6a1607>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppRouterV2_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CoreNavigationFloatingTabBar_query">;
  readonly " $fragmentType": "AppRouterV2_query";
};
export type AppRouterV2_query$key = {
  readonly " $data"?: AppRouterV2_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppRouterV2_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppRouterV2_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CoreNavigationFloatingTabBar_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4b193b37934638dc61b1a1be549cde40";

export default node;
