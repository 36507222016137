/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { OAuthConsumer } from "providers/OAuthProvider";

export type WithAuthenticationProps = {
  authenticateWithOAuth: (oauthProvider: SupportedOAuthProvider) => Promise<void>;
};

// eslint-disable-next-line max-len
export default <P extends WithAuthenticationProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithAuthenticationProps>): React.ReactElement => (
    <OAuthConsumer>
      {({ authenticateWithOAuth }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component authenticateWithOAuth={authenticateWithOAuth} {...props} />
      )}
    </OAuthConsumer>
  );
};
