import { ban, close, ellipsisHorizontal, flag, personRemove } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withBlockedUsers, {
  WithBlockedUsersProps,
} from "providers/BlockedUsersProvider/withBlockedUsers";
import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import ReportContext from "constants/reportContext";
import TEST_IDS from "constants/testIds";

import BlockUserConfirmationDialog from "common/components/BlockUserConfirmationDialog";
import RemoveFriendDialog from "common/components/RemoveFriendDialog";
import UserReportComponent from "common/components/Reporting/UserReport/UserReportComponent";
import UIClickableIcon from "common/components/UIClickableIcon";
import UIDialog from "common/components/UIDialog";
import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation &
  WithBlockedUsersProps &
  WithToastProps & {
    avatarUrl: string;
    displayName: string;
    username: string;
    userId: string;
    isBlocked: boolean;
    isMyFriend: boolean;
    reportContext: ReportContext;
  };

type State = {
  showMenu: boolean;
  isBlockUserOpen: boolean;
  isRemoveFriendOpen: boolean;
};

class UserProfileEllipsisButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showMenu: false,
      isBlockUserOpen: false,
      isRemoveFriendOpen: false,
    };
  }

  showMenu = (): void => {
    this.setState({ showMenu: true });
  };

  hideMenu = (): void => {
    this.setState({ showMenu: false });
  };

  showBlockModal = (): void => {
    this.setState({ isBlockUserOpen: true });
  };

  hideBlockModal = (): void => {
    this.setState({ isBlockUserOpen: false });
  };

  onBlockUser = (): void => {
    this.hideBlockModal();
    this.hideMenu();
  };

  showRemoveFriend = (): void => {
    this.setState({ isRemoveFriendOpen: true });
  };

  onRemoveFriend = (): void => {
    this.hideRemoveFriend();
    this.hideMenu();
  };

  hideRemoveFriend = (): void => {
    this.setState({ isRemoveFriendOpen: false });
  };

  unblockUser = async (): Promise<void> => {
    try {
      await this.props.blockedUsersProvider.unblockUser(this.props.userId);
    } catch (error) {
      this.props.toastProvider.showErrorToast(this.props.t("FAILED_UNBLOCK"));
    }

    this.hideMenu();
  };

  renderModals = (): React.ReactNode => {
    return (
      <div>
        <RemoveFriendDialog
          isOpen={this.state.isRemoveFriendOpen}
          onClose={this.hideRemoveFriend}
          onRemoveFriend={this.onRemoveFriend}
          username={this.props.username}
          userId={this.props.userId}
        />
        <BlockUserConfirmationDialog
          isOpen={this.state.isBlockUserOpen}
          onClose={this.hideBlockModal}
          onBlock={this.onBlockUser}
          displayName={this.props.displayName}
          userId={this.props.userId}
        />
      </div>
    );
  };

  renderMenu = (): React.ReactNode => {
    const showRemoveFriends = this.props.isMyFriend;

    return (
      <UIDialog
        type="drawer"
        isOpen={this.state.showMenu}
        close={this.hideMenu}
        onWillDismiss={this.hideMenu}
      >
        <div className={styles.drawer}>
          {showRemoveFriends && (
            <div
              onClick={this.showRemoveFriend}
              role="presentation"
              className={styles.settingsOption}
              data-test-id={TEST_IDS.ProfileDrawerRemoveFriendBtnId}
            >
              <UIIcon size={18} color="danger" icon={personRemove} />
              <UISpacer w={10} />
              <UIText color="danger" variant="h4" weight="bold">
                {this.props.t("REMOVE_FRIEND")}
              </UIText>
            </div>
          )}
          {!this.props.isBlocked && (
            <div
              onClick={this.showBlockModal}
              role="presentation"
              className={styles.settingsOption}
              data-test-id={TEST_IDS.ProfileDrawerBlockBtnId}
            >
              <UIIcon size={18} color="danger" icon={ban} />
              <UISpacer w={10} />
              <UIText color="danger" variant="h4" weight="bold">
                {this.props.t("BLOCK")}
              </UIText>
            </div>
          )}
          {this.props.isBlocked && (
            <div
              onClick={this.unblockUser}
              role="presentation"
              className={styles.settingsOption}
              data-test-id={TEST_IDS.ProfileDrawerUnblockBtnId}
            >
              <UIIcon size={18} color="dark" icon={ban} />
              <UISpacer w={10} />
              <UIText color="dark" variant="h4" weight="bold">
                {this.props.t("UNBLOCK")}
              </UIText>
            </div>
          )}
          <UserReportComponent
            avatarUrl={this.props.avatarUrl}
            displayName={this.props.displayName}
            userId={this.props.userId}
            username={this.props.username}
            reportContext={this.props.reportContext}
          >
            {({ showReportUser }) => (
              <div
                onClick={showReportUser}
                role="presentation"
                className={styles.settingsOption}
                data-test-id={TEST_IDS.ProfileDrawerReportUserBtnId}
              >
                <UIIcon size={18} color="dark" icon={flag} />
                <UISpacer w={10} />
                <UIText color="dark" variant="h4" weight="bold">
                  {this.props.t("REPORT")}
                </UIText>
              </div>
            )}
          </UserReportComponent>
          <div
            onClick={this.hideMenu}
            role="presentation"
            className={styles.settingsOption}
            data-test-id={TEST_IDS.ProfileDrawerCancelBtnId}
          >
            <UIIcon size={18} color="medium" icon={close} />
            <UISpacer w={10} />
            <UIText color="medium" variant="h4" weight="bold">
              {this.props.t("CANCEL")}
            </UIText>
          </div>
        </div>
      </UIDialog>
    );
  };

  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIClickableIcon
          color="dark"
          size={20}
          onClick={this.showMenu}
          icon={ellipsisHorizontal}
          dataTestId={TEST_IDS.UserProfileEllipsesId}
        />
        {this.renderMenu()}
        {this.renderModals()}
      </div>
    );
  };
}

const ToastConnected = withToast(UserProfileEllipsisButton);
const BlockedUsersConnected = withBlockedUsers(ToastConnected);

export default withTranslation()(BlockedUsersConnected);
