import { SlideInfo, SlideStackState, SlideComponentDef } from "./types";

const getSlideInfo = (
  slide: SlideComponentDef,
  currentSlideState: SlideStackState,
): SlideInfo | null => {
  const { slideId, onHidden, onOpen, contentShouldBeRenderedWhileClosed = false } = slide.props;

  const isOpen = currentSlideState.isSlideInStack(slideId);
  const indexInSlideStack = currentSlideState.getIndexOfSlideInStack(slideId);
  const isCurrentSlide = slideId === currentSlideState.activeSlide;

  return {
    slideId,
    isOpen,
    contentShouldBeRenderedWhileClosed,
    isCurrentSlide,
    indexInStack: indexInSlideStack,
    onHidden,
    onOpen,
  };
};

export default getSlideInfo;
