import React from "react";

export type ExposedProps = {
  isInteractive: boolean;
  getPageEl: () => Element | null;
};

type Props = {
  isActivePage: boolean;
  pageId: string;
  children: React.ReactNode;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  isInteractive: false,
  getPageEl: () => null,
};

export const CAMPFIRE_PAGE_ID_ATTRIBUTE_NAME = "data-page-id";

export const CampfirePageContext = React.createContext(INITIAL_CONTEXT);

export class CampfirePageProvider extends React.Component<Props, State> {
  findPageEl = (): Element | null => {
    return document.querySelector(`div[${CAMPFIRE_PAGE_ID_ATTRIBUTE_NAME}="${this.props.pageId}"]`);
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <CampfirePageContext.Provider
        value={{
          isInteractive: this.props.isActivePage,
          getPageEl: this.findPageEl,
        }}
      >
        {children}
      </CampfirePageContext.Provider>
    );
  };
}

export const CampfirePageConsumer = CampfirePageContext.Consumer;
