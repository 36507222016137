import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import UIButton from "common/components/UIButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  reload: () => void;
};

type State = {};

class ClubsPageLoadError extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIText variant="h3" color="dark" weight="semiBold">
          {this.props.t("SORRY_WERE_HAVING_TROUBLE_LOADING_YOUR_GROUPS")}
        </UIText>

        <UISpacer h={24} />

        <UIButton color="secondary" onClick={this.props.reload}>
          <UIText variant="body1" color="white" weight="medium">
            {this.props.t("RELOAD")}
          </UIText>
        </UIButton>
      </div>
    );
  };
}

export default withTranslation()(ClubsPageLoadError);
