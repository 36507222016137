import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import nianticLogo from "assets/images/niantic/niantic-logo-with-name.png";
import { isMHNowEmbed } from "common/utils/webInterop";
import AppInterop from "common/utils/webInterop/app";

import Image from "common/components/Image";
import MagellanErrorView from "common/components/Magellan/MagellanErrorView";
import UIButton from "common/components/UIButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {};

type State = {};

class LandingPageEmbedded extends React.Component<Props, State> {
  reload = (): void => {
    AppInterop.reload();
  };

  renderForMHNow = (): React.ReactNode => {
    return (
      <div className={styles.magellanExperienceRoot}>
        <MagellanErrorView
          errorDescription={this.props.t("SORRY_SOMETHING_WENT_WRONG")}
          errorButtonText={this.props.t("OK")}
          onButtonClick={this.reload}
        />
      </div>
    );
  };

  render = (): React.ReactNode => {
    if (isMHNowEmbed) {
      return this.renderForMHNow();
    }

    return (
      <div className={styles.root}>
        <div className={styles.bgImage} />

        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.dynamicSpacer} />
            <div className={styles.appName}>
              <UIText color="dark" variant="h1" weight="extraBold">
                {this.props.t("CAMPFIRE")}
              </UIText>
              <UISpacer h={16} />
              <UIText color="dark" variant="h3" weight="medium">
                {this.props.t("CONNECT_WITH_FRIENDS_EXPLORE_THE_WORLD")}
              </UIText>
            </div>

            <UISpacer h={60} />

            <div className={styles.centered}>
              <UIText color="dark" variant="body1" weight="bold">
                {this.props.t("THE_FIRE_NEEDS_TO_BE_RELIT")}
              </UIText>

              <UISpacer h={12} />

              <UIButton color="primary" expand="block" onClick={this.reload}>
                <UIText variant="body1" weight="bold">
                  {this.props.t("BACK_TO_GAME")}
                </UIText>
              </UIButton>
            </div>
          </div>

          <div className={styles.footer}>
            <Image className={styles.logo} src={nianticLogo} alt="Logo" />
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(LandingPageEmbedded);
