/* eslint-disable */
import Store from "store2";
import _ from "lodash";
import logException from "common/analytics/exceptions";

type HandlerFunction = (valueOfKey: any, store: GenericObject) => void;

/**
 * This BaseStore class acts as an in memory storage with caching to browser storage capabilities.
 *
 * It maintains a single instance of an object and simply saves it to browser storage when keys are changed.
 */
export default class BaseStore {
  _storeName: string;

  _store: GenericObject;

  _useSessionStorage: boolean;

  _subscriptions: Map<string, Map<HandlerFunction, HandlerFunction>>;

  constructor(storeName: string, defaultValue: GenericObject, useSessionStorage: boolean) {
    this._storeName = storeName;
    this._useSessionStorage = useSessionStorage;
    this._subscriptions = new Map();

    // Look for the store in localStorage or sessionStorage.
    const cachedStore = this._getFromStorage(storeName, useSessionStorage);
    const mergedWithDefaults = _.merge(defaultValue, cachedStore);
    this._store = mergedWithDefaults;

    // Write it to storage now
    if (this._useSessionStorage) {
      Store.session.set(this._storeName, this._store);
    } else {
      // Set into localStorage
      Store.set(this._storeName, this._store);
    }
  }

  _getFromStorage(key: string, useSessionStorage?: boolean): Object {
    // Grab the cached store from local or session storage
    const value = useSessionStorage ? Store.session.get(key) : Store.get(key);

    // NOTE: As of 3/15/2023, we are no longer double JSON.stringifying store values before saving to storage
    // since the store2 library handles stringifying for us. However, for clients whose store values are still
    // double-stringified, we will attempt to parse it first.
    if (typeof value === "string") {
      try {
        const parsedValue = JSON.parse(value);

        return parsedValue;
      } catch (error) {
        logException("JSON.parse", "_getFromStorage", "BaseStorage", error);
      }
    } else if (value) {
      return value;
    }

    // If no value is cached yet or we encountered an error, return an empty object
    return {};
  }

  _notifyHandlers(key: string, value: any) {
    // Invoke handlers for the key!
    if (this._subscriptions.get(key)) {
      const keySpecificHandlers = this._subscriptions.get(key);

      if (keySpecificHandlers) {
        keySpecificHandlers.forEach((handler: HandlerFunction) => {
          handler(value, this._store);
        });
      }
    }
  }

  get(key: string): any {
    return this._store ? this._store[key] : undefined;
  }

  getStore(): any {
    return this._store || undefined;
  }

  updateStore(updatedStore: GenericObject) {
    // Set into sessionStorage
    if (this._useSessionStorage) {
      Store.session.set(this._storeName, updatedStore);
    } else {
      // Set into localStorage
      Store.set(this._storeName, updatedStore);
    }
  }

  set(key: string, value: any): GenericObject {
    // Update the store in memory
    const updatedStore = _.merge(this._store, { [key]: value });

    this.updateStore(updatedStore);
    this._notifyHandlers(key, value);

    // Return the now updated store
    return this._store;
  }

  remove(key: string): GenericObject {
    delete this._store[key];

    this.updateStore(this._store);
    this._notifyHandlers(key, undefined);

    // Return the now updated store
    return this._store;
  }

  subscribe(key: string, callback: HandlerFunction) {
    // If we have never created a Map for this key, create one
    if (!this._subscriptions.get(key)) {
      this._subscriptions.set(key, new Map());
    }

    const keySpecificHandlers = this._subscriptions.get(key);

    if (keySpecificHandlers) {
      keySpecificHandlers.set(callback, callback);
    }
  }

  unsubscribe(key: string, callback: HandlerFunction) {
    if (this._subscriptions.get(key)) {
      const keySpecificHandlers = this._subscriptions.get(key);

      if (keySpecificHandlers) {
        keySpecificHandlers.delete(callback);
      }
    }
  }

  clear() {
    this._store = {};

    this.updateStore(this._store);
  }
}
