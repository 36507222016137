import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { NavigationApp, NavigationAppData } from "common/capacitor/plugins/navigation";

import UIDialog from "common/components/UIDialog";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  isOpen: boolean;
  availableApps: NavigationAppData[];
  onWillDismiss: () => void;
  close: () => void;
  onSelectApp: (appId: NavigationApp) => void;
};

type State = {};

class SelectNavigationAppDrawer extends React.Component<Props, State> {
  renderAppButton = (app: NavigationAppData): React.ReactNode => {
    return (
      <div className={styles.option}>
        <div
          role="presentation"
          className={styles.action}
          onClick={() => this.props.onSelectApp(app.id)}
        >
          <UIText color="dark" weight="bold">
            {app.name}
          </UIText>
        </div>
      </div>
    );
  };

  render = (): React.ReactNode => {
    const drawerItems = this.props.availableApps.map((app) => this.renderAppButton(app));

    return (
      <UIDialog
        isOpen={this.props.isOpen}
        type="drawer"
        close={this.props.close}
        onWillDismiss={this.props.onWillDismiss}
      >
        <div className={styles.root}>{drawerItems}</div>
      </UIDialog>
    );
  };
}

export default withTranslation()(SelectNavigationAppDrawer);
