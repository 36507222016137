import {
  InitiateUploadResponse,
  InitiateUploadOptions,
} from "types/embedPluginInterface/embedUpload";

export const validator = (initiateUploadResponse: InitiateUploadResponse): boolean => {
  const hasUploadId = Boolean(initiateUploadResponse.uploadId);

  return hasUploadId;
};

// eslint-disable-next-line max-len
const errorMessage = "Custom cap plugin EmbedUpload.initiateUpload not implemented!";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultInitiateUpload = (options: InitiateUploadOptions): Promise<InitiateUploadResponse> =>
  Promise.reject(errorMessage);

export default defaultInitiateUpload;
