import { attachClientInfoToEachRequest, ClientInfo } from "common/api/client-info-interceptor";
import { isStandalone } from "common/capacitor/helpers";
import { CAMPFIRE_JS_VERSION } from "common/utils/campfireApp";
import { getLanguage } from "common/utils/i18n";
import { embeddedClientInfo, isEmbedded } from "common/utils/webInterop";
import { ClientInfoPlatform } from "constants/clientInfo";
import { GAME_SHORT_CODES } from "constants/nianticGame";

export default async (): Promise<void> => {
  if (!isEmbedded) {
    // Attempt to grab the language from device
    try {
      const language = await getLanguage();
      const standaloneClientInfo: ClientInfo = {
        Game: GAME_SHORT_CODES.CAMPFIRE,
        Language: language,
        // Set Platform for standalone, web not necessary but eh, just set it anyways.
        Platform: isStandalone
          ? ClientInfoPlatform.PlatformNative
          : ClientInfoPlatform.PlatformWebStandalone,
        CampfireVersion: CAMPFIRE_JS_VERSION,
      };

      attachClientInfoToEachRequest(standaloneClientInfo);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  } else {
    // Since embeddedClientInfo is typically just forwarded, but we now need to send
    // the campfire app version, we will ever so slightly modify the payload with CampfireVersion.
    const clientInfoToTrackForEmbed = {
      ...embeddedClientInfo,
      CampfireVersion: CAMPFIRE_JS_VERSION,
    };

    attachClientInfoToEachRequest(clientInfoToTrackForEmbed);
  }
};
