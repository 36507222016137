import config from "constants/config";

export default (
  oauthLoginUrl: string,
  birthday: string,
  appRedirectUrl?: string,
  showRedirectSplash?: boolean,
): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const queryParams: Record<string, string | void> = {
    birthday,
    showRedirectSplash: showRedirectSplash ? "true" : undefined,
    redirectUrl: encodeURIComponent(
      appRedirectUrl || (config.get("CAMPFIRE_APP_CUSTOM_URL_SCHEME") as string),
    ),
  };

  // Join all query params into a string.
  const queryString: string = Object.keys(queryParams)
    .filter((key) => !!queryParams[key])
    .map((key) => `${key}=${queryParams[key] || ""}`)
    .join("&");

  return `${oauthLoginUrl}${queryString ? `?${queryString}` : ""}`;
};
