import classnames from "classnames";
import React from "react";

import styles from "./styles.scss";

type Props = {
  children: React.ReactNode;
};

const CoreNavigationFullSurfaceLayout = (props: Props): React.ReactElement => {
  return (
    <div className={styles.root}>
      <div className={styles.floatingTabBarCtn}>
        <div className={classnames(styles.floatingTabBarContentCtn, styles.shifted)}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default CoreNavigationFullSurfaceLayout;
