import classnames from "classnames";
import _ from "lodash";
import React, { RefObject } from "react";
import { FetchPolicy, RelayPaginationProp } from "react-relay";

import logException from "common/analytics/exceptions";

import styles from "./styles.scss";

// These are optional props that you can pass to your created connection loader, that control
// how much to fetch per page, if you want to reload the query, etc.
export type OptionalProps = {
  limit?: number;
  className?: string;
  contentClassName?: string;
  disablePagniation?: boolean;
  forceRefresh?: boolean; // Used in createConnectionLoader
  refetchPolicy?: FetchPolicy; // Used in createConnectionLoader
};

// These are things this component receives from the createConnectionLoader HOC.
// Don't worry too much about them, but they are required as we need to know
// how to get our nodes from the connection.
export type RequiredProps = {
  children: (exposedProps: ExposedProps) => React.ReactNode;
};

type PropsFromCreateConnectionLoader = {
  isLoadingInitialQuery: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNodes: (props: Props) => any[];
};

type RelayProps = {
  relay: RelayPaginationProp;
};

export type Props = PropsFromCreateConnectionLoader & RequiredProps & OptionalProps & RelayProps;

export const DEFAULT_LIMIT = 50; // # of members to fetch with each request

const MIN_SCROLL_THRESHOLD = 1; // # of px from the bottom to begin fetching
const SCROLL_THRESHOLD = 100; // # of px from the bottom to begin fetching
const THROTTLED_MS = 100;

type ExposedProps = {
  connectionItems: GenericObject[];

  // Flag that denotes if content is loading. Use this to show a loading spinner if desired.
  isLoadingMore: boolean;

  // Flag denotes if content is initially loading.
  isLoadingInitialQuery: boolean;

  // Returns the viewport that has a scroll handler attached to it to fetch
  // for more members when the bottom of the container has been reached.
  renderListViewport: (content: React.ReactNode) => React.ReactNode;

  // Refetches the data
  reload: () => void;

  // Loads more content
  loadMore: () => void;

  // Flag denoting if there is more content to load
  hasMore: boolean;

  // Denotes if an error occurred during a fetch.
  error: string | null;
};

type State = {
  isLoadingMore: boolean;
  error: string | null;
};

export default class ConnectionLoader extends React.Component<Props, State> {
  // Element that acts as the viewport. This component manages that.
  scrollableContainerRef: RefObject<HTMLDivElement> = React.createRef();

  // Element that allows us to determine the height of the content that is scrolling.
  listContentRef: RefObject<HTMLDivElement> = React.createRef();

  limit: number;

  constructor(props: Props) {
    super(props);

    this.limit = props.limit || DEFAULT_LIMIT;

    this.state = {
      isLoadingMore: false,
      error: null,
    };
  }

  isCurrentScrollPositionAtBottom = (
    currentScrollPosition: number,
    viewportHeight: number,
    listHeight: number,
    padding?: number,
  ): boolean => {
    const maxScrollTop = listHeight - viewportHeight;
    const bufferAmt = padding || MIN_SCROLL_THRESHOLD;

    return currentScrollPosition >= maxScrollTop - bufferAmt;
  };

  /**
   * The handler that is run when the viewport is scrolled.
   *
   * Based on how far we are from the bottom, the fetch is either triggered or not.
   */
  // eslint-disable-next-line react/sort-comp
  fetchIfNearBottom = _.throttle(() => {
    const { hasMore, isLoading } = this.props.relay;

    // If we are currently loading more data, or have hit the end, don't fetch.
    if (isLoading() || !hasMore() || this.props.disablePagniation) return;

    // If we don't have the core element containers, no math can be done so don't go forward.
    if (!this.scrollableContainerRef.current || !this.listContentRef.current) return;

    const currentScrollPosition = this.scrollableContainerRef.current.scrollTop;
    const viewportHeight = this.scrollableContainerRef.current.offsetHeight;
    const listHeight = this.listContentRef.current.offsetHeight;

    const shouldFetchMore = this.isCurrentScrollPositionAtBottom(
      currentScrollPosition,
      viewportHeight,
      listHeight,
      SCROLL_THRESHOLD,
    );

    if (shouldFetchMore) {
      this.fetchMore();
    }
  }, THROTTLED_MS);

  /**
   * Fetches the next set of items from the previous cursor.
   * This is all handled by the pagination container.
   */
  fetchMore = (): void => {
    try {
      this.setState({ isLoadingMore: true });
      this.props.relay.loadMore(this.limit, () => {
        this.setState({ isLoadingMore: false });
        this.forceUpdate();
      });
      this.forceUpdate();
    } catch (error) {
      this.setState({ error: error as string });

      logException("ConnectionLoader", "fetchMore", "ConnectionLoader", error);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  loadMore = (): void => {
    const { hasMore, isLoading } = this.props.relay;

    // If we are currently loading more data, or have hit the end, don't fetch.
    if (isLoading() || !hasMore() || this.props.disablePagniation) return;

    this.fetchMore();
  };

  refetchConnection = (): void => {
    this.props.relay.refetchConnection(this.limit, () => {
      this.forceUpdate();
    });
  };

  renderListViewport = (content: React.ReactNode): React.ReactNode => {
    return (
      <div
        className={classnames(styles.root, this.props.className)}
        ref={this.scrollableContainerRef}
        onScroll={this.fetchIfNearBottom}
      >
        <div ref={this.listContentRef} className={this.props.contentClassName}>
          {content}
        </div>
      </div>
    );
  };

  render = (): React.ReactNode => {
    const exposedProps = {
      connectionItems: this.props.getNodes(this.props),
      isLoadingMore: this.props.isLoadingInitialQuery || this.state.isLoadingMore,
      isLoadingInitialQuery: this.props.isLoadingInitialQuery,
      renderListViewport: this.renderListViewport,
      reload: this.refetchConnection,
      loadMore: this.loadMore,
      hasMore: this.props.relay.hasMore(),
      error: this.state.error,
    };

    return this.props.children(exposedProps);
  };
}
