/**
 * @generated SignedSource<<5b9a5d70ba2a17400755ff4c6807d000>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SuspendUserUntilExpirationInput = {
  expirationTime: any;
  userId: string;
};
export type SuspendUserUntilExpirationMutation$variables = {
  input: SuspendUserUntilExpirationInput;
};
export type SuspendUserUntilExpirationMutation$data = {
  readonly suspendUserUntilExpiration: {
    readonly success: boolean;
    readonly user: {
      readonly punishmentStatus: {
        readonly hasLegalHold: boolean;
        readonly isPermanentlyBanned: boolean;
        readonly isSuspended: boolean;
        readonly latestSuspensionExpiration: any | null;
        readonly latestWarningTimestamp: any | null;
      } | null;
    };
  };
};
export type SuspendUserUntilExpirationMutation = {
  response: SuspendUserUntilExpirationMutation$data;
  variables: SuspendUserUntilExpirationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PunishmentStatus",
  "kind": "LinkedField",
  "name": "punishmentStatus",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuspended",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPermanentlyBanned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasLegalHold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestSuspensionExpiration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestWarningTimestamp",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SuspendUserUntilExpirationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SuspendUserUntilExpirationResponse",
        "kind": "LinkedField",
        "name": "suspendUserUntilExpiration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SuspendUserUntilExpirationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SuspendUserUntilExpirationResponse",
        "kind": "LinkedField",
        "name": "suspendUserUntilExpiration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "990603513b73b0ffc27a0aeaf0427ef8",
    "id": null,
    "metadata": {},
    "name": "SuspendUserUntilExpirationMutation",
    "operationKind": "mutation",
    "text": "mutation SuspendUserUntilExpirationMutation(\n  $input: SuspendUserUntilExpirationInput!\n) {\n  suspendUserUntilExpiration(input: $input) {\n    user {\n      punishmentStatus {\n        isSuspended\n        isPermanentlyBanned\n        hasLegalHold\n        latestSuspensionExpiration\n        latestWarningTimestamp\n      }\n      id\n    }\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "37eaf11e47d01d8ee37037e1f6f413cf";

export default node;
