import React from "react";
import { animated, Transition } from "react-spring";

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  type?: "fade-in" | "fade-out" | "fade-in-out";
  show: boolean;
  children: React.ReactNode;
  delayEnterMs?: number;
  durationMs?: number;
};

export default class AnimatedFadeInOut extends React.Component<Props> {
  render = (): React.ReactNode => {
    const {
      type = "fade-in-out",
      show,
      children,
      delayEnterMs,
      durationMs,
      ...remainingProps
    } = this.props;

    const transitionConfigMap = {
      "fade-in": {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: !show ? undefined : { opacity: 0 },
        trail: delayEnterMs,
      },
      "fade-out": {
        from: { opacity: 1 },
        enter: undefined,
        leave: { opacity: 0 },
      },
      "fade-in-out": {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        trail: delayEnterMs,
      },
    };

    const transitionConfig = transitionConfigMap[type];

    return (
      <Transition
        native
        items={show}
        {...transitionConfig}
        config={{
          duration: durationMs,
        }}
      >
        {(styles, item) => {
          if (!item) return null;

          return (
            <animated.div {...remainingProps} style={styles}>
              {children}
            </animated.div>
          );
        }}
      </Transition>
    );
  };
}
