/**
 * @generated SignedSource<<77b28ca5a81de420a090ef8e348672f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfileFragment_query$data = {
  readonly outgoingPendingClubInvites: ReadonlyArray<{
    readonly clubId: string;
    readonly inviteId: string;
    readonly recipientId: string;
    readonly senderId: string;
  }>;
  readonly " $fragmentType": "UserProfileFragment_query";
};
export type UserProfileFragment_query$key = {
  readonly " $data"?: UserProfileFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfileFragment_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PendingClubInvite",
      "kind": "LinkedField",
      "name": "outgoingPendingClubInvites",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inviteId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "senderId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipientId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clubId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4e1a92c949b0c8a62b59d43472c696c8";

export default node;
