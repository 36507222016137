/**
 * @generated SignedSource<<6a9ae769935461b53411dd7868762d95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserForAccountResetInput = {
  displayName?: string | null;
  nianticId?: string | null;
};
export type UpdateUserForAccountResetMutation$variables = {
  input: UpdateUserForAccountResetInput;
};
export type UpdateUserForAccountResetMutation$data = {
  readonly updateUserForAccountReset: {
    readonly me: {
      readonly displayName: string;
      readonly username: string;
    };
  };
};
export type UpdateUserForAccountResetMutation = {
  response: UpdateUserForAccountResetMutation$data;
  variables: UpdateUserForAccountResetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserForAccountResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserForAccountResetResponse",
        "kind": "LinkedField",
        "name": "updateUserForAccountReset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserForAccountResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserForAccountResetResponse",
        "kind": "LinkedField",
        "name": "updateUserForAccountReset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4d8fb1a480b24aa3c109b18db05e17f",
    "id": null,
    "metadata": {},
    "name": "UpdateUserForAccountResetMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserForAccountResetMutation(\n  $input: UpdateUserForAccountResetInput!\n) {\n  updateUserForAccountReset(input: $input) {\n    me {\n      username\n      displayName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0a12bf6933b2486d85d2452cecc693e1";

export default node;
