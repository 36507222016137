import React from "react";

import { ChannelSlideConfig } from "pages/Clubs/providers/ClubsPageViewProvider/slides";
import useCampfirePage from "providers/CampfirePageProvider/useCampfirePage";

import { SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT } from "constants/springAnimations";

import CampfirePage from "common/components/CampfirePage";
import DelayedUnmount from "common/components/DelayedUnmount";
import { UINavSlide } from "common/components/UINav";

import ClubActivityFeedPage from "pages/ClubActivityFeed";
import ClubChannelPage from "pages/ClubChannel";

import styles from "./styles.scss";

type Props = {
  isActiveView: boolean;
  slideConfig: ChannelSlideConfig;
};

const ClubsPageSlideRenderer = (props: Props): React.ReactElement | null => {
  const campfirePage = useCampfirePage();
  const { isActiveView, slideConfig } = props;
  const { slideId, pageType, clubId, channelId = "" } = slideConfig;

  // Renders a mask on top of the slide to intercept pointer events during animations.
  const renderClickMask = () => (
    <DelayedUnmount springConfig={SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT} isOpen={!isActiveView}>
      <div className={styles.interceptPointerDuringAnimation} />
    </DelayedUnmount>
  );

  if (pageType === "channel-chat") {
    return (
      <UINavSlide key={slideId} slideId={slideId}>
        {({ hideSlide }) => (
          <CampfirePage isActivePage={campfirePage.isInteractive}>
            {renderClickMask()}
            <ClubChannelPage
              goBack={() => hideSlide(slideId)}
              clubId={clubId}
              channelId={channelId}
            />
          </CampfirePage>
        )}
      </UINavSlide>
    );
  }

  if (pageType === "club-feed") {
    return (
      <UINavSlide key={slideId} slideId={slideId}>
        {({ hideSlide }) => (
          <CampfirePage isActivePage={campfirePage.isInteractive}>
            {renderClickMask()}
            <ClubActivityFeedPage goBack={() => hideSlide(slideId)} clubId={clubId} />
          </CampfirePage>
        )}
      </UINavSlide>
    );
  }

  return null;
};

export default ClubsPageSlideRenderer;
