import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";

import { ActivityPageStateProvider } from "pages/MapAndActivities/providers/ActivityPageStateProvider";
import { CachedPosition } from "providers/CurrentLocationProvider";
import withCurrentLocation, {
  WithCurrentLocationProps,
} from "providers/CurrentLocationProvider/withCurrentLocation";
import withSelectedRealityChannel, {
  WithSelectedRealityChannelProps,
} from "providers/SelectedRealityChannelProvider/withSelectedRealityChannel";

import { RealityChannelMetadata } from "common/stores/SelectedRealityChannelStore";
import { delay } from "common/utils/delay";
import {
  computeBoundsOfEqualRadiusFromCenter,
  convertGoogleLatLngBoundsToLiteralFormat,
} from "common/utils/googleMaps/bounds";
import { embeddedClientInfo, isEmbedded } from "common/utils/webInterop";
import { DEFAULT_BOUNDS, DEFAULT_LOCATION_GOOGLE } from "constants/map";

import EmbeddedPageWithTopBarWrapper from "common/components/EmbeddedPageWithTopBarWrapper";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import PageLoadError from "common/components/PageLoadError";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import { RealityChannel as RealityChannelForACMap } from "pages/MapAndActivities/components/ActivityCenterMapQR";
import ActivityCenterView from "pages/MapAndActivities/components/ActivityCenterView";
import { RealityChannelForAC } from "pages/RealityChannelActivityCenter";
import { CURRENT_LOCATION_RADIUS_METERS } from "pages/RealityChannelActivityCenter/utils/common";

import {
  MapAndActivities_embed_page_Query$data as MapAndActivitiesEmbedPageQueryResponse,
  MapAndActivities_embed_page_Query$variables as MapAndActivitiesEmbedPageQueryVariables,
} from "__generated__/MapAndActivities_embed_page_Query.graphql";
import {
  MapAndActivities_page_Query$data as MapAndActivitiesPageQueryResponse,
  MapAndActivities_page_Query$variables as MapAndActivitiesPageQueryVariables,
} from "__generated__/MapAndActivities_page_Query.graphql";

// Lazy Load Main View
const MapAndActivitiesPageMainContent = React.lazy(
  () => import("pages/MapAndActivities/components/MapAndActivitiesPageMainContent"),
);

type PreliminaryData = {
  locationData: CachedPosition | void;
};

type Props = WithTranslation & WithCurrentLocationProps & WithSelectedRealityChannelProps & {};

type State = {
  readyToMakeMainQuery: boolean;
};

type QRProps = MapAndActivitiesPageQueryResponse & {
  me: MapAndActivitiesPageQueryResponse["me"];
};

type EmbedQRProps = MapAndActivitiesEmbedPageQueryResponse & {
  me: MapAndActivitiesEmbedPageQueryResponse["me"];
};

type RealityChannelForMapAndAC = RealityChannelForACMap & RealityChannelForAC;

// PLEASE DO NOT USE THIS FRAGMENT OUTSIDE OF THIS COMPONENT!
// IT IS MERELY HERE AS A CONVENIENCE TO NOT HAVE THE SAME FRAGMENT FIELDS TWICE.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RealityChannelFragment = graphql`
  fragment MapAndActivities_realityChannel on RealityChannel {
    id
    name
    iconURL
    sources {
      name
      dropTypes
    }
    metadata {
      isFirstParty
      game
      googleMapsPlatformMapId
      splashImageUrl
    }
    welcomeBanner {
      title
      bodyMd
      imgURL
      location {
        latitude
        longitude
      }
    }
  }
`;

// TODO: Refactor to a seperate page component if this starts to get outta hand.
const MAPANDACTIVITIES_EMBEDDED_PAGE_QUERY = graphql`
  query MapAndActivities_embed_page_Query($gameName: String!) {
    ...MapAndActivitiesPageMainContent_query
    realityChannelForEmbedGame(name: $gameName) {
      ...MapAndActivities_realityChannel @relay(mask: false)
    }
    me {
      ...MapAndActivitiesPageMainContent_me
      ...ActivityCenterView_me
    }
  }
`;

const MAPANDACTIVITIES_PAGE_QUERY = graphql`
  query MapAndActivities_page_Query(
    $realityChannelsInLatLngBoundsInput: RealityChannelsInLatLngBoundsInput!
  ) {
    ...MapAndActivitiesPageMainContent_query
    realityChannelsInLatLngBounds(input: $realityChannelsInLatLngBoundsInput) {
      ...MapAndActivities_realityChannel @relay(mask: false)
    }
    me {
      ...MapAndActivitiesPageMainContent_me
      ...ActivityCenterView_me
    }
  }
`;

const FETCH_REALITY_CHANNELS_IN_LAT_LNG_BOUNDS_RADIUS_METERS = 3000;

class MapAndActivitiesPage extends React.Component<Props, State> {
  initialQueryVariables: MapAndActivitiesPageQueryVariables;

  embedInitialQueryVariables: MapAndActivitiesEmbedPageQueryVariables;

  constructor(props: Props) {
    super(props);

    this.initialQueryVariables = {
      realityChannelsInLatLngBoundsInput: {
        bounds: DEFAULT_BOUNDS,
      },
    };

    this.embedInitialQueryVariables = {
      gameName: embeddedClientInfo.Game || "",
    };

    this.state = {
      readyToMakeMainQuery: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    const variablesForMainQuery = await this.getVariablesForQuery();

    this.initialQueryVariables = variablesForMainQuery;

    // Now that we have the data we need, we can mark the QR ready to make the main query.
    this.setState({
      readyToMakeMainQuery: true,
    });
  };

  getVariablesForQuery = async (): Promise<MapAndActivitiesPageQueryVariables> => {
    // First, lets resolve some things we need before the main query.
    // - Need location since that's used to determine reality channels
    const prelimData = await this.resolvePreliminaryData();
    const { locationData } = prelimData;

    // Set the query variables to use for the main query
    const currentLocation =
      locationData && locationData.coords
        ? {
            lat: locationData.coords?.latitude,
            lng: locationData.coords?.longitude,
          }
        : DEFAULT_LOCATION_GOOGLE;
    const boundsToFetch = computeBoundsOfEqualRadiusFromCenter(
      FETCH_REALITY_CHANNELS_IN_LAT_LNG_BOUNDS_RADIUS_METERS,
      currentLocation,
    );
    const boundsInMapQueryFormat = convertGoogleLatLngBoundsToLiteralFormat(boundsToFetch);

    return {
      realityChannelsInLatLngBoundsInput: {
        bounds: boundsInMapQueryFormat,
      },
    };
  };

  reInit = (): void => {
    // Change the variables to a new object reference, and then trigger state change to induce
    // a re-render. The QR will fetch since the variables have changed.
    this.initialQueryVariables = {
      realityChannelsInLatLngBoundsInput: {
        bounds: DEFAULT_BOUNDS,
      },
    };

    this.embedInitialQueryVariables = {
      gameName: embeddedClientInfo.Game || "",
    };

    this.forceUpdate();
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  resolveLocation = async (): Promise<CachedPosition | void> => {
    // At most, give us 3000ms to resolve the location.
    const geolocationPromise = Promise.race([
      delay(CURRENT_LOCATION_RADIUS_METERS, "Geolocation failed to load within 3000ms"),
      this.props.currentLocationProvider.getCurrentPosition(),
    ]);

    return geolocationPromise;
  };

  resolvePreliminaryData = async (): Promise<PreliminaryData> => {
    const locationDataPromise = this.resolveLocation();

    const responses = await Promise.allSettled([locationDataPromise]);

    const locationDataResponse = responses[0];

    return {
      locationData:
        locationDataResponse.status === "fulfilled" ? locationDataResponse.value : undefined,
    };
  };

  renderProvidersForPage = (
    qrPropsMe:
      | MapAndActivitiesPageQueryResponse["me"]
      | MapAndActivitiesEmbedPageQueryResponse["me"],
    possibleRcs: RealityChannelMetadata[],
    children: React.ReactNode,
  ): React.ReactNode => {
    const { selectedRealityChannelId, changeSelectedRealityChannel } =
      this.props.selectedRealityChannelProvider;

    return (
      <ActivityCenterView me={qrPropsMe}>
        <ActivityPageStateProvider
          possibleRealityChannels={possibleRcs}
          selectedRealityChannelId={selectedRealityChannelId}
          changeSelectedRealityChannel={changeSelectedRealityChannel}
        >
          {children}
        </ActivityPageStateProvider>
      </ActivityCenterView>
    );
  };

  renderPageForEmbeddedCampfire = (embedQrProps: EmbedQRProps): React.ReactNode => {
    const selectedRC = embedQrProps.realityChannelForEmbedGame;

    const possibleRcs = [embedQrProps.realityChannelForEmbedGame];
    const possibleRcsAsRCMetadata = possibleRcs.map((rc) => ({
      id: rc.id,
      name: rc.name,
      iconURL: rc.iconURL,
    }));

    return this.renderProvidersForPage(
      embedQrProps.me,
      possibleRcsAsRCMetadata,
      <MapAndActivitiesPageMainContent
        query={embedQrProps}
        me={embedQrProps.me}
        selectedRC={selectedRC || null}
        getVariablesForQuery={this.getVariablesForQuery}
        possibleRcs={possibleRcs}
      />,
    );
  };

  renderPageForStandaloneCampfire = (qrProps: QRProps): React.ReactNode => {
    const { selectedRealityChannelId } = this.props.selectedRealityChannelProvider;

    const selectedRC = qrProps.realityChannelsInLatLngBounds.find((rc) => {
      return rc.id === selectedRealityChannelId;
    });
    const possibleRcs = qrProps.realityChannelsInLatLngBounds;
    const possibleRcsAsRCMetadata = possibleRcs.map((rc) => ({
      id: rc.id,
      name: rc.name,
      iconURL: rc.iconURL,
    }));

    return this.renderProvidersForPage(
      qrProps.me,
      possibleRcsAsRCMetadata,
      <MapAndActivitiesPageMainContent
        query={qrProps}
        me={qrProps.me}
        selectedRC={selectedRC || null}
        getVariablesForQuery={this.getVariablesForQuery}
        possibleRcs={qrProps.realityChannelsInLatLngBounds as RealityChannelForMapAndAC[]}
      />,
    );
  };

  render = (): React.ReactNode => {
    const { readyToMakeMainQuery } = this.state;

    const { isDeterminingRealityChannel } = this.props.selectedRealityChannelProvider;
    const query = isEmbedded ? MAPANDACTIVITIES_EMBEDDED_PAGE_QUERY : MAPANDACTIVITIES_PAGE_QUERY;
    const variables = isEmbedded ? this.embedInitialQueryVariables : this.initialQueryVariables;

    return (
      <UIErrorBoundary onRetry={this.onRetry}>
        <Suspense fallback={<LoadingPageDefault />}>
          <EmbeddedPageWithTopBarWrapper>
            {!readyToMakeMainQuery && <LoadingPageDefault />}
            {readyToMakeMainQuery && (
              <QueryRenderer
                environment={environment}
                query={query}
                variables={variables}
                render={({ props, error }) => {
                  const qrProps = props;
                  const isError = !!error;
                  const isLoading = !isError && !props;

                  // If the query is loading or we still haven't resolved an RC yet, show a loading
                  // UI.
                  if (isLoading || isDeterminingRealityChannel) {
                    return <LoadingPageDefault />;
                  }

                  if (isError) {
                    return (
                      <PageLoadError
                        errorMessage={this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
                        reload={this.reInit}
                      />
                    );
                  }

                  if (isEmbedded) {
                    return this.renderPageForEmbeddedCampfire(qrProps as EmbedQRProps);
                  }

                  return this.renderPageForStandaloneCampfire(qrProps as QRProps);
                }}
              />
            )}
          </EmbeddedPageWithTopBarWrapper>
        </Suspense>
      </UIErrorBoundary>
    );
  };
}

const SelectedRealityChannelConnected = withSelectedRealityChannel(MapAndActivitiesPage);
const CurrentLocationConnected = withCurrentLocation(SelectedRealityChannelConnected);

export default withTranslation()(CurrentLocationConnected);
