/**
 * Returns a base64 and url safe string representing an object.
 */
export const serializeActionData = (actionData: GenericObject): string => {
  const base64Encoded = btoa(JSON.stringify(actionData));

  return encodeURIComponent(base64Encoded);
};

/**
 * Returns a JSON object from a string serialized as result of serializeActionData.
 */
export const deserializeActionData = (serializedActionData: string): GenericObject => {
  try {
    const decodedBase64 = decodeURIComponent(serializedActionData);
    const actionDataJsonString = atob(decodedBase64);

    return JSON.parse(actionDataJsonString);
  } catch (e) {
    return {};
  }
};
