import classnames from "classnames";
import React from "react";

import ContentWithHeader, {
  Props as ContentWithHeaderProps,
} from "common/components/ContentWithHeader";

import styles from "./styles.scss";

type Props = ContentWithHeaderProps;

export default class UINavSlideView extends React.Component<Props> {
  render = (): React.ReactNode => {
    const { className, ...remainingProps } = this.props;

    return <ContentWithHeader {...remainingProps} className={classnames(styles.root, className)} />;
  };
}
