/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { AppStateConsumer, ExposedProps } from "providers/AppStateProvider";

export type WithAppStateProps = {
  appState: ExposedProps;
};

// eslint-disable-next-line max-len
export default <P extends WithAppStateProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithAppStateProps>): React.ReactElement => (
    <AppStateConsumer>
      {(appState) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component appState={appState} {...props} />
      )}
    </AppStateConsumer>
  );
};
