import { getCurrentGameInfo } from "common/utils/nianticGame";
import { isEmbedded } from "common/utils/webInterop";
import GAMES_BY_SHORT_CODE from "constants/nianticGame";

import styles from "./index.scss";

const getAdjustmentForDevice = (): string => {
  if (window.navigator && window.navigator.userAgent) {
    const normalizedUserAgent = window.navigator.userAgent.toLowerCase();
    const currentGameInfo = getCurrentGameInfo();
    const isAndroid10 = normalizedUserAgent.includes("android 10");
    const gameIsIngress = currentGameInfo
      ? currentGameInfo.gameShortName === GAMES_BY_SHORT_CODE.ING.gameShortName
      : false;

    // TODO: Remove this when Ingress makes the status bar dismissable.
    // If we are running in ingress and the device is running android 10, move the
    // entire app down by some amount.
    if (isAndroid10 && gameIsIngress) {
      return styles.adjustedForAndroid10PixelXl;
    }
  }

  return "";
};

export default (): void => {
  const adjustment = getAdjustmentForDevice();

  if (isEmbedded && !!adjustment) {
    document.body.classList.add(adjustment);
  }
};
