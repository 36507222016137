import React, { useEffect } from "react";
import { graphql, PreloadedQuery, useQueryLoader } from "react-relay";

import { DEFAULT_BOUNDS } from "constants/map";

import QueryLoadMonitor from "common/components/RealityChannelSelection/queryLoadMonitor";

import {
  preloader_RealityChannelSelection_Query as RealityChannelSelectionPreloaderQuery,
  preloader_RealityChannelSelection_Query$data as RealityChannelSelectionPreloaderQueryResponse,
} from "__generated__/preloader_RealityChannelSelection_Query.graphql";

type ExposedProps = {
  queryReference: PreloadedQuery<RealityChannelSelectionPreloaderQuery> | null | void;
};

export type Props = {
  onLoaded: (data: RealityChannelSelectionPreloaderQueryResponse) => void;
  children: (exposedProps: ExposedProps) => React.ReactElement | null;
};

export const PRELOADED_QUERY = graphql`
  query preloader_RealityChannelSelection_Query($input: RealityChannelsInLatLngBoundsInput!) {
    realityChannelsInLatLngBounds(input: $input) {
      id
      name
      iconURL
      metadata {
        displayRank
      }
    }
  }
`;

const RealityChannelSelectionPreloader = (props: Props): React.ReactElement | null => {
  const [queryReference, loadQuery] =
    useQueryLoader<RealityChannelSelectionPreloaderQuery>(PRELOADED_QUERY);

  useEffect(() => {
    const queryVariables = {
      input: { bounds: DEFAULT_BOUNDS },
    };

    loadQuery(queryVariables);
  }, [loadQuery]);

  return (
    <>
      {queryReference && (
        <QueryLoadMonitor queryReference={queryReference} onLoaded={props.onLoaded} />
      )}
      {props.children({ queryReference })}
    </>
  );
};

export default RealityChannelSelectionPreloader;
