import { CoachmarkProvider } from "@niantic/react-coachmark";
import React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { ActivityProvider } from "providers/ActivityProvider";
import { BlockedUsersProvider } from "providers/BlockedUsersProvider";
import { FeatureFlagProvider } from "providers/FeatureFlagProvider";
import { LocationShareProvider } from "providers/LocationShareProvider";
import { MessageTaskQueueProvider } from "providers/MessageTaskQueueProvider";
import { NavigationProvider } from "providers/NavigationProvider";
import { PubnubProvider } from "providers/PubnubProvider";
import { PushNotificationProvider } from "providers/PushNotificationProvider";
import { ReportDialogProvider } from "providers/ReportDialogProvider";
import { ReportedEntityProvider } from "providers/ReportedEntityProvider";
import { SelectedRealityChannelProvider } from "providers/SelectedRealityChannelProvider";
import { TeamUpProvider } from "providers/TeamUpProvider";
import {
  UserInteractionHistoryProvider,
  UserInteractionHistoryConsumer,
} from "providers/UserInteractionHistoryProvider";
import { UserProfileProvider } from "providers/UserProfileProvider";

import QATestingStore from "common/stores/QATestingStore";
import { APP_COACHMARK_FLOWS } from "constants/coachmark/flows";

import LoadGoogleMapsScript from "common/components/LoadGoogleMapsScript";

import GlobalMessageHandlers from "boot-loader/components/GlobalMessageHandlers";
import GlobalSignalHandlers from "boot-loader/components/GlobalSignalHandlers";

import { AuthenticatedServiceProviders_me$data as AuthenticatedServiceProvidersMe } from "__generated__/AuthenticatedServiceProviders_me.graphql";
import { AuthenticatedServiceProviders_query$data as AuthenticatedServiceProvidersQuery } from "__generated__/AuthenticatedServiceProviders_query.graphql";

type Props = {
  query: AuthenticatedServiceProvidersQuery;
  me: AuthenticatedServiceProvidersMe;
  children: React.ReactNode;
};

type State = {};

class AuthenticatedServiceProviders extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { children, me } = this.props;

    // Attach to both signal and message channel group on user.
    const channelGroups = [me.signalsChannelGroupName, me.messagesChannelGroupName];

    const featureFlags = this.props.me.featureFlags.reduce(
      (map: { [key: string]: boolean }, featureFlagName) => {
        // eslint-disable-next-line no-param-reassign
        map[featureFlagName] = true;
        return map;
      },
      {},
    );

    // Convert array of users to a map for speed.
    const blockedByUserMap: Record<string, boolean> = this.props.me.blockedByUsers
      .filter((user) => Boolean(user.id))
      .reduce((result: Record<string, boolean>, user) => {
        return { ...result, [user.id]: true };
      }, {});

    // Get the location share update rates configured from the BE
    const { clientSetting } = this.props.query;
    const { slowLocationPushIntervalMs, fastLocationPushIntervalMs } = clientSetting;

    // Parse the string (since its a string wtf), and check if incorrectly configured.
    const slowLocationPushIntervalMsParsed = parseInt(slowLocationPushIntervalMs, 10);
    const fastLocationPushIntervalMsParsed = parseInt(fastLocationPushIntervalMs, 10);
    const defaultLocationShareUpdateRate = !Number.isNaN(slowLocationPushIntervalMsParsed)
      ? slowLocationPushIntervalMsParsed
      : undefined;
    const acceleratedLocationShareUpdateRate = !Number.isNaN(fastLocationPushIntervalMsParsed)
      ? fastLocationPushIntervalMsParsed
      : undefined;

    const disableCoachmarksForQA = QATestingStore.get("disableServerCoachmarks");

    return (
      // NOTE: If we ever need to move this for loading performance, we will need to modify
      // the <Map/> components to handle the potential case google maps api is not ready, or else
      // wrap other nodes in the React tree with this LoadGoogleMapsScript component so that the
      // <Map /> never renders until the script is loaded.
      <LoadGoogleMapsScript>
        <UserInteractionHistoryProvider me={this.props.me}>
          <UserInteractionHistoryConsumer>
            {({ interactionHistory, markInteraction }) => (
              <CoachmarkProvider
                flows={APP_COACHMARK_FLOWS}
                coachmarks={disableCoachmarksForQA ? {} : interactionHistory}
                storeName="CoachmarkStore"
                markCoachmarkCompleteOnServer={markInteraction}
              >
                <BlockedUsersProvider
                  blockedUsers={me.blockedUsers}
                  blockedByUserMap={blockedByUserMap}
                >
                  <FeatureFlagProvider featureFlags={featureFlags}>
                    <LocationShareProvider
                      defaultUpdateRate={defaultLocationShareUpdateRate}
                      acceleratedUpdateRate={acceleratedLocationShareUpdateRate}
                    >
                      <ReportedEntityProvider>
                        <ReportDialogProvider>
                          <ActivityProvider userId={me.id}>
                            <PushNotificationProvider userId={me.id}>
                              <PubnubProvider
                                userId={me.id}
                                pubnubAuthKey={me.pubnubAuthKey}
                                channelGroups={channelGroups}
                                userChannel={me.pubnubUserMessageChannelName}
                              >
                                {/* Channel Group Signal Handling for the user lives here! */}
                                <GlobalSignalHandlers me={me} />

                                {/* Channel Group Message Handling for the user lives here! */}
                                <GlobalMessageHandlers me={me} />

                                <MessageTaskQueueProvider>
                                  <NavigationProvider>
                                    <UserProfileProvider>
                                      <SelectedRealityChannelProvider>
                                        <TeamUpProvider>{children}</TeamUpProvider>
                                      </SelectedRealityChannelProvider>
                                    </UserProfileProvider>
                                  </NavigationProvider>
                                </MessageTaskQueueProvider>
                              </PubnubProvider>
                            </PushNotificationProvider>
                          </ActivityProvider>
                        </ReportDialogProvider>
                      </ReportedEntityProvider>
                    </LocationShareProvider>
                  </FeatureFlagProvider>
                </BlockedUsersProvider>
              </CoachmarkProvider>
            )}
          </UserInteractionHistoryConsumer>
        </UserInteractionHistoryProvider>
      </LoadGoogleMapsScript>
    );
  };
}

const FragmentContainer = createFragmentContainer(AuthenticatedServiceProviders, {
  query: graphql`
    fragment AuthenticatedServiceProviders_query on Query {
      clientSetting {
        slowLocationPushIntervalMs
        fastLocationPushIntervalMs
      }
    }
  `,
  me: graphql`
    fragment AuthenticatedServiceProviders_me on User {
      id
      signalsChannelGroupName
      messagesChannelGroupName
      pubnubUserMessageChannelName
      blockedUsers {
        id
        displayName
        avatarUrl
        username
      }
      blockedByUsers {
        id
      }
      pubnubAuthKey
      featureFlags
      ...GlobalSignalHandlers_me
      ...GlobalMessageHandlers_me
      ...UserInteractionHistoryProvider_me
    }
  `,
});

export default FragmentContainer;
