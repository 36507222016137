import campfireLogo from "assets/images/campfire/campfire-logo.png";
import ingressLogo from "assets/images/niantic/games/ingress/logo_image.png";
import nbaAllWorldLogo from "assets/images/niantic/games/nbaallworld/logo_image.png";
import peridotLogo from "assets/images/niantic/games/peridot/logo_image.png";
import pgoLogo from "assets/images/niantic/games/pgo/logo_image.png";
import pikminLogo from "assets/images/niantic/games/pikmin/logo_image.png";
import superblueLogo from "assets/images/niantic/games/superblue/logo_image.png";

// List of existingRealityChannels as of 7/25 so we can exclude them from having a "red dot" indicator
export const existingRealityChannels = ["Pokémon GO", "NBA All-World", "Peridot", "Ingress"];

export type GameShortCode =
  | "ING"
  | "PGO"
  | "SAMPLE"
  | "PIKMIN"
  | "PERIDOT"
  | "CAMPFIRE"
  | "NBAALLWORLD"
  | "MHNOW"
  | "SUPERBLUE";
export type DeprecatedGameShortCode = "ICH" | "NIASOCIAL";

export type GameShortCodeWithBackwardsCompat = GameShortCode | DeprecatedGameShortCode;

export type GameInfo = {
  gameName: string;
  gameShortName: string;
  logoUrl: string;
  supportsRegionalChat: boolean;
};

export const GAME_SHORT_CODES: Record<
  GameShortCodeWithBackwardsCompat,
  GameShortCodeWithBackwardsCompat
> = {
  ING: "ING",
  PGO: "PGO",
  SAMPLE: "SAMPLE",
  // Ichigo backwards compat
  ICH: "ICH",
  PIKMIN: "PIKMIN",
  PERIDOT: "PERIDOT",
  NIASOCIAL: "NIASOCIAL",
  CAMPFIRE: "CAMPFIRE",
  NBAALLWORLD: "NBAALLWORLD",
  MHNOW: "MHNOW",
  SUPERBLUE: "SUPERBLUE",
};

// ING
const INGRESS: GameInfo = {
  gameName: "Ingress",
  gameShortName: "Ingress",
  logoUrl: ingressLogo,
  supportsRegionalChat: true,
};

// PGO
const POKEMON_GO: GameInfo = {
  gameName: "Pok\u00E9mon GO",
  gameShortName: "Pok\u00E9mon GO",
  logoUrl: pgoLogo,
  supportsRegionalChat: false,
};

// PERIDOT
const PERIDOT: GameInfo = {
  gameName: "Peridot",
  gameShortName: "Peridot",
  logoUrl: peridotLogo,
  supportsRegionalChat: false,
};

// PIKMIN
const PIKMIN: GameInfo = {
  gameName: "Pikmin Bloom",
  gameShortName: "Pikmin Bloom",
  logoUrl: pikminLogo,
  supportsRegionalChat: false,
};

// CAMPFIRE
const CAMPFIRE: GameInfo = {
  gameName: "Campfire",
  gameShortName: "Campfire",
  logoUrl: campfireLogo,
  supportsRegionalChat: false,
};

// NBAALLWORLD
const NBAALLWORLD: GameInfo = {
  gameName: "NBA All-World",
  gameShortName: "NBA All-World",
  logoUrl: nbaAllWorldLogo,
  supportsRegionalChat: false,
};

// MHNOW
const MHNOW: GameInfo = {
  gameName: "Monster Hunter Now",
  gameShortName: "Monster Hunter Now",
  logoUrl: "", // TODO
  supportsRegionalChat: false,
};

// SUPERBLUE
const SUPERBLUE: GameInfo = {
  gameName: "JR Reality",
  gameShortName: "JR Reality",
  logoUrl: superblueLogo,
  supportsRegionalChat: false,
};

// Please check if getAllGameShortCodes needs to be updated when modifying this list.
const GAMES = {
  ING: INGRESS,
  PGO: POKEMON_GO,
  PERIDOT,
  PIKMIN,
  ICH: PIKMIN,
  NIASOCIAL: CAMPFIRE,
  CAMPFIRE,
  NBAALLWORLD,
  MHNOW,
  SUPERBLUE,
} as Record<GameShortCodeWithBackwardsCompat, GameInfo>;

export default GAMES;
