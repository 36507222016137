import { PositionOptions, Position } from "@capacitor/geolocation";

import logException from "common/analytics/exceptions";
import Geolocation from "common/utils/geolocation";

// https://capacitorjs.com/docs/apis/geolocation#getcurrentposition
export const validator = (data: Position): boolean => {
  try {
    const hasCoords = !!data.coords;

    const hasValidLat =
      data.coords && data.coords.latitude !== undefined && !Number.isNaN(data.coords.latitude);

    const hasValidLong =
      data.coords && data.coords.longitude !== undefined && !Number.isNaN(data.coords.longitude);

    return hasCoords && hasValidLat && hasValidLong;
  } catch (error) {
    logException("validating geolocationPosition", "validator", "getCurrentPosition", error);
    return false;
  }
};

export default (options?: PositionOptions): Promise<Position> => {
  return Geolocation.getCurrentPosition(options);
};
