import React from "react";

import UINavSlide, { Props as UINavSlideProps } from "./components/UINavSlide";
import UINavSlides, { Props as UINavSlidesProps } from "./components/UINavSlides";

type Props = {
  children: React.ReactNode;
};

export type HookReturnType = {
  getRootContent: () => React.ReactNodeArray;
  getSlides: () => Array<React.Component<UINavSlideProps>>;
};

function useUINavChildren(props: Props): HookReturnType {
  /**
   * Gets all the react children that are NOT UINavSlide
   */
  const getRootContent = (): React.ReactNodeArray => {
    return React.Children.toArray(props.children).filter((child) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return child.type !== UINavSlides;
    });
  };

  /**
   * Gets the react child that is the UINavSlides
   */
  const getNavSlidesComponentFromChildren = (): React.Component<UINavSlidesProps> => {
    return React.Children.toArray(props.children).find((child) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return child.type === UINavSlides;
    }) as React.Component<UINavSlidesProps>;
  };

  /**
   * Gets all the react children that are UINavSlide
   */
  const getNavSlideComponentsFromChildren = (): Array<React.Component<UINavSlideProps>> => {
    const uiNavSlidesComponent = getNavSlidesComponentFromChildren();

    // If no UINavSlides, return nothing. This wrapper is required.
    if (!uiNavSlidesComponent) {
      return [];
    }

    return React.Children.toArray(uiNavSlidesComponent.props.children).filter((child) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return child.type === UINavSlide;
    }) as Array<React.Component<UINavSlideProps>>;
  };

  return {
    getRootContent,
    getSlides: getNavSlideComponentsFromChildren,
  };
}

export default useUINavChildren;
