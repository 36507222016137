import _ from "lodash";
import React from "react";

import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import { _actionEventEmitter, embeddedClientInfo } from "common/utils/webInterop";
import AppInterop from "common/utils/webInterop/app";
import GAMES_BY_SHORT_CODE, { GameInfo, GameShortCode } from "constants/nianticGame";

type Props = WithToastProps & {};

type State = {};

const EXTERNAL_NOTIFICATION_EVENT_NAME = "externalNotification";

class ExternalNotificationActionHandler extends React.Component<Props, State> {
  componentDidMount = (): void => {
    _actionEventEmitter.on(EXTERNAL_NOTIFICATION_EVENT_NAME, this.handleExternalNotificationEvent);
  };

  componentWillUnmount = (): void => {
    _actionEventEmitter.off(EXTERNAL_NOTIFICATION_EVENT_NAME, this.handleExternalNotificationEvent);
  };

  clickExternalNotification = (): void => {
    AppInterop.logout();
  };

  handleExternalNotificationEvent = (header = "", message = "", duration?: number): void => {
    const gameShortCode = _.get(embeddedClientInfo, "Game") as GameShortCode;
    const gameInfo: GameInfo | undefined = GAMES_BY_SHORT_CODE[gameShortCode];
    const gameName = gameInfo ? gameInfo.gameName : "Game";
    const gameLogo = gameInfo ? gameInfo.logoUrl : "";

    const toastInfo = {
      headerText: header,
      messageText: message,
      srcImage: gameLogo,
      srcText: gameName,
      duration,
      onClick: this.clickExternalNotification,
    };

    this.props.toastProvider.showMessageToast(toastInfo);
  };

  render = (): React.ReactNode => {
    return null;
  };
}

export default withToast(ExternalNotificationActionHandler);
