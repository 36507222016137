import React from "react";

export type Props = {
  w?: number;
  h?: number;
  className?: string;
};

export default class UISpacer extends React.Component<Props> {
  render = (): React.ReactNode => {
    const { w, h, className } = this.props;

    // If neither a width or height are provided, clearly, we don't want to render anything.
    if (!w && !h) {
      return null;
    }

    return (
      <div
        className={className}
        style={{
          height: w ? undefined : `${h}px`,
          width: h ? "100%" : `${w}px`,
          flex: "0 0 auto",
        }}
      />
    );
  };
}
