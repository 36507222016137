import classnames from "classnames";
import { logoFacebook, logoApple } from "ionicons/icons";
import React from "react";

import { ReactComponent as GIcon } from "assets/icons/g-icon.svg";

import UIIcon from "common/components/UIIcon";

import styles from "./styles.scss";

type Props = {
  provider: SupportedOAuthProvider;
  mode:
    | "normal" // Standard icon style
    | "button"; // Icon style for use on colored button
  className?: string;
};

type State = {};

export default class AuthProviderIcon extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    switch (this.props.provider) {
      case "google":
        return <GIcon className={this.props.className} />;
      case "facebook":
        if (this.props.mode === "button") {
          return (
            <UIIcon slot="start" icon={logoFacebook} size={30} className={this.props.className} />
          );
        }

        return (
          <UIIcon
            slot="start"
            icon={logoFacebook}
            size={30}
            className={classnames(this.props.className, styles.fbColor)}
          />
        );

      case "apple":
        return <UIIcon slot="start" icon={logoApple} size={30} className={this.props.className} />;
      default:
        return null;
    }
  };
}
