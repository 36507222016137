/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import { CAMPFIRE_JS_VERSION } from "common/utils/campfireApp";
import config from "constants/config";
import isDev from "constants/isDev";

export type SentryContexts = {
  action: string;
  method: string;
  file: string;
  isFatal: boolean;
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const logSentryException = (
  action: string,
  method: string,
  file: string,
  error: any,
  isFatal?: boolean,
  errorName?: string,
  errorContext?: GenericObject,
): void => {
  const captureContext = {
    // Tags are searchable by the syntax: tags[key]:"value"
    tags: {
      action,
      method,
      file,
    },
    // TODO: We should consider using context for arbitrary data that we want to be visible
    //       to aid in debugging, but its not searchable.
    contexts: {
      errorContext: {
        ...errorContext,
      },
    },
  };

  const isError = error instanceof Error;
  const errorIsString = typeof error === "string";

  let errorToLog = error;

  // If the error is a string, create an error for it.
  // We will also choose a default error name unless overridden based on file/component name.
  // I think filename is the best candidate as action can be multi word and method
  // name is often generic.
  if (!isError && errorIsString) {
    const defaultErrorName = `${capitalizeFirstLetter(file)}Error`;

    errorToLog = new Error(error);

    errorToLog.name = defaultErrorName;
  }

  // To configure a custom error name to be more easily located on Sentry.
  if (errorName) {
    errorToLog.name = errorName;
  }

  Sentry.captureException(errorToLog, captureContext);
};

const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}/gi;
const scrubbedEmail = "*******";
const piiScrubber = (data: string) => {
  const emailScrubber = (value: string) => value.replace(emailRegex, scrubbedEmail);

  return emailScrubber(data);
};

/**
 * A function that scrubs PII from the Sentry exception message.
 *
 * In the stack of exceptions, the last exception has the exception
 * message which needs PII scrubbing.
 */
const scrubPIIFromException = (event: Sentry.Event) => {
  if (event.exception?.values?.length) {
    const exceptions: Sentry.Exception[] = event.exception.values;

    const lastException = exceptions[exceptions.length - 1];

    if (lastException.value !== undefined) {
      lastException.value = piiScrubber(lastException.value);
    }
  }

  return event;
};

export const initializeSentry = () => {
  Sentry.init({
    dsn: config.get("CAMPFIRE_APP_SENTRY_DSN_KEY"),
    beforeSend: (event: Sentry.Event) => {
      return scrubPIIFromException(event);
    },
    environment: isDev ? "development" : "production",
    release: CAMPFIRE_JS_VERSION,
    // integrations: [new Integrations.BrowserTracing()],
    // tracesSampleRate: 1.0,
  });
};
