import { Coachmark, CoachmarkRequiredProps } from "@niantic/react-coachmark";
import React from "react";

import withAppState, { WithAppStateProps } from "providers/AppStateProvider/withAppState";

export type Props = WithAppStateProps & CoachmarkRequiredProps & {};

type State = {};

class CampfireCoachmark extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { children, disabled, ...remainingProps } = this.props;

    return (
      <Coachmark
        disabled={disabled || this.props.appState.hasCoachmarkBlockingIntent}
        {...remainingProps}
      >
        {children}
      </Coachmark>
    );
  };
}

const AppStateConnected = withAppState(CampfireCoachmark);

export default AppStateConnected;
