/* eslint-disable import/first, import/order */
import React from "react";
import ReactDOM from "react-dom";
// CRITICAL: This must be imported first to initialize and set some stuff so that
//           when Capacitor initializes, it sees the correct platform.
import "app-setup/initializeLibrariesInCorrectOrder";

import { isStandalone } from "common/capacitor/helpers";
import { prepareNativeApp, finalizeNativeApp } from "app-setup/prepareNativeApp";
import prepareApp from "app-setup/prepareApp";

import BootLoader from "boot-loader";

(async () => {
  // Perform some setup stuff while the splashscreen is active on app boot.
  if (isStandalone) {
    try {
      await prepareNativeApp();

      // NOTE: For now, no need to wait for things since they can complete in the background.
      finalizeNativeApp();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error during standalone bootup!", error);
    }
  }

  // Perform some stuff to setup the webapp now.
  prepareApp();

  // Once all standalone work has been completed, we can start rendering.
  ReactDOM.render(
    <React.StrictMode>
      <BootLoader />
    </React.StrictMode>,
    document.getElementById("root"),
  );
})();
