import _ from "lodash";
import { MessageEvent } from "pubnub";
import React from "react";

import withPubnub, { WithPubnubProps } from "providers/PubnubProvider/withPubnub";

import createPgoBeacon from "common/utils/relayStore/createPgoBeacon";
import deletePgoBeacon from "common/utils/relayStore/deletePgoBeacon";

export type Props = WithPubnubProps & {
  channel: string;
};

type State = {};

class UserPgoBeaconUpdateMessageHandler extends React.Component<Props, State> {
  componentDidMount = (): void => {
    this.props.pubnubProvider.pubnubClient.registerGlobalMessageHandler(
      this.handlePgoBeaconUpdateMessage,
    );
  };

  componentWillUnmount = (): void => {
    this.props.pubnubProvider.pubnubClient.unregisterGlobalMessageHandler(
      this.handlePgoBeaconUpdateMessage,
    );
  };

  handlePgoBeaconUpdateMessage = (pubnubMessageEvent: MessageEvent): void => {
    const pubnubMessage: CampfirePushEvent.PgoBeaconUpdatePushEvent = pubnubMessageEvent.message;
    const actionType: CampfirePushEvent.ActionType = _.get(pubnubMessage, "action") || "";
    const isPgoBeaconUpdateAction = actionType === "pgo-beacon-update";

    if (isPgoBeaconUpdateAction) {
      const { actionData } = pubnubMessage;

      if (actionData.updateType === "created") {
        createPgoBeacon(actionData.dropId, actionData.beaconId, actionData.beaconData);
      }

      if (actionData.updateType === "deleted") {
        deletePgoBeacon(actionData.dropId, actionData.beaconId);
      }
    }
  };

  render = (): React.ReactNode => {
    return null;
  };
}

export default withPubnub(UserPgoBeaconUpdateMessageHandler);
