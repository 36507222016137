/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

type Key = "lastLocation" | "locationShareExpiresAt";

const DEFAULTS = {
  lastLocation: "",
  locationShareExpiresAt: 0,
};

class UserLocationStore extends BaseStore {
  constructor() {
    super("UserLocationStore", DEFAULTS, false);
  }

  get(key: Key): any {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, value: any): GenericObject {
    return super.set(key, value);
  }
}

export default new UserLocationStore();
