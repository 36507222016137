import environment from "common/relay/relay-env";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer, graphql, RelayRefetchProp } from "react-relay";

import withAnalytics, { WithAnalyticsProps } from "providers/AnalyticsProvider/withAnalytics";
import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import { OnboardingEvent, getOnboardingEventPayload } from "common/utils/telemetry/onboarding";
import { GameProfileVisibility } from "constants/user";
import UpdateGameProfilesMutation from "mutations/UpdateGameProfilesMutation";

import OnboardNianticGames from "common/components/Onboarding/components/OnboardNianticGames";

import { AccountSetupNianticGamesSlide_me$data as AccountSetupNianticGamesSlideMe } from "__generated__/AccountSetupNianticGamesSlide_me.graphql";

type Props = WithTranslation &
  WithToastProps &
  WithAnalyticsProps & {
    me: AccountSetupNianticGamesSlideMe;
    relay: RelayRefetchProp;
    next: () => void;
  };

type GameProfileFragment = NonNullable<
  ArrayElement<AccountSetupNianticGamesSlideMe["gameProfiles"]>
>;

class AccountSetupNianticGamesSlide extends React.Component<Props> {
  onSubmit = async (appVisibilityMap: Record<string, GameProfileVisibility>): Promise<void> => {
    const gameMapById = this.props.me.gameProfiles.filter(Boolean).reduce((result, gameProfile) => {
      if (!gameProfile) {
        return result;
      }

      return { ...result, [gameProfile.id]: gameProfile };
    }, {} as Record<string, GameProfileFragment>);

    const gameProfiles = Object.entries(appVisibilityMap).map(([gameProfileId, visibility]) => ({
      gameProfileId,
      visibility,
      game: gameMapById[gameProfileId].game,
    }));

    const payload = { gameProfiles };

    try {
      await UpdateGameProfilesMutation.commit(environment, payload);

      this.props.analyticsProvider.trackClientAction(
        "OnboardingEvent",
        getOnboardingEventPayload(OnboardingEvent.NIANTIC_GAMES),
      );
      this.props.next();
    } catch (error) {
      this.props.toastProvider.showErrorToast(
        this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN"),
      );
    }
  };

  render = (): React.ReactNode => {
    return <OnboardNianticGames me={this.props.me} onSubmit={this.onSubmit} />;
  };
}

const ToastConnected = withToast(AccountSetupNianticGamesSlide);

const FragmentContainer = createFragmentContainer(ToastConnected, {
  me: graphql`
    fragment AccountSetupNianticGamesSlide_me on User {
      gameProfiles {
        id
        game
        visibility
      }
      ...OnboardNianticGames_me
    }
  `,
});

const AnalyticsConnected = withAnalytics(FragmentContainer);

export default withTranslation()(AnalyticsConnected);
