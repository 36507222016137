/**
 * @generated SignedSource<<b6c1a2a1d113c951d3b94bec9a21dcf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pgo_MapAndActivities_page_Query$variables = {
  gameName: string;
};
export type pgo_MapAndActivities_page_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityCenterView_me" | "MapAndActivitiesPageMainContentPGO_me">;
  };
  readonly realityChannelForEmbedGame: {
    readonly iconURL: string;
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"MapAndActivitiesPageMainContentPGO_realityChannel">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"MapAndActivitiesPageMainContentPGO_query">;
};
export type pgo_MapAndActivities_page_Query = {
  response: pgo_MapAndActivities_page_Query$data;
  variables: pgo_MapAndActivities_page_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gameName"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "gameName"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iconURL",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pgo_MapAndActivities_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActivityCenterView_me"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MapAndActivitiesPageMainContentPGO_me"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MapAndActivitiesPageMainContentPGO_query"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RealityChannel",
        "kind": "LinkedField",
        "name": "realityChannelForEmbedGame",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MapAndActivitiesPageMainContentPGO_realityChannel"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pgo_MapAndActivities_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotificationSettings",
            "kind": "LinkedField",
            "name": "notificationSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "beaconSameLitScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "beaconNearMeScope",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "outgoingFlareScope",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientSetting",
        "kind": "LinkedField",
        "name": "clientSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MapPageClientSettings",
            "kind": "LinkedField",
            "name": "mapPageClientSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mapDefaultZoomLvl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RealityChannel",
        "kind": "LinkedField",
        "name": "realityChannelForEmbedGame",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RealityChannelSource",
            "kind": "LinkedField",
            "name": "sources",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dropTypes",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RealityChannelMetadata",
            "kind": "LinkedField",
            "name": "metadata",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFirstParty",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "game",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "splashImageUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RealityChannelBanner",
            "kind": "LinkedField",
            "name": "welcomeBanner",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bodyMd",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imgURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "location",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longitude",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "848ab933267657bd5faa9afca080614f",
    "id": null,
    "metadata": {},
    "name": "pgo_MapAndActivities_page_Query",
    "operationKind": "query",
    "text": "query pgo_MapAndActivities_page_Query(\n  $gameName: String!\n) {\n  me {\n    ...ActivityCenterView_me\n    ...MapAndActivitiesPageMainContentPGO_me\n    id\n  }\n  ...MapAndActivitiesPageMainContentPGO_query\n  realityChannelForEmbedGame(name: $gameName) {\n    id\n    name\n    iconURL\n    ...MapAndActivitiesPageMainContentPGO_realityChannel\n  }\n}\n\nfragment ActivityCenterPageLayout_me on User {\n  id\n  ...PGORealityChannelSettings_me\n}\n\nfragment ActivityCenterViewProvider_me on User {\n  ...ActivityCenterPageLayout_me\n}\n\nfragment ActivityCenterView_me on User {\n  ...ActivityCenterPageLayout_me\n  ...ActivityCenterViewProvider_me\n}\n\nfragment DefaultActivityCenterQR_clientSetting on ClientSetting {\n  mapPageClientSettings {\n    mapDefaultZoomLvl\n  }\n}\n\nfragment MapAndActivitiesPageMainContentPGO_me on User {\n  id\n}\n\nfragment MapAndActivitiesPageMainContentPGO_query on Query {\n  clientSetting {\n    ...RealityChannelActivityCenterPage_clientSetting\n  }\n}\n\nfragment MapAndActivitiesPageMainContentPGO_realityChannel on RealityChannel {\n  id\n  name\n  iconURL\n  sources {\n    name\n    dropTypes\n  }\n  metadata {\n    isFirstParty\n    game\n    splashImageUrl\n  }\n  welcomeBanner {\n    title\n    bodyMd\n    imgURL\n    location {\n      latitude\n      longitude\n    }\n  }\n}\n\nfragment PGOActivityCenterQR_clientSetting on ClientSetting {\n  mapPageClientSettings {\n    mapDefaultZoomLvl\n  }\n}\n\nfragment PGORealityChannelSettings_me on User {\n  notificationSettings {\n    beaconSameLitScope\n    beaconNearMeScope\n    outgoingFlareScope\n  }\n}\n\nfragment RealityChannelActivityCenterPage_clientSetting on ClientSetting {\n  ...PGOActivityCenterQR_clientSetting\n  ...DefaultActivityCenterQR_clientSetting\n}\n"
  }
};
})();

(node as any).hash = "97f6fb3b3e54551c566efa283bbc2357";

export default node;
