import { FunctionComponent, SVGProps } from "react";

import { ReactComponent as BellFilledV2 } from "assets/icons/bell-filled-2.svg";
import { ReactComponent as BellOutlineV2 } from "assets/icons/bell-outline-2.svg";
import { ReactComponent as ChatFilledV2 } from "assets/icons/chat-filled-2.svg";
import { ReactComponent as ChatOutlineV2 } from "assets/icons/chat-outline-2.svg";
import { ReactComponent as PeopleFilledV2 } from "assets/icons/people-filled-2.svg";
import { ReactComponent as PeopleOutlineV2 } from "assets/icons/people-outline-2.svg";
import {
  ACTIVITY_CENTER_ROUTE,
  CLUBS_ROUTE,
  TabNameRouterV2,
  MESSAGES_ROUTE,
  NOTIFICATIONS_ROUTE,
  USER_INFO_ROUTE,
} from "constants/routes";
import TEST_IDS from "constants/testIds";

type IconComponentType = FunctionComponent<
  SVGProps<SVGSVGElement> & { title?: string | undefined }
>;

export type TabConfig = {
  icon: IconComponentType;
  selectedIcon: IconComponentType;
  dataTestId: string;
};

export type RenderedRouteInfo = {
  isSelected: boolean;
  hasUnread: boolean;
  IconComponent: IconComponentType;
  href: string;
  dataTestId: string;
};

export const TAB_CONFIG: Record<TabNameRouterV2, TabConfig> = {
  [USER_INFO_ROUTE]: {
    icon: () => null,
    selectedIcon: () => null,
    dataTestId: TEST_IDS.MyProfileTabId,
  },
  [CLUBS_ROUTE]: {
    icon: PeopleOutlineV2,
    selectedIcon: PeopleFilledV2,
    dataTestId: TEST_IDS.GroupsTabId,
  },
  [NOTIFICATIONS_ROUTE]: {
    icon: BellOutlineV2,
    selectedIcon: BellFilledV2,
    dataTestId: TEST_IDS.NotificationsTabId,
  },
  [MESSAGES_ROUTE]: {
    icon: ChatOutlineV2,
    selectedIcon: ChatFilledV2,
    dataTestId: TEST_IDS.DirectMessagesTabId,
  },
  [ACTIVITY_CENTER_ROUTE]: {
    icon: () => null,
    selectedIcon: () => null,
    dataTestId: TEST_IDS.ActivityCenterTabId,
  },
};
