/* eslint-disable */
import { AxiosRequestConfig } from "axios";
import authAxios from "common/api/axios-instance";
import "common/api/axios-interceptors";

type RequestHeader = Record<string, string>;

const getHeadersConfig = (headers: RequestHeader = {}): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {};

  // Set request headers if there are any
  if (Object.keys(headers).length) {
    config.headers = headers;
  }

  return config;
};

export const get = async (
  url: string,
  requestHeaders?: RequestHeader,
  axiosConfig?: AxiosRequestConfig,
): Promise<any> => {
  const config = getHeadersConfig(requestHeaders);
  const finalConfig: AxiosRequestConfig = { ...axiosConfig, ...config };
  const response = await authAxios.get(url, finalConfig);

  return response.data;
};

export const post = async (
  url: string,
  data: any,
  requestHeaders?: RequestHeader,
  axiosConfig?: AxiosRequestConfig,
): Promise<any> => {
  const config = getHeadersConfig(requestHeaders);
  const finalConfig: AxiosRequestConfig = { ...axiosConfig, ...config };
  const response = await authAxios.post(url, data, finalConfig);

  return response.data;
};
