import React, { ForwardedRef } from "react";
import { animated, useSpring } from "react-spring";

import { UIPopperTransitionProps } from "./transitionTypes";

/**
 * Performs a fade in and out using react-spring and the Popper TransitionProps from Material UI.
 *
 * Taken From https://mui.com/components/popper
 * - https://codesandbox.io/s/mexn4?file=/demo.js
 */
const UIPopperFade = React.forwardRef(function Fade(
  props: UIPopperTransitionProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export default UIPopperFade;
