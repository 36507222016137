import React from "react";

import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = {
  title: string;
  subtitle?: string;
};

class OnboardElementTitle extends React.Component<Props> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIText color="dark" variant="h3" weight="bold">
          {this.props.title}
        </UIText>
        {this.props.subtitle && (
          <>
            <UISpacer h={4} />
            <UIText color="dark" variant="h4">
              {this.props.subtitle}
            </UIText>
          </>
        )}
      </div>
    );
  };
}

export default OnboardElementTitle;
