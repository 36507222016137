import { IonButton } from "@ionic/react";
import classnames from "classnames";
import React, { HTMLAttributes, SyntheticEvent } from "react";

import styles from "./styles.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IonButtonMouseEventType = SyntheticEvent<any>;

export type Props = HTMLAttributes<HTMLIonButtonElement> & {
  children: React.ReactNode;
  onClick?: (event: IonButtonMouseEventType) => void;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  dataTestListId?: string;
  expand?: "full" | "block" | undefined;
  size?: "small" | "default" | "large";
  fill?: "clear" | "default" | "outline" | "solid";
};

type State = {};

export default class UIButton extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { children, className, dataTestId, dataTestListId, ...remainingProps } = this.props;

    return (
      <IonButton
        {...remainingProps}
        className={classnames(styles.root, className)}
        data-test-id={dataTestId}
        data-test-list-id={dataTestListId}
      >
        {children}
      </IonButton>
    );
  };
}
