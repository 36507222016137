export enum EntryPoint {
  TAB_BAR = "tab-bar",
  FAB_BAR = "fab-bar",
  MESSAGES = "messages",
  MESSAGES_CREATE_DM = "messages-create-dm",
  NOTIFICATIONS_DM = "notifications-dm",
  NOTIFICATIONS_CAMPFIRE_INVITE_ACCEPTED = "notifications-campfire-invite-accepted",
  NOTIFICATIONS_FRIEND_INVITE_ACCEPTED = "notifications-friend-invite-accepted",
  NOTIFICATIONS_FRIEND_INVITE = "notifications-friend-invite",
  USER_PROFILE = "user-profile",
  MAP = "map",
  CLUB_INVITE = "club-invite",
  DEEPLINK = "deeplink",
  NONE = "none",
}

export enum TeamUpEntryPoint {
  PGO_MAP = "pgo_map",
  CAMPFIRE_MAP = "campfire_map",
  POI_CARD = "poi_card",
  ACTIVITY_CENTER = "activity_center",
  ACTIVITY_CENTER_HOST = "activity_center_host",
  FLARE_CHAT = "flare_chat",
  NONE = "none",
}

const MAX_ENTRIES = 1000;
let entryPoints: EntryPoint[] = [];
let teamUpEntryPoints: TeamUpEntryPoint[] = [];

const addEntryPoint = (e: EntryPoint): void => {
  entryPoints.unshift(e);
  entryPoints = entryPoints.slice(0, MAX_ENTRIES);
};

const getLatestEntryPoint = (): EntryPoint => {
  if (!entryPoints.length) {
    return EntryPoint.NONE;
  }

  return entryPoints[0] as EntryPoint;
};

const addTeamUpEntryPoint = (e: TeamUpEntryPoint): void => {
  teamUpEntryPoints.unshift(e);
  teamUpEntryPoints = teamUpEntryPoints.slice(0, MAX_ENTRIES);
};

const getLatestTeamUpEntryPoint = (): TeamUpEntryPoint => {
  if (!teamUpEntryPoints.length) {
    return TeamUpEntryPoint.NONE;
  }

  return teamUpEntryPoints[0] as TeamUpEntryPoint;
};

const entryPointHistory = {
  addEntryPoint,
  getLatestEntryPoint,
  addTeamUpEntryPoint,
  getLatestTeamUpEntryPoint,
};

export default entryPointHistory;
