import { useContext } from "react";

import {
  SelectedRealityChannelContext,
  ExposedProps,
} from "providers/SelectedRealityChannelProvider";

type HookReturnType = ExposedProps;

/**
 * Hook to get SelectedRealityChannelProvider props
 */
function useSelectedRealityChannel(): HookReturnType {
  const exposedProps = useContext(SelectedRealityChannelContext);

  return exposedProps;
}

export default useSelectedRealityChannel;
