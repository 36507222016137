import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { GameProfileVisibility } from "constants/user";

import OnboardElementCard from "common/components/Onboarding/components/OnboardElementCard";
import OnboardElementLayout from "common/components/Onboarding/components/OnboardElementLayout";
import OnboardElementSubmitButton from "common/components/Onboarding/components/OnboardElementSubmitButton";
import OnboardElementTitle from "common/components/Onboarding/components/OnboardElementTitle";
import UISpacer from "common/components/UISpacer";
import { GameProfile } from "common/components/UserAppList";
import UserAppListVisibilitySelection from "common/components/UserAppListVisibilitySelection";

import { OnboardNianticGames_me$data as OnboardNianticGamesMe } from "__generated__/OnboardNianticGames_me.graphql";

import styles from "./styles.scss";

type AppVisibilityState = Record<string, GameProfileVisibility>;

export type Props = WithTranslation & {
  me: OnboardNianticGamesMe;
  onSubmit: (appVisibility: AppVisibilityState) => Promise<void>;
};

type State = {
  appItemsVisibility: AppVisibilityState;
};

class OnboardNianticGames extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const initialAppItemsVisibilityState: AppVisibilityState =
      this.getInitialAppItemsVisibilityState();

    this.state = {
      appItemsVisibility: initialAppItemsVisibilityState,
    };
  }

  /**
   * Gets value of initial state of mapping between appItemId and its GameProfileVisibility
   */
  getInitialAppItemsVisibilityState = (): AppVisibilityState => {
    const gameProfiles = this.props.me.gameProfiles.filter(Boolean) as GameProfile[];

    return gameProfiles.reduce((result, gameProfile) => {
      const { id, visibility } = gameProfile;

      return { ...result, [id]: visibility };
    }, {});
  };

  onAppItemVisibilityChange = (appItemsVisibility: AppVisibilityState): void => {
    this.setState({ appItemsVisibility });
  };

  onSubmit = (): Promise<void> => {
    return this.props.onSubmit(this.state.appItemsVisibility);
  };

  renderSubmit = (): React.ReactNode => {
    return <OnboardElementSubmitButton text={this.props.t("CONTINUE")} onClick={this.onSubmit} />;
  };

  render = (): React.ReactNode => {
    const { me } = this.props;

    return (
      <OnboardElementLayout
        title={this.props.t("SELECT_WHAT_GAMES_YOU_PLAY")}
        renderSubmit={this.renderSubmit}
      >
        <UISpacer h={40} />
        <OnboardElementCard showRibbon>
          <div className={styles.cardContainer}>
            <UISpacer h={28} />
            <OnboardElementTitle title={me.displayName} subtitle={`@${me.username}`} />
            <UISpacer h={32} />
            <UserAppListVisibilitySelection
              me={this.props.me}
              onChange={this.onAppItemVisibilityChange}
              appItemsVisibility={this.state.appItemsVisibility}
            />
          </div>
        </OnboardElementCard>
      </OnboardElementLayout>
    );
  };
}

const FragmentContainer = createFragmentContainer(OnboardNianticGames, {
  me: graphql`
    fragment OnboardNianticGames_me on User {
      displayName
      username
      gameProfiles {
        id
        visibility
      }
      ...UserAppListVisibilitySelection_me
    }
  `,
});

export default withTranslation()(FragmentContainer);
