import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import UIButton from "common/components/UIButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  title: string;
  description: string;
  imageNode: React.ReactNode;
  onClickOk: () => void;
};

type State = {};

class OnboardCoachmarkInfoCard extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.card}>
        <div className={styles.bodyContainer}>
          <div className={styles.iconContainer}>{this.props.imageNode}</div>
          <UISpacer w={12} />
          <div className={styles.textContainer}>
            <UIText weight="bold" color="dark" variant="subtitle2">
              {this.props.title}
            </UIText>
            <UISpacer h={4} />
            <UIText weight="medium" color="dark" variant="subtitle2">
              {this.props.description}
            </UIText>
          </div>
        </div>

        <UISpacer h={6} />

        <UIButton
          fill="outline"
          size="small"
          onClick={this.props.onClickOk}
          className={styles.button}
        >
          <UIText weight="bold" variant="subtitle2">
            {this.props.t("GOT_IT")}
          </UIText>
        </UIButton>
      </div>
    );
  };
}

export default withTranslation()(OnboardCoachmarkInfoCard);
