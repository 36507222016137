import React from "react";

import campfireLogo from "assets/images/campfire/campfire-logo.png";

import Image from "common/components/Image";
import UIDivButton from "common/components/UIDivButton";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = {
  title: string;
  description?: string;
  imageUrl?: string;
  confirmBtnText?: string;
  confirmBtnHref?: string;
  cancelBtnText?: string;
  cancelBtnHref?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  renderBodyContent?: () => React.ReactNode;
};

type State = {};

export default class SimpleCampfireOnboardingView extends React.PureComponent<Props, State> {
  wrapWithUILink = (content: React.ReactNode, href?: string): React.ReactNode => {
    if (href) {
      return <UILink href={href}>{content}</UILink>;
    }

    return content;
  };

  render = (): React.ReactNode => {
    const showDescription = Boolean(this.props.description);
    const showImage = Boolean(this.props.imageUrl);
    const showConfirmButton = Boolean(this.props.onConfirm);
    const showCancelButton = Boolean(this.props.onCancel);

    return (
      <div className={styles.root}>
        <Image alt="campfire" className={styles.campfireLogo} src={campfireLogo} />
        <UISpacer h={16} />
        <UIText color="dark" weight="bold" variant="h3">
          {this.props.title}
        </UIText>

        {showDescription && (
          <>
            <UISpacer h={8} />
            <UIText color="dark" variant="subtitle1">
              {this.props.description}
            </UIText>
          </>
        )}

        {showImage && (
          <>
            <UISpacer h={32} />
            <Image className={styles.img} src={this.props.imageUrl} alt="" />
          </>
        )}

        {this.props.renderBodyContent && this.props.renderBodyContent()}

        {showConfirmButton && (
          <>
            <UISpacer h={20} />
            {this.wrapWithUILink(
              <UIDivButton className={styles.button} color="primary" onClick={this.props.onConfirm}>
                <UIText variant="h4" weight="bold">
                  {this.props.confirmBtnText}
                </UIText>
              </UIDivButton>,
              this.props.confirmBtnHref,
            )}
          </>
        )}

        {showCancelButton && (
          <>
            <UISpacer h={8} />
            {this.wrapWithUILink(
              <UIDivButton
                className={styles.clearButton}
                color="light"
                onClick={this.props.onCancel}
              >
                <UIText variant="body1" weight="bold">
                  {this.props.cancelBtnText}
                </UIText>
              </UIDivButton>,
              this.props.cancelBtnHref,
            )}
          </>
        )}
      </div>
    );
  };
}
