import { App, AppState } from "@capacitor/app";
import { PluginListenerHandle } from "@capacitor/core/types/definitions";
import { Network } from "@capacitor/network";
import React from "react";

import withAppUpdate, { WithAppUpdateProps } from "providers/AppUpdateProvider/withAppUpdate";
import withToast, { WithToastProps } from "providers/ToastProvider/withToast";
import withVersionInfo, {
  WithVersionInfoProps,
} from "providers/VersionInfoProvider/withVersionInfo";

import logException from "common/analytics/exceptions";
import { checkBundleAlreadyExistsByVersion } from "common/utils/appUpdate/bundleStatus";
import {
  getNextBundleIdToApplyOnColdStart,
  markLastBackgroundTimestamp,
  shouldApplyNextScheduledBundle,
} from "common/utils/appUpdate/shouldApplyBundle";
import { AUTO_UPDATER_LONG_POLL_TIME_MS } from "constants/appUpdate";
import { logInDevelopmentEnvs } from "constants/isDev";
import { ONE_SECOND } from "constants/time";

import PollingRefetcher from "common/components/PollingRefetcher";

export type Props = WithAppUpdateProps & WithToastProps & WithVersionInfoProps & {};

type State = {};

const DELAY_TIME_MS = 5 * ONE_SECOND;

/**
 * Mounted when the app is authenticated and checks if we need to download any updates to the
 * js bundle.
 *
 * Periodically will check for updates after initial check in case new updates come in hot.
 *
 * NOTE: If we want to enable automatic updates in logged out situations, we should just move
 * the code related to foregrounding the app to the AppUpdateProvider. For now, all the triggers
 * related to automatic updates lives here.
 */
class AutoUpdater extends React.Component<Props, State> {
  appListenerHandler: PluginListenerHandle | null;

  delayedTriggerTimeout: NodeJS.Timeout | void;

  componentDidMount = (): void => {
    this.checkForUpdatesAndDownload();
    this.setupAppForegroundListeners();

    // Since the auto updater runs AFTER the app is loaded, at this point, its likely safe to
    // cleanup some bundles we no longer need.
    this.props.appUpdateProvider.cleanupUnusedBundles();
  };

  componentWillUnmount = (): void => {
    this.cleanupListeners();

    // Don't execute the delayed update check if this ever gets unmounted.
    if (this.delayedTriggerTimeout) {
      clearTimeout(this.delayedTriggerTimeout);
    }
  };

  cleanupListeners = (): void => {
    if (this.appListenerHandler) {
      this.appListenerHandler.remove();
    }
  };

  setupAppForegroundListeners = async (): Promise<void> => {
    // Watch for changes in the appState.
    this.appListenerHandler = await App.addListener("appStateChange", (state: AppState) => {
      this.automaticallyApplyBundleWhenForegrounded(state.isActive);
    });
  };

  automaticallyApplyBundleWhenForegrounded = (isForegrounded: boolean): void => {
    // When the app becomes active again, check if we should auto apply an update downloaded by the AutoUpdater.
    // (Technically it can be via anything, but currently it's the AutoUpdater that schedules it.)
    // When the app is backgrounded, then mark the last time we backgrounded
    if (isForegrounded) {
      const nextBundleIdToApply = getNextBundleIdToApplyOnColdStart();

      if (nextBundleIdToApply && shouldApplyNextScheduledBundle()) {
        logInDevelopmentEnvs("AutoUpdater: App foregrounded and will apply an update now.");
        // We can either apply a bundle immediately, or we can just reload the entire webview.
        // For now, we will just apply the bundle immediately.
        this.props.appUpdateProvider.applyBundleImmediately(nextBundleIdToApply);
        logInDevelopmentEnvs(`AutoUpdater: Applying ${nextBundleIdToApply} now.`);
      }
    } else {
      markLastBackgroundTimestamp();
    }
  };

  checkIfBundleAlreadyDownloaded = async (jsVersion: string): Promise<boolean> => {
    return checkBundleAlreadyExistsByVersion(jsVersion);
  };

  checkForUpdatesDownloadAndScheduleUpdate = async (): Promise<void> => {
    logInDevelopmentEnvs("AutoUpdater: Checking for updates to download...");

    try {
      const networkStatus = await Network.getStatus();
      const isOnWifi = networkStatus.connectionType === "wifi";

      // Update will not occur unless user is on WiFi.
      if (!isOnWifi) {
        logInDevelopmentEnvs(
          "AutoUpdater: User is NOT on WiFi, skipping bundle update check and download.",
        );
        return;
      }

      // Check for updates and download them if needed.
      // We will get a BundleInfo back if we actually downloaded one, and it needs to be scheduled.
      this.showToastInQA(`AutoUpdater: Checking for new updates and downloading if needed.`);

      const bundleInfo = await this.props.appUpdateProvider.checkForUpdatesAndDownloadSilent();

      // Set the next bundle to apply. This will either take effect:
      // - Next cold start
      // - Next foreground that met some criteria. See: common/utils/appUpdate/shouldApplyBundle.ts
      if (bundleInfo) {
        this.props.appUpdateProvider.setNextBundleToApplyOnColdStart(bundleInfo);
        this.showToastInQA(
          `AutoUpdater: Applying version ${bundleInfo.version} on next cold start`,
        );
      }
    } catch (error) {
      logException("autoUpdate", "checkForUpdatesAndDownloadCore", "AutoUpdater", error);
    }
  };

  checkForUpdatesAndDownload = (): void => {
    if (this.delayedTriggerTimeout) {
      clearTimeout(this.delayedTriggerTimeout);
    }

    // Check for updates (Delay this slightly)
    this.delayedTriggerTimeout = setTimeout(
      this.checkForUpdatesDownloadAndScheduleUpdate,
      DELAY_TIME_MS,
    );
  };

  pollForUpdatesAndDownload = (): void => {
    this.checkForUpdatesAndDownload();
  };

  showToastInQA = (debugInfo: string): void => {
    if (process.env.CAMPFIRE_APP_TARGET_ENV !== "qa") {
      return;
    }

    this.props.toastProvider.showFeedbackToast(debugInfo);
  };

  render = (): React.ReactNode => {
    return (
      <PollingRefetcher
        pollingFn={this.pollForUpdatesAndDownload}
        intervalTimeMs={AUTO_UPDATER_LONG_POLL_TIME_MS}
      />
    );
  };
}

const AppUpdateConnected = withAppUpdate(AutoUpdater);
const ToastConnected = withToast(AppUpdateConnected);
const VersionInfoConnected = withVersionInfo(ToastConnected);

export default VersionInfoConnected;
