import { MessageCountsResponse } from "pubnub";
import React from "react";

import PubnubClient, {
  CampfireFetchMessagesParameters,
  MessageCountChannelConfig,
  PubnubFetchMessageMessage,
  PubnubMessageAction,
} from "common/utils/pubnub/client";

export type ExposedProps = {
  pubnubClient: PubnubClient;
  fetchMessagesFromHistory: (
    channelId: string,
    config: Partial<CampfireFetchMessagesParameters>,
  ) => Promise<PubnubFetchMessageMessage[]>;
  fetchMostRecentMessagesFromHistory: (
    channelId: string,
    count: number,
    config?: Partial<CampfireFetchMessagesParameters>,
  ) => Promise<PubnubFetchMessageMessage[]>;
  fetchMostRecentMessageActions: (
    channelId: string,
    endTimetoken?: string,
  ) => Promise<PubnubMessageAction[]>;
  fetchMessageCounts: (
    channelConfigs: MessageCountChannelConfig[],
  ) => Promise<MessageCountsResponse>;
};

type Props = {
  userId: string;
  pubnubAuthKey?: string;
  channelGroups?: string[];
  userChannel?: string;
  useDynamicMessageData?: boolean;
  children: React.ReactNode;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  pubnubClient: {} as PubnubClient,
  fetchMessagesFromHistory: () => Promise.reject(new Error("PubnubProvider not ready!")),
  fetchMostRecentMessagesFromHistory: () => Promise.reject(new Error("PubnubProvider not ready!")),
  fetchMostRecentMessageActions: () => Promise.reject(new Error("PubnubProvider not ready!")),
  fetchMessageCounts: () => Promise.reject(new Error("PubnubProvider not ready!")),
};

const PubnubContext = React.createContext(INITIAL_CONTEXT);

export class PubnubProvider extends React.Component<Props, State> {
  pubnubClient: PubnubClient;

  constructor(props: Props) {
    super(props);

    this.pubnubClient = new PubnubClient(this.props.userId, this.props.pubnubAuthKey);
  }

  componentDidMount = (): void => {
    if (this.props.channelGroups) {
      this.pubnubClient.subscribeToChannelGroups(this.props.channelGroups);
    }

    if (this.props.userChannel) {
      this.pubnubClient.subscribeToChannel(this.props.userChannel);
    }
  };

  componentWillUnmount = (): void => {
    if (this.props.channelGroups) {
      this.pubnubClient.unsubscribeFromChannelGroups(this.props.channelGroups);
    }

    if (this.props.userChannel) {
      this.pubnubClient.unsubscribeFromChannel(this.props.userChannel);
    }
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <PubnubContext.Provider
        value={{
          pubnubClient: this.pubnubClient,
          fetchMessagesFromHistory: this.pubnubClient.fetchMessagesFromHistory,
          fetchMostRecentMessagesFromHistory: this.pubnubClient.fetchMostRecentMessagesFromHistory,
          fetchMostRecentMessageActions: this.pubnubClient.fetchMostRecentMessageActions,
          fetchMessageCounts: this.pubnubClient.fetchMessageCounts,
        }}
      >
        {children}
      </PubnubContext.Provider>
    );
  };
}

export const PubnubConsumer = PubnubContext.Consumer;
