import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { UpdateUserPgoNotificationSettingsMutation$data } from "__generated__/UpdateUserPgoNotificationSettingsMutation.graphql";

type ReturnType = UpdateUserPgoNotificationSettingsMutation$data["updateUserNotificationSettings"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;

type InputType = {
  beaconSameLitScope: string;
  beaconNearMeScope: string;
  outgoingFlareScope: string;
};

const mutation = graphql`
  mutation UpdateUserPgoNotificationSettingsMutation($input: UpdateUserNotificationSettingsInput!) {
    updateUserNotificationSettings(input: $input) {
      user {
        ...PGORealityChannelSettings_me
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "UpdateUserNotificationSettings.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "UpdateUserNotificationSettings.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "UpdateUserNotificationSettings"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          beaconSameLitScope: input.beaconSameLitScope,
          beaconNearMeScope: input.beaconNearMeScope,
          outgoingFlareScope: input.outgoingFlareScope,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
