/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { ActivityConsumer, ExposedProps } from "providers/ActivityProvider";

export type WithActivityProps = {
  activityProvider: {
    channelIsVisiblyActive: ExposedProps["channelIsVisiblyActive"];
    increaseChannelActivityCounter: ExposedProps["increaseChannelActivityCounter"];
    reduceChannelActivityCounter: ExposedProps["reduceChannelActivityCounter"];
    updateChannelStatus: ExposedProps["updateChannelStatus"];
    updateChannelStatusBatched: ExposedProps["updateChannelStatusBatched"];
    updateChannelLastSeen: ExposedProps["updateChannelLastSeen"];
    updateChannelNewestMessage: ExposedProps["updateChannelNewestMessage"];
    getChannelUnseenActivityCount: ExposedProps["getChannelUnseenActivityCount"];
    getChannelStatus: ExposedProps["getChannelStatus"];
  };
};

// eslint-disable-next-line max-len
export default <P extends WithActivityProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithActivityProps>): React.ReactElement => (
    <ActivityConsumer>
      {({
        channelIsVisiblyActive,
        increaseChannelActivityCounter,
        reduceChannelActivityCounter,
        updateChannelStatus,
        updateChannelStatusBatched,
        updateChannelLastSeen,
        updateChannelNewestMessage,
        getChannelUnseenActivityCount,
        getChannelStatus,
      }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          activityProvider={{
            channelIsVisiblyActive,
            increaseChannelActivityCounter,
            reduceChannelActivityCounter,
            updateChannelStatus,
            updateChannelStatusBatched,
            updateChannelLastSeen,
            updateChannelNewestMessage,
            getChannelUnseenActivityCount,
            getChannelStatus,
          }}
          {...props}
        />
      )}
    </ActivityConsumer>
  );
};
