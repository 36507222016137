/* eslint-disable no-underscore-dangle */
import logException from "common/analytics/exceptions";
import googleAnalytics, { DimensionType } from "common/analytics/googleAnalytics";
import { ClientInfo } from "common/api/client-info-interceptor";
import SimpleEventEmitter from "common/classes/SimpleEventEmitter";
import { parseQuery } from "common/utils/url";
import {
  PreconfiguredInteropType,
  PreconfiguredInterop,
} from "common/utils/webInterop/preconfigured";
import unityPreconfiguredInterop from "common/utils/webInterop/preconfigured/unity";
import { resolveInteropPromise, rejectInteropPromise } from "common/utils/webInterop/resolver";
import { GAME_SHORT_CODES } from "constants/nianticGame";
import { QUERY_PARAMS } from "constants/routes";

const LOCAL_STORAGE_INTEROP_KEY = "CAMPFIRE_WEB_INTEROP_TYPE";
const LOCAL_STORAGE_INTEROP_EMBEDDED_INFO_KEY = "CAMPFIRE_WEB_INTEROP_EMBEDDED_INFO";
const PRECONFIGURED_INTEROPS: Record<PreconfiguredInteropType, PreconfiguredInterop> = {
  unity: unityPreconfiguredInterop,
};

export const _actionEventEmitter = new SimpleEventEmitter<CampfireInterop.ActionName>();

/**
 * Embeddable web interop for plugins and other overrides.
 * =======================================================
 * Since we run in a browser or fully web version in the embedded use case,
 * we need some way to interact with the native platform underneath occasionally.
 *
 * Enter the CAMPFIRE_INTEROPS.
 *
 * The idea is that from the FE code perspective, we will think we are interacting with Capacitor.
 * But, when desired, if we want to allow an embeddable app to configure what those plugin methods
 * do, we can. Here is how the flow works:
 *
 * REQUIREMENTS:
 * =============
 * - You must be able to access the webview's "window" object.
 * - You must be able to execute JS on the webview.
 * - You must have some way your webview can communicate with your main app, via JS somehow.
 * - A little bit of javascript experience, or someone who can help you with the JS layer of
 *   the interop implmentation.
 *
 * FLOW:
 * =====
 *    **********    LOAD WEBVIEW
 *    *  Unity *  ================>  Define on browser, window.__CAMPFIRE_PLUGIN_INTEROP__
 *    **********
 *
 * Eventually, JS will call our stubbed plugin:
 *
 *    ********  Invoke Stubbed Capacitor Plugin    *******************************************
 *    *  JS  *  ================================>  * Setup Promise and Invoke Interop method *
 *    ********                                     *******************************************
 *
 * => Which translates to invoking either the default or stubbed interop method.
 *
 *    ********    Invoke Interop Method     *********************************
 *    *  JS  *  =========================>  * Signals to Unity/External App *
 *    ********                              *********************************
 *
 * => Signal is completely configurable on app side, so you handle passing of arguments.
 *    Save the promise id you will be given.
 *
 *    ***********    Parse id and perform action  *******************
 *    *  Unity  *  ============================>  * Action Complete *
 *    ***********                                 *******************
 *
 * => Once action is complete on app side, app will use the resolver provided on the window.
 *
 *    ***********    Resolve promise by id     *****************************
 *    *  Unity  *  =========================>  * JS with Data from resolve *
 *    ***********                              *****************************
 *
 * => JS continues as normal.
 *
 *    ******************************
 *    *  JS now has data from app  *
 *    ******************************
 */

const getPreconfiguredInteropType = (): PreconfiguredInteropType => {
  // Check if we have any preconfigured defaults we want to use.
  // Look either at the route or at localstorage
  const queryParams = parseQuery(window.location.search);
  const interopTypeQueryParamString = QUERY_PARAMS.root.interopType.key;
  const interopFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_INTEROP_KEY);

  // parseQuery can return an array of strings if multiple are found.
  const interopTypeFromQueryTemp = queryParams[interopTypeQueryParamString];
  const hasMultiple = interopTypeFromQueryTemp && Array.isArray(interopTypeFromQueryTemp);
  const interopTypeFromQuery = hasMultiple ? interopTypeFromQueryTemp[0] : interopTypeFromQueryTemp;

  // Read interop type from localStorage or query params.
  const preconfiguredInteropType = (interopTypeFromQuery ||
    interopFromLocalStorage) as PreconfiguredInteropType;

  return preconfiguredInteropType;
};

const getEmbeddedClientInfo = (): GenericObject => {
  const queryParams = parseQuery(window.location.search);
  const embeddedClientInfoQueryParamString = QUERY_PARAMS.root.embeddedClientInfo.key;
  // eslint-disable-next-line max-len
  const embeddedClientInfoFromLocalStorage = localStorage.getItem(
    LOCAL_STORAGE_INTEROP_EMBEDDED_INFO_KEY,
  );

  // parseQuery can return an array of strings if multiple are found.
  const embeddedClientInfoTemp = queryParams[embeddedClientInfoQueryParamString];
  const hasMultiple = embeddedClientInfoTemp && Array.isArray(embeddedClientInfoTemp);
  const embeddedClientInfoFromQuery = hasMultiple
    ? embeddedClientInfoTemp[0]
    : embeddedClientInfoTemp;

  const embeddedClientInfo = embeddedClientInfoFromQuery || embeddedClientInfoFromLocalStorage;

  if (!embeddedClientInfo) {
    return {};
  }

  try {
    const clientInfo = JSON.parse(embeddedClientInfo);

    // Ensure that the Game value is always all uppercase for consistency
    if (clientInfo.Game) {
      clientInfo.Game = clientInfo.Game.toUpperCase();
    }

    googleAnalytics(DimensionType.clientInfo, clientInfo);

    return clientInfo;
  } catch (error) {
    logException("JSON.parse", "getEmbeddedClientInfo", "getEmbeddedClientInfo", error);
    return {};
  }
};

const getIsInClosedBeta = (): boolean => {
  const queryParams = parseQuery(window.location.search);
  const showClosedBetaQueryParam = QUERY_PARAMS.root.showClosedBeta.key;
  const shouldShowAsClosedBeta = queryParams[showClosedBetaQueryParam];

  return shouldShowAsClosedBeta;
};

const getEmbeddedPGOIntent = (): string => {
  const query = parseQuery(window.location.search);
  const pgoIntentQueryParam = QUERY_PARAMS.root.embeddedPgoIntent.key;
  const embeddedPgoIntent = query[pgoIntentQueryParam];

  return embeddedPgoIntent;
};

const determineIfEmbedded = (): boolean => {
  // If we have set either of these by the time we run this, we will assume we are embedded.
  const isEmbedded = !!window.__CAMPFIRE_APP_INTEROP__ || !!window.__CAMPFIRE_PLUGIN_INTEROP__;

  googleAnalytics(DimensionType.isEmbedded, isEmbedded);

  return isEmbedded;
};

/**
 * Removes embedded game query params from the url on boot.
 * We save the values so we can always rely on those initial values.
 */
const cleanupUrlAfterInit = (): void => {
  const currentPath = window.location.pathname;

  const queryParams = parseQuery(window.location.search);
  const removedEmbedQueryParams: GenericObject = {
    ...queryParams,
    [QUERY_PARAMS.root.embeddedClientInfo.key]: undefined,
    [QUERY_PARAMS.root.interopType.key]: undefined,
    [QUERY_PARAMS.root.showClosedBeta.key]: undefined,
    [QUERY_PARAMS.root.embeddedPgoIntent.key]: undefined,
  };

  // Join all query params into a string.
  const queryString: string = Object.keys(removedEmbedQueryParams)
    .filter((key) => !!removedEmbedQueryParams[key])
    .map((key) => `${key}=${removedEmbedQueryParams[key] || ""}`)
    .join("&");

  const updatedUrl = `${currentPath}${queryString ? `?${queryString}` : ""}`;

  // Now, remove the embed query params from the url
  window.history.replaceState(null, "", updatedUrl);
};

const initBridge = (): void => {
  // Read interop type from localStorage or query params.
  const preconfiguredInteropType = getPreconfiguredInteropType();

  const isUsingPreconfiguredInterop = !!PRECONFIGURED_INTEROPS[preconfiguredInteropType];

  // If we want to use a preconfiguredInterop, set it automagically here.
  if (isUsingPreconfiguredInterop) {
    window.__CAMPFIRE_APP_INTEROP__ = PRECONFIGURED_INTEROPS[preconfiguredInteropType].app;
    window.__CAMPFIRE_PLUGIN_INTEROP__ = PRECONFIGURED_INTEROPS[preconfiguredInteropType].plugins;

    // Perform any work for the interop if needed.
    PRECONFIGURED_INTEROPS[preconfiguredInteropType].initialize();
  }

  // Put the resolver on the window
  window.__CAMPFIRE_INTEROP_RESOLVER__ = {
    resolve: resolveInteropPromise,
    reject: rejectInteropPromise,
  };

  // Put the action resolver on the window
  window.__CAMPFIRE_ACTION_DISPATCHER__ = _actionEventEmitter;
};

/**
 * WORKAROUND FOR THE CAP v2 to v3 upgrade with embed naming bridge same as capacitor
 * jacking with Capacitor.getPlatform.
 *
 * https://github.com/ionic-team/capacitor/blob/bdacb300bb6391dc4b84bb2bab075df993a15cba/core/src/runtime.ts#L18
 *
 * From what I see in capacitor's code here, when this is set, the platform that capacitor
 * should use is the one denoted by the "name" field. Since we will want the "web" based
 * implementations of the plugin methods, we name this the same. But, what this does is
 * allow Capacitor internally in its runtime.ts to use this "web" as the platform, rather
 * than try to evaluate it using stuff on the window like normal as shown here:
 * https://github.com/ionic-team/capacitor/blob/bdacb300bb6391dc4b84bb2bab075df993a15cba/core/src/util.ts#L28
 */
const treatEmbedAsWebPlatformCapacitor = () => {
  // Must be invoked prior to initializing Capacitor!!
  window.CapacitorCustomPlatform = {
    name: "web",
    plugins: {},
  };
};

// When the html page loads, initialize the bridge.
initBridge();

// Check now if we actually initialized anything, and denote that we are embedded or not.
export const isEmbedded = determineIfEmbedded();
// Check if we opened with this closed beta flag. We strip this off after init.
export const isInClosedBeta = getIsInClosedBeta();
// Check if we opened with a desired PGO intent. We strip this off after init.
export const embeddedPGOIntent = getEmbeddedPGOIntent();
export const savedInteropType: PreconfiguredInteropType | null =
  getPreconfiguredInteropType() || null;
export const embeddedClientInfo: ClientInfo = getEmbeddedClientInfo() as ClientInfo;
export const isPGOEmbed = isEmbedded && embeddedClientInfo.Game === GAME_SHORT_CODES.PGO;
export const isIngressEmbed = isEmbedded && embeddedClientInfo.Game === GAME_SHORT_CODES.ING;
export const isMHNowEmbed = isEmbedded && embeddedClientInfo.Game === GAME_SHORT_CODES.MHNOW;
export const isNbaAllWorldEmbed =
  isEmbedded && embeddedClientInfo.Game === GAME_SHORT_CODES.NBAALLWORLD;
export const isPeridotEmbed = isEmbedded && embeddedClientInfo.Game === GAME_SHORT_CODES.PERIDOT;
export const isPikminEmbed = isEmbedded && embeddedClientInfo.Game === GAME_SHORT_CODES.PIKMIN;

// If we are detected as embed, treat embed as a "web" capacitor platform.
if (isEmbedded) {
  treatEmbedAsWebPlatformCapacitor();
}

// Finally, we have saved everything, now clean it all up
try {
  cleanupUrlAfterInit();
} catch (error) {
  // eslint-disable-next-line no-console
  console.error("Error cleaning up url after app init");
}
