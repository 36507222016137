import React from "react";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pollingFn: () => any;
  intervalTimeMs: number;
};

/*
  Automatically calls pollingFn after the last pollingFn is complete.
*/
export default class PollingRefetcher extends React.Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pollingTimeoutID: any = null;

  componentDidMount = (): void => {
    this.scheduleNextPoll();
  };

  componentWillUnmount = (): void => {
    if (this.pollingTimeoutID) {
      clearTimeout(this.pollingTimeoutID);
    }
  };

  poll = (): void => {
    this.props.pollingFn();
    this.scheduleNextPoll();
  };

  scheduleNextPoll = (): void => {
    const intervalMs = this.props.intervalTimeMs || 10000;

    this.pollingTimeoutID = setTimeout(this.poll, intervalMs);
  };

  render = (): React.ReactNode => {
    return null;
  };
}
