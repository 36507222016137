import { AnimationResult } from "react-spring";

const always1 = () => 1;

/**
 * Invokes a callback only if all animation promises complete succesfully and handling a small
 * edge case at the apex of the animation tail. That's what that mutex is for.
 */
const invokeCallbackIfAllAnimationsFinish = async (
  promises: Promise<AnimationResult>[],
  callback: () => void,
  getLastMutex: () => number = always1,
): Promise<void> => {
  const initialMutex = getLastMutex();
  const promiseSettledResults = await Promise.allSettled(promises);
  const allFinished = (promiseSettledResults as PromiseFulfilledResult<AnimationResult>[]).every(
    (animationResult: PromiseFulfilledResult<AnimationResult>) => {
      const isFulfilled = animationResult.status === "fulfilled";
      const didFinish = animationResult.value?.finished;

      return isFulfilled && didFinish;
    },
  );

  const mutexAfterPromises = getLastMutex();
  // Ensures that we handle this tiny edge case that happens when you
  // try to start an animation right as one is ending.
  const isSameMutex = initialMutex === mutexAfterPromises;

  if (allFinished && isSameMutex) {
    callback();
  }
};

export default invokeCallbackIfAllAnimationsFinish;
