/**
 * @generated SignedSource<<369518dcdf9ffbbc4c8889a3fb02788c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetNianticIdInput = {
  nianticId: string;
};
export type SetNianticIdMutation$variables = {
  input: SetNianticIdInput;
};
export type SetNianticIdMutation$data = {
  readonly setNianticId: {
    readonly me: {
      readonly displayName: string;
      readonly hasSetNianticId: boolean;
      readonly username: string;
    };
  };
};
export type SetNianticIdMutation = {
  response: SetNianticIdMutation$data;
  variables: SetNianticIdMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSetNianticId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetNianticIdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetNianticIdResponse",
        "kind": "LinkedField",
        "name": "setNianticId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetNianticIdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetNianticIdResponse",
        "kind": "LinkedField",
        "name": "setNianticId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e820c446c63bd23c0491e4ae09de072c",
    "id": null,
    "metadata": {},
    "name": "SetNianticIdMutation",
    "operationKind": "mutation",
    "text": "mutation SetNianticIdMutation(\n  $input: SetNianticIdInput!\n) {\n  setNianticId(input: $input) {\n    me {\n      username\n      displayName\n      hasSetNianticId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "359cf0c81d65007016c14d86741ec0c5";

export default node;
