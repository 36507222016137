import { Libraries, LoadScriptNext } from "@react-google-maps/api";
import React from "react";

export type Props = {
  children: React.ReactElement;
  onLoad?: () => void;
};

type State = {};

// The library throws a warning if we don't do this, so we make it a const
// to prevent reinitializing as a new prop on each render cycle
const LIBRARIES: Libraries = ["geometry"];

export default class LoadGoogleMapsScript extends React.Component<Props, State> {
  renderDefaultLoadingState = (): React.ReactElement => {
    return <>{null}</>;
  };

  render = (): React.ReactNode => {
    return (
      <LoadScriptNext
        loadingElement={this.renderDefaultLoadingState()}
        googleMapsApiKey=""
        googleMapsClientId="gme-nianticinc"
        libraries={LIBRARIES}
        version="quarterly"
        onLoad={this.props.onLoad}
      >
        {this.props.children}
      </LoadScriptNext>
    );
  };
}
