import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import TEST_IDS from "constants/testIds";
import { NIANTIC_TERMS_URL } from "constants/urls";

import UIButton from "common/components/UIButton";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  onClickAccept: () => void;
  onClickDecline: () => void;
};

type State = {};

class SignupTOS extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIText variant="h2" color="dark" weight="extraBold">
          {this.props.t("PLEASE_ACCEPT_THE_TERMS_OF_SERVICE")}
        </UIText>

        <UISpacer h={30} />

        <UILink className={styles.underline} href={NIANTIC_TERMS_URL} openInNewTab>
          <UIText variant="body1" weight="bold">
            {this.props.t("VIEW_TOS")}
          </UIText>
        </UILink>

        <UISpacer h={30} />

        <UIButton
          color="primary"
          expand="block"
          onClick={this.props.onClickAccept}
          dataTestId={TEST_IDS.AcceptToSBtnId}
        >
          <UIText variant="body1" weight="bold">
            {this.props.t("ACCEPT")}
          </UIText>
        </UIButton>

        <UISpacer h={8} />

        <UIButton expand="block" fill="clear" onClick={this.props.onClickDecline}>
          <UIText color="dark" variant="body1" weight="bold">
            {this.props.t("DECLINE").toLocaleUpperCase()}
          </UIText>
        </UIButton>
      </div>
    );
  };
}

export default withTranslation()(SignupTOS);
