// A method that returns a promise that expires/rejects after given time
export const delay = (timeMs: number, message: string): Promise<void> => {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error(`${message}: ${timeMs}`));
    }, timeMs);
  });
};

// A method that returns a promise that resolves after a given time
export const delayResolve = (timeMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeMs);
  });
};
