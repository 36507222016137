import { ONE_MINUTE } from "constants/time";

// The built-in bundle has a well defined id.
// https://github.com/Cap-go/capacitor-updater/blob/a6ba294b48424d214c548a431b612c31d66e1bcd/ios/Plugin/BundleInfo.swift#L4
export const BUILT_IN_BUNDLE_ID = "builtin";

// Check for updates every 20 minutes
export const AUTO_UPDATER_LONG_POLL_TIME_MS = 20 * ONE_MINUTE;

// Used for heuristics determining when it may be ok to apply an auto update
// without a cold start of an app.
export const MS_TO_ELAPSE_BEFORE_AUTO_UPDATE = 10 * ONE_MINUTE;
