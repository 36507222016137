import classnames from "classnames";
import React from "react";

import OnboardingStore from "common/stores/OnboardingStore";

import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";
import SignupPrivacy from "common/components/SignupPrivacy";
import SignupTOS from "common/components/SignupTOS";
import UIDialog from "common/components/UIDialog";

import styles from "./styles.scss";

type Mode = "TOS" | "privacy";

type Props = {
  isOpen: boolean;
  resume: () => void;
  close: () => void;
};

type State = {
  mode: Mode;
  transitioning: boolean;
  dialogHeight?: number;
};

const FADE_TIME_MS = 200;

export default class SignupLegalDialog extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const hasAcceptedTOS = !!OnboardingStore.get("acceptedTOS");
    const initialMode = hasAcceptedTOS ? "privacy" : "TOS";

    this.state = {
      mode: initialMode,
      transitioning: false,
    };
  }

  onClickAcceptTOS = (): void => {
    // Check to see how tall the dialog is currently, since we want to do a nice transition.
    // Honestly, I normally would not care about the transition here, as complexity increased due to
    // the changes required, but since I thought it would look cool, I decided to do it.
    const container = document.querySelector(`.${styles.transitionContainer}`);
    const currentHeight = container ? container.clientHeight : undefined;

    // Mark that we have accepted the TOS.
    OnboardingStore.set("acceptedTOS", true);

    this.setState(
      {
        mode: "privacy",
        dialogHeight: currentHeight,
        transitioning: true,
      },
      () => {
        // Transition complete, reset to being in flow just in case something went wrong.
        setTimeout(() => {
          this.setState({ transitioning: false });
        }, FADE_TIME_MS * 2);
      },
    );
  };

  onClickOk = (): void => {
    // Mark that we have accepted the Privacy Policy.
    OnboardingStore.set("seenPrivacyPolicy", true);

    this.props.resume();
  };

  onDidDismiss = (): void => {
    this.props.close();
  };

  render = (): React.ReactNode => {
    // When this component loads initially, the content is placed in flow.
    // In order to fade the two modes on top of each other, we need the dialog to be sized
    // prior to unmounting and mounting the other. Of course, we could just use visibility and
    // z-indexing, but I thought eh, why not try it this way for fun.
    // So, when the user hits accept, we measure the size of the parent container and then
    // put the content out of flow.
    return (
      <UIDialog
        isOpen={this.props.isOpen}
        onDidDismiss={this.onDidDismiss}
        close={this.props.close}
      >
        <div className={styles.root}>
          <div
            className={styles.dialogContentWrapper}
            style={{ minHeight: this.state.dialogHeight }}
          >
            <AnimatedFadeInOut
              className={classnames(styles.transitionContainer, {
                [styles.transitioning]: this.state.transitioning,
              })}
              show={this.state.mode === "TOS"}
              durationMs={FADE_TIME_MS}
            >
              <SignupTOS onClickAccept={this.onClickAcceptTOS} onClickDecline={this.props.close} />
            </AnimatedFadeInOut>

            <AnimatedFadeInOut
              className={classnames(styles.transitionContainer, {
                [styles.transitioning]: this.state.transitioning,
              })}
              type="fade-in"
              show={this.state.mode === "privacy"}
              durationMs={FADE_TIME_MS}
              delayEnterMs={FADE_TIME_MS}
            >
              <SignupPrivacy onClickOk={this.onClickOk} />
            </AnimatedFadeInOut>
          </div>
        </div>
      </UIDialog>
    );
  };
}
