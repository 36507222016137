import React from "react";
import { matchPath } from "react-router";
import { Route, withRouter, RouteComponentProps } from "react-router-dom";

import CampfirePage from "common/components/CampfirePage";

export type Props = RouteComponentProps & {
  pageClassname?: string;
  contentClassname?: string;

  // <Route> style props below
  exact?: boolean;
  path: string | string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode;
};

type State = {
  hasMatchedOnce: boolean;
};

class CampfireTabRoute extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const loadedWithMatchingRoute = this.isCurrentRouteMatch();

    this.state = {
      hasMatchedOnce: loadedWithMatchingRoute || false,
    };
  }

  componentDidUpdate = (): void => {
    if (this.state.hasMatchedOnce) {
      return;
    }

    const isNowMatchingRoute = this.isCurrentRouteMatch();

    if (isNowMatchingRoute) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasMatchedOnce: true });
    }
  };

  isCurrentRouteMatch = (): boolean => {
    const currentPathname = this.props.location.pathname;
    const match = matchPath(currentPathname, {
      path: this.props.path,
      exact: Boolean(this.props.exact),
    });

    return Boolean(match);
  };

  render = (): React.ReactNode => {
    let pageContent: React.ReactNode = null;

    if (this.state.hasMatchedOnce) {
      const Component = this.props.component;
      const isCurrentRouteMatching = this.isCurrentRouteMatch();

      pageContent = (
        <CampfirePage
          pageClassname={this.props.pageClassname}
          contentClassname={this.props.contentClassname}
          isActivePage={isCurrentRouteMatching}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {this.props.children || <Component />}
        </CampfirePage>
      );
    }

    // We must pass children as a function for these special routes so that React Router does not
    // unmount it when the path no longer matches. These routes should also never be used
    // in a <Switch> as that also by definition only renders one matching Route.
    // By passing children as a function, for whatever reason, react-router will keep
    // the content mounted after it mounts it once. Which for our needs, is perfect.
    // https://stackoverflow.com/a/45930922/3154525
    // eslint-disable-next-line react/no-children-prop
    return (
      <Route exact={this.props.exact} path={this.props.path}>
        {() => pageContent}
      </Route>
    );
  };
}

export default withRouter(CampfireTabRoute);
