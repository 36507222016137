/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

type Key = string;

export type TokenRegistration = {
  token: string;
  registeredAt: Date;
};

const DEFAULTS: Record<Key, TokenRegistration | null> = {};

class PushTokenRegistrationStore extends BaseStore {
  constructor() {
    super("PushTokenRegistrationStore", DEFAULTS, false);
  }

  get(key: Key): TokenRegistration {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, token: string): GenericObject {
    return super.set(key, {
      token,
      registeredAt: new Date(),
    });
  }
}

export default new PushTokenRegistrationStore();
