import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import mapPreview from "assets/images/mapImages/pgo_map_three_markers_preview.png";
import AppInterop from "common/utils/webInterop/app";

import SimpleCampfireOnboardingView from "common/components/SimpleCampfireOnboardingView";

export type Props = WithTranslation & {
  close: () => void;
};

type State = {};

class PGOCampfireFTUEFlow extends React.Component<Props, State> {
  closeEmbed = (): void => {
    AppInterop.logout();
  };

  render = (): React.ReactNode => {
    return (
      <SimpleCampfireOnboardingView
        title={this.props.t("DISCOVER_ACTIVITY_AROUND_YOU")}
        description={this.props.t("CHECK_OUT_GYMS_RAIDS_ON_ACTIVITY_MAP")}
        imageUrl={mapPreview}
        confirmBtnText={this.props.t("CHECK_OUT_CAMPFIRE")}
        cancelBtnText={this.props.t("NOT_NOW")}
        onConfirm={this.props.close}
        onCancel={this.closeEmbed}
      />
    );
  };
}

export default withTranslation()(PGOCampfireFTUEFlow);
