/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseStore from "common/stores/BaseStore";

type Key = string;

const DEFAULTS = {
  pubNubChannelStatus: {},
};

export default class ActivityStore extends BaseStore {
  constructor(userId: string) {
    super(`ActivityStore:${userId}`, DEFAULTS, false);
  }

  get(key: Key): any {
    return super.get(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(key: Key, value: any): GenericObject {
    return super.set(key, value);
  }
}
