/**
 * @generated SignedSource<<0f1b1e1fbd4a8ca78bb17e2121af5872>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TrackClientActionInput = {
  actionType: string;
  jsonPayload: string;
};
export type TrackClientActionMutation$variables = {
  input: TrackClientActionInput;
};
export type TrackClientActionMutation$data = {
  readonly trackClientAction: {
    readonly success: boolean;
  };
};
export type TrackClientActionMutation = {
  response: TrackClientActionMutation$data;
  variables: TrackClientActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TrackClientActionResponse",
    "kind": "LinkedField",
    "name": "trackClientAction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrackClientActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrackClientActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "70715bf9c81c7ead462c8ef78bab3253",
    "id": null,
    "metadata": {},
    "name": "TrackClientActionMutation",
    "operationKind": "mutation",
    "text": "mutation TrackClientActionMutation(\n  $input: TrackClientActionInput!\n) {\n  trackClientAction(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4beaa6ed1e204b3b86956e9c76ad849";

export default node;
