import { InputChangeEventDetail } from "@ionic/core";
import React from "react";

import ResetAccountDataInput from "common/components/ResetAccountData/components/ResetAccountDataInput";
import useResetAccountDataInputError from "common/components/ResetAccountData/components/ResetAccountDataInputError/useResetAccountDataInputError";
import { InputErrorType } from "common/components/ResetAccountData/resetAccountDataErrors";

export type Props = {
  label: string;
  value: string;
  placeholder: string;
  setValue: (value: string) => void;
  errors: number[];
};

const ResetAccountDataNianticIdInput: React.FC<Props> = (props: Props) => {
  const { label, value, placeholder, setValue, errors } = props;
  const [ResetAccountDataInputError, setErrors] = useResetAccountDataInputError(
    errors,
    InputErrorType.NIANTIC_ID,
  );

  const onChange = (event: CustomEvent<InputChangeEventDetail>) => {
    setErrors([]);

    setValue(event.detail.value || "");
  };

  const clearValue = () => {
    setErrors([]);

    setValue("");
  };

  return (
    <>
      <ResetAccountDataInput
        label={label}
        value={value}
        placeholder={placeholder}
        onClear={clearValue}
        onChange={onChange}
      />

      <ResetAccountDataInputError />
    </>
  );
};

export default ResetAccountDataNianticIdInput;
