import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { getQueryString } from "common/utils/url";
import { QUERY_PARAMS } from "constants/routes";
import { APPROX_TIME_FOR_SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT_MS } from "constants/springAnimations";

import EmbeddedPageWithTopBarWrapper from "common/components/EmbeddedPageWithTopBarWrapper";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import PageLoadError from "common/components/PageLoadError";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import { ClubActivityFeed_page_Query$data as ClubActivityFeedPageQueryResponse } from "__generated__/ClubActivityFeed_page_Query.graphql";

// Lazy Load Main View
const ClubActivityFeedPageMainContent = React.lazy(
  () => import("pages/ClubActivityFeed/components/ClubActivityFeedPageMainContent"),
);

type Props = WithTranslation &
  RouteComponentProps & {
    goBack: () => void;
    clubId: string;
  };

type State = {
  readyToRender: boolean;
  initializedAt: number;
};

type QRProps = {
  me: ClubActivityFeedPageQueryResponse["me"];
  club: ClubActivityFeedPageQueryResponse["club"];
};

const CLUBACTIVITYFEED_PAGE = graphql`
  query ClubActivityFeed_page_Query($clubId: ID!) {
    me {
      ...ClubActivityFeedPageMainContent_me
    }
    club(id: $clubId) {
      ...ClubActivityFeedPageMainContent_club
    }
  }
`;

class ClubActivityFeedPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      readyToRender: false,
      initializedAt: Date.now(),
    };
  }

  componentDidMount = (): void => {
    this.prepareToProceed();
  };

  reInit = (): void => {
    // If there's an error we assume there could have been an issue with the
    // deeplinked event ID that could not be fetched and so prompt the user
    // to reload while clearing that query param
    const eventIdQueryParam = QUERY_PARAMS.clubs.clubId.feed.eventId.key;
    const queryParamUpdates = { [eventIdQueryParam]: undefined };

    this.props.history.replace({
      search: getQueryString(queryParamUpdates, this.props.location.search),
    });

    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  prepareToProceed = (): void => {
    const approxTimeForSlideAnimationMs =
      APPROX_TIME_FOR_SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT_MS;

    setTimeout(() => {
      this.setState({ readyToRender: true });
    }, approxTimeForSlideAnimationMs);
  };

  render = (): React.ReactNode => {
    const { clubId } = this.props;

    return (
      <UIErrorBoundary onRetry={this.onRetry}>
        <Suspense fallback={<LoadingPageDefault />}>
          <EmbeddedPageWithTopBarWrapper hideTopBar>
            <QueryRenderer
              key={this.state.initializedAt}
              environment={environment}
              query={CLUBACTIVITYFEED_PAGE}
              variables={{
                clubId,
              }}
              render={({ props, error }) => {
                const qrProps = props as QRProps;
                const isError = !!error;
                const isLoading = !isError && !props;

                if (isError) {
                  return (
                    <PageLoadError
                      errorMessage={this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
                      reload={this.reInit}
                    />
                  );
                }

                if (isLoading || !this.state.readyToRender) {
                  return (
                    <LoadingPageDefault
                      delayMs={APPROX_TIME_FOR_SLIGHTLY_LONGER_SLIDE_ANIMATION_FOR_CHAT_MS}
                    />
                  );
                }

                return (
                  <ClubActivityFeedPageMainContent
                    me={qrProps.me}
                    club={qrProps.club}
                    goBack={this.props.goBack}
                  />
                );
              }}
            />
          </EmbeddedPageWithTopBarWrapper>
        </Suspense>
      </UIErrorBoundary>
    );
  };
}

const RouterConnected = withRouter(ClubActivityFeedPage);

export default withTranslation()(RouterConnected);
