/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { AppPermissionConsumer, ExposedProps } from "providers/AppPermissionProvider";

export type WithAppPermissionProps = {
  hasPermission: ExposedProps["hasPermission"];
  getPermissionState: ExposedProps["getPermissionState"];
  requestPermission: ExposedProps["requestPermission"];
};

// eslint-disable-next-line max-len
export default <P extends WithAppPermissionProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithAppPermissionProps>): React.ReactElement => (
    <AppPermissionConsumer>
      {({ hasPermission, getPermissionState, requestPermission }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          hasPermission={hasPermission}
          getPermissionState={getPermissionState}
          requestPermission={requestPermission}
          {...props}
        />
      )}
    </AppPermissionConsumer>
  );
};
