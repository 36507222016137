import { RouteComponentProps } from "react-router-dom";

import { getQueryString } from "common/utils/url";
import { MAP_ROUTE, MapAction, QUERY_PARAMS } from "constants/routes";

import { serializeActionData } from "./mapParamHelpers";

const getPathNameForMap = (): string => {
  return `/${MAP_ROUTE}`;
};

export const getSearchParamsForMap = (
  lat: string,
  lng: string,
  mapObjectId: string,
  game: string,
): string => {
  const queryParamUpdates = {
    [QUERY_PARAMS.map.lat.key]: lat,
    [QUERY_PARAMS.map.lng.key]: lng,
    [QUERY_PARAMS.map.mapObjId.key]: mapObjectId,
    [QUERY_PARAMS.map.action.key]: MapAction.SELECT_MAP_OBJECT,
    [QUERY_PARAMS.map.actionData.key]: serializeActionData({
      game,
    }),
  };
  const search = getQueryString(queryParamUpdates);

  return search;
};

export const generateMapObjectUrl = (
  lat: string,
  lng: string,
  mapObjectId: string,
  game: string,
): string => {
  return `${getPathNameForMap()}?${getSearchParamsForMap(lat, lng, mapObjectId, game)}`;
};

export default (
  history: RouteComponentProps["history"],
  lat: string,
  lng: string,
  mapObjectId: string,
  game: string,
): void => {
  history.push({
    pathname: getPathNameForMap(),
    search: getSearchParamsForMap(lat, lng, mapObjectId, game),
  });
};
