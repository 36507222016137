/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { UserProfileConsumer, ExposedProps } from "providers/UserProfileProvider";

export type WithUserProfileProps = {
  viewUser: ExposedProps["viewUser"];
};

// eslint-disable-next-line max-len
export default <P extends WithUserProfileProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithUserProfileProps>): React.ReactElement => (
    <UserProfileConsumer>
      {({ viewUser }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component viewUser={viewUser} {...props} />
      )}
    </UserProfileConsumer>
  );
};
