import classnames from "classnames";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import { getCurrentGameInfo } from "common/utils/nianticGame";
import { isEmbedded } from "common/utils/webInterop";
import AppInterop from "common/utils/webInterop/app";
import { GameInfo } from "constants/nianticGame";

import EmbeddedTopBar from "common/components/EmbeddedTopBar";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  hideTopBar?: boolean;
  children: React.ReactNode;
};

type State = {};

class EmbeddedPageWithTopBarWrapper extends React.Component<Props, State> {
  onClickTopBar = (): void => {
    AppInterop.logout();
  };

  render = (): React.ReactNode => {
    if (!isEmbedded) {
      return this.props.children;
    }

    const gameInfo: GameInfo | undefined = getCurrentGameInfo();
    const gameName = gameInfo ? gameInfo.gameName : this.props.t("GAME");
    const gameLogo = gameInfo ? gameInfo.logoUrl : "";

    return (
      <div className={styles.root}>
        <div
          className={classnames(styles.topBar, {
            [styles.hidden]: this.props.hideTopBar,
          })}
        >
          {!this.props.hideTopBar && (
            <EmbeddedTopBar gameName={gameName} gameLogo={gameLogo} onClick={this.onClickTopBar} />
          )}
        </div>

        <div className={styles.content}>{this.props.children}</div>
      </div>
    );
  };
}

export default withTranslation()(EmbeddedPageWithTopBarWrapper);
