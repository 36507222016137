import React from "react";

import { Navigation, NavigationApp, NavigationAppData } from "common/capacitor/plugins/navigation";

import SelectNavigationAppDrawer from "pages/NewMap/components/SelectNavigationAppDrawer";

type Location = {
  latitude: number;
  longitude: number;
};

export type ExposedProps = {
  navigate: (location: Location) => Promise<void>;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  showSelectNavigationAppDrawer: boolean;
  availableNavigationApps: NavigationAppData[];
  location: Location | null;
};

const INITIAL_CONTEXT: ExposedProps = {
  navigate: () => Promise.resolve(),
};

export const NavigationContext = React.createContext(INITIAL_CONTEXT);

const googleMapsUrl = "https://maps.google.com/?q=";

export class NavigationProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSelectNavigationAppDrawer: false,
      availableNavigationApps: [],
      location: null,
    };
  }

  navigate = async (location: Location): Promise<void> => {
    const appsResponse = await Navigation.getInstalledApps();

    if (appsResponse.apps.length === 0) {
      // If no apps are available, open Google Maps in a browser
      this.openGoogleMapsInBrowser(location.latitude, location.longitude);
    } else if (appsResponse.apps.length === 1) {
      // If only one app is available, no need to show drawer, immediately navigate
      await this.openNavigationApp(appsResponse.apps[0].id, location.latitude, location.longitude);
    } else {
      // If more than one app is available, show the drawer and defer navigation.
      // The drawer will call onSelectNavigationApp when the user selects an app.
      this.setState({
        showSelectNavigationAppDrawer: true,
        availableNavigationApps: appsResponse.apps,
        location,
      });
    }
  };

  openGoogleMapsInBrowser = (latitude: number, longitude: number): void => {
    window.open(`${googleMapsUrl}${latitude},${longitude}`, "_blank");
  };

  openNavigationApp = async (
    appId: NavigationApp,
    latitude: number,
    longitude: number,
  ): Promise<void> => {
    await Navigation.navigate({
      app: appId,
      latitude,
      longitude,
    });
  };

  onSelectNavigationApp = async (appId: NavigationApp): Promise<void> => {
    const { location } = this.state;

    this.setState({
      showSelectNavigationAppDrawer: false,
      availableNavigationApps: [],
      location: null,
    });

    if (location && location.longitude && location.latitude) {
      await this.openNavigationApp(appId, location.latitude, location.longitude);
    }
  };

  reset = (): void => {
    this.setState({
      showSelectNavigationAppDrawer: false,
      availableNavigationApps: [],
    });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <NavigationContext.Provider
        value={{
          navigate: this.navigate,
        }}
      >
        <SelectNavigationAppDrawer
          isOpen={this.state.showSelectNavigationAppDrawer}
          availableApps={this.state.availableNavigationApps}
          onSelectApp={this.onSelectNavigationApp}
          onWillDismiss={this.reset}
          close={this.reset}
        />
        {children}
      </NavigationContext.Provider>
    );
  };
}

export const NavigationConsumer = NavigationContext.Consumer;
