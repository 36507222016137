import { useContext } from "react";

import {
  AppRouterV2CoreNavigationContext,
  ExposedProps,
} from "providers/AppRouterV2CoreNavigationProvider";

type HookReturnType = ExposedProps;

/**
 * Hook to get AppRouterV2CoreNavigationProvider props
 */
function useAppRouterV2CoreNavigation(): HookReturnType {
  const exposedProps = useContext(AppRouterV2CoreNavigationContext);

  return exposedProps;
}

export default useAppRouterV2CoreNavigation;
