/**
 * @generated SignedSource<<78fc14c01ba2242836b48158ea24b45b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type App_me$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AppGlobalFlows_me" | "AppRouterV2_me" | "AuthenticatedServiceProviders_me">;
  readonly " $fragmentType": "App_me";
};
export type App_me$key = {
  readonly " $data"?: App_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"App_me">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "App_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppRouterV2_me"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppGlobalFlows_me"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthenticatedServiceProviders_me"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f2eddd570567c43025f4a99bfdac8ada";

export default node;
