import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import campfireLogo from "assets/images/campfire/campfire-logo.png";

import EmbeddedPageWithTopBarWrapper from "common/components/EmbeddedPageWithTopBarWrapper";
import Image from "common/components/Image";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithTranslation & {};

type State = {};

class ClosedBetaSplash extends React.PureComponent<Props, State> {
  render = (): React.ReactNode => {
    return (
      <EmbeddedPageWithTopBarWrapper>
        <div className={styles.root}>
          <div className={styles.info}>
            <Image alt="campfire" className={styles.campfireLogo} src={campfireLogo} />
            <UISpacer h={12} />
            <UIText variant="h2" weight="bold" color="dark">
              {this.props.t("NIANTIC_CHAT_IS_IN_CLOSED_BETA")}
            </UIText>
            <UISpacer h={12} />
            <UIText variant="h3" weight="medium" color="dark">
              {this.props.t("PLEASE_CHECK_BACK_SOON")}
            </UIText>
          </div>
        </div>
      </EmbeddedPageWithTopBarWrapper>
    );
  };
}

export default withTranslation()(ClosedBetaSplash);
