/**
 * Map of all test ids used in the app. To prevent overlapping test ids,
 * this map should be keyed by the test id.
 *
 * Please keep this list in alphabetical order.
 */
enum TEST_IDS {
  AcceptGroupGuidelinesBtnId = "AcceptGroupGuidelinesBtnId",
  AcceptToSBtnId = "AcceptToSBtnId",
  ActivityCenterTabId = "ActivityCenterTabId",
  ActivityCenterListBtnId = "ActivityCenterListBtnId",
  ActivityCenterMapBtnId = "ActivityCenterMapBtnId",
  AddChannelBtnId = "AddChannelBtnId",
  AddEventBtnId = "AddEventBtnId",
  AddFriendsButtonId = "AddFriendsButtonId",
  AddFriendsSearchInputId = "AddFriendsSearchInputId",
  AddFriendModalConfirmationId = "AddFriendModalConfirmationId",
  AddGroupButton = "CreateNewGroupButtonId",
  AgeEligibilityDateTimePickerId = "AgeEligibilityDateTimePickerId",
  AllowOnlyAdminsToCreateChannelToggleId = "AllowOnlyAdminsToCreateChannelToggleId",
  AllowOnlyAdminsToCreateEventsToggleId = "AllowOnlyAdminsToCreateEventsToggleId",
  AppBarTestId = "AppBarTestId",
  BlockedUsersMenuItemId = "BlockedUsersMenuItemId",
  ChannelNameId = "ChannelNameId",
  ChannelSelectionDrawerCloseBtnId = "ChannelSelectionDrawerCloseBtnId",
  ChannelSettingsEllipsesId = "ChannelSettingsEllipsesId",
  ChannelSettingsSaveBtnId = "ChannelSettingsSaveBtnId",
  ChatInputId = "ChatInputId",
  ClubInviteNotificationAcceptBtnId = "ClubInviteNotificationAcceptBtnId",
  ClubInviteNotificationIgnoreBtnId = "ClubInviteNotificationIgnoreBtnId",
  CreateChannelBtnId = "CreateChannelBtnId",
  CreateDirectMessageLinkId = "CreateDirectMessageLinkId",
  CreateGroupButtonId = "CreateGroupButtonId",
  CreateGroupHeaderId = "CreateGroupHeaderId",
  CreateMeetupBtnId = "CreateMeetupBtnId",
  CreateNewDirectMessageButtonId = "CreateNewDirectMessageButtonId",
  DeleteChannelBtnId = "DeleteChannelBtnId",
  DeclineGroupGuidelinesId = "DeclineGroupGuidelinesId",
  DeleteGroupBtnId = "DeleteGroupBtnId",
  DirectMessageGuidelinesAgreeBtnId = "DirectMessageGuidelinesAgreeBtnId",
  DirectMessageToInputId = "DirectMessageToInputId",
  DirectMessageGuidelinesNoThanksBtnId = "DirectMessageGuidelinesNoThanksBtnId",
  DirectMessagesTabId = "DirectMessagesTabId",
  DoneSendingInvitesBtnId = "DoneSendingInvitesBtnId",
  EditMeetupDrawerItemId = "EditMeetupDrawerItemId",
  EditProfileMenuItemId = "EditProfileMenuItemId",
  EmailNotificationsMenuItemId = "EmailNotificationsMenuItemId",
  EndAdornmentBtnId = "EndAdornmentBtnId",
  EventActionOption1DayId = "EventActionOption1DayId",
  EventActionOption1HourId = "EventActionOption1HourId",
  EventActionOption1WeekId = "EventActionOption1WeekId",
  EventActionOption3HoursId = "EventActionOption3HoursId",
  EventActionOption30MinutesId = "EventActionOption30MinutesId",
  EventActionOptionCustomId = "EventActionOptionCustomId",
  EventDescriptionTextId = "EventDescriptionTextId",
  FloatingTabBarRealityChannelIcon = "FloatingTabBarRealityChannelIcon",
  HomeRealityChannel = "HomeRealityChannelBtnId",
  FriendSuggestionMenuItemId = "FriendSuggestionMenuItemId",
  FriendsButtonId = "FriendsButtonId",
  GetSupportMenuItemId = "GetSupportMenuItemId",
  GroupLabelId = "GroupLabelId",
  GroupLocationInputId = "GroupLocationLabelId",
  GroupMenuIconId = "GroupMenuIconId",
  GroupNameInputId = "GroupNameInputId",
  GroupGameInputId = "GroupGameInputId",
  GroupsTabId = "GroupsTabId",
  GroupVisibilityId = "GroupVisibilityId",
  HeaderBackBtnId = "HeaderBackBtnId",
  HelpCenterMenuItemId = "HelpCenterMenuItemId",
  HomeBaseSaveBtnId = "HomeBaseSaveBtnId",
  InviteFriendsMenuId = "InviteFriendsMenuId",
  LeaveGroupMenuId = "LeaveGroupMenuId",
  LegalMenuItemId = "LegalMenuItemId",
  LocateMeButtonId = "LocateMeButtonId",
  LocationAddressInputId = "LocationAddressInputId",
  LocationSearchResultsId = "GroupLocationSearchResultsId",
  LocationPickerResultId = "LocationPickerResultId",
  LocationPickerResultsEmptyId = "LocationPickerEmptyStateId",
  LogoutMenuItemId = "LogoutMenuItemId",
  ManageAccountMenuItemId = "ManageAccountMenuItemId",
  MapTabId = "MapTabId",
  MeetupsChannelId = "MeetupsChannelId",
  MeetupDeleteBtnId = "MeetupDeleteBtnId",
  MeetupDetailsEllipsesBtnId = "MeetupDetailsEllipsesBtnId",
  MeetupEventCardId = "MeetupEventCardId",
  MeetupNameInputId = "MeetupNameInputId",
  MembersMenuId = "MembersMenuId",
  MessageRequestsMenuItemId = "MessageRequestsMenuItemId",
  ModalCancelBtnId = "ModalCancelBtnId",
  ModalConfirmBtnId = "ModalConfirmBtnId",
  MyProfileTabId = "MyProfileTabId",
  NearbyButtonId = "NearbyButtonId",
  NotificationsMenuId = "NotificationsMenuId",
  NotificationsSettingsGearId = "NotificationsSettingsGearId",
  NotificationsTabId = "NotificationsTabId",
  PgoRealityChannel = "PgoRealityChannelBtnId",
  PushNotificationsMenuItemId = "PushNotificationsMenuItemId",
  PrivacyPolicyOkBtnId = "PrivacyPolicyOkBtnId",
  ProfileDrawerBlockBtnId = "ProfileDrawerBlockBtnId",
  ProfileDrawerCancelBtnId = "ProfileDrawerCancelBtnId",
  ProfileDrawerRemoveFriendBtnId = "ProfileDrawerRemoveFriendBtnId",
  ProfileDrawerReportUserBtnId = "ProfileDrawerReportUserBtnId",
  ProfileDrawerUnblockBtnId = "ProfileDrawerUnblockBtnId",
  ProfileSettingsBtnId = "ProfileSettingsBtnId",
  RealityChannelBtnId = "RealityChannelBtnId",
  RealityChannelNameSelector = "RealityChannelNameSelector",
  ReportMeetupDrawerItemId = "ReportMeetupDrawerItemId",
  ReturnToInviteBtnId = "ReturnToInviteBtnId",
  SaveChangesBtnId = "SaveChangesBtnId",
  SendBtnId = "SendBtnId",
  SettingsMenuId = "SettingsMenuId",
  ShareMeetupDrawerItemId = "ShareMeetupDrawerItemId",
  SkipBtnId = "SkipBtnId",
  SkipConfirmationBtnId = "SkipConfirmationBtnId",
  SuperBlueRealityChannel = "SuperBlueRealityChannelBtnId",
  TextChannelSettingsId = "TextChannelSettingsId",
  TextChannelsMenuId = "TextChannelsMenuId",
  UserProfileEllipsesId = "UserProfileEllipsesId",
  UserProfileAddBtnId = "UserProfileAddBtnId",
  UserProfileCancelBtnId = "UserProfileCancelBtnId",
  UserProfileMessageBtnId = "UserProfileMessageBtnId",
  UpdateAppMenuItemId = "UpdateAppMenuItemId",
  UploadClubPhotoTestId = "UploadClubPhotoTestId",
}

export enum TEST_LIST_ITEM_IDS {
  ChatMessageId = "ChatMessageId",
  FriendInviteNotificationAcceptBtnId = "FriendInviteNotificationAcceptBtnId",
  FriendInviteNotificationDeclineBtnId = "FriendInviteNotificationDeclineBtnId",
  MapClubMarkerTestId = "MapClubMarkerTestId",
  MapNbaAllWorldDropZoneMarkerTestId = "MapNbaAllWorldDropZoneMarkerTestId",
  MapNbaAllWorldCourtMarkerTestId = "MapNbaAllWorldCourtMarkerTestId",
  MapMeetupMarkerTestId = "MapMeetupMarkerTestId",
  MapPgoGymMarkerTestId = "MapPgoGymMarkerTestId",
}
// TODO: @Marcus Add new enum TEST_NAMES and export, these are attributes for data-test-name
export default TEST_IDS;
