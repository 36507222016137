import classnames from "classnames";
import React from "react";

import { getAllGameShortCodes } from "common/utils/nianticGame";
import GAMES_BY_SHORT_CODE, { GameInfo, GameShortCode } from "constants/nianticGame";

import Image from "common/components/Image";
import UIDivButton from "common/components/UIDivButton";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = {
  className?: string;
};

type State = {};

const MAX_APPS_TO_SHOW = 5;

export default class AllAppDisplay extends React.Component<Props, State> {
  renderSingleApp = (shortCode: GameShortCode): React.ReactNode => {
    const appInfo: GameInfo = GAMES_BY_SHORT_CODE[shortCode];

    return <Image key={shortCode} alt="Logo" className={styles.logo} src={appInfo.logoUrl} />;
  };

  renderExtraAppIndicator = (extraApps: number): React.ReactNode => {
    return (
      <UIDivButton color="light" className={classnames(styles.extraAppIndicator, styles.logo)}>
        <UIText variant="h3" weight="bold">
          {`+${extraApps}`}
        </UIText>
      </UIDivButton>
    );
  };

  render = (): React.ReactNode => {
    // TODO: We might want to show a dynamic number based on screen size.
    // We might also want to randomize the order so no app gets priority
    let appShortCodes = getAllGameShortCodes();
    const originalLength = appShortCodes.length;
    const hasExtraItems = originalLength > MAX_APPS_TO_SHOW;

    if (hasExtraItems) {
      appShortCodes = appShortCodes.slice(0, MAX_APPS_TO_SHOW);
    }

    return (
      <div className={classnames(styles.appDisplayParent, this.props.className)}>
        {appShortCodes.map((shortCode) => this.renderSingleApp(shortCode))}
        {hasExtraItems && this.renderExtraAppIndicator(originalLength - MAX_APPS_TO_SHOW)}
      </div>
    );
  };
}
