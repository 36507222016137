import { useEffect } from "react";
import { usePreloadedQuery } from "react-relay";

import { PreloadedQueryReference } from "common/components/RealityChannelSelection/index";
import { PRELOADED_QUERY } from "common/components/RealityChannelSelection/preloader";

import {
  preloader_RealityChannelSelection_Query as RealityChannelSelectionPreloaderQuery,
  preloader_RealityChannelSelection_Query$data as RealityChannelSelectionPreloaderQueryResponse,
} from "__generated__/preloader_RealityChannelSelection_Query.graphql";

type Props = {
  onLoaded: (data: RealityChannelSelectionPreloaderQueryResponse) => void;
  queryReference: PreloadedQueryReference;
};

/* 
 Component that is used inside the preloader to provide away to trigger a callback
  with the data after the preloaded query finishes
*/
const QueryLoadMonitor = (props: Props): null => {
  const data = usePreloadedQuery<RealityChannelSelectionPreloaderQuery>(
    PRELOADED_QUERY,
    props.queryReference,
  );

  useEffect(() => {
    if (data) {
      props.onLoaded(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return null;
};

export default QueryLoadMonitor;
