import { LocalNotificationSchema } from "@capacitor/local-notifications";
import environment from "common/relay/relay-env";
import { RouteComponentProps } from "react-router-dom";

import logException from "common/analytics/exceptions";
import { PushNotificationSchema } from "common/capacitor/plugins/campfire-push-notifications";
import routeToClubChannel from "common/utils/routing/routeToClubChannel";
import routeToClubChannelByMessageId from "common/utils/routing/routeToClubChannelByMessageId";
import routeToClubChannelTimetokenDeprecated from "common/utils/routing/routeToClubChannelTimetoken_deprecated";
import routeToDMChannel from "common/utils/routing/routeToDMChannel";
import routeToMessageRequests from "common/utils/routing/routeToMessageRequests";
import routeToNotifications from "common/utils/routing/routeToNotifications";
import entryPointHistory, { EntryPoint } from "common/utils/telemetry/entryPointHistory";
import { Notification } from "constants/notification";
import TrackClientPushNotificationActionMutation from "mutations/TrackClientPushNotificationActionMutation";

type ActivityChannelActivityMetadata = {
  type: Notification;
  clubId: string;
  channelId: string;
  pubnubTimetoken: string;
};

type ActivityJoinedMetadata = {
  type: Notification;
  clubId: string;
  channelId: string;
};

type ClubJoinedMetadata = {
  type: Notification;
  clubId: string;
};

type DirectMessageMetadata = {
  type: Notification;
  channelId: string;
};

type ClubMessageMetadata = {
  type: Notification;
  clubId: string;
  channelId: string;
  // TODO: @mchen remove this temp old support
  pubnubTimetoken?: string;
  chatMessageTimestampMs: string;
  chatv2MessageId: string;
};

type MapNotificationMetadata = {
  type: Notification;
  campfirePayload: string;
};

type UntypedNotificationMetadata = {
  type: Notification;
  campfirePayload?: string;
};

type EventMetadata = {
  type: Notification;
  campfirePayload: string;
};

type NotificationMetadata =
  | ActivityChannelActivityMetadata
  | ActivityJoinedMetadata
  | ClubJoinedMetadata
  | DirectMessageMetadata
  | ClubMessageMetadata
  | MapNotificationMetadata
  | EventMetadata
  | UntypedNotificationMetadata;

export default class PushActionHandler {
  routeComponentProps: RouteComponentProps;

  constructor(routeComponentProps: RouteComponentProps) {
    this.routeComponentProps = routeComponentProps;
  }

  handleLocalAction = (notification: LocalNotificationSchema): void => {
    const metadata = notification.extra;

    this.handleAction(metadata);
  };

  handlePushAction = (notification: PushNotificationSchema): void => {
    const metadata = notification.data;

    this.handlePushTelemetry(metadata);
    this.handleAction(metadata);
  };

  handlePushTelemetry = (metadata: NotificationMetadata): void => {
    const payload = {
      notificationType: metadata.type,
      jsonPayload: {},
    };

    try {
      TrackClientPushNotificationActionMutation.commit(environment, payload);
    } catch (error) {
      logException(
        "TrackClientPushNotificationActionMutation",
        "handlePushTelemetry",
        "pushActionHandler",
        error,
      );
    }
  };

  handleAction = (metadata: NotificationMetadata): void => {
    const notificationType = metadata.type;

    switch (notificationType) {
      case Notification.CLUB_INVITE_RECEIVED:
      case Notification.FRIEND_INVITE_RECEIVED:
        this.handleNotificationsAction();
        break;
      case Notification.MENTION:
        this.handleClubMessageAction(metadata as ClubMessageMetadata);
        break;
      case Notification.DIRECT_MESSAGE_MENTION:
      case Notification.DIRECT_MESSAGE:
        this.handleDirectMessageAction(metadata as DirectMessageMetadata);
        break;
      case Notification.MESSAGE_REQUEST:
        this.handleMessageRequestAction();
        break;
      case Notification.BEACON_SAME_LIT:
      case Notification.BEACON_FRIEND_LIT:
      case Notification.POST_CREATED:
        this.handleMapAction(metadata as MapNotificationMetadata);
        break;
      case Notification.EVENT_CREATED:
      case Notification.EVENT_SOON:
        this.handleEventAction(metadata as EventMetadata);
        break;
      default:
        this.handleUntypedNotificationAction(metadata as UntypedNotificationMetadata);
        break;
    }
  };

  // Handles notifications that need to route to the Notifications page
  handleNotificationsAction = (): void => {
    routeToNotifications(this.routeComponentProps);
  };

  // Handles direct message notifications.
  handleDirectMessageAction = (directMessageMetadata: DirectMessageMetadata): void => {
    entryPointHistory.addEntryPoint(EntryPoint.NOTIFICATIONS_DM);
    routeToDMChannel(this.routeComponentProps, directMessageMetadata.channelId);
  };

  // Handles notifications that route to a club channel message.
  handleClubMessageAction = (mentionMetadata: ClubMessageMetadata): void => {
    if (mentionMetadata.chatv2MessageId) {
      routeToClubChannelByMessageId(
        this.routeComponentProps,
        mentionMetadata.clubId,
        mentionMetadata.channelId,
        mentionMetadata.chatv2MessageId,
      );
    } else if (mentionMetadata.pubnubTimetoken) {
      routeToClubChannelTimetokenDeprecated(
        this.routeComponentProps,
        mentionMetadata.clubId,
        mentionMetadata.channelId,
        mentionMetadata.pubnubTimetoken,
      );
    } else {
      routeToClubChannel(
        this.routeComponentProps,
        mentionMetadata.clubId,
        mentionMetadata.channelId,
      );
    }
  };

  // Handles message request notifications.
  handleMessageRequestAction = (): void => {
    routeToMessageRequests(this.routeComponentProps);
  };

  // Handles event notifications.
  handleEventAction = (metadata: EventMetadata): void => {
    this.routeComponentProps.history.push(metadata.campfirePayload);
  };

  handleMapAction = (metadata: MapNotificationMetadata): void => {
    this.routeComponentProps.history.push(metadata.campfirePayload);
  };

  // Fallback for notifications that don't have a defined type.
  // If the notification contains the campfirePayload key, we can use its contents
  // as the location to route to.
  handleUntypedNotificationAction = (metadata: UntypedNotificationMetadata): void => {
    if (metadata.campfirePayload) {
      this.routeComponentProps.history.push(metadata.campfirePayload);
    }
  };
}
