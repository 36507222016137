/* eslint-disable react/self-closing-comp */
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

import UIAvatar from "common/components/Avatar/UIAvatar";
import UIAsyncButton from "common/components/UIAsyncButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  avatarUrl: string;
  title: string;
  close: () => void;
  onClickVisitClub: () => Promise<void>;
};

type State = {};

class WelcomeToClubDialog extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { title } = this.props;

    return (
      <div className={styles.root}>
        <UIAvatar
          size={120}
          avatarUrl={this.props.avatarUrl}
          name={this.props.title}
          avatarStyle="square"
        />

        <UISpacer h={12} />

        <UIText color="dark" variant="h2" weight="bold">
          {this.props.title}
        </UIText>

        <UISpacer h={10} />

        <Trans i18nKey="WELCOME_TO_CAMPFIRE">
          <UIText color="dark" variant="h4" weight="medium">
            Welcome to Niantic Chat! Stop by
          </UIText>
          <UIText color="primary" variant="h4" weight="bold">
            {{ title }}
          </UIText>
          <UIText color="dark" variant="h4" weight="medium">
            and say 👋
          </UIText>
        </Trans>

        <UISpacer h={20} />

        <div className={styles.action}>
          <UIAsyncButton color="secondary" expand="block" onClick={this.props.onClickVisitClub}>
            <UIText variant="h4" weight="bold">
              {this.props.t("TAKE_ME_THERE")}
            </UIText>
          </UIAsyncButton>
        </div>
      </div>
    );
  };
}

export default withTranslation()(WelcomeToClubDialog);
