/**
 * @generated SignedSource<<a7531cf8f4aeb17879713248023b09a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountSetupFlowQR_Query$variables = {};
export type AccountSetupFlowQR_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountSetupFlow_me">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AccountSetupFlow_query">;
};
export type AccountSetupFlowQR_Query = {
  response: AccountSetupFlowQR_Query$data;
  variables: AccountSetupFlowQR_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSetupFlowQR_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccountSetupFlow_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountSetupFlow_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountSetupFlowQR_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FriendRecommendationsResponse",
        "kind": "LinkedField",
        "name": "getFriendRecommendations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatarUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPendingFriendInviteFromMe",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mutualFriendCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGameProfile",
            "kind": "LinkedField",
            "name": "gameProfiles",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "game",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "codename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "faction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "factionColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "level",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastPlayedTimestampMs",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isIncludeAsFriendSuggestionAllowed",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "758f0ff9cdb943b23c4c678fdf1eb39c",
    "id": null,
    "metadata": {},
    "name": "AccountSetupFlowQR_Query",
    "operationKind": "query",
    "text": "query AccountSetupFlowQR_Query {\n  ...AccountSetupFlow_query\n  me {\n    ...AccountSetupFlow_me\n    id\n  }\n}\n\nfragment AccountSetupFlow_me on User {\n  gameProfiles {\n    id\n    game\n    visibility\n  }\n  ...AccountSetupNianticGamesSlide_me\n  ...AccountSetupNianticFriendsSlide_me\n}\n\nfragment AccountSetupFlow_query on Query {\n  getFriendRecommendations {\n    reason\n  }\n  ...AccountSetupNianticFriendsSlide_query\n}\n\nfragment AccountSetupNianticFriendsSlide_me on User {\n  isIncludeAsFriendSuggestionAllowed\n}\n\nfragment AccountSetupNianticFriendsSlide_query on Query {\n  getFriendRecommendations {\n    user {\n      id\n      username\n      avatarUrl\n      hasPendingFriendInviteFromMe\n    }\n    reason\n    mutualFriendCount\n  }\n}\n\nfragment AccountSetupNianticGamesSlide_me on User {\n  gameProfiles {\n    id\n    game\n    visibility\n  }\n  ...OnboardNianticGames_me\n}\n\nfragment OnboardNianticGames_me on User {\n  displayName\n  username\n  gameProfiles {\n    id\n    visibility\n  }\n  ...UserAppListVisibilitySelection_me\n}\n\nfragment UserAppListVisibilitySelection_me on User {\n  gameProfiles {\n    id\n    game\n    codename\n    faction\n    factionColor\n    visibility\n    level\n    lastPlayedTimestampMs\n  }\n}\n"
  }
};
})();

(node as any).hash = "dfd3d613a9df19d25ddabf1aeb8cb023";

export default node;
