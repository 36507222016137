/**
 * @generated SignedSource<<5629d3e96c951881500da05fbb23b6a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TransferClubOwnershipInput = {
  clubId: string;
  userId: string;
};
export type TransferClubOwnershipMutation$variables = {
  input: TransferClubOwnershipInput;
};
export type TransferClubOwnershipMutation$data = {
  readonly transferClubOwnership: {
    readonly club: {
      readonly creator: {
        readonly id: string;
      } | null;
      readonly id: string;
    };
  };
};
export type TransferClubOwnershipMutation = {
  response: TransferClubOwnershipMutation$data;
  variables: TransferClubOwnershipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TransferClubOwnershipResponse",
    "kind": "LinkedField",
    "name": "transferClubOwnership",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransferClubOwnershipMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransferClubOwnershipMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e8b8f2c1d3944459832919874a786988",
    "id": null,
    "metadata": {},
    "name": "TransferClubOwnershipMutation",
    "operationKind": "mutation",
    "text": "mutation TransferClubOwnershipMutation(\n  $input: TransferClubOwnershipInput!\n) {\n  transferClubOwnership(input: $input) {\n    club {\n      id\n      creator {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9034451b74c6126b3d799233c58c6727";

export default node;
