import classnames from "classnames";
import React, { HTMLAttributes } from "react";

import styles from "./styles.scss";

export type Props = HTMLAttributes<HTMLDivElement> & {
  color?: IonicThemeShade;
  dataTestId?: string;
};

/**
 * This component was brought it during theming, since ionic by default handles coloration on different states.
 * For example when you have an IonButton and click on it the text inside may go from blue to white etc.
 * Ionic has a contrast color defined for the main color that color will automatically get applied depending on the state.
 * When looking into theming we realized we have some divs that act like buttons, but aren't buttons.
 * In order to still get the benefits of the ionic coloring we created UIDivButton.
 * Its a div, thats mimics the color styling that an IonButton provides.
 * Should only be used for the unique cases where you have very custom button.
 */
export default (props: Props): React.ReactElement => {
  const { className, dataTestId, ...remainingProps } = props;
  const colorClass = props.color ? `ion-color-${props.color}` : "";

  return (
    <div
      className={classnames(styles.root, colorClass, className)}
      data-test-id={dataTestId}
      {...remainingProps}
    />
  );
};
