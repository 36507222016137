/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { ExternalLinkConsumer, ExposedProps } from "providers/ExternalLinkProvider";

export type WithExternalLinkProps = {
  onClickUrl: ExposedProps["onClickUrl"];
};

export default <P extends WithExternalLinkProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithExternalLinkProps>): React.ReactElement => (
    <ExternalLinkConsumer>
      {({ onClickUrl }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component onClickUrl={onClickUrl} {...props} />
      )}
    </ExternalLinkConsumer>
  );
};
