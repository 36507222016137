import classnames from "classnames";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Fire } from "assets/icons/Flare.svg";
import { ReactComponent as People } from "assets/icons/Groups.svg";
import { ReactComponent as ChatBubble } from "assets/icons/Message.svg";
import { getDeepLinkToApp } from "common/utils/getDeepLinkWithEncodedParams";
import AppInterop from "common/utils/webInterop/app";

import SimpleCampfireOnboardingView from "common/components/SimpleCampfireOnboardingView";
import UIDialog from "common/components/UIDialog";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = {
  isOpen: boolean;
  close: () => void;
};

const DELAY_CLOSE_EMBED_MS = 1000;

const GetCampfireDialog: FunctionComponent<Props> = (props: Props): React.ReactElement => {
  const { t } = useTranslation();

  const deepLinkToCampfireApp = getDeepLinkToApp();

  const onConfirm = (): void => {
    // [CAMP-2011] Close embed when user taps to install campfire
    // Delaying this seems to work on iOS, android is unclear since sample app for whatever
    // reason keeps opening the embed again on foregrounding it regardless of the delay. Given
    // there's no change between that, this seems like it's fine on Android as well.
    setTimeout(() => {
      AppInterop.logout();
      props.close();
    }, DELAY_CLOSE_EMBED_MS);
  };

  const renderCustomBody = (): React.ReactNode => {
    const renderVerticallyCenteredCell = (content: React.ReactNode) => {
      return <div className={classnames(styles.tableCell, styles.centered)}>{content}</div>;
    };

    const renderLeftAlignedCell = (content: React.ReactNode) => {
      return <div className={classnames(styles.tableCell, styles.leftAligned)}>{content}</div>;
    };

    return (
      <div className={styles.featureList}>
        <div className={styles.tableRow}>
          {renderVerticallyCenteredCell(<ChatBubble className={styles.icon} />)}
          {renderLeftAlignedCell(
            <UIText color="dark" weight="medium" variant="subtitle2">
              {t("CHAT_WITH_OTHER_TRAINERS")}
            </UIText>,
          )}
        </div>

        <div className={styles.tableRow}>
          {renderVerticallyCenteredCell(<Fire className={styles.icon} />)}
          {renderLeftAlignedCell(
            <UIText color="dark" weight="medium" variant="subtitle2">
              {t("LIGHT_FLARES_AT_NEARBY_GYMS")}
            </UIText>,
          )}
        </div>

        <div className={styles.tableRow}>
          {renderVerticallyCenteredCell(<People className={styles.icon} />)}
          {renderLeftAlignedCell(
            <UIText color="dark" weight="medium" variant="subtitle2">
              {t("CONNECT_WITH_LOCAL_COMMUNITIES")}
            </UIText>,
          )}
        </div>
      </div>
    );
  };

  return (
    <UIDialog
      isOpen={props.isOpen}
      type="floating-center"
      onDidDismiss={props.close}
      close={props.close}
    >
      <SimpleCampfireOnboardingView
        title={t("GET_CAMPFIRE")}
        description={t("DOWNLOAD_THE_APP_FOR_FULL_EXPERIENCE")}
        confirmBtnText={t("DOWNLOAD_CAMPFIRE_APP").toLocaleUpperCase()}
        confirmBtnHref={deepLinkToCampfireApp}
        cancelBtnText={t("NOT_NOW")}
        onConfirm={onConfirm}
        onCancel={props.close}
        renderBodyContent={renderCustomBody}
      />
    </UIDialog>
  );
};

export default GetCampfireDialog;
