// eslint-disable-next-line import/prefer-default-export
export const getTranslateYPositionPx = (
  percentage: number,
  element: HTMLElement | undefined,
): number => {
  if (!element) {
    return 0;
  }

  return Math.floor(element.clientHeight * percentage);
};
