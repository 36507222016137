/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import {
  SelectedRealityChannelConsumer,
  ExposedProps,
} from "providers/SelectedRealityChannelProvider";

export type WithSelectedRealityChannelProps = {
  selectedRealityChannelProvider: ExposedProps;
};

// eslint-disable-next-line max-len
export default <P extends WithSelectedRealityChannelProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithSelectedRealityChannelProps>): React.ReactElement => (
    <SelectedRealityChannelConsumer>
      {(exposedProps: ExposedProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component selectedRealityChannelProvider={exposedProps} {...props} />
      )}
    </SelectedRealityChannelConsumer>
  );
};
