/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { KeyboardConsumer, ExposedProps } from "providers/KeyboardProvider";

export type WithKeyboardProps = {
  keyboard: {
    isKeyboardOpen: ExposedProps["isKeyboardOpen"];
    dispatchKeyboardWillShow: ExposedProps["dispatchKeyboardWillShow"];
    dispatchKeyboardDidShow: ExposedProps["dispatchKeyboardDidShow"];
    dispatchKeyboardWillHide: ExposedProps["dispatchKeyboardWillHide"];
    dispatchKeyboardDidHide: ExposedProps["dispatchKeyboardDidHide"];
  };
};

// eslint-disable-next-line max-len
export default <P extends WithKeyboardProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithKeyboardProps>): React.ReactElement => (
    <KeyboardConsumer>
      {({
        isKeyboardOpen,
        dispatchKeyboardWillShow,
        dispatchKeyboardDidShow,
        dispatchKeyboardWillHide,
        dispatchKeyboardDidHide,
      }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          keyboard={{
            isKeyboardOpen,
            dispatchKeyboardWillShow,
            dispatchKeyboardDidShow,
            dispatchKeyboardWillHide,
            dispatchKeyboardDidHide,
          }}
          {...props}
        />
      )}
    </KeyboardConsumer>
  );
};
