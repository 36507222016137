import { InputChangeEventDetail } from "@ionic/core";
import { IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from "@ionic/react";
import { globeOutline, lockClosed, people } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { GAME_PROFILE_VISIBILITY_TO_TRANSLATION_KEY, GameProfileVisibility } from "constants/user";

import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  selectedVisibility: GameProfileVisibility;
  onSelectVisibility: (visibility: GameProfileVisibility) => void;
};

type State = {};

const GAME_PROFILE_VISIBILITY_TO_ICON = {
  [GameProfileVisibility.EVERYONE]: globeOutline,
  [GameProfileVisibility.FRIENDS]: people,
  [GameProfileVisibility.PRIVATE]: lockClosed,
};

/**
 * Stateless component that renders within a UIDrawer, allowing user to choose a GameProfileVisibility.
 */
class UserAppVisibilitySelectionDrawer extends React.Component<Props, State> {
  onSelectedVisibilityChange = (event: CustomEvent<InputChangeEventDetail>): void => {
    if (event.detail.value) {
      const selectedVisibility = event.detail.value as GameProfileVisibility;

      this.props.onSelectVisibility(selectedVisibility);
    }
  };

  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIText variant="h3" color="dark" weight="bold">
          {this.props.t("GAME_PROFILE_VISIBILITY_PROMPT")}
        </UIText>
        <UISpacer h={16} />
        <IonRadioGroup
          value={this.props.selectedVisibility}
          onIonChange={this.onSelectedVisibilityChange}
        >
          <IonList mode="md" lines="none">
            {Object.values(GameProfileVisibility).map((visibility) => {
              const labelKey = GAME_PROFILE_VISIBILITY_TO_TRANSLATION_KEY[visibility];

              return (
                <IonItem key={visibility}>
                  <IonLabel className={styles.label} color="dark">
                    <UIIcon icon={GAME_PROFILE_VISIBILITY_TO_ICON[visibility]} size={20} />
                    <UISpacer w={16} />
                    <UIText variant="body1" weight="bold">
                      {this.props.t(labelKey)}
                    </UIText>
                  </IonLabel>
                  <IonRadio value={visibility} slot="end" mode="md" color="dark" />
                </IonItem>
              );
            })}
          </IonList>
        </IonRadioGroup>
      </div>
    );
  };
}

export default withTranslation()(UserAppVisibilitySelectionDrawer);
