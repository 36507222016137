import { IonIcon } from "@ionic/react";
import classnames from "classnames";
import { ellipse, ellipseOutline } from "ionicons/icons";
import React from "react";

import styles from "./styles.scss";

type Props = {
  presenceStatus: PresenceStatus;
  size?: number;
  className?: string;
  styleOverride?: Record<string, string | undefined>;
};

type State = {};

const DEFAULT_SIZE = 12;
const VISIBLE_STATUSES: PresenceStatus[] = ["online", "offline"];

const STATUS = {
  online: {
    iconOutline: ellipseOutline,
  },
  offline: {
    iconOutline: ellipseOutline,
  },
};

export default class PresenceStatusIcon extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { styleOverride = {} } = this.props;
    const isSupportedStatus = VISIBLE_STATUSES.includes(this.props.presenceStatus);

    if (!isSupportedStatus) {
      return null;
    }

    // Unfortunately, due to the shadow dom that IonIcons use, we cannot target the svg and adjust
    // the fill color. So to get a fill color, we overlay 2 icons, one for the outline,
    // the other for the fill color.
    const iconSizeInPixels = this.props.size || DEFAULT_SIZE;

    // We shave 1 px off from the filling icon, so that theres less bleed from the fill
    // to the outline border.
    const fillSizeInPixels = Math.max(0, iconSizeInPixels - 2);
    const styleConfig = STATUS[this.props.presenceStatus] || {};

    return (
      <div
        className={classnames(styles.root, this.props.className)}
        style={{
          width: `${iconSizeInPixels}px`,
          height: `${iconSizeInPixels}px`,
          ...styleOverride,
        }}
      >
        <IonIcon
          className={classnames(styles.fill, {
            [styles.online]: this.props.presenceStatus === "online",
            [styles.offline]: this.props.presenceStatus === "offline",
          })}
          icon={ellipse}
          style={{ fontSize: `${fillSizeInPixels}px` }}
        />

        <IonIcon
          className={classnames(styles.outline)}
          icon={styleConfig.iconOutline}
          style={{ fontSize: `${iconSizeInPixels}px` }}
        />
      </div>
    );
  };
}
