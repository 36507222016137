import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

import { FEATURE_FLAGS } from "constants/featureFlags";
import {
  EXPERIENCE_ROUTE,
  MAGELLAN_EXPERIENCE_PARTY_ROUTE,
  MAGELLAN_EXPERIENCE_ROUTE,
  MEDIA_SHARE_EXPERIENCE_ROUTE,
  MESSAGES_ROUTE,
  UNKNOWN_ROUTE,
} from "constants/routes";

import MagellanExperiencePage from "pages/ExperienceMagellan";
import MediaShareExperiencePage from "pages/ExperienceMediaShare";

type Props = WithFeatureFlagProps & {};

type State = {};

class ExperiencePage extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const hasEnabledMagellanExperienceFeatureFlag = this.props.hasFeatureFlag(
      FEATURE_FLAGS.MHN_EXPERIENCE,
    );

    return (
      <Switch>
        <Route exact path={`/${EXPERIENCE_ROUTE}/${MEDIA_SHARE_EXPERIENCE_ROUTE}`}>
          <MediaShareExperiencePage />
        </Route>

        {/* Router doesn't like fragments so we have to separate it out like this. */}
        {/* First define the specific Magellan experience routes that are accessible. */}
        {hasEnabledMagellanExperienceFeatureFlag && (
          <Route
            exact
            path={[
              `/${EXPERIENCE_ROUTE}/${MAGELLAN_EXPERIENCE_ROUTE}/${MAGELLAN_EXPERIENCE_PARTY_ROUTE}`,
              `/${EXPERIENCE_ROUTE}/${MAGELLAN_EXPERIENCE_ROUTE}/${MESSAGES_ROUTE}`,
            ]}
          >
            <MagellanExperiencePage />
          </Route>
        )}

        {/* Then setup the redirect from the base Magellan experience route to the default route (which is the party). */}
        {hasEnabledMagellanExperienceFeatureFlag && (
          // Catch-all redirect for /experience/mhn/* if it doesn't match any of the above routes
          <Redirect
            from={`/${EXPERIENCE_ROUTE}/${MAGELLAN_EXPERIENCE_ROUTE}`}
            to={`/${EXPERIENCE_ROUTE}/${MAGELLAN_EXPERIENCE_ROUTE}/${MAGELLAN_EXPERIENCE_PARTY_ROUTE}`}
          />
        )}

        <Redirect to={`/${UNKNOWN_ROUTE}`} />
      </Switch>
    );
  };
}

const FeatureFlagConnected = withFeatureFlag(ExperiencePage);

export default FeatureFlagConnected;
