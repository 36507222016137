import { IonLabel } from "@ionic/react";
import classnames from "classnames";
import React from "react";

import MenuItem from "common/components/MenuGroup/components/MenuItem";
import MenuItemLink from "common/components/MenuGroup/components/MenuItemLink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

// Connected menu group items appear as a single box. Disconnected, each menu item
// appears as a seperate box.
type MenuGroupVariant = "connected" | "disconnected";

type InheritedProps = {
  variant?: MenuGroupVariant;
};

export type DefinableProps = {
  name?: string;
  // If children is defined, we assume we want custom content
  children?: React.ReactNode;
  className?: string;
  description?: string;
  disabled?: boolean;
  onClick?: () => void;
  color?: IonicThemeShade;
  dataTestId?: string;
  renderEndAdornment?: () => React.ReactNode;
  hideEndAdornment?: boolean;
};

export type IMenuItemProps = DefinableProps & InheritedProps;

type NullChild = false | Element;

export type Props = {
  variant?: MenuGroupVariant;
  disabled?: boolean;
  label?: string;
  children:
    | React.ReactElement<DefinableProps>
    | Array<React.ReactElement<DefinableProps> | NullChild>;
};

type State = {};

const DEFAULT_VARIANT: MenuGroupVariant = "connected";
const SUPPORTED_MENU_ITEMS = [MenuItem, MenuItemLink];

export default class MenuGroup extends React.Component<Props, State> {
  findAllMenuItemsDirectChildren = (): Array<React.ReactElement<DefinableProps>> => {
    return React.Children.toArray(this.props.children).filter((child) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return SUPPORTED_MENU_ITEMS.includes(child.type);
    }) as Array<React.ReactElement<DefinableProps>>;
  };

  overrideMenuItemPropsIfNeeded = (
    menuItems: Array<React.ReactElement<DefinableProps>>,
  ): Array<React.ReactElement<IMenuItemProps>> => {
    const variant = this.props.variant || DEFAULT_VARIANT;

    return menuItems.map((menuItem: React.ReactElement<DefinableProps>) => {
      const isDisabled = this.props.disabled || menuItem.props.disabled;

      return React.cloneElement<IMenuItemProps>(menuItem, {
        ...menuItem.props,
        // If the entire group is disabled, each menu item is also disabled
        disabled: isDisabled,
        // Click handler is removed when menu item is disabled
        onClick: isDisabled ? undefined : menuItem.props.onClick,
        variant,
      }) as React.ReactElement<IMenuItemProps>;
    });
  };

  render = (): React.ReactNode => {
    const variant = this.props.variant || DEFAULT_VARIANT;

    // Find all MenuItem's inside.
    const allMenuItems = this.findAllMenuItemsDirectChildren();

    // Override some props on each menu item if needed. Such as disabling each item when the group
    // is disabled.
    const updatedMenuItems = this.overrideMenuItemPropsIfNeeded(allMenuItems);

    // If the group is disabled or all items inside are disabled, present the entire group as disabled.
    const isGroupDisabled =
      this.props.disabled || updatedMenuItems.every((item) => item.props.disabled);

    return (
      <div className={styles.root}>
        {this.props.label && (
          <>
            <IonLabel
              position="stacked"
              className={classnames(styles.label, {
                [styles.disabled]: isGroupDisabled,
              })}
            >
              <UIText color="medium" weight="bold">
                {this.props.label}
              </UIText>
            </IonLabel>
            <UISpacer h={4} />
          </>
        )}

        <div
          className={classnames(styles.menuGroup, {
            [styles.connected]: variant === "connected",
            [styles.disconnected]: variant === "disconnected",
          })}
        >
          {updatedMenuItems}
        </div>
      </div>
    );
  };
}

export { MenuItem, MenuItemLink };
