import { IonIcon } from "@ionic/react";
import React, { HTMLAttributes } from "react";

export type Props = HTMLAttributes<HTMLIonIconElement> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  color?: IonicThemeShade; // Leave undefined to inherit color.
  size?: number;
  slot?: "end" | "icon-only" | "start";
  dataTestId?: string;
};

type State = {};

/**
 * Can find Iconic ions here https://ionic.io/ionicons
 * Also have a few custom ones located src/assets/icons
 */
export default class UIIcon extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { className, icon, color, size, slot, dataTestId } = this.props;

    return (
      <IonIcon
        className={className}
        icon={icon}
        color={color}
        data-test-id={dataTestId}
        style={{
          fontSize: size ? `${size}px` : undefined,
        }}
        slot={slot || "icon-only"}
        onClick={this.props.onClick}
      />
    );
  };
}
