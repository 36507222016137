import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import classnames from "classnames";
import React, { FunctionComponent, useCallback, useRef, useState, useEffect } from "react";

import UIPopperFade from "./fade";
import { UIPopperTransitionProps } from "./transitionTypes";

import styles from "./styles.scss";

export type VirtualElement = {
  getBoundingClientRect: () => DOMRect;
  contextElement?: Element;
};

export type Placement =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "right"
  | "right-start"
  | "right-end"
  | "left"
  | "left-start"
  | "left-end";

type PopperPaddingFnArgument = {
  popper: Element;
  reference: Element;
  placement: Placement;
};

export type Props = {
  isOpen: boolean;
  renderPopperContent: () => React.ReactNode;
  children?: React.ReactNode;
  container?: Element;
  placement?: Placement;
  className?: string;
  popperRootClassName?: string;
  popperContentClassName?: string;
  popperModifiers?: GenericObject[];
  showArrow?: boolean;
  transitionPopperContent?: "fade";
  customTransitionPopperComponent?: React.ComponentType<UIPopperTransitionProps>;
  onClickPopperContent?: () => void;
  onClickAway?: () => void;
};

const CARET_SIZE = 16;

const generateGetBoundingClientRect = (x = 0, y = 0) => {
  return () =>
    ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    } as DOMRect);
};

const virtualElement: VirtualElement = {
  getBoundingClientRect: generateGetBoundingClientRect(),
};

export const UIPopper: FunctionComponent<Props> = (props: Props): React.ReactElement => {
  const {
    isOpen,
    children,
    className,
    popperRootClassName = "",
    popperContentClassName = "",
    placement = "auto",
    showArrow = false,
    popperModifiers = [],
    transitionPopperContent,
    onClickAway,
  } = props;

  const anchorElRef = useRef<HTMLDivElement>(null);
  const [, updateState] = useState();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const forceUpdate = useCallback(() => updateState({}), []);

  const onClickPopperContent = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.onClickPopperContent) {
      props.onClickPopperContent();
    }
  };

  const defaultContainer = document.querySelector("ion-app") || undefined;
  const containerEl: Element | undefined = props.container || defaultContainer;

  // Function to find the anchor element where the content we are trying to anchor to lives.
  // When Ionic displays an ion-page to display: none, popper.js will fail to position this element.
  // So, in those cases, detect that using getBoundingClientRect the same way MUI Popper does
  // and provide a fallback virtual element to use for positioning.
  const getAnchorEl = (): VirtualElement => {
    const elem = anchorElRef.current || virtualElement;
    const box = elem.getBoundingClientRect();
    const isNotRendered = box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0;
    const canBePositioned = !isNotRendered;

    return canBePositioned && anchorElRef.current ? anchorElRef.current : virtualElement;
  };

  // Modifier to shift the content slightly when a caret/arrow is desired such that
  // the arrow does not appear on the very edge of the content since it contains rounded corners.
  const offsetPopperModifier = {
    name: "offset",
    options: {
      offset: () => {
        // Only apply modifier if we want an arrow.
        if (showArrow) {
          return [0, CARET_SIZE];
        }

        return [];
      },
    },
  };

  // Modifier to position an arrow element that is pointed to the target.
  const arrowPopperModifier = {
    name: "arrow",
    options: {
      element: "[data-popper-arrow]",
      padding: ({ placement: popperPlacement }: PopperPaddingFnArgument) => {
        if (popperPlacement.includes("start") || popperPlacement.includes("end")) {
          return CARET_SIZE / 2;
        }

        return 0;
      },
    },
  };

  const handleClickAway = () => {
    if (onClickAway) {
      onClickAway();
    }
  };

  // Once we have an element reference, trigger the popper to show up.
  useEffect(() => {
    forceUpdate();
  }, [forceUpdate]);

  const shouldTransition =
    Boolean(transitionPopperContent) || Boolean(props.customTransitionPopperComponent);

  return (
    <>
      <div className={classnames(styles.root, className)} ref={anchorElRef}>
        {children}
      </div>

      {anchorElRef.current && (
        <Popper
          transition={shouldTransition}
          className={classnames(styles.popperRoot, popperRootClassName)}
          open={isOpen}
          placement={placement}
          anchorEl={getAnchorEl}
          container={containerEl}
          popperOptions={{
            modifiers: [arrowPopperModifier, offsetPopperModifier, ...popperModifiers],
          }}
        >
          {({ TransitionProps }) => {
            // This represents the content and the arrow.
            const popperContent = (
              <div
                role="presentation"
                className={classnames(styles.popperContent, popperContentClassName)}
                onClick={onClickPopperContent}
              >
                {props.renderPopperContent()}
                {showArrow && <div className={styles.arrow} data-popper-arrow />}
              </div>
            );

            // ===== Supported transitions =====
            // Popper from MUI is already integrated with react-spring!
            // Perform a fade on the popper content if so desired.
            if (transitionPopperContent === "fade" && TransitionProps) {
              return (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <UIPopperFade {...TransitionProps}>{popperContent}</UIPopperFade>
                </ClickAwayListener>
              );
            }

            // ===== Custom transitions =====
            // Check if we want to do a custom transition. This enables developers to define
            // transitions without having to modify the core component.
            if (props.customTransitionPopperComponent && TransitionProps) {
              const UIPopperCustomTransitionComponent = props.customTransitionPopperComponent;

              return (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <UIPopperCustomTransitionComponent {...TransitionProps}>
                    {popperContent}
                  </UIPopperCustomTransitionComponent>
                </ClickAwayListener>
              );
            }

            return (
              <ClickAwayListener onClickAway={handleClickAway}>{popperContent}</ClickAwayListener>
            );
          }}
        </Popper>
      )}
    </>
  );
};

export default UIPopper;
