import { Interactions } from "providers/UserInteractionHistoryProvider/interactions";

// Since we use Interactions to represent coachmarks, this should be a subset of the
// total interactions possible.
type InteractionsUsedAsCoachmark = Extract<
  keyof typeof Interactions,
  | "ONBOARDING_OFFICIAL_CLUB"
  | "ONBOARDING_OFFICIAL_CLUB_NBAALLWORLD"
  | "ONBOARDING_OFFICIAL_CLUB_PERIDOT"
  | "ONBOARDING_OFFICIAL_CLUB_PGO"
  | "ONBOARDING_PGO_EXPERIMENT_ONE"
  | "ONBOARDING_ACTIVITY_CENTER"
>;

// eslint-disable-next-line import/prefer-default-export
export const COACHMARKS: Record<InteractionsUsedAsCoachmark, Interactions> = {
  ONBOARDING_OFFICIAL_CLUB: Interactions.ONBOARDING_OFFICIAL_CLUB,
  ONBOARDING_OFFICIAL_CLUB_NBAALLWORLD: Interactions.ONBOARDING_OFFICIAL_CLUB_NBAALLWORLD,
  ONBOARDING_OFFICIAL_CLUB_PERIDOT: Interactions.ONBOARDING_OFFICIAL_CLUB_PERIDOT,
  ONBOARDING_OFFICIAL_CLUB_PGO: Interactions.ONBOARDING_OFFICIAL_CLUB_PGO,
  ONBOARDING_PGO_EXPERIMENT_ONE: Interactions.ONBOARDING_PGO_EXPERIMENT_ONE,
  ONBOARDING_ACTIVITY_CENTER: Interactions.ONBOARDING_ACTIVITY_CENTER,
};
