import { SpringRef } from "react-spring";

import { SlideComponentDef } from "./types";

/**
 * Translates a slide to a position in pixels.
 * 0 position is no offset and aligns the left of the slide with the left of the outlet.
 */
const translateSlideTo = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactSpringImperativeApi: SpringRef<any>,
  slides: SlideComponentDef[],
  targetSlideId: string,
  position: number,
): void => {
  reactSpringImperativeApi.start((index) => {
    const slideContent = slides[index];

    if (!slideContent) {
      return {};
    }

    const { slideId } = slideContent.props;

    // Since we must run an animation on all springs, we want to do nothing
    // for slides that are not the target.
    if (targetSlideId !== slideId) {
      return {};
    }

    return {
      immediate: true,
      translateX: position,
      opacity: 0,
    };
  });
};

export default translateSlideTo;
