import React, { FunctionComponent, useEffect } from "react";

import {
  CoachmarkConsumer,
  ExposedProps as CoachmarkProviderExposedProps,
} from "../../providers/CoachmarkProvider/CoachmarkProvider";

type ExposedProps = {
  isActive: boolean;
  markCoachmarkComplete: () => void;
};

export type RequiredProps = {
  flows: string[];
  coachmarkName: string;
  children: (exposedProps: ExposedProps) => React.ReactNode;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  onActive?: () => void;
  // eslint-disable-next-line react/require-default-props
  shouldAutoComplete?: () => boolean;
};

type PropsFromProvider = Pick<
  CoachmarkProviderExposedProps,
  | "currentFlow"
  | "currentCoachmark"
  | "registerCoachmark"
  | "unregisterCoachmark"
  | "markCoachmarkAsComplete"
  | "evaluateNextCoachmark"
  | "isCoachmarkActive"
>;

type Props = RequiredProps & PropsFromProvider;

/**
 * Wrapper component that a developer should use around items in the view they desire to
 * announce as a "student" or coachmark in a potential flow. Mounting this component will
 * tell the coachmark system to re-evaluate the currently presented coachmark.
 */
const Coachmark: FunctionComponent<Props> = (props: Props): React.ReactElement => {
  /**
   * Registers the coachmark for every flow it is denoted as a part of.
   * This is the act of a student raising their hand.
   */
  const registerFlowsAndCoachmark = (): void => {
    props.flows.forEach((flowName) => {
      props.registerCoachmark(flowName, props.coachmarkName);
    });
  };

  /**
   * Unregisters the coachmark for every flow it is denoted as a part of.
   * This is the act of a student putting their hand down.
   */
  const unregisterFlowsAndCoachmark = (): void => {
    props.flows.forEach((flowName) => {
      props.unregisterCoachmark(flowName, props.coachmarkName);
    });
  };

  /**
   * Marks a coachmark as complete.
   */
  const markCoachmarkComplete = async (): Promise<void> => {
    await props.markCoachmarkAsComplete(props.coachmarkName);
    props.evaluateNextCoachmark();
  };

  const coachmarkIsActive = props.currentCoachmark
    ? props.isCoachmarkActive(props.coachmarkName, props.flows)
    : false;

  // Effect that runs when the coachmark becomes active or inactive.
  useEffect(() => {
    if (coachmarkIsActive && props.onActive) {
      props.onActive();
    }

    // If the coachmark is active, and we should auto complete it, mark it as complete and
    // move on.
    if (coachmarkIsActive && props.shouldAutoComplete && props.shouldAutoComplete()) {
      markCoachmarkComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachmarkIsActive]);

  // Register coachmark when disabled flag changes and on mount. When unmount, unregister.
  useEffect(() => {
    if (props.disabled) {
      unregisterFlowsAndCoachmark();
    } else {
      registerFlowsAndCoachmark();
    }

    return () => {
      unregisterFlowsAndCoachmark();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disabled]);

  const exposedProps: ExposedProps = {
    isActive: coachmarkIsActive,
    markCoachmarkComplete,
  };

  return props.children(exposedProps) as React.ReactElement;
};

const CoachmarkConnected: FunctionComponent<RequiredProps> = (
  props: RequiredProps,
): React.ReactElement => {
  return (
    <CoachmarkConsumer>
      {({
        currentFlow,
        currentCoachmark,
        registerCoachmark,
        unregisterCoachmark,
        markCoachmarkAsComplete,
        evaluateNextCoachmark,
        isCoachmarkActive,
      }) => (
        <Coachmark
          {...props}
          currentFlow={currentFlow}
          currentCoachmark={currentCoachmark}
          registerCoachmark={registerCoachmark}
          unregisterCoachmark={unregisterCoachmark}
          markCoachmarkAsComplete={markCoachmarkAsComplete}
          evaluateNextCoachmark={evaluateNextCoachmark}
          isCoachmarkActive={isCoachmarkActive}
        />
      )}
    </CoachmarkConsumer>
  );
};

export default CoachmarkConnected;
