/**
 * @generated SignedSource<<a6c07c8ac72cca585d559eca56068f53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateDMChannelInput = {
  name: string;
  userIds: ReadonlyArray<string>;
};
export type CreateDMChannelMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateDMChannelInput;
};
export type CreateDMChannelMutation$data = {
  readonly createDMChannel: {
    readonly channel: {
      readonly avatarUrl: string;
      readonly createdAt: any;
      readonly id: string;
      readonly lastMessageSent: number;
      readonly members: ReadonlyArray<{
        readonly avatarUrl: string;
        readonly displayName: string;
        readonly id: string;
        readonly username: string;
      }>;
      readonly name: string;
      readonly pubnubSignalChannelName: string;
      readonly type: string;
      readonly unreadMessageCount: number;
      readonly userSettings: {
        readonly isMuted: boolean;
      };
    };
    readonly me: {
      readonly " $fragmentSpreads": FragmentRefs<"MessagesPageOutletContent_me">;
    };
  };
};
export type CreateDMChannelMutation = {
  response: CreateDMChannelMutation$data;
  variables: CreateDMChannelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Channel",
  "kind": "LinkedField",
  "name": "channel",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastMessageSent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadMessageCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pubnubSignalChannelName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTextChannelSetting",
      "kind": "LinkedField",
      "name": "userSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isMuted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDMChannelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDMChannelResponse",
        "kind": "LinkedField",
        "name": "createDMChannel",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MessagesPageOutletContent_me"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateDMChannelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDMChannelResponse",
        "kind": "LinkedField",
        "name": "createDMChannel",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "channel",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ChannelEdge"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageRequest",
                "kind": "LinkedField",
                "name": "pendingMessageRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isMessageRequestAllowed",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "77155f1ba3af05bd601cbd3398eeab65",
    "id": null,
    "metadata": {},
    "name": "CreateDMChannelMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDMChannelMutation(\n  $input: CreateDMChannelInput!\n) {\n  createDMChannel(input: $input) {\n    channel {\n      id\n      name\n      type\n      avatarUrl\n      createdAt\n      lastMessageSent\n      members {\n        id\n        avatarUrl\n        username\n        displayName\n      }\n      unreadMessageCount\n      pubnubSignalChannelName\n      userSettings {\n        isMuted\n      }\n    }\n    me {\n      ...MessagesPageOutletContent_me\n      id\n    }\n  }\n}\n\nfragment MessageRequestSettings_me on User {\n  isMessageRequestAllowed\n}\n\nfragment MessagesPageOutletContent_me on User {\n  id\n  pendingMessageRequests {\n    id\n  }\n  ...MessageRequestSettings_me\n}\n"
  }
};
})();

(node as any).hash = "870ad5808cbd8dcd7e658bea5b02365c";

export default node;
