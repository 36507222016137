import logException from "common/analytics/exceptions";
import { CampfirePushNotifications } from "common/capacitor/plugins/campfire-push-notifications";
import PermissionStore, { PERMISSION_TYPE } from "common/stores/PermissionStore";

type PermissionResultState = "granted" | "denied";

const promptForPushNotifications = async () => {
  PermissionStore.set(PERMISSION_TYPE.notifications, true);

  try {
    await CampfirePushNotifications.requestPermissions();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const checkNotificationPermissions = async (): Promise<PermissionResultState> => {
  try {
    const permissionStatus = await CampfirePushNotifications.checkPermissions();

    if (permissionStatus.receive === "granted") {
      return "granted";
    }

    return "denied";
  } catch (error) {
    return "denied";
  }
};

export const requestPushNotificationPermission = async (): Promise<PermissionResultState> => {
  const hasPromptedBefore = PermissionStore.get(PERMISSION_TYPE.notifications);

  try {
    // Check if we have enabled the geolocation permission already.
    const permissionResult = await checkNotificationPermissions();

    // If so, no need to prompt
    if (permissionResult === "granted") {
      return "granted";
    }

    if (!hasPromptedBefore) {
      await promptForPushNotifications();

      const permissionResultUpdated = await checkNotificationPermissions();

      if (permissionResultUpdated === "granted") {
        return "granted";
      }

      return "denied";
    }
  } catch (error) {
    logException(
      "requestPushNotificationPermission",
      "requestPushNotificationPermission",
      "AppPermissionProvider/pushNotifications",
      error,
    );
  }

  return "denied";
};
