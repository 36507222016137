import { invokeRemoteAsync } from "common/utils/webInterop/plugins";

import defaultGetFilePreviewUrl, {
  validator as getFilePreviewUrlValidator,
} from "./getFilePreviewUrl";
import defaultInitiateUpload, { validator as initiateUploadValidator } from "./initiateUpload";
import defaultUploadFile, { validator as uploadFileValidator } from "./uploadFile";

export default {
  initiateUpload: invokeRemoteAsync<typeof defaultInitiateUpload, "EmbedUpload">(
    defaultInitiateUpload,
    initiateUploadValidator,
    "EmbedUpload",
    "initiateUpload",
  ),
  uploadFile: invokeRemoteAsync<typeof defaultUploadFile, "EmbedUpload">(
    defaultUploadFile,
    uploadFileValidator,
    "EmbedUpload",
    "uploadFile",
  ),
  getFilePreviewUrl: invokeRemoteAsync<typeof defaultGetFilePreviewUrl, "EmbedUpload">(
    defaultGetFilePreviewUrl,
    getFilePreviewUrlValidator,
    "EmbedUpload",
    "getFilePreviewUrl",
  ),
};
