import { WithCoachmarkProps, withCoachmark } from "@niantic/react-coachmark";
import React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { withRouter, RouteComponentProps } from "react-router-dom";

import withAnalytics, { WithAnalyticsProps } from "providers/AnalyticsProvider/withAnalytics";
import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

import { embeddedClientInfo, isEmbedded } from "common/utils/webInterop";
import { COACHMARKS } from "constants/coachmark/coachmarks";
import {
  ONBOARDING_OFFICIAL_CLUB_FLOW,
  ONBOARDING_OFFICIAL_NBAALLWORLD_CLUB_FLOW,
  ONBOARDING_OFFICIAL_PERIDOT_CLUB_FLOW,
  ONBOARDING_OFFICIAL_PGO_CLUB_FLOW,
} from "constants/coachmark/flows";
import { GameShortCode } from "constants/nianticGame";
import { MESSAGES_ROUTE } from "constants/routes";

import CampfireCoachmark from "common/components/CampfireCoachmark";

import OfficialClubWelcomeDialog from "boot-loader/components/OfficialClubWelcomeDialog";

import { AppGlobalFlows_me$data as AppGlobalFlowsMe } from "__generated__/AppGlobalFlows_me.graphql";
import { AppGlobalFlows_query$data as AppGlobalFlowsQuery } from "__generated__/AppGlobalFlows_query.graphql";

type Props = WithCoachmarkProps &
  WithFeatureFlagProps &
  RouteComponentProps &
  WithAnalyticsProps & {
    query: AppGlobalFlowsQuery;
    me: AppGlobalFlowsMe;
    // TODO(emery) we should remove the majority of the code in this file related to offical clubs
    // It will be deprecated once we make the switch to campfireV2
    disableOfficialClubFTUE?: boolean;
  };

type State = {};

type CoachmarkFlowInfo = {
  flowName: string;
  coachmarkName: string;
};

// By default, use the original official club coachmark
const DEFAULT_OFFICIAL_CLUB_FLOW_DATA = {
  flowName: ONBOARDING_OFFICIAL_CLUB_FLOW.name,
  coachmarkName: COACHMARKS.ONBOARDING_OFFICIAL_CLUB,
};
// Per game, we can specify if we want a different coachmark to appear for it.
// NOTE: A flow needs to be created for each game. I experimented with a way to
//       reuse a single coachmark name, but it involves adjusting the coachmarks
//       passed to the CoachmarkProvider based on the game which I felt was more prone to cause
//       confusion as all other coachmarks declare and mark themselves as the same string name.
//       The experimented way would have made it use the default name, but when we mark,
//       we mark for the game specific name. So I value the less error prone way.
const OFFICIAL_CLUB_FLOW_FOR_GAME: Record<GameShortCode, CoachmarkFlowInfo> = {
  ING: DEFAULT_OFFICIAL_CLUB_FLOW_DATA,
  PERIDOT: {
    flowName: ONBOARDING_OFFICIAL_PERIDOT_CLUB_FLOW.name,
    coachmarkName: COACHMARKS.ONBOARDING_OFFICIAL_CLUB_PERIDOT,
  },
  PGO: {
    flowName: ONBOARDING_OFFICIAL_PGO_CLUB_FLOW.name,
    coachmarkName: COACHMARKS.ONBOARDING_OFFICIAL_CLUB_PGO,
  },
  NBAALLWORLD: {
    flowName: ONBOARDING_OFFICIAL_NBAALLWORLD_CLUB_FLOW.name,
    coachmarkName: COACHMARKS.ONBOARDING_OFFICIAL_CLUB_NBAALLWORLD,
  },
  // Below are all unset, defaulted to the original official club coachmark for now.
  // Change these when you want to have a new official club coachmark for the given game.
  SAMPLE: DEFAULT_OFFICIAL_CLUB_FLOW_DATA,
  PIKMIN: DEFAULT_OFFICIAL_CLUB_FLOW_DATA,
  CAMPFIRE: DEFAULT_OFFICIAL_CLUB_FLOW_DATA,
  MHNOW: DEFAULT_OFFICIAL_CLUB_FLOW_DATA,
  SUPERBLUE: DEFAULT_OFFICIAL_CLUB_FLOW_DATA,
};

class AppGlobalFlows extends React.Component<Props, State> {
  getOfficialClubFlowForCurrentGame = (): CoachmarkFlowInfo => {
    const gameShortCode = embeddedClientInfo.Game;
    const gameSpecificOfficialClubCoachmark =
      OFFICIAL_CLUB_FLOW_FOR_GAME[gameShortCode as GameShortCode];

    return gameSpecificOfficialClubCoachmark || DEFAULT_OFFICIAL_CLUB_FLOW_DATA;
  };

  dismissOfficialClubDialog = async (): Promise<void> => {
    const gameSpecificOfficialClubCoachmark =
      this.getOfficialClubFlowForCurrentGame().coachmarkName;

    // If there is no coachmark for the official club for the current game, do nothing.
    // The coachmark should never have shown in this case.
    if (!gameSpecificOfficialClubCoachmark) {
      return;
    }

    try {
      await this.props.coachmarkProvider.markCoachmarkAsComplete(gameSpecificOfficialClubCoachmark);
    } finally {
      this.props.coachmarkProvider.evaluateNextCoachmark();
    }
  };

  render = (): React.ReactNode => {
    const { officialClub } = this.props.query;

    // Disable the official club dialog when we are looking at the messages page.
    // Since we have a flow to create a dm, we don't want the official club one
    // to appear until we move away. Alternatively, we can add a hidden 3rd step to the
    // create dm flow, but this seems easier.
    const isOnMessagesRoute = this.props.location.pathname.includes(MESSAGES_ROUTE);

    // Determine the official club coachmark we should use based on the current game.
    const gameSpecificOfficialClubFlowInfo = this.getOfficialClubFlowForCurrentGame();
    const showOfficialClubCoachmark =
      officialClub &&
      isEmbedded &&
      gameSpecificOfficialClubFlowInfo &&
      !this.props.disableOfficialClubFTUE;

    return (
      <>
        {/* Flow/Dialog to prompt the user to join the official club */}
        {showOfficialClubCoachmark && (
          <CampfireCoachmark
            flows={[gameSpecificOfficialClubFlowInfo.flowName]}
            coachmarkName={gameSpecificOfficialClubFlowInfo.coachmarkName}
            disabled={isOnMessagesRoute}
          >
            {({ isActive }) => (
              <OfficialClubWelcomeDialog
                clubId={officialClub.id}
                clubName={officialClub.name}
                clubAvatarUrl={officialClub.avatarUrl}
                isOpen={isActive}
                close={this.dismissOfficialClubDialog}
              />
            )}
          </CampfireCoachmark>
        )}
      </>
    );
  };
}

const AnalyticsConnected = withAnalytics(AppGlobalFlows);
const FeatureFlagConnected = withFeatureFlag(AnalyticsConnected);
const CoachmarkConnected = withCoachmark(FeatureFlagConnected);
const FragmentContainer = createFragmentContainer(CoachmarkConnected, {
  query: graphql`
    fragment AppGlobalFlows_query on Query {
      officialClub {
        id
        name
        avatarUrl
      }
    }
  `,
  me: graphql`
    fragment AppGlobalFlows_me on User {
      id
      username
    }
  `,
});

export default withRouter(FragmentContainer);
