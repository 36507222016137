import BaseStore from "common/stores/BaseStore";
import dayjs from "common/utils/dayjs";

const DEFAULTS = {};

// Expire messages that have persisted for over 30 days since we don't want to build up
// an infinite list. By 30 days, the reported messages should have been moderated or
// the chat history has progressed far enough.
const TTL: number = dayjs.duration(30, "days").asMilliseconds();

class HiddenMessageStore extends BaseStore {
  constructor() {
    super("HiddenMessageStore", DEFAULTS, false);
  }

  expire() {
    const now = Date.now();
    const store = super.getStore();
    const keysToDelete: string[] = [];

    Object.keys(store).forEach((key: string) => {
      const diff = now - store[key];

      if (diff > TTL) {
        keysToDelete.push(key);
      }
    });

    keysToDelete.forEach((key) => {
      this.remove(key);
    });
  }

  add(key: string) {
    const now = Date.now();

    this.expire();
    super.set(key, now);
  }

  contains(key: string) {
    return super.get(key) !== undefined;
  }
}

export default new HiddenMessageStore();
