import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { _actionEventEmitter } from "common/utils/webInterop";

type Props = RouteComponentProps & {};

type State = {};

const NAVIGATION_EVENT_NAME = "navigate";

class NavigationActionHandler extends React.Component<Props, State> {
  componentDidMount = (): void => {
    _actionEventEmitter.on(NAVIGATION_EVENT_NAME, this.handleNavigateEvent);
  };

  componentWillUnmount = (): void => {
    _actionEventEmitter.off(NAVIGATION_EVENT_NAME, this.handleNavigateEvent);
  };

  handleNavigateEvent = (url?: string): void => {
    if (typeof url !== "string") {
      return;
    }

    this.props.history.push(url);
  };

  render = (): React.ReactNode => {
    return null;
  };
}

export default withRouter(NavigationActionHandler);
