/* eslint-disable no-alert */
import environment from "common/relay/relay-env";
import _ from "lodash";
import queryString from "query-string";
import React from "react";
import { graphql } from "react-relay";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { fetchQuery } from "relay-runtime";

import SharedLoginAccountWarning from "providers/AuthProvider/components/SharedLoginAccountWarning";

import logException from "common/analytics/exceptions";
import { get } from "common/api/axios";
import authAxios from "common/api/axios-instance";
import { isStandalone, isWeb } from "common/capacitor/helpers";
import { OAuth } from "common/capacitor/plugins/oauth";
import { SharedLoginToken } from "common/capacitor/plugins/shared-login";
import RouteOnLoadStore from "common/stores/RouteOnLoadStore";
import {
  authenticateWithSharedLoginToken,
  retrieveSharedLoginToken,
  validateSharedLoginToken,
} from "common/utils/sharedLogin";
import { getQueryString, parseQuery } from "common/utils/url";
import { isEmbedded, savedInteropType, embeddedClientInfo } from "common/utils/webInterop";
import { QUERY_PARAMS } from "constants/routes";
import { ONE_DAY } from "constants/time";
import TrackClientActionMutation from "mutations/TrackClientActionMutation";

import { AuthProvider_Query$data as AuthProviderQueryResponse } from "__generated__/AuthProvider_Query.graphql";

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

enum SharedLoginWarningState {
  HIDDEN = 1, // The warning has never been shown for this Campfire session.
  SHOWING = 2, // The warning is currently showing.
  DISMISSED = 3, // The warning was dismissed, and will never show again for this session.
}

const TRACKING_TIMEOUT_MS = 2000;

type SessionRefreshStatus = "failed" | "success";

export type ExposedProps = {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  extractTokensFromLaunchUrl: (url: string) => void;
  saveTokens: (csrfToken: string, sessionToken: string, loginMethod: LoginMethod) => void;
  isPresumablyLoggedIn: () => boolean;
  logout: () => Promise<void>;
  logoutGracefully: () => Promise<void>;
  handleDeepLinkFromGame: (game: string) => void;
  listenFor401Responses: () => void;
  stopListeningFor401s: () => void;
  refreshSession: () => Promise<SessionRefreshStatus>;
};

type State = {
  isLoggedIn: boolean;
  originGame: string;
  sharedLoginToken: SharedLoginToken | null;
  sharedLoginWarningState: SharedLoginWarningState;
};

const INITIAL_CONTEXT: ExposedProps = {
  isLoggedIn: false,
  setIsLoggedIn: _.noop,
  extractTokensFromLaunchUrl: _.noop,
  saveTokens: _.noop,
  isPresumablyLoggedIn: () => false,
  logout: () => Promise.resolve(),
  logoutGracefully: () => Promise.resolve(),
  handleDeepLinkFromGame: _.noop,
  listenFor401Responses: _.noop,
  stopListeningFor401s: _.noop,
  refreshSession: () => Promise.resolve("failed"),
};

const AUTH_PROVIDER_QUERY = graphql`
  query AuthProvider_Query {
    me {
      id
    }
  }
`;

const AuthContext = React.createContext(INITIAL_CONTEXT);

const CSRF_TOKEN_KEY_NAME = "CsrfToken";
const MIN_REFRESH_DELAY_MS = ONE_DAY;

class AuthProvider extends React.Component<Props, State> {
  logoutInterceptor: number | null = null;

  constructor(props: Props) {
    super(props);

    // For Web login debugging only
    // =============================
    // Since we only do a simple check to minimize initial queries to the backend
    // and let the 401 interceptor handle the Unauthorized case. But for web, where we
    // check for the presumable login happens before this component finishes mounting.
    // So, we will just run some code before mounting to combat this.
    // Also, since we use React.StrictMode, constructors are invoked twice.
    // So, its a little sketch, but componentWillMount throws warnings. Lame.
    this.performWebAuth();

    this.state = {
      isLoggedIn: false,
      originGame: "SAMPLE",
      sharedLoginToken: null,
      sharedLoginWarningState: SharedLoginWarningState.HIDDEN,
    };
  }

  setIsLoggedIn = (value: boolean): void => {
    this.setState({ isLoggedIn: value });
  };

  /**
   * Start listening for 401 responses and automatically log the user out if one is heard.
   */
  listenFor401Responses = (): void => {
    this.logoutInterceptor = authAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Clear tokens and logout on unauthorized responses.
        // TECHNICALLY, the top level axios interceptor already clears the tokens. So this
        // should be redundant.
        if (error.response && error.response.status && error.response.status === 401) {
          this.logoutGracefully();
        }

        return Promise.reject(error);
      },
    );
  };

  stopListeningFor401s = (): void => {
    // When we unmount, remove the logout interceptor
    if (this.logoutInterceptor) {
      authAxios.interceptors.request.eject(this.logoutInterceptor);
    }
  };

  extractTokensFromLaunchUrl = (url: string): void => {
    // Saw a super weird phenomenon where the state param contains a trailing "#" character
    // when hearing this event from a fresh install + new authentication. It only happens
    // under those conditions so far, as every other time it's ok. The trailing "#" causes
    // the decoding of the base64 encoded state string to fail, and thus, the csrftoken
    // cannot be parsed out, thereby failing requests.
    //
    // So, what we do here is parse the url into an object form, since query-string
    // seems to clean the url up. Then re-stringify it into a url, and then extract the search
    // part of that url instead. Also, so we can reuse the extractTokensFromSearch method haha.
    const parsed = queryString.parseUrl(url, { parseBooleans: true });
    const stringified = queryString.stringifyUrl(parsed);
    const urlParts = stringified.split("?");

    if (urlParts[1]) {
      const search = urlParts[1];
      const tokensFromUrlAppOpen = this.extractTokensFromSearch(search);

      if (tokensFromUrlAppOpen.csrfToken && tokensFromUrlAppOpen.sessionToken) {
        // TODO: This is not gonna let web refresh the token properly. Oh well.
        this.saveTokens(tokensFromUrlAppOpen.csrfToken, tokensFromUrlAppOpen.sessionToken, "");
        this.cleanupTokensFromUrl();
      }
    }
  };

  performWebAuth = () => {
    // WEB ONLY!
    // Check if we have any tokens to use. This is mainly for web for dev purposes.
    if (isWeb) {
      const tokens = this.extractTokensFromSearch(this.props.location.search);

      if (tokens.csrfToken && tokens.sessionToken) {
        // TODO: This is not gonna let web refresh the token properly. Oh well.
        this.saveTokens(tokens.csrfToken, tokens.sessionToken, "");
        this.cleanupTokensFromUrl();
      }
    }
  };

  cleanupTokensFromUrl = () => {
    // Cleanup token and encoded csrfToken from url
    const queryParamUpdates = {
      [QUERY_PARAMS.root.sessionToken.key]: undefined,
      [QUERY_PARAMS.root.state.key]: undefined,
    };

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: getQueryString(queryParamUpdates, this.props.location.search),
    });
  };

  extractTokensFromSearch = (search: string): { csrfToken: string; sessionToken: string } => {
    const queryParams = parseQuery(search);

    const sessionToken = queryParams[QUERY_PARAMS.root.sessionToken.key];
    let csrfToken = "";

    try {
      const encodedState = queryParams[QUERY_PARAMS.root.state.key];

      if (encodedState) {
        const decodedState = atob(encodedState);
        const parsedDecodedState = JSON.parse(decodedState);

        csrfToken = parsedDecodedState[CSRF_TOKEN_KEY_NAME];
      }
    } catch (error) {
      csrfToken = "";
      logException("decodingState", "extractTokensFromSearch", "AuthProvider", error);
    }

    return {
      csrfToken,
      sessionToken,
    };
  };

  saveTokens = (csrfToken: string, sessionToken: string, loginMethod: LoginMethod): void => {
    localStorage.setItem("csrfToken", csrfToken);
    localStorage.setItem("sessionToken", sessionToken);
    localStorage.setItem("loginMethod", loginMethod);
    localStorage.setItem("tokenSavedAt", Date.now().toString());
  };

  clearTokens = (): void => {
    localStorage.setItem("csrfToken", "");
    localStorage.setItem("sessionToken", "");
    localStorage.setItem("loginMethod", "");
    localStorage.setItem("tokenSavedAt", "");
  };

  isPresumablyLoggedIn = (): boolean => {
    const hasSessionToken = localStorage.getItem("sessionToken");

    return !!hasSessionToken;
  };

  performMainLogoutRoutine = async (): Promise<void> => {
    // Analytics tracking. We need to await this because we're about to clear the tokens.
    // Also set a timeout in case this takes forever.
    if (this.isPresumablyLoggedIn()) {
      await Promise.race([
        this.trackLogoutAction(),
        new Promise((resolve) => {
          setTimeout(resolve, TRACKING_TIMEOUT_MS);
        }),
      ]);
    }

    // Handle a more graceful logout
    this.clearTokens();

    // Clear global Helpshift config
    window.helpshiftConfig.userId = undefined;
    window.helpshiftConfig.userEmail = undefined;
    window.helpshiftConfig.userName = undefined;

    window.Helpshift("updateHelpshiftConfig");

    if (isStandalone) {
      // For standalone, we need to call the OAuth plugin's logout so the app forgets which user logged in.
      await OAuth.signOut();
    }

    this.setIsLoggedIn(false);
  };

  /**
   * Performs a hard logout by clearing tokens and force reloading the web page. This basically
   * gaurantees a fresh boot. At least from the web perspective.
   */
  logout = async (): Promise<void> => {
    await this.performMainLogoutRoutine();

    // For embedded, we will try to honor the embed and reload in the embedded state as well!
    if (isEmbedded && savedInteropType && embeddedClientInfo) {
      const interopTypeQueryParamString = QUERY_PARAMS.root.interopType.key as string;
      const embeddedClientInfoQueryParamString = QUERY_PARAMS.root.embeddedClientInfo.key as string;

      const queryParams: Record<string, string> = {
        [interopTypeQueryParamString]: savedInteropType,
        [embeddedClientInfoQueryParamString]: JSON.stringify(embeddedClientInfo),
      };

      // Join all query params into a string.
      const queryStringToAdd: string = Object.keys(queryParams)
        .filter((key) => !!queryParams[key])
        .map((key) => `${key}=${queryParams[key] || ""}`)
        .join("&");

      window.location.href = `/?${queryStringToAdd}`;
    } else {
      window.location.href = "/";
    }
  };

  /**
   * Performs a more graceful logout, by not reloading the entire app.
   */
  logoutGracefully = async (): Promise<void> => {
    return this.performMainLogoutRoutine();
  };

  trackLogoutAction = async (): Promise<void> => {
    await this.trackLogout();

    if ((localStorage.getItem("loginMethod") as LoginMethod) === "shared_login") {
      await this.trackSharedLoginLogout();
    }
  };

  trackLogout = async (): Promise<void> => {
    const payload = {
      actionType: "Logout" as const,
      jsonPayload: {},
    };

    try {
      await TrackClientActionMutation.commit(environment, payload);
    } catch (error) {
      logException("TrackClientActionMutation", "trackLogout", "AuthProvider", error);
    }
  };

  trackSharedLoginLogout = async (): Promise<void> => {
    const payload = {
      actionType: "SharedLoginLogout" as const,
      jsonPayload: {},
    };

    try {
      TrackClientActionMutation.commit(environment, payload);
    } catch (error) {
      logException("TrackClientActionMutation", "trackLogout", "AuthProvider", error);
    }
  };

  handleDeepLinkFromGame = async (game: string): Promise<void> => {
    // If we're coming from a different game, we check if we need to display a
    // shared login account warning.

    // Skip if the game param was empty.
    if (!game) {
      return;
    }

    // Skip if we aren't logged in yet.
    if (!this.isPresumablyLoggedIn()) {
      return;
    }

    if (this.state.sharedLoginWarningState !== SharedLoginWarningState.HIDDEN) {
      // Skip if the warning is already showing, or the user already dismissed it.
      return;
    }

    const sharedLoginToken = await retrieveSharedLoginToken();

    if (!sharedLoginToken) {
      return;
    }

    const validationResponse = await validateSharedLoginToken(sharedLoginToken);

    // Skip warning if garId was not returned
    if (validationResponse.garAccountId === "") {
      return;
    }

    try {
      const response = (await fetchQuery(
        environment,
        AUTH_PROVIDER_QUERY,
        {},
      ).toPromise()) as AuthProviderQueryResponse;

      // Show warning if returned garId is different than the logged in user's garId
      if (response.me.id !== validationResponse.garAccountId) {
        this.setState({
          originGame: game,
          sharedLoginToken,
          sharedLoginWarningState: SharedLoginWarningState.SHOWING,
        });
      }
    } catch (error) {
      logException("AUTH_PROVIDER_QUERY", "handleDeepLinkFromGame", "AuthProvider", error);
    }
  };

  switchToSharedLoginAccount = async (): Promise<void> => {
    // Clear existing tokens
    this.clearTokens();

    if (!this.state.sharedLoginToken) {
      return;
    }

    const response = await authenticateWithSharedLoginToken(this.state.sharedLoginToken);

    RouteOnLoadStore.setRoute(this.props.history.location.pathname);

    this.saveTokens("", response.token, "shared_login");
    window.location.href = "/";
  };

  continueWithCurrentAccount = (): void => {
    this.setState({
      sharedLoginWarningState: SharedLoginWarningState.DISMISSED,
    });
  };

  // Attempt to refresh the session token given the last used provider. Return "success" if the token
  // was refreshed successfully, "failed" otherwise.
  refreshSession = async (): Promise<SessionRefreshStatus> => {
    // Determine the login provider our token is likely valid for.
    // Hit the backend with our current session token and the provider we want to get a new token for.
    try {
      const loginMethod = localStorage.getItem("loginMethod") as LoginMethod;
      const token = localStorage.getItem("sessionToken");
      const lastSaved = localStorage.getItem("tokenSavedAt");

      if (!loginMethod || !token || !lastSaved) {
        return "failed";
      }

      // If the token was saved recently, don't try to refresh it
      const lastSavedDate = new Date(parseInt(lastSaved, 10));

      if (Date.now() - lastSavedDate.getTime() < MIN_REFRESH_DELAY_MS) {
        return "success";
      }

      if (loginMethod === "shared_login") {
        return this.refreshSharedLoginSessionToken();
      }

      // google, apple, facebook
      return this.refreshOauthSessionToken(loginMethod, token);
    } catch (error) {
      return "failed";
    }
  };

  refreshSharedLoginSessionToken = async (): Promise<SessionRefreshStatus> => {
    const sharedLoginToken = await retrieveSharedLoginToken();

    if (sharedLoginToken) {
      const response = await authenticateWithSharedLoginToken(sharedLoginToken);

      if (response.token) {
        this.saveTokens("", response.token, "shared_login");
        return "success";
      }
    }

    return "failed";
  };

  refreshOauthSessionToken = async (
    provider: LoginMethod,
    token: string,
  ): Promise<SessionRefreshStatus> => {
    try {
      const response = await get(`/auth/refresh_token?provider=${provider}&t=${token}`);

      if (response.token) {
        this.saveTokens("", response.token, provider);
        return "success";
      }

      return "failed";
    } catch (err) {
      return "failed";
    }
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          isLoggedIn: this.state.isLoggedIn,
          setIsLoggedIn: this.setIsLoggedIn,
          extractTokensFromLaunchUrl: this.extractTokensFromLaunchUrl,
          saveTokens: this.saveTokens,
          isPresumablyLoggedIn: this.isPresumablyLoggedIn,
          logout: this.logout,
          logoutGracefully: this.logoutGracefully,
          handleDeepLinkFromGame: this.handleDeepLinkFromGame,
          listenFor401Responses: this.listenFor401Responses,
          stopListeningFor401s: this.stopListeningFor401s,
          refreshSession: this.refreshSession,
        }}
      >
        {children}

        {this.state.sharedLoginWarningState === SharedLoginWarningState.SHOWING &&
          this.state.sharedLoginToken && (
            <SharedLoginAccountWarning
              gameShortCode={this.state.originGame}
              sharedLoginToken={this.state.sharedLoginToken}
              switchAccounts={this.switchToSharedLoginAccount}
              continue={this.continueWithCurrentAccount}
              close={this.continueWithCurrentAccount}
            />
          )}
      </AuthContext.Provider>
    );
  };
}

const AuthProviderRouterConnected = withRouter(AuthProvider);

export { AuthProviderRouterConnected as AuthProvider };
export const AuthConsumer = AuthContext.Consumer;
