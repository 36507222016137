import { setupConfig } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
/* Import our css for ionic and everything default and polyfills */
import "@ionic/react/css/core.css";
import "common/css/_init";
import "./polyfills";

// Import the core styles for the document.body.
import "./index.scss";

/* At this point, all the core stuff for the app should be imported. */
/* Now, lets import stuff we need for the interop */
/* Initialize i18n for translations and language */
/* Initialize the interop and if we are running in embed, force capacitor to treat as "web" */
/* Setup React GA, so we can track stuff and set dimensions */
import "common/analytics/exceptions";
import "common/utils/webInterop";
import "common/utils/i18n";

// ======== Now it is ok to import Capacitor Plugins ========
// Import Capacitor plugins after the interop because we initialize Capacitor there
// and importing such plugins first may initialize Capacitor without us forcing embed
// as web, as done by treatEmbedAsWebPlatformCapacitor.

// Force all UI to feel like iOS for consistency
// Prevent swiping back from doing some weird navigation thing on IOS
// https://github.com/ionic-team/ionic-framework/issues/16441
// https://github.com/ionic-team/ionic-framework/issues/20904
setupConfig({
  mode: "ios",
  swipeBackEnabled: false,
  hardwareBackButton: false,
});
