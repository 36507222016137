import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { MutationError } from "types/modules/relay/mutations";

import { SetNianticIdMutation$data } from "__generated__/SetNianticIdMutation.graphql";

type ReturnType = SetNianticIdMutation$data["setNianticId"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  nianticId: string;
};

const mutation = graphql`
  mutation SetNianticIdMutation($input: SetNianticIdInput!) {
    setNianticId(input: $input) {
      me {
        username
        displayName
        hasSetNianticId
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "setNianticId.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "setNianticId.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "setNianticId"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0]") || _.get(error, "data.errors[0]") || error;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          nianticId: input.nianticId,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
