export const SLIDE_POSITIONS_AS_PERCENT = {
  STACKED: -35,
  PRESENTING: 0,
  WAITING: 100,
};

const convertPercentageToPx = (percent: number, totalWidth: number): number => {
  return (percent / 100) * totalWidth;
};

export default (isSlideOpen: boolean, isCurrentSlide: boolean, totalWidth: number): number => {
  let translateXVal = convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.WAITING, totalWidth);

  if (isCurrentSlide) {
    translateXVal = convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.PRESENTING, totalWidth);
  } else if (isSlideOpen) {
    translateXVal = convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.STACKED, totalWidth);
  }

  return translateXVal;
};
