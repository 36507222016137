import { Capacitor } from "@capacitor/core";

import {
  GetFilePreviewUrlResponse,
  GetFilePreviewUrlOptions,
} from "types/embedPluginInterface/embedUpload";

export const validator = (initiateUploadResponse: GetFilePreviewUrlResponse): boolean => {
  const hasPreviewUrl = Boolean(initiateUploadResponse.previewUrl);

  return hasPreviewUrl;
};

// eslint-disable-next-line max-len
const defaultGetFilePreviewUrl = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options: GetFilePreviewUrlOptions,
): Promise<GetFilePreviewUrlResponse> => {
  return Promise.resolve({
    previewUrl: Capacitor.convertFileSrc(options.filePath),
  });
};

export default defaultGetFilePreviewUrl;
