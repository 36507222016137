import React, { useEffect } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Redirect, Route, Switch, withRouter, RouteComponentProps } from "react-router-dom";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";
import { UserProfileProvider } from "providers/UserProfileProvider";

import { FEATURE_FLAGS } from "constants/featureFlags";
import {
  UNKNOWN_ROUTE,
  MAP_ROUTE,
  TabName,
  ACTIVITY_CENTER_ROUTE,
  REALITY_CHANNEL_ID_PATH_PARAM,
} from "constants/routes";

import GetCampfireDialog from "common/components/GetCampfireDialog";

/* Main views */
import FourOhFour from "pages/FourOhFour";
import MapAndActivitiesPagePgo from "pages/MapAndActivities/variants/pgo";

type Props = RouteComponentProps & WithTranslation & WithFeatureFlagProps;

type State = {
  isDialogOpen: boolean;
};

const RunCallbackThenRedirect = (props: { callback: () => void; redirectTo: string }) => {
  const { callback, redirectTo } = props;

  useEffect(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Redirect to={redirectTo} />;
};

class AppRouterPGO extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDialogOpen: false,
    };
  }

  showDialog = (): void => {
    this.setState({ isDialogOpen: true });
  };

  hideDialog = (): void => {
    this.setState({ isDialogOpen: false });
  };

  isSupportedRoute = (tabName: TabName): boolean => {
    return tabName === MAP_ROUTE;
  };

  render = (): React.ReactNode => {
    const useCampfireV2 = this.props.hasFeatureFlag(FEATURE_FLAGS.CAMPFIRE_V2);
    const defaultTab = useCampfireV2 ? ACTIVITY_CENTER_ROUTE : MAP_ROUTE;

    return (
      <>
        {/* This UserProfileProvider should mask the one in AuthenticatedServiceProviders so we
            disable DMs and invite to club functionality for all user profiles within PGO embed */}
        <UserProfileProvider disableDms disableInviteToClubs>
          <GetCampfireDialog isOpen={this.state.isDialogOpen} close={this.hideDialog} />

          {/* Any of these routes will get rendered in the IonTabs */}
          {/* This Switch here just allows us to handle routes since Ionic's router can't handle fallback cases. */}
          <Switch>
            {/* Redirect from the base route to the default tab. */}
            <Redirect exact from="/" to={`/${defaultTab}`} />

            <Route
              exact
              path={[
                `/:baseRoute(${ACTIVITY_CENTER_ROUTE})`,
                `/:baseRoute(${MAP_ROUTE})`,
                `/:baseRoute(${ACTIVITY_CENTER_ROUTE})/:${REALITY_CHANNEL_ID_PATH_PARAM}`,
                `/:baseRoute(${ACTIVITY_CENTER_ROUTE})/:${REALITY_CHANNEL_ID_PATH_PARAM}/${MAP_ROUTE}`,
              ]}
              component={MapAndActivitiesPagePgo}
            />
            <Route exact path={`/${UNKNOWN_ROUTE}`} component={FourOhFour} />

            {/* If nothing matches, show dialog and redirect to map */}
            <Route>
              <RunCallbackThenRedirect callback={this.showDialog} redirectTo={`/${MAP_ROUTE}`} />
            </Route>

            {/* In the worst case, route to the 404 page */}
            <Redirect to={`/${UNKNOWN_ROUTE}`} />
          </Switch>
        </UserProfileProvider>
      </>
    );
  };
}

const RouterConnected = withRouter(AppRouterPGO);
const FeatureFlagConnected = withFeatureFlag(RouterConnected);
const TranslatedComponent = withTranslation()(FeatureFlagConnected);

export default TranslatedComponent;
