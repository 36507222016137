import React from "react";

import { isPGOEmbed } from "common/utils/webInterop";
import { GAME_SHORT_CODES, GameShortCodeWithBackwardsCompat } from "constants/nianticGame";

import PGOCampfireLayout from "boot-loader/components/PGOCampfireLayout";

export type Props = {
  children: React.ReactNode;
};

type State = {};

const DOCUMENT_LAYOUT: Partial<Record<GameShortCodeWithBackwardsCompat, React.ComponentClass>> = {
  [GAME_SHORT_CODES.PGO]: PGOCampfireLayout,
};

export default class BootLoaderLayout extends React.Component<Props, State> {
  documentLayout: React.ComponentClass | undefined = undefined;

  constructor(props: Props) {
    super(props);

    if (isPGOEmbed) {
      this.documentLayout = DOCUMENT_LAYOUT[GAME_SHORT_CODES.PGO];
    }
  }

  render = (): React.ReactNode => {
    if (this.documentLayout) {
      const DocumentLayout = this.documentLayout;

      return <DocumentLayout>{this.props.children}</DocumentLayout>;
    }

    return this.props.children;
  };
}
