import { PermissionStatus, Geolocation } from "@capacitor/geolocation";

import logException from "common/analytics/exceptions";

// https://capacitorjs.com/docs/apis/geolocation#checkpermissions
export const validator = (permissionStatus: PermissionStatus): boolean => {
  const permissionResult = permissionStatus.location;

  try {
    return !!permissionResult;
  } catch (error) {
    logException(
      "validating Geolocation.checkPermissions interop response",
      "validator",
      "checkPermissions",
      error,
    );
    return false;
  }
};

export default Geolocation.checkPermissions;
