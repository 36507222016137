import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import ReportContext from "constants/reportContext";
import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { ReportUserMutation$data } from "__generated__/ReportUserMutation.graphql";

type ReturnType = ReportUserMutation$data["reportUser"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  userId: string;
  reason: number;
  customReportReason: string;
  reportContext: ReportContext;
};

const mutation = graphql`
  mutation ReportUserMutation($input: ReportUserInput!) {
    reportUser(input: $input) {
      success
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "reportUser.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "reportUser.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "reportUser"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          userId: input.userId,
          reason: input.reason,
          customReportReason: input.customReportReason,
          context: input.reportContext,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
