// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Taken from capacitor:
// https://github.com/ionic-team/capacitor/blob/151e7a899d9646dbd5625a2539fd3f2297349bc5/ios/Capacitor/Capacitor/assets/native-bridge.js
const safeStringify = (value) => {
  const seen = new Set();

  return JSON.stringify(value, (_k, v) => {
    if (seen.has(v)) {
      return "...";
    }

    if (typeof v === "object") {
      seen.add(v);
    }

    return v;
  });
};

// eslint-disable-next-line import/prefer-default-export
export const serializeConsoleMessage = (msg: unknown): string => {
  let message = msg;

  if (typeof message === "object") {
    try {
      message = safeStringify(msg);
    } catch (e) {
      // ignore
    }
  }

  return String(message);
};
