import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";

import { MAGELLAN_EXPERIENCE_ROOT_DIV_ID } from "constants/magellan";

import LoadingPageDefault from "common/components/LoadingPageDefault";
import MagellanUIErrorBoundary from "common/components/Magellan/MagellanUIErrorBoundary";
import PageLoadError from "common/components/PageLoadError";

import { ExperienceMagellan_page_Query$data as ExperienceMagellanPageQueryResponse } from "__generated__/ExperienceMagellan_page_Query.graphql";

import styles from "./styles.scss";

// Lazy Load Main View
const ExperienceMagellanPageMainContent = React.lazy(
  () => import("pages/ExperienceMagellan/components/ExperienceMagellanPageMainContent"),
);

type Props = WithTranslation & {};

type State = {
  initializedAt: number;
};

type QRProps = {
  me: ExperienceMagellanPageQueryResponse["me"];
};

const EXPERIENCEMAGELLAN_PAGE = graphql`
  query ExperienceMagellan_page_Query {
    me {
      ...ExperienceMagellanPageMainContent_me
    }
  }
`;

class ExperienceMagellanPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedAt: Date.now(),
    };
  }

  reInit = (): void => {
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  render = (): React.ReactNode => (
    <div className={styles.root} id={MAGELLAN_EXPERIENCE_ROOT_DIV_ID}>
      <MagellanUIErrorBoundary onRetry={this.onRetry}>
        <Suspense fallback={<LoadingPageDefault />}>
          <QueryRenderer
            key={this.state.initializedAt}
            environment={environment}
            query={EXPERIENCEMAGELLAN_PAGE}
            variables={{}}
            render={({ props, error }) => {
              const qrProps = props as QRProps;
              const isLoading = !qrProps;
              const isError = !!error;

              if (isLoading) {
                return <LoadingPageDefault />;
              }

              if (isError) {
                return (
                  <PageLoadError
                    errorMessage={this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
                    reload={this.reInit}
                  />
                );
              }

              return <ExperienceMagellanPageMainContent me={qrProps.me} />;
            }}
          />
        </Suspense>
      </MagellanUIErrorBoundary>
    </div>
  );
}

export default withTranslation()(ExperienceMagellanPage);
