import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { CancelFriendInviteMutation$data } from "__generated__/CancelFriendInviteMutation.graphql";

type ReturnType = CancelFriendInviteMutation$data["cancelFriendInvite"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  recipientId: string;
};

const mutation = graphql`
  mutation CancelFriendInviteMutation($input: CancelFriendInviteInput!) {
    cancelFriendInvite(input: $input) {
      user {
        isMyFriend
        hasPendingFriendInviteFromMe
      }
      success
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "cancelFriendInvite.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "cancelFriendInvite.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "cancelFriendInvite"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          recipientId: input.recipientId,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
