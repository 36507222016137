// eslint-disable-next-line max-classes-per-file
import classnames from "classnames";
import _ from "lodash";
import React, { FunctionComponent, RefObject, useEffect, useRef } from "react";
import { SpringRef, useSpringRef, useSprings, animated, SpringConfig } from "react-spring";

import invokeCallbackIfAllAnimationsFinish from "common/utils/reactSpring/invokeCallbackIfAllAnimationsFinish";

import DelayedUnmount from "common/components/DelayedUnmount";
import IonBackHandler from "common/components/IonBackHandler";
import defaultSpringConfig from "common/components/UINav/animationSpringConfig";
import UINavGestureEnabledSlideContent from "common/components/UINav/components/UINavGestureEnabledSlideContent";
import UINavOutlet from "common/components/UINav/components/UINavOutlet";
import UINavSlide, {
  Props as UINavSlideProps,
} from "common/components/UINav/components/UINavSlide";
import UINavSlideView from "common/components/UINav/components/UINavSlideView";
import UINavSlides from "common/components/UINav/components/UINavSlides";
import useUINavChildren from "common/components/UINav/useUINavChildren";
import useUINavSlideStack from "common/components/UINav/useUINavSlideStack";
import animateSlidesToPositions from "common/components/UINav/utils/animateSlidesToPositions";
import computeOffsetPositionForSlide, {
  SLIDE_POSITIONS_AS_PERCENT,
} from "common/components/UINav/utils/computeOffsetPositionForSlide";
import { validateDynamicSlideChanges } from "common/components/UINav/utils/debug";
import getSlideInfo from "common/components/UINav/utils/getSlideInfo";
import getSlideInfoByIndex from "common/components/UINav/utils/getSlideInfoByIndex";
import translateSlideTo from "common/components/UINav/utils/translateSlideTo";
import triggerHandlersForAnimationFinished from "common/components/UINav/utils/triggerHandlersForAnimationFinished";
import { SlideComponentDef, SlideStackState } from "common/components/UINav/utils/types";
import updateSlideLayering from "common/components/UINav/utils/updateSlideLayering";

import styles from "./styles.scss";

export type SlideStatus = {
  // UUID of the slide.
  slideId: string;
};

export type Props = {
  enableSwipebackOnSlides?: boolean;
  enableHardwareBack?: boolean;
  hardwareBackPriority?: number;
  // Invoked when the slideStack changes. Communicates the next active slide.
  onSlideChange?: (activeSlide: string | null) => void;

  // Invoked immediately when a swipe back is initiated. (on the panStart event)
  onSwipeBackStart?: (activeSlide: string | null) => void;

  // Invoked immediately when a swipe back is performed. (on the panEnd event)
  onSwipeBackEnd?: (activeSlide: string | null) => void;

  // To adjust animation of the nav slides.
  animationSpringConfig?: SpringConfig;

  disableAnimations?: boolean;

  // If set, when an open slide swaps to become the active slide, no animation will be played
  // and the view will instantly change to the desired slide. Default behavior will bring
  // the desired slide from the side as if its opening again.
  noAnimationWhenSwapping?: boolean;

  children: React.ReactNode;
};

export type ExposedProps = {
  _slideStack: SlideStatus[];
  isSlideInStack: (slideId: string) => boolean;
  activeSlide: string | null;
  showSlide: (slideId: string) => void;
  hideSlide: (slideId: string) => void;
  hideSlides: (slideIds: string[]) => void;
  hideLastSlide: () => void;
  renderSlides: () => React.ReactNode;
  rootRef: RefObject<HTMLDivElement>;
  outletSpringRef: SpringRef;
  animationSpringConfig: SpringConfig;
};

const INITIAL_CONTEXT: ExposedProps = {
  _slideStack: [],
  isSlideInStack: () => false,
  activeSlide: null,
  showSlide: _.noop,
  hideSlide: _.noop,
  hideSlides: _.noop,
  hideLastSlide: _.noop,
  renderSlides: () => null,
  rootRef: React.createRef(),
  outletSpringRef: {} as SpringRef,
  animationSpringConfig: defaultSpringConfig,
};

const DELIMITER = ":&&:";
const UINavContext = React.createContext(INITIAL_CONTEXT);

/**
 * A component to assist in building navigation views that animate "slides" or other views into
 * a main outlet area. Gesture support enabled if desired.
 *
 * Known issues:
 * - Seems to be a problem with dynamic slide handling and multiple animations completing. Sometimes
 *   animation spring is associated to a different slide (since react-spring is index based) and dynamic
 *   slides can change positions.
 * - Also another issue is that onHidden isn't always invoked when slides are hidden for dynamic
 *   slides when quickly hiding and modifying the array of dynamic slides.
 * - TODO: Refactor to NOT use useSprings() plural, and instead have each slide manage its own spring.
 *         That should allow each slide to be independent and array position shouldn't matter.
 */
const UINav: FunctionComponent<Props> = (props: Props): React.ReactElement => {
  const slideState = useUINavSlideStack({ onSlideStackChanged: props.onSlideChange });
  const uiNavChildren = useUINavChildren({ children: props.children });
  const rootRef: RefObject<HTMLDivElement> = useRef(null);
  const outletSpringRef = useSpringRef();
  const navSlideComponents = uiNavChildren.getSlides();
  const stateRef = useRef({
    currentSlideState: slideState,
    navSlideComponents,
  });
  const rootRefReady = useRef(false);
  const runAfterFirstRenderRef = useRef(false);
  const animationSpringConfig: SpringConfig = props.animationSpringConfig || defaultSpringConfig;

  // Look for changes in the nav slides in case for dynamic slides.
  // If the list changes, update state such that only valid slides remain.
  const validNavSlideIds = navSlideComponents.map((s) => {
    return s.props.slideId;
  });
  const navSlideHash = validNavSlideIds.join(DELIMITER);
  const navSlideHashRef = useRef("");

  // For gesture as its not tied to react renders.
  // Save a reference to the current slide state and current nav slides possible.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  stateRef.current = {
    currentSlideState: slideState,
    navSlideComponents,
  };

  const getTotalWidth = (): number => {
    let width = window.innerWidth;

    if (rootRef.current && rootRef.current.clientWidth) {
      width = rootRef.current.clientWidth;
    }

    return width;
  };

  const convertPercentageToPx = (percent: number): number => {
    return (percent / 100) * getTotalWidth();
  };

  // Setup springs for all slide containers
  // Initialize their starting values.
  // All slides are offset by 100% initially.
  // opacity of the backdrop is 0 initially.
  const [springs, api] = useSprings(navSlideComponents.length, () => {
    return {
      translateX: convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.WAITING),
      opacity: 0,
      zIndex: 0,
      config: animationSpringConfig,
    };
  });

  const showSlide = (slideId: string): void => {
    // First, lets check if the slideId is in our set of supplied nav slides.
    const validSlideComponents = stateRef.current.navSlideComponents;
    const slideIsValid = validSlideComponents.find((slideComponent) => {
      return slideComponent.props.slideId === slideId;
    });

    if (slideIsValid) {
      stateRef.current.currentSlideState.showSlide(slideId);
    }
  };

  /**
   * Translates the outlet content to a position.
   * 0 position is no offset and aligns the left of the slide with the left of the outlet container.
   */
  const translateOutletTo = (position: number, immediate: boolean): void => {
    outletSpringRef.start({
      immediate,
      translateX: position,
      config: animationSpringConfig,
    });
  };

  /**
   * Animates the slides and outlet content to the correct positions based on current state.
   * Called when state changes usually.
   */
  const animateToPositionsBasedOnState = (
    currentSlideState: SlideStackState,
    slides: SlideComponentDef[],
    silent = false,
    immediate = false,
  ): void => {
    const numberOfSlides = springs.length;
    const computePosition = (index: number): number => {
      const { isOpen, isCurrentSlide } = getSlideInfoByIndex(index, slides, currentSlideState);

      const translateXVal = computeOffsetPositionForSlide(isOpen, isCurrentSlide, getTotalWidth());

      return translateXVal;
    };
    const computeOpacity = (index: number): number => {
      const { isOpen, isCurrentSlide } = getSlideInfoByIndex(index, slides, currentSlideState);

      return isOpen && !isCurrentSlide ? 0.1 : 0;
    };
    const events = {
      onBeforeAnimation: (index: number) => {
        const isSwapping = currentSlideState.lastSlideChanges.activeSlideSwapped;

        const { isCurrentSlide } = getSlideInfoByIndex(index, slides, currentSlideState);

        // If the current slide is actually swapping with another, we want to give the
        // impression it is coming in from the right again. So immediately move it
        // to the waiting position before proceeding with the normal animation.
        if (isCurrentSlide && isSwapping) {
          springs[index].translateX.set(convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.WAITING));
        }
      },
    };

    // Update the zIndex's of the slides to match final state
    updateSlideLayering(api, numberOfSlides, slides, currentSlideState);

    // Animate slides to final positions. Store the promises and wait for them to finish
    const animationPromises = animateSlidesToPositions(
      api,
      immediate,
      computePosition,
      computeOpacity,
      events,
    );

    // Finally, move the outlet the correct position.
    // The outlet is offset left when there is an active slide. Otherwise, it should be center.
    if (currentSlideState.activeSlide) {
      translateOutletTo(convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.STACKED), immediate);
    } else {
      translateOutletTo(convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.PRESENTING), immediate);
    }

    // If the transition was supposed to be silent to the user, then don't trigger any events!
    if (!silent) {
      triggerHandlersForAnimationFinished(currentSlideState, slides, animationPromises);
    }

    // Once we have come to rest and not via cancelling animations, reset the slide changes
    // data.
    invokeCallbackIfAllAnimationsFinish(animationPromises, () => {
      slideState.clearSlideChanges();
    });
  };

  /**
   * Initially sets positions of the slides when mounted.
   */
  const initallySetPositions = (
    currentSlideState: SlideStackState,
    slides: SlideComponentDef[],
  ): void => {
    animateToPositionsBasedOnState(currentSlideState, slides, true);
  };

  /**
   * Immediately shifts slides to the position dictated by the desiredSlideState
   */
  const repositionSlidesWhenSlideOrderChanges = (
    slideComponents: Array<React.Component<UINavSlideProps>>,
    desiredSlideState: SlideStackState,
  ): void => {
    slideComponents.forEach((slide, idx) => {
      const info = getSlideInfo(slide, desiredSlideState);

      if (info) {
        const translateXVal = computeOffsetPositionForSlide(
          info.isOpen,
          info.isCurrentSlide,
          getTotalWidth(),
        );

        springs[idx].translateX.set(translateXVal);
      }
    });
  };

  /**
   * Called when gesture is panning
   */
  const handlePanOnSlide = (slideId: string, slideBehind: string | null, deltaX: number): void => {
    const offsetLeftPos = convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.STACKED);
    const outletPosition = offsetLeftPos - deltaX * (SLIDE_POSITIONS_AS_PERCENT.STACKED / 100);
    const outletPositionBounded = Math.min(
      outletPosition,
      convertPercentageToPx(SLIDE_POSITIONS_AS_PERCENT.PRESENTING),
    );

    // Move the slide to the position.
    translateSlideTo(api, stateRef.current.navSlideComponents, slideId, deltaX);

    // Either move the previous slide behind this one, or the outlet content
    if (slideBehind) {
      translateSlideTo(
        api,
        stateRef.current.navSlideComponents,
        slideBehind,
        outletPositionBounded,
      );
    } else {
      translateOutletTo(outletPositionBounded, true);
    }
  };

  /**
   * Called when gesture starts
   */
  const onSwipeBackStart = (): void => {
    if (props.onSwipeBackStart) {
      props.onSwipeBackStart(stateRef.current.currentSlideState.activeSlide);
    }
  };

  /**
   * Called when gesture has ended
   */
  const onSwipeBackEnd = (shouldHide: boolean): void => {
    if (shouldHide) {
      stateRef.current.currentSlideState.hideLastSlide();

      if (props.onSwipeBackEnd) {
        props.onSwipeBackEnd(stateRef.current.currentSlideState.activeSlide);
      }
    } else {
      animateToPositionsBasedOnState(
        stateRef.current.currentSlideState,
        stateRef.current.navSlideComponents,
      );
    }
  };

  const renderSlides = () => {
    return springs.map((springStyles, itemIdx) => {
      const { slideId, isOpen, contentShouldBeRenderedWhileClosed, isCurrentSlide } =
        getSlideInfoByIndex(itemIdx, navSlideComponents, slideState);
      const slideContent = navSlideComponents[itemIdx];
      const { gestureEnabled = props.enableSwipebackOnSlides } = slideContent.props;

      const translateToForSlide = (positionPx: number): void => {
        handlePanOnSlide(slideId, stateRef.current.currentSlideState.slideBehind, positionPx);
      };

      // Always wrap the content in a UINavGestureEnabledSlideContent in case
      // we nest UINav's, the noop methods on the gesture handler will prevent the
      // other gestures from triggering.
      const gestureWrapperProps = {
        translateTo: gestureEnabled ? translateToForSlide : _.noop,
        onSwipeBackStart: gestureEnabled ? onSwipeBackStart : _.noop,
        onSwipeBackEnd: gestureEnabled ? onSwipeBackEnd : _.noop,
      };

      const shouldRenderSlideContent = isOpen || contentShouldBeRenderedWhileClosed;

      return (
        <animated.div
          key={slideId}
          className={classnames(styles.slide, {
            [styles.isOpen]: isOpen,
          })}
          style={{
            translateX: springStyles.translateX,
            zIndex: springStyles.zIndex,
          }}
        >
          {isOpen && (
            <animated.div
              className={classnames(styles.backdrop, {
                [styles.visible]: !isCurrentSlide,
              })}
              style={{
                opacity: springStyles.opacity,
              }}
            />
          )}

          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <UINavGestureEnabledSlideContent {...gestureWrapperProps}>
            {props.enableHardwareBack && props.hardwareBackPriority && (
              <IonBackHandler
                isActive={isOpen}
                priority={props.hardwareBackPriority}
                onHardwareBack={stateRef.current.currentSlideState.hideLastSlide}
              />
            )}

            <DelayedUnmount isOpen={shouldRenderSlideContent} springConfig={animationSpringConfig}>
              {slideContent}
            </DelayedUnmount>
          </UINavGestureEnabledSlideContent>
        </animated.div>
      );
    });
  };

  // If the active slide changes, animate things to the correct position.
  useEffect(() => {
    // Only react to changes when we are ready.
    if (rootRefReady.current) {
      const isSwapping = slideState.lastSlideChanges.activeSlideSwapped;
      const noAnimationWhenSwapping = isSwapping && props.noAnimationWhenSwapping;
      const noAnimation = noAnimationWhenSwapping || props.disableAnimations;

      animateToPositionsBasedOnState(slideState, navSlideComponents, false, noAnimation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,no-underscore-dangle
  }, [slideState.activeSlide, slideState._slideStack.length]);

  // Wait for the rootRef to exist before we position everything.
  // We use this rootRef's width to calculate the offset positions of the slides.
  useEffect(() => {
    if (rootRef.current) {
      rootRefReady.current = true;
      initallySetPositions(slideState, navSlideComponents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootRef.current]);

  useEffect(() => {
    if (runAfterFirstRenderRef.current) {
      const newHash = navSlideHash;

      // DEBUG: Validate dynamic slide changes, and log an error in dev when developers
      // don't maintain relative ordering of slides.
      validateDynamicSlideChanges(navSlideHashRef.current, newHash);
      navSlideHashRef.current = newHash;
      slideState.keepOnlyValidSlides(validNavSlideIds);
      repositionSlidesWhenSlideOrderChanges(
        stateRef.current.navSlideComponents,
        stateRef.current.currentSlideState,
      );
    } else {
      runAfterFirstRenderRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navSlideHash]);

  return (
    <UINavContext.Provider
      value={{
        // eslint-disable-next-line no-underscore-dangle
        _slideStack: slideState._slideStack,
        isSlideInStack: slideState.isSlideInStack,
        activeSlide: slideState.activeSlide,
        showSlide,
        hideSlide: slideState.hideSlide,
        hideSlides: slideState.hideSlides,
        hideLastSlide: slideState.hideLastSlide,
        renderSlides,
        rootRef,
        outletSpringRef,
        animationSpringConfig,
      }}
    >
      {uiNavChildren.getRootContent()}
    </UINavContext.Provider>
  );
};

export const UINavConsumer = UINavContext.Consumer;
export default UINav;

export { UINavOutlet, UINavSlide, UINavSlideView, UINavSlides };
