import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import TEST_IDS from "constants/testIds";

import AllAppDisplay from "common/components/AllAppDisplay";
import ConfirmationDialogView from "common/components/ConfirmationDialogView";
import UIAsyncButton from "common/components/UIAsyncButton";
import UICheckbox from "common/components/UICheckbox";
import UIDialog from "common/components/UIDialog";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithToastProps &
  WithTranslation & {
    showAddFriendConfirmation: boolean;
    handleConfirmAddFriend: (showAgain: boolean) => void;
    isOpen: boolean;
    onClose: () => void;
    username: string;
  };

type State = {
  showAgain: boolean;
};

class AddFriendDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showAgain: this.props.showAddFriendConfirmation,
    };
  }

  handleConfirm = (): void => {
    this.props.handleConfirmAddFriend(this.state.showAgain);
  };

  handleCheckboxClick = (): void => {
    this.setState((prevState) => ({
      showAgain: !prevState.showAgain,
    }));
  };

  renderConfirmButton = (): React.ReactNode => {
    return (
      <UIAsyncButton
        className={styles.addFriendButton}
        color="primary"
        onClick={() => this.props.handleConfirmAddFriend(this.state.showAgain)}
        dataTestId={TEST_IDS.AddFriendModalConfirmationId}
      >
        <UIText variant="body1" weight="bold">
          {this.props.t("ADD_FRIEND")}
        </UIText>
      </UIAsyncButton>
    );
  };

  render = (): React.ReactNode => {
    const { username } = this.props;
    const addFriendDialogTitle = this.props.t("ADD_USER", { username });
    const addFriendDialogDesc = this.props.t("ADD_USER_AS_A_FRIEND", { username });

    return (
      <UIDialog type="floating-center" isOpen={this.props.isOpen} close={this.props.onClose}>
        <ConfirmationDialogView
          title={addFriendDialogTitle}
          description={addFriendDialogDesc}
          renderConfirmBtn={this.renderConfirmButton}
          cancelBtnText={this.props.t("CANCEL")}
          onCancel={this.props.onClose}
          onConfirm={this.handleConfirm}
        >
          <AllAppDisplay className={styles.allAppDisplay} />
          <div className={styles.checkboxContainer}>
            <UICheckbox checked={!this.state.showAgain} onChange={this.handleCheckboxClick} />
            <UIText color="dark">{this.props.t("DONT_SHOW_AGAIN")}</UIText>
          </div>
        </ConfirmationDialogView>
      </UIDialog>
    );
  };
}

const WithToast = withToast(AddFriendDialog);

export default withTranslation()(WithToast);
