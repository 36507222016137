import classNames from "classnames";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import CampfireBadge from "assets/images/onboarding/campfire-square-badge.png";
import MockAvatarFishing from "assets/images/profiles/mock_avatar_fishing.jpg";
import MockAvatarManGrayShirt from "assets/images/profiles/mock_avatar_man_gray_shirt.jpg";
import MockAvatarPaintWall from "assets/images/profiles/mock_avatar_woman_paint_wall.jpg";

import UIAvatar from "common/components/Avatar/UIAvatar";
import Image from "common/components/Image";
import OnboardElementLayout from "common/components/Onboarding/components/OnboardElementLayout";
import OnboardElementSubmitButton from "common/components/Onboarding/components/OnboardElementSubmitButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Friend = {
  name: string;
  message: string;
  avatarUrl: string;
};

export type Props = WithTranslation & {
  onSubmit: () => Promise<void>;
};

type State = {};

class OnboardingNianticNotifications extends React.Component<Props, State> {
  getFriends = () => {
    return [
      {
        name: "Jesse",
        message: this.props.t("WANNA_GAME_TODAY"),
        avatarUrl: MockAvatarFishing,
      },
      {
        name: "Jose",
        message: this.props.t("IM_ALMOST_THERE"),
        avatarUrl: MockAvatarManGrayShirt,
      },
      {
        name: "Olivia",
        message: this.props.t("HI_IVE_ARRIVED"),
        avatarUrl: MockAvatarPaintWall,
      },
    ];
  };

  renderFriend = (friend: Friend, containerClassName?: string): React.ReactNode => {
    return (
      <div className={classNames(styles.card, containerClassName)}>
        <div className={styles.friendContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.avatarContainer}>
              <UIAvatar avatarUrl={friend.avatarUrl} name={friend.name} size={40} />
            </div>
            <UISpacer w={14} />
            <div className={styles.idContainer}>
              <UIText color="dark" variant="h5" weight="bold">
                {friend.name}
              </UIText>
              <UISpacer h={2} />
              <UIText color="medium" variant="h5" weight="medium">
                {`${friend.message}`}
              </UIText>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSubmit = (): React.ReactNode => {
    return (
      <OnboardElementSubmitButton text={this.props.t("NOTIFY_ME")} onClick={this.props.onSubmit} />
    );
  };

  render = (): React.ReactNode => {
    const friends = this.getFriends();

    return (
      <OnboardElementLayout
        title={this.props.t("GET_NOTIFIED_WHEN_YOUR_FRIENDS_MESSAGE")}
        renderSubmit={this.renderSubmit}
      >
        <UISpacer h={56} />
        {this.renderFriend(friends[2], styles.cardBottom)}
        {this.renderFriend(friends[1], styles.cardMiddle)}
        {this.renderFriend(friends[0], styles.cardTop)}
        <div className={styles.campfireBadge}>
          <Image src={CampfireBadge} />
        </div>
      </OnboardElementLayout>
    );
  };
}

export default withTranslation()(OnboardingNianticNotifications);
