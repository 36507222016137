import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";

import EmbeddedPageWithTopBarWrapper from "common/components/EmbeddedPageWithTopBarWrapper";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import PageLoadError from "common/components/PageLoadError";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import { UserPage_Query$data as UserPageQueryResponse } from "__generated__/UserPage_Query.graphql";

// Lazy Load Main View
const UserPageMainContent = React.lazy(() => import("pages/User/components/UserPageMainContent"));

type Props = WithTranslation & {};

type State = {
  // Ionic does not unmount pages when used in a tab view (IonTabs).
  // So we need to signal to relay when ionic brings this page into view and trigger a re-render.
  initializedAt: number;
};

type QRProps = {
  me: UserPageQueryResponse["me"];
};

const USER_PAGE_QUERY = graphql`
  query UserPage_Query {
    me {
      ...UserPageMainContent_me
    }
  }
`;

class UserPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedAt: Date.now(),
    };
  }

  reInit = (): void => {
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  render = (): React.ReactNode => (
    <UIErrorBoundary onRetry={this.onRetry}>
      <Suspense fallback={<LoadingPageDefault />}>
        <QueryRenderer
          key={this.state.initializedAt}
          environment={environment}
          query={USER_PAGE_QUERY}
          variables={{}}
          render={({ props, error }) => {
            const qrProps = props as QRProps;
            const isLoading = !qrProps;

            if (error) {
              return (
                <EmbeddedPageWithTopBarWrapper>
                  <PageLoadError
                    errorMessage={this.props.t("SORRY_WE_HAD_TROUBLE_LOADING_PROFILE")}
                    reload={this.reInit}
                  />
                </EmbeddedPageWithTopBarWrapper>
              );
            }

            if (isLoading) {
              return (
                <EmbeddedPageWithTopBarWrapper>
                  <LoadingPageDefault />
                </EmbeddedPageWithTopBarWrapper>
              );
            }

            return <UserPageMainContent me={qrProps.me} />;
          }}
        />
      </Suspense>
    </UIErrorBoundary>
  );
}

export default withTranslation()(UserPage);
