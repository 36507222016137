import _ from "lodash";
import React from "react";

import HARDWARE_BACK_EVENT_PRIORITY from "constants/hardwareBackHierarchy";
import ReportContext from "constants/reportContext";

import UIPane from "common/components/UIPane";
import { UserProfileOptions } from "common/components/UserProfile";
import UserProfileFetcher from "common/components/UserProfileFetcher";

import styles from "./styles.scss";

export type ExposedProps = {
  viewUser: (userId: string, reportContext?: ReportContext, options?: UserProfileOptions) => void;
};

type Props = {
  children: React.ReactNode;
  renderProfile?: (userId: string, close: () => void) => React.ReactNode;
  disableDms?: boolean;
  disableInviteToClubs?: boolean;
};

type State = {
  isProfileOpen: boolean;
  viewingUserId: string;
  reportContext: ReportContext;
  options?: UserProfileOptions;
};

const INITIAL_CONTEXT: ExposedProps = {
  viewUser: _.noop,
};

export const UserProfileContext = React.createContext(INITIAL_CONTEXT);

export class UserProfileProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isProfileOpen: false,
      viewingUserId: "",
      reportContext: ReportContext.UNKNOWN,
    };
  }

  viewUser = (
    userId: string,
    reportContext?: ReportContext,
    options?: UserProfileOptions,
  ): void => {
    this.setState({
      isProfileOpen: true,
      viewingUserId: userId,
      reportContext: reportContext ?? ReportContext.UNKNOWN,
      options,
    });
  };

  closeUserProfileDialog = (): void => {
    this.setState({ isProfileOpen: false });
  };

  clearViewingUserId = (): void => {
    this.setState({ viewingUserId: "" });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <UserProfileContext.Provider
        value={{
          viewUser: this.viewUser,
        }}
      >
        <UIPane
          className={styles.root}
          isOpen={this.state.isProfileOpen}
          onDidDismiss={this.clearViewingUserId}
          close={this.closeUserProfileDialog}
          disableDefaultHeader
          hardwareBackPriority={HARDWARE_BACK_EVENT_PRIORITY.appRoot.userProfile}
        >
          {this.props.renderProfile &&
            this.props.renderProfile(this.state.viewingUserId, this.closeUserProfileDialog)}
          {!this.props.renderProfile && (
            <UserProfileFetcher
              userId={this.state.viewingUserId}
              reportContext={this.state.reportContext}
              close={this.closeUserProfileDialog}
              options={this.state.options}
              disableDms={this.props.disableDms}
              disableInviteToClubs={this.props.disableInviteToClubs}
            />
          )}
        </UIPane>

        {children}
      </UserProfileContext.Provider>
    );
  };
}

export const UserProfileConsumer = UserProfileContext.Consumer;
