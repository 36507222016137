import _ from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { isAndroid } from "common/capacitor/helpers";
import config from "constants/config";

import UIButton from "common/components/UIButton";
import UIDialog from "common/components/UIDialog";
import UILink from "common/components/UILink";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  isOpen: boolean;
};

class ForceUpgradeDialog extends React.Component<Props> {
  // TODO: This button needs to link to the app store!
  render = (): React.ReactNode => {
    let storeLink: string | undefined;

    if (isAndroid) {
      storeLink = config.get("CAMPFIRE_APP_ANDROID_PLAY_STORE_URL");
    } else {
      storeLink = config.get("CAMPFIRE_APP_IOS_APP_STORE_URL");
    }

    return (
      <UIDialog
        type="floating-center"
        isOpen={this.props.isOpen}
        cssClass={styles.root}
        backdropDismiss={false}
        close={_.noop}
      >
        <div className={styles.content}>
          <UIText variant="h2" weight="bold" color="dark">
            {this.props.t("UPDATE_REQUIRED")}
          </UIText>
          <UISpacer h={20} />
          <UIText variant="body1" color="dark">
            {this.props.t("NEW_VERSION_AVAILABLE")}
          </UIText>
          <UISpacer h={24} />
          {storeLink && (
            <UILink href={storeLink} openInNewTab className={styles.action}>
              <UIButton className={styles.updateButton} expand="block" size="default" fill="solid">
                <UIText variant="body1" weight="bold" color="white">
                  {this.props.t("UPDATE")}
                </UIText>
              </UIButton>
            </UILink>
          )}
        </div>
      </UIDialog>
    );
  };
}

export default withTranslation()(ForceUpgradeDialog);
