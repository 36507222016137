import React from "react";

import { isStandalone } from "common/capacitor/helpers";
import UserLocationStore from "common/stores/UserLocationStore";

export type ExposedProps = {
  updateRateMs: number;
  expiresAt: number | null;
  useBackgroundLocationSharing: boolean;
  increaseLocationUpdateRate: () => void;
  resetLocationUpdateRate: () => void;
};

type Props = {
  defaultUpdateRate?: number;
  acceleratedUpdateRate?: number;
  children: React.ReactNode;
};

type State = {
  updateRateMs: number;
  expiresAt: number | null;
  useBackgroundLocationSharing: boolean;
};

const TWENTY_SECONDS = 1_000 * 20;
const ONE_MINUTE_MS = 1_000 * 60;
const ONE_HOUR_MS = ONE_MINUTE_MS * 60;
const FIFTEEN_MINUTES = ONE_MINUTE_MS * 15;

// These get overridden by the values on props as those are the values that Backend configures.
export const DEFAULT_UPDATE_RATE_MS = FIFTEEN_MINUTES;
export const ACCELERATED_RATE_MS = TWENTY_SECONDS;

const INITIAL_CONTEXT: ExposedProps = {
  updateRateMs: DEFAULT_UPDATE_RATE_MS,
  expiresAt: 0,
  useBackgroundLocationSharing: false,
  increaseLocationUpdateRate: () => undefined,
  resetLocationUpdateRate: () => undefined,
};

const LocationShareContext = React.createContext(INITIAL_CONTEXT);

export class LocationShareProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const lastSavedExpiryTimeMs = UserLocationStore.get("locationShareExpiresAt") || 0;
    const now = Date.now();
    const isAccelerated = lastSavedExpiryTimeMs > now;

    // Check if we should be using the accelerated rate.
    this.state = {
      updateRateMs: isAccelerated
        ? this.getAcceleratedUpdateRateMs()
        : this.getDefaultUpdateRateMs(),
      expiresAt: lastSavedExpiryTimeMs,
      useBackgroundLocationSharing: false,
    };
  }

  getDefaultUpdateRateMs = (): number => {
    return this.props.defaultUpdateRate || DEFAULT_UPDATE_RATE_MS;
  };

  getAcceleratedUpdateRateMs = (): number => {
    return this.props.acceleratedUpdateRate || ACCELERATED_RATE_MS;
  };

  getNextExpirationTime = (): number => {
    const oneHourInTheFuture = Date.now() + ONE_HOUR_MS;

    return oneHourInTheFuture;
  };

  changeLocationUpdateRate = (updateRateMs: number): void => {
    const nextExpiryTimeMs = this.getNextExpirationTime();

    // Cache this value, so if the user comes back, we can continue to use the accelerated
    // share rate.
    UserLocationStore.set("locationShareExpiresAt", nextExpiryTimeMs);

    // Enable background location sharing if we've accelerated updates and we're on standalone.
    const useBackgroundLocationSharing = updateRateMs < DEFAULT_UPDATE_RATE_MS && isStandalone;

    this.setState({
      updateRateMs,
      expiresAt: nextExpiryTimeMs,
      useBackgroundLocationSharing,
    });
  };

  increaseLocationUpdateRate = (): void => {
    this.changeLocationUpdateRate(this.getAcceleratedUpdateRateMs());
  };

  resetLocationUpdateRate = (): void => {
    UserLocationStore.set("locationShareExpiresAt", 0);

    this.setState({
      updateRateMs: this.getDefaultUpdateRateMs(),
      expiresAt: 0,
      useBackgroundLocationSharing: false,
    });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <LocationShareContext.Provider
        value={{
          updateRateMs: this.state.updateRateMs,
          expiresAt: this.state.expiresAt,
          useBackgroundLocationSharing: this.state.useBackgroundLocationSharing,
          increaseLocationUpdateRate: this.increaseLocationUpdateRate,
          resetLocationUpdateRate: this.resetLocationUpdateRate,
        }}
      >
        {children}
      </LocationShareContext.Provider>
    );
  };
}

export const LocationShareConsumer = LocationShareContext.Consumer;
