/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { ToastConsumer, ExposedProps } from "providers/ToastProvider";

export type WithToastProps = {
  toastProvider: ExposedProps;
};

// eslint-disable-next-line max-len
export default <P extends WithToastProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithToastProps>): React.ReactElement => (
    <ToastConsumer>
      {(exposedProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component toastProvider={exposedProps} {...props} />
      )}
    </ToastConsumer>
  );
};
