/**
 * @generated SignedSource<<6a6aca68e34223cd324fed133f882d05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkNuxShownMutation$variables = {};
export type MarkNuxShownMutation$data = {
  readonly markNuxShown: {
    readonly me: {
      readonly shouldShowNux: boolean;
    };
  };
};
export type MarkNuxShownMutation = {
  response: MarkNuxShownMutation$data;
  variables: MarkNuxShownMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shouldShowNux",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkNuxShownMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MarkNuxShownResponse",
        "kind": "LinkedField",
        "name": "markNuxShown",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MarkNuxShownMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MarkNuxShownResponse",
        "kind": "LinkedField",
        "name": "markNuxShown",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19a54357be0e95d400c66946616128b3",
    "id": null,
    "metadata": {},
    "name": "MarkNuxShownMutation",
    "operationKind": "mutation",
    "text": "mutation MarkNuxShownMutation {\n  markNuxShown {\n    me {\n      shouldShowNux\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "598be00cad7a0c958b991822498cb584";

export default node;
