import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { LocationHeartbeatMutation$data } from "__generated__/LocationHeartbeatMutation.graphql";

type ReturnType = LocationHeartbeatMutation$data["locationHeartbeat"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  latitude: number;
  longitude: number;
};

const mutation = graphql`
  mutation LocationHeartbeatMutation($input: LocationHeartbeatInput!) {
    locationHeartbeat(input: $input) {
      success
      me {
        location
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "locationHeartbeat.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "locationHeartbeat.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "locationHeartbeat"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      cacheConfig: {
        metadata: {
          disableMutationInvalidation: true,
        },
      },
      variables: {
        input: {
          location: `[${input.longitude}, ${input.latitude}]`,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
