import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import withBlockedUsers, {
  WithBlockedUsersProps,
} from "providers/BlockedUsersProvider/withBlockedUsers";
import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import ConfirmationDialogView from "common/components/ConfirmationDialogView";
import UIDialog from "common/components/UIDialog";

import styles from "./styles.scss";

export type Props = WithTranslation &
  WithBlockedUsersProps &
  WithToastProps & {
    isOpen: boolean;
    onClose: () => void;
    onBlock?: () => void;
    displayName: string;
    userId: string;
  };

type State = {};

class BlockUserConfirmationDialog extends React.Component<Props, State> {
  blockUser = async (): Promise<void> => {
    try {
      await this.props.blockedUsersProvider.blockUser(this.props.userId);
    } catch (error) {
      this.props.toastProvider.showErrorToast(this.props.t("FAILED_BLOCK"));
    }

    if (this.props.onBlock) {
      this.props.onBlock();
    }
  };

  render = (): React.ReactNode => {
    return (
      <UIDialog
        type="floating-center"
        isOpen={this.props.isOpen}
        onWillDismiss={this.props.onClose}
        close={this.props.onClose}
      >
        <div className={styles.drawer}>
          <ConfirmationDialogView
            title={this.props.t("BLOCK_USERNAME", { username: this.props.displayName })}
            description={this.props.t("BLOCK_CONFIRMATION_DESCRIPTION", {
              username: this.props.displayName,
            })}
            confirmBtnText={this.props.t("BLOCK")}
            onCancel={this.props.onClose}
            onConfirm={this.blockUser}
          />
        </div>
      </UIDialog>
    );
  };
}

const ToastConnected = withToast(BlockUserConfirmationDialog);
const BlockedUsersConnected = withBlockedUsers(ToastConnected);

export default withTranslation()(BlockedUsersConnected);
