import Clipboard from "clipboard";
import React, { RefObject } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import StubbedPlugins from "common/utils/webInterop/plugins";

const { Clipboard: ClipboardStubbedPlugin } = StubbedPlugins;

type ExposedProps = {
  copyStateStr: string;
  copyStatus: string;
};

export type Props = WithTranslation & {
  textToCopy: string;
  children: (exposedProps: ExposedProps) => React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  onSuccessfulCopy?: () => void;
  onFailToCopy?: () => void;
};

type State = {
  status: "ready" | "copied";
};

const COPY_TIMER_MS = 1500;

class UICopy extends React.Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  copiedTimer: any = null;

  triggerElRef: RefObject<HTMLDivElement> = React.createRef();

  clipboard: Clipboard;

  constructor(props: Props) {
    super(props);

    this.state = {
      status: "ready",
    };
  }

  componentDidMount = (): void => {
    if (this.triggerElRef.current) {
      // Create clipboardJs instance
      this.clipboard = new Clipboard(this.triggerElRef.current);

      // Setup success handler
      this.clipboard.on("success", () => {
        if (this.props.onSuccessfulCopy) {
          this.props.onSuccessfulCopy();
        }
      });

      // Setup error handler
      this.clipboard.on("error", () => {
        // If clipboardJs fails to copy, try the plugin.
        this.fallbackCopy();
      });
    }
  };

  componentWillUnmount = (): void => {
    if (this.copiedTimer) {
      clearTimeout(this.copiedTimer);
    }

    this.clipboard.destroy();
  };

  fallbackCopy = async (): Promise<void> => {
    try {
      // Try to use the plugin to perform a copy.
      await ClipboardStubbedPlugin.write({
        string: this.props.textToCopy,
      });

      // If the fallback was successful, announce that we actually copied!
      if (this.props.onSuccessfulCopy) {
        this.props.onSuccessfulCopy();
      }
    } catch (error) {
      // Otherwise, both browser and plugin copy failed. We sad.
      if (this.props.onFailToCopy) {
        this.props.onFailToCopy();
      }
    }
  };

  handleCopyText = (event: React.MouseEvent): void => {
    this.setState({ status: "copied" });

    this.copiedTimer = setTimeout(() => {
      this.setState({ status: "ready" });
    }, COPY_TIMER_MS);

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render = (): React.ReactNode => {
    return (
      <>
        <div
          role="presentation"
          className={this.props.className}
          ref={this.triggerElRef}
          onClick={this.handleCopyText}
          data-clipboard-text={this.props.textToCopy}
        >
          {this.props.children({
            copyStateStr:
              this.state.status === "copied" ? this.props.t("COPIED") : this.props.t("COPY"),
            copyStatus: this.state.status,
          })}
        </div>
      </>
    );
  };
}

export default withTranslation()(UICopy);
