/**
 * @generated SignedSource<<b32fb43ca1ee8899b115d33e6ce8a395>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BanUserInput = {
  clubId: string;
  reason?: string | null;
  userId: string;
};
export type BanUserMutation$variables = {
  clubId: string;
  input: BanUserInput;
};
export type BanUserMutation$data = {
  readonly banUser: {
    readonly success: boolean;
    readonly user: {
      readonly id: string;
      readonly isAdmin: boolean;
      readonly isMember: boolean;
    };
  };
};
export type BanUserMutation = {
  response: BanUserMutation$data;
  variables: BanUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clubId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "clubId",
    "variableName": "clubId"
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BanUserResponse",
    "kind": "LinkedField",
    "name": "banUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "kind": "ScalarField",
            "name": "isAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "kind": "ScalarField",
            "name": "isMember",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BanUserMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BanUserMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "14917b34ae70202fa402cd3371ec62ee",
    "id": null,
    "metadata": {},
    "name": "BanUserMutation",
    "operationKind": "mutation",
    "text": "mutation BanUserMutation(\n  $input: BanUserInput!\n  $clubId: ID!\n) {\n  banUser(input: $input) {\n    user {\n      id\n      isAdmin(clubId: $clubId)\n      isMember(clubId: $clubId)\n    }\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "08e509ddea38373105de687eea745427";

export default node;
