import { RouteComponentProps } from "react-router-dom";

import { CLUBS_ROUTE, QUERY_PARAMS } from "constants/routes";

export const getUrlForClubChannel = (clubId: string, channelId: string): string => {
  return `/${CLUBS_ROUTE}/${clubId}/${channelId}`;
};

// DEPRECATED - Please use routeToClubChannelByMessageId
export default (
  routeComponentProps: RouteComponentProps,
  clubId: string,
  channelId: string,
  chatv2MessageId: string,
): void => {
  routeComponentProps.history.push({
    pathname: getUrlForClubChannel(clubId, channelId),
    search: `${QUERY_PARAMS.clubs.textChannel.messageId.key}=${chatv2MessageId}`,
  });
};
