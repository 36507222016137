import logException from "common/analytics/exceptions";
import PermissionStore, { PERMISSION_TYPE } from "common/stores/PermissionStore";
import StubbedPlugins from "common/utils/webInterop/plugins";

const { Geolocation } = StubbedPlugins;

type PermissionResultState = "granted" | "denied";

const promptForGeolocationAccess = async (): Promise<void> => {
  PermissionStore.set(PERMISSION_TYPE.geolocation, true);

  try {
    await Geolocation.getCurrentPosition();
  } catch (error) {
    logException(
      "Geolocation.getCurrentPosition",
      "promptForGeolocationAccess",
      "geolocation",
      error,
    );
  }
};

export const checkGeolocationPermissions = async (): Promise<PermissionResultState> => {
  try {
    const permissionStatus = await Geolocation.checkPermissions();

    if (permissionStatus.location === "granted") {
      return "granted";
    }

    return "denied";
  } catch (error) {
    return "denied";
  }
};

export const requestGeolocationPermission = async (): Promise<PermissionResultState> => {
  const hasPromptedBefore = PermissionStore.get(PERMISSION_TYPE.geolocation);

  // Check if we have enabled the geolocation permission already.
  const permissionResult = await checkGeolocationPermissions();

  // If so, no need to prompt
  if (permissionResult === "granted") {
    return "granted";
  }

  if (!hasPromptedBefore) {
    await promptForGeolocationAccess();

    const permissionResultUpdated = await checkGeolocationPermissions();

    if (permissionResultUpdated === "granted") {
      return "granted";
    }

    return "denied";
  }

  return "denied";
};
