import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import {
  checkForErrorFromMutationResponse,
  extractErrorMessageFromMutationResponse,
  extractErrorMessageFromSource,
} from "mutations/helpers/errors";

import { JoinClubMutation$data } from "__generated__/JoinClubMutation.graphql";

type ReturnType = JoinClubMutation$data["joinClub"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  clubId: string;
};

const mutation = graphql`
  mutation JoinClubMutation($input: JoinClubInput!) {
    joinClub(input: $input) {
      me {
        ...ClubList_me
      }
      club {
        id
        ...ClubCard_club
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject) => {
      const hasErrors = checkForErrorFromMutationResponse("joinClub", response);

      if (hasErrors) {
        const errorMessage = extractErrorMessageFromMutationResponse("joinClub", response);

        reject(errorMessage);

        return;
      }

      resolve(_.get(response, "joinClub"));
    };

    // Relay Error Handler
    // Invoked when no data and errors are returned in the response.
    const onError = (error: Error) => {
      const relayErrorMessage = extractErrorMessageFromSource(error);

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          clubId: input.clubId,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
