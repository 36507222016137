import React from "react";

import { AnalyticsProvider } from "providers/AnalyticsProvider";

import { ANIMATION_TIMING_MS, AuthenticatedViewMode, SetupStepsNeeded } from "boot-loader";

import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";
import UIPane from "common/components/UIPane";

import AccountSetupFlowQR from "boot-loader/components/AccountSetupFlowQR";
import App from "boot-loader/components/App";
import ResetAccountDataQR from "boot-loader/components/ResetAccountDataQR";

import styles from "./styles.scss";

export type Props = {
  isLoggedIn: boolean;
  authenticatedViewMode: AuthenticatedViewMode | undefined;
  setupStepsNeeded: SetupStepsNeeded;
  closeAndReset: () => void;
  finishSetupFlow: () => Promise<void>;
  proceedToNextSequenceInApp: () => Promise<void>;
  disableBack?: boolean;
  respectSafeArea?: boolean;
};

type State = {};

export default class AuthenticatedAppModeRenderer extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { isLoggedIn, authenticatedViewMode } = this.props;

    const showAccountSetupFlow = isLoggedIn && authenticatedViewMode === "setup";
    const showApp = isLoggedIn && authenticatedViewMode === "app";
    const showResetFlow = isLoggedIn && authenticatedViewMode === "reset";

    return (
      // TODO: Consolidate this with providers in AuthenticatedServiceProviders?
      <AnalyticsProvider contextArgs={{}}>
        <UIPane
          animated={!showAccountSetupFlow}
          isOpen={showAccountSetupFlow}
          enableHardwareBack={false}
          close={this.props.closeAndReset}
          disableDefaultHeader
        >
          <AnimatedFadeInOut
            className={styles.fullHeight}
            show={showAccountSetupFlow}
            durationMs={ANIMATION_TIMING_MS.appFadeInDelayMS}
          >
            <AccountSetupFlowQR
              desiresSetupNianticId={this.props.setupStepsNeeded.nianticId}
              desiresSetupNianticGames={this.props.setupStepsNeeded.gameVisibility}
              desiresSetupNianticFriends={this.props.setupStepsNeeded.friendRecommendations}
              desiresSetupNianticNotifications={this.props.setupStepsNeeded.services}
              disableBack={this.props.disableBack}
              closeAndReset={this.props.closeAndReset}
              finishSetupFlow={this.props.finishSetupFlow}
            />
          </AnimatedFadeInOut>
        </UIPane>

        <UIPane
          animated={!showResetFlow}
          isOpen={showResetFlow}
          enableHardwareBack={false}
          close={this.props.closeAndReset}
          disableDefaultHeader
        >
          <AnimatedFadeInOut
            className={styles.fullHeight}
            show={showResetFlow}
            durationMs={ANIMATION_TIMING_MS.appFadeInDelayMS}
          >
            <ResetAccountDataQR
              proceedToNextSequenceInApp={this.props.proceedToNextSequenceInApp}
            />
          </AnimatedFadeInOut>
        </UIPane>

        <AnimatedFadeInOut
          type="fade-in"
          className={styles.fullScreen}
          show={showApp}
          durationMs={ANIMATION_TIMING_MS.appFadeInDurationMS}
          delayEnterMs={ANIMATION_TIMING_MS.appFadeInDelayMS}
        >
          <App />
        </AnimatedFadeInOut>
      </AnalyticsProvider>
    );
  };
}
