import React from "react";

export type ExposedProps = {
  lastExitUpdatedAt: number;
  invalidateCurrentExit: () => void;
};

type Props = {
  children: React.ReactNode;
};

type State = {
  lastExitUpdatedAt: number;
};

const INITIAL_CONTEXT: ExposedProps = {
  lastExitUpdatedAt: Date.now(),
  invalidateCurrentExit: () => undefined,
};

export const AppRouterV2CoreNavigationContext = React.createContext(INITIAL_CONTEXT);

export class AppRouterV2CoreNavigationProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      lastExitUpdatedAt: Date.now(),
    };
  }

  invalidateCurrentExit = (): void => {
    this.setState({ lastExitUpdatedAt: Date.now() });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <AppRouterV2CoreNavigationContext.Provider
        value={{
          lastExitUpdatedAt: this.state.lastExitUpdatedAt,
          invalidateCurrentExit: this.invalidateCurrentExit,
        }}
      >
        {children}
      </AppRouterV2CoreNavigationContext.Provider>
    );
  };
}

export const AppRouterV2CoreNavigationConsumer = AppRouterV2CoreNavigationContext.Consumer;
