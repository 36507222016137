import environment from "common/relay/relay-env";
import React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import logException from "common/analytics/exceptions";
import UpdateInteractionHistoryMutation from "mutations/UpdateInteractionHistoryMutation";

import { UserInteractionHistoryProvider_me$data as UserInteractionHistoryProviderMe } from "__generated__/UserInteractionHistoryProvider_me.graphql";

type UIInteractionHistory = Record<string, number>;

export type ExposedProps = {
  interactionHistory: UIInteractionHistory;
  markInteraction: (interactionName: string) => Promise<void>;
  hasSeen: (interactionName: string) => boolean;
};

type Props = {
  me: UserInteractionHistoryProviderMe;
  children: React.ReactNode;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  interactionHistory: {},
  markInteraction: () => Promise.resolve(),
  hasSeen: () => false,
};

export const UserInteractionHistoryContext = React.createContext(INITIAL_CONTEXT);

class UserInteractionHistoryProvider extends React.Component<Props, State> {
  getInteractionHistory = (): Record<string, number> => {
    return (this.props.me.interactionHistory || {}) as Record<string, number>;
  };

  /**
   * Marks an interaction name with a timestamp on the BE.
   * If the interaction is already set, this will skip the mutation.
   * If the developer wants to force overwrite, a flag can be provided to always set.
   */
  markInteraction = async (interactionName: string, overwrite = false): Promise<void> => {
    // If we have already marked this interaction, don't bother to mark again.
    if (!overwrite && this.hasSeen(interactionName)) {
      return;
    }

    try {
      const payload = { [interactionName]: Date.now() };

      await UpdateInteractionHistoryMutation.commit(environment, payload);
    } catch (error) {
      logException(
        "UpdateInteractionHistoryMutation",
        "markInteraction",
        "UserInteractionHistoryProvider",
        error,
      );
    }
  };

  hasSeen = (interactionName: string): boolean => {
    const interactionHistory = this.getInteractionHistory();

    return Boolean(interactionHistory[interactionName]);
  };

  render = (): React.ReactNode => {
    const { children } = this.props;
    const interactionHistory = this.getInteractionHistory();

    return (
      <UserInteractionHistoryContext.Provider
        value={{
          interactionHistory,
          markInteraction: this.markInteraction,
          hasSeen: this.hasSeen,
        }}
      >
        {children}
      </UserInteractionHistoryContext.Provider>
    );
  };
}

const FragmentContainer = createFragmentContainer(UserInteractionHistoryProvider, {
  me: graphql`
    fragment UserInteractionHistoryProvider_me on User {
      id
      interactionHistory
    }
  `,
});

export const UserInteractionHistoryConsumer = UserInteractionHistoryContext.Consumer;
export { FragmentContainer as UserInteractionHistoryProvider };
