import { IonItem, IonRadio } from "@ionic/react";
import classnames from "classnames";
import React from "react";

import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props<T> = {
  value: T;
  name: string;
  disabled?: boolean;
  onClick?: () => void;
};

type State = {};

export default class ToggleOption<T> extends React.Component<Props<T>, State> {
  render = (): React.ReactNode => {
    return (
      <IonItem
        className={classnames(styles.radioItem, styles.ionItem)}
        onClick={this.props.onClick}
      >
        <UIText variant="h4" weight="medium" color={this.props.disabled ? "light" : "dark"}>
          {this.props.name}
        </UIText>
        <IonRadio
          disabled={this.props.disabled}
          value={this.props.value}
          slot="end"
          mode="md"
          color="primary"
        />
      </IonItem>
    );
  };
}
