import _ from "lodash";
import { commitMutation, ConnectionHandler, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR } from "constants/errors";
import { MutationError } from "types/modules/relay/mutations";

import { CreateDMChannelMutation$data } from "__generated__/CreateDMChannelMutation.graphql";

type ReturnType = CreateDMChannelMutation$data["createDMChannel"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  currentUserId: string;
  userIds: string[];
};

const mutation = graphql`
  mutation CreateDMChannelMutation($connections: [ID!]!, $input: CreateDMChannelInput!) {
    createDMChannel(input: $input) {
      channel @prependNode(connections: $connections, edgeTypeName: "ChannelEdge") {
        id
        ...DmConnectionLoader_more_info_channel @relay(mask: false)
      }
      me {
        ...MessagesPageOutletContent_me
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "createDMChannel.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "createDMChannel.errors[0]", errors));

        return;
      }

      resolve(_.get(response, "createDMChannel"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0]") || _.get(error, "data.errors[0]") || APOLOGETIC_ERROR;

      reject(relayErrorMessage);
    };

    // Get the connection id for the dm channels connection for the current user.
    // We will target this connection to update and prepend the node.
    const dmChannelConnectionId = ConnectionHandler.getConnectionID(
      input.currentUserId,
      "DmConnectionLoader_dmChannelsConnection",
    );

    // Only pass connections that we could find.
    const connectionsToUpdate = [dmChannelConnectionId].filter(Boolean);

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          // Hardcode name to empty string for now.
          name: "",
          userIds: input.userIds,
        },
        connections: connectionsToUpdate,
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
