import { PermissionState } from "@capacitor/core";
import React from "react";

import logException from "common/analytics/exceptions";
import PermissionStore, { PermissionType, PERMISSION_TYPE } from "common/stores/PermissionStore";

import { requestCameraPermission, checkCameraPermissions } from "./camera";
import { requestGeolocationPermission, checkGeolocationPermissions } from "./geolocation";
import {
  requestPushNotificationPermission,
  checkNotificationPermissions,
} from "./pushNotifications";

type PermissionResultStateBinary = "granted" | "denied";

export type ExposedProps = {
  hasPermission: (permissionType: PermissionType) => Promise<boolean>;
  getPermissionState: (permissionType: PermissionType) => Promise<PermissionState>;
  requestPermission: (permissionType: PermissionType) => Promise<PermissionResultStateBinary>;
};

type Props = {
  children: React.ReactNode;
};

type State = {};

const INITIAL_CONTEXT: ExposedProps = {
  hasPermission: () => Promise.resolve(false),
  getPermissionState: () => Promise.resolve("denied"),
  requestPermission: () => Promise.resolve("denied"),
};

const AppPermissionContext = React.createContext(INITIAL_CONTEXT);

export class AppPermissionProvider extends React.Component<Props, State> {
  getPermissionState = async (permissionType: PermissionType): Promise<PermissionState> => {
    const hasPromptedBefore = PermissionStore.get(permissionType);

    // We manually will handle prompt state from now on.
    if (hasPromptedBefore !== undefined && !hasPromptedBefore) {
      return "prompt";
    }

    try {
      let permissionResult;

      // TODO: IMPLEMENT THESE!
      switch (permissionType) {
        case PERMISSION_TYPE.notifications:
          permissionResult = await checkNotificationPermissions();
          break;

        case PERMISSION_TYPE.geolocation: {
          permissionResult = await checkGeolocationPermissions();
          break;
        }

        case PERMISSION_TYPE.camera: {
          permissionResult = await checkCameraPermissions();
          break;
        }

        default:
          permissionResult = "denied";
      }

      if (permissionResult === "granted") {
        return "granted";
      }
    } catch (error) {
      logException("getPermissionState", "getPermissionState", "AppPermissionProvider", error);
      return "denied";
    }

    return "denied";
  };

  hasPermission = async (permissionType: PermissionType): Promise<boolean> => {
    try {
      const permissionState = await this.getPermissionState(permissionType);

      return permissionState === "granted";
    } catch (error) {
      logException("hasPermission", "hasPermission", "AppPermissionProvider", error);
      return false;
    }
  };

  requestPermission = async (
    permissionType: PermissionType,
  ): Promise<PermissionResultStateBinary> => {
    try {
      switch (permissionType) {
        case PERMISSION_TYPE.notifications:
          return requestPushNotificationPermission();

        case PERMISSION_TYPE.geolocation: {
          const geolocationPermissionState = await requestGeolocationPermission();

          return geolocationPermissionState;
        }

        case PERMISSION_TYPE.camera: {
          return requestCameraPermission();
        }

        default:
          // eslint-disable-next-line no-console
          console.error(`Requesting for permission ${permissionType} not implemented!`);
      }
    } catch (error) {
      logException(
        "requestGeolocationPermission",
        "requestPermission",
        "AppPermissionProvider",
        error,
      );
      return "denied";
    }

    return "denied";
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <AppPermissionContext.Provider
        value={{
          hasPermission: this.hasPermission,
          getPermissionState: this.getPermissionState,
          requestPermission: this.requestPermission,
        }}
      >
        {children}
      </AppPermissionContext.Provider>
    );
  };
}

export const AppPermissionConsumer = AppPermissionContext.Consumer;
