/* eslint-disable no-console */
import environment from "common/relay/relay-env";
import _ from "lodash";
import Pubnub, {
  MessageEvent,
  MessageActionEvent,
  FetchMessagesParameters,
  FetchMessagesResponse,
  MessageCountsResponse,
  GetMessageActionsParameters,
  GetMessageActionsResponse,
} from "pubnub";
import { fetchQuery, graphql } from "relay-runtime";

import decryptMessage from "common/utils/pubnub/decryption";
import { getTimestampAsTimetoken } from "common/utils/pubnub/helpers";
import config from "constants/config";

import { clientMessagesFromHistory_Query$data as messagesFromHistoryResponse } from "__generated__/clientMessagesFromHistory_Query.graphql";

type ListenerRemovalFn = () => void;
type PubnubListenerType = "message" | "messageAction" | "signal";

export type PubnubHandlerFn = (message: MessageEvent) => void;

type PubnubActionHandlerFn = (messageAction: MessageActionEvent) => void;
type SignalListenerObj = Pick<Pubnub.ListenerParameters, "signal">;
type MessageListenerObj = Pick<Pubnub.ListenerParameters, "message">;

export type PubnubFetchMessageMessage = ArrayElement<FetchMessagesResponse["channels"][string]>;
export type PubnubMessageAction = ArrayElement<GetMessageActionsResponse["data"]>;

export type MessageCountChannelConfig = {
  channel: string;
  startTimetoken?: string;
};

type CustomCampfireFetchMessagesParameters = {
  useChatv2?: boolean;
  campfireChannelId?: string;
};

export type CampfireFetchMessagesParameters = FetchMessagesParameters &
  CustomCampfireFetchMessagesParameters;

export const FETCH_MESSAGES_WITH_MESSAGE_ACTIONS_LIMIT = 25;
export const FETCH_MESSAGES_DEFAULT_COUNT = 100;
export const FETCH_MESSAGE_ACTIONS_DEFAULT_COUNT = 10000;
export const PUBNUB_FETCH_MESSAGES_MAX_COUNT = 100;

const MESSAGES_FROM_HISTORY_QUERY = graphql`
  query clientMessagesFromHistory_Query($input: MessagesFromHistoryInput!) {
    messagesFromHistory(input: $input) {
      channels {
        channel
        message {
          actions
          metadata
          attachments
          category
          content
          id
          messageType
          sentAt
          version
          isHiddenByServer
          sender {
            id
            avatarUrl
            displayName
          }
          senderV2 {
            id
            senderId
            avatar
            name
            color
          }
          reactions {
            me
            count
            emoji {
              name
              id
            }
          }
        }
        timetoken
        meta
        actions
      }
    }
  }
`;

export default class PubnubClient {
  client: Pubnub | null;

  globalSignalListeners: Map<PubnubHandlerFn, SignalListenerObj>;

  globalMessageListeners: Map<PubnubHandlerFn, MessageListenerObj>;

  // Not really needed, but this separation helps us debug.
  channelSignalListeners: Map<string, Map<PubnubHandlerFn, SignalListenerObj>>;

  // All handlers and listener types are added here.
  channelListeners: Map<string, Map<PubnubHandlerFn, Pubnub.ListenerParameters>>;

  constructor(uuid: string, authKey?: string) {
    this.globalSignalListeners = new Map();
    this.globalMessageListeners = new Map();
    this.channelSignalListeners = new Map();
    this.channelListeners = new Map();

    this.client = new Pubnub({
      subscribeKey: config.get("CAMPFIRE_APP_PUBNUB_SUBSCRIBE_KEY") as string,
      ssl: true,
      uuid,
      authKey,
      restore: true,
    });
  }

  reset = (uuidOverride: string): void => {
    // If we are initializing again, unsubscribe from all channels.
    if (this.client) {
      this.client.unsubscribeAll();
      this.globalSignalListeners = new Map();
      this.globalMessageListeners = new Map();
      this.channelSignalListeners = new Map();
      this.channelListeners = new Map();
    }

    const uuid = uuidOverride || this.client?.getUUID();

    this.client = new Pubnub({
      subscribeKey: config.get("CAMPFIRE_APP_PUBNUB_SUBSCRIBE_KEY") as string,
      ssl: true,
      uuid,
    });
  };

  checkClientIsReady = (): boolean => {
    if (!this.client) {
      console.warn("Warning: Pubnub Client Instance not initialized!");
      return false;
    }

    return true;
  };

  channelIsInUse = (channelId: string): boolean => {
    const channelSignalHandlers = this.channelSignalListeners.get(channelId);
    const channelHandlers = this.channelListeners.get(channelId);

    // If BOTH are empty, we are not currently using that channel for anything.
    const channelIsInUseForSignals = channelSignalHandlers ? channelSignalHandlers.size > 0 : false;
    const channelIsInUseForMessages = channelHandlers ? channelHandlers.size > 0 : false;

    return channelIsInUseForSignals || channelIsInUseForMessages;
  };

  getClient = (): Pubnub | null => {
    return this.client;
  };

  // ===================================================================
  // ========================= CHANNEL SUBSCRIBE =======================
  // ===================================================================

  subscribeToChannel = (channelId: string): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    this.client?.subscribe({
      channels: [channelId],
      // Pubnub's documentation is so wrong here, passing a number being Date.now() causes
      // new messages to fire on batch calls! wtf...
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      timetoken: getTimestampAsTimetoken() as any,
    });
  };

  unsubscribeFromChannel = (channelId: string): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    this.client?.unsubscribe({ channels: [channelId] });
  };

  // ===================================================================
  // ====================== CHANNEL GROUP SUBSCRIBE ====================
  // ===================================================================

  unsubscribeFromChannelGroups = (channelGroups: string[]): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    this.client?.unsubscribe({ channelGroups });
  };

  subscribeToChannelGroups = (channelGroups: string[]): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    this.client?.subscribe({
      channelGroups,
      // Pubnub's documentation is so wrong here, passing a number being Date.now() causes
      // new messages to fire on batch calls! wtf...
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      timetoken: getTimestampAsTimetoken() as any,
    });
  };

  // ===================================================================
  // ========================= GLOBAL SIGNALS ==========================
  // ===================================================================

  unregisterGlobalSignalHandler = (handler: PubnubHandlerFn): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    const listenerObj = this.globalSignalListeners.get(handler);

    if (listenerObj) {
      this.globalSignalListeners.delete(handler);
      this.client?.removeListener(listenerObj);
    }
  };

  /**
   * Attaches a handler that runs when ANY signal is received by the pub nub client instance
   */
  registerGlobalSignalHandler = (handler: PubnubHandlerFn): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    // If we already have set this function as a handler, overwrite it!
    // (By removing it, and then setting it again)
    if (this.globalSignalListeners.get(handler)) {
      this.unregisterGlobalSignalHandler(handler);
    }

    const listenerObj = { signal: handler };

    this.globalSignalListeners.set(handler, listenerObj);
    this.client?.addListener(listenerObj);
  };

  // ===================================================================
  // ========================= GLOBAL MESSAGES =========================
  // ===================================================================

  unregisterGlobalMessageHandler = (handler: PubnubHandlerFn): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    const listenerObj = this.globalMessageListeners.get(handler);

    if (listenerObj) {
      this.globalMessageListeners.delete(handler);
      this.client?.removeListener(listenerObj);
    }
  };

  /**
   * Attaches a handler that runs when ANY message is received by the pub nub client instance
   */
  registerGlobalMessageHandler = (handler: PubnubHandlerFn): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    // If we already have set this function as a handler, overwrite it!
    // (By removing it, and then setting it again)
    if (this.globalMessageListeners.get(handler)) {
      this.unregisterGlobalMessageHandler(handler);
    }

    // Attach our own handler to decrypt, but key as the function given so we know what
    // to remove later.
    const messageHandler = (messageEvent: MessageEvent) => {
      handler({
        ...messageEvent,
        message: decryptMessage(messageEvent.message),
      });
    };

    const listenerObj = { message: messageHandler };

    this.globalMessageListeners.set(handler, listenerObj);
    this.client?.addListener(listenerObj);
  };

  // ===========================================================================
  // ========================= CHANNEL SCOPED SIGNALS ==========================
  // ===========================================================================

  unregisterSignalHandler = (
    channelId: string,
    handler: PubnubHandlerFn,
    autoUnsubscribe = true,
  ): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    // Get the listener map for a given channelId
    const channelHandlers = this.channelSignalListeners.get(channelId);

    // If we have something, try to find the handler in that map
    if (channelHandlers) {
      const listenerObj = channelHandlers.get(handler);

      // If we could find a handler in the map, remove it from the map,
      // and then remove it from the pubnub client
      if (listenerObj) {
        channelHandlers.delete(handler);
        this.client?.removeListener(listenerObj);

        // If we want to auto unsub from the channel, check if we can, then do it.
        if (autoUnsubscribe && !this.channelIsInUse(channelId)) {
          this.unsubscribeFromChannel(channelId);
        }
      }
    }
  };

  /**
   * Attaches a handler that runs ONLY if a signal is received on the desired channel
   *
   * @returns A function that when called, will unregister the handler
   */
  registerSignalHandler = (channelId: string, handler: PubnubHandlerFn): ListenerRemovalFn => {
    if (!this.checkClientIsReady()) {
      return _.noop;
    }

    // Create a handler that checks if the channel is the one specified.
    const channelSpecificHandler = (message: MessageEvent) => {
      if (message.channel === channelId) {
        handler(message);
      }
    };

    const listenerObj = { signal: channelSpecificHandler };

    // If we have never created a Map for this channel, create one
    if (!this.channelSignalListeners.get(channelId)) {
      this.channelSignalListeners.set(channelId, new Map());
    }

    // Get the channel specific listeners for this channel
    const channelSpecificListeners = this.channelSignalListeners.get(channelId);

    // Automatically subscribe if the channel is not in use.
    if (!this.channelIsInUse(channelId)) {
      this.subscribeToChannel(channelId);
    }

    // If we already have set this function as a handler, overwrite it!
    // (By removing it, and then setting it again)
    if (channelSpecificListeners?.get(handler)) {
      this.unregisterSignalHandler(channelId, handler, false);
    }

    // Set the "generated" handler, keyed by the original handler.
    // (So we can remove it by the original method)
    channelSpecificListeners?.set(handler, listenerObj);
    this.client?.addListener(listenerObj);

    return () => {
      this.unregisterSignalHandler(channelId, handler);
    };
  };

  // =====================================================================
  // ============== CHANNEL SCOPED MESSAGE / MESSAGEACTIONS ==============
  // =====================================================================

  registerChannelListener = (
    channelId: string,
    listenerType: PubnubListenerType,
    handler: PubnubHandlerFn,
  ): ListenerRemovalFn => {
    if (!this.checkClientIsReady()) {
      return _.noop;
    }

    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    let channelSpecificHandler = (messageEvent: MessageEvent) => {};

    // Create a handler that checks if the channel is the one specified.
    // Also assign the correct handler based on the type of message.
    // Message actions are not encrypted.
    if (listenerType === "message") {
      channelSpecificHandler = (messageEvent: MessageEvent) => {
        if (messageEvent.channel === channelId) {
          handler({
            ...messageEvent,
            message: decryptMessage(messageEvent.message),
          });
        }
      };
    } else if (listenerType === "messageAction") {
      channelSpecificHandler = (messageEvent: MessageEvent) => {
        if (messageEvent.channel === channelId) {
          handler(messageEvent);
        }
      };
    }

    const listenerObj = { [listenerType]: channelSpecificHandler };

    // If we have never created a Map for this channel, create one
    if (!this.channelListeners.get(channelId)) {
      this.channelListeners.set(channelId, new Map());
    }

    // Get the channel specific listeners for this channel
    const channelSpecificListeners = this.channelListeners.get(channelId);

    if (!this.channelIsInUse(channelId)) {
      this.subscribeToChannel(channelId);
    }

    // If we already have set this function as a handler, overwrite it!
    // (By removing it, and then setting it again)
    if (channelSpecificListeners?.get(handler)) {
      this.unregisterListener(channelId, handler, false);
    }

    // Set the "generated" handler, keyed by the original handler.
    // (So we can remove it by the original method)
    channelSpecificListeners?.set(handler, listenerObj);
    this.client?.addListener(listenerObj);

    return () => {
      this.unregisterListener(channelId, handler);
    };
  };

  unregisterListener = (
    channelId: string,
    handler: PubnubHandlerFn,
    autoUnsubscribe = true,
  ): void => {
    if (!this.checkClientIsReady()) {
      return;
    }

    // Get the listener map for a given channelId
    const channelHandlers = this.channelListeners.get(channelId);

    // If we have something, try to find the handler in that map
    if (channelHandlers) {
      const listenerObj = channelHandlers.get(handler);

      // If we could find a handler in the map, remove it from the map,
      // and then remove it from the pubnub client
      if (listenerObj) {
        channelHandlers.delete(handler);
        this.client?.removeListener(listenerObj);

        // If we want to auto unsub from the channel, check if we can, then do it.
        if (autoUnsubscribe && !this.channelIsInUse(channelId)) {
          this.unsubscribeFromChannel(channelId);
        }
      }
    }
  };

  /**
   * Attaches a handler that runs ONLY if a message is received on the desired channel
   *
   * @returns A function that when called, will unregister the handler
   */
  registerMessageHandler = (channelId: string, handler: PubnubHandlerFn): ListenerRemovalFn => {
    return this.registerChannelListener(channelId, "message", handler);
  };

  registerMessageActionHandler = (
    channelId: string,
    handler: PubnubActionHandlerFn,
  ): ListenerRemovalFn => {
    return this.registerChannelListener(
      channelId,
      "messageAction",
      handler as unknown as PubnubHandlerFn, // Since we reuse this register method haha.
    );
  };

  /**
   * This is "the old way" of fetching messages, where we retrieve directly from Pubnub.
   * It's kept here behind a feature flag for debugging and performance testing.
   */
  fetchMessagesFromPubnubHistory = async (
    channelId: string,
    configuration: CampfireFetchMessagesParameters,
  ): Promise<PubnubFetchMessageMessage[]> => {
    // Remove CustomCampfireFetchMessagesParameters from the config going directly to Pubnub
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ...pubnubConfiguration } = configuration;

    // According to pubnub docs, async/await is supported. Casting type here since
    // there are multiple definitions of the typescript defs which conflict
    const response: FetchMessagesResponse = (await this.client?.fetchMessages({
      stringifiedTimeToken: true, // false is the default
      ...pubnubConfiguration,
    })) as FetchMessagesResponse;

    // If we hit an error, or there are no messages for the channel,
    // resolve with an empty array.
    if (!response.channels || !response.channels[channelId]) {
      return [];
    }

    // Messages are encrypted, so decrypt them
    const messagesForChannel = response.channels[channelId];
    const decryptedMessages: PubnubFetchMessageMessage[] = messagesForChannel.map((msg) => {
      return {
        ...msg,
        message: decryptMessage(msg.message),
      };
    });

    return decryptedMessages;
  };

  /**
   * This is "the new way" of fetching messages, where we instead request message
   * history from the server. This allows us to hydrate the messages with fresh
   * data, as opposed to rendering the possibly out-of-date data stored in Pubnub.
   */
  fetchMessagesFromCampfireHistory = async (
    channelId: string,
    configuration: CampfireFetchMessagesParameters,
  ): Promise<PubnubFetchMessageMessage[]> => {
    // According to pubnub docs, async/await is supported. Casting type here since
    // there are multiple definitions of the typescript defs which conflict

    const gqlResponse = (await fetchQuery(environment, MESSAGES_FROM_HISTORY_QUERY, {
      input: {
        ...configuration,
      },
    }).toPromise()) as messagesFromHistoryResponse;

    const response = gqlResponse.messagesFromHistory;

    // If we hit an error, or there are no messages for the channel,
    // resolve with an empty array.
    if (!response.channels || !response.channels[0]) {
      return [];
    }

    // Messages are encrypted, so decrypt them
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return response.channels.map((msg: any) => {
      let messageSender: CampfireChat.MessageSender = {
        ...msg.message.sender,
        name: msg.message.sender.displayName,
        avatar: msg.message.sender.avatarUrl,
      };

      // Prefer V2 with MessageSender typing if available
      if (msg.message.senderV2) {
        // V2's ID should be globally unique so we must use the `senderId` field instead which will match against a userId
        if (msg.message.senderV2.senderId) {
          messageSender = {
            id: msg.message.senderV2.senderId,
            name: msg.message.senderV2.name,
            avatar: msg.message.senderV2.avatar,
            color: msg.message.senderV2.color,
          };
        } else {
          messageSender = {
            ...msg.message.senderV2,
          };
        }
      }

      return {
        ...msg,
        message: {
          ...msg.message,
          sender: messageSender,
        },
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        meta: msg.meta as { [key: string]: any },
        actions: msg.actions as {
          [type: string]: { [value: string]: { uuid: string; actionTimetoken: string | number }[] };
        },
      };
    });
  };

  /**
   * Fetches messages using Pubnub's v3 history API.
   *
   * Note: Currently, includeMessageActions does not work the same as the other call. Setting,
   * this field triggers the pubnub library to use the v3 history-with-actions API, and the
   * query params are not treated the same as of v4.33.0.
   */
  fetchMessagesFromHistory = async (
    channelId: string,
    configuration: Partial<CampfireFetchMessagesParameters> = {},
  ): Promise<PubnubFetchMessageMessage[]> => {
    let numMessagesToFetch = FETCH_MESSAGES_DEFAULT_COUNT;

    // Warn developers that pubnub internal limits will get hit if they use
    // this configuration option.
    if (
      configuration.includeMessageActions &&
      configuration.count &&
      configuration.count > FETCH_MESSAGES_WITH_MESSAGE_ACTIONS_LIMIT
    ) {
      numMessagesToFetch = FETCH_MESSAGES_WITH_MESSAGE_ACTIONS_LIMIT;

      console.error(
        `Cannot fetch for ${configuration.count} messages with message actions! Pubnub max limit is ${FETCH_MESSAGES_WITH_MESSAGE_ACTIONS_LIMIT}`,
      );
    }

    const input = {
      channels: [channelId],
      count: numMessagesToFetch, // how many items to fetch
      ...configuration,
      start: configuration.start?.toString(),
      end: configuration.end?.toString(),
    };

    // Fetch for messages for this channel.
    try {
      return await this.fetchMessagesFromCampfireHistory(channelId, input);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  /**
   * Fetches the most recent messages from history.
   */
  fetchMostRecentMessagesFromHistory = async (
    channelId: string,
    count: number = FETCH_MESSAGES_DEFAULT_COUNT,
    configuration: Partial<CampfireFetchMessagesParameters> = {},
  ): Promise<PubnubFetchMessageMessage[]> => {
    // Fetch for at most 100 messages for this channel.
    // The API cannot support more than that.
    const numMessagesToFetch = Math.min(count, FETCH_MESSAGES_DEFAULT_COUNT);
    const messages = await this.fetchMessagesFromHistory(channelId, {
      ...configuration,
      count: numMessagesToFetch,
    });

    return messages;
  };

  /**
   * Returns the message counts on a set of channels from a desired starting timetoken to
   * the current time. Useful when checking unread message counts.
   */
  fetchMessageCounts = async (
    channelConfigs: MessageCountChannelConfig[],
  ): Promise<MessageCountsResponse> => {
    return new Promise((resolve) => {
      const payload = {
        channels: channelConfigs.map((chCfg) => chCfg.channel),
        channelTimetokens: channelConfigs.map((chCfg) => {
          return chCfg.startTimetoken || getTimestampAsTimetoken();
        }),
      };

      this.client?.messageCounts(payload, (status, response) => {
        // If we hit an error, resolve with an empty array.
        if (status.error || !response.channels) {
          resolve({ channels: {} });
          return;
        }

        resolve(response);
      });
    });
  };

  /**
   * Fetches the most recent message actions from history.
   */
  fetchMostRecentMessageActions = async (
    channelId: string,
    endTimetoken?: string,
  ): Promise<PubnubMessageAction[]> => {
    const messageActionParams: GetMessageActionsParameters = {
      channel: channelId,
      start: getTimestampAsTimetoken(),
      ...(endTimetoken && { end: endTimetoken }),
      limit: FETCH_MESSAGE_ACTIONS_DEFAULT_COUNT,
    };

    try {
      const response = await this.client?.getMessageActions(messageActionParams);

      if (!response || !response.data) {
        return [];
      }

      return response.data;
    } catch (error) {
      return [];
    }
  };
}
