import { invokeRemoteAsync } from "common/utils/webInterop/plugins";

import defaultRequestCurrentRegion, {
  validator as requestCurrentRegionValidator,
} from "./requestCurrentRegion";

export default {
  requestCurrentRegion: invokeRemoteAsync<typeof defaultRequestCurrentRegion, "RegionalChat">(
    defaultRequestCurrentRegion,
    requestCurrentRegionValidator,
    "RegionalChat",
    "requestCurrentRegion",
  ),
};
