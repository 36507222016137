import React from "react";

import styles from "./styles.scss";

const DEFAULT_Z_INDEX = 50;
const HIGHLIGHT_THICKNESS_PX = 4;

export type Props = {
  left: number;
  right: number;
  top: number;
  bottom: number;
  borderRadius: number;
  zIndex?: number;
  highlight?: boolean;
};

type Point = {
  x: number;
  y: number;
};

// Our "spotlight" rendering makes use of a CSS clip-path polygon to create a "cut-out"
// from a full-screen overlay. The overlay is a dark color, and the "cut-out" area is the
// UI element that's under a "spotlight."

// However, most of our UI elements have rounded corners, and a clip-path polygon can only
// draw straight lines. What we do in the algorithm below is simulate rounded corners by
// subdividing a right-angle corner into many segments. Matching the number of segments
// to the pixel value of an elements border-radius ends up looking pretty good.

const subdivideCorner = (
  centerX: number,
  centerY: number,
  radius: number,
  startAngle: number,
  endAngle: number,
  segments: number,
) => {
  const points = [];
  const angleStep = (endAngle - startAngle) / segments;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= segments; i++) {
    const angle = startAngle + angleStep * i;
    const x = centerX + radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);

    points.push({ x, y });
  }

  points.reverse();
  return points;
};

const createClipPath = (
  tl: Point,
  tr: Point,
  bl: Point,
  br: Point,
  radius: number,
  segments: number,
): string => {
  let clipPath = "polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0";

  const topLeft = subdivideCorner(
    tl.x + radius,
    tl.y + radius,
    radius,
    Math.PI,
    1.5 * Math.PI,
    segments,
  );
  const bottomLeft = subdivideCorner(
    bl.x + radius,
    bl.y - radius,
    radius,
    0.5 * Math.PI,
    Math.PI,
    segments,
  );
  const bottomRight = subdivideCorner(
    br.x - radius,
    br.y - radius,
    radius,
    0,
    0.5 * Math.PI,
    segments,
  );
  const topRight = subdivideCorner(
    tr.x - radius,
    tr.y + radius,
    radius,
    1.5 * Math.PI,
    2 * Math.PI,
    segments,
  );
  const allPoints = [...topLeft, ...bottomLeft, ...bottomRight, ...topRight];

  // eslint-disable-next-line no-restricted-syntax
  for (const { x, y } of allPoints) {
    clipPath += `, ${x}px ${y}px`;
  }

  clipPath += `, ${allPoints[0].x}px ${allPoints[0].y}px)`;
  return clipPath;
};

const OnboardCoachmarkSpotlight: React.FunctionComponent<Props> = (props: Props) => {
  const hl = props.highlight ? HIGHLIGHT_THICKNESS_PX : 0;
  const zIndex = props.zIndex ?? DEFAULT_Z_INDEX;

  const rect = {
    tl: { x: props.left - hl, y: props.top - hl },
    tr: { x: props.right + hl, y: props.top - hl },
    bl: { x: props.left - hl, y: props.bottom + hl },
    br: { x: props.right + hl, y: props.bottom + hl },
  };

  const { tr, tl, br, bl } = rect;

  // If a highlight is required around the radius of the spotlight, we need to increase the
  // borderRadius of the "cut-out" from the clip-path by the corresponding number of pixels.
  // Our strategy for rounding borders is to subdivide the corners of the the clip-path so that
  // # of segments == px of borderRadius. So we add "hl", the highlight radius in pixels,
  // to the borderRadius for our clip-path calculation.
  const clipPath = createClipPath(tl, tr, bl, br, props.borderRadius + hl, props.borderRadius + hl);

  return (
    <>
      <div
        style={{
          zIndex,
          position: "absolute",
          top: props.top - hl,
          left: props.left - hl,
          width: props.right - props.left,
          height: props.bottom - props.top,
          border: `solid ${hl}px #16bcf0`,
          borderRadius: props.borderRadius + hl,
        }}
      />
      <div className={styles.root} style={{ zIndex, clipPath, WebkitClipPath: clipPath }} />
    </>
  );
};

export default OnboardCoachmarkSpotlight;
