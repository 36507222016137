import { IonItem } from "@ionic/react";
import classnames from "classnames";
import _ from "lodash";
import React from "react";

import UIAvatar from "common/components/Avatar/UIAvatar";
import UIAvatarConnected from "common/components/Avatar/UIAvatarConnected";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

const DEFAULT_SIZE = 64;

/**
 * NOTE: When using this component or any associated component, try to follow the pattern:
 * 1. Create a type that extends UserLikeObject
 *    type MemberLikeObject = UserLikeObject & { anotherField: boolean; };
 * 2. Use this in all your types around your component and in the methods passed to the UIUserList or
 *    associated components.
 */
export type UserLikeObject = {
  id: string;
  avatarUrl: string;
  name: string;
  details?: string;
};

export type Props<T extends UserLikeObject> = {
  users: T[];
  renderEndAdornment?: (user: T) => React.ReactNode;
  renderUserListItem?: (user: T) => React.ReactNode;
  renderUserListName?: (user: T) => React.ReactNode;
  renderAvatarAdornment?: (user: T) => React.ReactNode;
  userClassName?: string;
  avatarStyle?: "circle" | "square";
  avatarSize?: number;
  /**
   * Renders a connected UIAvatar that allows user to tap on it to view profile
   */
  useActiveAvatar?: boolean;
  onClickUser?: (user: T) => void;
  userListItemClassName?: string;
};

type State = {};

export default class UIUserList<T extends UserLikeObject> extends React.Component<Props<T>, State> {
  getAvatarElement = (user: T): React.ReactElement => {
    const avatarSize = this.props.avatarSize || DEFAULT_SIZE;

    if (this.props.useActiveAvatar && user.id) {
      return (
        <>
          <UIAvatarConnected
            userId={user.id}
            avatarUrl={user.avatarUrl}
            avatarStyle={this.props.avatarStyle}
            name={user.name}
            size={avatarSize}
          />
          {this.props.renderAvatarAdornment ? this.props.renderAvatarAdornment(user) : null}
        </>
      );
    }

    return (
      <>
        <UIAvatar
          allowRandomBgColor
          avatarUrl={user.avatarUrl}
          avatarStyle={this.props.avatarStyle}
          size={avatarSize}
          name={user.name}
        />
        {this.props.renderAvatarAdornment ? this.props.renderAvatarAdornment(user) : null}
      </>
    );
  };

  renderUser = (user: T): React.ReactNode => {
    const onClickUser = this.props.onClickUser ? this.props.onClickUser : _.noop;

    return (
      <IonItem
        className={classnames(styles.ionItem, this.props.userListItemClassName)}
        key={`user-${user.id}`}
        button={!!this.props.onClickUser}
        detail={false}
        onClick={() => onClickUser(user)}
        lines="none"
        data-test-name={user.name}
      >
        {this.props.renderEndAdornment && (
          <div slot="end" className={styles.endSlot}>
            {this.props.renderEndAdornment(user)}
          </div>
        )}
        <div className={classnames(styles.userListItem, this.props.userClassName)}>
          {this.props.renderUserListItem ? (
            this.props.renderUserListItem(user)
          ) : (
            <>
              {this.getAvatarElement(user)}

              <UISpacer w={14} />

              <div className={styles.userInfo}>
                {this.props.renderUserListName ? (
                  this.props.renderUserListName(user)
                ) : (
                  <UIText color="dark" variant="body1" weight="bold">
                    {user.name}
                  </UIText>
                )}

                {user.details && (
                  <>
                    <UISpacer h={4} />

                    <UIText color="medium" variant="h5" weight="medium">
                      {user.details}
                    </UIText>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </IonItem>
    );
  };

  render = (): React.ReactNode => {
    return <div className={styles.itemParent}>{this.props.users.map(this.renderUser)}</div>;
  };
}
