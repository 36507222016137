/**
 * @generated SignedSource<<375dd9cc7c493aaade0642373c190c3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CancelFriendInviteInput = {
  recipientId: string;
};
export type CancelFriendInviteMutation$variables = {
  input: CancelFriendInviteInput;
};
export type CancelFriendInviteMutation$data = {
  readonly cancelFriendInvite: {
    readonly success: boolean;
    readonly user: {
      readonly hasPendingFriendInviteFromMe: boolean;
      readonly isMyFriend: boolean;
    };
  };
};
export type CancelFriendInviteMutation = {
  response: CancelFriendInviteMutation$data;
  variables: CancelFriendInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMyFriend",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPendingFriendInviteFromMe",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelFriendInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelFriendInviteResponse",
        "kind": "LinkedField",
        "name": "cancelFriendInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelFriendInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelFriendInviteResponse",
        "kind": "LinkedField",
        "name": "cancelFriendInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b92d2af9b5193c449279b1ad11eb642",
    "id": null,
    "metadata": {},
    "name": "CancelFriendInviteMutation",
    "operationKind": "mutation",
    "text": "mutation CancelFriendInviteMutation(\n  $input: CancelFriendInviteInput!\n) {\n  cancelFriendInvite(input: $input) {\n    user {\n      isMyFriend\n      hasPendingFriendInviteFromMe\n      id\n    }\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "aeb0e67b9ba12d558ba7dcd0dc7cab05";

export default node;
