import { RouteComponentProps } from "react-router-dom";

import { getClubFeedRouteForClub } from "common/utils/routing/routeToClubFeed";
import { getQueryString } from "common/utils/url";
import { QUERY_PARAMS } from "constants/routes";

export const getUrlForEventInClubFeed = (clubId: string, eventId: string): string => {
  const eventIdKey = QUERY_PARAMS.clubs.clubId.feed.eventId.key;
  const queryParamUpdates = { [eventIdKey]: eventId };
  const pathname = getClubFeedRouteForClub(clubId);
  const search = getQueryString(queryParamUpdates, "");

  // leading "/" is added from getClubFeedRouteForClub FYI
  return `${pathname}?${search}`;
};

export default (
  routeComponentProps: RouteComponentProps,
  clubId: string,
  eventId: string,
  replace?: boolean,
  preserveSearch?: boolean,
): void => {
  const eventIdKey = QUERY_PARAMS.clubs.clubId.feed.eventId.key;
  const queryParamUpdates = { [eventIdKey]: eventId };
  const searchStr = preserveSearch ? routeComponentProps.location.search : "";
  const routeConfig = {
    pathname: getClubFeedRouteForClub(clubId),
    search: getQueryString(queryParamUpdates, searchStr),
  };

  if (replace) {
    routeComponentProps.history.replace(routeConfig);
  } else {
    routeComponentProps.history.push(routeConfig);
  }
};
