import { BigNumber } from "bignumber.js";
import _ from "lodash";
import React from "react";

import ActivityStore from "common/stores/ActivityStore";

export type ChannelMessageState = {
  // Client saved value of the messages heard for this channel.
  // Useful for showing counts without pulling from API.
  messagesHeardCount: number | undefined;

  // The timetoken of the latest message this client has HEARD from a channel.
  newestMessage: string | undefined;

  // The timetoken of the latest message this client has SEEN.
  // Updated typically on message receipt.
  lastMessageSeen: string | undefined;
};

type ChannelStatus = Record<string, ChannelMessageState>;

export type ExposedProps = {
  channelIsVisiblyActive: (channelId: string) => boolean;
  increaseChannelActivityCounter: (channelId: string) => void;
  reduceChannelActivityCounter: (channelId: string) => void;
  updateChannelLastSeen: (channelId: string, timetoken: string) => void;
  updateChannelNewestMessage: (channelId: string, timetoken: string) => void;
  updateChannelStatus: (channelId: string, channelState: Partial<ChannelMessageState>) => void;
  updateChannelStatusBatched: (updates: { [key: string]: Partial<ChannelMessageState> }) => void;
  channelHasUnseen: (channelId: string) => boolean;
  getChannelUnseenActivityCount: (channelId: string) => number;
  getChannelStatus: (channelId: string) => ChannelMessageState | undefined;
};

type Props = {
  userId: string;
  children: React.ReactNode;
};

type State = {
  channelStatus: ChannelStatus;
};

const INITIAL_CONTEXT: ExposedProps = {
  channelIsVisiblyActive: () => false,
  increaseChannelActivityCounter: _.noop,
  reduceChannelActivityCounter: _.noop,
  updateChannelLastSeen: _.noop,
  updateChannelNewestMessage: _.noop,
  updateChannelStatus: _.noop,
  updateChannelStatusBatched: _.noop,
  channelHasUnseen: () => false,
  getChannelUnseenActivityCount: () => 0,
  getChannelStatus: () => undefined,
};

const ActivityContext = React.createContext(INITIAL_CONTEXT);

const CACHE_UPDATE_DELAY = 1000;

/**
 * Manages channel activity and sync's that state to local storage.
 */
export class ActivityProvider extends React.Component<Props, State> {
  visiblyActiveChannels: Record<string, number> = {};

  activityStore: ActivityStore;

  constructor(props: Props) {
    super(props);

    this.activityStore = new ActivityStore(props.userId);

    this.state = {
      channelStatus: this.activityStore.get("pubNubChannelStatus"),
    };
  }

  updateCachedChannelStatus = (channelStatus: ChannelStatus): void => {
    this.activityStore.set("pubNubChannelStatus", channelStatus);
  };

  // eslint-disable-next-line react/sort-comp
  updateCachedChannelStatusThrottled = _.throttle(
    this.updateCachedChannelStatus,
    CACHE_UPDATE_DELAY,
    { leading: true, trailing: true },
  );

  /**
   * Determines if a channel has unread messages, based on our client state based approach.
   *
   * A channel has an unread message when the timestamp of the last message the user has seen
   * is less than the timestamp of the newest message the user has been notified of.
   */
  channelHasUnseen = (channelId: string): boolean => {
    const channelStatus = this.state.channelStatus[channelId];
    const newestMessageTimestamp = _.get(channelStatus, "newestMessage", "0");
    const lastMessageSeenTimestamp = _.get(channelStatus, "lastMessageSeen", "0");

    const newMessageNum = new BigNumber(newestMessageTimestamp);
    const lastMessageNum = new BigNumber(lastMessageSeenTimestamp);

    return newMessageNum.gt(lastMessageNum);
  };

  getChannelUnseenActivityCount = (channelId: string): number => {
    const channelStatus = this.state.channelStatus[channelId];

    return _.get(channelStatus, "messagesHeardCount", 0);
  };

  /**
   * Updates a channel status entirely.
   */
  updateChannelStatus = (channelId: string, channelState: Partial<ChannelMessageState>): void => {
    const { channelStatus } = this.state;

    const clonedStatus = _.cloneDeep(channelStatus);

    _.merge(clonedStatus, {
      [channelId]: { ...clonedStatus[channelId], ...channelState },
    });

    this.setState({ channelStatus: clonedStatus });
    this.updateCachedChannelStatus(clonedStatus);
  };

  updateChannelStatusBatched = (updates: { [key: string]: Partial<ChannelMessageState> }): void => {
    const { channelStatus } = this.state;

    const clonedStatus = _.cloneDeep(channelStatus);
    const channelIds = Object.keys(updates);

    channelIds.forEach((channelId) => {
      _.merge(clonedStatus, {
        [channelId]: { ...clonedStatus[channelId], ...updates[channelId] },
      });
    });

    this.setState({ channelStatus: clonedStatus });
    this.updateCachedChannelStatus(clonedStatus);
  };

  /**
   * Used to update the time the client has actually pulled/seen a channel
   */
  updateChannelLastSeen = (channelId: string, timetoken: string): void => {
    const { channelStatus } = this.state;

    const channelMessageState = channelStatus[channelId];
    const currentValue = _.get(channelMessageState, "lastMessageSeen", "0");
    const timeTokenBigNum = new BigNumber(timetoken);
    const currentTokenBigNum = new BigNumber(currentValue);

    const isMoreRecent = timeTokenBigNum.gt(currentTokenBigNum);

    if (isMoreRecent) {
      const clonedStatus = _.cloneDeep(channelStatus);

      // Update the status by mutating ONLY the lastMessageSeen
      // Reset the messages messagesHeardCount count back to 0.
      _.merge(clonedStatus, {
        [channelId]: {
          lastMessageSeen: timetoken,
          messagesHeardCount: 0,
        },
      });

      this.setState({ channelStatus: clonedStatus });
      this.updateCachedChannelStatus(clonedStatus);
    }
  };

  /**
   * Used to update the time the client hears updates from a channel
   */
  updateChannelNewestMessage = (channelId: string, timetoken: string): void => {
    const { channelStatus } = this.state;

    const channelMessageState = channelStatus[channelId];
    const currentValue = _.get(channelMessageState, "newestMessage", "0");
    const timeTokenBigNum = new BigNumber(timetoken);
    const currentTokenBigNum = new BigNumber(currentValue);
    const isMoreRecent = timeTokenBigNum.gt(currentTokenBigNum);

    if (isMoreRecent) {
      const clonedStatus = _.cloneDeep(channelStatus);
      const clonedChannelStatus = clonedStatus[channelId];

      // Update the status by mutating ONLY the newestMessage
      // Increment the messagesHeardCount count by 1.
      _.merge(clonedStatus, {
        [channelId]: {
          newestMessage: timetoken,
          messagesHeardCount: _.get(clonedChannelStatus, "messagesHeardCount", 0) + 1,
        },
      });

      this.setState({ channelStatus: clonedStatus });
      this.updateCachedChannelStatus(clonedStatus);
    }
  };

  /**
   * Increases the number of times the UI thinks a channel is active.
   *
   * In case we open the same chat per se multiple times.
   */
  increaseChannelActivityCounter = (channelId: string): void => {
    if (!this.visiblyActiveChannels[channelId]) {
      this.visiblyActiveChannels[channelId] = 0;
    }

    this.visiblyActiveChannels[channelId] += 1;
  };

  /**
   * Reduces the number of times the UI thinks a channel is active.
   */
  reduceChannelActivityCounter = (channelId: string): void => {
    if (this.visiblyActiveChannels[channelId]) {
      this.visiblyActiveChannels[channelId] -= 1;
    }
  };

  /**
   * Determines if the UI has marked a channelId as active or as something it should care about.
   */
  channelIsVisiblyActive = (channelId: string): boolean => {
    return !!this.visiblyActiveChannels[channelId];
  };

  getChannelStatus = (channelId: string): ChannelMessageState | undefined => {
    const { channelStatus } = this.state;

    if (channelStatus[channelId]) {
      return channelStatus[channelId];
    }

    return undefined;
  };

  render = (): React.ReactNode => {
    return (
      <ActivityContext.Provider
        value={{
          channelIsVisiblyActive: this.channelIsVisiblyActive,
          increaseChannelActivityCounter: this.increaseChannelActivityCounter,
          reduceChannelActivityCounter: this.reduceChannelActivityCounter,
          updateChannelStatus: this.updateChannelStatus,
          updateChannelStatusBatched: this.updateChannelStatusBatched,
          updateChannelLastSeen: this.updateChannelLastSeen,
          updateChannelNewestMessage: this.updateChannelNewestMessage,
          channelHasUnseen: this.channelHasUnseen,
          getChannelUnseenActivityCount: this.getChannelUnseenActivityCount,
          getChannelStatus: this.getChannelStatus,
        }}
      >
        {this.props.children}
      </ActivityContext.Provider>
    );
  };
}

export const ActivityConsumer = ActivityContext.Consumer;
