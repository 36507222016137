import { IonItem } from "@ionic/react";
import classnames from "classnames";
import { chevronForward } from "ionicons/icons";
import React from "react";

import { IMenuItemProps } from "common/components/MenuGroup";
import menuItemStyles from "common/components/MenuGroup/components/menuItemStyles.scss";
import UIIcon from "common/components/UIIcon";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = IMenuItemProps;

type State = {};

export default class MenuItem extends React.Component<Props, State> {
  renderEndAdornment = (): React.ReactNode => {
    if (this.props.renderEndAdornment) {
      return this.props.renderEndAdornment();
    }

    if (this.props.hideEndAdornment) {
      return null;
    }

    return (
      <UIIcon className={styles.endArrow} size={16} color="dark" icon={chevronForward} slot="end" />
    );
  };

  renderDefaultMenuItem = (): React.ReactNode => {
    const { name, description, color } = this.props;

    return (
      <div className={styles.menuItemText}>
        <div>
          <UIText color={color} weight="bold">
            {name}
          </UIText>
        </div>

        {description && (
          <div>
            <UIText color="medium">{description}</UIText>
          </div>
        )}
      </div>
    );
  };

  render = (): React.ReactNode => {
    const { children, disabled, variant, onClick, dataTestId, className } = this.props;

    // If children is supplied, a custom menu item can be presented.
    const shouldRenderChildren = Boolean(children);

    return (
      <IonItem
        className={classnames(styles.root, menuItemStyles.menuItem, className, {
          [styles.disabled]: disabled,
          [styles.connected]: variant === "connected",
          [styles.disconnected]: variant === "disconnected",
          [menuItemStyles.connected]: variant === "connected",
          [menuItemStyles.disconnected]: variant === "disconnected",
        })}
        onClick={onClick}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        dataTestId={dataTestId}
      >
        {!shouldRenderChildren && this.renderDefaultMenuItem()}
        {shouldRenderChildren && children}
        <div slot="end">{this.renderEndAdornment()}</div>
      </IonItem>
    );
  };
}
