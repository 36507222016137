/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { FeatureFlagConsumer, ExposedProps } from "providers/FeatureFlagProvider";

export type WithFeatureFlagProps = {
  hasFeatureFlag: ExposedProps["hasFeatureFlag"];
};

// eslint-disable-next-line max-len
export default <P extends WithFeatureFlagProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithFeatureFlagProps>): React.ReactElement => (
    <FeatureFlagConsumer>
      {({ hasFeatureFlag }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component hasFeatureFlag={hasFeatureFlag} {...props} />
      )}
    </FeatureFlagConsumer>
  );
};
