import { InputChangeEventDetail, JSX } from "@ionic/core";
import { IonTextarea, IonLabel } from "@ionic/react";
import classnames from "classnames";
import React, { HTMLAttributes } from "react";

import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = HTMLAttributes<HTMLIonTextareaElement> &
  JSX.IonTextarea & {
    value?: string;
    label?: string;
    labelColor?: IonicThemeShade;
    className?: string;
    onIonChange?: (event: CustomEvent<InputChangeEventDetail>) => void;
    readonly?: boolean;
    textareaRef?: React.RefObject<HTMLIonTextareaElement>;
    dataTestId?: string;
  };

type State = {};

export default class UITextArea extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const {
      className,
      label,
      labelColor = "medium",
      textareaRef,
      dataTestId,
      ...remainingProps
    } = this.props;

    return (
      <>
        {label && (
          <IonLabel position="stacked" className={styles.label}>
            <UIText color={labelColor} weight="bold">
              {label}
            </UIText>
          </IonLabel>
        )}
        <div className={classnames(styles.root, className)}>
          <IonTextarea
            ref={textareaRef}
            className={styles.input}
            color="dark"
            data-test-id={dataTestId}
            {...remainingProps}
          />
        </div>
      </>
    );
  };
}
