import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import UIAsyncButton from "common/components/UIAsyncButton";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  onMount?: () => void;
  errorMessage: string;
  reload: () => void | Promise<void>;
};

type State = {};

class PageLoadError extends React.Component<Props, State> {
  componentDidMount = (): void => {
    if (this.props.onMount) {
      this.props.onMount();
    }
  };

  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <UIText variant="h3" color="dark" weight="semiBold">
          {this.props.errorMessage}
        </UIText>

        <UISpacer h={24} />

        <UIAsyncButton color="secondary" onClick={this.props.reload}>
          <UIText variant="body1" color="white" weight="medium">
            {this.props.t("RELOAD")}
          </UIText>
        </UIAsyncButton>
      </div>
    );
  };
}

export default withTranslation()(PageLoadError);
