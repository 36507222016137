import { BundleInfo, CapacitorUpdater } from "@capgo/capacitor-updater";

const VALID_APP_HOSTNAMES_CONTAINS = ["nianticlabs.com"];

/**
 * A little protection around downloading assets from urls we do not recognize.
 * Normally, the CSP prevents the browser from downloading from unknown urls, however,
 * in this case, Capacitor is the one downloading it, so it's the device.
 * As a result, let's only allow urls that we trust.
 */
const isRecognizedDownloadUrl = (url: string): boolean => {
  return VALID_APP_HOSTNAMES_CONTAINS.some((hostname: string) => {
    const urlObj = new URL(url);

    return urlObj.hostname.endsWith(hostname);
  });
};

/**
 * Downloads a capgo js bundle from an url and saves it.
 */
// eslint-disable-next-line import/prefer-default-export
export const downloadJSBundle = async (
  version: string,
  url: string,
): Promise<BundleInfo | void> => {
  if (!isRecognizedDownloadUrl(url)) {
    return undefined;
  }

  try {
    const bundleInfo = await CapacitorUpdater.download({
      url,
      version,
    });

    return bundleInfo;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error setting next jsbundle to use", error);
  }

  return undefined;
};
