import { Filesystem, ReadFileResult } from "@capacitor/filesystem";

import logException from "common/analytics/exceptions";

// https://capacitorjs.com/docs/apis/filesystem#geturi
export const validator = (readFileResult: ReadFileResult): boolean => {
  try {
    const base64EncodedDataString: string | undefined = readFileResult
      ? readFileResult.data
      : undefined;
    const dataIsString = base64EncodedDataString
      ? typeof base64EncodedDataString === "string"
      : false;

    return dataIsString;
  } catch (error) {
    logException("validating Filesystem.readFile data from embed", "validator", "readFile", error);
    return false;
  }
};

export default Filesystem.readFile;
