import _ from "lodash";
import React from "react";

import withFeatureFlag, {
  WithFeatureFlagProps,
} from "providers/FeatureFlagProvider/withFeatureFlag";

export type ExposedProps = {
  // A flag denoting that something is disabling coachmarks from being shown.
  hasCoachmarkBlockingIntent: boolean;
  setHasCoachmarkBlockingIntent: (hasCoachmarkBlockingIntent: boolean) => void;
};

type Props = WithFeatureFlagProps & {
  children: React.ReactNode;
};

type State = {
  hasCoachmarkBlockingIntent: boolean;
};

const INITIAL_CONTEXT: ExposedProps = {
  hasCoachmarkBlockingIntent: false,
  setHasCoachmarkBlockingIntent: _.noop,
};

const AppStateContext = React.createContext(INITIAL_CONTEXT);

class AppStateProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasCoachmarkBlockingIntent: false,
    };
  }

  setHasCoachmarkBlockingIntent = (hasCoachmarkBlockingIntent: boolean): void => {
    this.setState({ hasCoachmarkBlockingIntent });
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <AppStateContext.Provider
        value={{
          hasCoachmarkBlockingIntent: this.state.hasCoachmarkBlockingIntent,
          setHasCoachmarkBlockingIntent: this.setHasCoachmarkBlockingIntent,
        }}
      >
        {children}
      </AppStateContext.Provider>
    );
  };
}

const FeatureFlagConnected = withFeatureFlag(AppStateProvider);

export { FeatureFlagConnected as AppStateProvider };
export const AppStateConsumer = AppStateContext.Consumer;
