import environment, { clearCache } from "common/relay/relay-env";
import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";

import EmbeddedPageWithTopBarWrapper from "common/components/EmbeddedPageWithTopBarWrapper";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import PageLoadError from "common/components/PageLoadError";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import { Messages_page_Query$data as MessagesPageQueryResponse } from "__generated__/Messages_page_Query.graphql";

// Lazy Load Main View
const MessagesPageMainContent = React.lazy(
  () => import("pages/Messages/components/MessagesPageMainContent"),
);

type Props = WithTranslation & {};

type State = {
  initializedAt: number;
};

type QRProps = {
  me: MessagesPageQueryResponse["me"];
};

const MESSAGES_PAGE = graphql`
  query Messages_page_Query {
    me {
      ...MessagesPageMainContent_me
    }
  }
`;

class MessagesPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedAt: Date.now(),
    };
  }

  reInit = () => {
    clearCache();
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void) => {
    this.reInit();
    clearError();
  };

  render = (): React.ReactNode => (
    <UIErrorBoundary onRetry={this.onRetry}>
      <Suspense fallback={<LoadingPageDefault />}>
        <EmbeddedPageWithTopBarWrapper>
          <QueryRenderer
            key={this.state.initializedAt}
            environment={environment}
            query={MESSAGES_PAGE}
            variables={{}}
            render={({ props, error }) => {
              const qrProps = props as QRProps;
              const isError = !!error;

              if (isError) {
                return (
                  <PageLoadError
                    errorMessage={this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
                    reload={this.reInit}
                  />
                );
              }

              if (!qrProps) {
                return <LoadingPageDefault />;
              }

              return <MessagesPageMainContent me={qrProps.me} />;
            }}
          />
        </EmbeddedPageWithTopBarWrapper>
      </Suspense>
    </UIErrorBoundary>
  );
}

export default withTranslation()(MessagesPage);
