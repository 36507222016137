import environment from "common/relay/relay-env";
import React from "react";
import { graphql, QueryRenderer } from "react-relay";

import ReportContext from "constants/reportContext";

import UIErrorBoundary from "common/components/UIErrorBoundary";
import { UserProfileOptions } from "common/components/UserProfile";
import UserProfileFragment from "common/components/UserProfileFragment";
import UserProfileLoadingView from "common/components/UserProfileLoadingView";

import { UserProfileFetcher_Query$data as UserProfileFetcherQueryResponse } from "__generated__/UserProfileFetcher_Query.graphql";

type Props = {
  userId: string;
  options?: UserProfileOptions;
  reportContext: ReportContext;
  close: () => void;
  disableDms?: boolean;
  disableInviteToClubs?: boolean;
};

type State = {};

type QRProps = {
  query: UserProfileFetcherQueryResponse;
  me: UserProfileFetcherQueryResponse["me"];
  userById: UserProfileFetcherQueryResponse["userById"];
};

const USER_PROFILE_QUERY = graphql`
  query UserProfileFetcher_Query($userId: ID!) {
    ...UserProfileFragment_query
    me {
      ...UserProfileFragment_me
    }
    userById(id: $userId) {
      ...UserProfileFragment_user
    }
  }
`;

export default class UserProfileFetcher extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    // If there is no userId, don't even bother to fetch.
    if (!this.props.userId) {
      return null;
    }

    return (
      <UIErrorBoundary>
        <QueryRenderer
          environment={environment}
          query={USER_PROFILE_QUERY}
          variables={{
            userId: this.props.userId,
          }}
          render={({ props, error }) => {
            const qrProps = props as QRProps;
            const isError = !!error;
            const isLoading = !isError && !qrProps;

            if (isLoading || isError) {
              return <UserProfileLoadingView isError={isError} close={this.props.close} />;
            }

            return (
              <UserProfileFragment
                query={qrProps as unknown as UserProfileFetcherQueryResponse}
                me={qrProps.me}
                user={qrProps.userById}
                close={this.props.close}
                options={this.props.options}
                disableDms={this.props.disableDms}
                disableInviteToClubs={this.props.disableInviteToClubs}
                reportContext={this.props.reportContext}
              />
            );
          }}
        />
      </UIErrorBoundary>
    );
  };
}
