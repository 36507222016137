import React from "react";

import {
  CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_VALUE,
  CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_NAME,
} from "constants/appRouterV2";

import ReparentingDiv from "common/components/AppRouterV2CoreNavigationFabs/components/ReparentingDiv";

type Props = {
  children: React.ReactNode;
};

class CoreNavigationFloatingTabBarEntrance extends React.Component<Props> {
  findPossibleParents = (): HTMLElement[] => {
    const portalElems = document.querySelectorAll(
      `div[${CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_NAME}="${CORE_NAVIGATION_MAIN_FABS_EXIT_DATA_ATTRIBUTE_VALUE}"]`,
    );

    return Array.prototype.slice.call(portalElems);
  };

  render = (): React.ReactNode => {
    // Portal the content desired into our container element that will be moved around the DOM.
    return (
      <ReparentingDiv findPossibleParents={this.findPossibleParents}>
        {this.props.children}
      </ReparentingDiv>
    );
  };
}

export default CoreNavigationFloatingTabBarEntrance;
