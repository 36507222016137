/**
 * @generated SignedSource<<eea2d0b9a579f995334d2056c89c48bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Clubs_page_Query$variables = {};
export type Clubs_page_Query$data = {
  readonly me: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ClubsPageMainContent_me" | "ClubsPageStateProvider_me">;
  };
};
export type Clubs_page_Query = {
  response: Clubs_page_Query$data;
  variables: Clubs_page_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Clubs_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClubsPageMainContent_me"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClubsPageStateProvider_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Clubs_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClubConnection",
            "kind": "LinkedField",
            "name": "memberOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClubEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Club",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatarUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "feedLastUpdatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChannelConnection",
                        "kind": "LinkedField",
                        "name": "channels",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ChannelEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Channel",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "pubnubMessageChannelName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "pubnubSignalChannelName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unreadMentionsCount",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserTextChannelSetting",
                                    "kind": "LinkedField",
                                    "name": "userSettings",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "notificationSetting",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "isMuted",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67315ca0585b260d3d612b093b419179",
    "id": null,
    "metadata": {},
    "name": "Clubs_page_Query",
    "operationKind": "query",
    "text": "query Clubs_page_Query {\n  me {\n    id\n    ...ClubsPageMainContent_me\n    ...ClubsPageStateProvider_me\n  }\n}\n\nfragment ClubListForLayoutV2_me on User {\n  isSuperAdmin\n  memberOf {\n    edges {\n      node {\n        id\n        name\n        avatarUrl\n        feedLastUpdatedAt\n        channels {\n          edges {\n            node {\n              pubnubMessageChannelName\n              pubnubSignalChannelName\n              unreadMentionsCount\n              userSettings {\n                notificationSetting\n                isMuted\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ClubList_me on User {\n  isSuperAdmin\n  memberOf {\n    edges {\n      node {\n        id\n        name\n        avatarUrl\n        feedLastUpdatedAt\n        channels {\n          edges {\n            node {\n              pubnubMessageChannelName\n              pubnubSignalChannelName\n              unreadMentionsCount\n              userSettings {\n                notificationSetting\n                isMuted\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ClubPageEmptyState_me on User {\n  isSuperAdmin\n}\n\nfragment ClubsPageMainContent_me on User {\n  id\n  ...ClubList_me\n  ...ClubListForLayoutV2_me\n  ...ClubPageEmptyState_me\n}\n\nfragment ClubsPageStateProvider_me on User {\n  id\n  isSuperAdmin\n  memberOf {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5308bef5678e2cc35983a8a8315537b4";

export default node;
