/**
 * @generated SignedSource<<5cdf1b0ce3b6ef52897ee4f8f37a4776>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExperienceMagellan_page_Query$variables = {};
export type ExperienceMagellan_page_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ExperienceMagellanPageMainContent_me">;
  };
};
export type ExperienceMagellan_page_Query = {
  response: ExperienceMagellan_page_Query$data;
  variables: ExperienceMagellan_page_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExperienceMagellan_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExperienceMagellanPageMainContent_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExperienceMagellan_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "728ac42fdc3e472e758639a12f1334af",
    "id": null,
    "metadata": {},
    "name": "ExperienceMagellan_page_Query",
    "operationKind": "query",
    "text": "query ExperienceMagellan_page_Query {\n  me {\n    ...ExperienceMagellanPageMainContent_me\n    id\n  }\n}\n\nfragment ExperienceMagellanPageMainContent_me on User {\n  id\n}\n"
  }
};

(node as any).hash = "3380257ea3a25ff16737f31ba94e9c10";

export default node;
