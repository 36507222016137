/**
 * Checks if all objects passed in contain the same keys at a depth of 1.
 *
 * From: https://stackoverflow.com/a/35047888
 */
const objectsHaveSameKeys = (...objects: GenericObject[]): boolean => {
  const allKeys: string[] = objects.reduce((keys: string[], object) => {
    return keys.concat(Object.keys(object));
  }, []);
  const union = new Set(allKeys);

  return objects.every((object) => union.size === Object.keys(object).length);
};

export default objectsHaveSameKeys;
