import { registerPlugin } from "@capacitor/core";

import type { CampfirePushNotificationsPlugin } from "./definitions";

const CampfirePushNotifications = registerPlugin<CampfirePushNotificationsPlugin>(
  "CampfirePushNotifications",
  {
    web: () => import("./web").then((m) => new m.CampfirePushNotificationsWeb()),
  },
);

export * from "./definitions";
export { CampfirePushNotifications };
