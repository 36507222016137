import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { canAdministrateUser, ClubRoleMemberObj } from "common/utils/clubRoleHelper";

import ClubModerationControl from "common/components/ClubModerationControl";
import MenuGroup, { MenuItem } from "common/components/MenuGroup";

import { ClubUserProfileModerationActionMenu_club$data as ClubUserProfileModerationActionMenuClub } from "__generated__/ClubUserProfileModerationActionMenu_club.graphql";
import { ClubUserProfileModerationActionMenu_me$data as ClubUserProfileModerationActionMenuMe } from "__generated__/ClubUserProfileModerationActionMenu_me.graphql";
import { ClubUserProfileModerationActionMenu_user$data as ClubUserProfileModerationActionMenuUser } from "__generated__/ClubUserProfileModerationActionMenu_user.graphql";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  me: ClubUserProfileModerationActionMenuMe;
  user: ClubUserProfileModerationActionMenuUser;
  club: ClubUserProfileModerationActionMenuClub;
};

type State = {};

class ClubUserProfileModerationActionMenu extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const currentUserIsCreator = this.props.me.id === this.props.club.creator?.id;
    const targetIsAnAdmin = this.props.user.isAdmin;
    const targetIsCreator = this.props.user.id === this.props.club.creator?.id;

    const currentUserObj: ClubRoleMemberObj = {
      id: this.props.me.id,
      isAdmin: this.props.club.amIAdmin,
      isCreator: currentUserIsCreator,
      isMember: true,
      isSuperAdmin: this.props.me.isSuperAdmin,
    };

    const targetUserObj: ClubRoleMemberObj = {
      id: this.props.user.id,
      isAdmin: targetIsAnAdmin,
      isCreator: targetIsCreator,
      isMember: this.props.user.isMember,
      isSuperAdmin: false,
    };

    if (!canAdministrateUser(currentUserObj, targetUserObj)) {
      return null;
    }

    return (
      <ClubModerationControl me={this.props.me} club={this.props.club}>
        {({ promptMakeAdmin, promptRemoveAdmin, promptBanUser }) => {
          const adminOptionOnClick = this.props.user.isAdmin ? promptRemoveAdmin : promptMakeAdmin;
          const adminOptionName = this.props.user.isAdmin
            ? this.props.t("REMOVE_AS_ADMIN")
            : this.props.t("MAKE_ADMIN");

          return (
            <div className={styles.root}>
              <MenuGroup label={this.props.club.name}>
                <MenuItem
                  name={adminOptionName}
                  onClick={() => {
                    adminOptionOnClick(this.props.user);
                  }}
                  hideEndAdornment
                />
                <MenuItem
                  name={this.props.t("BAN_FROM_GROUP")}
                  onClick={() => {
                    promptBanUser(this.props.user);
                  }}
                  hideEndAdornment
                />
              </MenuGroup>
            </div>
          );
        }}
      </ClubModerationControl>
    );
  };
}

const FragmentContainer = createFragmentContainer(ClubUserProfileModerationActionMenu, {
  me: graphql`
    fragment ClubUserProfileModerationActionMenu_me on User {
      id
      isSuperAdmin
      ...ClubModerationControl_me
    }
  `,
  user: graphql`
    fragment ClubUserProfileModerationActionMenu_user on User
    @argumentDefinitions(clubId: { type: "ID!" }) {
      id
      displayName
      isAdmin(clubId: $clubId)
      isMember(clubId: $clubId)
    }
  `,
  club: graphql`
    fragment ClubUserProfileModerationActionMenu_club on Club {
      id
      amIAdmin
      name
      creator {
        id
      }
      ...ClubModerationControl_club
    }
  `,
});

export default withTranslation()(FragmentContainer);
