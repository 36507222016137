import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { Notification } from "constants/notification";
import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { TrackClientPushNotificationActionMutation$data } from "__generated__/TrackClientPushNotificationActionMutation.graphql";

type ReturnType =
  TrackClientPushNotificationActionMutation$data["trackClientPushNotificationAction"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  notificationType: Notification;
  jsonPayload: GenericObject;
};

const mutation = graphql`
  mutation TrackClientPushNotificationActionMutation(
    $input: TrackClientPushNotificationActionInput!
  ) {
    trackClientPushNotificationAction(input: $input) {
      success
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "trackClientPushNotificationAction.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "trackClientPushNotificationAction.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "trackClientPushNotificationAction"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      cacheConfig: {
        metadata: {
          disableMutationInvalidation: true,
        },
      },
      variables: {
        input: {
          notificationType: input.notificationType,
          jsonPayload: JSON.stringify(input.jsonPayload),
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
