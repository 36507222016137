import classnames from "classnames";
import { arrowBack } from "ionicons/icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import Image from "common/components/Image";
import UIIcon from "common/components/UIIcon";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = WithTranslation & {
  gameName: string;
  className?: string;
  gameLogo?: string;
  onClick?: () => void;
};

type State = {};

class EmbeddedTopBar extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const returnMessage = this.props.t("RETURN_TO_GAME", { game: this.props.gameName });

    return (
      <div
        role="presentation"
        className={classnames(styles.root, this.props.className)}
        onClick={this.props.onClick}
      >
        <UIIcon color="dark" icon={arrowBack} />
        <UISpacer w={4} />
        {this.props.gameLogo && (
          <>
            <Image alt="Logo" className={styles.logo} src={this.props.gameLogo} />

            <UISpacer w={8} />
          </>
        )}
        <UIText className={styles.gameName} variant="subtitle1" color="dark" weight="bold">
          {returnMessage}
        </UIText>
      </div>
    );
  };
}

export default withTranslation()(EmbeddedTopBar);
