import { Keyboard } from "@capacitor/keyboard";
import { IonSlides, IonSlide } from "@ionic/react";
import classnames from "classnames";
import { chevronBackOutline } from "ionicons/icons";
import React, { RefObject } from "react";

import { isWeb } from "common/capacitor/helpers";
import HARDWARE_BACK_EVENT_PRIORITY from "constants/hardwareBackHierarchy";

import IonBackHandler from "common/components/IonBackHandler";
import UIIcon from "common/components/UIIcon";

import styles from "./styles.scss";

export type ExposedProps = {
  activeIndex: number;
  goToSlide: (index: number) => void;
  next: () => void;
  prev: () => void;
};

export type Props = {
  children: (exposedProps: ExposedProps) => Array<React.ReactNode>;
  className?: string;
  initialStep?: number;
  renderBackground?: (activeIndex: number) => React.ReactNode;
  alignSlidesTo?: "top" | "bottom";
  pager?: boolean;
  renderBackBtn?: () => React.ReactNode;
  showBackButton?: (activeIndex: number) => boolean;
  respectSafeArea?: boolean;
  onClickBack?: (activeIndex: number, slidePrevious: () => void) => void;
  enableHardwareBack?: boolean;
  hardwareBackPriority?: number;
  footer?: (exposedProps: ExposedProps) => React.ReactNode;
  allowTouchMove?: boolean;
};

type State = {
  activeIndex: number;
};

const DEFAULT_PRIORITY_HARDWARE_BACK = HARDWARE_BACK_EVENT_PRIORITY.dialogs;

export default class UIWizard extends React.Component<Props, State> {
  slidesRef: RefObject<HTMLIonSlidesElement> = React.createRef();

  slideOpts: GenericObject;

  constructor(props: Props) {
    super(props);

    const initialSlideIndex = props.initialStep || 0;

    this.slideOpts = {
      allowTouchMove: Boolean(this.props.allowTouchMove),
      initialSlide: initialSlideIndex,
      speed: 400,
    };

    this.state = {
      activeIndex: initialSlideIndex,
    };
  }

  // Updated to use onIonSlidesDidLoad from componentDidMount.
  // If issues come up, try reverting this back to that.
  onIonSlidesDidLoad = (): void => {
    // There seems to be a weird issue with ion-slides inside of ion-modal.
    // The issue that happens is if they render at the same time or something,
    // the slide does not update upon moving to the next one. Adding this in, for some reason
    // fixes it.
    // Sorta seen from here:
    // https://github.com/ionic-team/ionic-framework/issues/17522#issuecomment-575828517
    // Update 3/2/2021: Not sure why but on initial render, update isnt a function sometimes.
    // I dunno, but I figure we only need to do this if it's ready.
    if (this.slidesRef.current && this.slidesRef.current.update) {
      this.slidesRef.current.update();
    }
  };

  ionSlideWillChange = async (): Promise<void> => {
    if (this.slidesRef.current) {
      const currentIndex = await this.slidesRef.current.getActiveIndex();

      const nextState = {
        activeIndex: currentIndex,
      };

      this.setState(nextState);
    }
  };

  ionSlideDidChange = (): void => {
    // Hide the keyboard when moving to a slide.
    if (!isWeb) {
      Keyboard.hide();
    }
  };

  slideTo = (index: number): void => {
    if (this.slidesRef.current) {
      this.slidesRef.current.slideTo(index);
    }
  };

  slideNext = (): void => {
    if (this.slidesRef.current) {
      this.slidesRef.current.slideNext();
    }
  };

  slidePrevious = (): void => {
    if (this.slidesRef.current) {
      this.slidesRef.current.slidePrev();
    }
  };

  renderSlide = (reactNode: React.ReactNode, idx: number): React.ReactNode => {
    return (
      <IonSlide
        className={classnames(styles.slide, {
          [styles.pager]: this.props.pager,
        })}
        key={`slide:${idx}`}
      >
        <div className={styles.slideContent}>{reactNode}</div>
      </IonSlide>
    );
  };

  showBackButton = (): boolean => {
    if (this.props.showBackButton) {
      return this.props.showBackButton(this.state.activeIndex);
    }

    // Default, show when active slide is not the first.
    return this.state.activeIndex > 0;
  };

  handleBackButton = (): void => {
    if (this.props.onClickBack) {
      this.props.onClickBack(this.state.activeIndex, this.slidePrevious);
      return;
    }

    // Default, go to the previous slide.
    this.slidePrevious();
  };

  render = (): React.ReactNode => {
    const exposedProps = {
      activeIndex: this.state.activeIndex,
      goToSlide: this.slideTo,
      next: this.slideNext,
      prev: this.slidePrevious,
    };

    const showBackButton = this.showBackButton();

    return (
      <>
        {this.props.enableHardwareBack && (
          <IonBackHandler
            isActive
            priority={this.props.hardwareBackPriority || DEFAULT_PRIORITY_HARDWARE_BACK}
            onHardwareBack={this.handleBackButton}
          />
        )}

        <div
          className={classnames(styles.root, this.props.className, {
            [styles.alignSlidesBottom]: this.props.alignSlidesTo === "bottom",
          })}
        >
          {this.props.renderBackground && this.props.renderBackground(this.state.activeIndex)}
          {showBackButton && (
            <div
              role="presentation"
              className={classnames(styles.back, {
                [styles.safeAreaBound]: this.props.respectSafeArea,
              })}
              onClick={this.handleBackButton}
            >
              {this.props.renderBackBtn && this.props.renderBackBtn()}
              {!this.props.renderBackBtn && (
                <UIIcon color="dark" size={26} icon={chevronBackOutline} />
              )}
            </div>
          )}

          <IonSlides
            pager={this.props.pager}
            ref={this.slidesRef}
            options={this.slideOpts}
            onIonSlideWillChange={this.ionSlideWillChange}
            onIonSlideDidChange={this.ionSlideDidChange}
            onIonSlidesDidLoad={this.onIonSlidesDidLoad}
          >
            {this.props.children(exposedProps).map(this.renderSlide)}
          </IonSlides>

          {this.props.footer ? this.props.footer(exposedProps) : null}
        </div>
      </>
    );
  };
}
