// eslint-disable-next-line max-classes-per-file
import React, { FunctionComponent, useEffect } from "react";
import { useSpring, animated } from "react-spring";

import { UINavConsumer, ExposedProps } from "common/components/UINav";
import styles from "common/components/UINav/styles.scss";

export type UINavOutletProps = Props & {
  uiNavProps: ExposedProps;
};

export type Props = {
  children: (exposedProps: ExposedProps) => React.ReactNode;
};

const DEFAULT_ANIMATION_OPACITY_MS = 200;

/**
 * Connects to the UINav and renders the frame required for doing slide animations.
 * Cannot be used without a UINav higher in the React Component Tree.
 */
const UINavOutlet: FunctionComponent<UINavOutletProps> = (
  props: UINavOutletProps,
): React.ReactElement => {
  const { uiNavProps } = props;
  const { renderSlides, activeSlide, rootRef, outletSpringRef, animationSpringConfig } = uiNavProps;

  const hasActiveSlide = !!activeSlide;
  const [springStyle, api] = useSpring(() => {
    return {
      ref: outletSpringRef,
      translateX: 0,
      opacity: 0,
      visibility: "hidden",
      config: animationSpringConfig,
    };
  });

  /**
   * Transitions the opacity and visibility of the backdrop on the outlet.
   */
  const transitionBackdrop = (isStacked: boolean) => {
    const backdropVisibility = isStacked ? "visible" : "hidden";
    const backdropOpacity = isStacked ? 0.1 : 0;
    const opacityDurationAnimationMs = DEFAULT_ANIMATION_OPACITY_MS;

    // Transition the opacity of the backdrop but faster than the translate.
    api.start(() => {
      return {
        config: { duration: opacityDurationAnimationMs },
        opacity: backdropOpacity,
        onRest: () => {
          api.start(() => {
            return {
              immediate: true,
              opacity: 0,
            };
          });
        },
      };
    });

    // Transition the visibility of the backdrop immediately.
    api.start(() => {
      return {
        immediate: true,
        visibility: backdropVisibility,
      };
    });
  };

  // When we have an active slide, the outlet needs to shift left and start to show a backdrop.
  // NOTE: The shifting actually is handled in the UINav itself, due to gesture management.
  //        We can do this here as well, but its unnecessary.
  useEffect(() => {
    transitionBackdrop(hasActiveSlide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActiveSlide]);

  return (
    <div ref={rootRef} className={styles.outletRoot}>
      <animated.div
        className={styles.outletContent}
        style={{
          translateX: springStyle.translateX,
        }}
      >
        <animated.div
          className={styles.backdrop}
          style={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            visibility: springStyle.visibility as any,
            opacity: springStyle.opacity,
          }}
        />
        {props.children(uiNavProps)}
      </animated.div>

      {renderSlides()}
    </div>
  );
};

const UINavConnected = (props: Props): React.ReactElement => {
  return (
    <UINavConsumer>
      {(exposedProps: ExposedProps) => {
        return <UINavOutlet {...props} uiNavProps={exposedProps} />;
      }}
    </UINavConsumer>
  );
};

export default UINavConnected;
