import environment from "common/relay/relay-env";
import { graphql } from "react-relay";
import { fetchQuery } from "relay-runtime";

import { fetchRealityChannelByGame_Query$data as fetchRealityChannelByGameQueryResponse } from "__generated__/fetchRealityChannelByGame_Query.graphql";

const FETCH_RC_BY_GAME = graphql`
  query fetchRealityChannelByGame_Query($gameName: String!) {
    realityChannelForEmbedGame(name: $gameName) {
      id
      name
      iconURL
      sources {
        name
        dropTypes
      }
      metadata {
        isFirstParty
        game
        googleMapsPlatformMapId
        splashImageUrl
      }
      welcomeBanner {
        title
        bodyMd
        imgURL
        location {
          latitude
          longitude
        }
      }
    }
  }
`;

export default async (gameName: string): Promise<fetchRealityChannelByGameQueryResponse> => {
  const response = await fetchQuery(environment, FETCH_RC_BY_GAME, { gameName }).toPromise();

  return response as fetchRealityChannelByGameQueryResponse;
};
