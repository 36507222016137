import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";
import { RecordSourceSelectorProxy } from "relay-runtime";

import { APOLOGETIC_ERROR } from "constants/errors";
import { MutationError } from "types/modules/relay/mutations";

import { InviteToClubMutation$data } from "__generated__/InviteToClubMutation.graphql";

type ReturnType = InviteToClubMutation$data["inviteToClub"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  clubId: string;
  userId: string;
  viaDirectMessage: boolean;
};

const mutation = graphql`
  mutation InviteToClubMutation($input: InviteToClubInput!) {
    inviteToClub(input: $input) {
      clubInvite {
        club {
          id
        }
      }
      pendingClubInvite {
        inviteId
        senderId
        recipientId
        clubId
      }
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "inviteToClub.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "inviteToClub.errors[0]", errors));

        return;
      }

      resolve(_.get(response, "inviteToClub"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0]") || _.get(error, "data.errors[0]") || APOLOGETIC_ERROR;

      reject(relayErrorMessage);
    };

    const updater = (store: RecordSourceSelectorProxy) => {
      try {
        const outgoingPendingClubInvites = store
          .getRoot()
          .getLinkedRecords("outgoingPendingClubInvites");

        if (outgoingPendingClubInvites === null) {
          return;
        }

        const payload = store.getRootField("inviteToClub");

        if (payload === null) {
          return;
        }

        const pendingClubInvite = payload?.getLinkedRecord("pendingClubInvite");
        const updatedInvites = [...outgoingPendingClubInvites, pendingClubInvite];

        store.getRoot().setLinkedRecords(updatedInvites, "outgoingPendingClubInvites");
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          clubId: input.clubId,
          recipientId: input.userId,
          viaDirectMessage: input.viaDirectMessage,
        },
      },
      updater,
      onCompleted,
      onError,
    });
  });
};

export default { commit };
