/**
 * @generated SignedSource<<d101471cb2488b005363392f6994a56a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateFriendInviteInput = {
  userId: string;
};
export type CreateFriendInviteMutation$variables = {
  input: CreateFriendInviteInput;
};
export type CreateFriendInviteMutation$data = {
  readonly createFriendInvite: {
    readonly success: boolean;
    readonly user: {
      readonly hasPendingFriendInviteFromMe: boolean;
      readonly isMyFriend: boolean;
    };
  };
};
export type CreateFriendInviteMutation = {
  response: CreateFriendInviteMutation$data;
  variables: CreateFriendInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMyFriend",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPendingFriendInviteFromMe",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateFriendInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFriendInviteResponse",
        "kind": "LinkedField",
        "name": "createFriendInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateFriendInviteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFriendInviteResponse",
        "kind": "LinkedField",
        "name": "createFriendInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "058aa31a3036b08e4bdd05ca2a7c8556",
    "id": null,
    "metadata": {},
    "name": "CreateFriendInviteMutation",
    "operationKind": "mutation",
    "text": "mutation CreateFriendInviteMutation(\n  $input: CreateFriendInviteInput!\n) {\n  createFriendInvite(input: $input) {\n    user {\n      isMyFriend\n      hasPendingFriendInviteFromMe\n      id\n    }\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "43984a93d570bd6524c585b8b22f9e6d";

export default node;
