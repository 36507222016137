import React from "react";

import { AppRouterV2CoreNavigationProvider } from "providers/AppRouterV2CoreNavigationProvider";
import { ExternalLinkProvider } from "providers/ExternalLinkProvider";
import { KeyboardProvider } from "providers/KeyboardProvider";
import { RouteHistoryProvider } from "providers/RouteHistoryProvider";
import { ToastProvider } from "providers/ToastProvider";

type Props = {
  children: React.ReactNode;
};

type State = {};

export default class UIProviders extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <AppRouterV2CoreNavigationProvider>
        <RouteHistoryProvider>
          <ToastProvider>
            <ExternalLinkProvider>
              <KeyboardProvider>{children}</KeyboardProvider>
            </ExternalLinkProvider>
          </ToastProvider>
        </RouteHistoryProvider>
      </AppRouterV2CoreNavigationProvider>
    );
  };
}
