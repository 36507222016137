import React from "react";
import ReactDOM from "react-dom";

import { findHighestPriorityParentElement } from "common/components/AppRouterV2CoreNavigationFabs/navReparentStrategy";

import styles from "./styles.scss";

type Props = {
  findPossibleParents: () => HTMLElement[];
  children: React.ReactNode;
};

/**
 * A component that renders children content into a div created in memory and shifted around
 * the DOM to avoid React remounting the content.
 */
class ReparentingDiv extends React.Component<Props> {
  divToMoveAround: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    // Create an element that we can portal content into.
    // Its important this element is the same to prevent remounting the inner content.
    // React will therefore see the same element and as a result not bother to remount.
    this.divToMoveAround = document.createElement("div");
    this.divToMoveAround.className = styles.root || "";
    this.divToMoveAround.setAttribute("data-attr-reparent", "true");
  }

  componentDidMount = (): void => {
    this.reparentContentElement();
  };

  componentDidUpdate = (): void => {
    this.reparentContentElement();
  };

  reparentContentElement = (): void => {
    const possibleParentElements = this.props.findPossibleParents();

    // If no parent elements could be located, do nothing
    if (possibleParentElements.length) {
      // Find the highest ranked portal
      const bestParentToRenderInto = findHighestPriorityParentElement(possibleParentElements);

      // If no parent element could be located, do nothing
      if (bestParentToRenderInto) {
        bestParentToRenderInto.appendChild(this.divToMoveAround);
      }
    }
  };

  render = (): React.ReactNode => {
    // Portal the content desired into our container element that will be moved around the DOM.
    return ReactDOM.createPortal(<>{this.props.children}</>, this.divToMoveAround);
  };
}

export default ReparentingDiv;
