import environment from "common/relay/relay-env";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer, graphql, RelayRefetchProp } from "react-relay";

import withAnalytics, { WithAnalyticsProps } from "providers/AnalyticsProvider/withAnalytics";
import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import logException from "common/analytics/exceptions";
import { OnboardingEvent, getOnboardingEventPayload } from "common/utils/telemetry/onboarding";
import { FriendReccommendationReason as Reason } from "constants/friendRecommendations";
import CreateFriendInviteMutation from "mutations/CreateFriendInviteMutation";
import UpdateIsIncludeAsFriendSuggestionAllowedMutation from "mutations/UpdateIsIncludeAsFriendSuggestionAllowedMutation";

import OnboardNianticFriends, {
  Friend,
} from "common/components/Onboarding/components/OnboardNianticFriends";

import { AccountSetupNianticFriendsSlide_me$data as AccountSetupNianticFriendsSlideMe } from "__generated__/AccountSetupNianticFriendsSlide_me.graphql";
import { AccountSetupNianticFriendsSlide_query$data as AccountSetupNianticFriendsSlideQuery } from "__generated__/AccountSetupNianticFriendsSlide_query.graphql";

type Props = WithTranslation &
  WithToastProps &
  WithAnalyticsProps & {
    query: AccountSetupNianticFriendsSlideQuery;
    me: AccountSetupNianticFriendsSlideMe;
    relay: RelayRefetchProp;
    next: () => void;
  };

type State = {
  isIncludeMeChecked: boolean;
};

class AccountSetupNianticFriendsSlide extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isIncludeMeChecked: this.props.me.isIncludeAsFriendSuggestionAllowed,
    };
  }

  // The ML service will return a number enumerating the reason for a friend
  // recommendation. We must transform this number into a string that we'll
  // show the user.
  reasonCodeToString = (code: number, mutualFriendCount: number): string => {
    switch (code) {
      case Reason.HAS_MUTUAL_FRIENDS:
        return this.props.t("MUTUAL_FRIENDS", { numFriends: mutualFriendCount });
      case Reason.JOINED_SAME_RAID:
      case Reason.BATTLED_AT_SAME_GYM:
      case Reason.SAME_GAME_SAME_LOCATION:
        return this.props.t("PLAYED_WITH_RECENTLY");
      default:
        return "";
    }
  };

  getFriends = (): Friend[] => {
    return this.props.query.getFriendRecommendations
      .slice(0, 3)
      .map(
        ({
          user: { username, avatarUrl, id, hasPendingFriendInviteFromMe },
          reason,
          mutualFriendCount,
        }) => ({
          id,
          name: username,
          displayName: username,
          avatarUrl,
          description: this.reasonCodeToString(reason, mutualFriendCount),
          isPending: hasPendingFriendInviteFromMe,
        }),
      );
  };

  onAddFriend = async (userId: string): Promise<void> => {
    this.props.analyticsProvider.trackClientAction("OnboardingAddFriend", {
      friendId: userId,
    });
    await CreateFriendInviteMutation.commit(environment, { userId });
  };

  onClickIncludeMe = () => {
    this.props.analyticsProvider.trackClientAction(
      "OnboardingCheckIncludeMeInFriendRecommendations",
      {
        isIncludeMeChecked: !this.state.isIncludeMeChecked,
      },
    );
    this.setState((prevState) => ({ isIncludeMeChecked: !prevState.isIncludeMeChecked }));
  };

  onSubmit = async (): Promise<void> => {
    // Run mutations asynchronously to keep user going through FTUE.
    try {
      UpdateIsIncludeAsFriendSuggestionAllowedMutation.commit(environment, {
        isAllowed: this.state.isIncludeMeChecked,
      });
    } catch (error) {
      logException(
        "UpdateIsIncludeAsFriendSuggestionAllowed",
        "onSubmit",
        "AccountSetupNianticFriendsSlide",
        error,
      );
    }

    try {
      this.props.analyticsProvider.trackClientAction(
        "OnboardingEvent",
        getOnboardingEventPayload(OnboardingEvent.NIANTIC_FRIENDS),
      );
    } catch (error) {
      logException(
        "TrackClientActionOnboardingEvent",
        "onSubmit",
        "AccountSetupNianticFriendsSlide",
        error,
      );
    }

    this.props.next();
  };

  render = (): React.ReactNode => {
    const { isIncludeMeChecked } = this.state;

    return (
      <OnboardNianticFriends
        onSubmit={this.onSubmit}
        onAddFriend={this.onAddFriend}
        friends={this.getFriends()}
        isIncludedInSuggestions={isIncludeMeChecked}
        onClickIncludeMe={this.onClickIncludeMe}
      />
    );
  };
}

const ToastConnected = withToast(AccountSetupNianticFriendsSlide);

const FragmentContainer = createFragmentContainer(ToastConnected, {
  me: graphql`
    fragment AccountSetupNianticFriendsSlide_me on User {
      isIncludeAsFriendSuggestionAllowed
    }
  `,
  query: graphql`
    fragment AccountSetupNianticFriendsSlide_query on Query {
      getFriendRecommendations {
        user {
          id
          username
          avatarUrl
          hasPendingFriendInviteFromMe
        }
        reason
        mutualFriendCount
      }
    }
  `,
});

const AnalyticsConnected = withAnalytics(FragmentContainer);

export default withTranslation()(AnalyticsConnected);
