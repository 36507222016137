import React from "react";

import { CoachmarkConsumer, ExposedProps } from "./CoachmarkProvider";

export type WithCoachmarkProps = {
  coachmarkProvider: {
    currentCoachmark: ExposedProps["currentCoachmark"];
    markCoachmarkAsComplete: ExposedProps["markCoachmarkAsComplete"];
    isCoachmarkActive: ExposedProps["isCoachmarkActive"];
    evaluateNextCoachmark: ExposedProps["evaluateNextCoachmark"];
    forceEnterFlow: ExposedProps["forceEnterFlow"];
  };
};

export default <P extends WithCoachmarkProps>(Component: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithCoachmarkProps>): React.ReactElement => (
    <CoachmarkConsumer>
      {({
        currentCoachmark,
        markCoachmarkAsComplete,
        isCoachmarkActive,
        evaluateNextCoachmark,
        forceEnterFlow,
      }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component
          coachmarkProvider={{
            currentCoachmark,
            markCoachmarkAsComplete,
            isCoachmarkActive,
            evaluateNextCoachmark,
            forceEnterFlow,
          }}
          {...props}
        />
      )}
    </CoachmarkConsumer>
  );
};
