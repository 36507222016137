/**
 * @generated SignedSource<<8a89e08436be8a6171dd03c714aa5643>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InviteToClubInput = {
  clubId: string;
  recipientId: string;
  viaDirectMessage?: boolean | null;
};
export type InviteToClubMutation$variables = {
  input: InviteToClubInput;
};
export type InviteToClubMutation$data = {
  readonly inviteToClub: {
    readonly clubInvite: {
      readonly club: {
        readonly id: string;
      };
    };
    readonly pendingClubInvite: {
      readonly clubId: string;
      readonly inviteId: string;
      readonly recipientId: string;
      readonly senderId: string;
    };
  };
};
export type InviteToClubMutation = {
  response: InviteToClubMutation$data;
  variables: InviteToClubMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InviteToClubResponse",
    "kind": "LinkedField",
    "name": "inviteToClub",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClubInvite",
        "kind": "LinkedField",
        "name": "clubInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Club",
            "kind": "LinkedField",
            "name": "club",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PendingClubInvite",
        "kind": "LinkedField",
        "name": "pendingClubInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "senderId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recipientId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clubId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteToClubMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteToClubMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d5b448d0acfd372ead34c9f23d4d3152",
    "id": null,
    "metadata": {},
    "name": "InviteToClubMutation",
    "operationKind": "mutation",
    "text": "mutation InviteToClubMutation(\n  $input: InviteToClubInput!\n) {\n  inviteToClub(input: $input) {\n    clubInvite {\n      club {\n        id\n      }\n    }\n    pendingClubInvite {\n      inviteId\n      senderId\n      recipientId\n      clubId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5c94c2d0ebe5bd08d9c00182d4c0574";

export default node;
