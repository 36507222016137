import { ModifierPhases, StrictModifiers } from "@popperjs/core";
import { Modifier } from "@popperjs/core/lib/types";

const PX_AWAY_FROM_TOP_TAB_BAR = 10;

// A popper.js modifier which will shift the dropdown menu up by 10px.
export const POPPER_MODIFIER_SHIFT_MENU_10PX_UP: StrictModifiers = {
  name: "offset",
  options: {
    offset: [0, PX_AWAY_FROM_TOP_TAB_BAR],
  },
};

// A popper.js modifier which will match the width of the dropdown with the width of the tab bar.
// Taken from: https://github.com/floating-ui/floating-ui/issues/794#issuecomment-824220211
export const POPPER_MODIFIER_MATCH_WIDTH_OF_TAB_BAR: Modifier<"sameWidth", {}> = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite" as ModifierPhases,
  requires: ["computeStyles"],
  fn: ({ state }) => {
    // eslint-disable-next-line no-param-reassign
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    // Reference element is the tab bar which is a div, so cast it.
    const elReference = state.elements.reference as HTMLDivElement;

    // eslint-disable-next-line no-param-reassign
    state.elements.popper.style.minWidth = `${elReference.offsetWidth}px`;
  },
};
