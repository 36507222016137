import environment from "common/relay/relay-env";
import React, {
  ForwardedRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from "react";
import { useTranslation } from "react-i18next";
import {
  QueryRenderer,
  createPaginationContainer,
  GraphQLTaggedNode,
  ConnectionConfig,
} from "react-relay";
import { Variables } from "relay-runtime";

import ConnectionLoader, {
  DEFAULT_LIMIT,
  RequiredProps,
  OptionalProps,
} from "common/components/ConnectionLoader";
import LoadingPageDefault from "common/components/LoadingPageDefault";
import UIErrorBoundary from "common/components/UIErrorBoundary";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type ConnectionLoaderOptions = {
  useDefaultError?: boolean;
  useDefaultLoading?: boolean;
};
type SupportedComponentProps = RequiredProps & OptionalProps & ConnectionLoaderOptions;

/**
 * A helper method to create a pagination container around a connection.
 *
 * @param relayPaginationContainerOpts
 * @param connectionInfiniteLoaderOpts
 * @param initialQuery
 * @param options
 */
function createConnectionLoader<LoaderRequiredProps, LoaderRelayProps, LoaderQueryResponse>(
  // Fields we need that pertain to the Relay PaginationContainer
  relayPaginationContainerOpts: {
    // This is the fragment spec for the pagination container.
    // Specifies the data requirements for the Component via a GraphQL fragment.
    // It is expected that one of the fragments specified here will contain a @connection
    // for pagination.
    fragmentSpec: Record<string, GraphQLTaggedNode>;

    // This is the config for the pagination query. We set a few fields explicitly, but the main
    // fields you need to provide are:
    // 1. query
    //    - This is the query that will be used when fetching more data. Separate
    //            from the mainQuery as you may not need everything in the main query.
    // 2. getConnectionFromProps
    //    - This tells where the @connection is on the fragment. This is necessary in most cases
    //      because the Relay can't automatically tell which connection you mean to paginate over
    //      (a container might fetch multiple fragments and connections, but can only paginate
    //       one of them)
    // eslint-disable-next-line max-len
    connectionConfig: ConnectionConfig<LoaderRequiredProps & LoaderRelayProps>;
  },

  // Fields we need that pertain to the ConnectionInfiniteLoader, the generic interface
  // that we use when paginating over any set of data.
  connectionInfiniteLoaderOpts: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getNodes: (props: LoaderRelayProps) => any[];

    // This allows us to specify the fragment name onto the component we are rendering.
    getRelayPropsForComponent: (props?: LoaderQueryResponse) => LoaderRelayProps;
  },

  // Things that define the main query that will be fetched to start off the pagination.
  initialQuery: {
    // This is the main query you want to fetch when the component renders.
    query: GraphQLTaggedNode;

    // Since we support an initial query, we must be able to pass variables to it, so this is how.
    getVariables: (props: LoaderRequiredProps) => Variables;
  },
): ForwardRefExoticComponent<
  PropsWithoutRef<LoaderRequiredProps & SupportedComponentProps> & RefAttributes<unknown>
> {
  // Implement a consistent pattern for pagination using first/after.
  // NOTE: This only works when paginating through sets in most recent order.
  // TODO: Add support to handle last/before and reverse direction pagiantion.
  const getVariables: ConnectionConfig<LoaderRequiredProps & LoaderRelayProps>["getVariables"] = (
    props: LoaderRequiredProps & LoaderRelayProps,
    paginationInfo,
    fragmentVariables: Variables,
  ) => {
    return {
      // eslint-disable-next-line max-len
      ...relayPaginationContainerOpts.connectionConfig.getVariables(
        props,
        paginationInfo,
        fragmentVariables,
      ),
      first: paginationInfo.count,
      after: paginationInfo.cursor,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ConnectionLoaderPaginated = createPaginationContainer<any>(
    ConnectionLoader,
    relayPaginationContainerOpts.fragmentSpec,
    {
      ...relayPaginationContainerOpts.connectionConfig,
      direction: "forward",
      getVariables,
    },
  );

  return React.forwardRef(
    (componentProps: LoaderRequiredProps & SupportedComponentProps, ref: ForwardedRef<unknown>) => {
      const { t } = useTranslation();

      return (
        <UIErrorBoundary>
          <QueryRenderer
            environment={environment}
            query={initialQuery.query}
            variables={{
              first: componentProps.limit || DEFAULT_LIMIT,
              ...initialQuery.getVariables(componentProps),
            }}
            cacheConfig={{
              force: !!componentProps.forceRefresh,
            }}
            fetchPolicy={componentProps.refetchPolicy || "network-only"}
            render={({ props, error }) => {
              const qrProps = props as LoaderQueryResponse;
              const isMainQueryLoading = !qrProps;
              const isMainQueryErrored = !!error;
              const useDefaultError =
                componentProps.useDefaultError !== undefined
                  ? componentProps.useDefaultError
                  : true;
              const useDefaultLoading =
                componentProps.useDefaultLoading !== undefined
                  ? componentProps.useDefaultLoading
                  : true;

              if (isMainQueryErrored && useDefaultError) {
                return (
                  <div className={styles.error}>
                    <UIText variant="body1" color="dark">
                      {t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
                    </UIText>
                  </div>
                );
              }

              if (isMainQueryLoading && useDefaultLoading) {
                return <LoadingPageDefault delayMs={0} />;
              }

              return (
                <ConnectionLoaderPaginated
                  {...componentProps}
                  {...connectionInfiniteLoaderOpts.getRelayPropsForComponent(qrProps)}
                  ref={ref}
                  getNodes={connectionInfiniteLoaderOpts.getNodes}
                  isLoadingInitialQuery={!qrProps}
                />
              );
            }}
          />
        </UIErrorBoundary>
      );
    },
  );
}

export default createConnectionLoader;
