// ===== Font Size Accessibility Management =====
// On devices, the font size can be set for accessibility reasons. However, when set to very large
// this impacts our view consistency. This code will adjust the zoom level of the app
// in an attempt to keep things more visually consistent, regardless of the user's
// preferred settings.
import { TextZoom } from "@capacitor/text-zoom";

import { isStandalone } from "common/capacitor/helpers";

const adjustFontSizeIfNeeded = async (): Promise<void> => {
  try {
    // Prevent accessibility settings from getting too far out of whack.
    if (isStandalone) {
      const maxZoomLevel = 1.2;
      const minZoomLevel = 0.8;
      const preferredZoomLevelResponse = await TextZoom.getPreferred();
      const preferredZoomLevel = preferredZoomLevelResponse.value;
      // Put an upper and lower bound.
      const boundedZoomLevel = Math.min(Math.max(minZoomLevel, preferredZoomLevel), maxZoomLevel);

      // If the preferred zoom level is not the same (means it was out of bounds)
      // apply the bounded value. Otherwise, we're all good!
      if (preferredZoomLevel !== boundedZoomLevel) {
        TextZoom.set({ value: boundedZoomLevel });
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Unable to adjust font size");
  }
};

export default adjustFontSizeIfNeeded;
