import classnames from "classnames";
import React, { FunctionComponent } from "react";

import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";

import styles from "./styles.scss";

export type Props = {
  isOpen: boolean;
  isInvisible?: boolean;
  onClick?: () => void;
  // Let the parent control the backdrop positioning
  cssClass: string;
};

/**
 * The backdrop receives click events outside of the dialog or drawer, to close the dialog or drawer.
 * It can be rendered in 3 states:
 * - Rendered + Visible: Normal use cases
 * - Rendered + Invisible: When you want a dialog/drawer and you don't want it to show but still receive clicks.
 * - Not Rendered: When you want a dialog/drawer but want to be able to act on things behind
 */
const Backdrop: FunctionComponent<Props> = (props: Props): React.ReactElement => {
  return (
    <AnimatedFadeInOut className={props.cssClass} show={props.isOpen}>
      <div
        role="presentation"
        className={classnames(styles.backdrop, {
          [styles.visible]: !props.isInvisible,
        })}
        onClick={props.onClick}
      />
    </AnimatedFadeInOut>
  );
};

export default Backdrop;
