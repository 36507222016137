import _ from "lodash";
import { commitMutation, DeclarativeMutationConfig, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR } from "constants/errors";
import { MutationError } from "types/modules/relay/mutations";

import { BanUserMutation$data } from "__generated__/BanUserMutation.graphql";

type ReturnType = BanUserMutation$data["banUser"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  clubId: string;
  userId: string;
  reason?: string;
};

const mutation = graphql`
  mutation BanUserMutation($input: BanUserInput!, $clubId: ID!) {
    banUser(input: $input) {
      user {
        id
        isAdmin(clubId: $clubId)
        isMember(clubId: $clubId)
      }
      success
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "banUser.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "banUser.errors[0]", errors));

        return;
      }

      resolve(_.get(response, "banUser"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0]") || _.get(error, "data.errors[0]") || APOLOGETIC_ERROR;

      reject(relayErrorMessage);
    };

    // Updater configs to update other connections in the app.
    // Find a club with this id, look for the connection named as such
    // then append it. Identify what we named the edge field for this connection.
    const addUpdaterConfig: DeclarativeMutationConfig = {
      type: "RANGE_ADD",
      parentID: input.clubId,
      connectionInfo: [
        {
          key: "BannedClubMembersConnectionLoader_bannedUsers",
          rangeBehavior: "append",
        },
      ],
      edgeName: "edges",
    };

    // Find a club with this id, look for the connection named as such
    // located at the path to connection. The entity to delete can be identified using
    // the field on this entity "id".
    const deleteUpdaterConfig: DeclarativeMutationConfig = {
      type: "RANGE_DELETE",
      parentID: input.clubId,
      connectionKeys: [
        {
          key: "ClubMembersConnectionLoader_members",
        },
      ],
      pathToConnection: ["club", "bannedUsers"],
      deletedIDFieldName: ["user"],
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          clubId: input.clubId,
          userId: input.userId,
          reason: input.reason || "No reason specified.",
        },
        clubId: input.clubId,
      },
      configs: [addUpdaterConfig, deleteUpdaterConfig],
      onCompleted,
      onError,
    });
  });
};

export default { commit };
