import environment from "common/relay/relay-env";
import { close } from "ionicons/icons";
import _ from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";
import { RouteComponentProps, withRouter } from "react-router-dom";

import withToast, { WithToastProps } from "providers/ToastProvider/withToast";

import logException from "common/analytics/exceptions";
import entryPointHistory, { EntryPoint } from "common/utils/telemetry/entryPointHistory";
import { USER_BANNED_ERR_CODE } from "constants/backendErrors";
import HARDWARE_BACK_EVENT_PRIORITY from "constants/hardwareBackHierarchy";
import ReportContext from "constants/reportContext";
import InviteToClubMutation from "mutations/InviteToClubMutation";

import MenuGroup, { MenuItem } from "common/components/MenuGroup";
import MyClubsInfiniteLoader, { Club } from "common/components/MyClubsConnectionLoader";
import UIAsyncButton from "common/components/UIAsyncButton";
import UIButton from "common/components/UIButton";
import UIIcon from "common/components/UIIcon";
import UINav, {
  UINavSlides,
  UINavSlide,
  UINavSlideView,
  UINavOutlet,
} from "common/components/UINav";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";
import UIUserList, { UserLikeObject } from "common/components/UIUserList";
import { GameProfile, toAppItems } from "common/components/UserAppList";
import UserProfile, { UserProfileOptions } from "common/components/UserProfile";

import { UserProfileFragment_me$data as UserProfileFragmentMe } from "__generated__/UserProfileFragment_me.graphql";
import { UserProfileFragment_query$data as UserProfileFragmentQuery } from "__generated__/UserProfileFragment_query.graphql";
import { UserProfileFragment_user$data as UserProfileFragmentUser } from "__generated__/UserProfileFragment_user.graphql";

import styles from "./styles.scss";

type MyClubEdge = ExtractRelayEdge<UserProfileFragmentMe["memberOf"]>;
type MyClub = ExtractRelayEdgeNode<UserProfileFragmentMe["memberOf"]>;
type MemberOfClubEdge = ExtractRelayEdge<UserProfileFragmentUser["memberOf"]>;
type MemberOfClub = ExtractRelayEdgeNode<UserProfileFragmentUser["memberOf"]>;

type Props = RouteComponentProps &
  WithTranslation &
  WithToastProps & {
    query: UserProfileFragmentQuery;
    me: UserProfileFragmentMe;
    user: UserProfileFragmentUser;
    reportContext: ReportContext;
    close: () => void;
    children?: React.ReactNode;
    options?: UserProfileOptions;
    disableDms?: boolean;
    disableInviteToClubs?: boolean;
  };

type State = {};

enum UserProfileSlide {
  INVITE_TO_GROUP = "INVITE_TO_GROUP",
}

const PX_PROFILE_FROM_TOP = 70;

class UserProfileFragment extends React.Component<Props, State> {
  /**
   * Checks whether current user (me) can invite another to a club.
   * The prop, disableInviteToClubs, if set, will override this behavior.
   *
   * @param inviteeClubIds - the club IDs that invitee has already joined
   */
  canInviteToClubs = (inviteeClubIds: string[]): boolean => {
    if (this.props.disableInviteToClubs) {
      return false;
    }

    const myClubEdges = _.get(this.props.me.memberOf, "edges", []) as MyClubEdge[];
    const myClubs = myClubEdges.map((edge: MyClubEdge) => edge.node) as MyClub[];

    return myClubs
      .map((club: MyClub) => club.id)
      .some((clubId: string) => !inviteeClubIds.includes(clubId));
  };

  inviteUserToGroup = async (groupId: string): Promise<void> => {
    const userToInvite = this.props.user.id;

    try {
      const payload = {
        clubId: groupId,
        userId: userToInvite,
        viaDirectMessage: true,
      };

      await InviteToClubMutation.commit(environment, payload);
      entryPointHistory.addEntryPoint(EntryPoint.USER_PROFILE);
      this.props.toastProvider.showSuccessToast(this.props.t("INVITED_EXCLAMATION"));
    } catch (error) {
      if ((error as CampfireErrors.Error).extensions?.code === USER_BANNED_ERR_CODE) {
        this.props.toastProvider.showErrorToast(this.props.t("USER_IS_BANNED_FROM_GROUP"));
      } else {
        this.props.toastProvider.showErrorToast(this.props.t("FAILED_INVITE_USER"));
      }

      logException(
        "InviteToClubMutation.commit",
        "inviteUserToGroup",
        "UserProfileFragment",
        error,
      );
    }
  };

  renderUserEndAdornment = (club: UserLikeObject): React.ReactNode => {
    // Cast it to denote these are actually groups that we passed in.
    const group = club as Club;
    const pendingInvite = _.find(this.props.query.outgoingPendingClubInvites, (invite) => {
      return invite.clubId === group.id && invite.recipientId === this.props.user.id;
    });
    const hasPendingInvite = pendingInvite !== undefined;

    return (
      <div>
        <UIAsyncButton
          className={styles.inviteToClubBtn}
          onClick={async () => {
            await this.inviteUserToGroup(group.id);
          }}
          fill="outline"
          color="dark"
          size="small"
          disabled={hasPendingInvite}
        >
          <UIText weight="semiBold" variant="h5">
            {hasPendingInvite
              ? this.props.t("PENDING").toLocaleUpperCase()
              : this.props.t("INVITE")}
          </UIText>
        </UIAsyncButton>
      </div>
    );
  };

  renderUserActions = (
    showSlide: (slideId: string) => void,
    canInviteToClubs: boolean,
  ): React.ReactNode => {
    const isCurrentUser = this.props.me.id === this.props.user.id;

    return (
      <div className={styles.actions}>
        {!this.props.user.isBlocked && !isCurrentUser && canInviteToClubs && (
          <MenuGroup label={this.props.t("GROUPS")}>
            <MenuItem
              name={this.props.t("INVITE_TO_GROUP")}
              onClick={() => showSlide(UserProfileSlide.INVITE_TO_GROUP)}
            />
          </MenuGroup>
        )}
      </div>
    );
  };

  renderProfileView = (
    showSlide: (slideId: string) => void,
    canInviteToClubs: boolean,
  ): React.ReactNode => {
    const visibleAppList = toAppItems(
      this.props.user.gameProfiles.filter(Boolean) as GameProfile[],
      this.props.user.isMyFriend,
    );

    return (
      <UserProfile
        myUserId={this.props.me.id}
        avatarUrl={this.props.user.avatarUrl}
        displayName={this.props.user.displayName}
        username={this.props.user.username}
        userId={this.props.user.id}
        isBlocked={this.props.user.isBlocked}
        isMyFriend={this.props.user.isMyFriend}
        hasPendingFriendInviteFromMe={this.props.user.hasPendingFriendInviteFromMe}
        userGameProfiles={visibleAppList}
        isSelf={this.props.me.id === this.props.user.id}
        close={this.props.close}
        renderActions={() => this.renderUserActions(showSlide, canInviteToClubs)}
        options={this.props.options}
        disableDms={this.props.disableDms}
        reportContext={this.props.reportContext}
      >
        {this.props.children}
      </UserProfile>
    );
  };

  render = (): React.ReactNode => {
    const memberOfClubEdges = _.get(this.props.user.memberOf, "edges", []) as MemberOfClubEdge[];
    const memberOfClubs = memberOfClubEdges.map(
      (edge: MemberOfClubEdge) => edge.node,
    ) as MemberOfClub[];
    const memberOfClubsIds: string[] = memberOfClubs.map((club: MemberOfClub) => club.id);
    const canInviteToClubs = this.canInviteToClubs(memberOfClubsIds);

    return (
      <div className={styles.root}>
        <UINav
          enableSwipebackOnSlides
          enableHardwareBack
          hardwareBackPriority={HARDWARE_BACK_EVENT_PRIORITY.appRoot["userProfile|nav"]}
        >
          <UINavSlides>
            <UINavSlide slideId={UserProfileSlide.INVITE_TO_GROUP}>
              {({ hideLastSlide }) => (
                <UINavSlideView
                  title={this.props.t("INVITE_USERNAME", { username: this.props.user.displayName })}
                  onClickBack={hideLastSlide}
                  scrollable={false}
                  respectSafeArea
                >
                  <div className={styles.paneContent}>
                    <MyClubsInfiniteLoader>
                      {({ connectionItems, renderListViewport }) => {
                        const clubsAsObjects = (connectionItems as Club[]).filter(
                          (club: Club) => !memberOfClubsIds.includes(club.id),
                        );

                        return renderListViewport(
                          <UIUserList
                            avatarStyle="square"
                            users={clubsAsObjects}
                            renderEndAdornment={this.renderUserEndAdornment}
                          />,
                        );
                      }}
                    </MyClubsInfiniteLoader>
                  </div>
                </UINavSlideView>
              )}
            </UINavSlide>
          </UINavSlides>

          <UINavOutlet>
            {({ showSlide }) => (
              <div className={styles.scroller}>
                {/* For mobile devices that can elastic/overscroll, the transparent bg of the */}
                {/* scroller div becomes visible. This overscrollBg covers the transparency */}
                {/* behind the profile */}
                <div className={styles.overscrollBg} style={{ top: PX_PROFILE_FROM_TOP }} />
                <div className={styles.buttonGradient}>
                  <UIButton color="light" className={styles.closeBtn} onClick={this.props.close}>
                    <UIIcon color="medium" icon={close} size={30} />
                  </UIButton>
                </div>

                <div role="presentation" onClick={this.props.close}>
                  <UISpacer h={PX_PROFILE_FROM_TOP} />
                </div>

                <div className={styles.profileWrapper}>
                  {this.renderProfileView(showSlide, canInviteToClubs)}
                </div>
              </div>
            )}
          </UINavOutlet>
        </UINav>
      </div>
    );
  };
}

const FragmentContainer = createFragmentContainer(UserProfileFragment, {
  query: graphql`
    fragment UserProfileFragment_query on Query {
      outgoingPendingClubInvites {
        inviteId
        senderId
        recipientId
        clubId
      }
    }
  `,
  me: graphql`
    fragment UserProfileFragment_me on User {
      id
      memberOf {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
  user: graphql`
    fragment UserProfileFragment_user on User {
      id
      username
      displayName
      avatarUrl
      isBlocked
      isMyFriend
      hasPendingFriendInviteFromMe
      gameProfiles {
        id
        game
        codename
        faction
        factionColor
        visibility
        level
        lastPlayedTimestampMs
      }
      memberOf {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
});

const ToastConnected = withToast(FragmentContainer);
const RouterConnected = withRouter(ToastConnected);

export default withTranslation()(RouterConnected);
