import { App, AppState } from "@capacitor/app";
import { Dialog } from "@capacitor/dialog";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withAnalytics, { WithAnalyticsProps } from "providers/AnalyticsProvider/withAnalytics";
import withAppPermission, {
  WithAppPermissionProps,
} from "providers/AppPermissionProvider/withAppPermission";

import { isWeb } from "common/capacitor/helpers";
import { PERMISSION_TYPE } from "common/stores/PermissionStore";
import { getOnboardingEventPayload, OnboardingEvent } from "common/utils/telemetry/onboarding";

import PollingRefetcher from "common/components/PollingRefetcher";

import AccountSetupLocationServicesPermissionSlideView from "boot-loader/components/AccountSetupLocationServicesPermissionSlideView";

type Props = WithAppPermissionProps &
  WithTranslation &
  WithAnalyticsProps & {
    isActive: boolean;
    next: () => void;
  };

type State = {
  checkingIfLocationServicesEnabled: boolean;
  stepComplete: boolean;
};

class AccountSetupLocationServicesPermissionSlide extends React.Component<Props, State> {
  appListenerHandler: GenericObject | null;

  constructor(props: Props) {
    super(props);

    this.appListenerHandler = null;

    this.state = {
      checkingIfLocationServicesEnabled: false,
      stepComplete: false,
    };
  }

  componentDidMount = async (): Promise<void> => {
    this.setState({
      checkingIfLocationServicesEnabled: false,
    });

    // Listen for changes in the app state, and when the app comes back,
    // check and update the geolocation value.
    this.appListenerHandler = App.addListener("appStateChange", (state: AppState) => {
      if (this.props.isActive) {
        // Mark the step as not complete to restart checking for non-prompt status.
        if (state.isActive) {
          this.setState({ stepComplete: false });
        }
      }
    });
  };

  componentWillUnmount = (): void => {
    if (this.appListenerHandler) {
      this.appListenerHandler.remove();
    }
  };

  handleDeniedPermissionState = async (): Promise<void> => {
    if (isWeb) {
      this.slideNext();
    } else {
      // When permissions are denied, show an alert to encourage the user to enable it.
      const confirmResult = await Dialog.confirm({
        title: this.props.t("LOCATION_SERVICES_OFF"),
        message: this.props.t("HIGHLY_ENCOURAGE_LOCATION_SERVICES"),
        okButtonTitle: this.props.t("GOT_IT"),
      });

      const userWantsToProceed = confirmResult.value;

      if (userWantsToProceed) {
        this.props.analyticsProvider.trackClientAction(
          "OnboardingEvent",
          getOnboardingEventPayload(OnboardingEvent.LOCATION, false),
        );
        this.slideNext();
      }
    }
  };

  autoAdvancePermissionsSlide = async (): Promise<void> => {
    const permissionState = await this.props.getPermissionState(PERMISSION_TYPE.geolocation);

    if (permissionState === "granted") {
      this.setState({ stepComplete: true });
      setTimeout(this.slideNext, 250);
    }
  };

  slideNext = (): void => {
    this.setState({ stepComplete: true });
    this.props.next();
  };

  enableLocationServices = async (): Promise<void> => {
    const permissionState = await this.props.requestPermission(PERMISSION_TYPE.geolocation);

    if (permissionState === "granted") {
      this.props.analyticsProvider.trackClientAction(
        "OnboardingEvent",
        getOnboardingEventPayload(OnboardingEvent.LOCATION),
      );
      this.slideNext();
    } else {
      this.handleDeniedPermissionState();
    }
  };

  render = (): React.ReactNode => {
    // If this is our first time seeing this slide, and we are currently being presented,
    // Poll for changes to auto advance the flow.
    const autoAdvance = this.props.isActive && !this.state.stepComplete;

    return (
      <>
        {autoAdvance && (
          <PollingRefetcher pollingFn={this.autoAdvancePermissionsSlide} intervalTimeMs={1000} />
        )}

        <AccountSetupLocationServicesPermissionSlideView
          isActive={this.props.isActive}
          checkingIfLocationServicesEnabled={this.state.checkingIfLocationServicesEnabled}
          enableLocationServices={this.enableLocationServices}
          next={this.slideNext}
        />
      </>
    );
  };
}

const AnalyticsConnected = withAnalytics(AccountSetupLocationServicesPermissionSlide);
const AppPermissionConnected = withAppPermission(AnalyticsConnected);

export default withTranslation()(AppPermissionConnected);
