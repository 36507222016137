import React, { useEffect, useState, FunctionComponent, useCallback } from "react";
import ReactDOM from "react-dom";

type Props = {
  children: React.ReactNode;
};

const MountAfterPortalAttached = (props: {
  children: React.ReactElement;
}): React.ReactElement | null => {
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    setReadyToRender(true);
  }, []);

  if (!readyToRender) {
    return null;
  }

  return props.children;
};

/**
 * Uses React Portals to move your content to the IonApp on the page.
 * Mounts your component after the portal has attached the DOM node.
 */
const IonAppPortal: FunctionComponent<Props> = (props: Props): React.ReactElement | null => {
  const ionAppEl = document.querySelector("ion-app");
  const [, updateState] = useState();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    // In storybook, sometimes we use this component inside of an IonApp, which isn't ready just yet.
    // Normal operation, it always is ready since its basically the root of our app.
    // So trigger a forced update so our examples in storybook work and not as a result of the
    // aforementioned error.
    if (process.env.STORYBOOK) {
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If no portal could be located, do nothing
  if (!ionAppEl) return null;

  // Portal the content to the export container somewhere else on the page
  return ReactDOM.createPortal(
    <MountAfterPortalAttached>
      <>{props.children}</>
    </MountAfterPortalAttached>,
    ionAppEl,
  );
};

export default IonAppPortal;
