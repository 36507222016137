import _ from "lodash";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export type ExposedProps = {
  addToQueue: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taskCallback: () => Promise<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess?: (response: any) => void,
    onError?: (error: unknown) => void,
  ) => void;
  clearQueue: () => void;
};

type Props = {
  children: React.ReactNode;
};

type State = {};

type AsyncTask = {
  id: string;
  task: () => Promise<unknown>;
  onTaskSuccess?: (response: unknown) => void;
  onTaskError?: (error: unknown) => void;
};

const INITIAL_CONTEXT: ExposedProps = {
  addToQueue: _.noop,
  clearQueue: _.noop,
};

const MessageTaskQueueContext = React.createContext(INITIAL_CONTEXT);

const PROCESSING_RATE_MS = 100;

export class MessageTaskQueueProvider extends React.Component<Props, State> {
  queueStatus: "idle" | "processing" = "idle";

  tasks: AsyncTask[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processingTimer: any;

  hasTasksToProcess = (): boolean => {
    return Boolean(this.tasks.length);
  };

  addToQueue = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taskCallback: () => Promise<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess?: (response: any) => void,
    onError?: (error: unknown) => void,
  ): void => {
    // Create an AsyncTask object.
    const asyncTask = {
      id: uuidv4(),
      task: taskCallback,
      onTaskSuccess: onSuccess,
      onTaskError: onError,
    };

    // Add the task to the queue.
    this.tasks.push(asyncTask);

    // Start the processing queue if not already in motion.
    this.scheduleProcessing();
  };

  /**
   * Resets the task queue and stops pending tasks.
   */
  clearQueue = (): void => {
    if (this.processingTimer) {
      clearTimeout(this.processingTimer);
    }

    this.tasks = [];
    this.queueStatus = "idle";
  };

  scheduleProcessing = (): void => {
    if (this.queueStatus === "idle") {
      this.processNextTask();
    }
  };

  processNextTask = (): void => {
    if (this.hasTasksToProcess()) {
      this.queueStatus = "processing";

      this.processingTimer = setTimeout(async () => {
        // Get the first task in the queue
        const asyncTask = this.tasks[0];

        // Remove the item from the queue
        this.tasks.shift();

        // Invoke the task
        try {
          const response = await asyncTask.task();

          if (asyncTask.onTaskSuccess) {
            asyncTask.onTaskSuccess(response);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error processing task:", error);

          if (asyncTask.onTaskError) {
            asyncTask.onTaskError(error);
          }
        } finally {
          // Bring the queue to rest and schedule the next task
          this.queueStatus = "idle";
          this.processNextTask();
        }
      }, PROCESSING_RATE_MS);
    }
  };

  render = (): React.ReactNode => {
    const { children } = this.props;

    return (
      <MessageTaskQueueContext.Provider
        value={{
          addToQueue: this.addToQueue,
          clearQueue: this.clearQueue,
        }}
      >
        {children}
      </MessageTaskQueueContext.Provider>
    );
  };
}

export const MessageTaskQueueConsumer = MessageTaskQueueContext.Consumer;
