import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { RemoveFriendMutation$data } from "__generated__/RemoveFriendMutation.graphql";

type ReturnType = RemoveFriendMutation$data["removeFriend"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;
type InputType = {
  userId: string;
};

const mutation = graphql`
  mutation RemoveFriendMutation($input: RemoveFriendInput!) {
    removeFriend(input: $input) {
      me {
        ...friendsList_shared_user @relay(mask: false)
      }
      user {
        isMyFriend
      }
      success
    }
  }
`;

const commit = (environment: Environment, input: InputType): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "removeFriend.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "removeFriend.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "removeFriend"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          userId: input.userId,
        },
      },
      onCompleted,
      onError,
    });
  });
};

export default { commit };
