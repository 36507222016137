import environment from "common/relay/relay-env";
import React from "react";
import { createFragmentContainer, graphql } from "react-relay";

import LandingPageHills from "assets/images/backgrounds/landing_page_hills.png";
import logException from "common/analytics/exceptions";
import { isEmbedded } from "common/utils/webInterop";
import MarkNuxShownMutation from "mutations/MarkNuxShownMutation";

import AnimatedFadeInOut from "common/components/AnimatedFadeInOut";
import EmbeddedPageWithTopBarWrapper from "common/components/EmbeddedPageWithTopBarWrapper";
import Image from "common/components/Image";
import UIWizard, { ExposedProps as UIWizardExposedProps } from "common/components/UIWizard";

import AccountSetupEscapeSlide from "boot-loader/components/AccountSetupEscapeSlide";
import AccountSetupLocationServicesPermissionSlide from "boot-loader/components/AccountSetupLocationServicesPermissionSlide";
import AccountSetupNianticFriendsSlide from "boot-loader/components/AccountSetupNianticFriendsSlide";
import AccountSetupNianticGamesSlide from "boot-loader/components/AccountSetupNianticGamesSlide";
import AccountSetupNianticIdSlide from "boot-loader/components/AccountSetupNianticIdSlide";
import AccountSetupNianticNotificationsSlide from "boot-loader/components/AccountSetupNianticNotificationsSlide";
import AccountSetupNotificationsPermissionSlide from "boot-loader/components/AccountSetupNotificationsPermissionSlide";

import { AccountSetupFlow_me$data as AccountSetupFlowMe } from "__generated__/AccountSetupFlow_me.graphql";
import { AccountSetupFlow_query$data as AccountSetupFlowQuery } from "__generated__/AccountSetupFlow_query.graphql";

import styles from "./styles.scss";

export type Props = {
  query: AccountSetupFlowQuery | null;
  me: AccountSetupFlowMe | null;
  closeAndReset: () => void;
  finishSetupFlow: () => Promise<void>;
  setupWithoutData?: boolean;
  disableBack?: boolean;
  desiresSetupNianticId?: boolean;
  desiresSetupNianticGames?: boolean;
  desiresSetupNianticFriends?: boolean;
  desiresSetupNianticNotifications?: boolean;
};

type State = {};

type SetupStepName =
  | "niantic-id"
  | "niantic-games"
  | "niantic-friends"
  | "niantic-notifications"
  | "location-services"
  | "push-notification-services"
  | "exit-flow";

type SetupSlideProps = {
  isActive: boolean;
  next: () => Promise<void>;
};

type SetupSlidePropsWithRelay = SetupSlideProps & {
  me: AccountSetupFlowMe;
  query: AccountSetupFlowQuery;
};

// These are setup steps that do NOT require relay data to work.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CORE_SLIDES: Record<SetupStepName, React.FunctionComponent<SetupSlideProps>> = {
  "niantic-id": (props: SetupSlideProps) => <AccountSetupNianticIdSlide {...props} />,
  "niantic-notifications": (props: SetupSlideProps) => (
    <AccountSetupNianticNotificationsSlide {...props} />
  ),
  "location-services": (props: SetupSlideProps) => (
    <AccountSetupLocationServicesPermissionSlide {...props} />
  ),
  "push-notification-services": (props: SetupSlideProps) => (
    <AccountSetupNotificationsPermissionSlide {...props} />
  ),
  "exit-flow": (props: SetupSlideProps) => <AccountSetupEscapeSlide {...props} />,
};

// These are setup steps that REQUIRE relay data to work.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const RELAY_CONNECTED_SLIDES: Record<
  SetupStepName,
  React.FunctionComponent<SetupSlidePropsWithRelay>
> = {
  "niantic-games": (props: SetupSlidePropsWithRelay) => (
    <AccountSetupNianticGamesSlide {...props} me={props.me} />
  ),
  "niantic-friends": (props: SetupSlidePropsWithRelay) => (
    <AccountSetupNianticFriendsSlide {...props} query={props.query} />
  ),
};

/**
 * Often referred to as the FTUE, user onboarding, or for eng the AccountSetupFlow.
 *
 * The purpose of this flow is the guarantee that a user always has a nianticId set before
 * entering the application. In this version of the flow, steps can be divided into 2 categories:
 * - Controlled Steps
 * - Conditional Steps
 *
 * A "Controlled" step is a step that is 100% controlled by the parent component. If the parent
 * says its wants this type of step, it will 100% get it. The services step is an example of this.
 *
 * A "Conditional" step is a step that can be desired by the parent, but is not guaranteed to be
 * displayed as it requires additional data to resolve in order to make that decision. Friend
 * recommendations is an example of this, since if we have no friends to recommend, this step
 * should be skipped.
 *
 * In the event a user has no steps to show, we insert a special escape hatch step to welcome
 * the user to campfire, which allows them to exit the flow.
 */
class AccountSetupFlow extends React.Component<Props, State> {
  slidesToShow: SetupStepName[] = [];

  constructor(props: Props) {
    super(props);

    const slidesWithoutRelay = AccountSetupFlow.getSlidesToShow(
      this.props.desiresSetupNianticId,
      false,
      false,
      this.props.desiresSetupNianticNotifications,
    );

    if (props.setupWithoutData) {
      this.slidesToShow = slidesWithoutRelay;
    } else if (props.me && props.query) {
      const hasGameProfiles =
        this.props.desiresSetupNianticGames && props.me.gameProfiles.length > 0;
      const hasEnoughFriendReccomendations = props.query.getFriendRecommendations.length > 0;
      const setupNianticGames = this.props.desiresSetupNianticGames && hasGameProfiles;
      const setupNianticFriends =
        this.props.desiresSetupNianticFriends && hasEnoughFriendReccomendations;

      // Compute slides to show immediately (Since this really should not be dynamic)
      this.slidesToShow = AccountSetupFlow.getSlidesToShow(
        this.props.desiresSetupNianticId,
        setupNianticGames,
        setupNianticFriends,
        this.props.desiresSetupNianticNotifications,
      );
    } else {
      // This case happens when we want to setup with data, but the data is null so we can't
      this.slidesToShow = slidesWithoutRelay;
    }

    // Check if we potentially don't have any steps we need to show. If so, insert a special
    // escape hatch step so the user can GTFO of this flow.
    if (this.slidesToShow.length === 0) {
      this.slidesToShow = ["exit-flow"];
    }
  }

  static getSlidesToShow = (
    setupNianticId: boolean | undefined,
    setupNianticGames: boolean | undefined,
    setupNianticFriends: boolean | undefined,
    setupNianticNotifications: boolean | undefined,
  ): SetupStepName[] => {
    let slidesToShowAsPartOfSetup: SetupStepName[] = [];

    // Based on props, compose the set of slides that we should have.
    // If we need to setup niantic id, show slides for that.
    if (setupNianticId) {
      slidesToShowAsPartOfSetup = slidesToShowAsPartOfSetup.concat(["niantic-id"]);
    }

    if (setupNianticGames) {
      slidesToShowAsPartOfSetup = slidesToShowAsPartOfSetup.concat(["niantic-games"]);
    }

    if (setupNianticFriends) {
      slidesToShowAsPartOfSetup = slidesToShowAsPartOfSetup.concat(["niantic-friends"]);
    }

    if (setupNianticNotifications) {
      slidesToShowAsPartOfSetup = slidesToShowAsPartOfSetup.concat(["niantic-notifications"]);
    }

    return slidesToShowAsPartOfSetup;
  };

  finishSetup = async (): Promise<void> => {
    this.markNuxShown();
    return this.props.finishSetupFlow();
  };

  renderBackground = (): React.ReactNode => {
    return (
      <AnimatedFadeInOut show>
        <div className={styles.bgContainer}>
          <Image className={styles.bgImage} src={LandingPageHills} />
        </div>
      </AnimatedFadeInOut>
    );
  };

  showBackButton = (activeIndex: number): boolean => {
    if (this.props.disableBack) {
      return false;
    }

    return activeIndex === 0;
  };

  onClickBack = (activeIndex: number): void => {
    if (activeIndex === 0) {
      this.props.closeAndReset();
    }
  };

  getSlides = (wizardExposedProps: UIWizardExposedProps): React.ReactNode[] => {
    const { activeIndex, goToSlide } = wizardExposedProps;

    const { slidesToShow } = this;
    const slidesThatCanBeShown = slidesToShow.filter((slideName: SetupStepName) => {
      const isRelayConnectedSlide = Boolean(RELAY_CONNECTED_SLIDES[slideName]);

      // If we don't have data, then all relay connected slides should be bypassed
      if (!this.props.me || !this.props.query) {
        return !isRelayConnectedSlide;
      }

      // Otherwise if we have data, all slides are ok
      return true;
    });

    return slidesThatCanBeShown.map((slideName, idx) => {
      const isActive = activeIndex === idx;
      const isLastSlide = idx === slidesThatCanBeShown.length - 1;

      // A slide can only advance to the next slide, nothing more than that.
      const goToNextSlide = async () => goToSlide(idx + 1);

      // Last slide should complete the flow.
      const next = isLastSlide ? this.finishSetup : goToNextSlide;

      const RelayConnectedSetupSlideComponent = RELAY_CONNECTED_SLIDES[slideName];

      // If the slide is a relay connected slide, add some props to it.
      if (RelayConnectedSetupSlideComponent) {
        return (
          <RelayConnectedSetupSlideComponent
            isActive={isActive}
            next={next}
            me={this.props.me as AccountSetupFlowMe}
            query={this.props.query as AccountSetupFlowQuery}
          />
        );
      }

      const CoreSetupSlideComponent = CORE_SLIDES[slideName];

      return <CoreSetupSlideComponent isActive={isActive} next={next} />;
    });
  };

  markNuxShown = (): void => {
    try {
      // Mark when we finish setup to denote if we have seen the setup flow.
      MarkNuxShownMutation.commit(environment);
    } catch (error) {
      logException("MarkNuxShownMutation", "componentDidMount", "AccountSetupWelcomeSlide", error);
    }
  };

  render = (): React.ReactNode => {
    return (
      <EmbeddedPageWithTopBarWrapper>
        <div className={styles.scrollable}>
          <UIWizard
            showBackButton={this.showBackButton}
            onClickBack={this.onClickBack}
            renderBackground={this.renderBackground}
            respectSafeArea={!isEmbedded}
            className={styles.wizard}
            enableHardwareBack
          >
            {this.getSlides}
          </UIWizard>
        </div>
      </EmbeddedPageWithTopBarWrapper>
    );
  };
}

const FragmentContainer = createFragmentContainer(AccountSetupFlow, {
  query: graphql`
    fragment AccountSetupFlow_query on Query {
      getFriendRecommendations {
        reason
      }
      ...AccountSetupNianticFriendsSlide_query
    }
  `,
  me: graphql`
    fragment AccountSetupFlow_me on User {
      gameProfiles {
        id
        game
        visibility
      }
      ...AccountSetupNianticGamesSlide_me
      ...AccountSetupNianticFriendsSlide_me
    }
  `,
});

export default FragmentContainer;
