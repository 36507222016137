/* eslint-disable react/self-closing-comp */
import _ from "lodash";
import { MessageEvent } from "pubnub";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import withAuth, { WithAuthProps } from "providers/AuthProvider/withAuth";
import withPubnub, { WithPubnubProps } from "providers/PubnubProvider/withPubnub";

import ConfirmationDialogView, {
  OwnProps as ConfirmationDialogProps,
} from "common/components/ConfirmationDialogView";
import UIDialog from "common/components/UIDialog";

export type Props = WithTranslation &
  WithAuthProps &
  WithPubnubProps & {
    userId: string;
    channel: string;
  };

type State = {
  isDialogOpen: boolean;
  confirmationDialogProps: ConfirmationDialogProps;
};

/**
 * Registers actions to be performed when the user's channel group receives a signal from
 * pubnub. Usually when a different user sends a chat message, the BE will publish
 * a pubnub signal to an associated signal channel that exists alongside a message channel. This
 * we use to denote activity within a message channel, all for the awesome price of $0.00.
 * (Since signals are free).
 */
class UserWarningMessageHandler extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDialogOpen: false,
      confirmationDialogProps: {
        title: "",
      },
    };
  }

  componentDidMount = (): void => {
    // eslint-disable-next-line max-len
    this.props.pubnubProvider.pubnubClient.registerMessageHandler(
      this.props.channel,
      this.handleNewChannelActivity,
    );
  };

  componentWillUnmount = (): void => {
    // eslint-disable-next-line max-len
    this.props.pubnubProvider.pubnubClient.unregisterListener(
      this.props.channel,
      this.handleNewChannelActivity,
      false,
    );
  };

  openConfirmationDialog = (confirmationDialogProps: ConfirmationDialogProps) => {
    this.setState({ isDialogOpen: true, confirmationDialogProps });
  };

  closeConfirmationDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  promptWarningModal = (): void => {
    this.openConfirmationDialog({
      title: this.props.t("ACCOUNT_WARNING"),
      description: this.props.t("ACCOUNT_WARNING_MESSAGE"),
      onCancel: this.closeConfirmationDialog,
      cancelBtnText: this.props.t("CLOSE"),
    });
  };

  handleNewChannelActivity = (pubnubMessageEvent: MessageEvent) => {
    const pubnubMessage: CampfirePushEvent.WarnPushEvent = pubnubMessageEvent.message;
    const actionType: CampfirePushEvent.ActionType = _.get(pubnubMessage, "action") || "";
    const isWarnAction = actionType === "warn";

    if (isWarnAction) {
      this.promptWarningModal();
    }
  };

  render = (): React.ReactNode => {
    return (
      <UIDialog
        type="floating-center"
        isOpen={this.state.isDialogOpen}
        close={this.closeConfirmationDialog}
        onWillDismiss={this.closeConfirmationDialog}
      >
        <ConfirmationDialogView {...this.state.confirmationDialogProps} />
      </UIDialog>
    );
  };
}

const PubnubConnected = withPubnub(UserWarningMessageHandler);
const AuthConnected = withAuth(PubnubConnected);

export default withTranslation()(AuthConnected);
