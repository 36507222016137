export type LatLngBounds = {
  sw: google.maps.LatLngLiteral;
  ne: google.maps.LatLngLiteral;
};

// Legacy format used in our codebase
// sw: `[lat, lng]`
// ne: `[lat, lng]`
export type LegacyLatLngBoundsFormat = {
  sw: string;
  ne: string;
};

/**
 * Computes a bounds object of equal radius from a center point.
 */
export const computeBoundsOfEqualRadiusFromCenter = (
  radiusMeters: number,
  center: google.maps.LatLng | google.maps.LatLngLiteral,
): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds();
  const ne = google.maps.geometry.spherical.computeOffset(center, radiusMeters, 45);
  const sw = google.maps.geometry.spherical.computeOffset(center, radiusMeters, 225);

  bounds.extend(ne);
  bounds.extend(sw);

  return bounds;
};

/**
 * Converts google.maps.LatLngBounds into the legacy format used for some of our GQL queries
 */
export const convertGoogleLatLngBoundsToLegacyFormat = (
  latLngBounds: google.maps.LatLngBounds,
): LegacyLatLngBoundsFormat => {
  const swLatLng = latLngBounds.getSouthWest();
  const neLatLng = latLngBounds.getNorthEast();

  return {
    sw: `[${swLatLng.lat()}, ${swLatLng.lng()}]`,
    ne: `[${neLatLng.lat()}, ${neLatLng.lng()}]`,
  };
};

/**
 * Converts google.maps.LatLngBounds into our own literal LatLngBounds format
 */
export const convertGoogleLatLngBoundsToLiteralFormat = (
  latLngBounds: google.maps.LatLngBounds,
): LatLngBounds => {
  const swLatLng = latLngBounds.getSouthWest();
  const neLatLng = latLngBounds.getNorthEast();

  return {
    sw: {
      lat: swLatLng.lat(),
      lng: swLatLng.lng(),
    },
    ne: {
      lat: neLatLng.lat(),
      lng: neLatLng.lng(),
    },
  };
};
