import React from "react";

import UISpinner from "common/components/UISpinner";

import styles from "./styles.scss";

type Props = {
  delayMs?: number;
};

type State = {
  show: boolean;
};

const DEFAULT_DELAY_MS = 300;

export default class LoadingPageDefault extends React.Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timer: any;

  constructor(props: Props) {
    super(props);

    const delay = props.delayMs !== undefined ? props.delayMs : DEFAULT_DELAY_MS;

    this.state = {
      show: delay === 0,
    };
  }

  componentDidMount = (): void => {
    if (!this.state.show) {
      const delay = this.props.delayMs || DEFAULT_DELAY_MS;

      // Dont show immediately, show if the component hasn't been unmounted.
      this.timer = setTimeout(() => {
        this.setState({ show: true });
      }, delay);
    }
  };

  componentWillUnmount = (): void => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  };

  render = (): React.ReactNode => {
    const { show } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className={styles.root}>
        <UISpinner color="dark" size={48} />
      </div>
    );
  };
}
