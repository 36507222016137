import { getUrlForClubWithInvite } from "common/utils/routing/routeToClubById";
import { getUrlForClubChannelWithInvite } from "common/utils/routing/routeToClubChannel";
import { getClubFeedRouteForClub } from "common/utils/routing/routeToClubFeed";
import { getUrlForClubs } from "common/utils/routing/routeToClubs";
import { getUrlForDmChannel } from "common/utils/routing/routeToDMChannel";
import { getUrlForDmMessageDeprecated } from "common/utils/routing/routeToDMMessage_deprecated";
import { getUrlForEventInClubFeed } from "common/utils/routing/routeToEventInClubFeed";
import { generateMapObjectUrl } from "common/utils/routing/routeToMapObject";
import { getRouteForMapPageCreatePost } from "common/utils/routing/routeToMapPageAndCreatePost";
import { getRouteForMapPageSelectedRealityChannel } from "common/utils/routing/routeToMapPageAndSelectRealityChannel";
import { getUrlForMessagesTab } from "common/utils/routing/routeToMessages";
import { CLUBS_FEED_ROUTE, CLUBS_ROUTE, MAP_ROUTE, MESSAGES_ROUTE } from "constants/routes";

import { ParsedDeepLink } from "./index";

const buildRedirectUrlFromParsedDeepLinkArgs = ({
  ROUTE,
  CLUB_ID,
  CHANNEL_ID,
  EVENT_ID,
  INVITE,
  LAT,
  LNG,
  MAP_OBJECT_ID,
  PHOTO_ID,
  UN_MODERATED_PHOTO_ID,
  GAME,
  CREATE_POST,
  SHARE_PROMPT,
  TIME_TOKEN,
}: {
  [k in keyof ParsedDeepLink]: string;
}): ReactRouterv5HistoryPushParam | undefined => {
  // Deeplink to Club Page
  if (ROUTE === CLUBS_ROUTE) {
    if (CLUB_ID && CHANNEL_ID) {
      return getUrlForClubChannelWithInvite(CLUB_ID, CHANNEL_ID, Boolean(INVITE));
    }

    if (CLUB_ID) {
      return getUrlForClubWithInvite(CLUB_ID, Boolean(INVITE));
    }

    return getUrlForClubs();
  }

  // Deeplink to Club Feed
  if (ROUTE === CLUBS_FEED_ROUTE) {
    if (CLUB_ID && EVENT_ID) return getUrlForEventInClubFeed(CLUB_ID, EVENT_ID);
    if (CLUB_ID) return getClubFeedRouteForClub(CLUB_ID);
  }

  // Deeplink to Map Page
  if (ROUTE === MAP_ROUTE) {
    if (CREATE_POST) {
      return getRouteForMapPageCreatePost(
        PHOTO_ID,
        UN_MODERATED_PHOTO_ID,
        GAME,
        SHARE_PROMPT,
        LAT,
        LNG,
      );
    }

    if (MAP_OBJECT_ID) {
      return generateMapObjectUrl(LAT, LNG, MAP_OBJECT_ID, GAME);
    }

    return getRouteForMapPageSelectedRealityChannel(GAME);
  }

  // Deeplink to Direct Message
  if (ROUTE === MESSAGES_ROUTE) {
    if (CHANNEL_ID && TIME_TOKEN) {
      return getUrlForDmMessageDeprecated(CHANNEL_ID, TIME_TOKEN);
    }

    if (CHANNEL_ID) {
      return getUrlForDmChannel(CHANNEL_ID);
    }

    return getUrlForMessagesTab();
  }

  return undefined;
};

export default buildRedirectUrlFromParsedDeepLinkArgs;
