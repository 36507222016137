/**
 * @generated SignedSource<<5a628024628670ab9abbafbd115c3f89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveFriendInput = {
  userId: string;
};
export type RemoveFriendMutation$variables = {
  input: RemoveFriendInput;
};
export type RemoveFriendMutation$data = {
  readonly removeFriend: {
    readonly me: {
      readonly friendsList: ReadonlyArray<{
        readonly avatarUrl: string;
        readonly displayname: string;
        readonly hasCampfireStandaloneAccess: boolean;
        readonly hasCampfireStandaloneInvite: boolean;
        readonly isCampfire: boolean;
        readonly userId: string;
        readonly username: string;
      }>;
    };
    readonly success: boolean;
    readonly user: {
      readonly isMyFriend: boolean;
    };
  };
};
export type RemoveFriendMutation = {
  response: RemoveFriendMutation$data;
  variables: RemoveFriendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "FriendsListEntry",
  "kind": "LinkedField",
  "name": "friendsList",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCampfire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCampfireStandaloneInvite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCampfireStandaloneAccess",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMyFriend",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveFriendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveFriendResponse",
        "kind": "LinkedField",
        "name": "removeFriend",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveFriendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveFriendResponse",
        "kind": "LinkedField",
        "name": "removeFriend",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0515f1c0d7e0720cf13d09506e1ad5c1",
    "id": null,
    "metadata": {},
    "name": "RemoveFriendMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveFriendMutation(\n  $input: RemoveFriendInput!\n) {\n  removeFriend(input: $input) {\n    me {\n      friendsList {\n        userId\n        username\n        displayname\n        isCampfire\n        avatarUrl\n        hasCampfireStandaloneInvite\n        hasCampfireStandaloneAccess\n      }\n      id\n    }\n    user {\n      isMyFriend\n      id\n    }\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "968f64a5488e29a276cede0a2d033e4a";

export default node;
