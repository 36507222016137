/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import _ from "lodash";
import { Environment, Network, QueryResponseCache, RecordSource, Store } from "relay-runtime";

import logException from "common/analytics/exceptions";
import ajaxPath from "common/api/ajaxPath";
import { post } from "common/api/axios";
import { ONE_MINUTE } from "constants/time";

export const cacheTTL = ONE_MINUTE;

const MOCKED_RELAY_EMPTY_RESPONSE = { data: null };

const cache = new QueryResponseCache({ size: 250, ttl: cacheTTL });

type CacheConfig = any & {
  force: boolean;
  metadata: {
    // By default, mutations invalidate the cache. If you do not want that to happen
    // you can set this.
    disableMutationInvalidation: boolean;

    // axios stuff
    axios: {
      signal: AbortSignal;
    };
  };
};

export const clearCache = (): void => {
  cache.clear();
};

function fetchQuery(operation: any, variables: any, cacheConfig: CacheConfig, uploadables: any) {
  const queryID = operation.text;
  const isMutation = operation.operationKind === "mutation";
  const isQuery = operation.operationKind === "query";
  const forceFetch = cacheConfig && cacheConfig.force;
  const disableMutationInvalidation =
    cacheConfig && cacheConfig.metadata && cacheConfig.metadata.disableMutationInvalidation;

  // Try to get data from cache on queries
  const fromCache = cache.get(queryID, variables);

  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  let requestHeaders = {};
  let requestData: any = {
    query: operation.text,
    variables,
  };

  // Taken from: https://github.com/facebook/relay/issues/1844#issuecomment-316893590
  if (uploadables) {
    if (!window.FormData) {
      throw new Error("Uploading files without `FormData` not supported.");
    }

    const formData = new FormData();

    // For Gqlgen, we need "operations" and "map" on form data so that it auto parses.
    // They implement this spec: https://github.com/jaydenseric/graphql-multipart-request-spec
    // "operations": A JSON encoded operations object with files replaced with null.
    //               (NOT SURE IF WE NEED TO DO THE NULL PART)
    // "map": A JSON encoded map of where files occurred in the operations.
    //        For each file, the key is the file multipart form field name and the value is an
    //        array of operations paths.
    const gqlGenOperations = {
      query: operation.text,
      variables,
    };

    const mapGqlGen: Record<string, string[]> = {};

    // Example: Appends "file" as binary data.
    Object.keys(uploadables).forEach((key) => {
      mapGqlGen[key] = [`variables.${key}`];

      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    // Add the required fields to the form data.
    formData.append("operations", JSON.stringify(gqlGenOperations));
    formData.append("map", JSON.stringify(mapGqlGen));

    requestData = formData;
  } else {
    requestHeaders = {
      "Content-Type": "application/json",
    };

    requestData = {
      query: operation.text,
      variables,
    };
  }

  const axiosConfigs = _.get(cacheConfig, "metadata.axios") || {};

  // Otherwise, fetch data from server
  return post(ajaxPath.get("graphql"), requestData, requestHeaders, axiosConfigs)
    .then((response) => {
      // Update cache on queries
      if (isQuery && response) {
        cache.set(queryID, variables, response);
      }

      // Clear cache on mutations
      if (!disableMutationInvalidation && isMutation) {
        cache.clear();
      }

      // Sentry: If no response from gql, return an empty null payload
      // Look at the way relay handles errors here as to why we kept getting this
      // "TypeError: undefined is not an object (evaluating 'e.data')" error
      // Relay expects a "data" field to exist on the response, otherwise its sadface.
      // https://github.com/facebook/relay/blob/6d013387f27bfd5cde684033c5e1bf93afeea9d2/packages/relay-runtime/store/OperationExecutor.js#L405
      if (!response) {
        // Log an error for the operation name so we can see this in sentry.
        logException(
          "relay fetchQuery",
          "fetchQuery",
          "relay-env",
          new Error(`Error: Empty response from GQL for query: ${operation.name}`),
        );
        return MOCKED_RELAY_EMPTY_RESPONSE;
      }

      return response;
    })
    .catch((error) => {
      // Check if the axios request got cancelled.
      if (axios.isCancel(error)) {
        throw error;
      }

      // Error is forwarded from axios error interceptor
      // This happens when we get a 400 response
      // eslint-disable-next-line no-console
      console.error("network error:", error);

      // Return the error response, as there might be useable error info on it.
      // This error goes to the mutation onError
      if (error.response) {
        throw error.response;
      }
    });
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource(), { queryCacheExpirationTime: cacheTTL }),
});

export default environment;
