import { IonItem } from "@ionic/react";
import classnames from "classnames";
import { chevronForward } from "ionicons/icons";
import React from "react";

import { IMenuItemProps } from "common/components/MenuGroup";
import UIIcon from "common/components/UIIcon";
import UILink from "common/components/UILink";
import UIText from "common/components/UIText";
// eslint-disable-next-line import/order
import menuItemStyles from "common/components/MenuGroup/components/menuItemStyles.scss";
import styles from "./styles.scss";

export type Props = IMenuItemProps & {
  href: string;
  openInNewTab?: boolean;
};

type State = {};

export default class MenuItemLink extends React.Component<Props, State> {
  stopEvent = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  renderEndAdornment = (): React.ReactNode => {
    if (this.props.renderEndAdornment) {
      return this.props.renderEndAdornment();
    }

    if (this.props.hideEndAdornment) {
      return null;
    }

    return (
      <UIIcon className={styles.endArrow} size={16} color="dark" icon={chevronForward} slot="end" />
    );
  };

  render = (): React.ReactNode => {
    const { name, href, openInNewTab = true, disabled, variant, onClick, dataTestId } = this.props;

    return (
      <UILink
        onClick={disabled ? this.stopEvent : undefined}
        className={classnames(menuItemStyles.menuItem, styles.link, {
          [menuItemStyles.connected]: variant === "connected",
          [menuItemStyles.disconnected]: variant === "disconnected",
        })}
        href={href}
        openInNewTab={openInNewTab}
      >
        <IonItem
          className={classnames(styles.root, {
            [styles.disabled]: disabled,
            [styles.connected]: variant === "connected",
            [styles.disconnected]: variant === "disconnected",
          })}
          onClick={onClick}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          dataTestId={dataTestId}
        >
          <UIText weight="bold">{name}</UIText>
          <div slot="end">{this.renderEndAdornment()}</div>
        </IonItem>
      </UILink>
    );
  };
}
