import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { SharedLoginToken } from "common/capacitor/plugins/shared-login";
import { getGameShortNameFromCode } from "common/utils/nianticGame";

import AuthProviderIcon from "common/components/AuthProviderIcon";
import UIAsyncButton from "common/components/UIAsyncButton";
import UIButton from "common/components/UIButton";
import UIDialog from "common/components/UIDialog";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

type Props = WithTranslation & {
  gameShortCode: string;
  sharedLoginToken: SharedLoginToken;
  switchAccounts: () => void;
  continue: () => void;
  close: () => void;
};

type State = {};

class SharedLoginAccountWarning extends React.Component<Props, State> {
  render = (): React.ReactNode => {
    const gameName = getGameShortNameFromCode(this.props.gameShortCode);

    return (
      <UIDialog type="floating-center" isOpen close={this.props.close}>
        <div className={styles.content}>
          <UIText variant="h2" weight="bold" color="dark">
            {this.props.t("CHOOSE_AN_ACCOUNT")}
          </UIText>

          <UISpacer h={20} />

          <UIText variant="h4" color="dark">
            {this.props.t("SHARED_LOGIN_WARNING_COMING_FROM", { gameName })}
          </UIText>

          <div className={styles.accountInfo}>
            <AuthProviderIcon
              provider={this.props.sharedLoginToken.AuthProviderId as SupportedOAuthProvider}
              mode="normal"
              className={styles.authProviderIcon}
            />
            <UISpacer w={15} />
            <UIText>{this.props.sharedLoginToken.AccountName}</UIText>
          </div>

          <UISpacer h={20} />

          <UIText variant="h4" color="dark">
            {this.props.t("SHARED_LOGIN_WARNING_ALREADY_LOGGED_IN")}
          </UIText>

          <UISpacer h={20} />

          <UIAsyncButton
            onClick={this.props.switchAccounts}
            color="success"
            expand="block"
            fill="solid"
          >
            <UIText weight="bold">{this.props.t("SWITCH_ACCOUNT")}</UIText>
          </UIAsyncButton>

          <UISpacer h={6} />

          <UIButton onClick={this.props.continue} color="light" expand="block" fill="solid">
            <UIText weight="bold">{this.props.t("CONTINUE")}</UIText>
          </UIButton>
        </div>
      </UIDialog>
    );
  };
}

export default withTranslation()(SharedLoginAccountWarning);
