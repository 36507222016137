import environment from "common/relay/relay-env";
import React, { Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";

import LoadingPageDefault from "common/components/LoadingPageDefault";
import PageLoadError from "common/components/PageLoadError";
import UIErrorBoundary from "common/components/UIErrorBoundary";

import { DiscordIntegration_page_Query$data as DiscordIntegrationPageQueryResponse } from "__generated__/DiscordIntegration_page_Query.graphql";

// import styles from "./styles.scss";

// Lazy Load Main View
const DiscordIntegrationPageMainContent = React.lazy(
  () => import("pages/DiscordIntegration/components/DiscordIntegrationPageMainContent"),
);

type Props = WithTranslation & {};

type State = {
  initializedAt: number;
};

type QRProps = {
  me: DiscordIntegrationPageQueryResponse["me"];
};

const DISCORDINTEGRATION_PAGE = graphql`
  query DiscordIntegration_page_Query {
    me {
      ...DiscordIntegrationPageMainContent_me
    }
  }
`;

class DiscordIntegrationPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      initializedAt: Date.now(),
    };
  }

  reInit = (): void => {
    this.setState({ initializedAt: Date.now() });
  };

  onRetry = (clearError: () => void): void => {
    this.reInit();
    clearError();
  };

  render = (): React.ReactNode => (
    <UIErrorBoundary onRetry={this.onRetry}>
      <Suspense fallback={<LoadingPageDefault />}>
        <QueryRenderer
          key={this.state.initializedAt}
          environment={environment}
          query={DISCORDINTEGRATION_PAGE}
          variables={{}}
          render={({ props, error }) => {
            const qrProps = props as QRProps;
            const isLoading = !qrProps;
            const isError = !!error;

            if (isLoading) {
              return <LoadingPageDefault />;
            }

            if (isError) {
              return (
                <PageLoadError
                  errorMessage={this.props.t("SORRY_SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN")}
                  reload={this.reInit}
                />
              );
            }

            return <DiscordIntegrationPageMainContent me={qrProps.me} />;
          }}
        />
      </Suspense>
    </UIErrorBoundary>
  );
}

export default withTranslation()(DiscordIntegrationPage);
