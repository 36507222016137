import React from "react";

import { UserProfileProvider } from "providers/UserProfileProvider";

import ClubUserProfileFetcher from "common/components/ClubUserProfileFetcher";

export type Props = {
  clubId: string | null;
  children: React.ReactNode;
};

type State = {};

export default class ClubUserProfileProviderMask extends React.Component<Props, State> {
  renderProfile = (userId: string, close: () => void): React.ReactNode => {
    if (!this.props.clubId) {
      return null;
    }

    return <ClubUserProfileFetcher userId={userId} clubId={this.props.clubId} close={close} />;
  };

  render = (): React.ReactNode => {
    // If we have a clubId, use the more custom rendering. Otherwise defer to the defaults.
    const renderProfile = this.props.clubId ? this.renderProfile : undefined;

    return (
      <UserProfileProvider renderProfile={renderProfile}>{this.props.children}</UserProfileProvider>
    );
  };
}
