/**
 * @generated SignedSource<<718308d5af1120377e524484ee29a766>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClubActivityFeed_page_Query$variables = {
  clubId: string;
};
export type ClubActivityFeed_page_Query$data = {
  readonly club: {
    readonly " $fragmentSpreads": FragmentRefs<"ClubActivityFeedPageMainContent_club">;
  };
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ClubActivityFeedPageMainContent_me">;
  };
};
export type ClubActivityFeed_page_Query = {
  response: ClubActivityFeed_page_Query$data;
  variables: ClubActivityFeed_page_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clubId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "clubId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClubActivityFeed_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClubActivityFeedPageMainContent_me"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClubActivityFeedPageMainContent_club"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClubActivityFeed_page_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visibility",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pubnubMessageChannelName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amIAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amIBanned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amIMember",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "113dc447fce80fc7d08808d9ab2a9f8f",
    "id": null,
    "metadata": {},
    "name": "ClubActivityFeed_page_Query",
    "operationKind": "query",
    "text": "query ClubActivityFeed_page_Query(\n  $clubId: ID!\n) {\n  me {\n    ...ClubActivityFeedPageMainContent_me\n    id\n  }\n  club(id: $clubId) {\n    ...ClubActivityFeedPageMainContent_club\n    id\n  }\n}\n\nfragment ClubAccessControl_club on Club {\n  id\n  amIBanned\n  amIMember\n  ...JoinClubDialog_club\n  ...ClubGuidelines_club\n}\n\nfragment ClubAccessControl_me on User {\n  isSuperAdmin\n  ...ClubGuidelines_me\n}\n\nfragment ClubActivityFeedPageMainContent_club on Club {\n  id\n  name\n  visibility\n  pubnubMessageChannelName\n  amIAdmin\n  ...ClubAccessControl_club\n}\n\nfragment ClubActivityFeedPageMainContent_me on User {\n  id\n  avatarUrl\n  displayName\n  username\n  isSuperAdmin\n  ...ClubAccessControl_me\n  ...EventCard_me\n}\n\nfragment ClubGuidelines_club on Club {\n  id\n  avatarUrl\n  name\n}\n\nfragment ClubGuidelines_me on User {\n  id\n}\n\nfragment EventCard_me on User {\n  ...EventRendererProvider_me\n}\n\nfragment EventRendererEditEventForm_me on User {\n  id\n}\n\nfragment EventRendererProvider_me on User {\n  id\n  ...EventRendererEditEventForm_me\n}\n\nfragment JoinClubDialog_club on Club {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "065801d786fbad97f7bd446e307c9a00";

export default node;
