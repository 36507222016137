import _ from "lodash";
import { commitMutation, Environment, graphql } from "react-relay";

import { APOLOGETIC_ERROR_MESSAGE } from "constants/strings/network";
import { MutationError } from "types/modules/relay/mutations";

import { MarkNuxShownMutation$data } from "__generated__/MarkNuxShownMutation.graphql";

type ReturnType = MarkNuxShownMutation$data["markNuxShown"];
type ResolveType = (data: ReturnType) => void;
type RejectType = (error: string) => void;

const mutation = graphql`
  mutation MarkNuxShownMutation {
    markNuxShown {
      me {
        shouldShowNux
      }
    }
  }
`;

const commit = (environment: Environment): Promise<ReturnType> => {
  return new Promise((resolve: ResolveType, reject: RejectType) => {
    // Success Handler
    const onCompleted = (response: GenericObject, errors: MutationError) => {
      const hasErrors = _.get(response, "markNuxShown.errors.length", 0) > 0;

      if (hasErrors) {
        reject(_.get(response, "markNuxShown.errors[0].message", errors));

        return;
      }

      resolve(_.get(response, "markNuxShown"));
    };

    // Relay Error Handler
    const onError = (error: Error) => {
      const relayErrorMessage =
        _.get(error, "source.errors[0].message") ||
        _.get(error, "data.errors[0].message") ||
        APOLOGETIC_ERROR_MESSAGE;

      reject(relayErrorMessage);
    };

    // Commit the Mutation
    commitMutation(environment, {
      mutation,
      variables: {},
      onCompleted,
      onError,
    });
  });
};

export default { commit };
