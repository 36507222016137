import { App } from "@capacitor/app";
import React from "react";

import { isWeb } from "common/capacitor/helpers";

type Props = {
  children: React.ReactNode;
};

type State = {
  appVersion: string;
};

export type ExposedProps = {
  appVersion: string;
};

const INITIAL_CONTEXT: ExposedProps = {
  appVersion: "",
};

const VersionInfoContext = React.createContext(INITIAL_CONTEXT);

class VersionInfoProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      appVersion: "",
    };
  }

  componentDidMount = async (): Promise<void> => {
    // Version info not implemented on web.
    if (isWeb) {
      return;
    }

    const versionInfo = await this.getVersionInfo();

    this.setState(versionInfo);
  };

  getVersionInfo = async (): Promise<State> => {
    const appVersion = await App.getInfo();

    return {
      appVersion: appVersion.version,
    };
  };

  render = (): React.ReactNode => {
    const { children } = this.props;
    const { appVersion } = this.state;

    return (
      <VersionInfoContext.Provider
        value={{
          appVersion,
        }}
      >
        {children}
      </VersionInfoContext.Provider>
    );
  };
}

export const VersionInfoConsumer = VersionInfoContext.Consumer;
export { VersionInfoProvider };
