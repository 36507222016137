/**
 * @generated SignedSource<<c3335a959e8a562b226db2f4ddea2bca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthenticatedServiceProviders_me$data = {
  readonly blockedByUsers: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly blockedUsers: ReadonlyArray<{
    readonly avatarUrl: string;
    readonly displayName: string;
    readonly id: string;
    readonly username: string;
  }>;
  readonly featureFlags: ReadonlyArray<string>;
  readonly id: string;
  readonly messagesChannelGroupName: string;
  readonly pubnubAuthKey: string;
  readonly pubnubUserMessageChannelName: string;
  readonly signalsChannelGroupName: string;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalMessageHandlers_me" | "GlobalSignalHandlers_me" | "UserInteractionHistoryProvider_me">;
  readonly " $fragmentType": "AuthenticatedServiceProviders_me";
};
export type AuthenticatedServiceProviders_me$key = {
  readonly " $data"?: AuthenticatedServiceProviders_me$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthenticatedServiceProviders_me">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthenticatedServiceProviders_me",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signalsChannelGroupName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messagesChannelGroupName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pubnubUserMessageChannelName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "blockedUsers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatarUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "blockedByUsers",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pubnubAuthKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "featureFlags",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GlobalSignalHandlers_me"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GlobalMessageHandlers_me"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserInteractionHistoryProvider_me"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "81719ae01f1fed558024f0fa52e0e662";

export default node;
