import React from "react";

import { ReactComponent as NianticAirship } from "assets/icons/niantic-airship.svg";
import NianticYetiPng from "assets/images/niantic/niantic-doty-yeti-avatar.png";

import OnboardElementCard from "common/components/Onboarding/components/OnboardElementCard";
import UISpacer from "common/components/UISpacer";
import UIText from "common/components/UIText";

import styles from "./styles.scss";

export type Props = {};

export default class OnboardNianticIdPreview extends React.Component<Props> {
  render = (): React.ReactNode => {
    return (
      <div className={styles.root}>
        <OnboardElementCard small showRibbon={false}>
          <div className={styles.ribbon}>
            <NianticAirship className={styles.airship} width={25} height={35} />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.avatarContainer}>
              <img className={styles.avatarImg} src={NianticYetiPng} alt="Avatar" />
            </div>
            <UISpacer w={9} />
            <div className={styles.idContainer}>
              <UIText color="dark" variant="h4" weight="bold">
                YourNianticID
              </UIText>
              <UISpacer h={2} />
              <UIText color="dark" variant="h5">
                @YourNianticID
              </UIText>
            </div>
          </div>
        </OnboardElementCard>
      </div>
    );
  };
}
